import React from "react";

function SnapDataBox({class_name = '', title, value, subtext=""}){
    return (
        <div className="snap-data-box">
            <p className="snap-box-title">{title}</p>
            <div className="snap-box-value-box">
                <p className={`snap-box-value ${class_name}`}>{value}</p>
                <div className="snap-box-subtext">{subtext}</div>
            </div>
        </div>
    )
}

export default SnapDataBox;