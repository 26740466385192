import axios from "axios";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;
axios.defaults.baseURL = REQUEST_BASE_URL;
axios.defaults.withCredentials = true;

async function ServerRequest(params){
  try{
    return new Promise(async (resolve,reject)=>{
      const response = await axios.request(params);
      resolve(response.data);
    });
  }
  catch(e){
    throw new Error(e);
  }
}

export default ServerRequest;