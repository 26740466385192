import React from "react";

class CustomIconButton extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { disabled, onClick, icon, title } = this.props;

    return (
      <>
        <div
          className={"swift-button-icon " + (disabled ? "disabled" : "")}
          onClick={!disabled ? onClick : () => { }}
        >
          <div className="button-icon">{icon}</div>
          <p>{title}</p>
        </div>
      </>
    );
  }
}

export default CustomIconButton;
