import React, { useEffect } from "react";
import { Cross } from "../../../CustomComponents/SwiftIcon/Icons";
import useMarketStock from "../../../../hooks/useMarketStock";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { numberFormat, priceFormat } from "../../../../exports/UtilFunctions";

function PortfolioTableRow({
  user_role_type,
  index,
  data,
  total_portfolio_value,
  orderArr,
  newWeight,
  originalLen,
  deleteStock,
  updateOrderArr,
  setIndividualChange,
  setIndividualPrice,
}) {
  const {
    StockCode,
    StockName,
    Quantity,
    AverageCost,
    CostValue,
    CurrentPrice,
    LastClose,
    PortfolioWeight,
  } = data;

  const stockData = useMarketStock(StockCode);

  useEffect(() => {
    if (!stockData) return;
    setIndividualChange(index, stockData.change_percentage);
    setIndividualPrice(index, stockData.last_traded_price);
  }, [stockData]);

  function onChangeOrder(event) {
    const value = event.target.value;

    if (/^-?\d*$/.test(value) || value === "") {
      updateOrderArr(index, value);
    }
  }

  function onBlurOrder(event) {
    const value = event.target.value;
    if (value === "") {
      updateOrderArr(index, 0);
    }
  }

  function onKeyDownOrder(event) {
    let value = event.target.value;

    if (value == "") {
      value = "0";
    }

    value = parseInt(value);

    const keyCode = event.keyCode;

    if (keyCode === 38) {
      event.preventDefault();
      updateOrderArr(index, value + 1);
    } else if (keyCode === 40) {
      event.preventDefault();
      updateOrderArr(index, value - 1);
    }
  }

  function GetNewWeight(weight) {
    if (isNaN(weight)) return "";

    if (weight || weight === 0) {
      if (weight === 0) {
        return `(0.00%)`;
      } else {
        return `(${weight}%)`;
      }
    } else {
      return "";
    }
  }

  const total_value = total_portfolio_value;
  let prev_close = LastClose;
  let last_traded_price = stockData
    ? stockData.last_traded_price
    : CurrentPrice;
  let current_value = Quantity * last_traded_price;
  let portfolio_weight = (current_value * 100) / total_value;
  let change_price = last_traded_price - prev_close;
  //   let change_percentage = stockData ? stockData.change_percentage : "0.00%";
  let change_percentage = (last_traded_price / prev_close - 1) * 100;
  let priceClass = change_price >= 0 ? "positive" : "negative";
  let new_weight = GetNewWeight(newWeight);

  let current_returns = (current_value / CostValue - 1) * 100;

  return (
    <tr>
      <td className="name active">
        <div>
          <div className="portfolio__stock__code">
            <div className="stock__code">{StockCode}</div>
          </div>
          <div className="portfolio__stock__name">{StockName}</div>
        </div>
      </td>
      <td className="quantity active">
        <div>{numberFormat(Quantity, 0)}</div>
      </td>
      <td className="buyprice">
        <div>{priceFormat(AverageCost)}</div>
      </td>
      <td className="currprice active">
        <div>{priceFormat(last_traded_price)}</div>
      </td>
      <td className="cost">
        <div>{priceFormat(CostValue)}</div>
      </td>
      <td className="currvalue">
        <div>{priceFormat(current_value)}</div>
      </td>
      <td className="return">
        <div>
          <span className={current_returns >= 0 ? "positive" : "negative"}>
            {numberFormat(current_returns)}%
          </span>
        </div>
      </td>
      <td className="today">
        <div>
          <span className={priceClass}>{numberFormat(change_percentage)}%</span>
        </div>
      </td>

      {user_role_type === "BROKER" ? (
        <>
          <td className="portweight active">
            <div>
              {`${numberFormat(portfolio_weight)}%`}&nbsp;
              <span>{new_weight}</span>
            </div>
          </td>
          <td className="tradevol">
            <div>{priceFormat(orderArr[index] * last_traded_price)}</div>
          </td>
          <td className="order active">
            <div className="input-box">
              <span
                onClick={() => {
                  if (-1 * Quantity === orderArr[index]) {
                    updateOrderArr(index, 0);
                  } else {
                    updateOrderArr(index, -1 * Quantity);
                  }
                }}
              >
                FS
              </span>
              <span
                onClick={() => {
                  updateOrderArr(index, orderArr[index] - 1);
                }}
              >
                <RemoveIcon fontSize="12" color="#192b40" />
              </span>
              <input
                type="text"
                value={orderArr[index]}
                onChange={(e) => {
                  onChangeOrder(e);
                }}
                onBlur={(e) => {
                  onBlurOrder(e);
                }}
                onKeyDown={(e) => {
                  onKeyDownOrder(e);
                }}
              />
              <span
                onClick={() => {
                  updateOrderArr(index, orderArr[index] + 1);
                }}
              >
                <AddIcon fontSize="12" color="#192b40" />
              </span>
            </div>
          </td>

          <td className="delete">
            {index < originalLen ? (
              <></>
            ) : (
              <>
                <div
                  className="increase-circle remove"
                  onClick={() => deleteStock(index)}
                >
                  <Cross size={20} color="#192b40" />
                </div>
              </>
            )}
          </td>
        </>
      ) : (
        <>
          <td className="portweight active">
            <div>{`${numberFormat(PortfolioWeight)}% ${new_weight}`}</div>
          </td>
        </>
      )}
    </tr>
  );
}

export default PortfolioTableRow;
