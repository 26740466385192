import React from 'react';
import CrossIcon from '../../../assets/icons/closesmall.svg';

class ShareSplitPopup extends React.PureComponent {

    constructor(props) {
        super(props);
        this.setComponentRef = this.setComponentRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setComponentRef(node) {
        this.ComponentRef = node;
    }

    handleClickOutside(event) {
        if (this.ComponentRef && !this.ComponentRef.contains(event.target)) {
            this.props.closePopup();
        }
    }

    render() {
        const { shares, interval } = this.props;
        const totalTrades = shares.length;
        const totalQuantity = shares.reduce((p, a) => p + a, 0);

        return (
            <div className='share__split__overlay'>
                <div className='share__split__popup' ref={this.setComponentRef}>
                    <div className='share__popup__header'>
                        <p>Share Split</p>
                        <div className='share__popup__close' onClick={() => { this.props.closePopup() }}>
                            <img src={CrossIcon} alt="x" />
                        </div>
                    </div>
                    <div className='share__popup__body'>
                        <p className='share__lable'>Trade Interval: <span>{interval} Minutes</span></p>
                        <p className='share__lable'>Total Trades: <span>{totalTrades}</span></p>
                        <p className='share__lable'>Total Quantity: <span>{totalQuantity}</span></p>
                        <div className='share__table'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Trade Number</th>
                                        <th>Trade Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {shares.map((share, indx) => {
                                        return <tr>
                                            <td>{indx + 1}</td>
                                            <td>{share}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ShareSplitPopup;