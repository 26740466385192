import React, { useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  NavLink,
  useLocation,
} from "react-router-dom";
import "../../css/RolesManagement/RolesManagement.css";
import ExternalClients from "./ExternalClients";
import RoleAddition from "./RoleAddition";
import RolesTable from "./RolesTable";

function RolesManagement() {
  const [submenu, setSubmenu] = useState("internal");

  const location = useLocation();
  const { pathname = "" } = location;

  return (
    <div className="roles-management">
      {/* <div className="roles-header">
        <p className="roles-title">User Management</p>
        <p className='roles-subtext'>Internal . External</p>
      </div> */}
      {/* submenu */}
      <div className="swift-submenu">
        <>
          <NavLink to="/account/roles/internal">
            <p
              className={pathname === "/account/roles/internal" ? "active" : ""}
            >
              Internal Users
            </p>
          </NavLink>
          <NavLink to="/account/roles/external">
            <p
              className={pathname === "/account/roles/external" ? "active" : ""}
            >
              External Clients
            </p>
          </NavLink>
        </>
      </div>
      <div className="strategy-content">
        <>
          <Routes>
            <Route
              path="internal"
              element={
                <div
                  className={
                    "roles-data " +
                    (pathname !== "/account/roles/internal" ? "hide" : "")
                  }
                >
                  <RoleAddition />
                  <RolesTable />
                </div>
              }
            />

            <Route
              path="external"
              element={
                <div
                  className={
                    "roles-customers " +
                    (pathname !== "/account/roles/external" ? "hide" : "")
                  }
                >
                  <ExternalClients />
                </div>
              }
            />

            <Route
              path="*"
              element={<Navigate to="/account/roles/internal" />}
            />
          </Routes>
        </>
      </div>
    </div>
  );
}

export default RolesManagement;
