import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import useStockInfo from "../../hooks/useStockInfo";
import useMarketStock from "../../hooks/useMarketStock";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";
import { GetCookie } from "../../exports/CookieManagement";
import Pulse from "../Loader/Pulse";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CustomIconButton from "../Body/CustomChartComponents/CustomIconButton/CustomIconButton";
import PortfolioSearchBar from "../Body/MenuSection/PortfolioComponents/SearchBar";
import {
  PlayCircle,
  TickCircle,
  Cross,
  Book1,
  Edit,
} from "../CustomComponents/SwiftIcon/Icons";
import {
  numberFormat,
  priceFormat,
  titleCase,
} from "../../exports/UtilFunctions";
import useAccount from "../../hooks/useAccount";
import SwiftModal from "../CustomComponents/SwiftModal/SwiftModal";
import OrderTypePopup from "../TransactionManagement/OrdersComponents/OrderTypePopup";
import OrderPopup from "../Body/AppPopups/StockOrderPopup/OrderPopup";
import useMovingAverage from "../../hooks/useMovingAverage";
import { SWIFTALGO_TIMEWINDOW } from "../../exports/OrderAlgoConfig";
import { COLOR_VARS } from "../../exports/ColorVars";
import useCustomerCash from "../../hooks/useCustomerCash";
import {
  PortfolioCashPosition,
  PortfolioChange,
  PortfolioInvestment,
  PortfolioProfitLoss,
  PortfolioValue,
} from "./PortfolioComponents";
import { getDeviceSize } from "../../exports/InteractiveUtils";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const DEVICE_SIZE = getDeviceSize();

function AlertMessage(message) {
  Alert({
    TitleText: "Warning",
    Message: message,
    Band: true,
    BandColor: "#E51A4B",
    BoxColor: "#ffffff",
    TextColor: "#000000",
    AutoClose: {
      Active: true,
      Line: true,
      LineColor: "#E51A4B",
      Time: 5,
    },
  });
}

function CustomerPortfolio() {
  const navigate = useNavigate();
  const account_token = GetCookie("token");

  async function GetPortfolio() {
    const response = await fetch(
      `${REQUEST_BASE_URL}/broking/portfolio?token=${account_token}`
    );
    const data = await response.json();
    return data;
  }

  const [isPlacing, setIsPlacing] = useState(false);
  const [execute, setExecute] = useState(false);
  const [holdings, setHoldings] = useState(undefined);
  const [stockConfig, setStockConfig] = useState(undefined);
  const [config, setConfig] = useState(undefined);
  const [broker, setBroker] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const { account } = useAccount();
  const { cash_data } = useCustomerCash();

  useEffect(() => {
    setHoldings(undefined);
    setStockConfig(undefined);
    setConfig(undefined);
    setExecute(false);
    GetPortfolio()
      .then((portfolio) => {
        let { error, holdings } = portfolio;
        if (!error) {
          holdings = holdings.filter((h) => {
            return h["Qty"] > 0;
          });
          setHoldings(holdings);
        } else {
          setHoldings([]);
        }
      })
      .catch(() => {
        setHoldings([]);
      });
  }, [trigger]);

  useEffect(() => {
    if (!account) return;
    const { brokers = [] } = account;

    if (!brokers || !Array.isArray(brokers) || brokers.length === 0) return;
    setBroker(brokers[0]);
  }, [account]);

  useEffect(() => {
    if (!holdings || !Array.isArray(holdings)) return;

    if (holdings.length === 0) {
      setStockConfig({});
      return;
    }

    let stock_config_object = { ...stockConfig };

    holdings.forEach((h) => {
      if (!stock_config_object.hasOwnProperty(h["Isin"])) {
        stock_config_object[h["Isin"]] = {
          symbol: h["Symbol"],
          current_price: 0,
          order: 0,
          order_config: {
            type: "MARKET",
            algo_order_type: "MARKET",
            limit: 0,
            algo_config: false,
          },
        };
      }
    });

    setStockConfig({ ...stock_config_object });
  }, [holdings]);

  useEffect(() => {
    if (!holdings || !Array.isArray(holdings) || !stockConfig) return;

    if (holdings.length === 0) {
      setConfig({});
      return;
    }

    let portfolio_value = 0;
    let order_portfolio_value = 0;
    let order_value = 0;
    let total_portfolio_value = 0;
    let total_investment_value = 0;

    holdings.forEach((h) => {
      const { Isin, Qty, AvgBuyPrice, ClosePrice, PriceDivisor } = h;
      if (stockConfig[Isin]) {
        let { order, current_price, order_config } = stockConfig[Isin];
        const { type, limit } = order_config;

        current_price = type === "MARKET" ? current_price : limit;
        order = parseInt(order);
        if (isNaN(order)) {
          order = 0;
        }

        portfolio_value += Qty * (ClosePrice / PriceDivisor);
        order_portfolio_value +=
          (parseInt(Qty) + parseInt(order)) * parseFloat(current_price);
        order_value += parseInt(order) * parseFloat(current_price);
        total_portfolio_value += Qty * parseFloat(current_price);
        total_investment_value += Qty * AvgBuyPrice;
      }
    });

    let cash_position = cash_data["cash"] || 0;
    // total_portfolio_value += cash_position;
    // order_portfolio_value += cash_position;

    let total_profit_loss = total_portfolio_value - total_investment_value;
    const total_returns =
      (total_portfolio_value / total_investment_value - 1) * 100;
    // console.log(total_portfolio_value,portfolio_value);
    const change_amount = total_portfolio_value - portfolio_value;
    const change_percentage =
      (total_portfolio_value / portfolio_value - 1) * 100;
    const cash_percentage = (cash_position * 100) / total_portfolio_value;
    const portfolio_percentage = 100;

    const stock_config_object_copy = JSON.parse(JSON.stringify(stockConfig));

    const { buy_value, sell_value } = CalculateBuySellValue(
      stock_config_object_copy
    );

    let { brokerage = 10 } = broker;

    brokerage = brokerage / 100 / 100;
    const total_trade_value = Math.abs(buy_value) + Math.abs(sell_value);
    const brokerage_value = total_trade_value * brokerage;

    // console.log('BS => ',buy_value,sell_value)

    setConfig((config) => {
      return {
        ...config,
        portfolio_value,
        total_portfolio_value,
        order_portfolio_value,
        total_investment_value,
        total_profit_loss,
        total_returns,
        change_amount,
        change_percentage,
        cash_percentage,
        portfolio_percentage,
        cash_position,
        buy_value,
        sell_value,
        brokerage_value,
      };
    });
  }, [holdings, stockConfig]);

  function CalculateBuySellValue(stock_config) {
    let buy_value = 0,
      sell_value = 0;

    if (!stock_config) return { buy_value, sell_value };

    for (let isin in stock_config) {
      let { order, current_price, order_config } = stock_config[isin];
      const { type, limit } = order_config;

      current_price = type === "MARKET" ? current_price : limit;
      if (!order && order != 0) order = 0;

      if (order < 0) {
        sell_value += Math.abs(order) * current_price;
      } else {
        buy_value += Math.abs(order) * current_price;
      }
    }

    return { buy_value, sell_value };
  }

  function GetStockQuantity(isin) {
    const stock = holdings.find((h) => {
      return h["Isin"] == isin;
    });
    if (stock) return stock["Qty"];
    return false;
  }

  function SetStockConfig(isin, property, value) {
    let error_message = false;
    const { cash_position } = config;
    let stock_config_object = { ...stockConfig };
    let buy_value, sell_value;

    if (property == "order") {
      const quantity = GetStockQuantity(isin);

      if (!quantity && quantity != 0) return;
      if (-value > quantity) {
        error_message = "Cannot sell more than current holding";
      }

      let stock_config_object_copy = JSON.parse(JSON.stringify(stockConfig));
      stock_config_object_copy[isin][property] = value;

      ({ buy_value, sell_value } = CalculateBuySellValue(
        stock_config_object_copy
      ));

      if (
        buy_value !== 0 &&
        cash_position - (buy_value + brokerage_value) < 0
      ) {
        error_message =
          "You don't have sufficient cash margin to proceed with the order";
      }
    }

    if (error_message === false) {
      stock_config_object[isin][property] = value;
      setStockConfig({ ...stock_config_object });
    } else {
      AlertMessage(error_message);
    }
  }

  function AddStockToPortfolio(stock) {
    function IsExists(isin) {
      const exists = holdings.find((h) => {
        return h["Isin"] === isin;
      });
      if (exists == undefined) return false;
      return true;
    }

    if (!stock) return;
    const { isin, symbol, company, exchange, code } = stock;
    const { code: exchange_code, multiplier } = exchange;

    if (IsExists(isin)) {
      AlertMessage(`${symbol} is already added`);
      return;
    }

    setHoldings([
      ...holdings,
      {
        Isin: isin,
        SegmentId: exchange_code,
        Token: code,
        Symbol: symbol,
        SecName: company,
        LTP: 0,
        ClosePrice: 0,
        AvgBuyPrice: 0,
        PriceDivisor: multiplier,
        Qty: 0,
        SellQty: 0,
        MarketLot: 1,
        TxnId: null,
        AprQty: 0,
        TotalSaarQty: 0,
        lExchangeScrip: null,
        editable: true,
      },
    ]);
  }

  function RemoveStockPortfolio(isin) {
    let updated_holdings = holdings.filter((h) => {
      return h["Isin"] !== isin;
    });

    const config_copy = JSON.parse(JSON.stringify(stockConfig));
    delete config_copy[isin];

    setStockConfig({
      ...config_copy,
    });
    setHoldings([...updated_holdings]);
  }

  function PlaceOrder() {
    const { cash_position } = config;
    const { buy_value, brokerage_value } = stockConfig;

    if (buy_value !== 0 && cash_position - (buy_value + brokerage_value) < 0) {
      AlertMessage(
        "You don't have sufficient cash margin to proceed with the order"
      );
    } else {
      setExecute(true);
    }
  }

  function PlacePortfolioOrder() {
    if (!account) return;

    setIsPlacing(true);
    const { account_code } = account;

    let stocks = [];
    holdings.forEach((h) => {
      const { Isin } = h;
      const { order: quantity, symbol, order_config } = stockConfig[Isin];
      const { type, limit, algo_order_type, algo_config } = order_config;

      let limit_price = 0;
      let algo_config_data = false;

      if (algo_order_type === "MARKET") {
        limit_price = false;
        algo_config_data = false;
      } else if (algo_order_type === "LIMIT") {
        limit_price = limit;
        algo_config_data = false;
      } else {
        limit_price = limit;
        algo_config_data = {
          order_type: algo_order_type,
          algo_config: {
            time_window: SWIFTALGO_TIMEWINDOW[algo_config["time_window"]],
            data_points: algo_config["data_points"],
          },
        };
      }

      if (quantity != 0) {
        stocks.push({
          StockCode: symbol,
          Company: h.SecName,
          isin: Isin,
          Quantity: quantity,
          Side: quantity < 0 ? "Sell" : "Buy",
          OrderType: type,
          LMPrice: limit_price,
          AlgoConfig: algo_config_data,
        });
      }
    });

    stocks.sort((a, b) => a.Quantity - b.Quantity);
    stocks.forEach((el) => (el.Quantity = Math.abs(el.Quantity)));

    Axios.post(`${REQUEST_BASE_URL}/broking/order?token=${account_token}`, {
      account_code: account_code,
      stocks: stocks,
    })
      .then((response) => {
        const data = response.data;
        console.log(data);
        Alert({
          TitleText: "Order Routed Successfully!",
          Message: "Please visit transaction section to check your order",
          Band: true,
          BandColor: "#67D098",
          BoxColor: "#ffffff",
          TextColor: "#000000",
          AutoClose: {
            Active: true,
            Line: true,
            LineColor: "#67D098",
            Time: 3,
          },
        });

        setTimeout(() => {
          navigate(`/account/strategy/transaction`, { replace: true });
        }, 1000);
      })
      .catch((error) => {
        Alert({
          TitleText: "Order Routing Failed",
          Message: "Something went wrong while placing the order!",
          Band: true,
          BandColor: "#470985",
          BoxColor: "#ffffff",
          TextColor: "#000000",
          AutoClose: {
            Active: true,
            Line: true,
            LineColor: "#470985",
            Time: 3,
          },
        });
        console.log(error);
      })
      .finally(() => {
        setIsPlacing(false);
        setTrigger(!trigger);
      });
  }

  if (!holdings || !config) {
    return (
      <>
        <div className="portfolio__container empty">
          <Pulse />
        </div>
      </>
    );
  }

  if (holdings.length === 0) {
    return (
      <>
        <div className="portfolio__container empty">
          <Book1 size={40} color={COLOR_VARS["SWIFT_COLOR4"]} />
          <p>Your portfolio is empty</p>
        </div>
      </>
    );
  }

  const {
    portfolio_value,
    total_portfolio_value,
    order_portfolio_value,
    total_investment_value,
    total_profit_loss,
    total_returns,
    change_amount,
    change_percentage,
    cash_percentage,
    portfolio_percentage,
    cash_position,
    buy_value = 0,
    sell_value = 0,
    brokerage_value = 0,
  } = config;

  if (isPlacing) {
    return (
      <>
        <div className="portfolio__container loader">
          <Pulse />
          <p>Routing your order...</p>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="portfolio__options strategy">
        <div className="portfolio__header">
          <div className="portfolio__info__box">
            <PortfolioInvestment
              portfolio_investment={total_investment_value}
            />
          </div>
          <div className="portfolio__info__box">
            <PortfolioValue portfolio_value={total_portfolio_value} />
          </div>
          <div className="portfolio__info__box">
            <PortfolioProfitLoss portfolio_profitloss={total_profit_loss} />
          </div>
          <div className="portfolio__info__box">
            <PortfolioCashPosition cash={cash_position} />
          </div>
          <div className="portfolio__info__box">
            <PortfolioChange
              change_amount={change_amount}
              change_percentage={change_percentage}
            />
          </div>
        </div>
        <div className="portfolio__search__bar">
          <PortfolioSearchBar addTableRow={AddStockToPortfolio} />
        </div>
      </div>

      <div className="portfolio__container">
        {DEVICE_SIZE === "S" && (
          <>
            <p className="title">Positions</p>
          </>
        )}
        <div className="portfolio__table">
          {DEVICE_SIZE === "S" ? (
            <>
              {holdings.map((t, index) => {
                if (!stockConfig[t["Isin"]])
                  return <React.Fragment key={index}></React.Fragment>;
                return (
                  <CustomerPortfolioRow
                    key={index}
                    index={index}
                    DEVICE_SIZE={DEVICE_SIZE}
                    account={account}
                    data={t}
                    config={stockConfig[t["Isin"]]}
                    total_portfolio_value={total_portfolio_value}
                    order_portfolio_value={order_portfolio_value}
                    SetStockConfig={SetStockConfig}
                    RemoveStockPortfolio={RemoveStockPortfolio}
                  />
                );
              })}
            </>
          ) : (
            <>
              <div className="table__content">
                <table>
                  <thead>
                    <tr>
                      <td className="name active">Name</td>
                      <td className="quantity active">Quantity</td>
                      <td className="buyprice">Avg. Buy Price</td>
                      <td className="currprice">Current Price</td>
                      <td className="cost">Investment Cost</td>
                      <td className="currvalue">Current Value</td>
                      <td className="return shrink">Return</td>
                      <td className="today shrink">Today</td>
                      <td className="portweight active shrink">
                        Portfolio Weight
                      </td>
                      <td className="order active">Order</td>
                      <td className="ordertype">Order Type</td>
                      {/* <td className="limitprice">Limit Price</td> */}
                      <td className="tradevol">Amount</td>
                      <td className="deleteorder"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {holdings.map((t, index) => {
                      if (!stockConfig[t["Isin"]])
                        return <React.Fragment key={index}></React.Fragment>;
                      return (
                        <CustomerPortfolioRow
                          key={index}
                          index={index}
                          DEVICE_SIZE={DEVICE_SIZE}
                          account={account}
                          data={t}
                          config={stockConfig[t["Isin"]]}
                          total_portfolio_value={total_portfolio_value}
                          order_portfolio_value={order_portfolio_value}
                          SetStockConfig={SetStockConfig}
                          RemoveStockPortfolio={RemoveStockPortfolio}
                        />
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className="name active"></td>
                      <td className="quantity active"></td>
                      <td className="buyprice"></td>
                      <td className="currprice"></td>
                      <td className="cost">
                        {numberFormat(total_investment_value)}
                      </td>
                      <td className="currvalue">
                        {numberFormat(total_portfolio_value)}
                      </td>
                      <td
                        className={`return shrink ${
                          total_profit_loss > 0 ? "positive" : "negative"
                        }`}
                      >
                        {numberFormat(total_returns)}%
                      </td>
                      <td
                        className={`today shrink ${
                          change_amount > 0 ? "positive" : "negative"
                        }`}
                      >
                        {numberFormat(change_percentage)}%
                      </td>
                      <td className="portweight active shrink">
                        {numberFormat(portfolio_percentage)}%
                      </td>
                      <td className="tradevol"></td>
                      <td className="order active"></td>
                      <td className="deleteorder"></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </>
          )}
        </div>
        <div className="trade__value__section">
          <div className="trade__value">
            <p>
              Total Buy Value: &nbsp;
              <span className="positive">{priceFormat(buy_value)}</span>
            </p>
            <p>
              Total Sell Value: &nbsp;
              <span className="negative">{priceFormat(sell_value)}</span>
            </p>
            <p>
              Approx Brokerage: &nbsp;
              <span>{priceFormat(brokerage_value)}</span>
            </p>
            <p>
              Cash Position Post Trade: &nbsp;
              <span>{priceFormat(cash_position - buy_value + sell_value)}</span>
            </p>
          </div>
          <div className="trade__button">
            {execute ? (
              <>
                <CustomIconButton
                  disabled={buy_value === 0 && sell_value === 0}
                  onClick={PlacePortfolioOrder}
                  icon={<PlayCircle size={20} />}
                  title="Route Order"
                />
              </>
            ) : (
              <>
                <CustomIconButton
                  disabled={buy_value === 0 && sell_value === 0}
                  onClick={PlaceOrder}
                  icon={<TickCircle size={20} />}
                  title="Confirm Routing"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function CustomerPortfolioRow({
  DEVICE_SIZE,
  config,
  data,
  account,
  total_portfolio_value,
  order_portfolio_value,
  SetStockConfig,
  RemoveStockPortfolio,
}) {
  const {
    Token,
    Symbol,
    Qty,
    AvgBuyPrice,
    Isin,
    ClosePrice,
    PriceDivisor,
    editable = false,
  } = data;
  const { order, current_price, order_config } = config;
  const { brokers } = account;

  const { type, algo_order_type, limit, algo_config } = order_config;

  const [orderPopup, setOrderPopup] = useState(false);
  const [movingPrice, setMovingPrice] = useState(0);
  const [typeOpen, setTypeOpen] = useState(false);
  const { stock_info } = useStockInfo(Symbol);
  const stock_data = useMarketStock(Symbol);

  useEffect(() => {
    if (!stock_data) return;
    SetStockConfig(Isin, "current_price", stock_data.last_traded_price);
  }, [stock_data]);

  useEffect(() => {
    if (!stock_info) return;
    const { symbol } = stock_info;
    SetStockConfig(Isin, "symbol", symbol);
  }, [stock_info]);

  useEffect(() => {
    // console.log(type, algo_order_type, limit, algo_config);
    if (algo_order_type === "MARKET" || algo_order_type === "LIMIT") {
      return;
    }
    SetStockConfig(Isin, "order_config", {
      type,
      algo_order_type,
      limit: movingPrice,
      algo_config: algo_config,
    });
  }, [movingPrice]);

  if (!stock_info) return;

  const { exchange } = stock_info;

  function onChangeOrder(event) {
    const value = event.target.value;

    if (/^-?\d*$/.test(value) || value === "") {
      SetStockConfig(Isin, "order", value);
    }
  }

  function onBlurOrder(event) {
    const value = event.target.value;
    if (value === "") {
      SetStockConfig(Isin, "order", 0);
    }
  }

  function onKeyDownOrder(event) {
    let value = event.target.value;

    if (value == "") {
      value = "0";
    }

    value = parseInt(value);

    const keyCode = event.keyCode;

    if (keyCode === 38) {
      event.preventDefault();
      SetStockConfig(Isin, "order", value + 1);
    } else if (keyCode === 40) {
      event.preventDefault();
      SetStockConfig(Isin, "order", value - 1);
    }
  }

  function ModifyOrder(config) {
    let { type, limit_price, algo_config } = config;

    let algo_order_type = type;

    if (algo_order_type === "MARKET") {
      limit_price = 0;
      algo_config = false;
    } else if (algo_order_type === "LIMIT") {
      algo_config = false;
    } else {
      type = "LIMIT";
    }

    SetStockConfig(Isin, "order_config", {
      type,
      algo_order_type,
      limit: limit_price,
      algo_config: algo_config,
    });
  }

  const { symbol, name } = stock_info;
  let last_traded_price = stock_data
    ? stock_data.last_traded_price
    : ClosePrice / PriceDivisor;
  const investment_cost = Qty * AvgBuyPrice;
  const current_value = Qty * last_traded_price;
  const order_amount =
    type === "MARKET" ? order * last_traded_price : order * limit;

  const portfolio_weight = (current_value * 100) / total_portfolio_value;
  const live_portfolio_weight =
    ((order_amount + current_value) * 100) / order_portfolio_value;
  let change_price = stock_data ? parseFloat(stock_data.change_price) : 0;
  let change_percentage = stock_data ? stock_data.change_percentage : "0.00%";

  let returns = (current_value / investment_cost - 1) * 100;

  let price_class = change_price >= 0 ? "positive" : "negative";

  if (DEVICE_SIZE === "S") {
    return (
      <>
        <div
          className="portfolio-table-row"
          onClick={() => {
            setOrderPopup(true);
          }}
        >
          <div className="table-row">
            <div>
              <span>{symbol}</span>
            </div>
            <div>
              <span>{numberFormat(current_value)}</span>
            </div>
          </div>
          <div className="table-row">
            <div>
              <span>{numberFormat(Qty, 0)}</span>
              <span>@&nbsp;{numberFormat(AvgBuyPrice)}</span>
            </div>
            <div>
              <span>{numberFormat(investment_cost)}</span>
              <span className={returns >= 0 ? "positive" : "negative"}>
                {numberFormat(returns)}%
              </span>
            </div>
          </div>
          <div className="table-row">
            <div>
              <span>Wt.</span>
              <span>{numberFormat(portfolio_weight)}%</span>
            </div>
            <div>
              <span>{numberFormat(last_traded_price)}</span>
              <span className={price_class}>{numberFormat(change_price)}</span>
              <span className={price_class}>{change_percentage}</span>
            </div>
          </div>
        </div>

        {orderPopup && (
          <>
            <OrderPopup
              closeModal={() => {
                setOrderPopup(false);
              }}
              config={{
                side: "B",
                symbol: symbol,
                squareoff: false,
              }}
            />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <tr>
        <td className="name active">
          <div>
            <div className="portfolio__stock__code">
              <div className="stock__code">{symbol}</div>
            </div>
            <div className="portfolio__stock__name">{name}</div>
          </div>
        </td>
        <td className="quantity active">
          <div>{numberFormat(Qty, 0)}</div>
        </td>
        <td className="buyprice">
          <div>{numberFormat(AvgBuyPrice)}</div>
        </td>
        <td className="currprice active">
          <div>{numberFormat(last_traded_price)}</div>
        </td>
        <td className="cost">
          <div>{numberFormat(investment_cost)}</div>
        </td>
        <td className="currvalue">
          <div>{numberFormat(current_value)}</div>
        </td>
        <td className="return">
          <div>
            <span className={returns >= 0 ? "positive" : "negative"}>
              {numberFormat(returns)}%
            </span>
          </div>
        </td>
        <td className="today">
          <div>
            <span className={price_class}>{change_percentage}</span>
          </div>
        </td>
        <td className="portweight active">
          <div>
            {numberFormat(portfolio_weight)}%&nbsp;
            {total_portfolio_value !== order_portfolio_value && (
              <>
                <span>({numberFormat(live_portfolio_weight)}%)</span>
              </>
            )}
          </div>
        </td>
        <td className="order">
          <div className="input-box">
            <span
              onClick={() => {
                if (-1 * Qty === order) {
                  SetStockConfig(Isin, "order", 0);
                } else {
                  SetStockConfig(Isin, "order", -1 * Qty);
                }
              }}
            >
              FS
            </span>
            <span
              onClick={() => {
                SetStockConfig(Isin, "order", order - 1);
              }}
            >
              <RemoveIcon fontSize="14" color={COLOR_VARS["SWIFT_COLOR4"]} />
            </span>
            <input
              type="text"
              value={order}
              onChange={(e) => {
                onChangeOrder(e);
              }}
              onBlur={(e) => {
                onBlurOrder(e);
              }}
              onKeyDown={(e) => {
                onKeyDownOrder(e);
              }}
            />
            <span
              onClick={() => {
                SetStockConfig(Isin, "order", order + 1);
              }}
            >
              <AddIcon fontSize="14" color={COLOR_VARS["SWIFT_COLOR4"]} />
            </span>
          </div>
        </td>
        <td className="ordertype">
          {order != 0 ? (
            <div
              className="ordertype-box"
              onClick={() => {
                setTypeOpen(true);
              }}
            >
              <Edit size={14} color={COLOR_VARS["SWIFT_COLOR4"]} />
              <p>
                {titleCase(algo_order_type)}&nbsp; @&nbsp;
                {order == 0 || algo_order_type === "MARKET" ? (
                  numberFormat(current_price)
                ) : algo_config ? (
                  <>
                    <MovingAverage
                      symbol={symbol}
                      current_price={current_price}
                      side={order < 0 ? "SELL" : "BUY"}
                      time_window={algo_config.time_window}
                      data_points={algo_config.data_points}
                      setMovingPrice={setMovingPrice}
                    />
                  </>
                ) : (
                  numberFormat(limit)
                )}
              </p>
            </div>
          ) : (
            <div className="ordertype-box">-</div>
          )}
        </td>

        <td className="tradevol">
          <div
            className={parseFloat(order_amount) >= 0 ? "positive" : "negative"}
          >
            {numberFormat(order_amount)}
          </div>
        </td>
        <td className="delete">
          {editable ? (
            <>
              <div
                className="increase-circle remove"
                onClick={() => {
                  RemoveStockPortfolio(Isin);
                }}
              >
                <Cross size={20} color="#192b40" />
              </div>
            </>
          ) : (
            <></>
          )}
        </td>
      </tr>
      {typeOpen && (
        <>
          <SwiftModal
            top="2%"
            closeModal={() => {
              setTypeOpen(false);
            }}
          >
            <OrderTypePopup
              order={{
                ordertype: algo_order_type,
                code: Token,
                exchange: exchange.exchange,
                quantity: order,
                side: order >= 0 ? "BUY" : "SELL",
                name: symbol,
                broker:
                  Array.isArray(brokers) && brokers.length > 0
                    ? brokers[0].broker
                    : "-",
                limitprice: limit,
                algo_config: algo_config,
              }}
              current_price={last_traded_price}
              ModifyOrder={ModifyOrder}
              setTypeOpen={setTypeOpen}
              closePopup={() => {
                setTypeOpen(false);
              }}
            />
          </SwiftModal>
        </>
      )}
    </>
  );
}

function MovingAverage({
  symbol,
  current_price,
  side,
  time_window,
  data_points,
  setMovingPrice,
}) {
  let movingPrice = useMovingAverage(symbol, side, time_window, data_points);
  movingPrice = movingPrice == 0 ? current_price : movingPrice;
  useEffect(() => {
    setMovingPrice(movingPrice);
  }, [movingPrice]);

  return <>{numberFormat(movingPrice)}</>;
}

export default CustomerPortfolio;
