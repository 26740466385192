import React, { useEffect, useRef } from "react";
import useFullSnapQuote from "../../../../hooks/useFullSnapQuote";
import { numberFormat } from "../../../../exports/UtilFunctions";
import Pulse from "../../../Loader/Pulse";
import { getDeviceSize } from "../../../../exports/InteractiveUtils";

const DEVICE_SIZE = getDeviceSize();

function MarketDepth({ symbol, onClose = false }) {
  const ref = useRef();
  const snapquote = useFullSnapQuote(symbol);

  function GenerateTableData(price_array, quantity_array, customers_array) {
    function isset(val) {
      if (val || val == 0) return val;
      return "-";
    }

    const data = [];
    if (Array.isArray(price_array) && price_array.length != 0) {
      price_array.forEach((p, index) => {
        data.push({
          price: isset(price_array[index]),
          quantity: isset(quantity_array[index]),
          customers: isset(customers_array[index]),
        });
      });
    }

    return data;
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && onClose) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!snapquote) {
    return (
      <div className="order-market-depth loading">
        <Pulse />
      </div>
    );
  }

  const {
    top_5_buyers = [],
    top_5_sellers = [],
    top_5_bid_prices = [],
    top_5_ask_prices = [],
    top_5_bid_quantities = [],
    top_5_ask_quantities = [],
    open_price,
    close_price,
    high_price,
    low_price,
    total_buy_quantity,
    total_sell_quantity,
    average_traded_price,
    volume,
  } = snapquote;

  const buy_data = GenerateTableData(
    top_5_bid_prices,
    top_5_bid_quantities,
    top_5_buyers
  );
  const sell_data = GenerateTableData(
    top_5_ask_prices,
    top_5_ask_quantities,
    top_5_sellers
  );

  return (
    <>
      <div className="order-market" ref={ref}>
        <div className="order-market-depth">
          <div className="order-market-offers">
            <p>OFFERS</p>
            <table>
              <thead>
                <tr>
                  <td>{DEVICE_SIZE === "S" ? "Bid" : "Bid Price"}</td>
                  <td>{DEVICE_SIZE === "S" ? "Qty" : "Quantity"}</td>
                  <td>Buyers</td>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(buy_data) && buy_data.length > 0 ? (
                  <>
                    {buy_data.map((b, index) => {
                      return (
                        <tr key={index}>
                          <td>{numberFormat(b["price"])}</td>
                          <td className="buy_quantity positive">
                            {numberFormat(b["quantity"], 0)}
                          </td>
                          <td>{b["customers"]}</td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
          <div className="separator"></div>
          <div className="order-market-bids">
            <p>BIDS</p>
            <table>
              <thead>
                <tr>
                  <td>{DEVICE_SIZE === "S" ? "Ask" : "Ask Price"}</td>
                  <td>{DEVICE_SIZE === "S" ? "Qty" : "Quantity"}</td>
                  <td>Sellers</td>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(sell_data) && sell_data.length > 0 ? (
                  <>
                    {sell_data.map((s, index) => {
                      return (
                        <tr key={index}>
                          <td>{numberFormat(s["price"])}</td>
                          <td className="sell_quantity negative">
                            {numberFormat(s["quantity"], 0)}
                          </td>
                          <td>{s["customers"]}</td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="order-market-extra">
          <div className="market-extra-column">
            <p>
              <span>Open Price</span>
              <span>{numberFormat(open_price)}</span>
            </p>
            <p>
              <span>Close Price</span>
              <span>{numberFormat(close_price)}</span>
            </p>
            <p>
              <span>High Price</span>
              <span>{numberFormat(high_price)}</span>
            </p>
            <p>
              <span>Low Price</span>
              <span>{numberFormat(low_price)}</span>
            </p>
          </div>
          <div className="market-extra-column">
            <p>
              <span>Total Buy Qty.</span>
              <span className="positive">
                {numberFormat(total_buy_quantity, 0)}
              </span>
            </p>
            <p>
              <span>Total Sell Qty.</span>
              <span className="negative">
                {numberFormat(total_sell_quantity, 0)}
              </span>
            </p>
            <p>
              <span>Average Trade Price</span>
              <span>{numberFormat(average_traded_price)}</span>
            </p>
            <p>
              <span>Volume</span>
              <span>{numberFormat(volume, 0)}</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default MarketDepth;
