import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Navigate } from 'react-router-dom';
import { GetCookie, SetCookie } from '../../exports/CookieManagement';
import '../../css/AccessManagement/AccessManagement.css';
import { BarCode, ArrowRight2 } from '../CustomComponents/SwiftIcon/Icons';
import Pulse from '../Loader/Pulse';
import AccountRow from './AccountRow';
import AccessHeader from './AccessHeader';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function AccessSuperLogin() {

    const [email, setEmail] = useState('');
    const [otp, setOTP] = useState('');
    const [timer, setTimer] = useState(0);
    const [emailError, setEmailError] = useState('');
    const [otpError, setOTPError] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState(false);
    const [otpSent, setOTPSent] = useState(false);
    const [login, setLogin] = useState(false);
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        const counter = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
        return () => clearInterval(counter);
    }, [timer]);

    function sendOTP() {

        if (timer > 0) {
            return;
        }

        if (email === '') {
            setEmailError('Please enter valid email address');
            setOTPError('');
        }
        else {
            setLoading(true);
            setLoadingMessage('Sending one time password...');

            Axios.post(`${REQUEST_BASE_URL}/access/sendotp?email=${email}`).then((response) => {
                const data = response.data;
                if (data.status === 'success') {

                    setTimeout(() => {
                        setEmailError('');
                        setOTPError('');
                        setLoading(false);
                        setLoadingMessage('');
                        setOTPSent(true);
                        setTimer(30);
                    }, 2000)

                }
                else {

                    setEmailError('Account does not exists');
                    setOTPError('');
                    setLoading(false);
                    setLoadingMessage('');
                }
            }).catch((error) => {
                setEmailError('Something went wrong!');
                setOTPError('');
                setLoading(false);
                setLoadingMessage('');

            });

        }
    }

    function validateOtp() {

        if (otp === '') {
            setEmailError('');
            setOTPError('Please enter otp')
        }
        else {
            setLoading(true);
            setLoadingMessage('Validating one time password...');

            Axios.post(`${REQUEST_BASE_URL}/access/validateotp?email=${email}&otp=${otp}&type=USER`).then((response) => {
                const data = response.data;
                if (!data.error) {

                    const token = data.token;
                    setTimeout(() => {
                        setEmailError('');
                        setOTPError('');
                        setOTPSent(true);
                        getAccounts(token);
                        SetCookie('auth_token', token);
                    }, 2000)
                    // setLoading(false);
                    // setLogin(true);


                }
                else {
                    setEmailError('');
                    setOTPError('Entered otp is invalid');
                    setLoading(false);
                    setLoadingMessage('');
                }
            }).catch((error) => {
                setEmailError('');
                setOTPError('Something went wrong!');
                setLoading(false);
                setLoadingMessage('');
            });
        }
    }

    function getAccounts(token) {

        Axios.get(`${REQUEST_BASE_URL}/access/accounts?auth_token=${token}`).then((response) => {
            const data = response.data;

            if (data.error) {
                setOTPError('Something went wrong! Please try again later');
                setLoading(false);
                setLoadingMessage('');
            }
            else {
                const accounts = data.data;
                if (accounts.length === 1) {
                    getCredentials(accounts[0]['account_code']);
                }
                else {
                    setLoadingMessage('Fetching accounts...')
                    setTimeout(() => {
                        setAccounts(accounts);
                        setLoading(false);
                        setLoadingMessage('');
                    }, 2000);
                }
            }

        }).catch((err) => {
            console.log(err);
        });
    }

    function getCredentials(account_code) {

        const auth_token = GetCookie('auth_token');
        Axios.get(`${REQUEST_BASE_URL}/access/credentials?auth_token=${auth_token}&account_code=${account_code}`).then((response) => {
            const data = response.data;
            if (!data.error) {
                setLoading(false);
                setLogin(true);

                SetCookie('token', data.token);
            }
            else {
                setLoading(false);
                setLoadingMessage('');

            }

        }).catch((err) => {
            setLoading(false);
            setLoadingMessage('');

        });
    }

    function emailKeyUp(e) {
        if (e.key === 'Enter') {
            sendOTP();
        }
    }

    function otpKeyUp(e) {
        if (e.key === 'Enter') {
            validateOtp();
        }
    }

    if (login) {
        return <Navigate to="/login" />
    }

    return (
        <div className='user-login'>
            <div className='user-login-popup-wrapper'>
                {loading ?
                    <>
                        <div className='popup-loader'>
                            <Pulse />
                            <p>{loadingMessage}</p>
                        </div>
                    </> :
                    <>
                        <div className='login-header'>
                            <AccessHeader header_text="Super User Login" />
                        </div>
                        {accounts.length > 0 ? <>
                            <div className='login-content'>
                                <div className='login-account-selection'>
                                    <p className='account-text'>Please select an account to continue</p>
                                    <div className='login-accounts'>
                                        {accounts.map((a, index) => {
                                            return <AccountRow key={index} account={a} getCredentials={getCredentials} />
                                        })}

                                    </div>
                                </div>
                            </div>
                        </> : <>
                            <div className='login-content'>
                                <div className='login-body'>
                                    <div className='swift-input-box'>
                                        <p>Email</p>
                                        <input className='swift-input' value={email} onChange={(e) => { setEmail(e.target.value) }} onKeyUp={emailKeyUp} placeholder="Your email address" />
                                        <p className={emailError === '' ? 'error-text hide' : 'error-text'}>{emailError}</p>
                                    </div>
                                    <div className='swift-button-icon' onClick={sendOTP}>
                                        <div className='button-icon'>
                                            <BarCode size={20} />
                                        </div>
                                        <p>Generate OTP</p>
                                    </div>
                                    {otpSent &&
                                        <>
                                            <p className={timer === 0 ? "resend-message hide" : "resend-message"}>Please wait {timer} seconds to resend</p>
                                            <div className='swift-input-box'>
                                                <p>OTP</p>
                                                <input className='swift-input' value={otp} placeholder="123456" onKeyUp={otpKeyUp} maxLength={6} onChange={(e) => { setOTP(e.target.value.replace(/[^\d]/g, '')) }} />
                                                <p className={otpError === '' ? 'error-text hide' : 'error-text'}>{otpError}</p>
                                            </div>
                                        </>
                                    }
                                </div>
                                {otpSent &&
                                    <>
                                        <div className='login-footer'>
                                            <div className='swift-button-icon' onClick={validateOtp}>
                                                <div className='button-icon'>
                                                    <ArrowRight2 size={20}/>
                                                </div>
                                                <p>Submit</p>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </>}
                        
                    </>
                }
            </div>
        </div>
    )
}

export default AccessSuperLogin;