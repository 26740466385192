import React from 'react';
import moment from 'moment';
import OrderSelect from '../OrderSelect';
import OrderLiveData from '../OrderLiveData';

import Warning from '../../../assets/backoffice/warning-3.svg';
import Cross from '../../../assets/icons/cross.svg';

const MARKET_END_HOUR = 23;
const MARKET_END_MINUTE = 15;

class TWTrade extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {};

        this.TableHead = this.TableHead.bind(this);
        this.TableBody = this.TableBody.bind(this);
        this.changeOption = this.changeOption.bind(this);
        this.changeInput = this.changeInput.bind(this);
        this.increaseInput = this.increaseInput.bind(this);
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        const { data } = this.props;
        data.map((d, index) => {
            data[index].ShareSplit = this.setShareSplit(data[index].Quantity, data[index].OrderInterval);
        });
        this.props.calculateCash(data);
    }

    TableHead({ th = [] }) {
        return th.map((v, i) => {
            return <th key={i}>{v}</th>
        })
    }

    TableBody({ td = [], index }) {
        const { data } = this.props;
        return (
            <tr>
                {
                    td.map((v, i) => {

                        return <td key={i}>
                            {
                                (i == 2 || i == 6 || i == 8) ?
                                    <OrderSelect
                                        defaultIndex={i == 2 ? this.getSide().indexOf(v) : i == 6 ? this.getOrderType().indexOf(v) : this.getIntervals().indexOf(v)}
                                        options={i == 2 ? this.getSide() : i == 6 ? this.getOrderType() : this.getIntervals()}
                                        width={75}
                                        placeholder={i == 2 ? "Order Side" : i == 6 ? "Order Type" : "Time Interval"}
                                        height={25}
                                        onTypeChange={(value) => this.changeOption(td[0], i, value)} />

                                    : i == 1 || (i == 7 && td[6] == "Limit") ?
                                        <input value={v}
                                            style={{ width: i == 7 ? 70 : 60, marginLeft: i == 7 ? 2 : 0 }}
                                            onChange={(e) => this.changeInput(index, i, e.target.value)}
                                            onKeyDown={(e) => { this.increaseInput(index, i, e.key) }} />

                                        : i == 3 || i == 4 || i == 5 ?
                                            <OrderLiveData
                                                el={data[index]}
                                                key={td[0]} type={v}
                                                index={index}
                                                setData={this.props.setData} />
                                            : v
                            }
                            {
                                i == 7 && td[6] == "Limit" && data[index].ErrMsg &&
                                <span style={{ position: 'relative' }}>
                                    {
                                        data[index].DisErr &&
                                        <span style={{
                                            position: 'absolute',
                                            top: -15,
                                            left: '-100%',
                                            padding: '0 5px',
                                            borderRadius: '5px',
                                            color: 'red',
                                            background: '#cc9966'
                                        }}>
                                            {data[index].ErrMsg}
                                        </span>
                                    }
                                    <img src={Warning}
                                        width={15}
                                        onMouseOver={() => {
                                            data[index].DisErr = true;
                                            this.setState({ data })
                                        }}
                                        onMouseOut={() => {
                                            data[index].DisErr = false;
                                            this.setState({ data })
                                        }}
                                    />
                                </span>
                            }

                        </td>
                    })
                }
                <td><img width={8} src={Cross} onClick={() => this.props.deleteStock(index)} /></td>
            </tr>
        )
    }

    getSide() {
        return ["Buy", "Sell"];
    }

    getOrderType() {
        return ["Limit", "Market"];
    }

    getIntervals() {
        const intervals = [5, 10, 15, 20];
        let available = [];

        const curr_time = moment();
        const market_end = moment().set('hour', MARKET_END_HOUR).set('minutes', MARKET_END_MINUTE).set('seconds', 0);
        const diff = market_end.diff(curr_time, 'minutes');
        // console.log(diff);

        for (let i = 0; i < intervals.length; i++) {
            if (parseInt(diff / intervals[i]) > 0) {
                available.push(intervals[i]);
            }
        }

        return available;
    }

    changeOption(StockCode, i, value) {
        let data = JSON.parse(JSON.stringify(this.props.data));
        let row = null;
        for (let el of data) {
            if (el.StockCode == StockCode) {
                row = el;
                break;
            }
        }
        if (!row) return;

        if (i == 2) {
            row.LMPrice = row.LTP
            row.LimitPrice = "Current LTP"
            row.Side = value
        }
        else if (i == 6) {
            row.LMPrice = row.LTP
            row.OrderType = value
            row.LimitPrice = "Current LTP"
        }
        else if (i == 8) {
            row.OrderInterval = value
            row.ShareSplit = this.setShareSplit(row.Quantity, value);
        }

        const val = row.Quantity * row.LMPrice
        row.TradeValue = row.Side === "Buy" ? val : -val

        this.props.calculateCash(data)
    }

    changeInput(index, i, value) {
        let data = JSON.parse(JSON.stringify(this.props.data));
        if (i == 1) {
            data[index].Quantity = isNaN(parseInt(value)) ? value.length > 0 ? data[index].Quantity : 0 : parseInt(value);
            data[index].ShareSplit = this.setShareSplit(data[index].Quantity, data[index].OrderInterval);
        }
        else if (i == 7) {
            const regexp = /^[0-9]{0,7}\.?[0-9]{0,2}$/;
            data[index].ErrMsg = null;

            let j = 0, n = value.length;
            while (j < n && value[j] == '0') j++;
            value = value.substring(j);

            if (value.length == 0) {
                data[index].LMPrice = 0;
            } else {
                if (value[0] === '.') {
                    value = '0' + value;
                }
                if (regexp.test(value)) {
                    data[index].LMPrice = value;
                }
            }
        }

        if ((data[index].LMPrice * 1000) % (data[index].TickSize * 1000) != 0) {
            data[index].ErrMsg = `Tick size must be ${data[index].TickSize}`
        }
        if (data[index].LMPrice * 1000 < data[index].LowerLimit * 1000 || data[index].LMPrice * 1000 > data[index].HigherLimit * 1000) {
            data[index].ErrMsg = `Must be in limit [${data[index].LowerLimit}, ${data[index].HigherLimit}]`;
        }

        const val = data[index].Quantity * data[index].LMPrice
        data[index].TradeValue = data[index].Side === "Buy" ? val : -val

        this.props.calculateCash(data);
    }

    increaseInput(index, i, key) {

        if (key === "ArrowUp" || key === "ArrowDown") {

            let data = JSON.parse(JSON.stringify(this.props.data));
            data[index].ErrMsg = null;

            if (i == 1) {
                const quant = data[index].Quantity
                data[index].Quantity = key === "ArrowUp" ? quant + 1 : quant - 1;
                data[index].ShareSplit = this.setShareSplit(data[index].Quantity, data[index].OrderInterval);

            }
            else if (i == 7) {
                const tick = data[index].TickSize * 1000, lm = parseInt(data[index].LMPrice * 1000);
                data[index].LMPrice = key === "ArrowUp" ? parseInt(lm + tick) / 1000 : parseInt(lm - tick) / 1000;
            }

            const val = data[index].Quantity * data[index].LMPrice
            data[index].TradeValue = data[index].Side === "Buy" ? val : -val

            if ((data[index].LMPrice * 1000) % (data[index].TickSize * 1000) != 0) {
                data[index].ErrMsg = `Tick size must be ${data[index].TickSize}`
            }
            if (data[index].LMPrice * 1000 < data[index].LowerLimit * 1000 || data[index].LMPrice * 1000 > data[index].HigherLimit * 1000) {
                data[index].ErrMsg = `Must be in limit [${data[index].LowerLimit}, ${data[index].HigherLimit}]`;
            }

            this.props.calculateCash(data);
        }
    }


    setShareSplit(quantity, interval) {
        const curr_time = moment();
        const market_end = moment().set('hour', MARKET_END_HOUR).set('minutes', MARKET_END_MINUTE).set('seconds', 0);
        const diff = market_end.diff(curr_time, 'minutes');

        let ShareSplit = [];

        const nos = parseInt(diff / interval);

        if (quantity <= 0) {
            ShareSplit = [];
        }
        else if (quantity < nos) {
            ShareSplit.push(quantity);
        }
        else {
            let qpt = parseInt(quantity / nos);
            for (let i = 0; i < nos; i++) {
                ShareSplit.push(qpt);
            }

            let remaining_qty = quantity - (qpt * nos);
            ShareSplit[nos - 1] += remaining_qty;
        }

        return ShareSplit;

    }

    viewShareSplit(interval, shares) {
        if (shares.length > 0) {
            return <span className='view__split' onClick={() => { this.props.openShareSplit(interval, shares) }}>View Split</span>
        }
        else {
            return '-';
        }
    }

    currencyFormat(price) {
        return price.toLocaleString('en-IN', { currency: 'INR', minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }


    render() {
        const { data } = this.props;
        return (
            <div className="getOrder__table">
                <table>
                    <thead>
                        <tr>
                            <this.TableHead th={["StockCode", "Quantity", "Side",
                                "Bid", "Ask", "LTP", "Order Type", "Price", , "Interval",
                                "Trade Value", "Share Split", "Status", ""]} />
                        </tr>
                    </thead>
                    <tbody>

                        {data.length > 0 ? data.map((v, i) =>

                            <this.TableBody key={v.StockCode} td={[
                                v.StockCode, v.Quantity, v.Side, "Bid", "Ask", "LTP", v.OrderType,
                                v.LMPrice, v.OrderInterval, this.currencyFormat(v.TradeValue), this.viewShareSplit(v.OrderInterval, v.ShareSplit), v.Status
                            ]} index={i} />
                        ) : null}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default TWTrade;