import React, { useEffect, useState } from "react";
import moment from "moment";
import { UserSearch } from "../../CustomComponents/SwiftIcon/Icons";
import ServerRequest from "../../../exports/ServerRequest";
import { useNavigate } from "react-router-dom";
import "../../../css/CustomComponents/CustomSearchSelect.css";
import ConfirmBox from "../../CustomComponents/ConfirmBox/ConfirmBox";
import { GetCookie } from "../../../exports/CookieManagement";
import { titleCase } from "../../../exports/UtilFunctions";
import Pulse from "../../Loader/Pulse";

moment.updateLocale("language_code", { invalidDate: "-" });

const CustomerActivation = () => {
  const [data, setData] = useState([]);
  const [change, setChange] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filterData, setFilterData] = useState("");
  const token = GetCookie("admin_token");

  const navigate = useNavigate();

  const ShowConfirmBox = (type, item) => {
    const type_name = type == "active" ? "Activation" : "Deactivation";
    const type_label = type == "active" ? "Activate" : "Deactivate";
    ConfirmBox({
      title: `Customer Account ${type_name}`,
      description: (
        <>
          <>Are you sure you want to {type} the account ?</>
        </>
      ),
      properties: [
        {
          id: "2",
          label: type_label,
          color: "#192b40",
          bgColor: "#ffffff",
          handleFunction: (callback) => {
            handleSubmit(item);
            callback();
          },
        },
      ],
      cancel: true,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data1 = await ServerRequest({
        url: `/admin/customer-activation/get?token=${token}`,
        method: "get",
      });
      if (data1.server_error) {
        navigate("/404", { state: { errorMessage: data1.message } });
      }

      const formattedData = data1.data.filter((d)=>{return d.name != null}).map((item) => {
        // Replace "null" strings with null values
        const sanitizedItem = Object.fromEntries(
          Object.entries(item).map(([key, value]) => [
            key,
            value === "" || value === "null" ? null : value,
          ])
        );

        const date = new Date(sanitizedItem.input_timestamp);
        const ac_date = new Date(sanitizedItem.activation_timestamp);
        const formattedDate = date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
        const formattedac_date = ac_date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        });
        return {
          ...sanitizedItem,
          input_timestamp: formattedDate,
          activation_timestamp: formattedac_date,
        };
      });

      setData(formattedData);

      setLoading(false);
    };
    fetchData();
    return () => {
      console.log("Cleanup");
    };
  }, [change]);

  const handleSubmit = async (item) => {
    setLoading(true);
    let data = item;
    data.status =
      data.status === "deactive"
        ? "active"
        : data.status === "pending"
        ? "active"
        : "deactive";
    console.log(data);

    const data2 = await ServerRequest({
      url: `/admin/customer-activation/update?token=${token}`,
      data: data,
      method: "post",
    });
    if (data2.server_error) {
      navigate("/404", { state: { errorMessage: data2.message } });
    }
    setLoading(false);
    setChange(Math.random());
  };

  const openForm = (item) => {
    navigate(`/admin/form/${item.pan}`);
  };

  const openconfirmPopup = (item) => {
    if (item.status == "Pending") return;
    // setFormData(item);
    ShowConfirmBox(
      item.status === "deactive"
        ? "active"
        : item.status === "pending"
        ? "active"
        : "deactive",
      item
    );
  };

  return !loading ? (
    <div className="swift-account-main open">
      <div className="swift-account-heading">
        <div className="select-search-wrapper">
          <div className="select-search-box">
            <input
              placeholder="Search Customer"
              value={filterData}
              onChange={(e) => setFilterData(e.target.value)}
            />
            <div className="filter-icon">
              <UserSearch size={20} color="#000" />
            </div>
          </div>
        </div>
      </div>

      {data.filter((item) =>
        item.name.toLowerCase().includes(filterData.toLowerCase())
      ).length > 0 ? (
        <div className="admin-wrapper full">
          <div className="admin-history-container admin-activation-container">
            <table>
              <thead>
                <tr>
                  <td className="pan">PAN</td>
                  <td className="mobile">Mobile Number</td>
                  <td className="email">Email Address</td>
                  <td className="name">Name</td>
                  <td className="vertical">Vertical</td>
                  <td className="registration-time">Registration Time</td>
                  <td className="activation-time">Activation Time</td>
                  <td className="status">Status</td>
                  <td className="view"></td>
                  <td className="deactivate"></td>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) =>
                  item.name.toLowerCase().includes(filterData.toLowerCase()) ? (
                    <tr key={index}>
                      <td>{item.pan}</td>
                      <td>{item.mobile}</td>
                      <td>{item.email}</td>
                      <td>{titleCase(item.name)}</td>
                      <td>{titleCase(item.vertical)}</td>
                      <td>
                        {moment(item.input_timestamp).format(
                          "MMM DD, YYYY HH:mm:ss"
                        )}
                      </td>
                      <td>
                        {moment(item.activation_timestamp).format(
                          "MMM DD, YYYY HH:mm:ss"
                        )}
                      </td>

                      <td>{titleCase(item.status)}</td>

                      <td>
                        <button
                          className="swift-account-btn"
                          onClick={() => openForm(item)}
                        >
                          View
                        </button>
                      </td>
                      <td>
                        <button
                          className="swift-account-btn"
                          onClick={() => {
                            openconfirmPopup(item);
                          }}
                        >
                          {item.status === "deactive"
                            ? "Activate"
                            : item.status === "pending"
                            ? "active"
                            : "Deactivate"}
                        </button>
                      </td>
                    </tr>
                  ) : null
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="empty-data-wrapper">
          <p>No Customers Found</p>
        </div>
      )}
    </div>
  ) : (
    <>
      <div className="admin-body-loader">
        <Pulse />
        <p>Loading data</p>
      </div>
    </>
  );
};

export default CustomerActivation;
