import React, { Component } from 'react';
import {readMarketData,readMarketStatus,setChange} from '../../exports/FormatData';
import Axios from 'axios';

const LIVEFEED_BASE_URL = process.env.REACT_APP_LIVEFEED_BASE_URL;
let HeartBeatInterval;

class OrderLiveData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stockCode: null,
            exchange: null,
            ws: null,
            FeedConnection: false,
            stockData: null,
        }

        this.feedLiveData = this.feedLiveData.bind(this)
        this.makeSocketConnection = this.makeSocketConnection.bind(this)
        this.checkConnection = this.checkConnection.bind(this)
    }
    

    componentDidMount(){

        const { el } = this.props
        this.setState({
            stockCode: parseInt(el.Code),
            exchange: parseInt(el.Exchange)
        }, () => {
            // console.log(this.state.stockCode, this.state.exchange)
            this.makeSocketConnection()
                .then(()=>{
                    this.checkConnection();
                })
        });
    }

    componentWillUnmount() {
        if (this.state.ws)
            this.state.ws.send(JSON.stringify({
            "a": "unsubscribe", 
            "v": [[this.state.exchange, this.state.stockCode]], 
            "m": "marketdata"}
        ));

        if (HeartBeatInterval) {
            clearInterval(HeartBeatInterval);
        }
    }

    /*<--- Live Data Feed Methods --->*/
    async makeSocketConnection()
    {
        return new Promise((resolve,reject)=>{
            // console.log('make socket connection')
            let ws = new WebSocket(LIVEFEED_BASE_URL);
            ws.onopen = ()=>{
                // console.log('connection done');
                this.setState({
                    ws : ws,
                    FeedConnection : true
                });
                resolve(ws)
            }
            ws.onerror = ()=>{
                // console.log('Connection Error');
                this.setState({
                    ws : null,
                    FeedConnection : false
                })
            }
        })

       

    }

    checkConnection()
    {
            if(this.state.FeedConnection)
            {
                this.feedLiveData(this.state.ws);
            }    
       
    }

    feedLiveData(ws)
    {
    
        ws.send(JSON.stringify({
            "a": "subscribe", 
            "v": [[this.state.exchange, this.state.stockCode]], 
            "m": "marketdata"}
        ));

        ws.onmessage = (response)=>{
            var reader = new FileReader();
            
            reader.readAsArrayBuffer(response.data);
            let convertedData;
            reader.onloadend = (event) => {
                let data = new Uint8Array(reader.result);
                if(response.data.size >= 86)
                {
                    if(this.state.stockData)
                    {
                        convertedData = readMarketData(data,this.state.stockData['close_price']);
                    }
                    else
                    {
                        convertedData = readMarketData(data,-1);
                    }
    
                    let livedata = convertedData.livedata;
                    this.setState({
                        stockData : livedata
                    });
                }
                else
                {
                    let livedata = this.state.stockData;

                    this.setState({
                        stockData : livedata
                    });
                }

                this.props.setData(this.state.stockData, this.props.el.StockCode, this.props.type)
            }
        }

        HeartBeatInterval = setInterval(() => {
            ws.send(JSON.stringify({
                "a": "h", 
                "v": [[this.state.exchange, this.state.stockCode]], 
                "m": ""}
            ));
        }, 10*1000);

    }

    
    render() {
        const { stockData } = this.state;
        const { type, el } = this.props;


        if(stockData){
            const curr = (price) => price.toLocaleString('en-IN',{ currency: 'INR', minimumFractionDigits: 2, maximumFractionDigits: 2 })
            return (
                <span style={{color: el.Side==="Buy"? '#28947f':'#e51a4b', marginLeft : '0px' , fontWeight : '700' }}>
                    { curr(type==="Bid"? stockData.best_bid_price : type==="Ask"? stockData.best_ask_price : stockData.last_traded_price) }
                </span>
            );
        }
        else
            return(
                <span style={{color: '#4a4a4a' }}>0.00</span>
            )
    }
}

export default OrderLiveData;