import React, { useEffect } from "react";
import useMarketStock from "../../../hooks/useMarketStock";
import { Cross } from "../../CustomComponents/SwiftIcon/Icons";
import SwiftToolTip from "../../CustomComponents/SwiftToolTip/SwiftToolTip";
import CustomValueSelect from "../../Body/CustomChartComponents/CustomValueSelect/CustomValueSelect";
import { numberFormat } from "../../../exports/UtilFunctions";
import ConfirmBox from "../../CustomComponents/ConfirmBox/ConfirmBox";

function ReplaceNoise(value) {
  if (
    !value ||
    value === null ||
    value === undefined ||
    value === "" ||
    parseInt(value) === 0
  ) {
    return "-";
  }
  return value;
}

function OrderItem({
  user_role_type,
  index,
  show,
  setTradeIndex,
  SetBroker,
  RemoveSingleOrder,
  order,
  brokers,
  account_code,
}) {
  const {
    userOrderID: order_id,
    tradeOrderID: trade_id,
    isin,
    stockCode: name,
    instrument_token: code,
    stockName: fullname,
    orderType: ordertype,
    limitPrice: limitprice,
    Side: side,
    Quantity: quantity,
    Status: status,
    broker,
    is_valid,
  } = order;

  const stockData = useMarketStock(name);

  useEffect(() => {
    let last_traded_price = stockData ? stockData.last_traded_price : 0;
    setTradeIndex(index, quantity * last_traded_price, side);
  }, [stockData?.last_traded_price]);

  function RemoveOrder(account_code, trade_id,callback){
    RemoveSingleOrder(account_code, trade_id);
    callback()
  }

  let last_traded_price = stockData ? stockData.last_traded_price : 0;
  let current_price = parseFloat(last_traded_price);
  let order_value = quantity * parseFloat(last_traded_price);

  if (!show) {
    return <></>;
  } else {
    return (
      <>
        <tr>
          <td className="order-item-stock-name">
            {!is_valid && <>
              <SwiftToolTip  title="Mismatched Holdings" placement="bottom-start">
                <span className="order-item-error"></span>
              </SwiftToolTip>
            </>}
            <SwiftToolTip title={fullname} placement="bottom-start">
              <div className="stock-info">
                <span className="stock-name">{name}</span>
                <span className="stock-fullname">{fullname}</span>
              </div>
            </SwiftToolTip>
          </td>
          <td className="order-item-type">{ordertype.toUpperCase()}</td>
          <td className="order-item-limit">{ReplaceNoise(limitprice)}</td>
          <td className="order-item-side">
            <div className={side.toLowerCase()}>{side.toUpperCase()}</div>
          </td>
          <td className="order-item-quantity">{quantity}</td>
          <td className="order-item-current-price">
            {current_price ? numberFormat(current_price) : "-"}
          </td>
          <td className="order-item-current-value">
            {order_value ? numberFormat(order_value) : "-"}
          </td>
          {user_role_type === "BROKER" ? (
            <>
              <td className="order-item-broker">
                <CustomValueSelect
                  value={broker}
                  options={brokers}
                  onChange={(value) => {
                    SetBroker(order_id, isin, value);
                  }}
                />
              </td>
            </>
          ) : (
            <></>
          )}
          <td className="order-item-status">{status}</td>
          {user_role_type === "BROKER" ? (
            <>
              <td className="order-item-remove">
                <SwiftToolTip title={`Remove Trade`} placement="bottom">
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={()=>{
                      ConfirmBox({
                        title: "Remove Trade",
                        description: (
                          <>
                            Are you sure you want to remove the trade?
                          </>
                        ),
                        properties: [
                          {
                            id: "2",
                            label: "Remove",
                            color: "#192b40",
                            bgColor: "#ffffff",
                            handleFunction: RemoveOrder,
                            functionArgs: [account_code,trade_id],
                          },
                        ],
                        cancel: true,
                      });
                    }}

                  >
                    <Cross size={20} color="#192b40" />
                  </div>
                </SwiftToolTip>
              </td>
            </>
          ) : (
            <></>
          )}
        </tr>
      </>
    );
  }
}

export default OrderItem;
