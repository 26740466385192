import React, { useEffect, useState, useRef } from "react";
import {
  Routes,
  Route,
  Navigate,
  NavLink,
  useLocation,
} from "react-router-dom";
import withRouter from "../../hooks/withRouter";

import StrategyDashboard from "./StrategyDashboard";
import "../../css/MenuSection/MenuSection.css";
import "../../css/CustomComponents/CustomChartComponents.css";
import "../../css/StrategyManagement/StrategyManagement.css";
import "../../css/BusinessNews/BusinessNews.css";
import SmallCase from "../Body/MenuSection/SmallCase";
import { GetCookie } from "../../exports/CookieManagement";
import { GetRoleType } from "../../exports/RoleTypes";
import useQueryParams from "../../hooks/useQueryParams";
import useStockInfo from "../../hooks/useStockInfo";
import { AddHistory, GetHistory } from "../../exports/HistoryStorage";
import CustomerPortfolio from "./CustomerPortfolio";
import OrderPopup from "../Body/AppPopups/StockOrderPopup/OrderPopup";
import CustomerTransactions from "./CustomerTransactions";
import CustomerPosition from "./CustomerPosition";
import useAccount from "../../hooks/useAccount";
import MACPortfolio from "./MACPortfolio";
import CustomerPortfolioV2 from "./CustomerPortfolioV2";
import MACTransactions from "./MACTransactions";
import { ArrowDown2, ArrowUp2 } from "../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../exports/ColorVars";

const ROUTE_NAME_MAP = {
  "/account/strategy": "Dashboard",
  "/account/strategy/stock": "Dashboard",
  "/account/strategy/portfolio": "Portfolio",
  "/account/strategy/transaction": "Transactions",
  "/account/strategy/position": "Net Position",
  "/account/strategy/manage": "Strategy Management",
};

function StrategyMenu({ account, user_role_type }) {
  const location = useLocation();
  const { pathname = "" } = location;
  const ref = useRef();

  const [menu, setMenu] = useState("Dashboard");
  const [menuPopup, setMenuPopup] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setMenuPopup(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setMenu(ROUTE_NAME_MAP[pathname]);
  }, []);

  function NavLinkCallback(menu_item) {
    setMenu(menu_item);
    setMenuPopup(false);
  }

  if (!account) return <></>;

  const { isAPI = false } = account;

  return (
    <>
      <div
        className="swift-submenu-item"
        onClick={() => {
          setMenuPopup(!menuPopup);
        }}
      >
        <p>{menu}</p>
        {menuPopup ? (
          <>
            <ArrowUp2 size={18} color={COLOR_VARS["SWIFT_COLOR1"]} />
          </>
        ) : (
          <>
            <ArrowDown2 size={18} color={COLOR_VARS["SWIFT_COLOR1"]} />
          </>
        )}
      </div>
      <div ref={ref} className={"swift-submenu " + (menuPopup ? "active" : "")}>
        <NavLink
          to={`/account/strategy/stock`}
          onClick={() => {
            NavLinkCallback("Dashboard");
          }}
        >
          <p className={pathname === "/account/strategy/stock" ? "active" : ""}>
            Dashboard
          </p>
        </NavLink>

        {user_role_type === "CUSTOMER" && (
          <>
            <NavLink
              to={`/account/strategy/portfolio`}
              onClick={() => {
                NavLinkCallback("Portfolio");
              }}
            >
              <p
                className={
                  pathname === "/account/strategy/portfolio" ? "active" : ""
                }
              >
                Portfolio
              </p>
            </NavLink>
            <NavLink
              to={`/account/strategy/transaction`}
              onClick={() => {
                NavLinkCallback("Transactions");
              }}
            >
              <p
                className={
                  pathname === "/account/strategy/transaction" ? "active" : ""
                }
              >
                Transactions
              </p>
            </NavLink>
          </>
        )}

        {user_role_type === "CUSTOMER" && isAPI && (
          <>
            <NavLink
              to={`/account/strategy/position`}
              onClick={() => {
                NavLinkCallback("Net Position");
              }}
            >
              <p
                className={
                  pathname === "/account/strategy/position" ? "active" : ""
                }
              >
                Net Position
              </p>
            </NavLink>
          </>
        )}
        {user_role_type === "BROKER" && (
          <>
            <NavLink
              to="/account/strategy/manage"
              onClick={() => {
                NavLinkCallback("Strategy Management");
              }}
            >
              <p
                className={
                  pathname === "/account/strategy/manage" ? "active" : ""
                }
              >
                Strategy Management
              </p>
            </NavLink>
          </>
        )}

        {/* <div
          className="swift-submenu-close"
          onClick={() => {
            setMenuPopup(false);
          }}
        >
          <Cross size={36} color={COLOR_VARS["SWIFT_COLOR4"]} />
        </div> */}
      </div>
    </>
  );
}

function StrategyManagement({ pannumber, clientid }) {
  const user_role_type = GetRoleType(GetCookie("role"));

  const { account } = useAccount();
  const { symbol: query_symbol = "" } = useQueryParams();

  const [symbol, setSymbol] = useState(query_symbol);

  const { stock_info } = useStockInfo(symbol);

  useEffect(() => {
    if (!account) return;
    if (query_symbol === "") {
      const { account_code } = account;
      const data = GetHistory(account_code);
      if (!data || !Array.isArray(data) || data.length === 0) {
        setSymbol("HDFCBANK");
      } else {
        setSymbol(data[0]["symbol"]);
      }
    } else {
      setSymbol(query_symbol);
    }
  }, [account, query_symbol]);

  useEffect(() => {
    if (!stock_info) return;
    const { account_code } = account;
    const { symbol, company, exchange } = stock_info;
    AddHistory(account_code, { symbol, company, exchange, type: "STOCKS" });
  }, [stock_info]);

  const [orderAction, setOrderAction] = useState(false);

  function OpenOrderPopup(action) {
    setOrderAction(action);
  }

  const { isAPI = false } = account;

  return (
    <div className="swift-strategy-management swift-content">
      {/* submenu */}
      <div className="swift-submenu-wrapper">
        <StrategyMenu account={account} user_role_type={user_role_type} />
      </div>
      <div className="strategy-content">
        <>
          <Routes>
            <Route
              path="stock"
              element={
                <StrategyDashboard
                  symbol={symbol}
                  OpenOrderPopup={OpenOrderPopup}
                />
              }
            />

            {/* <Route
              path="portfolio"
              element={isAPI ? <CustomerPortfolio /> : <MACPortfolio />}
            /> */}
            <Route
              path="portfolio"
              element={isAPI ? <CustomerPortfolio /> : <CustomerPortfolioV2 />}
            />

            <Route
              path="transaction"
              element={isAPI ? <CustomerTransactions /> : <MACTransactions />}
            />

            <Route path="position" element={<CustomerPosition />} />

            <Route
              path="manage"
              element={<SmallCase pannumber={pannumber} clientid={clientid} />}
            />

            <Route
              path="*"
              element={<Navigate to="/account/strategy/stock" />}
            />
          </Routes>
        </>

        {orderAction && (
          <>
            <OrderPopup
              closeModal={() => {
                setOrderAction(false);
              }}
              config={{
                side: orderAction,
                symbol: symbol,
                squareoff: false,
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default withRouter(StrategyManagement);
