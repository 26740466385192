import React from 'react';
import '../../css/CustomComponents/CustomSelect.css';
import ArrowUp from '../../assets/icons/ArrowUp.svg';
import ArrowDown from '../../assets/icons/ArrowDown.svg';

class OrderSelect extends React.PureComponent {
    

    constructor(props)
    {
        super(props);
        this.state = {
            isListOpen : false,
            title : this.props.options[this.props.defaultIndex] || this.props.title,
            selectedIndex : this.props.defaultIndex,
            placeholder: this.props.placeholder
        }
        this.toggleSelect = this.toggleSelect.bind(this);
        this.closeSelect = this.closeSelect.bind(this);
        this.selectOption = this.selectOption.bind(this);
    }

    componentDidUpdate(prevProps){
        
        // if(this.props.defaultIndex !== prevProps.defaultIndex)
        // {
        //     this.setState({
        //         title : this.props.options[this.props.defaultIndex] || this.props.title
        //     });
        // }

        this.setState({
            title: this.props.options[this.props.defaultIndex] || this.props.title
        })

        const { isListOpen } = this.state;
      
        setTimeout(() => {
            if(isListOpen){
              window.addEventListener('click', this.closeSelect)
            }
            else{
              window.removeEventListener('click', this.closeSelect)
            }
        }, 0)
    }

    toggleSelect()
    {
        this.setState((prevState)=>({
            isListOpen : !prevState.isListOpen
        }))
    }

    selectOption(indx,value)
    {
        this.setState({
            title : value,
            selectedIndex : indx
        },()=>{
            this.toggleSelect();
            this.props.onTypeChange(value)
        });
    }

    closeSelect()
    {
        this.setState({
            isListOpen : false
        })
    }

    render() {

        const {width,height,options,additionalOptions} = this.props;
        const {isListOpen,selectedIndex, placeholder, title} = this.state;

        return (
            <div className="custom-select" style={{width : width , height : height}}>
                <div className="custom-select-header" onClick={()=>{this.toggleSelect()}}>
                    <span className="custom-select-title">{title==null? placeholder:title}</span>
                    <span>
                        {isListOpen ? 
                            <img width={20} height={20} src={ArrowUp} alt=""/> :
                            <img width={20} height={20} src={ArrowDown} alt=""/>
                        }
                    </span>
                </div>
                {isListOpen ? 
                    <div className="custom-select-container">
                        {options.map((option,indx)=>{
                            return <span key={indx} 
                                    className={selectedIndex === indx ? "custom-select-option option-selected" : "custom-select-option"} 
                                    onClick={()=>{
                                        this.selectOption(indx,option)
                                    }}>
                                    {option} <br></br>
                                    {additionalOptions?<span className="custom-select-additional-option">{additionalOptions[indx]}</span>: null}
                                    </span>
                        })}
                    </div>
                    :
                    <></>
                }
            </div>
        )
    }
}

export default OrderSelect;