import React, { useState, useEffect } from "react";
import OrderItem from "./OrderItem";
import {
  ArrowRight2,
  ArrowDown2,
  FilterSearch,
  Cross,
} from "../../CustomComponents/SwiftIcon/Icons";
import CustomParentCheckBox from "../../Body/CustomChartComponents/CustomParentCheckBox/CustomParentCheckBox";
import CustomValueSelect from "../../Body/CustomChartComponents/CustomValueSelect/CustomValueSelect";
import SwiftToolTip from "../../CustomComponents/SwiftToolTip/SwiftToolTip";
import { numberFormat, titleCase } from "../../../exports/UtilFunctions";
import ConfirmBox from "../../CustomComponents/ConfirmBox/ConfirmBox";
import { Alert } from "../../Body/CustomChartComponents/CustomAlert/CustomAlert";

import { COLOR_VARS } from "../../../exports/ColorVars";

function AlertMessage(title, message) {
  Alert({
    TitleText: title,
    Message: message,
    Band: true,
    BandColor: "#E51A4B",
    BoxColor: "#ffffff",
    TextColor: "#000000",
    AutoClose: {
      Active: true,
      Line: true,
      LineColor: "#E51A4B",
      Time: 5,
    },
  });
}

function OrderSection({
  user_role_type,
  OrderID,
  order,
  SetSelectBox,
  SetBrokerGroup,
  SetBroker,
  ExecuteSingleOrder,
  RemoveBulkOrder,
  RemoveSingleOrder,
}) {
  // console.log('OrderSection')
  const [filter, setFilter] = useState("");
  const [trade, setTrade] = useState([]);
  const [totalSellValue, setTotalSellValue] = useState(0);
  const [totalBuyValue, setTotalBuyValue] = useState(0);
  const [totalTradeValue, setTotalTradeValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [tradeBroker, setTradeBroker] = useState("");

  useEffect(() => {
    if (order && order["orders"]) {
      if (
        order &&
        order["config"]["brokers"] &&
        order["config"]["brokers"].length > 0
      ) {
        setTradeBroker(order["config"]["brokers"][0]["broker"]);
      }
    }
  }, [order]);

  useEffect(() => {
    const orders = order.orders;

    const brokers_list = orders.map((o) => {
      return o.broker;
    });
    const broker_set = new Set(brokers_list);
    if (broker_set.size === 1) {
      setTradeBroker(brokers_list[0]);
    } else {
      setTradeBroker("MIXED");
    }
  }, [order.orders]);

  function setTradeIndex(index, value, side) {
    const arr = trade;
    arr[index] = side.toUpperCase() === "SELL" ? -1 * value : value;
    setTrade(arr);

    if (arr.length === 0) return;

    const sum = arr.reduce((a, b) => {
      return a + b;
    });
    const sell_sum = arr.filter((a) => a < 0).reduce((a, b) => a + b, 0);
    const buy_sum = arr.filter((a) => a >= 0).reduce((a, b) => a + b, 0);
    setTotalSellValue(-1 * sell_sum);
    setTotalBuyValue(buy_sum);
    setTotalTradeValue(sum);
  }

  function SubmitSingleOrder(is_valid, order_id) {
    if (!is_valid) {
      AlertMessage(
        "Warning",
        "Kindly fix all the mismatched holdings to execute this trade"
      );
      setOpen(true);
      return;
    }

    ConfirmBox({
      title: "Execute Trades",
      description: <>Are you sure you want to execute the trades?</>,
      properties: [
        {
          id: "2",
          label: "Execute",
          color: "#192b40",
          bgColor: "#ffffff",
          handleFunction: ExecuteOrder,
          functionArgs: [order_id],
        },
      ],
      cancel: true,
    });
  }

  function ToggleCheckBox(is_valid, order_id, value) {
    if (!is_valid) {
      AlertMessage(
        "Warning",
        "Kindly fix all the mismatched holdings to execute this trade"
      );
      setOpen(true);
      return;
    }

    SetSelectBox(order_id, value);
  }

  function ExecuteOrder(order_id, callback) {
    ExecuteSingleOrder(order_id);
    callback();
  }

  function RemoveOrder(account_code, order_id, callback) {
    RemoveBulkOrder(account_code, order_id);
    callback();
  }

  const brokers = order.config.brokers.map((b) => {
    return b.broker;
  });

  return (
    <>
      <div className="order-trades-section">
        <div className="order-trades-header">
          <div className="order-trades-config">
            <div
              className="config-box expand-collapse-box arrow"
              onClick={() => {
                setOpen(!open);
                setFilter("");
              }}
            >
              {open ? (
                <ArrowDown2 size={24} color={COLOR_VARS["SWIFT_COLOR4"]} />
              ) : (
                <ArrowRight2 size={24} color={COLOR_VARS["SWIFT_COLOR4"]} />
              )}
            </div>
            {user_role_type === "BROKER" ? (
              <>
                <div className="config-box select">
                  <CustomParentCheckBox
                    width={18}
                    height={18}
                    isChecked={order.selected}
                    onChangeValue={(value) => {
                      ToggleCheckBox(
                        order.config.is_valid,
                        order.config.order_id,
                        value
                      );
                    }}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="config-box name">
              <span title={titleCase(order.config.name)}>
                {titleCase(order.config.name)}
              </span>
            </div>
            <div className="config-box account">
              <span>
                {order.config.user_role === "MAC"
                  ? "Managed Account"
                  : "Broking Account"}
              </span>
            </div>

            <div className="config-box count">
              <span>{order.config.count}</span>
            </div>
            <div className="config-box sell">
              <span>{numberFormat(totalSellValue)}</span>
            </div>
            <div className="config-box buy">
              <span>{numberFormat(totalBuyValue)}</span>
            </div>
            <div
              className={
                "config-box trade " +
                (totalTradeValue >= 0 ? "positive" : "negative")
              }
            >
              <span>{numberFormat(totalTradeValue)}</span>
            </div>
            {user_role_type === "BROKER" ? (
              <>
                <div className="config-box broker">
                  <CustomValueSelect
                    value={tradeBroker}
                    options={brokers}
                    onChange={(value) => {
                      SetBrokerGroup(order.config.order_id, value);
                    }}
                  />
                </div>
                <div className="config-box execute">
                  <button
                    onClick={() => {
                      SubmitSingleOrder(order.config.is_valid, OrderID);
                    }}
                  >
                    Execute Trades
                  </button>
                </div>
                <div className="config-box remove">
                  <SwiftToolTip title="Remove All Trades" placement="top">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        ConfirmBox({
                          title: "Remove Trades",
                          description: (
                            <>Are you sure you want to remove the trades?</>
                          ),
                          properties: [
                            {
                              id: "2",
                              label: "Remove",
                              color: "#192b40",
                              bgColor: "#ffffff",
                              handleFunction: RemoveOrder,
                              functionArgs: [
                                order.config.account_code,
                                order.config.order_id,
                              ],
                            },
                          ],
                          cancel: true,
                        });
                      }}
                    >
                      <Cross size={24} color={COLOR_VARS["SWIFT_COLOR4"]} />
                    </div>
                  </SwiftToolTip>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div
          className={"order-trades-container-wrapper " + (open ? "" : "hide")}
        >
          <div className="order-trades-options">
            <p>Generated Trades</p>
            <div className="order-trades-search">
              <input
                placeholder="Search symbol"
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value.toUpperCase());
                }}
              />
              <div className="filter-icon">
                <FilterSearch size={20} color={COLOR_VARS["SWIFT_COLOR4"]} />
              </div>
            </div>
          </div>

          <div className="order-trades-container orders">
            <table>
              <thead>
                <tr>
                  {user_role_type === "BROKER" ? (
                    <>
                      <td>Stock Name</td>
                      <td>Order Type</td>
                      <td>Limit Price</td>
                      <td>Order Side</td>
                      <td>Quantity</td>
                      <td>Current Price</td>
                      <td>Amount</td>
                      <td>Broker</td>
                      <td>Status</td>
                      <td></td>
                    </>
                  ) : (
                    <>
                      <td>Stock Name</td>
                      <td>Order Type</td>
                      <td>Limit Price</td>
                      <td>Order Side</td>
                      <td>Quantity</td>
                      <td>Current Price</td>
                      <td>Amount</td>
                      <td>Status</td>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {order["orders"].map((o, index) => {
                  return (
                    <OrderItem
                      key={index}
                      user_role_type={user_role_type}
                      setTradeIndex={setTradeIndex}
                      SetBroker={SetBroker}
                      RemoveSingleOrder={RemoveSingleOrder}
                      index={index}
                      show={o.stockCode.includes(filter)}
                      order={o}
                      brokers={brokers}
                      account_code={order.config.account_code}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderSection;
