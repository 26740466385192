import React, { useEffect, useState } from 'react';
import { GetCookie } from '../../../exports/CookieManagement';
import "../../../css/AdminManagement/AdminDashboard.css"
import ServerRequest from '../../../exports/ServerRequest';
import { useNavigate } from 'react-router-dom';
import { numberFormat } from '../../../exports/UtilFunctions';

function AdminDashboard() {

    const token = GetCookie('admin_token')
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const data1 = await ServerRequest({
                url: `/admin/dashboard-data?token=${token}`,
                method: "get",
            });
            if (data1.server_error) {
                navigate("/404", { state: { errorMessage: data1.message } });
            }
            setData(data1.data);
            setLoading(false);
        };
        fetchData();
    }, []);


    return !loading ? (
        <div className='admin-dashboard-stats-wrapper'>
            <div className='admin-dashboard-stats-container'>
                <div className='admin-dashboard-stats-title'>
                    Snapshot
                </div>
                <div className='admin-dashboard-stats-data'>
                    <div className='admin-dashboard-stats-data-row'>
                        <div className='admin-dashboard-stats-data-object'>
                            <div className='admin-dashboard-stats-data-title'>Total Trade Amount</div>
                            <div className='admin-dashboard-stats-data-value'>₹{numberFormat(data.total_net)}</div>
                        </div>
                        <div className='admin-dashboard-stats-data-object'>
                            <div className='admin-dashboard-stats-data-title'>Total Brokerage</div>
                            <div className='admin-dashboard-stats-data-value'>₹{numberFormat(data.total_brokerage)}</div>
                        </div>
                    </div>
                    <div className='admin-dashboard-stats-data-row'>
                        <div className='admin-dashboard-stats-data-object'>
                            <div className='admin-dashboard-stats-data-title'>Total Accounts</div>
                            <div className='admin-dashboard-stats-data-value'>{numberFormat(data.total_accounts, 0)}</div>
                        </div>
                        <div className='admin-dashboard-stats-data-object'>
                            <div className='admin-dashboard-stats-data-title'>Total Pending Accounts</div>
                            <div className='admin-dashboard-stats-data-value'>{numberFormat(data.total_pending_accounts, 0)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>) : (<></>)

}

export default AdminDashboard;