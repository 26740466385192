import React, { useMemo } from "react";
import moment from "moment";
import { GetCookie } from "../../exports/CookieManagement";
import { useQuery } from "@tanstack/react-query";
import { OrderBook } from "../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../exports/ColorVars";
import Pulse from "../Loader/Pulse";
import { getDeviceSize } from "../../exports/InteractiveUtils";
import useMarketStock from "../../hooks/useMarketStock";
import useStockInfo from "../../hooks/useStockInfo";
import { numberFormat } from "../../exports/UtilFunctions";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;
const DEVICE_SIZE = getDeviceSize();

function FormatTime(time) {
  return moment(time).format("HH:mm:ss");
}

function MACTransactions() {
  const account_token = GetCookie("token");

  async function GetTransactions() {
    const response = await fetch(
      `${REQUEST_BASE_URL}/order/usertransaction?token=${account_token}`
    );
    const data = await response.json();
    return data;
  }

  const { data: data = {} } = useQuery(["transactions"], GetTransactions);
  const { error, data: orders } = data;

  const order_array = useMemo(() => {
    if (!orders || !Array.isArray(orders) || orders.length === 0) return [];

    let o_array = [];
    orders.forEach((o) => {
      o_array.push(...o["transactions"]);
    });

    return o_array;
  }, [orders]);

  console.log(order_array);

  if (
    error === undefined ||
    orders === undefined ||
    order_array === undefined
  ) {
    return (
      <>
        <div className="orders-wrapper full">
          <div className="orders-transactions-empty">
            <Pulse />
          </div>
        </div>
      </>
    );
  }

  if (
    error ||
    !orders ||
    !Array.isArray(orders) ||
    !Array.isArray(order_array) ||
    order_array.length === 0
  ) {
    return (
      <>
        <div className="orders-wrapper full">
          <div className="orders-transactions-empty">
            <OrderBook size={40} color={COLOR_VARS["SWIFT_COLOR4"]} />
            <div>
              <p>No trades found</p>
              <span>Looks like you have't made any trades yet</span>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="orders-wrapper full">
      <div className="orders-transactions-container">
        {DEVICE_SIZE === "S" ? (
          <>
            {order_array.map((o, index) => {
              return (
                <MACTransaction
                  key={index}
                  DEVICE_SIZE={DEVICE_SIZE}
                  data={o}
                />
              );
            })}
          </>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <td>Stock Name</td>
                  <td>Current Price</td>
                  <td>Order Type</td>
                  <td>Limit Price</td>
                  <td>Order Side</td>
                  <td>Quantity</td>
                  <td>Trade Price</td>
                  <td>Trade Amount</td>
                  <td>Transaction Time</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
                {order_array.map((o, index) => {
                  return (
                    <MACTransaction
                      key={index}
                      DEVICE_SIZE={DEVICE_SIZE}
                      data={o}
                    />
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
}

function MACTransaction({ DEVICE_SIZE, data }) {
  const {
    symbol,
    trxnPrice,
    orderType,
    limitPrice,
    Side,
    Quantity,
    Date,
    Status,
  } = data;

  const stock_data = useMarketStock(symbol);
  const { stock_info } = useStockInfo(symbol);

  if (!stock_info) return;

  const { exchange } = stock_info;

  const current_price = stock_data
    ? stock_data["last_traded_price"]
    : trxnPrice;

    const orderPrice = trxnPrice === '' ? (orderType === 'MARKET' ? current_price : limitPrice) : trxnPrice;
  const orderAmount = parseInt(Quantity) * parseFloat(orderPrice);

  if(DEVICE_SIZE === 'S'){
    return <>
      <div className="transaction-row">
        <div className="tran-row">
          <div><span>{Side}</span><span>{Quantity}</span></div>
          <div><span>LTP : {numberFormat(current_price)}</span></div>
        </div>
        <div className="tran-row">
          <div><span>{symbol}</span></div>
          <div><span>@ {numberFormat(orderPrice)}</span></div>
        </div>
        <div className="tran-row">
          <div><span>{orderType}</span></div>
          <div><span>{FormatTime(Date)}</span><span>{Status}</span></div>
        </div>
      </div>
    </>
  }

  return (
    <tr>
      <td>{symbol}</td>
      <td>{numberFormat(current_price)}</td>
      <td>{orderType}</td>
      <td>{orderType === "LIMIT" ? numberFormat(limitPrice) : "-"}</td>
      <td>{Side}</td>
      <td>{Quantity}</td>
      <td>{numberFormat(orderPrice)}</td>
      <td>{numberFormat(orderAmount)}</td>
      <td>{FormatTime(Date)}</td>
      <td>{Status}</td>
    </tr>
  );
}

export default MACTransactions;
