import React, { useEffect, useState } from "react";
import Axios from "axios";
import "../../../../css/Popup/StockOrderPopup.css";
import Pulse from "../../../Loader/Pulse";
import MarketDepth from "./MarketDepth";
import MACSearch from "../../../CustomComponents/MACSearch/MACSearch";
import CustomIconButton from "../../CustomChartComponents/CustomIconButton/CustomIconButton";
import CustomIconActionButton from "../../CustomChartComponents/CustomIconActionButton/CustomIconActionButton";
import AnimatedDigit from "../../AnimatedDigit";
import { Alert } from "../../CustomChartComponents/CustomAlert/CustomAlert";
import PriceBox from "../../CustomChartComponents/PriceBox/PriceBox";
import StockOrderSearch from "./StockOrderSearch";
import CustomCheckBox from "../../CustomChartComponents/CustomCheckBox/CustomCheckBox";
import SearchIcon from "../../../../assets/icons/stocksearch.svg";
import { ArrowRight2, Cross } from "../../../CustomComponents/SwiftIcon/Icons";
import { GetCookie } from "../../../../exports/CookieManagement";
import useMarketStock from "../../../../hooks/useMarketStock";
import useLiveOrderValue from "../../../../hooks/useLiveOrderValue";
import useAccount from "../../../../hooks/useAccount";
import { GetRoleType } from "../../../../exports/RoleTypes";
import { numberFormat } from "../../../../exports/UtilFunctions";
import { COLOR_VARS } from "../../../../exports/ColorVars";
import { getDeviceSize } from "../../../../exports/InteractiveUtils";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;
const DEVICE_SIZE = getDeviceSize();

function StockOrderPopup({ closepopup, symbol, ...order_config }) {
  const user_role = GetCookie("role");
  const user_role_type = GetRoleType(user_role);

  const [loading, setLoading] = useState(false);
  const [executing, setExecuting] = useState("default");
  const [searchOpen, setSearchOpen] = useState(false);

  const [depth,setDepth] = useState(false);

  const [orderConfig, setOrderConfig] = useState({
    stock_symbol: symbol,
    holdings: false,
    side: order_config.side || "B",
    type: order_config.type || "M",
    product: order_config.product || "NRML",
    quantity: order_config.quantity || 0,
  });

  const [tickConfig, setTickConfig] = useState({
    tick_size: 0,
    higher_limit: 0,
    lower_limit: 0,
  });

  const [cashPosition, setCashPosition] = useState(false);
  const [virtualCash, setVirtualCash] = useState(false);
  const [postTrade, setPostTrade] = useState(false);
  const [orderPrice, setOrderPrice] = useState(order_config.orderprice || 0);
  const [triggerPrice, setTriggerPrice] = useState(
    order_config.triggerprice || 0
  );

  const [activityConfig, setActivityConfig] = useState({
    price_active: true,
    trigger_active: true,
  });

  const [errorMessage, setErrorMessage] = useState(false);
  const [errors, setErrors] = useState({});

  const { stock_symbol, side, type, product, quantity, holdings } = orderConfig;
  const { tick_size, higher_limit, lower_limit } = tickConfig;
  const { price_active, trigger_active } = activityConfig;

  const { account } = useAccount();

  const [stockDetails, setStockDetails] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState(false);
  const [isAPI, setIsAPI] = useState(false);

  const { account_code } = customer;

  const stockdata = useMarketStock(stock_symbol);
  const livecash = useLiveOrderValue(account_code);

  async function GetStockDetails(symbol) {
    return new Promise((resolve, reject) => {
      Axios.get(`${REQUEST_BASE_URL}/StockDetails/${symbol}`)
        .then((response) => {
          if (!response.data.error) {
            const data = response.data.stock;

            const {
              isin,
              code,
              ric_code,
              name,
              nse_code,
              bse_code,
              exchange,
              industry,
            } = data;
            setStockDetails({
              stockISIN: isin,
              stockCode: parseInt(code),
              stockSymbol: ric_code,
              stockName: name,
              stockNSECode: nse_code,
              stockBSECode: bse_code,
              stockExchange: exchange,
              stockIndustry: industry,
            });
            resolve(true);
          } else {
            resolve(true);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(true);
        });
    });
  }

  function GetMACustomers(account_token) {
    Axios.get(`${REQUEST_BASE_URL}/portfolio/customers?token=${account_token}`)
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          setCustomers(data.customers);
        }
      })
      .catch((error) => {
        setCustomers([]);
      });
  }

  function LoadOrder() {
    ChangeActivity();
    FetchTickSize().then(() => {
      if (!customer) return;
      GetQuantity();
      GetCashPosition();
    });
    ChangeOrderPrice();
    setSearchOpen(false);
  }

  function ChangeActivity() {
    const { type } = orderConfig;
    let activity = {};

    if (type === "M") {
      activity = { price_active: false, trigger_active: false };
    } else if (type === "L") {
      activity = { price_active: true, trigger_active: false };
    } else if (type === "SL") {
      activity = { price_active: true, trigger_active: true };
    } else if (type === "SLM") {
      activity = { price_active: false, trigger_active: true };
    }

    setActivityConfig(activity);
  }

  async function FetchTickSize() {
    return new Promise((resolve, reject) => {
      if (!stockDetails) resolve(true);

      const { stockExchange, stockCode } = stockDetails;
      Axios.get(
        `${REQUEST_BASE_URL}/GetScripInfo/${stockExchange.exchange}/${stockCode}`
      )
        .then((response) => {
          const data = response.data;
          if (!data.error) {
            const { tick_size, higher_circuit_limit, lower_circuit_limit } =
              data.stock;
            // console.log(tick_size, higher_circuit_limit, lower_circuit_limit);
            setTickConfig({
              tick_size: tick_size,
              higher_limit: higher_circuit_limit,
              lower_limit: lower_circuit_limit,
            });
            resolve(true);
          } else {
            resolve(true);
          }
        })
        .catch((e) => {
          resolve(true);
        });
    });
  }

  function onCustomerSelect(customer) {
    if (!customer || !customer.account_code) return;
    setCustomer(customer);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  async function GetQuantity() {
    if (!stockDetails || !customer) return;

    const ISIN = stockDetails["stockISIN"];
    const { account_code } = customer;

    Axios.get(
      `${REQUEST_BASE_URL}/clientinfo/stockqunatity?account_code=${account_code}&isin=${ISIN}`
    )
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          setOrderConfig({
            ...orderConfig,
            holdings: parseInt(data.quantity),
            quantity: 1,
          });
        }
      })
      .catch((error) => {
        console.log("HERE");
        setOrderConfig({
          ...orderConfig,
          holdings: false,
          quantity: 1,
        });
      });
  }

  function GetCashPosition() {
    if (!customer) return;

    const { account_code } = customer;

    Axios.get(
      `${REQUEST_BASE_URL}/clientinfo/cashposition?account_code=${account_code}`
    )
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          setCashPosition(parseFloat(data.cash_virtual));
        }
      })
      .catch((error) => {
        setCashPosition(0);
      });
  }

  function ChangeOrderPrice() {
    if (!stockdata) return;

    const { type, side } = orderConfig;
    let price;
    if (type === "M" || type === "SLM") {
      if (side === "B") {
        price = parseFloat(stockdata.best_ask_price);
      } else {
        price = parseFloat(stockdata.best_bid_price);
      }

      price = price && !isNaN(price) ? parseFloat(price).toFixed(2) : 0.0;
    } else if (type === "L" || type === "SL") {
      price = orderPrice;
    }
    setOrderPrice(price);
  }

  function ChangePostTradeValues() {
    const { quantity, side } = orderConfig;
    let totaltrade = 0,
      netcash = cashPosition;
    totaltrade = parseFloat(quantity * parseFloat(orderPrice)).toFixed(2);
    totaltrade = isNaN(totaltrade) || totaltrade < 0 ? 0 : totaltrade;
    let cashposition =
      isNaN(cashPosition) || cashPosition < 0 ? 0 : cashPosition;
    let live_cash = isNaN(livecash) ? 0 : livecash;

    const virtual_cash = cashposition - live_cash;

    if (side === "B") {
      netcash = parseFloat(
        parseFloat(virtual_cash) - parseFloat(totaltrade)
      ).toFixed(2);
    } else {
      netcash = parseFloat(
        parseFloat(virtual_cash) + parseFloat(totaltrade)
      ).toFixed(2);
    }

    netcash = isNaN(netcash) ? false : netcash;

    setVirtualCash(virtual_cash);
    setPostTrade({
      netCash: netcash,
      totalTrade: totaltrade ? totaltrade : 0,
    });
  }

  function ChangeSide(side) {
    setLoading(true);
    setTimeout(() => {
      let product = "NRML";
      if (side === "S") {
        product = "CNC";
      }

      setOrderConfig({
        ...orderConfig,
        side: side,
        product: product,
        quantity: 1,
      });
      setLoading(false);
    }, 1000);
  }

  function ShowErrors() {
    const { side, type, quantity, holdings } = orderConfig;
    const { tick_size, higher_limit, lower_limit } = tickConfig;
    const { netCash } = postTrade;

    let error_message = false;
    let q_err = false,
      p_err = false,
      t_err = false;

    if (quantity <= 0 || !IsInt(quantity)) {
      error_message = "Please enter valid quantity";
      q_err = true;
    } else if (side === "S" && quantity > holdings) {
      error_message = `You don't have enough holding in the account`;
      q_err = true;
    } else if (orderPrice <= 0) {
      error_message = "Please enter valid price";
      p_err = true;
    } else if (!IsMultiple(orderPrice, tick_size)) {
      error_message = "Price must be multiple of tick size";
      p_err = true;
    } else if (orderPrice < lower_limit || orderPrice > higher_limit) {
      error_message =
        "Price must be within the valid range (" +
        lower_limit +
        "-" +
        higher_limit +
        ")";
      p_err = true;
    } else if (type === "SL" || type === "SLM") {
      if (triggerPrice <= 0) {
        error_message = "Please enter valid trigger price";
        t_err = true;
      }
    }

    if (netCash != false && !isNaN(netCash) && side === "B" && netCash <= 0) {
      error_message = "Cash margin is not available";
    }

    setErrorMessage(error_message);
    setErrors({
      quantity_error: q_err,
      price_error: p_err,
      trigger_error: t_err,
    });
  }

  function IsMultiple(num1, num2) {
    num1 = parseFloat(parseFloat(num1).toFixed(2) * 100).toFixed(2);
    num2 = parseFloat(parseFloat(num2).toFixed(2) * 100).toFixed(2);

    if (num1 % num2 == 0) {
      return true;
    } else {
      return false;
    }
  }

  function IsInt(num) {
    num = parseFloat(num);
    return Number(num) === num && num % 1 === 0;
  }

  function SelectedStock(stock) {
    if (!stock) return;
    const stock_symbol = stock.symbol;
    setOrderConfig({
      ...orderConfig,
      stock_symbol: stock_symbol,
      side: "B",
      type: "M",
    });
    setTriggerPrice(0);
  }

  function ExecuteOrder() {
    setExecuting("loading");

    const account_token = GetCookie("token");
    const { quantity, side, type } = orderConfig;

    const { stockExchange, stockName, stockISIN, stockNSECode, stockBSECode } =
      stockDetails;
    const exchange = stockExchange.exchange;
    const company = stockName;
    const isin = stockISIN;
    const symbol = exchange === "NSE" ? stockNSECode : stockBSECode;
    let orderside = side === "B" ? "Buy" : "Sell";

    if (!customer) return;

    const { account_code } = customer;

    let order_url = "";

    if (isAPI) {
      order_url = "broking/order";
    } else {
      order_url = "order/single";
    }

    const stocks = [
      {
        AccountCode: account_code,
        StockCode: symbol,
        Company: company,
        isin: isin,
        Quantity: quantity,
        Side: orderside,
        OrderType: GetOrderType(type),
        LMPrice: orderPrice,
      },
    ];

    setTimeout(() => {
      Axios.post(
        `${REQUEST_BASE_URL}/${order_url}?token=${account_token}`,
        { account_code: account_code, stocks: stocks },
        {}
      )
        .then((response) => {
          const data = response.data;
          if (!data.error) {
            Alert({
              TitleText: "Order Routed Successfully!",
              Message: "Please visit transaction section to check your order",
              Band: true,
              BandColor: "#67D098",
              BoxColor: "#ffffff",
              TextColor: "#000000",
              AutoClose: {
                Active: true,
                Line: true,
                LineColor: "#67D098",
                Time: 3,
              },
            });
            setExecuting("success");
          } else {
            Alert({
              TitleText: "Order Routing Failed",
              Message: "Something went wrong while placing the order!",
              Band: true,
              BandColor: "#470985",
              BoxColor: "#ffffff",
              TextColor: "#000000",
              AutoClose: {
                Active: true,
                Line: true,
                LineColor: "#470985",
                Time: 3,
              },
            });
            setExecuting("failure");
          }
        })
        .catch(() => {
          setExecuting("default");
        })
        .finally(() => {
          setTimeout(() => {
            closepopup();
          }, 1500);
        });
    }, 2000);
  }

  function GetOrderType(type) {
    if (type === "M") {
      return "MARKET";
    } else if (type === "L" || type === "SL") {
      return "LIMIT";
    } else {
      return type;
    }
  }

  /* effects */
  useEffect(() => {
    if (user_role_type === "BROKER") {
      const account_token = GetCookie("token");
      GetMACustomers(account_token);
    }
  }, []);

  useEffect(() => {
    if (!customer) return;
    setIsAPI(customer["isAPI"] || false);
  }, [customer]);

  useEffect(() => {
    if (user_role_type === "BROKER") return;
    if (!account) return;
    setCustomer(account);
  }, [account]);

  useEffect(() => {
    GetStockDetails(stock_symbol);
  }, [stock_symbol]);

  useEffect(() => {
    LoadOrder();
  }, [stockDetails, customer]);

  useEffect(() => {
    if (!stockdata) return;
    ChangeOrderPrice();
  }, [stockdata, orderConfig]);

  useEffect(() => {
    ChangePostTradeValues();
  }, [orderPrice, cashPosition, customer, orderConfig, livecash]);

  useEffect(() => {
    ChangeActivity();
  }, [orderConfig]);

  useEffect(() => {
    ShowErrors();
  }, [orderConfig, orderPrice, triggerPrice, postTrade]);

  if (stockDetails && stockdata) {
    const { stockExchange, stockNSECode, stockBSECode } = stockDetails;

    let exchange = stockExchange.exchange;
    let stockname = exchange === "NSE" ? stockNSECode : stockBSECode;

    let bestask = parseFloat(stockdata.best_ask_price).toFixed(2);
    let bestbid = parseFloat(stockdata.best_bid_price).toFixed(2);
    let bestdiff = parseFloat(Math.abs(bestask - bestbid)).toFixed(2);

    const { netCash = 0, totalTrade = cashPosition } = postTrade;

    // console.log(orderConfig);

    return (
      <div className="order-popup">
        <div className="order-header">
          <div className="order-title">
            <div className="title-name">
              <span
                className={
                  side === "B" ? "title-action buy" : "title-action sell"
                }
              >
                {side === "B" ? "BUY" : "SELL"}
              </span>
              <span className="title-symbol">
                {stockname} : {exchange}
              </span>
            </div>
            {DEVICE_SIZE === "S" ? (
              <>
              <div className="title-search" onClick={()=>{closepopup()}}>
                <Cross size={30} color={COLOR_VARS['SWIFT_COLOR4']}  />
              </div>
              </>
            ) : (
              <>
                <div
                  className={
                    searchOpen ? "title-search active" : "title-search"
                  }
                >
                  {searchOpen ? (
                    <StockOrderSearch
                      closeSearchBox={() => setSearchOpen(false)}
                      selectedStock={SelectedStock}
                    />
                  ) : (
                    <img
                      src={SearchIcon}
                      alt=""
                      onClick={() => setSearchOpen(true)}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="order-body">
          <div className="order-setup-section">
            {stockdata ? (
              <div className="order-side">
                <div className="order-side-difference">
                  <AnimatedDigit number={bestdiff} size={28} />
                </div>
                <div
                  className={
                    side === "B" ? "order-side-buy active" : "order-side-buy"
                  }
                  onClick={() => {
                    ChangeSide("B");
                  }}
                >
                  <span>BUY</span>
                  <div className="order-price">{bestask}</div>
                  <div className="order-side-line"></div>
                </div>
                <div
                  className={
                    side === "S" ? "order-side-sell active" : "order-side-sell"
                  }
                  onClick={() => {
                    ChangeSide("S");
                  }}
                >
                  <span>SELL</span>
                  <div className="order-price">{bestbid}</div>
                  <div className="order-side-line"></div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {user_role_type === "BROKER" && (
              <>
                <div className="order-body-row customers">
                  <MACSearch
                    title=""
                    customers={customers}
                    onSelect={(customer) => {
                      onCustomerSelect(customer);
                    }}
                  />
                </div>
              </>
            )}
            {customer && (
              <>
                <div className="order-body-section">
                  {loading || holdings === false ? (
                    <>
                      <div className="order-body-loading">
                        <Pulse />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <div
                    className={
                      side === "B"
                        ? "order-body-row buy"
                        : "order-body-row sell"
                    }
                  >
                    <div
                      className={
                        product === "NRML"
                          ? "order-type-box active"
                          : "order-type-box"
                      }
                      onClick={() => {
                        setOrderConfig({ ...orderConfig, product: "NRML" });
                      }}
                    >
                      Normal Order
                    </div>
                    <div
                      className={
                        product === "CNC"
                          ? "order-type-box active"
                          : "order-type-box"
                      }
                      onClick={() => {
                        setOrderConfig({ ...orderConfig, product: "CNC" });
                      }}
                    >
                      Cash n' Carry
                    </div>
                  </div>
                  <div
                    className={
                      side === "B"
                        ? "order-body-row buy"
                        : "order-body-row sell"
                    }
                  >
                    <div
                      className={
                        type === "M"
                          ? "order-type-box active"
                          : "order-type-box"
                      }
                      onClick={() => {
                        setOrderConfig({ ...orderConfig, type: "M" });
                      }}
                    >
                      Market
                    </div>

                    {/* <div
                      className={
                        type === "SL"
                          ? "order-type-box active"
                          : "order-type-box"
                      }
                      onClick={() => {
                        setOrderConfig({ ...orderConfig, type: "SL" });
                      }}
                    >
                      Stop Loss
                    </div> */}

                    <div
                      className={
                        type === "L"
                          ? "order-type-box active"
                          : "order-type-box"
                      }
                      onClick={() => {
                        setOrderConfig({ ...orderConfig, type: "L" });
                      }}
                    >
                      Limit
                    </div>
                  </div>
                  {side === "S" && (
                    <>
                      <div className="exit-position">
                        <CustomCheckBox
                          isChecked={holdings == quantity}
                          width={20}
                          height={20}
                          disabled={false}
                          onChangeValue={(val) => {
                            setOrderConfig({
                              ...orderConfig,
                              quantity: holdings,
                            });
                          }}
                        />
                        <p>Exit Position</p>
                      </div>
                    </>
                  )}

                  <div className="order-body-row order-box">
                    <PriceBox
                      label="Quantity"
                      helper={"(Holdings : " + holdings + " )"}
                      valid={!errors.quantity_error}
                      step={1}
                      value={quantity}
                      calculator={true}
                      changehandler={(qty) => {
                        setOrderConfig({ ...orderConfig, quantity: qty });
                      }}
                    />
                    <PriceBox
                      enabled={price_active}
                      valid={!errors.price_error}
                      label="Price"
                      step={tick_size}
                      helper={"(Ticksize : " + tick_size + ")"}
                      value={orderPrice}
                      changehandler={(price) => {
                        setOrderPrice(price);
                      }}
                    />
                    {/* <PriceBox
                      enabled={trigger_active}
                      valid={!errors.trigger_error}
                      label="Trigger Price"
                      value={triggerPrice}
                      changehandler={(price) => {
                        setTriggerPrice(price);
                      }}
                    /> */}
                  </div>
                  <div className="order-body-row order-info-button">
                    <p className="order-body-market-depth-button" onClick={()=>{setDepth(!depth)}}>View Market Depth</p>
                  </div>
                  <div className="order-body-row order-config">
                    <div className="order-value">
                      <p>Total Cash Position</p>
                      <p>
                        {numberFormat(cashPosition)}&nbsp;(
                        {numberFormat(virtualCash)})
                      </p>
                    </div>
                    <div className="order-value">
                      <p>Total Trade Value</p>
                      <p>{numberFormat(totalTrade)}</p>
                    </div>
                    <div className="order-value">
                      <p>Available Cash Post Trade</p>
                      <p>{numberFormat(netCash)}</p>
                    </div>
                  </div>
                  
                  <div className="order-body-row">
                    <div className="order-error">
                      <p>{errorMessage}</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="order-section-separator">
            <div></div>
          </div>
          <div className={"order-depth-section " + (depth ? 'active' : '')}>
            <MarketDepth symbol={stock_symbol} onClose={()=>{setDepth(false)}} />
          </div>
        </div>
        {customer && (
          <>
            <div className="order-footer">
              <div className="order-footer-wrap">
                <div className="order-info">
                  {/* <p>Orders will still need to be routed post processing</p> */}
                </div>
                <div className="order-buttons">
                  <CustomIconActionButton
                    action={executing}
                    disabled={errorMessage}
                    icon={
                      <ArrowRight2
                        size={18}
                        color={
                          errorMessage ? "#CCCCCC" : COLOR_VARS["SWIFT_COLOR1"]
                        }
                      />
                    }
                    title="Process Order"
                    loading_title="Processing..."
                    success_title="Completed"
                    failure_title="Failed"
                    onClick={() => {
                      if (errorMessage || executing !== "default") return;
                      ExecuteOrder();
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className="order-popup loading">
        <Pulse />
      </div>
    );
  }
}

export default StockOrderPopup;
