import React from 'react';



function KYCCustomerInfo({ data }) {

    const { pan, customer_type } = data;

    return (
        <div className="client-content-left">
            <p className="kyc-message">Please complete the form to complete KYC <strong>(Know Your Customer)</strong> for the client with given information.</p>
            <div className="client-info">
                <p>Customer Type&nbsp;&nbsp; <span>{customer_type}</span></p>
                <p>Customer PAN&nbsp;&nbsp; <span>{pan}</span></p>
            </div>
        </div>
    )
}

export default KYCCustomerInfo;