import React from 'react';
import Axios from 'axios';
import Pulse from '../Loader/Pulse';
import Theme from '../../assets/icons/theme.svg';
import SearchIcon from '../../assets/icons/search.svg';
import Chess from '../../assets/icons/chess.svg';
import Analysis from '../../assets/icons/analysis.svg';
import BalanceBall from '../../assets/icons/balance-ball.svg';
import SmallCaseIcon from '../../assets/icons/smallcase.svg';
import SettingsGears from '../../assets/icons/settings-gears.svg';
import Objective from '../../assets/icons/objective.svg';
import { SingleLine } from '../Body/MenuSection/SmallCaseComponents/BackTestCharts';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

export class SmallcaseStrategy extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            pannumber: this.props.pannumber,
            active: null,
            allDetails: [],
            details: [],
            selected: null,
        }

        this.TopSection = this.TopSection.bind(this);
        this.StrategyCard = this.StrategyCard.bind(this);
        this.StrategyDetail = this.StrategyDetail.bind(this);
        this.ShowDetails = this.ShowDetails.bind(this);

    }

    componentDidMount() {
        const { pannumber } = this.state;
        Axios.get(`${REQUEST_BASE_URL}/fetch_smallcase?pan=${pannumber}`)
            .then(res => {
                // Axios.post(`${REQUEST_BASE_URL}/fetch_activated`, { pan: pannumber })
                //     .then(d => {
                this.setState({
                    active: "home",
                    details: res.data.details,
                    allDetails: res.data.details,
                    // activated: d.data
                })
                // })
            })
            .catch(err => console.log(err))
    }

    ShowDetails(smallcase_id) {
        const { details } = this.state;
        const indx = details.findIndex(d => d.smallcase_id === smallcase_id);

        if (indx != -1) {
            this.setState({ isLoadingIndex: true })
            Axios.post(`${REQUEST_BASE_URL}/fetch_backtest_result`, { smallcase_id })
                .then(res => {
                    this.setState({
                        selected: indx + 1,
                        index: res.data.datas,
                        stocks: res.data.stocks,
                        isLoadingIndex: false,
                    })
                })
        }
    }

    TopSection() {
        const { allDetails } = this.state
        return <>
            <div className="advisor__smallcase__search__wrapper">
                <div className="advisor__smallcase__search__box">
                    <img src={SearchIcon} alt="Q" />
                    <input type="text" className="input-field" placeholder="Search Smallcase"
                        onChange={(e) => {
                            e = e.target.value
                            this.setState({
                                selected: null,
                                details: e === "" ? allDetails : allDetails.filter(el => el.pName.toLowerCase().includes(e.toLowerCase()))
                                // }, ()=>{
                                //     this.setState({
                                //         selected: this.state.details.length>0? 1: null
                                //     })
                            })
                        }}
                    />
                </div>
            </div>
        </>
    }

    StrategyCard({ smallcase_id, pan, heading, price, news, data = [] }) {
        return <>
            <div className="advisor__smallcase__strategy__card">
                <div className="advisor__strategy__info">
                    {/* <div className="advisor__strategy__icon__box">
                        <img src={Chess} alt="" />
                        <span>Startegy</span>
                    </div> */}
                    <div className="advisor__strategy__headings__box">
                        <p className="advisor__strategy__heading">{heading}</p>
                        <p className="advisor__strategy__news">{news}</p>
                    </div>
                </div>
                <div className="advisor__strategy__data">
                    {/* <div className="advisor__strategy__icon__box">
                        <img src={Analysis} alt="" />
                        <span>Back Test Results</span>
                    </div> */}
                    {/* <div className="advisor__strategy__data__table">
                        {data.map((d, i) => {
                            return <div key={i}>
                                <p className="title">{d.title}</p>
                                <p className="value">{d.value}</p>
                            </div>
                        })}
                    </div> */}
                </div>
                <div className="advisor__strategy__options">
                    <p onClick={() => { this.ShowDetails(smallcase_id) }}>Details</p>
                </div>
            </div>
        </>
    }

    StrategyDetail({ name, details, data, index, stocks, smallcase_id }) {
        return (
            <div className="advisor__smallcase__strategy__details">
                <div className="advisor__strategy__details__box">
                    <div className="details__header">
                        {/* <img src={Chess} alt="" /> */}
                        <span>Strategy</span>
                    </div>
                    <div className="details__content advisor__strategy__name">
                        <p>{name}</p>
                    </div>
                </div>
                <div className='advisor__strategy__changes'>
                    <div className='edit__strategy' onClick={() => this.props.editStrategy(smallcase_id, name)}>
                        Edit Strategy
                    </div>
                    <div className='edit__portfolio' onClick={() => this.props.editPortfolio(smallcase_id, name)}>
                        Edit Portfolio
                    </div>
                </div>
                {details.map((d, i) => (
                    <div key={i} className="advisor__strategy__details__box">
                        <div className="details__header">
                            {/* <img src={d.title.toLowerCase() == 'rebalancing' ? BalanceBall : d.title.toLowerCase() == 'objective' ? Objective : SettingsGears} alt="" /> */}
                            <span>{d.title}</span>
                        </div>
                        <div className="details__content">
                            <p>{d.value}</p>
                        </div>
                    </div>
                ))}

                <div className="advisor__strategy__details__box">
                    <div className="details__header">
                        {/* <img src={Analysis} alt="" /> */}
                        <span>Back Test Results(Past 3 Years)</span>
                    </div>
                    <div className="details__content details__chart">
                        <div className="advisor__strategy__data__table">
                            {data.map((d, i) => {
                                return <div key={i}>
                                    <p className="title">{d.title}</p>
                                    <p className="value">{isNaN(d.value) ? '--' : d.value + "%"}</p>
                                </div>

                            })}
                        </div>
                        {
                            index && index.length > 0 &&
                            <div className='advisor__strategy__chart' >
                                <SingleLine data={index} />
                            </div>
                        }
                    </div>
                </div>
                <div className='advisor__strategy__details__box'>
                    <div className='details__header'>
                        <span>Included Stocks</span>
                    </div>
                    <div>
                        {
                            stocks && stocks.length > 0 &&
                            <div className='advisor__strategy__stocks'>
                                {stocks.map((el, i) =>
                                    <span key={i}>{el}</span>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { active, pannumber, details, allDetails, selected, index, stocks, isLoadingIndex } = this.state;

        return (
            <>
                {!active ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Pulse />
                    </div>
                    :
                    allDetails.length > 0 ?
                        <>
                            <this.TopSection />
                            <div className="advisor__smallcase__body">
                                <>
                                    <div className="advisor__smallcase__strategy__wrapper">
                                        {details.map((el, i) => (
                                            <this.StrategyCard
                                                key={i}
                                                smallcase_id={el.smallcase_id}
                                                price={el.price}
                                                heading={el.pName}
                                                news={el.objective}
                                                pan={el.pan}
                                                index={i + 1}
                                                data={[
                                                    { title: 'Annual Returns', value: el.annReturns },
                                                    { title: 'Annual Risk', value: el.annRisk },
                                                    { title: 'Sharpe Ratio', value: el.sharpeRatio }
                                                ]}
                                            />
                                        ))}


                                    </div>
                                    {isLoadingIndex ?
                                        <div className='advisor__smallcase__strategy__details loader'>
                                            <Pulse />
                                        </div>
                                        :
                                        selected ? <this.StrategyDetail
                                            name={details[selected - 1].pName}
                                            smallcase_id={details[selected-1].smallcase_id}
                                            details={[
                                                { title: 'Rebalancing', value: details[selected - 1].rebalancing_frequency + ", Automatic" },
                                                { title: 'Objective', value: details[selected - 1].objective },
                                                { title: 'Methodology', value: details[selected - 1].methodology }
                                            ]}
                                            data={[
                                                { title: 'Annual Returns', value: details[selected - 1].annReturns },
                                                { title: 'Annual Risk', value: details[selected - 1].annRisk },
                                                { title: 'Sharpe Ratio', value: details[selected - 1].sharpeRatio }
                                            ]}
                                            index={index}
                                            stocks={stocks}
                                        /> :
                                            <div className='advisor__smallcase__strategy__details loader'>
                                                <h2>Smallcase Investing made easy</h2>
                                                <p>Create the smallcases and put them at marketplace</p>
                                                <div className='advisor__create__smallcase' onClick={() => this.props.newsmallcase()}>Create Smallcase</div>
                                            </div>
                                    }
                                </>
                            </div>
                        </>
                        :
                        <p>Gone</p>

                }
            </>
        )
    }
}

export default SmallcaseStrategy;
