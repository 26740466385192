import React, { useEffect, useState } from "react";
import ServerRequest from "../../exports/ServerRequest";
import { GetCookie } from "../../exports/CookieManagement";
import { GetRoleType } from "../../exports/RoleTypes";
import moment from "moment";
import {
  titleCase,
  replaceNull,
  numberFormat,
} from "../../exports/UtilFunctions";
import { getDeviceSize } from "../../exports/InteractiveUtils";
import { Add } from "../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../exports/ColorVars";
import Pulse from "../Loader/Pulse";

function FundsHistory({ historyFlag, setOpenFundsPopup }) {
  const user_role_type = GetRoleType(GetCookie("role"));
  const [history, setHistory] = useState(undefined);

  useEffect(() => {
    async function GetFundsHistory() {
      const token = GetCookie("token");
      const data = await ServerRequest({
        method: "GET",
        url: `/funds/history?token=${token}`,
      });
      return data;
    }

    try {
      GetFundsHistory().then((data) => {
        if (!data.error) {
          setHistory(data.data.reverse());
        } else {
          throw new Error("Invalid Credentials");
        }
      });
    } catch (error) {
      setHistory(false);
    }
  }, [historyFlag]);

  const DEVICE_SIZE = getDeviceSize();

  if (!history || !Array.isArray(history)) {
    return (
      <div className="funds-history loader">
        <Pulse />
      </div>
    );
  }

  if (history.length === 0) {
    return (
      <div className="funds-history">
        {DEVICE_SIZE === "S" ? (
          <>
            <div className="funds-header">
              <p className="funds-title">Funds History</p>
              <div
                onClick={() => {
                  setOpenFundsPopup(true);
                }}
              >
                <Add size={20} color={COLOR_VARS["SWIFT_COLOR1"]} />
              </div>
            </div>
          </>
        ) : (
          <p className="funds-title">Funds History</p>
        )}
        <div className="funds-history-table empty">
          <p>Funds payment history not found</p>
        </div>
      </div>
    );
  }

  return (
    <div className="funds-history">
      {DEVICE_SIZE === "S" ? (
        <>
          <div className="funds-header">
            <p className="funds-title">Funds History</p>
            <div
              onClick={() => {
                setOpenFundsPopup(true);
              }}
            >
              <Add size={20} color={COLOR_VARS["SWIFT_COLOR1"]} />
            </div>
          </div>
        </>
      ) : (
        <p className="funds-title">Funds History</p>
      )}

      <div className="funds-history-table">
        {DEVICE_SIZE === "S" ? (
          <>
            {history.map((h, index) => {
              return (
                <div key={index} className="history-table-row">
                  {user_role_type !== "CUSTOMER" && (
                    <>
                      <p className="customer-name">{titleCase(h.name)}</p>
                    </>
                  )}
                  <div className="table-row">
                    <span>{titleCase(h.request_type)}</span>
                    <span
                      className={
                        h.request_type === "payin" ? "positive" : "negative"
                      }
                    >
                      {numberFormat(h.amount, 2)}
                    </span>
                  </div>
                  <div className="table-row">
                    <span>
                      {moment(h.input_timestamp).format("DD-MM-YYYY")}
                      {h.utr_number ? ` - ${h.utr_number}` : ""}
                    </span>
                    <span>{titleCase(h.status)}</span>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <td>Timestamp</td>
                  {user_role_type === "CUSTOMER" ? (
                    <></>
                  ) : (
                    <td className="customer">Customer Name</td>
                  )}
                  <td>Request Type</td>
                  <td>Broker</td>
                  <td>Amount</td>
                  <td className="utr">UTR Number</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
                {history.map((h, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        {moment(h.input_timestamp).format("DD-MM-YY HH:mm:ss")}
                      </td>
                      {user_role_type === "CUSTOMER" ? (
                        <></>
                      ) : (
                        <td className="customer" title={titleCase(h.name)}>
                          {titleCase(h.name)}
                        </td>
                      )}
                      <td>{titleCase(h.request_type)}</td>
                      <td>{h.broker}</td>
                      <td
                        className={
                          h.request_type === "payin" ? "positive" : "negative"
                        }
                      >
                        {numberFormat(h.amount)}
                      </td>
                      <td className="utr" title={replaceNull(h.utr_number)}>
                        {replaceNull(h.utr_number)}
                      </td>
                      <td>{titleCase(h.status)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
}

export default FundsHistory;
