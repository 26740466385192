import React, { useEffect, useRef, useState } from "react";
import "../../css/StocksManagement/StocksManagement.css";
import StockDetail from "./StockDetail";
import StockIncome from "./StockIncome";
import StockBalance from "./StockBalance";
import StockCash from "./StockCash";
import StockTechnical from "./StockTechnical";
import useQueryParams from "../../hooks/useQueryParams";
import useStockInfo from "../../hooks/useStockInfo";
import ServerRequest from "../../exports/ServerRequest";
import { useNavigate } from "react-router-dom";
import Pulse from "../Loader/Pulse";
import { priceFormat } from "../../exports/UtilFunctions";
import useMarketStock from "../../hooks/useMarketStock";
import StockSearch from "./StockSearch";
import SwiftModal from "../CustomComponents/SwiftModal/SwiftModal";
import StockHeader from "./StockHeader";
import LOGO from "../../assets/images/logo.png";
import StockFooter from "./StockFooter";
import StockChartContainer from "./StockChartContainer";

const StocksManagement = () => {
  const { symbol = "" } = useQueryParams();

  const { stock_info, loading: stockLoading } = useStockInfo(symbol);
  const stock_data = useMarketStock(symbol);
  const [stock, setStock] = useState();
  // console.log(symbol)

  const [snapShot, setSnapShot] = useState(null);
  const controllerRef = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [financial, setFinanacial] = useState(null);
  const [targets, setTargets] = useState(undefined);
  const [news, setNews] = useState(null);
  const [showFeedModal, setshowFeedModal] = useState(false);
  const [showAboutModal, setshowAboutModal] = useState(false);
  const [top, setTop] = useState("");
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (stock_info == false && stockLoading == false) alert("Symbol not valid");
    if (!stock_info) return;
    if (JSON.stringify(stock_info) === JSON.stringify(stock)) return;

    setStock(stock_info);
  }, [stock_info, stockLoading]);

  useEffect(() => {
    if (!stock_info) return;
    setLoading(true);
    SnapShotRequest();
    GetTargets();
    GetNews();

    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(timeout);
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [stock]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      if (window.innerWidth > 1000) {
        setTop("1%");
      } else {
        setTop("0%");
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function formatDate(pubDate) {
    const today = new Date();
    const newsDate = new Date(pubDate);
    const diffTime = Math.abs(today - newsDate);
    const diffSeconds = Math.floor(diffTime / 1000);
    const diffMinutes = Math.floor(diffTime / (1000 * 60));
    const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffSeconds < 60) {
      return `${diffSeconds} second${diffSeconds !== 1 ? "s" : ""} ago`;
    } else if (diffMinutes < 60) {
      return `${diffMinutes} minute${diffMinutes !== 1 ? "s" : ""} ago`;
    } else if (diffHours < 24) {
      return `${diffHours} hour${diffHours !== 1 ? "s" : ""} ago`;
    } else if (diffDays === 1) {
      return "1 day ago";
    } else if (diffDays < 30) {
      return `${diffDays} day${diffDays !== 1 ? "s" : ""} ago`;
    } else if (diffDays < 365) {
      const diffMonths = Math.floor(diffDays / 30);
      return `${diffMonths} month${diffMonths !== 1 ? "s" : ""} ago`;
    } else {
      const diffYears = Math.floor(diffDays / 365);
      return `${diffYears} year${diffYears !== 1 ? "s" : ""} ago`;
    }
  }

  async function SnapShotRequest() {
    // console.log("call")

    if (!stock_info) return;

    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    controllerRef.current = new AbortController();
    const signal = controllerRef.current.signal;

    const { isin, nse_code, bse_code, exchange } = stock_info;
    let exchange_name = exchange.exchange;

    const response = await ServerRequest({
      url: `/detailed_view/snapshot/${symbol}`,
      method: "get",
      signal: signal,
    });

    if (response.server_error) {
      navigate("/404", { state: { errorMessage: response.message } });
    }

    if (response.error == false) {
      const data = response.data;
      setSnapShot(data);
      const snapshotData = data.snapshot;
      const financialData = {
        "Market Cap": priceFormat(snapshotData.MarketCap),
        "High / Low":
          priceFormat(snapshotData.High) +
          " / " +
          priceFormat(snapshotData.Low),
        "Stock P/E": snapshotData.StockPE,
        "Book Value": priceFormat(snapshotData.BookValue),
        "Dividend Yield": `${snapshotData.DividendYield} %`,
        ROCE: `${snapshotData.ROCE} %`,
        ROE: `${snapshotData.ROE} %`,
        "Face Value": priceFormat(snapshotData.FaceValue),
      };
      setFinanacial(financialData);
    } else {
      const financialData = {
        "Market Cap": "--",
        "High / Low": "--",
        "Stock P/E": "--",
        "Book Value": "--",
        "Dividend Yield": "--",
        ROCE: "--",
        ROE: "--",
        "Face Value": "--",
      };
      setSnapShot(false);
      setFinanacial(financialData);
    }
    // const url = `${REQUEST_BASE_URL}/detailed_view/snapshot/${isin}/${nse_code}/${bse_code}/${exchange_name}`;
  }
  async function GetTargets() {
    const { nse_code, bse_code, exchange } = stock_info;
    let exchange_name = exchange.exchange;
    const code = exchange_name === "NSE" ? nse_code : bse_code;

    const response = await ServerRequest({
      url: `/detailed_view/technical/${exchange_name}/${code}`,
      method: "get",
      //  signal: signal,
    });

    if (response.server_error) {
      navigate("/404", { state: { errorMessage: response.message } });
    }
    if (response.status === "success") {
      setTargets(response.targets);
    } else {
      setTargets(false);
    }
  }
  async function GetNews() {
    const { nse_code, bse_code, exchange } = stock_info;
    let exchange_name = exchange.exchange;
    const code = exchange_name === "NSE" ? nse_code : bse_code;

    const response = await ServerRequest({
      url: `/feeds?symbol=${code}`,
      method: "get",
      //  signal: signal,
    });

    if (response.server_error) {
      navigate("/404", { state: { errorMessage: response.message } });
    }
    if (response.data.length > 0 && !response.error) {
      const newsData = response.data.slice(0, 200).map((item) => {
        return {
          news: item.title,
          source: item.source._,
          date: formatDate(item.pubDate),
          link: item.link,
        };
      });
      setNews(newsData);
    } else {
      setNews(false);
    }
  }
  const handleSelect = (stock) => {
    setLoading(true);
    const newUrl = `/stocks?symbol=${stock}`;
    // window.location.href = newUrl;
    navigate(newUrl);
  };
  const closeFeedModal = () => {
    setshowFeedModal(false);
  };
  const openFeedModal = () => {
    setshowFeedModal(true);
  };
  const closeAboutModal = () => {
    setshowAboutModal(false);
  };
  const openAboutModal = () => {
    setshowAboutModal(true);
  };

  const FeedModal = ({ newsData, closeModal }) => {
    return (
      <div className="swift-stock-feed-modal">
        <div className="swift-stock-feed-modal-header">
          <p className="swift-stock-feed-modal-title">Feed</p>
          <div className="swift-account-svg" onClick={closeModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M13.4099 12.0002L19.7099 5.71019C19.8982 5.52188 20.004 5.26649 20.004 5.00019C20.004 4.73388 19.8982 4.47849 19.7099 4.29019C19.5216 4.10188 19.2662 3.99609 18.9999 3.99609C18.7336 3.99609 18.4782 4.10188 18.2899 4.29019L11.9999 10.5902L5.70994 4.29019C5.52164 4.10188 5.26624 3.99609 4.99994 3.99609C4.73364 3.99609 4.47824 4.10188 4.28994 4.29019C4.10164 4.47849 3.99585 4.73388 3.99585 5.00019C3.99585 5.26649 4.10164 5.52188 4.28994 5.71019L10.5899 12.0002L4.28994 18.2902C4.19621 18.3831 4.12182 18.4937 4.07105 18.6156C4.02028 18.7375 3.99414 18.8682 3.99414 19.0002C3.99414 19.1322 4.02028 19.2629 4.07105 19.3848C4.12182 19.5066 4.19621 19.6172 4.28994 19.7102C4.3829 19.8039 4.4935 19.8783 4.61536 19.9291C4.73722 19.9798 4.86793 20.006 4.99994 20.006C5.13195 20.006 5.26266 19.9798 5.38452 19.9291C5.50638 19.8783 5.61698 19.8039 5.70994 19.7102L11.9999 13.4102L18.2899 19.7102C18.3829 19.8039 18.4935 19.8783 18.6154 19.9291C18.7372 19.9798 18.8679 20.006 18.9999 20.006C19.132 20.006 19.2627 19.9798 19.3845 19.9291C19.5064 19.8783 19.617 19.8039 19.7099 19.7102C19.8037 19.6172 19.8781 19.5066 19.9288 19.3848C19.9796 19.2629 20.0057 19.1322 20.0057 19.0002C20.0057 18.8682 19.9796 18.7375 19.9288 18.6156C19.8781 18.4937 19.8037 18.3831 19.7099 18.2902L13.4099 12.0002Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
        <div className="swift-stock-feed-modal-detail">
          {newsData &&
            newsData.length > 0 &&
            newsData.map((item, index) => (
              <div className="swift-stock-feed-modal-detail-rows">
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="swift-stock-feed-modal-news"
                >
                  <p>{item.news}</p>
                </a>
                <p className="swift-stock-feed-modal-date">
                  {item.source} {"- "} {item.date}
                </p>
              </div>
            ))}
          {(!newsData || newsData.length == 0) && <>No Feed</>}
        </div>
      </div>
    );
  };

  const AboutModal = ({ newsData, closeModal }) => {
    return (
      <div className="swift-stock-feed-modal swift-stock-about-modal">
        <div className="swift-stock-feed-modal-header">
          <p className="swift-stock-feed-modal-title">House view</p>
          <div className="swift-account-svg" onClick={closeModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M13.4099 12.0002L19.7099 5.71019C19.8982 5.52188 20.004 5.26649 20.004 5.00019C20.004 4.73388 19.8982 4.47849 19.7099 4.29019C19.5216 4.10188 19.2662 3.99609 18.9999 3.99609C18.7336 3.99609 18.4782 4.10188 18.2899 4.29019L11.9999 10.5902L5.70994 4.29019C5.52164 4.10188 5.26624 3.99609 4.99994 3.99609C4.73364 3.99609 4.47824 4.10188 4.28994 4.29019C4.10164 4.47849 3.99585 4.73388 3.99585 5.00019C3.99585 5.26649 4.10164 5.52188 4.28994 5.71019L10.5899 12.0002L4.28994 18.2902C4.19621 18.3831 4.12182 18.4937 4.07105 18.6156C4.02028 18.7375 3.99414 18.8682 3.99414 19.0002C3.99414 19.1322 4.02028 19.2629 4.07105 19.3848C4.12182 19.5066 4.19621 19.6172 4.28994 19.7102C4.3829 19.8039 4.4935 19.8783 4.61536 19.9291C4.73722 19.9798 4.86793 20.006 4.99994 20.006C5.13195 20.006 5.26266 19.9798 5.38452 19.9291C5.50638 19.8783 5.61698 19.8039 5.70994 19.7102L11.9999 13.4102L18.2899 19.7102C18.3829 19.8039 18.4935 19.8783 18.6154 19.9291C18.7372 19.9798 18.8679 20.006 18.9999 20.006C19.132 20.006 19.2627 19.9798 19.3845 19.9291C19.5064 19.8783 19.617 19.8039 19.7099 19.7102C19.8037 19.6172 19.8781 19.5066 19.9288 19.3848C19.9796 19.2629 20.0057 19.1322 20.0057 19.0002C20.0057 18.8682 19.9796 18.7375 19.9288 18.6156C19.8781 18.4937 19.8037 18.3831 19.7099 18.2902L13.4099 12.0002Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
        <div className="swift-stock-about-modal-detail">
          <div className="swift-stock-about-row-1">
            <div className="swift-stock-about-target">
              <span>3 Yr. </span>
              <p>&nbsp;Target</p>
            </div>
            <div className="swift-stock-about-value">
              <span>Rs.3,050&nbsp;</span>
              <p className="plus">(+35%)</p>
            </div>
          </div>

          <div className="swift-stock-about-row-2">
            <p className="swift-stock-about-desc">Updated on 06/03/2024</p>
            <p className="swift-stock-about-desc">
              Polycab is India’s leading manufacturers of cables and wires and
              allied products such as uPVC conduits and lugs and glands.
            </p>
            <p className="swift-stock-about-desc swift-stock-about-regional">
              Regional Breakup
            </p>
            <p className="swift-stock-about-desc">
              India- 90%
              <br></br>
              USA- 10%.
            </p>
          </div>
          <div className="swift-stock-about-row-2">
            <p className="swift-stock-about-desc">Updated on 06/03/2024</p>
            <p className="swift-stock-about-desc">
              Polycab is India’s leading manufacturers of cables and wires and
              allied products such as uPVC conduits and lugs and glands.
            </p>
            <p className="swift-stock-about-desc swift-stock-about-regional">
              Regional Breakup
            </p>
            <p className="swift-stock-about-desc">
              India- 90%
              <br></br>
              USA- 10%.
            </p>
          </div>
        </div>
      </div>
    );
  };
  const menu_array = [
    {
      title: "",
      options: [
        {
          name: "Overview",
          title: "Overview",
          ref: "overview",
        },
        {
          name: "Chart",
          title: "Chart",
          ref: "chart",
        },
        {
          name: "Income",
          title: "Income Statement",
          ref: "income",
        },
        {
          name: "Balance",
          title: "Balance Sheet",
          ref: "balance",
        },
        {
          name: "Cashflow",
          title: "Cash Flow",
          ref: "caseflow",
        },
        // {
        //   name: "Technical",
        //   title: "Technicals",
        //   ref: "technical"

        // }
      ],
    },
  ];

  return !loading && stock_info && stock_data && financial ? (
    <div className="swift-stock-management-wrapper">
      <div className="swift-stock-header-wrapper">
        <div className="swift-stock-management-header">
          <div className="swift-stock-management-menu-icon">
            <img src={LOGO} alt="" />
          </div>
          <div className="swift-stock-management-header-row-1">
            <StockHeader menu_array={menu_array} />
          </div>

          {width > "768" && <StockSearch handleSelect={handleSelect} />}
        </div>
      </div>
      <div className="swift-stock-container">
        <div className="swift-stock-management-inner">
          {width <= "768" && <StockSearch handleSelect={handleSelect} />}
          <StockDetail
            data={financial}
            stock_data={stock_data}
            technicals={targets}
            newsData={news}
            stock_info={stock_info}
            openAboutModal={openAboutModal}
            openFeedModal={openFeedModal}
          />
          <StockChartContainer symbol={symbol} />
          <StockIncome snapShot={snapShot} />
          <StockBalance snapShot={snapShot} />
          <StockCash snapShot={snapShot} />
          <StockFooter />
        </div>

        <div className="swift-stock-management-footer"></div>
      </div>
      {showFeedModal && (
        <FeedModal newsData={news} closeModal={closeFeedModal} />
      )}
      {showAboutModal && (
        <SwiftModal closeModal={closeAboutModal} top={top}>
          <div>
            <AboutModal newsData={news} closeModal={closeAboutModal} />
          </div>
        </SwiftModal>
      )}
    </div>
  ) : (
    <div className="swift-stock-management-wrapper">
      <div className="loader">
        <Pulse />
        <p>Loading Data </p>
      </div>
    </div>
  );
};

export default StocksManagement;
