import React from "react";
import { numberFormat } from "../../exports/UtilFunctions";

const StockTable = ({ data, header }) => {
  return (
    <div className="stock-management-table">
      <table
        className="stock-management-create-table"
        style={{ fontSize: "10px", marginBottom: 20 }}
      >
        <thead>
          <tr>
            <td></td>

            {header.map((column, index) => (
              <td key={index}>{column}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* Map through the data and create table rows */}
          {data.map((row, index) => (
            <tr key={index}>
              {/* Map through the row data and create table cells */}
              {row.data.map((cell, cellIndex) => (
                <td
                  className={row.isBold ? "cell__bold" : "cell__normal"}
                  key={cellIndex}
                >
                  {isNaN(cell) ? cell : numberFormat(cell,0)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StockTable;
