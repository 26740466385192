import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { GetCookie } from "../../exports/CookieManagement";
import ServerRequest from "../../exports/ServerRequest";

function BackofficeAuth({ children }) {
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    try {
      const token = GetCookie("back_token");

      async function CheckToken(){
        const data = await ServerRequest({
          method: "GET",
          url: `/backoffice/auth`,
        });
        return data;
      }

      if (!token) {
        setLoading(false);
        setRedirect(true);
        return;
      }

      CheckToken().then((data)=>{
        if (!data.error) {
          setLoading(false);
        } else {
          throw new Error("Invalid Credentials");
        }
      });
    
    } catch (e) {
      setLoading(false);
      setRedirect(true);
    }  
  }, []);


  if (loading) {
    return null;
  }
  if (redirect) {
    return <Navigate to="/404" />;
  }
  return children;
}

export default BackofficeAuth;
