import React from 'react';
import Axios from 'axios';
import EmptyOrder from '../../assets/icons/emptyorder.svg';
import OrderItem from './OrderItem';
import Pulse from '../Loader/Pulse';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

class OrderBook extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            pannumber: this.props.pannumber,
            ordersloaded: false,
            orderslist: [],
        }
        this.FetchUserOrders = this.FetchUserOrders.bind(this);
    }

    componentDidMount() {
        this.FetchUserOrders();
    }

    FetchUserOrders() {
        const { pannumber } = this.state;
        this.setState({ ordersloaded: false }, () =>
            Axios.get(`${REQUEST_BASE_URL}/order/userorder?pan=${pannumber}`).then((response) => {
                if (!response.data.error) {

                    let orderslist = response.data.orders;
                    this.setState({
                        ordersloaded: true,
                        orderslist: orderslist,
                    })
                }
            })
                .catch((err) => {
                    console.log(err);
                })
        )
    }

    render() {

        const { pannumber, ordersloaded, orderslist } = this.state;

        if (ordersloaded) {
            if (orderslist.length > 0) {
                return <div className='orderbook__container'>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Limit Price</th>
                                <th>Side</th>
                                <th>Quantity</th>
                                <th>Avg. Fill Price</th>
                                <th>Amount</th>
                                <th>Timestamp</th>
                                <th>Status</th>
                                <th>Modify</th>
                                <th>Cancel</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderslist.map((order, indx) => {
                                return <OrderItem
                                    OpenModifyPopup={this.props.OpenModifyPopup}
                                    FetchUserOrders={this.FetchUserOrders}
                                    order={{
                                        name: order['stockCode'],
                                        portfolio: order['portfolioName'],
                                        fullname: order['stockName'],
                                        ordertype: order['orderType'],
                                        limitprice: order['limitPrice'],
                                        side: order['Side'],
                                        quantity: order['Quantity'],
                                        avgprice: order['trxnPrice'],
                                        grossvalue: order['grossValue'],
                                        status: order['Status'],
                                        omsOrderId: order['omsOrderID'],
                                        timestamp: order['Date'],
                                        clientId: order['ClientID'],
                                        pan: pannumber
                                    }}
                                />
                            })}
                        </tbody>
                    </table>
                </div>;
            }
            else {
                return <div className='orderbook__container empty'>
                    <img src={EmptyOrder} alt="" />
                    <p>We are not able to find any orders</p>
                </div>
            }
        }
        else {
            return <div className='orderbook__container loading'><Pulse /></div>
        }
    }
}

export default OrderBook;
