import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import ServerRequest from "../../exports/ServerRequest";
import { SetCookie } from "../../exports/CookieManagement";
import { ArrowRight2, BarCode } from "../CustomComponents/SwiftIcon/Icons";
import Pulse from "../Loader/Pulse";
import AccessHeader from "../AccessManagement/AccessHeader";

function BackofficeLogin() {
  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [timer, setTimer] = useState(0);
  const [emailError, setEmailError] = useState("");
  const [otpError, setOTPError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [otpSent, setOTPSent] = useState(false);
  const [login, setLogin] = useState(false);

  useEffect(() => {
    const counter = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
    return () => clearInterval(counter);
  }, [timer]);

  async function sendOTP() {
    async function SendLoginOTP(email) {
      const data = await ServerRequest({
        method: "POST",
        url: `/backoffice/sendotpemail`,
        data: {
          email: email,
        },
      });
      return data;
    }

    if (timer > 0) {
      return;
    }

    if (email === "") {
      setEmailError("Please enter valid email address");
      setOTPError("");
    } else {
      setLoading(true);
      setLoadingMessage("Sending one time password...");

      await new Promise((resolve) => setTimeout(resolve, 2000));

      try {
        const data = await SendLoginOTP(email);
        if (data.status === "success") {
          setEmailError("");
          setOTPSent(true);
          setTimer(30);
        } else {
          setEmailError("Account does not exists");
        }
      } catch (error) {
        setEmailError("Something went wrong!");
      } finally {
        setOTPError("");
        setLoading(false);
        setLoadingMessage("");
      }
    }
  }

  async function validateOtp() {
    async function ValidateLoginOTP(email, otp) {
      const data = await ServerRequest({
        method: "POST",
        url: `/backoffice/validateotp`,
        data: {
          email: email,
          otp: otp,
        },
      });
      return data;
    }

    if (otp === "") {
      setEmailError("");
      setOTPError("Please enter otp");
    } else {
      setLoading(true);
      setLoadingMessage("Validating one time password...");

      await new Promise((resolve) => setTimeout(resolve, 2000));

      try {
        const data = await ValidateLoginOTP(email, otp);
        if (!data.error) {
          const token = data.token;
          setOTPError("");
          setOTPSent(true);
          SetCookie("back_token", token);
          setLogin(true);
        } else {
          setOTPError("Entered otp is invalid");
        }
      } catch (error) {
        setOTPError("Something went wrong!");
      } finally {
        setEmailError("");
        setLoading(false);
        setLoadingMessage("");
      }
    }
  }

  function emailKeyUp(e) {
    if (e.key === "Enter") {
      sendOTP();
    }
  }

  function otpKeyUp(e) {
    if (e.key === "Enter") {
      validateOtp();
    }
  }

  if (login) {
    return <Navigate to="/backoffice/dealer" />;
  }

  return (
    <div className="user-login">
      <div className="user-login-popup-wrapper">
        {loading ? (
          <>
            <div className="popup-loader">
              <Pulse />
              <p>{loadingMessage}</p>
            </div>
          </>
        ) : (
          <>
            <div className="login-header">
              <AccessHeader header_text="Backoffice Login" />
            </div>
            <div className="login-content">
              <div className="login-body">
                <div className="swift-input-box">
                  <p>Email</p>
                  <input
                    className="swift-input"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    onKeyUp={emailKeyUp}
                    placeholder="Your email address"
                  />
                  <p
                    className={
                      emailError === "" ? "error-text hide" : "error-text"
                    }
                  >
                    {emailError}
                  </p>
                </div>
                <div className="swift-button-icon" onClick={sendOTP}>
                  <div className="button-icon">
                    <BarCode size={20} />
                  </div>
                  <p>Generate OTP</p>
                </div>
                {otpSent && (
                  <>
                    <p
                      className={
                        timer === 0 ? "resend-message hide" : "resend-message"
                      }
                    >
                      Please wait {timer} seconds to resend
                    </p>
                    <div className="swift-input-box">
                      <p>OTP</p>
                      <input
                        className="swift-input"
                        value={otp}
                        placeholder="123456"
                        onKeyUp={otpKeyUp}
                        maxLength={6}
                        onChange={(e) => {
                          setOTP(e.target.value.replace(/[^\d]/g, ""));
                        }}
                      />
                      <p
                        className={
                          otpError === "" ? "error-text hide" : "error-text"
                        }
                      >
                        {otpError}
                      </p>
                    </div>
                  </>
                )}
              </div>
              {otpSent && (
                <>
                  <div className="login-footer">
                    <div className="swift-button-icon" onClick={validateOtp}>
                      <div className="button-icon">
                        <ArrowRight2 size={20} />
                      </div>
                      <p>Submit</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default BackofficeLogin;
