import React, { useEffect, useState } from "react";
import StockTable from "./StockTable";

const balancesheetCommon = {
  "Share Capital": false,
  Reserves: false,
  "Total Equity": true,
  Borrowings: false,
  "Other Liabilities": false,
  "Total Liabilities": true,
  "Fixed Assets": false,
  CWIP: false,
  Investments: false,
  Inventories: false,
  "Trade receivables": false,
  "Cash Equivalents": false,
  "Loans n Advances": false,
  "Total Assets": true,
};
const balancesheetBank = {
  "Share Capital": false,
  Reserves: false,
  "Total Equity": true,
  Borrowings: false,
  "Other Liabilities": false,
  "Total Liabilities": true,
  "Fixed Assets": false,
  CWIP: false,
  Investments: false,
  "Other Assets": false,
  "Total Assets": false,
};

const StockBalance = ({ snapShot }) => {
  const [data, setData] = useState([]);
  const [header, setHeader] = useState([]);

  useEffect(() => {
    if (!snapShot) return;
    filterData();
  }, [snapShot]);

  function convertNumber(text) {
    const num = parseFloat(text.replace(/,/g, ""));
    if (isNaN(num)) {
      return text;
    }
    return num;
  }

  const filterData = () => {
    const display_data = { ...snapShot["balance_sheet"] };
    let headers = [];
    let data = [];
    let filteredData = [];

    headers = display_data.fields.slice(2);

    filteredData = display_data.values
      .map((row) => {
        const filteredRow = row.map((value) => value.replace(/\+/g, ""));

        return filteredRow.filter((value, index) => {
          if (index === 0) {
            return false;
          }
          const fieldName = row[1].replace(/\s*\+$/, "");
          let res;
          if (snapShot?.type == "banks") {
            res = balancesheetBank.hasOwnProperty(fieldName);
          } else {
            res = balancesheetCommon.hasOwnProperty(fieldName);
          }

          return res;
        });
      })
      .filter((row) => row.length > 0)
      .map((row) => {
        const fieldName = row[0].trim();
        // console.log(fieldName);

        let isBold;
        if (snapShot?.type == "banks") {
          isBold = balancesheetBank[fieldName];
        } else {
          isBold = balancesheetCommon[fieldName];
        }

        return { data: row, isBold };
      })
      .map((row) => {
        // Reverse each row from the second item onwards
        const reversedRow = [...row.data.slice(0, 1), ...row.data.slice(1)];

        // Replace empty or null values with "--"
        const updatedRow = reversedRow.map((value) =>
          value === "" || value === null ? "--" : convertNumber(value)
        );

        return { data: updatedRow, isBold: row.isBold };
      });

    // console.log(headers, filteredData);
    setData(filteredData);
    setHeader(headers);
  };

  return (
    <div className="swift-stock-management-balance" id="balance">
      <div className="swift-stock-management-balance-header">Balance Sheet</div>
      {data.length > 0 && header.length > 0 ? (
        <StockTable data={data} header={header} />
      ) : (
        <>No Data</>
      )}
    </div>
  );
};

export default StockBalance;
