import React, { useEffect, useState } from "react";
import { ChartCanvas, Chart } from "react-stockcharts";
import { AreaSeries, LineSeries } from "react-stockcharts/lib/series";
import { discontinuousTimeScaleProviderBuilder } from "react-stockcharts/lib/scale";
import { format } from "d3-format";
import { splitAdjustment } from "../../exports/SplitAdjustment";
import { tooltipContent } from "../../exports/ChartProps";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import PriceEdgeIndicator from "./CustomChartComponents/EdgeIndicator/PriceEdgeIndicator";
import PriceMarkerCoordinate from "./CustomChartComponents/PriceMarker/PriceMarkerCoordinate";
import { HoverTooltip } from "./CustomChartComponents/HoverTooltip/HoverTooltip";
import LastPointIndicator from "./CustomChartComponents/LastPointEdgeIndicator/LastPointIndicator";
import { lastValidVisibleItemBasedZoomAnchor } from "./CustomChartComponents/ZoomBehaviour/zoomBehaviour";
import { last } from "react-stockcharts/lib/utils";
import { COLOR_VARS } from "../../exports/ColorVars";
import { getDeviceSize } from "../../exports/InteractiveUtils";

function StockChartNew({
  chartDimension,
  chartProps,
  closePrice,
  currentPrice,
}) {
  const [chartConfig, setChartConfig] = useState(false);

  useEffect(() => {
    SetupChart();
  }, [chartProps]);

  useEffect(() => {
    UpdateChart();
  }, [currentPrice]);

  function SetupChart() {
    // console.log("======== CHART SETUP ========", currentPrice);
    const {
      chart_data: input_data = [],
      extra_data = [],
      last_point,
      start_index,
    } = chartProps;

    const split_data = splitAdjustment(input_data);
    const chart_data = split_data.concat(extra_data);
    const LENGTH_TO_SHOW = chart_data.length - start_index;

    // console.log(chart_data)
    // console.log(LENGTH_TO_SHOW)

    const calculated_data = chart_data.slice(-LENGTH_TO_SHOW);
    const accessor_data = calculated_data;

    // console.log(calculated_data)

    const indexCalculator =
      discontinuousTimeScaleProviderBuilder().indexCalculator();
    const { index } = indexCalculator(calculated_data);

    const xScaleProvider =
      discontinuousTimeScaleProviderBuilder().withIndex(index);
    const {
      data: linear_data,
      xScale,
      xAccessor,
      displayXAccessor,
    } = xScaleProvider(accessor_data);

    // console.log(input_data,extra_data,last_point,linear_data)

    const x_start = xAccessor(last(linear_data));
    const x_end = xAccessor(
      linear_data[Math.max(0, linear_data.length - LENGTH_TO_SHOW)]
    );

    const xExtents = [x_start, x_end];
    // console.log("SETUP => ", xExtents);

    setChartConfig({
      api_data: input_data,
      extra_data: extra_data,
      data: linear_data,
      last_point,
      xScale,
      xExtents,
      xAccessor,
      displayXAccessor,
    });
  }

  function UpdateChart() {
    const { data: chart_data } = chartConfig;

    // console.log(chart_data);
    if (!chart_data) return;

    let index = false;

    for (let i = chart_data.length - 1; i >= 0; i--) {
      if (chart_data[i].close) {
        index = i;
        break;
      }
    }

    chart_data[index]["close"] = currentPrice;

    // setChartConfig({
    //   data: linear_data,
    // });
  }

  function HandleLoadMore(start, end) {
    if (Math.ceil(start) === end) return;
    // console.log("rows to download", start, end);

    const { data: prev_data } = chartConfig;
    const { chart_data: input_data = [] } = chartProps;

    if (input_data.length === prev_data.length) return;

    const rows_to_download = end - Math.ceil(start);

    // console.log("rows to download", rows_to_download);

    const data_to_calculate = input_data.slice(
      -rows_to_download - prev_data.length,
      -prev_data.length
    );
    const calculated_data = data_to_calculate;
    const accessor_data = calculated_data
      .slice(-rows_to_download)
      .concat(prev_data);

    const indexCalculator = discontinuousTimeScaleProviderBuilder()
      .initialIndex(Math.ceil(start))
      .indexCalculator();
    const { index } = indexCalculator(accessor_data);

    const xScaleProvider = discontinuousTimeScaleProviderBuilder()
      .initialIndex(Math.ceil(start))
      .withIndex(index);

    const {
      data: linear_data,
      xScale,
      xAccessor,
      displayXAccessor,
    } = xScaleProvider(accessor_data);

    // console.log(linear_data.length,rows_to_download);
    // console.log(last(linear_data))
    // console.log(linear_data.length-1)
    // console.log(Math.max(0, -rows_to_download))
    // console.log(linear_data[Math.max(0, -rows_to_download)])
    // console.log(linear_data)
    // console.log(-rows_to_download,Math.max(0, -rows_to_download),linear_data[-rows_to_download],linear_data[Math.max(0, -rows_to_download)])

    const x_start = xAccessor(last(linear_data));
    const x_end = xAccessor(linear_data[Math.max(0, -rows_to_download)]);

    // console.log(x_start, x_end);

    setTimeout(() => {
      setChartConfig({
        ...chartConfig,
        data: linear_data,
        xScale,
        xAccessor,
        displayXAccessor,
        initialIndex: Math.ceil(start),
      });
    }, 300);
  }

  function GetYExtents(range, high, low) {
    if (range === "1Y") {
      return [high + high * (10 / 100), low - low * (10 / 100)];
    }
    return [high + high * (1 / 100), low - low * (1 / 100)];
  }

  const DEVICE_SIZE = getDeviceSize();

  const { range, last_point } = chartProps;
  const { width, height } = chartDimension;
  const {
    data = false,
    xScale,
    xAccessor,
    displayXAccessor,
    xExtents,
  } = chartConfig;

  if (!data) return <></>;

  const yExtents = (d) => GetYExtents(range, d.high, d.low);

  const padding = { left: 0, right: 0, top: 0, bottom: 0 };
  const margin = {
    left: 0,
    right: DEVICE_SIZE === "S" ? 0 : 10,
    top: 0,
    bottom: 0,
  };

  var gridHeight = height - margin.top - margin.bottom;
  var gridWidth = width - margin.left - margin.right;

  const showGrid = true;

  const yGrid = showGrid
    ? {
        innerTickSize: -1 * gridWidth,
        tickStrokeDasharray: "Solid",
        tickStrokeOpacity: 0.25,
        tickStrokeWidth: 0.1,
      }
    : {};

  const xGrid = showGrid
    ? {
        innerTickSize: -1 * gridHeight,
        tickStrokeDasharray: "Solid",
        tickStrokeOpacity: 0.25,
        tickStrokeWidth: 0.1,
      }
    : {};

  return (
    <div>
      <ChartCanvas
        key={range}
        width={width}
        height={height}
        ratio={1}
        margin={margin}
        seriesName="MAFANG"
        xScale={xScale}
        xAccessor={xAccessor}
        displayXAccessor={displayXAccessor}
        xExtents={xExtents}
        data={data}
        type="svg"
        panEvent={DEVICE_SIZE === 'S' ? false: true}
        zoomEvent={DEVICE_SIZE === 'S' ? false: true}
        onLoadMore={HandleLoadMore}
        zoomAnchor={lastValidVisibleItemBasedZoomAnchor}
      >
        <Chart
          id={1}
          padding={padding}
          yExtents={yExtents}
          origin={[0, 0]}
          height={height}
        >
          <ChartComponents
            DEVICE_SIZE={DEVICE_SIZE}
            range={range}
            closePrice={closePrice}
            xGrid={xGrid}
            yGrid={yGrid}
          />
        </Chart>
      </ChartCanvas>
    </div>
  );
}

function ChartComponents({ DEVICE_SIZE, range, closePrice, xGrid, yGrid }) {
  const fillcolor = "#3179F5";
  const close = (d) => d.close;
  return (
    <>
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          {/* <stop offset="5%" stopColor={fillcolor} stopOpacity={0.1} /> */}
          {/* <stop offset="95%" stopColor={fillcolor} stopOpacity={0} /> */}
        </linearGradient>
      </defs>
      <AreaSeries
        yAccessor={close}
        strokeWidth={DEVICE_SIZE === "S" ? 2 : 2}
        stroke={fillcolor}
        opacity={0.3}
        fill="url(#colorUv)"
      />

      <LastPointIndicator
        yAccessor={close}
        displayFormat={format(".4s")}
        radius={DEVICE_SIZE === "S" ? 6 : 3}
        fill={fillcolor}
      />

      <PriceMarkerCoordinate
        at="left"
        orient="right"
        price={closePrice}
        displayFormat={format(".2f")}
        strokeDasharray="Dash"
        dx={20}
        fill="#d8d8d8"
        textFill={COLOR_VARS["SWIFT_COLOR4"]}
        lineStroke="#404040"
        lineOpacity={0.15}
        rectWidth={65}
        rectHeight={22}
        fontSize={11}
        fontWeight="700"
        opacity={0.6}
      />

      <HoverTooltip
        tooltipContent={tooltipContent(range)}
        fontSize={11}
        bgOpacity={0}
        fill="#ffffff"
        opacity={1}
        bgrx={15}
        stroke="none"
        isLabled={false}
        isInline={true}
      />

      {DEVICE_SIZE != "S" && (
        <>
          <YAxis axisAt="left" orient="left" {...yGrid} />
          <YAxis
            axisAt="right"
            orient="left"
            showTicks={true}
            showTickLabel={true}
            showDomain={false}
            outerTickSize={0}
            innerTickSize={0}
            fontSize={12}
            ticks={10}
            tickPadding={4}
            tickStroke={COLOR_VARS["SWIFT_COLOR4"]}
          />
          <PriceEdgeIndicator
            orient="left"
            edgeAt="right"
            itemType="last"
            yAccessor={(d) => d.close}
            displayFormat={format(".2f")}
            arrowWidth={0}
            fill="#efefef"
            fontSize={11}
            rectWidth={70}
            rectHeight={22}
            textFill={COLOR_VARS["SWIFT_COLOR4"]}
            strokeWidth={3}
            lineOpacity={0}
            opacity={1}
            rectRadius={14}
            fontWeight="700"
            dx={1}
          />
        </>
      )}

      <XAxis
        axisAt="bottom"
        orient="top"
        showTicks={true}
        showTickLabel={true}
        showDomain={false}
        outerTickSize={0}
        innerTickSize={0}
        fontSize={12}
        fontWeight={500}
        ticks={DEVICE_SIZE == "S" ? 4 : 6}
        tickPadding={6}
        fill="red"
        opacity={1}
        tickStroke={COLOR_VARS["SWIFT_COLOR4"]}
        {...xGrid}
      />
    </>
  );
}

export default StockChartNew;
