import React from "react";
import Statistics from "./Statistics";
import Pulse from "../../Loader/Pulse";
import useMarketStock from "../../../hooks/useMarketStock";

function KeyStatistics({ symbol }) {
    
  function PriceFormat(num, frac = 2) {
    if (num) {
      return num.toLocaleString("en-IN", {
        minimumFractionDigits: frac,
        currency: "INR",
      });
    } else {
      return num;
    }
  }

  const stock_data = useMarketStock(symbol);

  if (stock_data) {
    return (
      <>
        <div className="key__statistics">
          <div className="ks__container">
            <div className="ks__container__half">
              <Statistics
                type="number"
                name="Previous Close"
                value={PriceFormat(stock_data.close_price)}
              />
              <Statistics
                type="number"
                name="Open Price"
                value={PriceFormat(stock_data.open_price)}
              />
              <Statistics
                type="range"
                name="Day Range"
                value={[
                  PriceFormat(stock_data.low_price),
                  PriceFormat(stock_data.high_price),
                ]}
              />
              <Statistics
                type="range"
                name="52-week Range"
                value={[
                  PriceFormat(stock_data.yearly_low_price),
                  PriceFormat(stock_data.yearly_high_price),
                ]}
              />
              <Statistics
                type="number"
                name="Avg. Trade Price"
                value={PriceFormat(stock_data.average_trade_price, 0)}
              />
              <Statistics
                type="number"
                name="Last Traded Quantity"
                value={PriceFormat(
                  stock_data.last_traded_quantity,
                  0
                )}
              />
              <Statistics
                type="number"
                name="Total Buy Qunatity"
                value={PriceFormat(stock_data.total_buy_quantity, 0)}
              />
              <Statistics
                type="number"
                name="Total Sell Qunatity"
                value={PriceFormat(stock_data.total_sell_quantity, 0)}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="key__statistics loader">
        <Pulse />
      </div>
    );
  }
}

export default KeyStatistics;
