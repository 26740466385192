import React from 'react';
import '../../css/Footer/Footer.css';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function Footer() {
    return (
        <div className='swift-footer'>
            <p className='copyright-text'>All rights reserved Copyright @ Yottol Fintech Private Limited</p>
            <div className='swift-social'>
                <a target="_blank" href="mailto:shout@yottol.com"><EmailOutlinedIcon style={{ fontSize: '20px', color: '#CCCCCC' }} /></a>
                <a target="_blank" href="https://api.whatsapp.com/send?phone=+918160080658"><WhatsAppIcon style={{ fontSize: '20px', color: '#CCCCCC' }} /></a>
                <a target="_blank" href="skype:yottolShout?add"><AlternateEmailOutlinedIcon style={{ fontSize: '20px', color: '#CCCCCC' }} /></a>
                <a target="_blank" href="https://www.yottol.com"><HelpOutlineOutlinedIcon style={{ fontSize: '20px', color: '#CCCCCC' }} /></a>

            </div>
        </div>
    )
}

export default Footer;