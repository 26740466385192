import React, { useState, useEffect, useMemo } from "react";
import ServerRequest from "../../../exports/ServerRequest";
import CustomIconButton from "../../Body/CustomChartComponents/CustomIconButton/CustomIconButton";
import { Book, DocumentText } from "../../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../../exports/ColorVars";
import Pulse from "../../Loader/Pulse";
import TradesTableItem from "../../BrokerManagement/TradesManagement/TradesTableItem";
import UploadTrades from "../../BrokerManagement/TradesManagement/UploadTrades";

function OrderConfirmation() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [executionCount, setExecutionCount] = useState(0);

  useEffect(() => {
    GetTrades();
  }, [executionCount]);

  async function GetTrades() {
    try {
      const data = await ServerRequest({
        method: "GET",
        url: `/backoffice/dealer/trades`,
      });

      if (data.error) {
        throw new Error("Something went wrong");
      } else {
        setData(data.data);
      }
    } catch (error) {
      setData([]);
    } finally {
      setLoading(false);
    }
  }

  if (loading || !data) {
    return (
      <div className="orders-confirmation loader">
        <Pulse />
        <p>Loading trades, please wait...</p>
      </div>
    );
  }

  return (
    <div className="orders-confirmation">
      {uploadOpen && (
        <>
          <UploadTrades
            closeUpload={() => {
              setUploadOpen(false);
            }}
            updateCount={() => {
              setExecutionCount(executionCount + 1);
            }}
          />
        </>
      )}
      <OrderSection trades={data["trades"]} setUploadOpen={setUploadOpen} />
    </div>
  );
}

function OrderSection({ trades, setUploadOpen }) {
  const [status, setStatus] = useState("pending");
  const [statusArray, setStatusArray] = useState(["Pending"]);

  const pending_trades = useMemo(() => {
    return trades.filter((t) => {
      return t.Status === "Pending";
    }).length;
  }, [trades]);
  const complete_trades = useMemo(() => {
    return trades.filter((t) => {
      return t.Status === "Complete";
    }).length;
  }, [trades]);
  const total_trades = useMemo(() => {
    return pending_trades + complete_trades;
  }, [trades]);

  return (
    <>
      <div className="orders-confirmation-header">
        <div className="status-menu">
          <p
            className={"status-menu " + (status === "pending" ? "active" : "")}
            onClick={() => {
              setStatus("pending");
              setStatusArray(["Pending"]);
            }}
          >
            Pending Trades ({pending_trades})
          </p>
          <p
            className={"status-menu " + (status === "complete" ? "active" : "")}
            onClick={() => {
              setStatus("complete");
              setStatusArray(["Complete"]);
            }}
          >
            Completed Trades ({complete_trades})
          </p>
          <p
            className={"status-menu " + (status === "all" ? "active" : "")}
            onClick={() => {
              setStatus("all");
              setStatusArray(["Pending", "Complete"]);
            }}
          >
            All Trades ({total_trades})
          </p>
        </div>
        <CustomIconButton
          title="Upload Trades"
          icon={<DocumentText size={20} color={COLOR_VARS["SWIFT_COLOR4"]} />}
          onClick={() => {
            setUploadOpen(true);
          }}
        />
      </div>
      <TradesTable trades={trades} status={statusArray} />
    </>
  );
}

function TradesTable({ trades, status }) {
  const [filteredTrades, setFilteredTrades] = useState([]);

  useEffect(() => {
    const filter = trades.filter((t) => {
      return status.includes(t.Status);
    });
    setFilteredTrades(filter);
  }, [trades, status]);


  return (
    <div className="orders-confirmation-table">
      {filteredTrades.length === 0 ? (
        <>
          <div className="empty-table-data">
            <Book size={60} color={COLOR_VARS["SWIFT_COLOR4"]} />
            <p>There are no trades to display</p>
          </div>
        </>
      ) : (
        <>
          {
            <table>
              <thead>
                <tr>
                  <td>Stock Name</td>
                  <td>Client Name</td>
                  <td>Client Code</td>
                  <td>Exchange</td>
                  <td>Order Type</td>
                  <td>Limit Price</td>
                  <td>Order Side</td>
                  <td>Quantity</td>
                  <td>Current Price</td>
                  <td>Trade Price</td>
                  <td>Trade Amount</td>
                  <td>Trade ID</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
                {filteredTrades.map((t, index) => {
                  return (
                    <TradesTableItem
                      key={index}
                      show={status.includes(t.Status)}
                      order={{
                        order_id: t["userOrderID"],
                        client_name: t["name"],
                        client_code: t["ClientID"],
                        isin: t["isin"],
                        symbol: t["symbol"],
                        name: t["stockCode"],
                        exchange: t["exchange"],
                        fullname: t["stockName"],
                        ordertype: t["orderType"],
                        limitprice: t["limitPrice"],
                        side: t["Side"],
                        quantity: t["Quantity"],
                        avgprice: t["trxnPrice"],
                        grossvalue: t["grossValue"],
                        status: t["Status"],
                        trade_id: t["tradeOrderID"],
                        broker: t["brokerCode"],
                      }}
                    />
                  );
                })}
              </tbody>
            </table>
          }
        </>
      )}
    </div>
  );
}

export default OrderConfirmation;
