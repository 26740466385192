import React, { useEffect, useState } from "react";
import Axios from "axios";

import { GetCookie } from "../../exports/CookieManagement";
import ReportSchedule from "./ReportSchedule";
import ReportScheduler from "./ReportScheduler";
import ReportHistory from "./ReportHistory";
import ReportGenerate from "./ReportGenerate";
import { GetRoleType } from "../../exports/RoleTypes";
import SwiftModal from "../CustomComponents/SwiftModal/SwiftModal";
import { getDeviceSize } from "../../exports/InteractiveUtils";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function ReportDashboard({ menu }) {
  const account_token = GetCookie("token");
  const user_role = GetCookie("role");
  const user_role_type = GetRoleType(user_role);

  const [customers, setCustomers] = useState([]);
  const [portfolios, setPortfolios] = useState([]);
  const [scheduler, setScheduler] = useState(false);
  const [scheduled, setScheduled] = useState(0);

  function getCustomerDetails(account_token) {
    Axios.get(
      `${REQUEST_BASE_URL}/clientinfo/swiftaccount?token=${account_token}`
    )
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          const customer = data.data;
          setCustomers([customer]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getMACustomers(account_token) {
    Axios.get(`${REQUEST_BASE_URL}/portfolio/customers?token=${account_token}`)
      .then((response) => {
        const data = response.data;
        // console.log(data)
        if (!data.error) {
          setCustomers(data.customers);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getFamilyPortfolios(account_token) {
    Axios.get(`${REQUEST_BASE_URL}/family?token=${account_token}`)
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          let family_portfolios = data.family_portfolios;
          family_portfolios = family_portfolios.map((f) => {
            return {
              family_portfolio_id: f.family_portfolio_id,
              name: f.family_portfolio_name,
              family_portfolio_customers: f.family_portfolio_customers,
            };
          });
          setPortfolios(family_portfolios);
        } else {
          setPortfolios([]);
        }
      })
      .catch(() => {
        setPortfolios([]);
      });
  }

  useEffect(() => {
    if (user_role_type === "BROKER") {
      getMACustomers(account_token);
    } else {
      getCustomerDetails(account_token);
    }
    getFamilyPortfolios(account_token);
  }, []);

  const DEVICE_SIZE = getDeviceSize();

  //   console.log(DEVICE_SIZE,menu)

  return (
    <div className="report-dashboard">
      {DEVICE_SIZE !== "S" ||
      (DEVICE_SIZE === "S" && menu === "Generate Report") ? (
        <>
          <div className="report-dashboard-left">
            <div className="report-generate">
              <ReportGenerate
                user_role_type={user_role_type}
                customers={customers}
                portfolios={portfolios}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="report-dashboard-right">
        {scheduler && (
          <>
            <SwiftModal
              top="5%"
              closeModal={() => {
                setScheduler(false);
              }}
            >
              <div className="report-scheduler">
                <ReportScheduler
                  user_role_type={user_role_type}
                  customers={customers}
                  portfolios={portfolios}
                  setScheduled={setScheduled}
                  setScheduler={setScheduler}
                />
              </div>
            </SwiftModal>
          </>
        )}

        {DEVICE_SIZE !== "S" ||
        (DEVICE_SIZE === "S" && menu === "Report Schedule") ? (
          <>
            <div className="report-schedule">
              <ReportSchedule
                customers={customers}
                scheduled={scheduled}
                setScheduled={setScheduled}
                setScheduler={setScheduler}
              />
            </div>
          </>
        ) : (
          <></>
        )}

        {DEVICE_SIZE !== "S" ||
        (DEVICE_SIZE === "S" && menu === "Report History") ? (
          <>
            <div className="report-history">
              <ReportHistory />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ReportDashboard;
