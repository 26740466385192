import React from "react";
import { titleCase } from "../../../../exports/UtilFunctions";
import useStockInfo from "../../../../hooks/useStockInfo";

function CompanyBox({ data }) {
  const { stock_code, stock_weight, industry } = data;
  const { loading, stock_info } = useStockInfo(stock_code);

  if (loading) {
    return (
      <tr>
        <td>{stock_code}</td>
        <td></td>
        <td></td>
        <td>{stock_weight}</td>
        <td></td>
      </tr>
    );
  }

  if (stock_info) {

    const {symbol,name,exchange} = stock_info;
    let exchange_name = exchange.exchange;

    return (
      <tr>
        <td>{symbol}</td>
        <td>{name ? name : symbol}</td>
        <td>{exchange_name}</td>
        <td>{stock_weight}</td>
        <td>{industry ? titleCase(industry) : "ETF"}</td>
      </tr>
    );
  }

  return (
    <tr>
      <td>{stock_code}</td>
      <td>-</td>
      <td>-</td>
      <td>{stock_weight}</td>
      <td>{industry ? titleCase(industry) : "ETF"}</td>
    </tr>
  );
}

export default CompanyBox;

// class CompanyBox extends React.PureComponent {

//     constructor(props) {
//         super(props);
//         this.state = {
//             logo: false
//         }
//         this.GetCompanyLogo = this.GetCompanyLogo.bind(this);
//     }

//     componentDidMount() {
//         this.GetCompanyLogo();
//     }

//     GetCompanyLogo() {

//         function FormatCompanyName(name) {
//             if (!name) {
//                 return '';
//             }

//             name = name.split("Ltd.")[0].trim();
//             name = name.split("Limited")[0].trim();
//             name = name.toUpperCase();
//             return name;
//         }

//         const { name } = this.props;
//         let filtered_name = FormatCompanyName(name);
//         const url = `https://autocomplete.clearbit.com/v1/companies/suggest?query=${filtered_name}`;

//         if (filtered_name && filtered_name !== null && filtered_name !== undefined) {
//             Axios.get(encodeURI(url)).then((response) => {
//                 const data = response.data;
//                 const company = data.find((d) => { return FormatCompanyName(d.name) === filtered_name });

//                 if (company) {
//                     this.setState({
//                         logo: company.logo ? company.logo : false
//                     });
//                 }
//             });
//         }

//     }

//     render() {
//         const { stock } = this.props;
//         const { logo } = this.state;
//         return (
//             <div className='stock__logo__name'>
//                 {/* <div className='logo__image'>
//                     {logo ? <>
//                         <img src={logo} alt="" />
//                     </> :
//                         <span>
//                             {stock[0]}
//                         </span>
//                     }
//                 </div> */}
//                 <span>{stock}</span>
//             </div>
//         )
//     }
// }

// export default CompanyBox;
