import React, { useState } from "react";
import {  useLocation } from "react-router-dom";

function StockHeader({ menu_array }) {
  const location = useLocation();
  const { pathname = "" } = location;

  const [menuOpen, setMenuOpen] = useState(false);
  const scrollToSection = (ref) => {
    setMenuOpen(false);

    // console.log(ref);
    const element = document.getElementById(ref);
    // console.log(element);

    element.scrollIntoView({ behavior: "smooth" });
  };
  //   const user_role = GetRoleType(GetCookie("role"));
  // const currentElement = document.activeElement;

  // console.log(currentElement);
  return (
    <div
      className={"swift-menu " + (menuOpen ? "active" : "")}
      onMouseEnter={() => {
        setMenuOpen(true);
      }}
      onMouseLeave={() => {
        setMenuOpen(false);
      }}
      // onClick={() => {
      //   // setMenuOpen((prevState) => !prevState);
      //   setMenuOpen(true);
      // }}
    >
      <div
        className="swift-menu-icon swift-menu-logo"
        style={{
          justifyContent: menuOpen ? "flex-end" : "flex-start",
          width: "100%",
        }}
      >
        {!menuOpen && (
          <div className="swift-account-svg" onClick={() => setMenuOpen(true)}>
            <svg
              width="42"
              height="32"
              viewBox="0 0 42 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 32H42V26.6667H0V32ZM0 18.6667H42V13.3333H0V18.6667ZM0 0V5.33333H42V0H0Z"
                fill="black"
              />
            </svg>
          </div>
        )}

        {menuOpen && (
          <div className="swift-account-svg" onClick={() => setMenuOpen(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              viewBox="0 0 512 512"
              width="20"
            >
              <path d="m256 512c-141.160156 0-256-114.839844-256-256s114.839844-256 256-256 256 114.839844 256 256-114.839844 256-256 256zm0-475.429688c-120.992188 0-219.429688 98.4375-219.429688 219.429688s98.4375 219.429688 219.429688 219.429688 219.429688-98.4375 219.429688-219.429688-98.4375-219.429688-219.429688-219.429688zm0 0" />
              <path d="m347.429688 365.714844c-4.679688 0-9.359376-1.785156-12.929688-5.359375l-182.855469-182.855469c-7.144531-7.144531-7.144531-18.714844 0-25.855469 7.140625-7.140625 18.714844-7.144531 25.855469 0l182.855469 182.855469c7.144531 7.144531 7.144531 18.714844 0 25.855469-3.570313 3.574219-8.246094 5.359375-12.925781 5.359375zm0 0" />
              <path d="m164.570312 365.714844c-4.679687 0-9.355468-1.785156-12.925781-5.359375-7.144531-7.140625-7.144531-18.714844 0-25.855469l182.855469-182.855469c7.144531-7.144531 18.714844-7.144531 25.855469 0 7.140625 7.140625 7.144531 18.714844 0 25.855469l-182.855469 182.855469c-3.570312 3.574219-8.25 5.359375-12.929688 5.359375zm0 0" />
            </svg>
          </div>
        )}
      </div>

      <div className="swift-menu-options">
        {menu_array.map((m) => {
          const { title, options } = m;

          return Array.isArray(options) && options.length > 0 ? (
            <MenuSection
              key={title}
              menuOpen={menuOpen}
              //   user_role={user_role}
              pathname={pathname}
              title={title}
              options={options}
              scrollToSection={scrollToSection}
            />
          ) : (
            <></>
          );
        })}
      </div>
    </div>
  );
}

function MenuSection({ menuOpen, pathname, title, options, scrollToSection }) {
  return (
    <div className="swift-menu-section" key={title}>
      <p className="menu-section-title">{title}</p>
      <div className="menu-section-options">
        {options.map((o, index) => {
          return (
            <div
              key={`${title}-${index}`}
              className={
                "swift-menu-icon " +
                ((pathname.includes(`/admin/${o.path}`) ||
                  pathname.includes(`/account/${o.path}`)) &&
                menuOpen
                  ? "active"
                  : "")
              }
              onClick={() => scrollToSection(o.ref)}
            >
              
              <p className="menu-text">{o.title}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default StockHeader;
