import React from 'react';
import { titleCase } from '../../exports/UtilFunctions';
import GetProfileColor from '../../exports/GetProfileColor';

function AccountRow({ account, getCredentials }) {

    return (
        <div className='login-account-row' onClick={() => { getCredentials(account.account_code) }}>
            <div className='account-icon'>
                <div className='account-profile' style={{ background: GetProfileColor(account.name) }}>
                    <span>{account?.name.slice(0, 1).toUpperCase()}</span>
                </div>
            </div>
            <div className='account-info'>
                <p className='account-name'>{titleCase(account.name)}</p>
                <p className='account-role'>{account.role_name}</p>
            </div>
        </div>
    )
}

export default AccountRow;