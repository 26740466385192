import React from 'react';
import Axios from 'axios';
import Chess from '../../assets/icons/chess.svg';
import BalanceBall from '../../assets/icons/balance-ball.svg';
import SettingsGears from '../../assets/icons/settings-gears.svg';
import Objective from '../../assets/icons/objective.svg';
import Compare from '../../assets/icons/CompareArrow.svg';
import Thinking from '../../assets/icons/smallcasedetails.svg';
import Price from '../../assets/icons/price.svg';
import Pulse from '../Loader/Pulse';
import CustomSelect from '../Body/CustomChartComponents/CustomSelect/CustomSelect';

import AddBackTestStock from './AddBackTestStock';
import EditPortfolios from './EditPortfolios';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

class MakeSmallcase extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            pannumber: this.props.pannumber,
            strategyName: [],
            smallcaseIds: [],
            newStrategy: false,
            active: null,
            isStrategyExist: false,
            createdDate: null,
            strategy: '',
            smallcase_id: this.props.smallcase_id,
            objective: '',
            methodology: '',
            benchmark: '',
            frequency: '',
            price: '',
            portfolios: [],
            backTestResult: [],
            isChecking: false,
            opacityMsg: true
        }
        this.TopSection = this.TopSection.bind(this)
        this.Row = this.Row.bind(this)
        this.setValue = this.setValue.bind(this)
        this.validateInput = this.validateInput.bind(this)
        this.checkDatabase = this.checkDatabase.bind(this)
        this.changeOpacityMsg = this.changeOpacityMsg.bind(this)
    }

    async componentDidMount() {
        const { pannumber, smallcase_id } = this.state;
        Axios.get(`${REQUEST_BASE_URL}/fetch_strategy?pan=${pannumber}`)
            .then(res => {
                this.setState({
                    strategyName: res.data.Strategy,
                    smallcaseIds: res.data.SmallcaseId
                }, () => {
                    if(smallcase_id) {
                        const strategy = this.state.strategyName[this.state.smallcaseIds.indexOf(smallcase_id)]
                        this.checkDatabase(strategy, "strategy")
                    }
                })
            })
    }

    TopSection() {
        return <div className="advisor__smallcase__strategy__header">
            <div onClick={this.props.smallCaseHome}>
                <span>Back</span>
            </div>
        </div>
    }


    Row({ icon, text, type, name, setValue }) {

        const benchmarkOptions = ['Nifty 50', 'Nifty 100', 'Nifty 200'];
        const frequencyOptions = ['12 Month', 'Year End', 'Quarter End', 'Month End']

        const getList = () => {

            let list = []
            let options = (name == "strategy") ? this.state.strategyName : (name == "benchmark") ? benchmarkOptions : frequencyOptions;

            if (name == "strategy") list.push('New Strategy');

            options.forEach(el => {
                list.push(el.split('_').join(' '))
            })

            return list;
        }

        const getListIndex = () => {

            let list = getList();
            let listindx = list.findIndex((li) => {
                return li === this.state.benchmark || li === this.state.frequency || li === this.state.strategy
            });
            return listindx;
        }

        let errorStyle = { color: '#e51a4b', fontSize: 10, fontWeight: 600, opacity: 0 }

        return (
            (type == "selectBox") ?

                <div className="advisor__strategy__select__container">

                    <div className="advisor__strategy__select__header">
                        {icon && <img src={icon} alt={text} />}
                        <span>{name}</span>
                    </div>

                    <div className="advisor__strategy__select__box">
                        <CustomSelect
                            width={200}
                            height={40}
                            options={getList()}
                            defaultIndex={getListIndex()}
                            title="Select"
                            onTypeChange={(value) => setValue(value, name)}
                        />
                    </div>

                    <span style={errorStyle} className="errSpan" id={name}>Please select {name}</span>
                </div>
                : (type == "inputBox") ?

                    <div className="advisor__strategy__select__container">
                        <div className="advisor__strategy__select__header">
                            {icon && <img src={icon} alt={text} width={20} />}
                            <span>{name}</span>
                        </div>

                        <div className="advisor__strategy__text__box">
                            <textarea className="advisor__text_area_small_case"
                                placeholder={text}
                                maxLength="200"
                                value={name == 'objective' ? this.state.objective : this.state.methodology}
                                onChange={(e) => setValue(e, name)}>
                            </textarea>
                        </div>

                        <span style={errorStyle} className="errSpan" id={name}>{name[0].toUpperCase() + name.slice(1)} cannot be less than 25 characters</span>
                    </div>
                    :
                    <div className="advisor__strategy__select__container">

                        <div className="advisor__strategy__select__header">
                            {icon && <img src={icon} alt={text} width={20} />}
                            <span>{name}</span>
                        </div>

                        <div className="advisor__strategy__input__box">
                            <input className="text_area_small_case"
                                placeholder={text} maxLength="50" type={name == "price" ? "number" : ""}
                                value={name == 'price' ? this.state.price : this.state.strategy}
                                onChange={(e) => setValue(e, name)}
                                onBlur={(e) => this.checkDatabase(e, name)}>
                            </input>
                        </div>

                        <span style={errorStyle} className="errSpan" id={name}>{name[0].toUpperCase() + name.slice(1)} {name == 'price' ? 'cannot be less than 50' : 'cannot be empty'}</span>
                    </div>

        )
    }

    async checkDatabase(e, name) {

        if (name != 'strategy') return;
        let { strategyName, smallcaseIds } = this.state

        this.setState({ isChecking: true })
        this.changeOpacityMsg();

        const payload = {
            pan: this.state.pannumber,
            strategy: e.target ? e.target.value : e,
            smallcase_id: smallcaseIds[strategyName.indexOf(e.target ? e.target.value.split(' ').join('_') : e.split(' ').join('_'))]
        }

        Axios.post(`${REQUEST_BASE_URL}/check_strategy`, payload)
            .then(res => {
                const result = res.data;
                if (result.details) {
                    for (let span of document.getElementsByClassName('errSpan')) span.style.opacity = '0';
                    this.setState({
                        isStrategyExist: true,
                        smallcase_id: result.details.smallcase_id,
                        createdDate: result.details.createdDate,
                        strategy: result.details.strategy,
                        objective: result.details.objective,
                        methodology: result.details.methodology,
                        benchmark: result.details.benchmark,
                        frequency: result.details.frequency,
                        price: result.details.price,
                        portfolios: result.portfolios,
                        backTestResult: result.backTestResult,
                    })
                } else {
                    this.setState({
                        isStrategyExist: false,
                        createdDate: new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + (new Date().getDate())).slice(-2),
                        objective: '',
                        smallcase_id: null,
                        methodology: '',
                        benchmark: '',
                        frequency: '',
                        price: '',
                        portfolios: [],
                        backTestResult: []
                    })
                }
                this.setState({ isChecking: false });
            })

    }


    setValue(e, name) {

        document.getElementById(name).style.opacity = '0';

        if (name == 'strategy' && e == 'New Strategy') {
            this.setState({ newStrategy: true });
            this.setState({
                smallcase_id: null,
                strategy: "",
                objective: "",
                methodology: "",
                benchmark: "",
                frequency: "",
                price: ""
            });
            return;
        }

        switch (name) {
            case 'strategy':
                this.state.newStrategy ? this.setState({ strategy: e.target.value }) : this.setState({ strategy: e });
                if (!this.state.newStrategy) {
                    this.checkDatabase(e, name)
                }
                break;
            case 'objective':
                this.setState({ objective: e.target.value }); break;
            case 'methodology':
                this.setState({ methodology: e.target.value }); break;
            case 'benchmark':
                this.setState({ benchmark: e }); break;
            case 'frequency':
                this.setState({ frequency: e }); break;
            case 'price':
                this.setState({ price: e.target.value > 100000 ? 100000 : e.target.value }); break;
        }
    }

    validateInput() {

        const state = this.state;
        let isValid = true;
        let data = new Object();

        for (let name in state) {
            if (name == 'strategy' || name == 'objective' || name == 'methodology' || name == 'benchmark' || name == 'frequency' || name == 'price') {

                if (name != 'active') {
                    if (state[name] == '' || (name == 'price' && parseInt(state[name]) < 50) || ((name == 'objective' || name == 'methodology') && state[name].length < 20)) {
                        document.getElementById(name).style.opacity = '1';
                        isValid = false;
                    } else
                        data[name] = state[name];
                }
            }
        }
        if (isValid) this.setState({ active: 'Backtest' })
    }

    changeOpacityMsg() {

        let displayOpacity = setInterval(() => {
            if (!this.state.isChecking) {
                clearInterval(displayOpacity)
            }
            else {
                this.setState({ opacityMsg: (this.state.opacityMsg == false) ? true : false })
            }
        }, 1500);
    }

    render() {

        let { pannumber, isStrategyExist, smallcase_id } = this.state;

        if (this.state.active == 'Backtest')
            return (<AddBackTestStock
                smallcase_id={smallcase_id}
                pannumber={pannumber}
                state={this.state}
                backButton={() => this.setState({ active: null })}
                smallCaseHome={this.props.smallCaseHome} />);

        return (
            <>
                <div className="advisor__smallcase__strategy__container">
                    <this.TopSection />
                    <div className="advisor__smallcase__strategy__body__wrapper" style={{ opacity: this.state.isChecking ? 0.5 : 1 }}>
                        {this.state.isChecking ?

                            <div className="advisor__smallcase__strategy__body loader">
                                <Pulse />
                                <p className={this.state.opacityMsg ? 'fadeIn loading__text' : 'fadeOut loading__text'}>Checking...</p>
                            </div>

                            :

                            <div className="advisor__smallcase__strategy__body">
                                <div className="advisor__smallcase__strategy__image">
                                    <img src={Thinking} alt="Thinking" />
                                </div>
                                <div className="advisor__smallcase__strategy__form">
                                    <this.Row
                                        icon={Chess}
                                        text="Strategy Name (max 50 char)"
                                        type={this.state.newStrategy ? "" : "selectBox"}
                                        name={'strategy'}
                                        setValue={this.setValue}
                                    />
                                    <this.Row
                                        icon={Objective}
                                        text="Objective (max 200 characters)"
                                        type={"inputBox"}
                                        name={'objective'}
                                        setValue={this.setValue}
                                    />
                                    <this.Row
                                        icon={SettingsGears}
                                        text="Methodology (max 200 characters)"
                                        type={"inputBox"}
                                        name={'methodology'}
                                        setValue={this.setValue}
                                    />

                                    <this.Row
                                        icon={Compare}
                                        text="Select Benchmark"
                                        type={"selectBox"}
                                        name={'benchmark'}
                                        setValue={this.setValue}
                                    />

                                    <this.Row
                                        icon={BalanceBall}
                                        text="Select Rebalancing Frequency"
                                        type={"selectBox"}
                                        name={'frequency'}
                                        setValue={this.setValue}
                                    />

                                    <this.Row
                                        icon={Price}
                                        text="Price (max Rs.100,000)"
                                        type={""} name={'price'}
                                        setValue={this.setValue}
                                    />

                                </div>

                            </div>

                        }
                    </div>
                    <div className="advisor__smallcase__strategy__footer">
                        <div className="advisor__strategy__footer__button" onClick={() => this.validateInput()}>
                            Next
                            {/* <img src={RightArrow} alt="RightArrow" style={{width: 10, marginLeft: 2}} /> */}
                        </div>
                        <div className="advisor__strategy__footer__button"
                            style={{ cursor: isStrategyExist ? 'pointer' : 'not-allowed', opacity: isStrategyExist ? 1 : 0.3 }}
                            onClick={() => { if (isStrategyExist) this.props.editPortfolio(this.state.smallcase_id) }}>
                            Edit Portfolios
                        </div>
                    </div>

                </div>

            </>
        )
    }
}

export default MakeSmallcase
