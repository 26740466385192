// const PROFILE_COLORS = [
//     '#0077B6', '#D62828', '#F77F00', '#6930C3', '#8338EC', '#EF476F', '#003566', '#687FF0', '#26B7BF', '#6930C3', '#8338EC', '#EF476F', '#003566', '#687FF0', '#26B7BF'
// ];

const PROFILE_COLORS = [
    '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF'
];


function GetProfileColor(text) {

    if (!text) return '#FFFFFF';

    let total = 0;
    const letters = text.length > 0 ? text.split('') : [];
    letters.forEach(l => {
        total += l.charCodeAt(0);
    });

    const index = total % PROFILE_COLORS.length;
    return PROFILE_COLORS[index];
}

module.exports = GetProfileColor;