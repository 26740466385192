import React from "react";
import "../../../../css/CustomComponents/CustomCheckBox.css";
import { Tick } from "../../../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../../../exports/ColorVars";

export default function CustomParentCheckBox({
  isChecked,
  width,
  height,
  disabled,
  onChangeValue,
}) {
  function toggleCheckBox() {
    onChangeValue(!isChecked);
  }

  return (
    <div
      className={
        isChecked
          ? "custom-checkbox checked"
          : disabled
          ? "custom-checkbox disabled"
          : "custom-checkbox"
      }
      style={{ width: width, height: height }}
      onClick={disabled ? () => {} : toggleCheckBox}
    >
      {isChecked ? <Tick size={20} color={COLOR_VARS['SWIFT_COLOR4']} /> : <></>}
    </div>
  );
}
