import React, { useEffect, useRef, useState } from "react";
import "../../../css/AdminManagement/CustomerManagement.css";
import SwiftModal from "../../CustomComponents/SwiftModal/SwiftModal";
import {
  ArrowRight2,
  Cross,
  Edit,
  FilterSearch,
} from "../../CustomComponents/SwiftIcon/Icons";
import CustomSelectError from "../../Body/CustomChartComponents/CustomSelectError/CustomSelectError";
import ServerRequest from "../../../exports/ServerRequest";
import { useNavigate } from "react-router-dom";
import Pulse from "../../Loader/Pulse";
import "../../../css/CustomComponents/CustomSearchSelect.css";

import CustomInputFile from "../../CustomComponents/CustomInputFile/CustomInputFile";
import axios from "axios";
import { isEmpty } from "../../../exports/UtilFunctions";
import { Alert } from "../../Body/CustomChartComponents/CustomAlert/CustomAlert";
import { GetCookie } from "../../../exports/CookieManagement";
import { COLOR_VARS } from "../../../exports/ColorVars";
import AdminStockSearch from "./AdminStockSearch";
import ConfirmBox from "../../CustomComponents/ConfirmBox/ConfirmBox";

const StockManagement = () => {
  const [form, setForm] = useState(null);
  const [formData, setFormData] = useState({});
  const [change, setChange] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingpage, setLoadingPage] = useState(true);
  const [data, setData] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [filterData, setFilterData] = useState("");
  const [newStock, setNewStock] = useState(false);
  const [newIndustry, setNewIndustry] = useState(false);
  const [defaultIndex, setDefaultIndex] = useState(-1);
  const [oldSymbol, setOldSymbol] = useState("");
  const token = GetCookie("admin_token");

  const navigate = useNavigate();

  const DisplayAlert = (msg) => {
    Alert({
      TitleText: "Error",
      Message: msg,
      BandColor: "#e51a4b",

      AutoClose: {
        Active: true,
        Line: true,
        LineColor: "#e51a4b",
        Time: 3,
      },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoadingPage(true);
      const data1 = await ServerRequest({
        url: `/admin/stock?token=${token}`,
        method: "get",
      });
      if (data1.server_error) {
        navigate("/404", { state: { errorMessage: data1.message } });
      }
      setData(data1.data);

      const data2 = await ServerRequest({
        url: `/admin/stock/industry?token=${token}`,
        method: "get",
      });
      if (data2.server_error) {
        navigate("/404", { state: { errorMessage: data2.message } });
      }
      setIndustry(data2.data.sort());
      setLoadingPage(false);
    };
    fetchData();
  }, [change]);

  const [error, setError] = useState("");

  const handleInputChange = (name, value) => {
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  };
  const AddHandler = () => {
    setNewStock(true);
    setFormData({});
    setForm(true);
  };

  const fetchData = async (symbol) => {
    if (isEmpty(symbol)) {
      setFormData({
        Industry: "",
        Symbol: "",
        "Company Name": "",
        Series: "",
        "ISIN Code": "",
      });
      return;
    }
    try {
      const { data } = await axios.get(
        `https://www.swiftfolios.com/api/v1/StockDetails/${symbol}`
      );

      if (data.error) {
        navigate("/404", { state: { errorMessage: data.message } });
      }
      const updatedItem = {
        Industry: null,
        Symbol: symbol,
        "Company Name": data.stock.company_name,
        Series: data.stock.series,
        "ISIN Code": data.stock.isin,
      };

      setFormData(updatedItem);
      setDefaultIndex(-1);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const openForm = async (item) => {
    if (isEmpty(item.Industry)) {
      await fetchData(item.Symbol);
    } else {
      setDefaultIndex(industry.indexOf(item["Industry"]));
      setFormData(item);
    }
    setOldSymbol(item.Symbol);
    setForm(true);
  };

  const closeForm = () => {
    setFormData({});
    setForm(false);
    setNewStock(false);
    setError("");
    setNewIndustry(false);
    setDefaultIndex(-1);
    setOldSymbol("");
  };

  const handleSubmit = async () => {
    if (isEmpty(formData.Industry)) {
      if (newIndustry) {
        setError("Enter Valid Industry");
        DisplayAlert("Enter Valid Industry");
        return;
      }
      setError("Select Valid Industry");
      DisplayAlert("Select Valid Industry");
      return;
    }
    if (
      data.some((item) => item.Symbol === formData.Symbol) &&
      oldSymbol != formData.Symbol
    ) {
      DisplayAlert("Symbol Already Exist");
      return;
    }
    const data2 = await ServerRequest({
      url: `/admin/stock/insert?token=${token}`,
      data: { ...formData, newStock: newStock },
      method: "post",
    });

    if (data2.server_error) {
      navigate("/404", { state: { errorMessage: data2.message } });
    }
    setLoading(false);
    closeForm();
    setChange(Math.floor(Math.random() * 900000000 + 100000000));
  };

  const handleSelect = (stock) => {
    fetchData(stock);
    console.log(stock);
  };
  const deleteHandler = async (symbol) => {
    ConfirmBox({
      title: "Delete Stock",
      description: (
        <>
          <>Are you sure you want to Delete this stock ?</>
        </>
      ),
      properties: [
        {
          id: "2",
          label: "Delete",
          color: "#192b40",
          bgColor: "#ffffff",
          handleFunction: async (callback) => {
            const data = await ServerRequest({
              url: `/admin/stock/delete?token=${token}`,
              data: { symbol: symbol },
              method: "delete",
            });
            setChange(Math.random());
            callback();
          },
        },
      ],
      cancel: true,
    });
  };

  const renderFormContent = () => {
    return (
      <>
        <div className="swift-account-form-body-content-extra">
          <div className="swift-account-form-body-content">

            <div className="swift-admin-stock-search-wrapper">
              <AdminStockSearch
                handleSelect={handleSelect}
                value={formData["Symbol"]}
              />
            </div>

            <CustomInputFile
              label="Company Name"
              name="CompanyName"
              type="text"
              value={formData["Company Name"]}
              onchange={handleInputChange}
              disabled={true}
            />
            <CustomSelectError
              heading="Industry"
              placeholder="Please Select Industry"
              defaultIndex={defaultIndex}
              options={industry}
              onTypeChange={(role) => {
                setNewIndustry(false);
                setError("");
                if (role == "Add New Industry") {
                  handleInputChange("Industry", "");
                  setNewIndustry(true);
                  setDefaultIndex(0);
                } else {
                  handleInputChange("Industry", role);
                }
              }}
              error={newIndustry ? "" : error}
            />
            {newIndustry && (
              <CustomInputFile
                label="New Industry"
                name="Industry"
                type="text"
                value={formData["Industry"]}
                onchange={handleInputChange}
                disabled={false}
                error={error}
              />
            )}

            <CustomInputFile
              label="Series"
              name="Series"
              type="text"
              value={formData["Series"]}
              onchange={handleInputChange}
              disabled={true}
            />

            <CustomInputFile
              label="ISIN Code"
              name="ISIN Code"
              type="text"
              value={formData["ISIN Code"]}
              onchange={handleInputChange}
              disabled={true}
            />
          </div>
        </div>
        <div className="login-footer">
          <div className="swift-button-icon" onClick={handleSubmit}>
            <div className="button-icon">
              <ArrowRight2 size={20} />
            </div>
            <p>Submit</p>
          </div>
        </div>
      </>
    );
  };
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };
  const FileHandler = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios({
        url: `/admin/stock/upload?token=${token}`,
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (!response.data.error) {
        setChange(Math.random());
      }
    } catch (error) {
      const data = error.response.data;
      console.log("Error uploading file", error.response.data);
      DisplayAlert(data.message);
    }
  };

  return !loadingpage ? (
    <div className="swift-account-main open">
      <div className="swift-account-heading">
        <div className="swift-account-heading-right">
          <div className="select-search-wrapper">
            <div className="select-search-box">
              <input
                placeholder="Search Stock"
                value={filterData}
                onChange={(e) => setFilterData(e.target.value.toUpperCase())}
              />
              <div className="filter-icon">
                <FilterSearch size={20} color="#000" />
              </div>
            </div>
          </div>
          <button onClick={AddHandler}>Add Stock</button>
          <>
            <button onClick={handleClick}>Upload File</button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={FileHandler}
            />
          </>
        </div>
      </div>

      {data.filter(
        (item) =>
          (item["Company Name"] &&
            item["Company Name"]
              .toLowerCase()
              .includes(filterData.toLowerCase())) ||
          item.Symbol.toLowerCase().includes(filterData.toLowerCase())
      ).length > 0 ? (
        <div className="admin-wrapper full">
          <div className="admin-history-container admin-stock-container">
            <table>
              <thead>
                <tr>
                  <td className="company">Company Name</td>
                  <td className="symbol">Symbol</td>
                  <td className="industry">Industry</td>
                  <td className="series">Series</td>
                  <td className="isin">ISIN Code</td>
                  <td className="edit"></td>
                  <td className="delete"></td>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  if (
                    (item["Company Name"] &&
                      item["Company Name"]
                        .toLowerCase()
                        .includes(filterData.toLowerCase())) ||
                    item.Symbol.toLowerCase().includes(filterData.toLowerCase())
                  ) {
                    return (
                      <tr key={index}>
                        <td>
                          {item["Company Name"] ? item["Company Name"] : "-"}
                        </td>
                        <td>{item.Symbol ? item.Symbol : "-"}</td>
                        <td>{item.Industry ? item.Industry : "-"}</td>
                        <td>{item.Series ? item.Series : "-"}</td>
                        <td>{item["ISIN Code"] ? item["ISIN Code"] : "-"}</td>
                        <td>
                          <div
                            className="swift-account-svg"
                            onClick={() => openForm(item)}
                          >
                            <Edit size={18} color={COLOR_VARS['SWIFT_COLOR4']} />
                          </div>
                        </td>
                        <td>
                          <button className="swift-account-btn" onClick={() => deleteHandler(item.Symbol)}>
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div
          className="empty-data-wrapper"
        >
          <p>No Stocks Found</p>
        </div>
      )}

      {form && (
        <SwiftModal closeModal={closeForm} top="2%">
          <div className="swift-account-form">
            {loading ? (
              <>
                <div className="loader">
                  <Pulse />
                  <p>Creating Account</p>
                </div>
              </>
            ) : (
              <>
                <div className="swift-account-form-header">
                  <div className="swift-account-form-title">
                    Stock Management
                  </div>
                  <div className="swift-account-svg" onClick={closeForm}>
                    <Cross size={30} color={COLOR_VARS["SWIFT_COLOR4"]} />
                  </div>
                </div>
                <div className="swift-account-form-body">
                  {renderFormContent()}
                </div>
              </>
            )}
          </div>
        </SwiftModal>
      )}
    </div>
  ) : (
    <>
      <div className="admin-body-loader">
        <Pulse />
        <p>Loading data</p>
      </div>
    </>
  );
};

export default StockManagement;
