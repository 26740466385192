import React, { useEffect } from "react";
import useMarketStock from "../../../hooks/useMarketStock";
import SwiftToolTip from "../../CustomComponents/SwiftToolTip/SwiftToolTip";
import { numberFormat } from "../../../exports/UtilFunctions";
import { getDeviceSize } from "../../../exports/InteractiveUtils";

const DEVICE_SIZE = getDeviceSize();

function ReplaceNoise(value) {
  if (
    !value ||
    value === null ||
    value === undefined ||
    value === "" ||
    parseInt(value) === 0
  ) {
    return "-";
  }
  return value;
}

function ConfirmationItem({
  index,
  show,
  setTradeIndex,
  order,
}) {
  const {
    stockCode: name,
    instrument_token: code,
    stockName: fullname,
    orderType: ordertype,
    limitPrice: limitprice,
    Side: side,
    Quantity: quantity,
    Status: status,
    brokerCode: broker,
  } = order;

  const stockData = useMarketStock(name);

  useEffect(() => {
    let last_traded_price = stockData ? stockData.last_traded_price : 0;
    setTradeIndex(index, quantity * last_traded_price, side);
  }, [stockData?.last_traded_price]);

  let last_traded_price = stockData ? stockData.last_traded_price : 0;
  let current_price = parseFloat(last_traded_price);
  let order_value = quantity * parseFloat(last_traded_price);

  let trxn_price = ordertype.toUpperCase() === 'MARKET' ? current_price : limitprice;

  if (!show) {
    return <></>;
  } else {

    if(DEVICE_SIZE === 'S'){
      return <>
        <div className="order-row">
          <div>
            <span>{side.toUpperCase()}</span>
          </div>
          <div>
            <span>Broker: {broker}</span>
          </div>
        </div>
        <div className="order-row">
          <div>
            <span>{name}</span>
          </div>
          <div>
            <span>{quantity}</span>
            <span>@ {numberFormat(trxn_price)}</span>
          </div>
        </div>
        <div className="order-row">
          <div>
            <span>Total: {order_value ? numberFormat(order_value) : "-"}</span>
          </div>
          <div>
            <span>Status: {status}</span>
          </div>
        </div>
      </>
    }

    return (
      <>
        <tr>
          <td className="order-item-stock-name">
            <SwiftToolTip title={fullname} placement="bottom-start">
              <div className="stock-info">
                <span className="stock-name">{name}</span>
                <span className="stock-fullname">{fullname}</span>
              </div>
            </SwiftToolTip>
          </td>
          <td className="order-item-type">{ordertype.toUpperCase()}</td>
          <td className="order-item-limit">{ReplaceNoise(limitprice)}</td>
          <td className="order-item-side">
            <div className={side.toLowerCase()}>{side.toUpperCase()}</div>
          </td>
          <td className="order-item-quantity">{quantity}</td>
          <td className="order-item-current-price">
            {current_price ? numberFormat(current_price) : "-"}
          </td>
          <td className="order-item-current-value">
            {order_value ? numberFormat(order_value) : "-"}
          </td>
          <td className="order-item-broker">{broker}</td>
          <td className="order-item-status">{status}</td>
        </tr>
      </>
    );
  }
}

export default ConfirmationItem;
