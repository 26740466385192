import React from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import contact from "../../assets/icons/contact.svg";
import "../../css/HeroPage.css";
import { Login } from "../CustomComponents/SwiftIcon/Icons";
import CustomIconButton from "../Body/CustomChartComponents/CustomIconButton/CustomIconButton";

class HeroIntro extends React.PureComponent {
  componentDidMount() {
    var process = $(".process");
    var canvas = document.getElementById("canvas");
    var ctx = canvas.getContext("2d");

    var SECTION_WIDTH = 200;

    var sections = [];

    var create = function (start) {
      var section = {
        start: start,
        width: SECTION_WIDTH,
        height: 45,
        hMax: 35,
        hMod: 0,
        progress: 0,
        dot: {
          x: 0,
          y: 0,
        },
      };
      section.end = section.start + section.width;
      section.dot.x = section.start + section.width / 2;
      section.dot.y = section.height;
      sections.push(section);
    };

    var draw = function (s) {
      var wMod = s.width * 0.3;
      ctx.beginPath();
      ctx.moveTo(s.start, s.height);
      ctx.bezierCurveTo(
        s.start + wMod,
        s.height,
        s.start + wMod,
        s.height - s.hMod,
        s.start + s.width / 2,
        s.height - s.hMod
      );
      ctx.bezierCurveTo(
        s.end - wMod,
        s.height - s.hMod,
        s.end - wMod,
        s.height,
        s.end,
        s.height
      );
      ctx.lineWidth = 4;
      ctx.strokeStyle = "#192b40";
      ctx.stroke();

      ctx.beginPath();
      ctx.fillStyle = "#192b40";
      ctx.arc(s.dot.x, s.dot.y, 8, 0, Math.PI * 2);
      ctx.fill();
    };

    function quad(progress) {
      return Math.pow(progress, 2);
    }

    function makeEaseOut(delta) {
      return function (progress) {
        return 1 - delta(1 - progress);
      };
    }

    var quadOut = makeEaseOut(quad);

    var bend = function (s) {
      if (s.progress < s.hMax) {
        var delta = quadOut(s.progress / s.hMax);
        s.hMod = s.hMax * delta;
        s.dot.y = s.height - s.hMax * delta;
        s.progress++;
      }
    };

    var reset = function (s) {
      if (s.progress > 0) {
        var delta = quadOut(s.progress / s.hMax);
        s.hMod = s.hMax * delta;
        s.dot.y = s.height - s.hMax * delta;
        s.progress -= 2;
      } else {
        s.hMod = 0;
        s.dot.y = s.height;
        s.progress = 0;
      }
    };

    var currentSection = 0;
    process.on("mousemove", function (event) {
      var section = Math.floor(
        (event.clientX - process.offset().left) / SECTION_WIDTH
      );
      currentSection = section;
      process.find(".active").removeClass("active");
      process.find("li").eq(section).addClass("active");
    });

    create(0);
    create(200);
    create(400);
    create(600);

    var loop = function () {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      sections.forEach(function (s, index) {
        if (currentSection === index) {
          bend(s);
        } else {
          reset(s);
        }
        draw(s);
      });

      window.requestAnimationFrame(loop);
    };
    loop();
  }

  render() {
    return (
      <>
        <div className="first">
          {/* <div className="logo"><img src={logo} /></div> */}
          <div className="touch" id="touch">
            <a href="mailto:shout@yottol.com">
              <img src={contact} />
              Get in Touch
            </a>
          </div>
          <div className="firstone">
            <i>swift</i>folios
          </div>
          <div className="firsttag">
            Scalable Portfolio Management in a{" "}
            <strong style={{ fontWeight: "900", letterSpacing: "-1px" }}>
              Platform as a Service
            </strong>{" "}
            model.
          </div>
          <div className="firsttwo">
            for PMSs, AIFs, RIAs, Family Offices, and HNIs
          </div>
          <div style={{display: 'flex', columnGap: '30px'}}>
            <div className="login">
              <Link to="/access/login">
                <CustomIconButton icon={<Login size={18} />} title="Login" />
              </Link>
            </div>
            <div className="login">
              <Link to="/access/signup">
                <CustomIconButton icon={<Login size={18} />} title="Signup" />
              </Link>
            </div>
          </div>
          <div className="firstthree">Complete Automation</div>
          <div className="process">
            <ol className="process-items">
              <li className="active">
                Bank Accounts
                <em>Pooled & Individual</em>
              </li>
              <li>
                Portfolio Management
                <em>Platform level change implementation</em>
              </li>
              <li>
                Trade
                <em>Execution & Processing</em>
              </li>
              <li>
                Reporting
                <em>Client & Regulatory</em>
              </li>
            </ol>
            <canvas id="canvas" width="800" height="55"></canvas>
          </div>
          <div className="processmobile">
            <div className="card">
              Bank Accounts<span>Pooled & Individual</span>
            </div>
            <div className="card">
              Portfolio Management
              <span>Platform level change implementation</span>
            </div>
            <div className="card">
              Trade<span>Execution & Processing</span>
            </div>
            <div className="card">
              Reporting<span>Client & Regulatory</span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HeroIntro;
