import React from 'react';
import Axios from 'axios';
import { ReceiptSearch } from '../../../CustomComponents/SwiftIcon/Icons';
import Search from '../../../../assets/icons/search.svg';
import CloseIcon from '../../../../assets/icons/closesmall.svg';
import Pulse from '../../../Loader/Pulse';
import { COLOR_VARS } from '../../../../exports/ColorVars';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;


class HighLightText extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        const text = this.props.text;
        const query = this.props.query;
        const customClass = this.props.customClass;
        const parts = text.split(new RegExp(`(${query})`, 'gi'));
        return <span> {parts.map((part, i) =>
            <span key={i}
                className={part.toLowerCase() === query.toLowerCase() ? `${customClass}` : ''}>
                {part.toUpperCase()}
            </span>)
        } </span>;
        // return <span>{text}</span>
    }
}

class StockSuggestion extends React.PureComponent {
    constructor(props) {
        super(props)
    }

    render() {
        const { search, suggestions, handleSelection } = this.props;

        if (search.length > 0) {
            if (suggestions.length > 0) {

                const exchangeList = ['NSE', 'BSE'];
                return (
                    <>
                        {this.props.suggestions.map((s, index) => {
                            let stocksymbol = exchangeList.includes(s.exchange.exchange) ? s.nse_code : s.code;
                            return <p style={{ fontSize: 10, padding: 0, alignItems: 'center' }}
                                key={s.code}
                                onClick={e => { handleSelection(this.props.suggestions[index]) }}>
                                <span style={{ paddingLeft: 10 }}>{stocksymbol}</span>
                                <HighLightText text={s.company} query={search} customClass="search__highlight" />
                                <span>{s.exchange.exchange}</span>
                            </p>
                        })}
                    </>
                )
            }
            else {
                return (
                    <div className="stock__suggestions__loader">
                        <Pulse />
                        <span>Loading Stocks please wait...</span>
                    </div>
                )
            }
        }
        else {
            return (
                <div className="stock__suggestions__loader">
                    <ReceiptSearch size={60} color={COLOR_VARS['SWIFT_COLOR4']} />
                    <span className="stock__suggestions__empty">
                        Search symbol to add into watchlist
                    </span>
                </div>
            )
        }


    }
}

class WatchlistPopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            search: '',
            suggestions: []
        }
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
        this.setComponentRef = this.setComponentRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }


    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }


    setComponentRef(node) {
        this.ComponentRef = node;
    }


    handleClickOutside(event) {
        if (this.ComponentRef && !this.ComponentRef.contains(event.target)) {
            this.setState({
                loading: true,
                search: '',
                suggestions: [],
            })
            this.props.ClosePopup();
        }
    }

    handleSearchChange(e) {
        this.setState({
            search: e.target.value,
            loading: true,
        }, () => {
            if (this.state.search && this.state.search.length > 0) {
                this.setState({
                    suggestions: [],
                }, () => {
                    this.getSuggestions();
                });

            }
            else {
                this.setState({
                    suggestions: [],
                    loading: false,
                })
            }
        });
    }

    getSuggestions() {
        Axios.get(`${REQUEST_BASE_URL}/stock/${this.state.search}`)
            .then((response) => {
                let suggestions = response.data.suggestions.filter(s => s.exchange.exchange === "NSE" || s.exchange.exchange === "BSE")
                this.setState({
                    loading: false,
                    suggestions: suggestions
                })
            })
            .catch((error) => {
                console.log(error);
            })
    }

    handleSelection(data) {
        this.setState({
            suggestions: [],
            search: ''
        });
        this.props.AddSymbol(data)
    }



    render() {

        return (
            <div className="watchlist__popup__overlay">
                <div className="watchlist__popup" ref={this.setComponentRef}>
                    <div className="watclist__popup__header">
                        <p>Add symbol to watchlist</p>
                        <span className="watchlist__popup__close" onClick={() => { this.props.ClosePopup(); }}>
                            <img src={CloseIcon} alt="X" />
                        </span>
                    </div>
                    <div className="watchlist__popup__body">
                        <div className="stock__search__wrapper">
                            <div className="stock__search">
                                <img src={Search} alt="" />
                                <input placeholder='Search symbol' value={this.state.search} onChange={e => this.handleSearchChange(e)} autoFocus />
                            </div>
                        </div>
                        <div className="stock__suggestions">
                            <StockSuggestion
                                search={this.state.search}
                                loading={this.state.loading}
                                suggestions={this.state.suggestions}
                                handleSelection={this.handleSelection}
                            />
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default WatchlistPopup;



