import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Pulse from '../Loader/Pulse';
import { GetCookie } from '../../exports/CookieManagement';
import { titleCase } from '../../exports/UtilFunctions';
import { TickCircle, CloseCircle } from '../CustomComponents/SwiftIcon/Icons';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function RoleAccessIcon(access) {
    if (access) {
        return <TickCircle size={20} color="#67D098" />
    }
    else {
        return <CloseCircle size={20} color="#D74467" />
    }
}

function RolesTable() {

    const [users, setUsers] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = GetCookie('token');
        axios.get(`${REQUEST_BASE_URL}/roles/internal?token=${token}`).then((response) => {
            const data = response.data;
            if (data.error) {
                setLoading(false);
            }
            else {
                setLoading(false);
                setUsers(data.users);
            }
        }).catch((error) => {
            // console.log(error)
            setLoading(false);
        })
    }, []);

    return (
        <div className='roles-users-table'>
            <p className='title'>Existing Internal Users</p>
            {loading ?
                <>
                    <div className='users-loading'>
                        <Pulse />
                    </div>
                </>
                :
                <>
                    {users ?
                        <>
                            {users.length > 0 ?
                                <>
                                    <div className='users-table'>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td>User Name</td>
                                                    <td>Mobile</td>
                                                    <td>Email Address</td>
                                                    <td className='center'>Trading</td>
                                                    <td className='center'>Operations</td>
                                                    <td className='center'>Marketing</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {users.map((u, index) => {
                                                    return <tr key={index}>
                                                        <td>{titleCase(u.name)}</td>
                                                        <td>{u.mobile}</td>
                                                        <td>{u.email}</td>
                                                        <td className='center'>{RoleAccessIcon(u.trading)}</td>
                                                        <td className='center'>{RoleAccessIcon(u.operations)}</td>
                                                        <td className='center'>{RoleAccessIcon(u.marketing)}</td>
                                                    </tr>
                                                })}

                                            </tbody>
                                        </table>
                                    </div>
                                </> :
                                <>
                                    <div className='users-empty'>
                                        <p>No users</p>
                                    </div>
                                </>
                            }
                        </> :
                        <>

                        </>
                    }
                </>
            }
        </div>
    )
}

export default RolesTable;