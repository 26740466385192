import React from 'react';

class HeroFAQ extends React.PureComponent {
    render() {
        return (
            <div className="hero__body__FAQ">
                <h1>FAQs</h1>
            </div>
        )
    }
}

export default HeroFAQ;
