import React, { Component } from 'react';
import Axios from 'axios';
import $ from 'jquery';
import { Navigate, Link } from 'react-router-dom';
import XLSX from 'xlsx';
import { removeUserSession } from '../../exports/UserAuth';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import BROKERAGEICON from '../../assets/backoffice/brokerage.svg';
import CURRENTCASH from '../../assets/backoffice/currentcash.svg';
import PROPOSEDCASH from '../../assets/backoffice/proposedcash.svg';
import TRADEVALUE from '../../assets/backoffice/tradevalue.svg';
import IMPORTICON from '../../assets/backoffice/import.svg';
import LOGOUTICON from '../../assets/backoffice/logout.svg';
import Spinner from '../Loader/Spinner';

import Cross from '../../assets/icons/cross.svg';
import {Alert} from '../Body/CustomChartComponents/CustomAlert/CustomAlert'
import Pulse from '../Loader/Pulse'
import CPTrade from './CPTrades/CPTrade';
import OrderBook from './OrderBook';
import ClientHoldings from './ClientHoldings';
import OrderSelect from './OrderSelect';
import SearchBar from '../Body/MenuSection/PortfolioComponents/SearchBar';
import OrderLiveData from './OrderLiveData';
import OrderModifyPopUp from '../Body/AppPopups/OrderModifyPopup/OrderModifyPopup';
import order_format from '../../exports/Templates/order_format.csv';
import Warning from '../../assets/backoffice/warning-3.svg'


const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;


class Backoffice extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedin : false,
            logincheck : false,
            logout : false,
            Accounts: [],
            Pans: [],
            Names: [],
            Portfolios: [],
            PortfolioName: null,
            AccountCode: null,
            AccountPan: null,
            CurrentCash: null,
            CurrentCashAPI: null,
            TotalTrade: null,
            ApproxBrokerage: null,
            ProposedCash: null,
            tradingOption: 'demat',
            data: [],
            dataupdated : false,
            dataLoaded: false,
            addable: false,
            fileloaded : false,
            importloading : false,
            user_order_id : false,
            isNewPortfolio : false,
            newPortfolioName : '',
            Existing: [],
            orderplaceable: false,
            modifyconfig: false,
            activeWindow: 1
        }

        this.getAccounts = this.getAccounts.bind(this);
        this.calculateCash = this.calculateCash.bind(this);
        this.Logout = this.Logout.bind(this);
        this.THead = this.THead.bind(this);
        this.TBody = this.TBody.bind(this);
        this.BackofficeHeader = this.BackofficeHeader.bind(this);
        this.AddImportSection = this.AddImportSection.bind(this);
        this.addTableRow = this.addTableRow.bind(this);
        this.importFile = this.importFile.bind(this);
        this.deleteStock = this.deleteStock.bind(this);
        this.TopSection = this.TopSection.bind(this);
        this.changeInput = this.changeInput.bind(this);
        this.getAsk = this.getAsk.bind(this);
        this.getBid = this.getBid.bind(this);
        this.getSide = this.getSide.bind(this);
        this.getOrderType = this.getOrderType.bind(this);
        this.changeOption = this.changeOption.bind(this);
        this.setData = this.setData.bind(this);
        this.checkCashPos = this.checkCashPos.bind(this);
        this.checkStock = this.checkStock.bind(this);
        this.ExecuteOrder = this.ExecuteOrder.bind(this);
        this.SetPlaced = this.SetPlaced.bind(this);
        this.checkStatus = this.checkStatus.bind(this);
        this.checkOrderPlaceable = this.checkOrderPlaceable.bind(this);
        this.OpenModifyPopup = this.OpenModifyPopup.bind(this);
        this.CloseModifyPopup = this.CloseModifyPopup.bind(this);
        this.increaseInput = this.increaseInput.bind(this);
    }

    componentDidMount(){
      this.setState({
        loggedin : true,
        logincheck : true
    })
    }


    async Logout()
    {
        removeUserSession();
        this.setState({
            logout : true
        })
    }

    getAccounts()
    {
        Axios.get(`${REQUEST_BASE_URL}/backoffice/fetch_account_code`)
        .then(response => {
            console.log(response.data.names)
            this.setState({
                Accounts: response.data.accounts,
                Pans: response.data.pans,
                Names: response.data.names,
                CurrentCash: 0,
                CurrentCashAPI: 0,
            }, () => {
                this.calculateCash(this.state.data)
            })
        })
        .catch(err => console.log(err))
    }

    checkCashPos(AccountCode){
        let { Pans, Accounts } = this.state
        Axios.get(`${REQUEST_BASE_URL}/clientinfo/cashposition?pan=${Pans[Accounts.indexOf(AccountCode)]}`)
            .then(res => {
                console.log(res.data)
                this.setState({ CurrentCash: res.data.cash, CurrentCashAPI: res.data.cashapi }, () => {
                    this.calculateCash(this.state.data)
                })
            })
    }

    BackofficeHeader() {

        const { tradingOption } = this.state;
        return <div className="windowtitle">
            <div>
                <p>Yottol Order Management Console</p>
                <div className='trade-options'>
                    <div className={tradingOption === 'demat' ? 'active' : ''} onClick={() => {
                        this.setState({ tradingOption: 'demat' })
                    }}>Demat Trades</div>
                    <div className={tradingOption === 'cp' ? 'active' : ''} onClick={() => {
                        this.setState({ tradingOption: 'cp' })
                    }}>CP Trades</div>
                </div>
            </div>
            <div className='logout-btn' onClick={() => {
                this.Logout();
            }}>
                <span>Logout</span>
                <img src={LOGOUTICON} alt="" />
            </div>
        </div>
    }

    TopSection() {
        const { Accounts, Pans, Names, Portfolios, AccountCode, PortfolioName, CurrentCash, TotalTrade, ApproxBrokerage, ProposedCash, isNewPortfolio, newPortfolioName } = this.state;

        let FinalPortfolios = ['New Portfolio', ...Portfolios];

        const curr = (val) => parseFloat(val).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })
        return <div className="getOrder__header">

           <div className="getOrder__selection__wrapper">
                <div>
                    <div className="getOrder__header__comp">
                        <span>
                            <OrderSelect
                                defaultIndex={Accounts.indexOf(AccountCode)}
                                placeholder="Select Account"
                                options={Accounts}
                                additionalOptions={Names}
                                width={240}
                                height={40}
                                onTypeChange={(value) => {
                                    Axios.post(`${REQUEST_BASE_URL}/backoffice/fetch_portfolioName`, { clientId: value })
                                        .then(res =>
                                            this.setState({
                                                AccountCode: value,
                                                AccountPan: Pans[Accounts.indexOf(value)],
                                                Portfolios: res.data.portfolios,
                                                PortfolioName: null,
                                                data: [],
                                                addable: true,
                                                dataLoaded: true,
                                                user_order_id: false,
                                                isNewPortfolio: false,
                                                newPortfolioName: ''
                                            }, () => {
                                                this.checkCashPos(value);
                                                if (this.statusinterval) {
                                                    clearInterval(this.statusinterval)
                                                }
                                            })

                                        )
                                        .catch(err => console.log(err))
                                }} />
                        </span>
                    </div>
                    <div className="getOrder__header__comp">
                        <span>
                            <OrderSelect
                                defaultIndex={FinalPortfolios.indexOf(PortfolioName)}
                                placeholder="Select Portfolio"
                                options={FinalPortfolios}
                                width={240}
                                height={40}
                                onTypeChange={(value) => {
                                    if ((value.replace(' ', '').toLowerCase()) == 'newportfolio') {
                                        this.setState({
                                            isNewPortfolio: true,
                                            PortfolioName: newPortfolioName,
                                            addable: false
                                        }, () => {
                                            Axios.post(`${REQUEST_BASE_URL}/backoffice/availablestock`, { portfolioName: newPortfolioName, clientId: AccountCode, pan: Pans[Accounts.indexOf(AccountCode)] })
                                                .then(res => {
                                                    this.setState({ Existing: res.data.portfolio, addable: true })
                                                })
                                        })
                                    }
                                    else {
                                        this.setState({ PortfolioName: value, isNewPortfolio: false, newPortfolioName: '' }, () => {
                                            Axios.post(`${REQUEST_BASE_URL}/backoffice/availablestock`, { portfolioName: value, clientId: AccountCode, pan: Pans[Accounts.indexOf(AccountCode)] })
                                                .then(res => {
                                                    this.setState({ Existing: res.data.portfolio, addable: true })
                                                })
                                        })
                                    }

                                }} />
                        </span>
                    </div>
                    {isNewPortfolio &&
                        <div className="getOrder__header__comp header__comp__input">
                            <input
                                placeholder="Enter Portfolio Name"
                                value={newPortfolioName}
                                onChange={(e) => {
                                    this.setState({
                                        newPortfolioName: e.target.value
                                    })
                                }}
                            />
                        </div>
                    }
                </div>

                <div className="getOrder__download__format">
                    <a style={{ textDecoration: 'none' }} href={order_format} download="order_format.csv">Download Format</a>
                </div>
            </div>

            <div className="getOrder__header__wrapper">
                <div className="getOrder__details">
                    <img src={CURRENTCASH} alt="X" />
                    <div>
                        <span className="getOrder__details__heading">Current Cash</span>
                        <span className="getOrder__details__val">{curr(CurrentCash)}</span>
                    </div>
                </div>
                <div className="getOrder__details">
                    <img src={TRADEVALUE} alt="X" />
                    <div>
                        <span className="getOrder__details__heading">Total Trade Value</span>
                        <span className="getOrder__details__val">{curr(TotalTrade)}</span>
                    </div>
                </div>
                <div className="getOrder__details">
                    <img src={BROKERAGEICON} alt="X" />   
                    <div>
                        <span className="getOrder__details__heading">Approx Brokerage</span>
                        <span className="getOrder__details__val">{curr(ApproxBrokerage)}</span>
                    </div>
                </div>
                <div className="getOrder__details">
                    <img src={PROPOSEDCASH} alt="X" />   
                    <div>
                        <span className="getOrder__details__heading">Proposed Cash</span>
                        <span className={ProposedCash >= 0 ? "getOrder__details__val neutral" : "getOrder__details__val negative"}>{curr(ProposedCash)}</span>
                    </div>
                </div>
            </div>
       </div>
    }

    AddImportSection(){

        const { activeWindow, AccountCode } = this.state;

       return(
           <div className="getOrder__add__import">

               <div className='getOrder__sections'>
                   <div className={activeWindow == 1 ? "order__section active" : "order__section"} onClick={() => {
                       this.setState({
                           activeWindow: 1
                       })
                   }}><span>Trading Window</span></div>
                   <div className={activeWindow == 2 ? "order__section active" : "order__section"} onClick={() => {
                       this.setState({
                           activeWindow: 2
                       })
                   }}><span>Orders</span></div>
                   <div className={activeWindow == 3 ? "order__section active" : "order__section"} onClick={() => {
                       this.setState({
                           activeWindow: 3
                       })
                   }}><span>Holdings</span></div>
                   <div className="order__section"><span><Link to={`/backoffice/user?id=${AccountCode}`} target="_blank">API Details</Link></span></div>
               </div>
               <div className="order__search">
                <div className="getOrder__add">
                        <SearchBar addTableRow={this.addTableRow} />
                </div>
                   <div className="order__browse">
                       <label htmlFor="getOrder__import"></label>
                       <div id="getOrder__import" className="getOrder__import">
                           <button className="btn-upload" onClick={(e) => this.importFile(e)}>Browse File</button>
                       </div>
                </div>
               </div>
           </div>
       )
    }

    THead({ th = [] }) {
        return th.map((v, i) => {
            return <th key={i}>{v}</th>
        })
    }

    getBid(TickSize){
        let bid = [];
        bid.push('Current LTP', 'Mid Price', 'Current Bid')
        if(TickSize){
            let i = 0, s = 0
            while(s<5){
                if(i<10){
                    s += TickSize*1
                    bid.push(`Bid+${parseFloat(s<=5? s: 5).toFixed(2)}`)
                }
                else if(i>=10 && i<20){
                    s += TickSize*2
                    bid.push(`Bid+${parseFloat(s<=5? s: 5).toFixed(2)}`)
                }
                else if(i>=20 && i<25){
                    s += TickSize*3
                    bid.push(`Bid+${parseFloat(s<=5? s: 5).toFixed(2)}`)
                }
                else if(i>=25 && i<30){
                    s += TickSize*4
                    bid.push(`Bid+${parseFloat(s<=5? s: 5).toFixed(2)}`)
                }
                else{
                    s += TickSize*5
                    bid.push(`Bid+${parseFloat(s<=5? s: 5).toFixed(2)}`)
                }
                i++;
            }
        }
        return bid;
    }

    getAsk(TickSize){
        let ask = [];
        ask.push('Current LTP', 'Mid Price', 'Current Ask')
        if(TickSize){
            let i = 0, s = 0
            while(s<5){
                if(i<10){
                    s += TickSize*1
                    ask.push(`Ask-${parseFloat(s<=5? s: 5).toFixed(2)}`)
                }
                else if(i>=10 && i<20){
                    s += TickSize*2
                    ask.push(`Ask-${parseFloat(s<=5? s: 5).toFixed(2)}`)
                }
                else if(i>=20 && i<25){
                    s += TickSize*3
                    ask.push(`Ask-${parseFloat(s<=5? s: 5).toFixed(2)}`)
                }
                else if(i>=25 && i<30){
                    s += TickSize*4
                    ask.push(`Ask-${parseFloat(s<=5? s: 5).toFixed(2)}`)
                }
                else{
                    s += TickSize*5
                    ask.push(`Ask-${parseFloat(s<=5? s: 5).toFixed(2)}`)
                }
                i++;
            }
        }
        return ask;
    }

    getSide(){
        return ["Buy", "Sell"]
    }

    getOrderType(){
        return ["Limit", "Market"]
    }

    TBody({ td = [], index }) {
        const { data } = this.state
        return (
            <tr>
                {
                    td.map((v, i) => {
                    
                        return <td key={i}>
                            {
                                (i == 3 && !this.state.ischange) || i == 7 ?
                                    <OrderSelect 
                                        defaultIndex={i == 3 ? this.getSide().indexOf(v) : this.getOrderType().indexOf(v)}
                                        options={i == 3 ? this.getSide() : this.getOrderType()}
                                        width={75}
                                        placeholder={"Custom"}
                                        height={25}
                                        onTypeChange={(value) => this.changeOption(td[1], i, value)} />

                                    : i == 2 || (i == 8 && td[7] == "Limit") ?
                                        <input value={v}
                                            style={{ width: i == 8 ? 70 : 60, marginLeft: i == 8 ? 2 : 0 }}
                                            onChange={(e) => this.changeInput(index, i, e.target.value)}
                                            onKeyDown={(e) => { this.increaseInput(index, i, e.key) }} />

                                        : i == 4 || i == 5 || i == 6 ?
                                            <OrderLiveData
                                                el={data[index]}
                                                key={td[1]} type={v}
                                                index={index}
                                                setData={this.setData} />

                                            : v
                            }
                            {
                                i == 8 && td[7] == "Limit" && data[index].ErrMsg &&
                                <span style={{ position: 'relative' }}>
                                    {
                                        data[index].DisErr &&
                                        <span style={{
                                            position: 'absolute',
                                            top: -15,
                                            left: '-100%',
                                            padding: '0 5px',
                                            borderRadius: '5px',
                                            color: 'red',
                                            background: '#cc9966'
                                        }}>
                                            {data[index].ErrMsg}
                                        </span>
                                    }
                                    <img src={Warning}
                                        width={15}
                                        onMouseOver={() => {
                                            data[index].DisErr = true;
                                            this.setState({ data })
                                        }}
                                        onMouseOut={() => {
                                            data[index].DisErr = false;
                                            this.setState({ data })
                                        }}
                                    />
                                </span>
                            }
                        </td>
                    })
                }
                <td><img width={8} src={Cross} onClick={()=>this.deleteStock(index)} /></td>
            </tr>
        )
    }

    setData(stockData, StockCode, type){
        const { data } = this.state;
        let newData = JSON.parse(JSON.stringify(data))
        let row = null;
        for (let el of newData) {
            if(el.StockCode==StockCode){
                row = el;
                break;
            } 
        }

        if(!row)    return;

        row[type] = type==="Bid"? stockData.best_bid_price : type==="Ask"? stockData.best_ask_price : stockData.last_traded_price;

        if (row.OrderType === "Market") {
            row.LMPrice = row.LTP;
            row.TradeValue = row.Side === "Buy" ? row.LMPrice * row.Quantity : -row.LMPrice * row.Quantity;
        }
        this.calculateCash(newData)
    }

    changeOption(StockCode, i, value){
        let data = JSON.parse(JSON.stringify(this.state.data));
        let row = null;
        for(let el of data){
            if(el.StockCode==StockCode){
                row = el;
                break;
            } 
        }   
        if(!row)    return;

        if(i==3){
            row.LMPrice = row.LTP
            row.LimitPrice = "Current LTP"
            row.Side = value
            row.Quantity = value === "Sell" ? this.checkStock(row.StockCode, row.Quantity) : row.Quantity
        }
        else if(i==7){
            row.LMPrice = row.LTP
            row.OrderType = value
            row.LimitPrice = "Current LTP"
        }

        const val = row.Quantity * row.LMPrice
        row.TradeValue = row.Side === "Buy" ? val : -val

        this.calculateCash(data)
    }

    changeInput(index, i, value) {
        let data = JSON.parse(JSON.stringify(this.state.data));
        if (i == 2) {
            data[index].Quantity = isNaN(parseInt(value)) ? value.length > 0 ? data[index].Quantity : 0 : parseInt(value)
        }
        else if (i == 8) {
            const regexp = /^[0-9]{0,7}\.?[0-9]{0,2}$/;
            data[index].ErrMsg = null;

            let j = 0, n = value.length;
            while (j < n && value[j] == '0') j++;
            value = value.substring(j);

            if (value.length == 0) {
                data[index].LMPrice = 0;
            } else {
                if (value[0] === '.') {
                    value = '0' + value;
                }
                if (regexp.test(value)) {
                    data[index].LMPrice = value;
                }
            }
        }

        if ((data[index].LMPrice * 1000) % (data[index].TickSize * 1000) != 0) {
            data[index].ErrMsg = `Tick size must be ${data[index].TickSize}`
        }
        if (data[index].LMPrice * 1000 < data[index].LowerLimit * 1000 || data[index].LMPrice * 1000 > data[index].HigherLimit * 1000) {
            data[index].ErrMsg = `Must be in limit [${data[index].LowerLimit}, ${data[index].HigherLimit}]`;
        }

        const val = data[index].Quantity * data[index].LMPrice
        data[index].TradeValue = data[index].Side === "Buy" ? val : -val

        this.calculateCash(data);
    }

    increaseInput(index, i, key) {

        if (key === "ArrowUp" || key === "ArrowDown") {

            let data = JSON.parse(JSON.stringify(this.state.data));
            data[index].ErrMsg = null;

            if (i == 2) {
                const quant = data[index].Quantity
                data[index].Quantity = key === "ArrowUp" ? quant + 1 : quant - 1;
            }
            else if (i == 8) {
                const tick = data[index].TickSize * 1000, lm = parseInt(data[index].LMPrice * 1000);
                data[index].LMPrice = key === "ArrowUp" ? parseInt(lm + tick) / 1000 : parseInt(lm - tick) / 1000;
            }

            const val = data[index].Quantity * data[index].LMPrice
            data[index].TradeValue = data[index].Side === "Buy" ? val : -val

            if ((data[index].LMPrice * 1000) % (data[index].TickSize * 1000) != 0) {
                data[index].ErrMsg = `Tick size must be ${data[index].TickSize}`
            }
            if (data[index].LMPrice * 1000 < data[index].LowerLimit * 1000 || data[index].LMPrice * 1000 > data[index].HigherLimit * 1000) {
                data[index].ErrMsg = `Must be in limit [${data[index].LowerLimit}, ${data[index].HigherLimit}]`;
            }

            this.calculateCash(data);
        }
    }

    deleteStock(index){
        let data = JSON.parse(JSON.stringify(this.state.data));
        data.splice(index, 1);
        this.setState({
            data: data
        }, () => {
            this.calculateCash(data);
        })
    }

    addTableRow(stock){
        let {data, addable, Existing, AccountCode} = this.state;
        if(!addable){
            this.displayAlert('Please select portfolio!',2)
            return;
        };
        let code = (stock.exchange.exchange.toLowerCase()=='nse')? stock.nse_code : stock.code;
        if(!(data.map(el => el.isin).includes(stock.isin))){
            const obj = {
                Code: stock['code'],
                Exchange: stock.exchange.code,
                AccountCode: AccountCode,
                StockCode: code,
                Company : stock['company'],
                Quantity: 0,
                Side: "Buy",
                isin: stock.isin,
                Bid: 0,
                Ask: 0,
                LTP: 0,
                OrderType: "Market",
                LimitPrice: "Current LTP",
                LMPrice: 0,
                TradeValue: 0,
                DisErr: false,
                ErrMsg: null,
            }

            Axios.get(`${REQUEST_BASE_URL}/fetch_tick_size/${stock.exchange.exchange}/${obj.Code}`).then((response) => {
                const res = response.data;
                obj.TickSize = res.tick_size
                obj.HigherLimit = res.higher_circuit_limit
                obj.LowerLimit = res.lower_circuit_limit

                let a = Existing.filter(el => el.isin == obj.isin);
                obj.QOrderBook = a.length == 1 ? a[0].QOrderBook : a.length > 1 ? a.reduce((a, b) => a + b.QOrderBook, 0) : 0;
                obj.QDP = a.length == 1 ? a[0].QDP : a.length > 1 ? a.reduce((a, b) => a + b.QDP, 0) : 0;
                obj.Status = '-';

                data.push(obj);
                this.setState({ fileloaded: true })
                this.calculateCash(data)
            })
        }else{
            this.displayAlert("You already include one stock with the same isin. Please check and either buy in nse or bse. Or buy nse in one order and bse in another order.", 10)
        }

    }

    importFile(e){
        const { addable } = this.state;
        if(!addable){
            this.displayAlert('Please select portfolio!',2)
            return;
        };
        e.preventDefault();
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('accept', '.csv');
        fileSelector.click();

        fileSelector.onchange = (evt) => {

            const file = evt.target.files[0];
            // document.getElementById("file-upload-filename").innerHTML = file.name;
            const reader = new FileReader();

            reader.readAsBinaryString(file);
            reader.onload = (event) => {
                const doc = event.target.result;
                this.setState({
                    importloading : true,
                    data : []
                });
                if(this.statusinterval)
                {
                    clearInterval(this.statusinterval)
                }
                this.validateCsv(doc);
            };
        }
    }
    
    convertCsv = (doc) => {
        var workbook = XLSX.read(doc, { type: 'binary' });
        let sheetName = workbook.SheetNames[0]
        return XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName]);
    }

    async validateCsv(doc){
        const { Existing, AccountCode } = this.state;
        let unverifiedStock = [];
        doc = this.convertCsv(doc);
        let row = doc.split('\n');
        if(row[row.length-1]=="")   row.pop();

        let newData = [];
        let buyData = []
        let sellData = [];

        let col = row[0].split(',');

        if(col[0]!="AccountCode"||col[1]!="StockCode"||col[2]!="Quantity"||col[3]!="Side"){
            this.displayAlert("File import not in correct format.");
            return;
        }
        else{

            for(let i=1; i<row.length; i++){     
                col = row[i].split(',');
                if(isNaN(parseInt(col[2])) || (col[3]!=="Buy" && col[3]!=="Sell") ){
                    unverifiedStock.push(col[1]);
                    continue;
                }
                if(col[0]!=AccountCode){
                    this.displayAlert("Wrong AccountCode. Please check it and import again.");
                    return;
                }

                const orderdata = {
                    AccountCode: col[0],
                    StockCode: col[1],
                    Company : '',
                    Quantity: col[2],
                    Side: col[3],
                    Bid: 0,
                    Ask: 0,
                    LTP: 0,
                    OrderType: "Market",
                    LimitPrice: "Current LTP",
                    LMPrice: 0,
                    TradeValue: 0
                }

                if(orderdata.Side.toLowerCase() === 'buy')
                {
                    buyData.push(orderdata)
                }
                else if(orderdata.Side.toLowerCase() === 'sell')
                {
                    sellData.push(orderdata);
                }
                
            }
        }

        newData = sellData.concat(buyData);

        let verified = (await Axios.post(`${REQUEST_BASE_URL}/backoffice/validate_order_import`, { data: newData })).data;
        let msg = "";
        if(unverifiedStock.length>0)
            msg += `${unverifiedStock.join(' ,')} was not imported because of wrong input.  `
        if(verified.unverifiedStock.length>0)
            msg += `${verified.unverifiedStock.join(' ,')} was not imported because of wrong StockCode\n.  `;
        if(msg != ""){
            msg += "Import again";
            this.displayAlert(msg);
        }

        //for testing
        for(let d of verified.portfolio){
            let a = Existing.filter(el => el.isin==d.isin)
            d.QOrderBook = a.length==1? a[0].QOrderBook : a.length>1? a.reduce((a,b)=>a+b.QOrderBook, 0) : 0;
            d.QDP = a.length==1? a[0].QDP : a.length>1? a.reduce((a,b)=>a+b.QDP, 0) : 0;
            d.Status = '-';
            d.DisErr = false;
            d.ErrMsg = null;
        }

        this.setState({
            fileloaded : true,
            importloading : false
        })
       

       this.calculateCash(verified.portfolio)
    }

    checkStock(stockCode, quantity){
        const { Existing } = this.state;
        for(let el of Existing){
            // console.log(el.StockCode,el.Quantity)
            if(el.StockCode===stockCode){
                return Math.min(el.Quantity, quantity)
            }
        }

        return 0;
    }

    displayAlert = (msg,time) => {
        Alert({
            TitleText : 'Warning',
            Message : msg,
            AutoClose : {
                Active : true,
                Line : true,
                LineColor : '#470985',
                Time : time? time : 5
            }
        })

        this.setState({
            importloading : false
        })
    }

    calculateCash(data){
        const { CurrentCash, CurrentCashAPI } = this.state;

        let totalTrade = 0, tradeVal = 0
        data.forEach((v, i) => {
            totalTrade += Math.abs(parseFloat(v.TradeValue))
            tradeVal += parseFloat(v.TradeValue)
        })

        const proposedCash = CurrentCash - tradeVal - totalTrade * 0.003;
        const proposedCashAPI = CurrentCashAPI - tradeVal - totalTrade * 0.003;

        // if(proposedCash < 0){

        //     this.displayAlert(`Current Proposed Cash can't be less than 0`)
        //     return false
        // }


        this.setState({
            data: data,
            TotalTrade: totalTrade,
            ApproxBrokerage: totalTrade*0.003,
            ProposedCash: proposedCash,
            ProposedCashAPI: proposedCashAPI
        }, () => this.checkOrderPlaceable())

        return true
    }

    checkOrderPlaceable(){
        const { data, orderplaceable, ProposedCash, ProposedCashAPI } = this.state
        let b = data.map(el=>el.Bid)
        let a = data.map(el=>el.Ask)
        let l = data.map(el=>el.LTP)

        //console.log(a,b,l);

        let b_u = b.filter(el=>el==undefined || el==0)
        let a_u = a.filter(el=>el==undefined || el==0)
        let l_u = l.filter(el=>el==undefined || el==0)

        if (b_u.length == 0 && a_u.length == 0 && l_u.length == 0 && ProposedCash >= 0 && ProposedCashAPI >= 0) {
            this.setState({ orderplaceable : true})
        }else{
            this.setState({ orderplaceable: false })
        }
    }


    ExecuteOrder()
    {
        const { data, PortfolioName, newPortfolioName, isNewPortfolio, ProposedCash, ProposedCashAPI } = this.state;

        let checkquantity = 'valid';

        data.forEach((d,indx)=>{
            let currentquantity = d['QOrderBook'] === 0 ? Math.max(d['QDP']) : Math.min(d['QOrderBook'], d['QDP']);
            if(d['Quantity'] <= 0)
            {
                checkquantity = 'zero';
            }
            else if(d['Side'] === 'Sell' && d['Quantity'] > currentquantity)
            {
                checkquantity = 'invalid'
            }
        });

        if (ProposedCash < 0) {
            this.displayAlert("You don't have enough cash!", 2);
        }
        else if (ProposedCashAPI < 0) {
            this.displayAlert("Please wait until margin release of the sell orders", 2);
        }
        else if (checkquantity === 'valid')
        {
            let portfolio;
            if(isNewPortfolio)
            {
               if(newPortfolioName == ''){
                this.displayAlert(`Portfolio name can not be empty`,2)
                return;
               }
               portfolio = newPortfolioName;
            }
            else
            {
                portfolio = PortfolioName;
            }
            // console.log(portfolio)
            Axios.post(`${REQUEST_BASE_URL}/backoffice/placeorder`,{
                data : data,
                portfolio : portfolio
            }).then((res)=>{
                if(!res.data.error)
                {
                    console.log(res);
                    this.SetPlaced();
                    this.setState({
                        user_order_id : res.data.user_order_id
                    })
                    $('#success').text("Order Placed Successfully");
                    $('#success').css("opacity",1);
                    setTimeout(()=>{
                        $('#success').css("opacity",0);
                    },3000)

                    this.checkStatus();
                }
                
    
            }).catch((err)=>{
                console.log(err);
            })
            console.log('go')
        }
        else if(checkquantity === 'zero')
        {
            this.displayAlert("Please make sure quantity is more than 0",2);
        }
        else if(checkquantity === 'invalid')
        {
            this.displayAlert("Sell quantity can not be more than holdings",2);
        }
    }

    SetPlaced()
    {
        const {data} = this.state;
        data.forEach((d,indx)=>{
            d['Status'] = 'Placed'
        });
        this.setState({
            data:data
        })
    }

    checkStatus()
    {
        this.statusinterval = setInterval(()=>{
            const {AccountCode,user_order_id,data,dataupdated} = this.state;
            if(AccountCode !== '' && user_order_id)
            {
                // get the status of the order
                Axios.get(`${REQUEST_BASE_URL}/backoffice/orderstatus/${AccountCode}/${user_order_id}`).then((response)=>{
                    if(!response.data.error)
                    {
                        const statusdata = response.data.data;
                        statusdata.forEach((sdata,indx)=>{
                            const isin = sdata['isin'];
                            const status = sdata['Status'];
                            const stockindex = data.map((d)=>{ return d.isin}).indexOf(isin);
                            console.log(stockindex)
                            if(stockindex!=-1 && stockindex<data.length)
                            {
                                data[stockindex]['Status'] = status;
                            }
                        })
                    }
                });

                this.setState({
                    data : data,
                    dataupdated : !dataupdated
                });
            }
        },5000)
    }

    OpenModifyPopup(config) {
        // console.log(config);
        this.setState({
            modifyconfig: config
        });
    }

    CloseModifyPopup() {
        this.setState({
            modifyconfig: false
        })
    }

    
    render() {

        const { loggedin, logincheck, logout, tradingOption, data, dataLoaded, fileloaded, importloading, modifyconfig, ProposedCash, ProposedCashAPI, activeWindow, AccountCode, AccountPan, Existing, PortfolioName } = this.state;
        const curr = (price) => price.toLocaleString('en-IN', { currency: 'INR', minimumFractionDigits: 2, maximumFractionDigits: 2 });

        return (
            <>

                {loggedin ? 

                    <>
                    {logout && <Navigate to="/" />}

                        {modifyconfig && <OrderModifyPopUp
                            pannumber={AccountPan}
                            clientid={AccountCode}
                            {...modifyconfig}
                            closepopup={this.CloseModifyPopup}
                        />}

                        <div className="getOrder__container">
                            <this.BackofficeHeader />
                            {tradingOption === 'demat' ?
                                <>
                                    <this.TopSection />
                                    {dataLoaded ?
                                        <>
                                            <this.AddImportSection />

                                            {activeWindow === 1 ?

                                                data.length > 0 ?
                                                    <div className="getOrder__table">

                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <this.THead th={["Account Code", "StockCode", "Quantity", "Side",
                                                                        "Bid", "Ask", "LTP", "Order Type", "Limit/Market Price",
                                                                        "Trade Value", "Holdings", "Status", ""]} />
                                                                </tr>
                                                            </thead>
                                                            <tbody>    

                                                                {data.length > 0 ? data.map((v, i) =>

                                                                    <this.TBody key={v.StockCode} td={[
                                                                        v.AccountCode, v.StockCode, v.Quantity, v.Side, "Bid", "Ask", "LTP", v.OrderType, 
                                                                        v.LMPrice, curr(v.TradeValue), (v.QOrderBook + " , " + v.QDP), v.Status
                                                                    ]} index={i} />
                                                                ) : null}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    :
                                                    <div className="getOrder__table__empty">
                                                        {importloading ?
                                                            <Pulse />
                                                            :
                                                            <>
                                                                <img src={IMPORTICON} alt="" />
                                                                <p>Search symbol, or <span onClick={(e) => this.importFile(e)}>import</span> the file</p>
                                                            </>
                                                        }
                                                    </div>
                                                :
                                                null
                                            }
                                            {activeWindow === 2 &&
                                                <OrderBook pannumber={AccountPan} type="P" OpenModifyPopup={this.OpenModifyPopup} />
                                            }
                                            {activeWindow === 3 &&
                                                <ClientHoldings pannumber={AccountPan} holdings={Existing} portfolio={PortfolioName} />
                                            }
                                            <div className='getOrder__footer'>
                                                {fileloaded && data.length > 0 && activeWindow == 1 &&
                                                    <div className="order__button">
                                                        {this.state.orderplaceable ?
                                                            <button onClick={() => { this.ExecuteOrder() }}>Place Order</button>
                                                            :
                                                            <button style={{ opacity: 0.5, cursor: 'not-allowed' }}>Place Order</button>
                                                        }
                                                    </div>
                                                }
                                                <div className='getOrder__message'>
                                                    {/* <p id="error" style={{ opacity: 0 }}>Quantity exceeds</p> */}
                                                    <p id="error" style={{ opacity: ProposedCash >= 0 ? 0 : 1 }}>You don't have enough cash. Please verfiy orders!</p>
                                                    {ProposedCash >= 0 && <p id="error" style={{ opacity: ProposedCashAPI >= 0 ? 0 : 1 }}>Please wait until margin release of the sell orders</p>}
                                                    <p id="success" style={{ opacity: 0 }}></p>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="container__empty">
                                                <p>Please select an account to get started</p>
                                            </div>
                                        </>
                                    }
                                </>
                                :
                                <CPTrade />
                            }
                        </div>
                    </>
                    :
                    <>
                        {logincheck ? 
                            <Navigate to="/404" />
                            :
                            <div className="backoffice__login__check">
                                <Spinner size={50}/>
                            </div>
                        }
                    </>
                }
                
            </>
        );
    }
}


// function Backoffice() {
//   return (
//     <div>Backoffice</div>
//   )
// }

export default Backoffice;