import React, { useState, useEffect } from "react";
import Axios from "axios";
import { GetCookie } from "../../../exports/CookieManagement";
import WatchlistPopup from "../AppPopups/WatchlistPopup/WatchlistPopup";
import WatchListItem from "./WatchListItem";
import Pulse from "../../Loader/Pulse";
import { Add, AddSquare } from "../../CustomComponents/SwiftIcon/Icons";
import { Alert } from "../CustomChartComponents/CustomAlert/CustomAlert";
import SwiftToolTip from "../../CustomComponents/SwiftToolTip/SwiftToolTip";
import SwiftModal from "../../CustomComponents/SwiftModal/SwiftModal";
import { useQuery } from "@tanstack/react-query";
import useAccount from "../../../hooks/useAccount";
import { GetRoleType } from "../../../exports/RoleTypes";
import { numberFormat } from "../../../exports/UtilFunctions";
import { COLOR_VARS } from "../../../exports/ColorVars";
import SwiftInfoMessage from "../../CustomComponents/SwiftInfoMessage/SwiftInfoMessage";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function WatchList() {
  const [watchlistPopup, setWatchlistPopup] = useState(false);
  const [watchlist, setWatchlist] = useState(false);
  const [cash, setCash] = useState(undefined);

  const user_role_type = GetRoleType(GetCookie("role"));

  async function FetchWatchList() {
    const account_token = GetCookie("token");
    const response = await fetch(
      `${REQUEST_BASE_URL}/fetch_watchlist?token=${account_token}`
    );
    const data = await response.json();
    if (data.watchlist) {
      return data.watchlist;
    }
    return [];
  }

  const { loading, account } = useAccount();
  const { data, refetch } = useQuery(["watchlist"], FetchWatchList);

  useEffect(() => {
    setWatchlist(data);
  }, [data]);

  useEffect(() => {
    if (!account) return;

    const account_code = account.account_code;

    async function GetCash() {
      const response = await fetch(
        `${REQUEST_BASE_URL}/clientinfo/cashposition?account_code=${account_code}`
      );
      const data = await response.json();
      return data;
    }

    GetCash()
      .then((data) => {
        const { error, cash = 0 } = data;
        if (error) {
          setCash(0);
        } else {
          setCash(cash);
        }
      })
      .catch(() => {
        setCash(0);
      });
  }, [account]);

  function PushWatchList(watchlist) {
    const account_token = GetCookie("token");
    Axios.post(`${REQUEST_BASE_URL}/push_watchlist?token=${account_token}`, {
      watchlist: watchlist,
    })
      .then((response) => {
        const data = response.data;
        console.log(data);
        refetch();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function DeleteSymbol(index) {
    let stock = watchlist[index];
    watchlist.splice(index, 1);
    PushWatchList(watchlist);
    DisplaySuccess(`${stock.symbol} removed successfully`);
    setWatchlist([...watchlist]);
  }

  function AddSymbol(stock) {
    const { exchange, nse_code, code } = stock;
    let symbol = ["NSE", "NFO"].includes(exchange.exchange) ? nse_code : code;
    if (!watchlist.map((el) => el.symbol).includes(symbol)) {
      watchlist.push({ symbol: symbol, id: watchlist.length + 1 });
      PushWatchList(watchlist);
      DisplaySuccess(`${symbol} added successfully`);
      setWatchlist([...watchlist]);
    }
  }

  function DisplaySuccess(message) {
    Alert({
      TitleText: "Success",
      Message: message,
      AutoClose: {
        Active: true,
        Line: true,
        LineColor: COLOR_VARS["SWIFT_COLOR1"],
        Time: 3,
      },
    });
  }

  function HandleDragStart(e, index) {
    e.dataTransfer.setData("draggedIndex", index);
  }

  function HandleDragOver(e, index) {
    e.preventDefault();
  }

  function HandleDrop(e, index) {
    e.preventDefault();
    const draggedIndex = e.dataTransfer.getData("draggedIndex");
    const updated_watchlist = [...watchlist];
    const [draggedItem] = updated_watchlist.splice(draggedIndex, 1);
    updated_watchlist.splice(index, 0, draggedItem);
    PushWatchList(updated_watchlist);
    setWatchlist([...updated_watchlist]);
  }

  if (!watchlist) {
    return (
      <>
        <div className="cp__watchlist__loading">
          <Pulse />
          <p>Loading Watchlist...</p>
        </div>
      </>
    );
  }

  return (
    <>
      {watchlistPopup && (
        <>
          <SwiftModal
            top="5%"
            closeModal={() => {
              setWatchlistPopup(false);
            }}
          >
            <WatchlistPopup
              AddSymbol={AddSymbol}
              ClosePopup={() => {
                setWatchlistPopup(false);
              }}
            />
          </SwiftModal>
        </>
      )}
      <div className="cp__watchlist">
        <div className="cp__watchlist__text">
          <p>Watchlist</p>
          {user_role_type === "CUSTOMER" && (
            <>
              <div className="cash-value">
                {cash !== undefined && (
                  <>
                    <p>
                      Cash:&nbsp;<span>{numberFormat(cash)}</span>
                    </p>
                    <SwiftInfoMessage
                      size={14}
                      color={COLOR_VARS["SWIFT_COLOR4"]}
                      position="bottom"
                      align="left"
                      message="If you have sold shares today, partial credit will take atleast 90 minutes to show"
                      width={250}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <SwiftToolTip title="Add Watchlist" placement="bottom">
          <div
            className="cp__watchlist__edit"
            onClick={() => {
              setWatchlistPopup(true);
            }}
          >
            <Add size={24} color={COLOR_VARS["SWIFT_COLOR4"]} />
          </div>
        </SwiftToolTip>
      </div>

      {!Array.isArray(watchlist) || watchlist.length === 0 ? (
        <>
          <div className="cp__shares__container empty">
            <AddSquare size={40} color={COLOR_VARS["SWIFT_COLOR4"]} />
            <p className="empty__watchlist">
              Your watchlist is empty
            </p>
            <div
              className="create__watchlist"
              onClick={() => {
                setWatchlistPopup(true);
              }}
            >
              Create Watchlist
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="cp__shares__container">
            {watchlist.map((w, index) => {
              return (
                <WatchListItem
                  key={w.symbol}
                  item={w}
                  index={index}
                  handleDragStart={HandleDragStart}
                  handleDragOver={HandleDragOver}
                  handleDrop={HandleDrop}
                  deleteItem={DeleteSymbol}
                />
              );
            })}
          </div>
        </>
      )}
    </>
  );
}

export default WatchList;
