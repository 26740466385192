import React from 'react';
import Axios from 'axios';
import PropTypes from "prop-types";
import OrderStatus from '../OrderStatus/OrderStatus';
import PriceBox from '../../CustomChartComponents/PriceBox/PriceBox';
import CustomSelect from '../../CustomChartComponents/CustomSelect/CustomSelect';
import CloseIcon from '../../../../assets/icons/closesmall.svg';
import "../../../../css/Popup/OrderModifyPopup.css";
import { StockPrice } from '../../../../exports/StockPrice';
import AnimatedDigit from '../../AnimatedDigit';
import Pulse from '../../../Loader/Pulse';
import { readMarketData, setChange } from '../../../../exports/FormatData';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;
const LIVEFEED_BASE_URL = process.env.REACT_APP_LIVEFEED_BASE_URL;


class OrderModifyPopUp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pannumber: this.props.pannumber,
            clientid: this.props.clientid,
            symbol: this.props.symbol,
            side: this.props.side,
            type: this.props.type,
            quantity: this.props.quantity,
            orderprice: this.props.orderprice,
            triggerprice: this.props.triggerprice,
            portfolio: this.props.portfolio,
            omsOrderId: this.props.omsOrderId,
            cashposition: false,
            stockDetails: false,
            stockdataloaded: false,
            stockdata: false,
            priceactive: true,
            triggeractive: true,
            errors: {
                quantity_error: false,
                price_error: false,
                trigger_error: false
            },
            executing: false,
            ticksize: 0.05,
            higherlimit: false,
            lowerlimit: false,
        }
        this.setComponentRef = this.setComponentRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.changeType = this.changeType.bind(this);
        this.closeOrderStatus = this.closeOrderStatus.bind(this);

    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

        const { pannumber, symbol, side } = this.state;

        this.getStockDetails(symbol).then(() => {
            Axios.get(`${REQUEST_BASE_URL}/clientinfo/cashposition?pan=${pannumber}`)
                .then((response) => {
                    if (!response.data.error) {
                        this.setState({
                            cashposition: parseFloat(response.data.cash).toFixed(2),
                        }, () => {
                            this.loadOrder();
                        })

                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        })

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.ComponentRef && !this.ComponentRef.contains(event.target)) {

            this.props.closepopup();
        }
    }

    setComponentRef(node) {
        this.ComponentRef = node;
    }

    getQunatity(portfolio) {

        if (portfolio !== '') {
            const { pannumber, stockDetails } = this.state;
            const ISIN = stockDetails['stockISIN'];

            Axios.get(`${REQUEST_BASE_URL}/clientinfo/stockqunatity?pan=${pannumber}&isin=${ISIN}&portfolio=${portfolio}`)
                .then((response) => {
                    if (!response.data.error) {

                        this.setState({
                            holdings: parseInt(response.data.quantity),
                        });

                    }
                    else {
                        this.setState({
                            holdings: 0
                        })
                    }
                })
        }

    }

    async getStockDetails(stocksymbol) {
        return new Promise((resolve, reject) => {
            Axios.get(`${REQUEST_BASE_URL}/StockDetails/${stocksymbol}`)
                .then((response) => {
                    if (!response.data.error) {
                        const data = response.data.stock;
                        this.setState({
                            stockDetails: {
                                stockISIN: data.isin,
                                stockCode: parseInt(data.code),
                                stockSymbol: data.ric_code,
                                stockName: data.name,
                                stockNSECode: data.nse_code,
                                stockBSECode: data.bse_code,
                                stockExchange: data.exchange,
                                stockIndustry: data.industry
                            }
                        });
                        resolve()
                    }
                    else {
                        resolve()

                    }
                })
                .catch((err) => {
                    console.log(err);
                    resolve()
                });
        })

    }

    async fetchTickSize() {

        const { stockDetails } = this.state;
        Axios.get(`${REQUEST_BASE_URL}/GetScripInfo/${stockDetails.stockExchange.exchange}/${stockDetails.stockCode}`).then((response) => {
            const data = response.data;
            if (!data.error) {
                this.setState({
                    ticksize: data.stock.tick_size,
                    higherlimit: parseFloat(data.stock.higher_circuit_limit).toFixed(2),
                    lowerlimit: parseFloat(data.stock.lower_circuit_limit).toFixed(2)
                })
            }
        })
    }

    loadOrder() {
        const { portfolio } = this.state;
        this.getQunatity(portfolio);
        this.fetchTickSize().then(() => {
            this.makeSocketConnection()
                .then(() => {
                    this.checkConnection();
                });
        })

        this.changeActivity();
        this.changeOrderPrice();


    }

    feedLiveData(ws) {

        const { oldstockDetails, stockDetails } = this.state;

        ws.send(JSON.stringify({
            "a": "subscribe",
            "v": [[stockDetails.stockExchange.code, stockDetails.stockCode]],
            "m": "marketdata"
        }
        ));
        ws.onmessage = (response) => {


            const { actiontype } = this.state;

            var reader = new FileReader();

            reader.readAsArrayBuffer(response.data);
            let convertedData;
            reader.onloadend = async (event) => {
                let data = new Uint8Array(reader.result);
                let change_per;


                if (response.data.size >= 86) {
                    if (this.state.stockdata) {
                        convertedData = readMarketData(data, this.state.stockdata['close_price']);
                    }
                    else {
                        convertedData = readMarketData(data, -1);
                        let stockdata = convertedData.livedata;

                        let livedata = stockdata;
                        convertedData = {
                            livedata
                        }
                    }

                    let livedata = convertedData.livedata;
                    if (livedata.last_traded_price === livedata.close_price) {
                        let closeprices = await StockPrice(parseInt(stockDetails.stockExchange.code), parseInt(stockDetails.stockCode));
                        let last_close;
                        if (!closeprices.error) {
                            closeprices = closeprices.closearray;
                            let indx = 0;
                            while (indx < closeprices.length) {
                                last_close = closeprices[indx++];
                                if (last_close !== livedata.close_price) {
                                    break;
                                }
                            }
                        }
                        const { change_price, change_percentage } = setChange(livedata.last_traded_price, last_close);
                        livedata['change_price'] = change_price;
                        livedata['change_percentage'] = change_percentage;
                    }

                    change_per = livedata.change_percentage;

                    this.setState({
                        stockdata: livedata
                    });
                    this.changeOrderPrice();
                }
            }
        }

        setInterval(() => {
            ws.send(JSON.stringify({
                "a": "h",
                "v": [[stockDetails.stockExchange.code, stockDetails.stockCode]],
                "m": ""
            }
            ));
        }, 10 * 1000);
    }

    async makeSocketConnection() {
        return new Promise((resolve, reject) => {
            let ws = new WebSocket(LIVEFEED_BASE_URL);
            ws.onopen = () => {
                // console.log('connection done');
                this.setState({
                    ws: ws,
                    FeedConnection: true
                });
                resolve(ws)
            }
            ws.onerror = () => {
                console.log('Connection Error');
                this.setState({
                    ws: null,
                    FeedConnection: false
                })
            }
        })

    }

    checkConnection() {
        if (this.state.FeedConnection) {
            this.feedLiveData(this.state.ws);
        }

    }

    changeType(type) {
        this.setState({
            type: type,
            triggerprice: 0
        }, () => {
            this.changeActivity();
            this.changeOrderPrice();
        })
    }

    changeActivity() {
        const { type } = this.state;
        if (type === 'M') {
            this.setState({
                priceactive: false,
                triggeractive: false
            });
        }
        else if (type === 'L') {
            this.setState({
                priceactive: true,
                triggeractive: false
            });
        }
        else if (type === 'SL') {
            this.setState({
                priceactive: true,
                triggeractive: true
            });
        }
        else if (type === 'SLM') {
            this.setState({
                priceactive: false,
                triggeractive: true
            });
        }

    }

    changeOrderPrice() {
        const { type, side, stockdata, orderprice } = this.state;
        let price;
        if (type === 'M' || type === 'SLM') {
            if (side === 'B') {
                price = parseFloat(stockdata.best_ask_price);
            }
            else {
                price = parseFloat(stockdata.best_bid_price);
            }
            this.setState({
                orderprice: price && !isNaN(price) ? parseFloat(price).toFixed(2) : 0.00
            }, () => {
                this.changeVariables();
                this.showErrors();
            })
        }
        else if (type === 'L' || type === 'SL') {
            price = orderprice;
            this.changeVariables();
            this.showErrors();
        }


    }

    changeVariables() {
        const { quantity, side, orderprice, cashposition } = this.state;
        let totaltrade, netcash;
        totaltrade = parseFloat(quantity * parseFloat(orderprice)).toFixed(2);
        totaltrade = isNaN(totaltrade) || totaltrade < 0 ? 0 : totaltrade;
        if (side === 'B') {
            netcash = parseFloat(parseFloat(cashposition) - parseFloat(totaltrade)).toFixed(2);
        }
        else {
            netcash = parseFloat(parseFloat(cashposition) + parseFloat(totaltrade)).toFixed(2);
        }

        this.setState({
            totaltrade,
            netcash
        });

    }

    showErrors() {
        let { type, quantity, orderprice, triggerprice, netcash, ticksize, higherlimit, lowerlimit } = this.state;
        let errormessage = false;
        let q_err = false, p_err = false, t_err = false;
        lowerlimit = parseFloat(lowerlimit);
        higherlimit = parseFloat(higherlimit);


        if (quantity <= 0 || !this.isInt(quantity)) {
            errormessage = 'Invalid Quantity';
            q_err = true;
        }
        else if (orderprice <= 0) {
            errormessage = 'Invalid Price Value';
            p_err = true;
        }
        else if (!this.isMultiple(orderprice, ticksize)) {
            errormessage = 'Price must be multiple of tick size';
            p_err = true
        }
        else if (orderprice < lowerlimit || orderprice > higherlimit) {
            errormessage = 'Price must be within the valid range (' + lowerlimit + '-' + higherlimit + ')';
            p_err = true
        }
        else if (type === 'SL' || type === 'SLM') {
            if (triggerprice <= 0) {
                errormessage = 'Invalid Trigger Price';
                t_err = true
            }
        }
        else if (netcash <= 0) {
            errormessage = 'Margin not available';
        }
        this.setState({
            errormessage: errormessage,
            errors: {
                quantity_error: q_err,
                price_error: p_err,
                trigger_error: t_err
            }
        });
    }

    executeOrder() {
        // console.log('execute')
        this.setState({
            executing: true
        });
        const { pannumber, clientid, omsOrderId, quantity, orderprice, triggerprice, type, portfolio, stockDetails } = this.state;
        const exchange = stockDetails.stockExchange.exchange;
        const symbol = exchange === 'NSE' ? stockDetails.stockNSECode : stockDetails.stockBSECode;
        const details = {
            'clientId': clientid,
            'pan': pannumber,
            'omsOrderId': omsOrderId,
            'name': symbol,
            'exchange': exchange,
            'ordertype': this.getOrderType(type),
            'orderprice': orderprice,
            'quantity': quantity
        };

        // console.log(details);
        Axios.post(`${REQUEST_BASE_URL}/order/modify`, { detail: details }).then((response) => {
            const data = response.data;
            console.log(data);
            this.setState({
                orderdetails: { 'orderinfo': data.orderinfo.data },
                executing: false
            })

        }).catch(() => {
            this.setState({
                executing: false
            })
        })
    }

    getOrderType(type) {
        if (type === 'M') {
            return 'MARKET'
        }
        else if (type === 'L') {
            return 'LIMIT';
        }
        else {
            return type;
        }

    }

    isMultiple(num1, num2) {
        num1 = parseFloat(parseFloat(num1).toFixed(2) * 100).toFixed(2)
        num2 = parseFloat(parseFloat(num2).toFixed(2) * 100).toFixed(2)

        if (num1 % num2 == 0) {
            return true;
        }
        else {
            return false;
        }
    }

    isInt(num) {
        num = parseFloat(num);
        return Number(num) === num && num % 1 === 0;
    }

    convertIntoPriceFormat(num, frac = 2) {
        if (num) {
            return num.toLocaleString('en-IN', {
                minimumFractionDigits: frac,
                currency: 'INR'
            });
        }
        else {
            return num;
        }

    }

    closeOrderStatus(back = false) {
        this.setState({
            orderdetails: false
        });
        this.props.onclose();
        if (!back) {
            this.props.closepopup();
        }
    }


    render() {

        const { pannumber, stockDetails, stockdataloaded, stockdata, searchopen, cashposition } = this.state;
        const { side, type, quantity, holdings } = this.state;
        const { ticksize, priceactive, triggeractive, orderprice, triggerprice, totaltrade, netcash, errormessage, errors } = this.state;
        const { executing, orderdetails } = this.state;
        const { closepopup } = this.props;

        let trade_price = this.convertIntoPriceFormat(stockdata.last_traded_price);
        let change_price = parseFloat(stockdata.change_price).toFixed(2);
        let priceClass = change_price >= 0 ? 'positive' : 'negative';



        if (stockdata && !executing) {
            let exchange = stockDetails.stockExchange.exchange;
            let stockname = exchange === 'NSE' ? stockDetails.stockNSECode : stockDetails.stockBSECode;

            if (orderdetails) {
                return <div className='modify-popup-overlay'>
                    <div className='modify-popup'>
                        <OrderStatus pannumber={pannumber} orderdetails={orderdetails} closeOrderStatus={this.closeOrderStatus} />
                    </div>
                </div>
            }

            else {
                return (
                    <div className='modify-popup-overlay'>
                        <div className='modify-popup' ref={this.setComponentRef}>
                            <div className='modify-header'>
                                <div className='modify-title'>
                                    <div className='title-name'>
                                        <span className={side === 'B' ? 'title-action buy' : 'title-action sell'}>{side === 'B' ? "BUY" : "SELL"}</span>
                                        <span className='title-symbol'>{stockname} : {exchange}</span>
                                    </div>
                                    <div className='title-price'>
                                        <div className={'title-price-value ' + priceClass}><AnimatedDigit number={trade_price} size={16} /></div>
                                        <div className={'title-price-change ' + priceClass}><AnimatedDigit number={'(' + change_price + ')'} size={16} /></div>

                                    </div>
                                </div>
                                <div className='modify-close' onClick={() => { closepopup() }}>
                                    <img src={CloseIcon} alt="X" />
                                </div>
                            </div>
                            <div className='modify-body'>
                                {/* <div className='modify-body-row'>
                                    Current Order : Buy 13 shares @12.45
                                </div> */}
                                <div className={side === 'B' ? 'modify-body-row buy' : 'modify-body-row sell'}>
                                    <div
                                        className={type === 'M' ? 'modify-type-box active' : 'modify-type-box'}
                                        onClick={() => { this.changeType('M') }}>
                                        MARKET
                                    </div>
                                    <div
                                        className={type === 'L' ? 'modify-type-box active' : 'modify-type-box'}
                                        onClick={() => { this.changeType('L') }}>
                                        LIMIT
                                    </div>
                                    <div
                                        className={type === 'SL' ? 'modify-type-box active' : 'modify-type-box'}
                                        onClick={() => { this.changeType('SL') }}>
                                        SL
                                    </div>
                                    <div
                                        className={type === 'SLM' ? 'modify-type-box active' : 'modify-type-box'}
                                        onClick={() => { this.changeType('SLM') }}>
                                        SLM
                                    </div>
                                </div>
                                <div className='modify-body-row modify-box'>
                                    <PriceBox
                                        label="Quantity"
                                        helper={"(Holdings : " + holdings + " )"}
                                        valid={!errors.quantity_error}
                                        step={1}
                                        value={quantity}
                                        calculator={true}
                                        changehandler={(quantity) => { this.setState({ quantity: quantity }, () => { this.changeOrderPrice(); }) }}
                                    />
                                    <PriceBox
                                        enabled={priceactive}
                                        valid={!errors.price_error}
                                        label="Price"
                                        step={ticksize}
                                        helper={"(Ticksize : " + ticksize + ")"}
                                        value={orderprice}
                                        changehandler={(price) => { this.setState({ orderprice: price }, () => { this.changeOrderPrice(); }) }}
                                    />
                                    <PriceBox
                                        enabled={triggeractive}
                                        valid={!errors.trigger_error}
                                        step={ticksize}
                                        helper={"(Ticksize : " + ticksize + ")"}
                                        label="Trigger Price"
                                        value={triggerprice}
                                        changehandler={(price) => { this.setState({ triggerprice: price }, () => { this.changeOrderPrice(); }) }}
                                    />
                                    <PriceBox
                                        editable={false}
                                        label="Available Margin"
                                        value={cashposition}
                                    />
                                    <PriceBox
                                        editable={false}
                                        label="Total Trade Value"
                                        value={totaltrade}
                                    />
                                    <PriceBox
                                        editable={false}
                                        valid={parseFloat(netcash) > 0}
                                        label="Net Cash Value"
                                        value={netcash}
                                    />
                                </div>
                                <div className='modify-body-row'>
                                    <div className='modify-error'>
                                        <p>{errormessage}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='modify-footer'>
                                <div className='modify-error'>
                                    <p>{errormessage}</p>
                                </div>
                                <div className='modify-buttons'>
                                    <div onClick={() => { this.executeOrder() }} className={!errormessage ? side == 'B' ? 'modify-button buy' : 'modify-button sell' : 'modify-button modify disabled'}>MODIFY</div>
                                    <div className='modify-button close' onClick={() => { closepopup() }}>Close</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
        else {
            return <div className='modify-popup-overlay'>
                <div className='modify-popup loading' ref={this.setComponentRef}>
                    <Pulse />
                </div>
            </div>;
        }


    }
}

OrderModifyPopUp.propTypes = {
    pannumber: PropTypes.string.isRequired,
    clientid: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    side: PropTypes.oneOf(['B', 'S']),
    type: PropTypes.oneOf(['M', 'L', 'SL', 'SLM']),
    portfolio: PropTypes.string.isRequired,
    quantity: PropTypes.number,
    orderprice: PropTypes.number,
    triggerprice: PropTypes.number,
    omsOrderId: PropTypes.string.isRequired,
    closepopup: PropTypes.func,
    onclose: PropTypes.func
}

OrderModifyPopUp.defaultProps = {
    side: "B",
    type: "M",
    quantity: 1,
    orderprice: 0.00,
    triggerprice: 0.00,
    closepopup: () => { },
    onclose: () => { }
}

export default OrderModifyPopUp;