import React, { useEffect, useState } from "react";
import {
  ArrowLeft2,
  ArrowRight2,
  ICON_COLORS,
  Link,
} from "../CustomComponents/SwiftIcon/Icons";
import { NavLink } from "react-router-dom";

function MoreManagement({ ...props }) {
  const { menuRolesArray } = props;
  const [menu, setMenu] = useState(undefined);

  useEffect(() => {
    const data = menuRolesArray.find((m) => {
      return m["title"] === "GENERAL";
    });

    if (!data) {
      setMenu([]);
    } else {
      setMenu(data.options);
    }
  }, [menuRolesArray]);

  if (!menu || !Array.isArray(menu) || menu.length === 0) return;

  return (
    <div className="more-management">
      <p className="title">Menu</p>
      <div className="more-section">
        {menu.map((m, index) => {
          return (
            <div key={index}>
              <NavLink to={`/account/${m.path}`} className="more-section-row">
                <div className="row-icon">
                  <p>{m.title}</p>
                </div>
                <div>
                  <ArrowRight2 size={24} color={ICON_COLORS["SWIFT_COLOR4"]} />
                </div>
              </NavLink>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MoreManagement;
