export const CUSTOMER_ROLES = ['BRC', 'MAC'];
export const BROKER_ROLES = ['SU', 'TRD', 'OPR', 'MRK', 'OMU', 'TOU', 'TMU', 'OMU', 'TMO'];
export const ADMIN_ROLES = ['SA'];

export function GetRoleType(role) {
    if (CUSTOMER_ROLES.includes(role)) {
        return 'CUSTOMER';
    }
    else if (BROKER_ROLES.includes(role)) {
        return 'BROKER';
    }
    else if (ADMIN_ROLES.includes(role)) {
        return 'ADMIN';
    }
    else {
        return 'OTHER';
    }
}