import React from "react";
import moment from "moment";
import { titleCase } from "../../exports/UtilFunctions";
import { Cross } from "../CustomComponents/SwiftIcon/Icons";
import ConfirmBox from "../CustomComponents/ConfirmBox/ConfirmBox";
import { COLOR_VARS } from "../../exports/ColorVars";

function ReportScheduleRow({ DEVICE_SIZE, data, show, DeleteSchedule }) {
  const { name, report_id, report_date, report_type, report_frequency } = data;

  function GetReportDate(date) {
    return moment(date).format("DD/MM/YYYY");
  }

  function GetReportType(type) {
    if (type === "factsheet") return "Factsheet";
    if (type === "gainloss") return "Gain & Loss";
    return "-";
  }

  function GetReportFrequency(frequency) {
    if (frequency === "daily") return "Daily";
    if (frequency === "weekly") return "Weekly";
    if (frequency === "monthly") return "Monthly";
    return "-";
  }

  function DeleteReport(report_id) {
    ConfirmBox({
      title: "Delete Report Schedule",
      description: (
        <>
          Are you sure you want to delete the report schedule? Keep in mind that
          by doing so, you will no longer receive the reports.
        </>
      ),
      properties: [
        {
          id: "2",
          label: "Delete",
          color: "#192b40",
          bgColor: "#ffffff",
          handleFunction: DeleteSchedule,
          functionArgs: [report_id],
        },
      ],
      cancel: true,
    });
  }

  if (!show) {
    return <></>;
  }

  if (DEVICE_SIZE === "S") {
    return (
      <div className="report-schedule-row">
        <div>
          <span>{titleCase(name)}</span>
          <span>{GetReportType(report_type)}</span>
        </div>
        <div>
          <span>Next Date: {GetReportDate(report_date)}</span>
          <span>{GetReportFrequency(report_frequency)}</span>
        </div>
        <div>
          <span
            onClick={() => {
              DeleteReport(report_id);
            }}
          >
            Delete
          </span>
        </div>
      </div>
    );
  }

  return (
    <tr>
      <td title={titleCase(name)}>{titleCase(name)}</td>
      <td>{GetReportDate(report_date)}</td>
      <td>{GetReportType(report_type)}</td>
      <td>{GetReportFrequency(report_frequency)}</td>
      <td
        onClick={() => {
          DeleteReport(report_id);
        }}
      >
        <Cross size={20} color={COLOR_VARS["SWIFT_COLOR4"]} />
      </td>
    </tr>
  );
}

export default ReportScheduleRow;
