import React, { useState, useEffect } from "react";
import useMarketStock from "../../../hooks/useMarketStock";
import CustomValueSelect from "../../Body/CustomChartComponents/CustomValueSelect/CustomValueSelect";
import { Cross, ReceiptEdit } from "../../CustomComponents/SwiftIcon/Icons";
import SwiftToolTip from "../../CustomComponents/SwiftToolTip/SwiftToolTip";
import SwiftModal from "../../CustomComponents/SwiftModal/SwiftModal";
import useMovingAverage from "../../../hooks/useMovingAverage";
import OrderTypePopup from "./OrderTypePopup";

import { COLOR_VARS } from "../../../exports/ColorVars";

function ReplaceNoise(value) {
  if (
    !value ||
    value === null ||
    value === undefined ||
    value === "" ||
    parseInt(value) === 0
  ) {
    return "-";
  }
  return value;
}

function RouteItem({
  user_role_type,
  modify_order,
  index,
  show,
  setTradeIndex,
  SetOrderType,
  SetLimitPrice,
  RemoveSingleOrder,
  order,
  account_code,
}) {
  // console.log('RouteItem')

  const {
    userOrderID: order_id,
    tradeOrderID: trade_id,
    isin,
    stockCode: name,
    instrument_token: code,
    exchange,
    stockName: fullname,
    orderType: ordertype,
    limitPrice: limitprice,
    Side: side,
    Quantity: quantity,
    Status: status,
    broker,
    algo_config = false,
  } = order;

  const [typeOpen, setTypeOpen] = useState(false);
  const [movingPrice, setMovingPrice] = useState(0);

  const { time_window = false, data_points = false } = algo_config;

  // const stockData = useMarketStock(name);

  const stockData = useMarketStock(name);
  // const movingPrice = 0;

  useEffect(() => {
    let last_traded_price = stockData ? stockData.last_traded_price : 0;
    // console.log(`STOCK PRICE UPDATE: ${last_traded_price}`);
    setTradeIndex(index, quantity * last_traded_price, side);
  }, [stockData?.last_traded_price]);

  useEffect(() => {
    if (ordertype === "MARKET" || ordertype === "LIMIT") {
      return;
    }
    SetLimitPrice(order_id, isin, movingPrice);
  }, [movingPrice]);

  const CurrencyFormat = (num) => {
    if (!num && num != 0) return "-";
    return parseFloat(num).toLocaleString("en-IN", {
      currency: "INR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  function ModifyOrder(config) {
    SetOrderType(order_id, isin, config);
  }

  let last_traded_price = stockData ? stockData.last_traded_price : 0;
  let current_price = parseFloat(last_traded_price);
  let order_value = quantity * parseFloat(last_traded_price);

  if (!show) {
    return <></>;
  } else {
    return (
      <>
        <tr>
          <td className="order-item-stock-name">
            <SwiftToolTip title={fullname} placement="bottom-start">
              <div className="stock-info">
                <span className="stock-name">{name}</span>
                <span className="stock-fullname">{fullname}</span>
              </div>
            </SwiftToolTip>
          </td>
          <td className="order-item-type">
            {user_role_type === "BROKER" && modify_order ? (
              <>
                <div
                  className="order-item-type-box"
                  onClick={() => {
                    setTypeOpen(true);
                  }}
                >
                  {ordertype.toUpperCase()}
                  <ReceiptEdit size={14} color="rgba(1, 22, 39, 0.90)" />
                </div>
              </>
            ) : (
              <>{ordertype.toUpperCase()}</>
            )}
          </td>
          <td className="order-item-limit">
            {!algo_config ? (
              <>{ReplaceNoise(limitprice)}</>
            ) : (
              <>
                <MovingAverage
                  symbol={algo_config ? name : false}
                  side={side}
                  time_window={time_window}
                  data_points={data_points}
                  setMovingPrice={setMovingPrice}
                />
              </>
            )}
          </td>
          <td className="order-item-side">
            <div className={side.toLowerCase()}>{side.toUpperCase()}</div>
          </td>
          <td className="order-item-quantity">{quantity}</td>
          <td className="order-item-current-price">
            {current_price ? CurrencyFormat(current_price) : "-"}
          </td>
          <td className="order-item-current-value">
            {order_value ? CurrencyFormat(order_value) : "-"}
          </td>
          <td className="order-item-status">{status}</td>
          {user_role_type === "BROKER" ? (
            <>
              <td className="order-item-remove">
                <SwiftToolTip title={`Remove Trade`} placement="bottom">
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={() => {
                      RemoveSingleOrder(account_code, trade_id);
                    }}
                  >
                    <Cross size={24} color={COLOR_VARS["SWIFT_COLOR4"]} />
                  </div>
                </SwiftToolTip>
              </td>
            </>
          ) : (
            <></>
          )}
        </tr>

        {typeOpen && (
          <>
            <SwiftModal
              top="2%"
              closeModal={() => {
                setTypeOpen(false);
              }}
            >
              <OrderTypePopup
                order={{
                  ordertype,
                  code,
                  exchange,
                  quantity,
                  side,
                  name,
                  broker,
                  limitprice,
                  algo_config,
                }}
                current_price={current_price}
                ModifyOrder={ModifyOrder}
                setTypeOpen={setTypeOpen}
                closePopup={() => {
                  setTypeOpen(false);
                }}
              />
            </SwiftModal>
          </>
        )}
      </>
    );
  }
}

function MovingAverage({
  symbol,
  side,
  time_window,
  data_points,
  setMovingPrice,
}) {
  const movingPrice = useMovingAverage(symbol, side, time_window, data_points);

  useEffect(() => {
    setMovingPrice(movingPrice);
  }, [movingPrice]);

  return <>{ReplaceNoise(movingPrice)}</>;
}

export default RouteItem;
