import React from 'react';

class CustomButton extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render() {

        const { disabled, onClick, title } = this.props;

        return (
            <>
                <div className={'swift-button ' + (disabled ? 'disabled' : '')} onClick={onClick}>
                    <p>{title}</p>
                </div>
            </>
        )

    }

}

export default CustomButton;