import {
  ResponsiveContainer, Legend, LabelList, Cell, Tooltip,
  PieChart, Pie,
  BarChart, Bar,
  LineChart, Line, ReferenceLine, XAxis, YAxis
} from "recharts";



export const BaseLine = ({ height = 400, width = 360, centerLine = 1, data = data, benchmark = benchmark }) => {

  return <ResponsiveContainer width="100%" height="100%">
    <LineChart
      data={data}
      margin={{
        top: 10,
        left: -30
      }}
    >
      <YAxis axisLine={false} tickLine={false} fontSize={10} stroke="#e8e8e8" strokeWidth={1} allowDecimals={true} />
      <XAxis dataKey="date" fontSize={10} stroke="#e8e8e8" strokeWidth={0.5} tick={{ fill: '#e8e8e8' }} />
      <Legend verticalAlign="top" />
      <Tooltip content={<CustomTooltip benchmark={benchmark} />} />
      <ReferenceLine y={centerLine} stroke="#cccccc" strokeDasharray="3 3" />
      <Line type="monotone" dataKey="Portfolio" stroke="#00a0e3" dot={false} strokeWidth={2} />
      <Line type="monotone" dataKey={benchmark} stroke="#404040" dot={false} strokeWidth={2} />
    </LineChart>

  </ResponsiveContainer>
}


const CustomTooltip = ({ active, payload, benchmark }) => {

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ background: '#ffff', padding: '6px 16px 6px 16px', borderRadius: 3, boxShadow: '1px 3px 5px #e8e8e8' }}>
        <p className="label" style={{ color: '#00a0e3' }}>{`Portfolio : ${parseFloat(payload[0].payload.Portfolio).toFixed(2)}`}</p>
        <p className="label" style={{ color: '#404040' }}>{`${benchmark} : ${parseFloat(payload[0].payload[benchmark]).toFixed(2)}`}</p>
        <p className="label" style={{ display: 'flex', justifyContent: 'center', fontSize: 10, color: '#404040', marginTop: '3px' }}>{`${payload[0].payload.date}`}</p>
      </div>
    );
  }

  return null;
};

const CustomTooltipSingle = ({ active, payload }) => {

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ background: '#ffff', padding: '6px 16px 6px 16px', borderRadius: 3, boxShadow: '1px 3px 5px #e8e8e8' }}>
        <p className="label" style={{ color: '#00a0e3', fontSize: 10 }}>{`Portfolio : ${parseFloat(payload[0].payload.indexValue).toFixed(2)}`}</p>
        <p className="label" style={{ display: 'flex', justifyContent: 'center', fontSize: 10, color: '#404040', marginTop: '3px' }}>{`${payload[0].payload.date}`}</p>
      </div>
    );
  }

  return null;
};

/*
const RenderLegend = ({payload}) => {

return (
  <ul style={{ display: 'flex', justifyContent: 'center'}}>
    <li style={{color: '#00a0e3', marginLeft: 0}}>Portfolio</li>
    <li style={{color: '#404040'}}>Nifty 50</li>
  </ul>
);
}
*/

export const SingleLine = ({ height = 400, width = 250, centerLine = 1, data = data }) => {


  return <ResponsiveContainer width="100%" height={height}>
    <LineChart
      data={data}
      margin={{
        left: -30
      }}
    >
      <YAxis axisLine={false} tickLine={false} fontSize={10} />
      <XAxis dataKey="date" fontSize={10} stroke="#404040" strokeWidth={1} tick={{ fill: '#404040' }} />
      <Tooltip content={<CustomTooltipSingle />} />
      <ReferenceLine y={centerLine} stroke="#cccccc" strokeDasharray="10 10" />
      <Line type="monotone" dataKey="indexValue" stroke="#00a0e3" strokeWidth={2} dot={false} activeDot={{ r: 6 }} />
    </LineChart>

  </ResponsiveContainer>
}