const { GetLocalStorage, SetLocalStorage } = require("./LocalStorage");

const HISTORY_PATH = "yottol-stock-search-history";

export function GetHistory(account_code) {

  const hist = GetLocalStorage(`${HISTORY_PATH}-${account_code}`);

  if(!hist){
    SetHistory(account_code,[]);
  }

  return JSON.parse(GetLocalStorage(`${HISTORY_PATH}-${account_code}`));
}

export function AddHistory(account_code, data) {
  const { symbol } = data;
  const history = GetHistory(account_code);


  let updated_history = [];

  if (history.length == 0) {
    updated_history.push(data);
  } else {
    const removed_history = history.filter((h) => h.symbol != symbol);
    updated_history = [data, ...removed_history];
  }

  updated_history = updated_history.slice(0, 10);

  SetHistory(account_code, updated_history);
}


function SetHistory(account_code, data) {
  SetLocalStorage(`${HISTORY_PATH}-${account_code}`, JSON.stringify(data));
}

