import React, { useState, useEffect } from "react";
import Axios from "axios";

import {
  UserSearch,
  Add,
  Cross,
  ArrowRight2,
  TaskSquare,
} from "../CustomComponents/SwiftIcon/Icons";
import MACSearch from "../CustomComponents/MACSearch/MACSearch";
import { GetCookie } from "../../exports/CookieManagement";
import { isEmpty, titleCase } from "../../exports/UtilFunctions";
import Pulse from "../Loader/Pulse";
import { COLOR_VARS } from "../../exports/ColorVars";
import TopTask from "./TopTask";
import SwiftModal from "../CustomComponents/SwiftModal/SwiftModal";
import CustomInput from "../Body/CustomChartComponents/CustomInput/CustomInput";
import CustomDate from "../Body/CustomChartComponents/CustomDate/CustomDate";
import CustomIconButton from "../Body/CustomChartComponents/CustomIconButton/CustomIconButton";
import CustomTextArea from "../Body/CustomChartComponents/CustomTextArea/CustomTextArea";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function ClientSearch({ customers }) {
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState(false);

  function GetCustomer(account_code) {
    setLoading(true);
    Axios.get(
      `${REQUEST_BASE_URL}/clientinfo/accountdetails?account_code=${account_code}`
    )
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          setCustomer(data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  return (
    <div className="client-search">
      <div className="client-search-header">
        <MACSearch
          title=""
          customers={customers}
          onSelect={(customer) => {
            GetCustomer(customer.account_code);
          }}
          hideOnSelect={true}
        />
      </div>
      <div className="client-search-data">
        {!customer ? (
          <>
            <div className="client-search-empty">
              <UserSearch size={26} color={COLOR_VARS["SWIFT_COLOR4"]} />
              <p>Find contact details quickly</p>
            </div>
          </>
        ) : (
          <>
            {loading ? (
              <>
                <div className="client-search-empty">
                  <Pulse />
                  <p>Loading customer details...</p>
                </div>
              </>
            ) : (
              <>
                <p className="client-name">{titleCase(customer.name)}</p>
                <div className="search-data-box-row">
                  <div className="search-data-box">
                    <span>Mobile</span>
                    <p title={customer.mobile}>{customer.mobile}</p>
                  </div>
                  <div className="search-data-box data-box-email">
                    <span>Email</span>
                    <p title={customer.email}>{customer.email}</p>
                  </div>
                  <div className="search-data-box">
                    <span>PAN</span>
                    <p>{customer.pan}</p>
                  </div>
                </div>
                <div className="search-data-box-row">
                  <div className="search-data-box">
                    <span>Trading Code</span>
                    <p>{customer.trading_code}</p>
                  </div>
                  <div className="search-data-box">
                    <span>Custody Code</span>
                    <p>{customer.custody_user_code}</p>
                  </div>
                  <div className="search-data-box"></div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function DashboardBottom() {
  const [customers, setCustomers] = useState([]);
  const [task, setTask] = useState([]);
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(1);
  const account_token = GetCookie("token");
  const [popup, setPopup] = useState(false);
  const [taskDueDate, setTaskDueDate] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [dateError, setDateError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  function getMACustomers() {
    Axios.get(`${REQUEST_BASE_URL}/portfolio/customers?token=${account_token}`)
      .then((response) => {
        const data = response.data;
        // console.log(data)
        if (!data.error) {
          setCustomers(data.customers);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const fetchTask = async () => {
    Axios.get(`${REQUEST_BASE_URL}/task?token=${account_token}`)
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          const allTasks = [
            ...data.tasks.overdue.map((task) => ({
              ...task,
              status: "overdue",
            })),
            ...data.tasks.pending.map((task) => ({
              ...task,
              status: "pending",
            })),
          ];

          const sortedTasks = allTasks.sort(
            (a, b) => new Date(a.task_date) - new Date(b.task_date)
          );
          setTask(sortedTasks);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const TaskDelete = async (task_id) => {
    Axios.delete(
      `${REQUEST_BASE_URL}/task?token=${account_token}&task_id=${task_id}`
    )
      .then((response) => {
        setChange(Math.random());
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const TaskUpdate = async (task_id) => {
    Axios.put(
      `${REQUEST_BASE_URL}/task?token=${account_token}&task_id=${task_id}`
    )
      .then((response) => {
        setChange(Math.random());
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const TaskInsert = async () => {
    setDescriptionError("");
    setDateError("");
    if (isEmpty(taskDescription)) {
      setDescriptionError("Please enter task description");
      return;
    }
    if (isEmpty(taskDueDate)) {
      setDateError("Please select due date");
      return;
    }
    const selectedDate = new Date(taskDueDate);
    const currentDate = new Date();

    if (selectedDate < currentDate) {
      setDateError("Selected date cannot be in the past");
      return;
    }

    setLoading(true);
    try {
      const response = await Axios.post(
        `${REQUEST_BASE_URL}/task?token=${account_token}`,
        { task_description: taskDescription, task_due_date: taskDueDate }
      );
    } catch (error) {
      console.error("Error inserting task:", error);
      setLoading(false);
    }
    setTimeout(() => {
      setLoading(false);
      setPopup(false);
      setDescriptionError("");
      setDateError("");
      setTaskDescription("");
      setTaskDueDate("");
      setChange(Math.random());
    }, 3000);
  };

  useEffect(() => {
    setLoading(true);
    getMACustomers();
    fetchTask();
  }, []);

  useEffect(() => {
    setLoading(true);
    fetchTask();
  }, [change]);

  return (
    <div className="swift-dashboard-bottom">
      <ClientSearch customers={customers} />
      <div className="tasks-wrapper">
        <div className="tasks-header">
          <p className="dash-bottom-box-title">My Tasks</p>
          <div onClick={() => setPopup(true)} style={{ cursor: "pointer" }}>
            <Add size={22} />
          </div>
        </div>
        <div className="tasks-content">
          {loading ? (
            <div className="tasks-loader">
              <Pulse />
              <p>Loading Tasks...</p>
            </div>
          ) : (
            <>
              {task.length > 0 ? (
                task.map((task) => (
                  <TopTask
                    key={task.task_id}
                    pending={task.status === "overdue"}
                    data={{
                      created: task.task_date,
                      due: task.task_due_date,
                      description: task.task_description,
                      id: task.task_id,
                    }}
                    TaskDelete={TaskDelete}
                    TaskUpdate={TaskUpdate}
                  />
                ))
              ) : (
                <div className="task-content-empty">
                  <TaskSquare size={26} color={COLOR_VARS["SWIFT_COLOR4"]} />
                  <p>You don't have any tasks in the list</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {popup && (
        <SwiftModal
          closeModal={() => {
            setPopup(false);
            setDescriptionError("");
            setDateError("");
            setTaskDescription("");
            setTaskDueDate("");
          }}
          top="2%"
        >
          <div className="swift-superuser-task-popup">
            {loading ? (
              <>
                <div className="task-popup-loader">
                  <Pulse />
                  <p>
                    Adding your task, please wait...
                  </p>
                </div>
              </>
            ) : (
              <>
                {" "}
                <div className="swift-superuser-task-header">
                  <div className={`swift-superuser-task-title`}>
                    Task Management
                  </div>

                  <div
                    className="swift-account-svg"
                    onClick={() => {
                      setPopup(false);
                      setDescriptionError("");
                      setDateError("");
                      setTaskDescription("");
                      setTaskDueDate("");
                    }}
                  >
                    <Cross size={26} color={COLOR_VARS["SWIFT_COLOR4"]} />
                  </div>
                </div>
                <div className="swift-superuser-task-body">
                  <CustomTextArea
                    heading="Task Description"
                    placeholder="Description"
                    value={taskDescription}
                    onChange={(value) => {
                      setTaskDescription(value);
                    }}
                    error={descriptionError}
                  />
                  <CustomDate
                    heading="Due Date"
                    value={taskDueDate}
                    onChange={(val) => {
                      setTaskDueDate(val);
                    }}
                    min={new Date().toISOString().split("T")[0]}
                    error={dateError}
                  />
                </div>
                <div className="swift-superuser-task-submit-btn">
                  <CustomIconButton
                    title="Submit"
                    icon={
                      <ArrowRight2 size={20} color={COLOR_VARS.SWIFT_COLOR1} />
                    }
                    onClick={TaskInsert}
                  />
                </div>
              </>
            )}
          </div>
        </SwiftModal>
      )}
    </div>
  );
}

export default DashboardBottom;
