import React from "react";
import { Routes, Route } from "react-router-dom";
import BackofficeLogin from "./BackofficeLogin";
import BackofficeClient from "./BackofficeClient/BackofficeClient";
import Backoffice from "./Backoffice";
import DealerManagement from "./Dealer/DealerManagement";
import BackofficeAuth from "./BackofficeAuth";

import '../../css/Backoffice/Backoffice.css';

function BackofficeManagement() {
  return (
    <div className="backoffice-wrapper">
      <Routes>
        <Route path="/login" element={<BackofficeLogin />}></Route>
        <Route
          path="/user"
          element={
            <BackofficeAuth>
              <BackofficeClient />
            </BackofficeAuth>
          }
        ></Route>
        <Route
          path="/dealer/*"
          element={
            <BackofficeAuth>
              <DealerManagement />
            </BackofficeAuth>
          }
        ></Route>
        <Route
          path="/dashboard"
          element={
            <BackofficeAuth>
              <Backoffice />
            </BackofficeAuth>
          }
        ></Route>
      </Routes>
    </div>
  );
}

export default BackofficeManagement;
