import React, { useEffect, useState } from "react";
import Axios from "axios";
import {
  FilterSearch,
  Add,
  NotificationStatus,
} from "../CustomComponents/SwiftIcon/Icons";
import ReportScheduleRow from "./ReportScheduleRow";
import SwiftToolTip from "../CustomComponents/SwiftToolTip/SwiftToolTip";
import { GetCookie } from "../../exports/CookieManagement";
import { GetRoleType } from "../../exports/RoleTypes";
import Pulse from "../Loader/Pulse";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";
import { COLOR_VARS } from "../../exports/ColorVars";
import { getDeviceSize } from "../../exports/InteractiveUtils";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;
const DEVICE_SIZE = getDeviceSize();

function ReportSchedule({ scheduled, setScheduler, setScheduled }) {
  const user_role_type = GetRoleType(GetCookie("role"));
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    GetSchedule();
  }, [scheduled]);

  function GetSchedule() {
    const account_token = GetCookie("token");
    Axios.get(
      `${REQUEST_BASE_URL}/reports/schedule?token=${account_token}`
    ).then((response) => {
      const data = response.data;
      if (!data.error) {
        setSchedule(data.data);
      }
      setLoading(false);
    });
  }

  function DeleteSchedule(report_id, callback) {
    if (!report_id) {
      callback();
      return;
    }

    const account_token = GetCookie("token");
    Axios.delete(
      `${REQUEST_BASE_URL}/reports/schedule?report_id=${report_id}&token=${account_token}`
    )
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          Alert({
            TitleText: "Report Schedule Delete",
            Message: "The report schedule has been deleted successfully",
            BandColor: "#470985",
            AutoClose: {
              Active: true,
              Line: true,
              LineColor: "#470985",
              Time: 3,
            },
          });
          callback();
          setScheduled(Math.floor(Math.random() * 1000));
        } else {
          Alert({
            TitleText: "Warning",
            Message: "Something went wrong while deleting the report schedule",
            BandColor: "#E51A4B",
            AutoClose: {
              Active: true,
              Line: true,
              LineColor: "#E51A4B",
              Time: 3,
            },
          });
          callback();
        }
      })
      .catch((error) => {
        Alert({
          TitleText: "Warning",
          Message: "Something went wrong while deleting the report schedule",
          BandColor: "#E51A4B",
          AutoClose: {
            Active: true,
            Line: true,
            LineColor: "#E51A4B",
            Time: 3,
          },
        });
        callback();
      });
  }

  return (
    <>
      {loading ? (
        <>
          <div className="report-schedule-loading">
            <Pulse />
            <p>Loading schedule... please wait</p>
          </div>
        </>
      ) : (
        <>
          <div className="report-schedule-header">
            <p className="report-schedule-title">Report Schedule</p>
            <SwiftToolTip title="Schedule Report" placement="bottom">
              <div
                className="report-schedule-add"
                onClick={() => {
                  setScheduler(true);
                }}
              >
                <Add size={24} color={COLOR_VARS["SWIFT_COLOR4"]} />
              </div>
            </SwiftToolTip>
          </div>
          <div className="report-schedule-content">
            {schedule.length === 0 ? (
              <>
                <div className="report-schedule-empty">
                  <NotificationStatus
                    size={50}
                    color={COLOR_VARS["SWIFT_COLOR4"]}
                  />
                  <p>You don't have any scheduled reports</p>
                </div>
              </>
            ) : (
              <>
                {["ADMIN", "BROKER"].includes(user_role_type) ? (
                  <>
                    <div className="report-schedule-customer">
                      <input
                        placeholder="Search customer"
                        value={filter}
                        onChange={(e) => {
                          setFilter(e.target.value);
                        }}
                      />
                      <div className="filter-icon">
                        <FilterSearch size={20} color="#192b40" />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="report-schedule-table">
                  {DEVICE_SIZE === "S" ? (
                    <>
                      {schedule.map((s, index) => {
                        return (
                          <ReportScheduleRow
                            DEVICE_SIZE={DEVICE_SIZE}
                            show={
                              s
                                ? s.name
                                    .toLowerCase()
                                    .includes(filter.toLowerCase())
                                : true
                            }
                            key={index}
                            data={s}
                            DeleteSchedule={DeleteSchedule}
                          />
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <table>
                        <thead>
                          <tr>
                            <td className="name">Customer/Family Portfolio</td>
                            <td>Next Report Date</td>
                            <td>Report Type</td>
                            <td>Frequency</td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody>
                          {schedule.map((s, index) => {
                            return (
                              <ReportScheduleRow
                                DEVICE_SIZE={DEVICE_SIZE}
                                show={
                                  s
                                    ? s.name
                                        .toLowerCase()
                                        .includes(filter.toLowerCase())
                                    : true
                                }
                                key={index}
                                data={s}
                                DeleteSchedule={DeleteSchedule}
                              />
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default ReportSchedule;
