import React, { useEffect, useState } from "react";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { GetCookie } from "../../exports/CookieManagement";
import moment from "moment";
import ConfirmBox from "../CustomComponents/ConfirmBox/ConfirmBox";
import {
  CloseCircle,
  Edit,
  OrderBook,
} from "../CustomComponents/SwiftIcon/Icons";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";
import { COLOR_VARS } from "../../exports/ColorVars";
import SwiftModal from "../CustomComponents/SwiftModal/SwiftModal";
import OrderTypePopup from "../TransactionManagement/OrdersComponents/OrderTypePopup";
import useStockInfo from "../../hooks/useStockInfo";
import useMarketStock from "../../hooks/useMarketStock";
import { numberFormat } from "../../exports/UtilFunctions";
import {
  SWIFTALGO_TIMEWINDOW,
  SWIFTALGO_TIMEWINDOW_REVERSE,
} from "../../exports/OrderAlgoConfig";
import Pulse from "../Loader/Pulse";
import { getDeviceSize } from "../../exports/InteractiveUtils";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;
const DEVICE_SIZE = getDeviceSize();

const success_config = {
  BandColor: "#470985",
  AutoClose: {
    Active: true,
    Line: true,
    LineColor: "#470985",
    Time: 3,
  },
};

const failure_config = {
  BandColor: "#E51A4B",
  AutoClose: {
    Active: true,
    Line: true,
    LineColor: "#E51A4B",
    Time: 3,
  },
};

function FormatTime(time) {
  return moment(time).format("HH:mm:ss");
}

function CustomerTransactions() {
  const account_token = GetCookie("token");

  async function GetTransactions() {
    const response = await fetch(
      `${REQUEST_BASE_URL}/broking/orderbook?token=${account_token}`
    );
    const data = await response.json();
    return data;
  }

  const { data: orders = {} } = useQuery(["transactions"], GetTransactions);

  const { orders: transactions } = orders;


  if (!transactions || !Array.isArray(transactions)) {
    return (
      <>
        <div className="orders-wrapper full">
          <div className="orders-transactions-empty">
            <Pulse />
          </div>
        </div>
      </>
    );
  }

  if (transactions.length === 0) {
    return (
      <>
        <div className="orders-wrapper full">
          <div className="orders-transactions-empty">
            <OrderBook size={40} color={COLOR_VARS["SWIFT_COLOR4"]} />
            <div>
              <p>No trades found</p>
              <span>Looks like you have't made any trades yet</span>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="orders-wrapper full">
      <div className="orders-transactions-container">
        {DEVICE_SIZE === "S" ? (
          <>
            {transactions.map((t, index) => {
              return <CustomerTransaction key={index} DEVICE_SIZE={DEVICE_SIZE} data={t} />;
            })}
          </>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <td>Stock Name</td>
                  <td>Current Price</td>
                  <td>Order Type</td>
                  <td>Limit Price</td>
                  <td>Order Side</td>
                  <td>Quantity</td>
                  <td>Trade Price</td>
                  <td>Trade Amount</td>
                  <td>Transaction Time</td>
                  <td className="status">Status</td>
                  <td className="modify"></td>
                  <td className="delete"></td>
                </tr>
              </thead>
              <tbody>
                {transactions.map((t, index) => {
                  return <CustomerTransaction key={index} DEVICE_SIZE={DEVICE_SIZE} data={t} />;
                })}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
}

function CustomerTransaction({ DEVICE_SIZE, data }) {
  const [typeOpen, setTypeOpen] = useState(false);

  const {
    Symbol,
    Token,
    Qty,
    BS,
    TradedQty,
    Price,
    SwiftOrderType,
    ClientOrderNo,
    TradeOrderID,
    OrderStatus,
    SwiftSide,
    Time,
    AlgoOrderConfig,
  } = data;

  const account_token = GetCookie("token");

  function CancelOrder(order_id, callback) {
    if (!order_id) {
      callback();
      return;
    }

    axios
      .post(`${REQUEST_BASE_URL}/order/cancel?token=${account_token}`, {
        order_id: order_id,
      })
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          Alert({
            TitleText: "Order Cancel",
            Message: "The order has been cancelled successfully",
            ...success_config,
          });
          callback();
        } else {
          Alert({
            TitleText: "Warning",
            Message: "Something went wrong while cancelling the order",
            ...failure_config,
          });
          callback();
        }
      })
      .catch((error) => {
        Alert({
          TitleText: "Warning",
          Message: "Something went wrong while cancelling the order",
          ...failure_config,
        });
        callback();
      });
  }

  function ModifyOrder(config) {
    const account_token = GetCookie("token");
    const { type, limit_price, algo_config } = config;

    if (algo_config["time_window"]) {
      algo_config["time_window"] =
        SWIFTALGO_TIMEWINDOW[algo_config["time_window"]];
    }

    axios
      .post(`${REQUEST_BASE_URL}/order/modify?token=${account_token}`, {
        order_id: TradeOrderID,
        order_type: type,
        limit_price: limit_price,
        algo_config: algo_config,
      })
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          Alert({
            TitleText: "Order Modify",
            Message: "The order has been modified successfully",
            ...success_config,
          });
        } else {
          Alert({
            TitleText: "Warning",
            Message: "Something went wrong while modifying the order",
            ...failure_config,
          });
        }
      })
      .catch((error) => {
        Alert({
          TitleText: "Warning",
          Message: "Something went wrong while modifying the order",
          ...failure_config,
        });
      });
  }

  const [config, setConfig] = useState(false);
  const stock_data = useMarketStock(Symbol);
  const { stock_info } = useStockInfo(Symbol);

  useEffect(() => {
    const { AlgoOrderType, AlgoOrderInfo } = AlgoOrderConfig;
    let algo_config = false;
    let limit_price = Price;

    if (AlgoOrderType == "MARKET") {
      limit_price = 0;
    }

    if (AlgoOrderType != "MARKET" && AlgoOrderType != "LIMIT") {
      algo_config = {
        time_window: SWIFTALGO_TIMEWINDOW_REVERSE[AlgoOrderInfo["time_window"]],
        data_points: AlgoOrderInfo["data_points"],
      };
    }

    setConfig({
      type: "MARKET",
      algo_order_type: AlgoOrderType,
      limit: limit_price,
      algo_config: algo_config,
    });
  }, [AlgoOrderConfig]);

  if (!stock_info || !config) return;

  const { symbol, exchange } = stock_info;

  const current_price = stock_data ? stock_data["last_traded_price"] : Price;

  const { type, algo_order_type, limit, algo_config } = config;

  const OrderAmount = parseInt(Qty) * parseFloat(Price);

  if(DEVICE_SIZE === 'S'){
    return <>
      <div className="transaction-row">
        <div className="tran-row">
          <div><span>{SwiftSide}</span><span>{TradedQty}/{Qty}</span></div>
          <div><span>LTP : {numberFormat(current_price)}</span></div>
        </div>
        <div className="tran-row">
          <div><span>{symbol}</span></div>
          <div><span>@ {numberFormat(Price)}</span></div>
        </div>
        <div className="tran-row">
          <div><span>{SwiftOrderType}</span></div>
          <div><span>{FormatTime(Time)}</span><span>{OrderStatus}</span></div>
        </div>
      </div>
    </>
  }

  return (
    <>
      <tr>
        <td>{symbol}</td>
        <td>{numberFormat(current_price)}</td>
        <td>{SwiftOrderType}</td>
        <td>{SwiftOrderType === "LIMIT" ? numberFormat(Price) : "-"}</td>
        <td>{SwiftSide}</td>
        <td>
          {TradedQty}/{Qty}
        </td>
        <td>{numberFormat(Price)}</td>
        <td>{numberFormat(OrderAmount)}</td>
        <td>{FormatTime(Time)}</td>
        <td>{OrderStatus}</td>
        {OrderStatus == "PENDING" ? (
          <>
            <td
              className="modify"
              onClick={() => {
                setTypeOpen(true);
              }}
            >
              <Edit size={18} color={COLOR_VARS["SWIFT_COLOR4"]} />
            </td>
            <td
              className="delete"
              onClick={() => {
                ConfirmBox({
                  title: "Delete Trade",
                  description: (
                    <>
                      Are you sure you want to delete the{" "}
                      <strong>{Symbol}</strong>?
                    </>
                  ),
                  properties: [
                    {
                      id: "2",
                      label: "Delete",
                      color: "#192b40",
                      bgColor: "#ffffff",
                      handleFunction: CancelOrder,
                      functionArgs: [TradeOrderID],
                    },
                  ],
                  cancel: true,
                });
              }}
            >
              <CloseCircle size={18} color={COLOR_VARS["SWIFT_COLOR4"]} />
            </td>
          </>
        ) : (
          <>
            <td></td>
            <td></td>
          </>
        )}
      </tr>
      {typeOpen && (
        <>
          <SwiftModal
            top="2%"
            closeModal={() => {
              setTypeOpen(false);
            }}
          >
            <OrderTypePopup
              order={{
                ordertype: algo_order_type,
                code: Token,
                exchange: exchange.exchange,
                quantity: Qty,
                side: SwiftSide,
                name: symbol,
                broker: "CHOICE",
                limitprice: limit,
                algo_config: algo_config,
              }}
              current_price={current_price}
              ModifyOrder={ModifyOrder}
              setTypeOpen={setTypeOpen}
              closePopup={() => {
                setTypeOpen(false);
              }}
            />
          </SwiftModal>
        </>
      )}
    </>
  );
}

export default CustomerTransactions;
