import React from 'react';
import { Routes, Route } from "react-router-dom";
import KYCManagement from '../KYCManagement/KYCManagement';
import CustomerTrades from './CustomerTrades';
import '../../css/CustomerManagement/CustomerManagement.css';

function CustomerManagement() {
  return (
    <Routes>
      <Route path='trades' element={<CustomerTrades />} />
      <Route path='kyc' element={<KYCManagement />} />
    </Routes>
  )
}

export default CustomerManagement;

