import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { GetCookie } from '../../exports/CookieManagement';
import Pulse from '../Loader/Pulse';
import { Cross, ArchiveBook } from '../CustomComponents/SwiftIcon/Icons';
import { COLOR_VARS } from '../../exports/ColorVars';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function BrokerHistory({ close }) {

    const [history, setHistory] = useState([]);
    const [config, setConfig] = useState({});
    const [loading, setLoading] = useState(true);

    function CurrencyFormat(value) {
        return parseFloat(value).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }


    useEffect(() => {
        const account_token = GetCookie('token');
        Axios.get(`${REQUEST_BASE_URL}/clientinfo/stats?token=${account_token}`).then((response) => {
            const data = response.data;
            if (data.error || !data.data || data.data.length === 0) {
                setHistory([]);
                setLoading(false);
            }
            else {

                const history = data.data.reverse();
                let buy_amount = 0;
                let sell_amount = 0;
                let brokerage = 0;


                history.forEach(h => {
                    buy_amount += parseFloat(h['buy_amount']);
                    sell_amount += parseFloat(h['sell_amount']);
                    brokerage += parseFloat(h['brokerage']);
                });

                setHistory(history);
                setConfig({
                    buy_amount, sell_amount, brokerage
                })
                setLoading(false);
            }
        }).catch((err) => {
            console.log(err)
            setHistory([]);
            setLoading(false);
        });
    }, []);

    return (
        <div className='broker-history-popup'>
            {loading && <>
                <div className='broker-history-popup-loader'>
                    <Pulse />
                </div>
            </>}
            <div className='broker-history-header'>
                <p className='broker-history-title'>Broking History</p>
                <div className='broker-history-close' onClick={close}>
                    <Cross color="#192b40" size={26} />
                </div>
            </div>
            <div className='broker-history-body'>
                {!loading && <>
                    {history.length === 0 ? <>
                        <div className='broker-history-empty'>
                            <ArchiveBook color={COLOR_VARS['SWIFT_COLOR4']} size={70} />
                            <p>Your broking history is empty.</p>
                        </div>
                    </> : <>
                        <div className='broker-history-table'>
                            <table>
                                <thead>
                                    <tr>
                                        <td>Month</td>
                                        <td>Buy Amount</td>
                                        <td>Sell Amount</td>
                                        <td>Brokerage</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {history.map((h, index) => {
                                        return <tr key={index}>
                                            <td>{h.date_string}</td>
                                            <td className='positive'>{CurrencyFormat(h.buy_amount)}</td>
                                            <td className='negative'>{CurrencyFormat(h.sell_amount)}</td>
                                            <td>{CurrencyFormat(h.brokerage)}</td>
                                        </tr>
                                    })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>Total</td>
                                        <td>{CurrencyFormat(config?.buy_amount)}</td>
                                        <td>{CurrencyFormat(config?.sell_amount)}</td>
                                        <td>{CurrencyFormat(config?.brokerage)}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </>}
                </>}
            </div>
        </div>
    )
}

export default BrokerHistory