import React, { useState, useRef, useEffect } from "react";
import "../../../../css/CustomComponents/CustomValueSelect.css";
import {
  ArrowDown2,
  ArrowUp2,
} from "../../../CustomComponents/SwiftIcon/Icons";

function CustomValueSelect({ value, options, onChange }) {
  const ref = useRef(null);
  const [listOpen, setListOpen] = useState(false);
  const [width,setWidth] = useState('100%');

  useEffect(() => {

    if(!ref || !ref.current) return;

    const width = ref.current.offsetWidth;
    setWidth(width);

    document.addEventListener("mousedown", handleClick);
    return () => document.removeEventListener("mousedown", handleClick);
  }, [listOpen]);


  function handleClick(evt) {
    if (ref.current && !ref.current.contains(evt.target)) {
      setListOpen(false);
    }
  }

  function toggleSelect() {
    setListOpen(!listOpen);
  }

  function handleSelect(value) {
    onChange(value);
    setListOpen(false);
  }

  return (
    <div ref={ref} className="value-select-wrapper">
      <div className="value-select-content" onClick={toggleSelect}>
        <p>{value}</p>
        <div className="value-select-arrow">
          {listOpen ? (
            <ArrowUp2 size={16} color="#192b40" />
          ) : (
            <ArrowDown2 size={16} color="#192b40" />
          )}
        </div>
      </div>
      {listOpen && (
        <>
          <div className="value-select-options-wrapper">
            <div className="value-select-options" style={{width: `${width}px`}}>
              {options.map((o, index) => {
                return (
                  <div
                    onClick={() => {
                      handleSelect(o);
                    }}
                    key={index}
                    className="value-select-option"
                  >
                    {o}
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CustomValueSelect;
