import React, { useEffect, useState } from "react";
import SwiftModal from "../../CustomComponents/SwiftModal/SwiftModal";
import {
  Cross,
  ArrowRight2,
  UserSearch,
  Edit,
} from "../../CustomComponents/SwiftIcon/Icons";
import CustomSelectError from "../../Body/CustomChartComponents/CustomSelectError/CustomSelectError";
import ServerRequest from "../../../exports/ServerRequest";
import { useNavigate } from "react-router-dom";
import Pulse from "../../Loader/Pulse";
import "../../../css/CustomComponents/CustomSearchSelect.css";

import CustomInputFile from "../../CustomComponents/CustomInputFile/CustomInputFile";
import { isEmpty, isValidEmail } from "../../../exports/UtilFunctions";
import { Alert } from "../../Body/CustomChartComponents/CustomAlert/CustomAlert";
import { GetCookie } from "../../../exports/CookieManagement";
import { COLOR_VARS } from "../../../exports/ColorVars";

const BrokerContact = () => {
  const [form, setForm] = useState(null);
  const [formData, setFormData] = useState({});
  const [change, setChange] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState("");
  const [firsttime, setFirstTime] = useState(false);
  const token = GetCookie("admin_token");

  const navigate = useNavigate();

  const type = ["BROKER", "CUSTODIAN", "PM"];
  const method = ["FILE", "API"];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data1 = await ServerRequest({
        url: `/admin/broker/get?token=${token}`,
        method: "get",
      });
      if (data1.server_error) {
        navigate("/404", { state: { errorMessage: data1.message } });
      }
      setData(data1.data);
      setLoading(false);
    };
    fetchData();
  }, [change]);

  const [error, setError] = useState({
    type: "",
    broker: "",
    contact1: "",
    system: "",
    trading_method: "",
  });

  const DisplayAlert = (msg) => {
    Alert({
      TitleText: "Error",
      Message: msg,
      BandColor: "#e51a4b",

      AutoClose: {
        Active: true,
        Line: true,
        LineColor: "#e51a4b",
        Time: 3,
      },
    });
  };

  const handleInputChange = (name, value) => {
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const openForm = (item) => {
    setFormData(item);
    setForm(true);
  };

  const closeForm = () => {
    setFormData({});
    setForm(false);
    setError("");
    setFirstTime(false);
  };
  const AddBroker = () => {
    setFormData({});
    setFirstTime(true);
    setForm(true);
  };
  const validateAll = () => {
    const requiredFields = [
      "type",
      "broker",
      "system",
      "trading_method",
      "contact1",
    ];
    for (const field of requiredFields) {
      if (field === "contact1" && !isValidEmail(formData[field])) {
        setError((prevError) => ({
          ...prevError,
          [field]: `Enter Valid Email`,
        }));
        DisplayAlert("Enter Valid Email");
        return false;
      }
      if (isEmpty(formData[field])) {
        setError((prevError) => ({
          ...prevError,
          [field]: `${field} is required`,
        }));
        DisplayAlert(`${field} is required`);

        return false;
      } else {
        setError((prevError) => ({
          ...prevError,
          [field]: "",
        }));
      }
    }
    if (formData["contact2"] && !isValidEmail(formData["contact2"])) {
      setError((prevError) => ({
        ...prevError,
        ["contact2"]: `Enter Valid Email`,
      }));
      DisplayAlert(`Enter Valid Email`);

      return false;
    }
    if (formData["contact3"] && !isValidEmail(formData["contact3"])) {
      setError((prevError) => ({
        ...prevError,
        ["contact3"]: `Enter Valid Email`,
      }));
      DisplayAlert(`Enter Valid Email`);

      return false;
    }

    return true;
  };
  const handleSubmit = async () => {
    // Validate all fields
    const isValid = validateAll();

    if (isValid) {
      setLoading(true);

      // Proceed with the API call if all fields are valid
      const data2 = await ServerRequest({
        url: `/admin/broker/update?token=${token}`,
        data: formData,
        method: "post",
      });

      if (data2.server_error) {
        navigate("/404", { state: { errorMessage: data2.message } });
      }
      setLoading(false);
      closeForm();
      setChange(Math.floor(Math.random() * 900000000 + 100000000));
    }
  };

  const renderFormContent = () => {
    return (
      <>
        <div className="swift-account-form-body-content-extra">
          <div className="swift-account-form-body-content">
            {firsttime && (
              <CustomSelectError
                heading="Type"
                placeholder="Please Select Type"
                defaultIndex={type.indexOf(formData["type"])}
                options={type}
                onTypeChange={(role) => {
                  handleInputChange("type", role);
                }}
                error={error.type}
              />
            )}

            <CustomInputFile
              label="Broker Name"
              name="broker"
              type="text"
              value={formData["broker"]}
              onchange={handleInputChange}
              disabled={firsttime ? false : true}
              error={error.broker}
            />
            <CustomInputFile
              label="System"
              name="system"
              type="text"
              value={formData["system"]}
              onchange={handleInputChange}
              error={error.system}
            />
            <CustomSelectError
              heading="Method"
              placeholder="Please Select Method"
              defaultIndex={method.indexOf(formData["trading_method"])}
              options={method}
              onTypeChange={(role) => {
                handleInputChange("trading_method", role);
              }}
              error={error.trading_method}
            />
            <CustomInputFile
              label="Contact 1"
              name="contact1"
              type="text"
              value={formData["contact1"]}
              onchange={handleInputChange}
              error={error.contact1}
              placeholder="xyz@gmail.com"
            />
            <CustomInputFile
              label="Contact 2"
              name="contact2"
              type="text"
              value={formData["contact2"]}
              onchange={handleInputChange}
              error={error.contact2}
              placeholder="xyz@gmail.com"
            />
            <CustomInputFile
              label="Contact 3"
              name="contact3"
              type="text"
              value={formData["contact3"]}
              onchange={handleInputChange}
              error={error.contact3}
              placeholder="xyz@gmail.com"
            />
          </div>
        </div>
        <div className="login-footer">
          <div className="swift-button-icon" onClick={handleSubmit}>
            <div className="button-icon">
              <ArrowRight2 size={20} />
            </div>
            <p>Submit</p>
          </div>
        </div>
      </>
    );
  };

  return !loading ? (
    <div className="swift-account-main open">
      <div className="swift-account-heading">
        <div className="swift-account-heading-right">
          <div className="select-search-wrapper">
            <div className="select-search-box">
              <input
                placeholder="Search Broker"
                value={filterData}
                onChange={(e) => setFilterData(e.target.value)}
              />
              <div className="filter-icon">
                <UserSearch size={20} color="#000" />
              </div>
            </div>
          </div>
          <button onClick={AddBroker}>Add Broker</button>
        </div>
      </div>

      {data.filter((item) =>
        item.broker.toLowerCase().includes(filterData.toLowerCase())
      ).length > 0 ? (
        <div className="admin-wrapper full">
          <div className="admin-history-container admin-broker-container">
            <table>
              <thead>
                <tr>
                  <td className="type">Type</td>
                  <td className="name">Broker Name</td>
                  <td className="system">System</td>
                  <td className="method">Trading Method</td>
                  <td className="contact">Contact 1</td>
                  <td className="contact">Contact 2</td>
                  <td className="contact">Contact 3</td>
                  <td className="edit"></td>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  if (
                    item.broker.toLowerCase().includes(filterData.toLowerCase())
                  ) {
                    return (
                      <tr key={index}>
                        <td>{item["type"] ? item["type"] : "-"}</td>
                        <td>{item.broker ? item.broker : "-"}</td>
                        <td>{item.system ? item.system : "-"}</td>
                        <td>
                          {item.trading_method ? item.trading_method : "-"}
                        </td>
                        <td>{item["contact1"] ? item["contact1"] : "-"}</td>
                        <td>{item["contact2"] ? item["contact2"] : "-"}</td>
                        <td>{item["contact3"] ? item["contact3"] : "-"}</td>

                        <td>
                          <div
                            className="swift-account-svg"
                            onClick={() => openForm(item)}
                          >
                            <Edit
                              size={18}
                              color={COLOR_VARS["SWIFT_COLOR4"]}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="empty-data-wrapper">
          <p>No broker found</p>
        </div>
      )}

      {form && (
        <SwiftModal closeModal={closeForm} top="2%">
          <div className="swift-account-form">
            {loading ? (
              <>
                <div className="loader">
                  <Pulse />
                  <p>Creating Account</p>
                </div>
              </>
            ) : (
              <>
                <div className="swift-account-form-header">
                  <div className="swift-account-form-title">
                    Broker Management
                  </div>
                  <div className="swift-account-svg" onClick={closeForm}>
                    <Cross size={30} color={COLOR_VARS["SWIFT_COLOR4"]} />
                  </div>
                </div>
                <div className="swift-account-form-body">
                  {renderFormContent()}
                </div>
              </>
            )}
          </div>
        </SwiftModal>
      )}
    </div>
  ) : (
    <>
      <div className="admin-body-loader">
        <Pulse />
        <p>Loading data</p>
      </div>
    </>
  );
};

export default BrokerContact;
