import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useSearchParams } from "react-router-dom";
import SmallCaseIcon from "../../../assets/icons/smallcase.svg";
import CloseIcon from "../../../assets/icons/closesmall.svg";
import SmallCaseStrategy from "./SmallCaseComponents/SmallCaseStrategy";
import Pulse from "../../Loader/Pulse";
import SmallCaseConfigurePopup from "../AppPopups/SmallCaseConfigurePopup/SmallCaseConfigurePopup";
import {
  Add,
  FilterSearch,
  Edit,
  Trash,
  BoxAdd,
} from "../../CustomComponents/SwiftIcon/Icons";
import SectorMarketChart from "./SmallCaseComponents/SectorMarketChart.js";
import CompanyBox from "./SmallCaseComponents/CompanyBox";
import { GetCookie } from "../../../exports/CookieManagement";
import ConfirmBox from "../../CustomComponents/ConfirmBox/ConfirmBox";
import { Alert } from "../CustomChartComponents/CustomAlert/CustomAlert";
import "../../../css/MenuSection/SmallCase.css";
import SwiftToolTip from "../../CustomComponents/SwiftToolTip/SwiftToolTip";
import ServerRequest from "../../../exports/ServerRequest.js";
import CustomButton from "../CustomChartComponents/CustomButton/CustomButton.js";
import { COLOR_VARS } from "../../../exports/ColorVars.js";
import useQueryParams from "../../../hooks/useQueryParams.js";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;


function SmallCase({}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    strategy_id = false,
    edit = false,
    new_strategy = false,
  } = useQueryParams();

  const [smallcaseFetch, setSmallcaseFetch] = useState(0);
  const [smallcases, setSmallcases] = useState(undefined);
  const [strategy, setStrategy] = useState(undefined);

  function DisplayAlert(msg) {
    Alert({
      TitleText: "Warning",
      Message: msg,
      Band: true,
      BandColor: "#470985",
      BoxColor: "#ffffff",
      TextColor: "#000000",
      AutoClose: {
        Active: true,
        Line: true,
        LineColor: "#470985",
        Time: 3,
      },
    });
  }

  function DeleteStrategy(smallcase_id, strategy_name) {
    const access_token = GetCookie("token");
    ConfirmBox({
      title: "Delete Strategy",
      description: (
        <>
          <>
            Are you sure you want to delete the strategy named{" "}
            <strong>{strategy_name}</strong>?<br /> Keep in mind that by doing
            so, all data associated with this strategy will be removed.
          </>
        </>
      ),
      properties: [
        {
          id: "2",
          label: "Delete",
          color: "#192b40",
          bgColor: "#ffffff",
          handleFunction: async (callback) => {
            const payload = { smallcase_id };

            const data = await ServerRequest({
              method: "POST",
              url: `/delete_smallcase?token=${access_token}`,
              data: payload,
            });

            if (!data.error) {
              Alert({
                TitleText: "Strategy Deleted",
                Message: "Strategy deleted successfully",
                Band: true,
                BandColor: "#67D098",
                BoxColor: "#ffffff",
                TextColor: "#000000",
                AutoClose: {
                  Active: true,
                  Line: true,
                  LineColor: "#67D098",
                  Time: 3,
                },
              });
              setSmallcaseFetch(Math.random());
              searchParams.delete("strategy_id");
              setSearchParams(searchParams);
              callback();
            } else {
              DisplayAlert("Something went wrong while deleting the strategy");
              callback();
            }
          },
        },
      ],
      cancel: true,
    });
  }

  useEffect(() => {
    async function GetSmallCases() {
      const token = GetCookie("token");
      const data = await ServerRequest({
        method: "GET",
        url: `/fetch_smallcase?token=${token}`,
      });
      return data;
    }

    GetSmallCases()
      .then((data) => {
        if (!data.error) {
          setSmallcases(data.details);
          // setSmallcases([]);
        } else {
          throw new Error("Invalid Credentials");
        }
      })
      .catch((error) => {
        setSmallcases([]);
      });
  }, [smallcaseFetch]);

  useEffect(() => {
    async function GetSmallCaseDetails() {
      const data = await ServerRequest({
        method: "POST",
        url: `/fetch_backtest_result`,
        data: {
          smallcase_id: strategy_id,
        },
      });
      return data;
    }

    if (!strategy_id || !smallcases) return;

    setStrategy(undefined);

    GetSmallCaseDetails()
      .then((data) => {
        const { stocks = [] } = data;
        if (stocks && Array.isArray(stocks) && stocks.length > 0) {
          const strategy_info = smallcases.find((s) => {
            return s["smallcase_id"] === strategy_id;
          });
          if (strategy_info) {
            setStrategy({ ...data, ...strategy_info });
          } else {
            setStrategy(false);
          }
        } else {
          throw new Error("Invalid Credentials");
        }
      })
      .catch(() => {
        setStrategy(false);
      });
  }, [strategy_id, smallcases]);

  if (!smallcases) {
    return (
      <div className="smallcase__container loader">
        <Pulse />
      </div>
    );
  }

  if (smallcases.length === 0) {
    return (
      <div className="smallcase__container__empty">
        <div className="create__smallcase__content">
          <BoxAdd size={80} color={COLOR_VARS["SWIFT_COLOR4"]} />
          <p>You haven't created any strategy yet</p>
        </div>
        <CustomButton title="Create Strategy" />
      </div>
    );
  }

  if (new_strategy === "true") {
    return (
      <SmallCaseStrategy
        strategy_info={false}
        smallCaseHome={() => {
          searchParams.delete("new_strategy");
          setSearchParams(searchParams);
          setSmallcaseFetch(Math.random());
        }}
      />
    );
  }

  if (strategy && edit == "true") {
    return (
      <SmallCaseStrategy
        strategy_info={{
          smallcase_id: strategy_id,
          name: strategy.pName,
        }}
        smallCaseHome={() => {
          searchParams.delete("edit");
          setSearchParams(searchParams);
          setSmallcaseFetch(Math.random());
        }}
      />
    );
  }

  return (
    <>
      <div className="smallcase__container">
        <div className="smallcase__content__wrapper">
          <div className="smallcase__left">
            <div className="personal__strategies__header">
              <p>Startegy Management</p>
              <div
                onClick={() => {
                  searchParams.delete("strategy_id");
                  searchParams.delete("edit");
                  searchParams.set("new_strategy", true);
                  setSearchParams(searchParams);
                }}
              >
                <Add size={20} />
              </div>
            </div>
            <div className="smallcase__strategies__content">
              <div className="smallcase__public__strategies">
                <div className="public__strategies__content">
                  <div className="smallcase__strategy__wrapper">
                    {smallcases.map((el, i) => (
                      <StrategyCard
                        key={i}
                        smallcase_id={el.smallcase_id}
                        heading={el.pName}
                        news={el.objective}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="smallcase__right">
            {strategy_id ? (
              <>
                {strategy ? (
                  <>
                    <StrategyDetail
                      smallcase_id={strategy.smallcase_id}
                      name={strategy.pName}
                      details={[
                        {
                          title: "Rebalancing",
                          value: strategy.rebalancing_frequency + ", Automatic",
                        },
                        {
                          title: "Objective",
                          value: strategy.objective,
                        },
                        {
                          title: "Methodology",
                          value: strategy.methodology,
                        },
                      ]}
                      cash_weight={strategy.cash_weight}
                      stocks={strategy.stocks}
                      DeleteStrategy={DeleteStrategy}
                    />
                  </>
                ) : (
                  <>
                    <div className="smallcase__strategy__details loader">
                      <Pulse />
                      <p>Loading strategy...</p>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="smallcase__strategy__details loader">
                  <h2>Investing made easy</h2>
                  <span>
                    Create or execute the available strategies and start
                    investing
                  </span>
                  <div
                    className="create__smallcase"
                    onClick={() => {
                      searchParams.delete("strategy_id");
                      searchParams.delete("edit");
                      searchParams.set("new_strategy", true);
                      setSearchParams(searchParams);
                    }}
                  >
                    Create New Strategy
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function StrategyCard({ smallcase_id, heading, news }) {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <>
      <div className="smallcase__strategy__card">
        <div className="strategy__info">
          <div
            className="strategy__headings__box"
            onClick={() => {
              searchParams.delete("new_strategy");
              searchParams.set("strategy_id", smallcase_id);
              setSearchParams(searchParams);
            }}
          >
            <p className="strategy__heading">{heading}</p>
            <p className="strategy__news" title={news}>
              {news}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

function StrategyDetail({
  smallcase_id,
  name,
  details,
  cash_weight,
  stocks,
  DeleteStrategy,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="smallcase__strategy__details">
      <div className="strategy__section">
        <div className="smallcase__strategy__header">
          <div className="strategy__name__icon">
            <p className="strategy__name">{name}</p>
            <div className="strategy__options">
              <SwiftToolTip title="Edit Startegy" placement="bottom">
                <span
                  onClick={() => {
                    searchParams.delete("new_strategy");
                    searchParams.set("edit", true);
                    setSearchParams(searchParams);
                  }}
                >
                  <Edit size={18} />
                </span>
              </SwiftToolTip>
              <SwiftToolTip title="Delete Startegy" placement="bottom">
                <span
                  onClick={() => {
                    DeleteStrategy(smallcase_id, name);
                  }}
                >
                  <Trash size={18} />
                </span>
              </SwiftToolTip>
            </div>
          </div>
          <div className="strategy__info">
            <div className="strategy__text">
              <p>
                <span>Strategy Objective </span>
                {details[1].value}
              </p>
              <p>
                <span>Strategy Methodology </span>
                {details[2].value}
              </p>
            </div>
          </div>
        </div>
        <div className="strategy__details__box">
          <div className="details__header">
            <span>Added Stocks&nbsp;({stocks.length})</span>
          </div>
          <div className="stocks__table">
            <table>
              <thead>
                <tr>
                  <td>Symbol</td>
                  <td>Company Name</td>
                  <td>Exchange</td>
                  <td>Stock Weight (%)</td>
                  <td>Stock Industry</td>
                </tr>
              </thead>
              <tbody>
                <CompanyBox
                  key="0"
                  data={{
                    stock_code: "CASH",
                    name: "-",
                    stock_weight: cash_weight,
                    industry: "-",
                  }}
                />
                {stocks.map((el, index) => (
                  <CompanyBox key={index + 1} data={el} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="strategy__charts">
        <div className="strategy__details__box">
          <div className="details__header">
            <span>Sector Weights</span>
          </div>
          <SectorMarketChart
            values={stocks.map((s) => {
              return {
                name: s["industry"],
                value: s["stock_weight"],
              };
            })}
            unit="Stocks"
          />
        </div>
        {/* <div className="strategy__details__box">
          <div className="details__header">
            <span>Market Cap Breakdown</span>
          </div>
          <SectorMarketChart
            values={stocks.map((s) => {
              return {
                name: s["market_cap_label"],
                value: s["stock_weight"],
              };
            })}
            unit="Stocks"
          />
        </div> */}
      </div>
    </div>
  );
}

export default SmallCase;
