import React, { useState, useEffect } from "react";
// import "../../../css/BusinessNews/Technicals.css";

const marksa = [
  {
    value: -2,
    label: "Strong Sell",
  },
  {
    value: -1,
    label: "Sell",
  },
  {
    value: 0,
    label: "Neutral",
  },
  {
    value: 1,
    label: "Buy",
  },
  {
    value: 2,
    label: "Strong Buy",
  },
];

const marksb = [
  {
    value: -2,
    label: "Sell",
  },
  {
    value: 0,
    label: "Neutral",
  },
  {
    value: 2,
    label: "Buy",
  },
];

const GetLabel = (array, val = 0) => {
  const ele = array.find((a) => a.value === val);
  const label = ele ? ele.label : "Inconclusive";
  return label;
};

const GetClass = (label) => {
  if (label === "Strong Sell" || label === "Sell") {
    return "negative";
  } else if (label === "Strong Buy" || label === "Buy") {
    return "positive";
  } else {
    return "neutral";
  }
};

const convertIntoPriceFormat = (num, frac = 1) => {
  if (num) {
    return num.toLocaleString("en-IN", {
      minimumFractionDigits: frac,
      currency: "INR",
    });
  } else {
    return num;
  }
};

const TechnicalsViews = ({ targets }) => {
  const st = GetLabel(marksa, targets.STTV.point);
  const lt = GetLabel(marksa, targets.LTTV.point);
  const rsi = GetLabel(marksa, targets.RSIV.point);
  const macd = GetLabel(marksa, targets.MACDV.point);

  return (
    <>
      <div className="swift-stock-management-technical-tech__view">
        <div className="swift-stock-management-technical-slider__wrapper">
          <p className="swift-stock-management-technical-view__title">
            Short Term Technical View
          </p>
          <div className="swift-stock-management-technical-slider">
            <p className={`${GetClass(st)} stock__view__label`}>{st}</p>
          </div>
        </div>
        <div className="swift-stock-management-technical-text">
          <p>Using 5, 10, 20 day Exponential Moving Average.</p>
        </div>
      </div>
      <div className="swift-stock-management-technical-tech__view">
        <div className="swift-stock-management-technical-slider__wrapper">
          <p className="swift-stock-management-technical-view__title">
            Long Term Technical View
          </p>
          <div className="swift-stock-management-technical-slider">
            <p className={`${GetClass(lt)} stock__view__label`}>{lt}</p>
          </div>
        </div>
        <div className="swift-stock-management-technical-text">
          <p>Using 20, 50, 100 day Exponential Moving Average.</p>
        </div>
      </div>
      <div className="swift-stock-management-technical-tech__view">
        <div className="swift-stock-management-technical-slider__wrapper">
          <p className="swift-stock-management-technical-view__title">
            Relative Strength Index
          </p>
          <div className="swift-stock-management-technical-slider">
            <p className={`${GetClass(rsi)} stock__view__label`}>{rsi}</p>
          </div>
        </div>
        <div className="swift-stock-management-technical-text">
          <p>Momentum indicator used in technical analysis.</p>
        </div>
      </div>
      <div className="swift-stock-management-technical-tech__view small">
        <div className="swift-stock-management-technical-slider__wrapper">
          <p className="swift-stock-management-technical-view__title">MACD</p>
          <div className="swift-stock-management-technical-slider">
            <p className={`${GetClass(macd)} stock__view__label`}>{macd}</p>
          </div>
        </div>
        <div className="swift-stock-management-technical-text">
          <p>Moving Average Convergence Divergence.</p>
        </div>
      </div>
    </>
  );
};

const TechnicalsTargets = ({ targets }) => {
  const ov = GetLabel(marksa, targets.OVERV.point);

  return (
    <>
      <div className="swift-stock-management-technical-tech__view__final">
        <p className="swift-stock-management-technical-view__title">
          Short Term Technical View
        </p>
        <p className="swift-stock-management-technical-text">
          Using the four factors above.
        </p>
        <p className={`${GetClass(ov)} stock__view__label`}>{ov}</p>
      </div>
      <div className="swift-stock-management-technical-target__price">
        <div className="swift-stock-management-technical-target__genie">
          <p>Our AI and ML prediction models indicate a</p>
        </div>
        {targets.FPV.Enough ? (
          <div className="swift-stock-management-technical-target__value">
            <p>
              <span className="span__bold">{targets.FPV.Days} Day</span>&nbsp;
              target of&nbsp;&nbsp;
              <span className="span__bold">
                Rs. {convertIntoPriceFormat(targets.FPV.FutureAmount)}{" "}
              </span>
              &nbsp;&nbsp;
              <span
                className={
                  targets.FPV.FuturePer >= 0
                    ? "span__bold swift-stock-management-technical-target__change positive"
                    : "span__bold swift-stock-management-technical-target__change negative"
                }
              >
                {convertIntoPriceFormat(targets.FPV.FuturePer)}%
              </span>
            </p>
          </div>
        ) : (
          <div className="swift-stock-management-technical-target__value">
            <p className="swift-stock-management-technical-target__empty">
              Not Enough Data To Predict
            </p>
          </div>
        )}
      </div>
    </>
  );
};

const StockTechnical = ({ technicals, isVisible }) => {
  // console.log(technicals);

  if (technicals) {
    return (
      <div
        className="swift-stock-management-technical"
        id="technical"
        style={{ display: !isVisible ? "none" : "flex" }}
      >
        <div className="swift-stock-management-detail-row-2-left-title">
          Technicals
        </div>
        <div className="swift-stock-management-technical-technicals">
          {/* <p className="card__title">Technical Evaluation</p> */}
          <div className="swift-stock-management-technical-views">
            <TechnicalsViews targets={technicals} />
          </div>
          <div className="swift-stock-management-technical-target">
            <TechnicalsTargets targets={technicals} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="swift-stock-management-technical"
        id="technical"
        style={{ display: !isVisible ? "none" : "flex" }}
      >
        <div className="swift-stock-management-technical-header">
          Technicals
        </div>
        <div className="swift-stock-management-technical-technicals">
          <p>No Data</p>
        </div>
      </div>
    );
  }
};

export default StockTechnical;
