import React, { Component } from 'react';
import Axios from 'axios';
import "../../../../css/MenuSection/PortfolioRebalance.css"
import CustomSearchSelect from '../../CustomChartComponents/CustomSearchSelect/CustomSearchSelect';
import { Alert } from '../../CustomChartComponents/CustomAlert/CustomAlert';
import { GetCookie } from '../../../../exports/CookieManagement';
import SmallcaseCustomerTable from './SmallcaseCustomerTable';
import Pulse from '../../../Loader/Pulse';


const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;


class ApplySmallcasePopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user_role: this.props.user_role,
            user_role_type: this.props.user_role_type,
            account_token: GetCookie('token'),
            customersLoading: true,
            customers: [],
            schemeLoading: true,
            scheme_portfolios: [],
            addedSchemes: [],
            smallcaseCustomers: [],
            activeCustomer: false,
            advisorpan: false,
            userportfolios: [],
            cashPos: 0,
            activatedstrategies: [],
            activatedportfolios: [],
            newportfolio: false,
            currentportfolio: this.props.currentportfolio,
            portfolioname: false,
            smallcasename: false,
            stocks: [],
            stocksbuyvalue: 0,
            stockssellvalue: 0,
            dataLen: 0,
            isLoading: false,
            mincash: 0,
            mininvestment: 0,
            capital: this.props.cashpos <= 0 ? 0 : this.props.cashpos,
            purchased: false,
            portfolio_value: 0,
            execute: false,
            tradeWindow: false
        }
        this.TopSection = this.TopSection.bind(this);
        this.ResetExecute = this.ResetExecute.bind(this);
        this.setMinInvestment = this.setMinInvestment.bind(this);
        this.setCustomer = this.setCustomer.bind(this);
        this.getActivatedStrategies = this.getActivatedStrategies.bind(this);
        this.removeCustomer = this.removeCustomer.bind(this);
        this.RemoveScheme = this.RemoveScheme.bind(this);
        this.SetTradeWindow = this.SetTradeWindow.bind(this);
    }

    componentDidMount() {
        this.getCustomerPortfolios();
        this.getSchemePortfolios();
        this.getActivatedStrategies();
    }

    getCustomerPortfolios() {
        const { account_token } = this.state;
        Axios.get(`${REQUEST_BASE_URL}/smallcase/customers?token=${account_token}`)
            .then((response) => {
                this.setState({ customers: response.data.customers, customersLoading: false })
            });
    }

    getSchemePortfolios(){
        const { account_token } = this.state;
        Axios.get(`${REQUEST_BASE_URL}/scheme?token=${account_token}`)
            .then((response) => {
                const data = response.data;
                if(data.error){
                    this.setState({ scheme_portfolios: [], schemeLoading: false })
                }
                else{
                    this.setState({ scheme_portfolios: data.scheme_portfolios, schemeLoading: false })
                }
            });
    }

    setSchemeCustomers(scheme){
        const {id} = scheme;
        const {scheme_portfolios,customers,smallcaseCustomers} = this.state;

        const scheme_ids = smallcaseCustomers.map((s)=>{return s.scheme_portfolio_id});

        if(scheme_ids.includes(id)){
            Alert({
                TitleText: 'Warning',
                Message: 'Selected scheme already added',
                Band: true,
                BandColor: '#E51A4B',
                BoxColor: '#ffffff',
                TextColor: '#000000',
                AutoClose: {
                    Active: true,
                    Line: true,
                    LineColor: '#E51A4B',
                    Time: 3
                }
            })
            return;
        }

        

        let scheme_info = scheme_portfolios.find((s)=>{
            return s['scheme_portfolio_id'] === id;
        });

        if(!scheme_info) return;

        const {scheme_portfolio_customers} = scheme_info;
        const scheme_account_codes = scheme_portfolio_customers.map((s)=>{
            return s.customer_account_code
        });

        const scheme_customers = customers.filter((c)=>{
            return scheme_account_codes.includes(c.account_code);
        });

        // const total_cash = scheme_customers.reduce((a,b)=>{return a + parseFloat(b.cash_virtual)},0);
        // const total_portfolio_value = scheme_customers.reduce((a,b)=>{return a + parseFloat(b.portfolios_config['currentValueSum'])},0);
        let total_cash = 0;
        let total_portfolio_value = 0;

        scheme_customers.forEach((scheme)=>{
            const {cash_virtual,portfolios_config} = scheme;
            total_cash += parseFloat(cash_virtual);

            if(portfolios_config && Array.isArray(portfolios_config) && portfolios_config.length > 0){
                total_portfolio_value += parseFloat(portfolios_config[0]['currentValueSum']);
            }

        })

        const scheme_details = {
            ...scheme_info,
            scheme_portfolio_customers: scheme_customers,
            scheme_total_customers: scheme_customers.length,
            scheme_total_cash: total_cash,
            scheme_total_portfolio_value: total_portfolio_value     
        };

        this.setState({
            smallcaseCustomers: [...smallcaseCustomers, scheme_details]
        });
        
    }

    setCustomer(customer) {

        const { smallcaseCustomers } = this.state;

        const account_codes = smallcaseCustomers.map((s) => { return s.account_code });
        if (account_codes.includes(customer.account_code)) {
            Alert({
                TitleText: 'Warning',
                Message: 'Selected customer already added',
                Band: true,
                BandColor: '#E51A4B',
                BoxColor: '#ffffff',
                TextColor: '#000000',
                AutoClose: {
                    Active: true,
                    Line: true,
                    LineColor: '#E51A4B',
                    Time: 3
                }
            })
            return;
        }

        this.setState({
            activeCustomer: true,
            smallcaseCustomers: [...smallcaseCustomers, customer]
        });
    }

    removeCustomer(account_code) {

        const { smallcaseCustomers } = this.state;
        let customers = smallcaseCustomers.filter((s) => {
            return s.account_code !== account_code;
        });

        this.setState({
            smallcaseCustomers: [...customers],
            activeCustomer: customers.length > 0
        })
    }

    getActivatedStrategies() {

        const { account_token } = this.state;

        Axios.get(`${REQUEST_BASE_URL}/fetch_smallcase?token=${account_token}`)
            .then(res => {
                // console.log(res.data)
                this.setState({ activatedstrategies: res.data.details })
            })
    }

    TopSection() {
        const {scheme_portfolios } = this.state;

        const scheme_array = scheme_portfolios.map((s)=>{
            return {
                name: s.scheme_portfolio_name,
                id: s.scheme_portfolio_id
            }
        });

        return (
            <div className="apply__smallcase__content">
                    <CustomSearchSelect title="Search & Add Schemes" placeholder='Enter Scheme Name' options={scheme_array} onSelect={(val) => { this.setSchemeCustomers(val) }} showSearch={false} hideOnSelect />
            </div>

        )
    }

    setMinInvestment() {
        const { portfolio_value, mincash } = this.state;
        let min_cash = mincash - portfolio_value;
        if (min_cash <= 0) {
            this.setState({
                mininvestment: 0
            })
        }
        else {
            this.setState({
                mininvestment: min_cash
            })
        }
    }

    ResetExecute() {
        this.getCustomerPortfolios();
        this.getActivatedStrategies();
        this.setState({
            activeCustomer: false,
            smallcaseCustomers: []
        });
    }

    RemoveScheme(scheme_id){
        const {smallcaseCustomers} = this.state;
        let schemes = smallcaseCustomers.filter((s)=>{
            return s.scheme_portfolio_id !== scheme_id;
        });

        this.setState({
            smallcaseCustomers: schemes
        });
    }

    SetTradeWindow(status){
        this.setState({
            tradeWindow: status
        });
    }


    render() {
        const { customersLoading, smallcasename, smallcase_id, mincash, smallcaseCustomers,activatedstrategies,tradeWindow } = this.state;

        return (
            <div className="apply__smallcase__container">

                {customersLoading ? <>
                    <div className='smallcase__container__loading'>
                        <Pulse />
                        <p>Loading blanket execution...</p>
                    </div>
                </> :
                    <>
                        <>
                            {!tradeWindow && 
                                <this.TopSection />
                            }
                            <SmallcaseCustomerTable
                                schemes={smallcaseCustomers}
                                smallcases={activatedstrategies}
                                customers={[]}
                                removeCustomer={this.removeCustomer}
                                smallcaseName={smallcasename}
                                smallcaseId={smallcase_id}
                                minimumCash={mincash}
                                resetExecute={this.ResetExecute}
                                RemoveScheme={this.RemoveScheme}
                                SetTradeWindow={this.SetTradeWindow}
                            />
                        </>
                    </>

                }

            </div>
        );
    }
}

export default ApplySmallcasePopup;