import React, { useEffect, useState } from "react";
import useMarketStockNew from "../../hooks/useMarketStockNew";
import useFullSnapQuote from "../../hooks/useFullSnapQuote";
import useFullSnapQuoteNew from "../../hooks/useFullSnapQuoteNew";
import useMarketStock from "../../hooks/useMarketStock";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";

const HeroAbout = () => {
  const [symbol, setSymbol] = useState("HDFCBANK");
  const stock_data = useMarketStockNew(symbol);
  const snap = useFullSnapQuoteNew(symbol);

  const symbols = ["HDFCBANK", "RELIANCE", "TCS", "INFY", "SBIN"];

  // useEffect(() => {
  //     const interval = setInterval(() => {
  //         const randomIndex = Math.floor(Math.random() * symbols.length);
  //         setSymbol(symbols[randomIndex]);
  //     }, 5000);

  //     return () => clearInterval(interval); // Cleanup interval on component unmount
  // }, []);

  function AlertMessage(message) {
    Alert({
      TitleText: "Warning",
      Message: message,
      Band: true,
      BandColor: "#E51A4B",
      BoxColor: "#ffffff",
      TextColor: "#000000",
      AutoClose: {
        Active: true,
        Line: true,
        LineColor: "#E51A4B",
        Time: 5,
      },
    });
  }

  if (!stock_data || !snap) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {/* <div>
        {symbols.map((sym) => (
          <button key={sym} onClick={() => setSymbol(sym)}>
            {sym}
          </button>
        ))}
      </div>
      <p>Current Symbol: {symbol}</p>
      <div>{JSON.stringify(stock_data)}</div>
      <div>{JSON.stringify(snap)}</div> */}

      <div style={{ marginTop: "20px" }}>
        <button
          onClick={() => {
            AlertMessage("Something went wrong!");
          }}
        >
          Alert Message
        </button>
      </div>
    </div>
  );
};

export default HeroAbout;
