import React from 'react';
import Axios from 'axios';
import { Navigate } from 'react-router-dom';
import Spinner from '../../Loader/Spinner';
import Pulse from '../../Loader/Pulse';
import moment from 'moment';
import OrderHistoryIcon from '../../../assets/icons/orderhistory.svg';
import EmptyOrder from '../../../assets/icons/emptyorder.svg';
import { getToken, getMobile } from '../../../exports/UserAuth';

import '../../../css/Backoffice/BackofficeClient.css';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

let updateinterval;

const curr = (val) => {
    if (isNaN(val)) {
        return val
    }
    else {
        return parseFloat(val).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'INR' })
    }
}

const bo_menu = [
    {
        'name': 'Cash Position',
        'key': 'cash'
    },
    {
        'name': 'Order Book (Pending)',
        'key': 'obp'
    },
    {
        'name': 'Order Book (Completed)',
        'key': 'obc'
    },
    {
        'name': 'Trade Book',
        'key': 'trade'
    },
    {
        'name': 'Position Book (Live)',
        'key': 'pbl'
    },
    {
        'name': 'Position Book (Historical)',
        'key': 'pbh'
    },
    {
        'name': 'Demat Holdings',
        'key': 'holding'
    }
]


class CashPosition extends React.PureComponent {

    constructor(props) {

        super(props);
        this.state = {
            data: [],
            loaded: false
        }

        this.GetCashPosition = this.GetCashPosition.bind(this);
    }

    componentDidMount() {
        const { client_id } = this.props;
        this.GetCashPosition(client_id);

        this.updateinterval = setInterval(() => {
            this.GetCashPosition(client_id);
        }, 2000);

    }

    GetCashPosition(client_id) {
        Axios.get(`${REQUEST_BASE_URL}/clientinfo/funds?clientid=${client_id}`)
            .then((res) => {
                const data = res.data
                if (!data.error) {
                    this.setState({
                        data: data.funds,
                        loaded: true
                    })
                }
            }).catch((err) => {
                this.setState({
                    loaded: true
                })
            })
    }

    componentWillUnmount() {
        if (this.updateinterval) {
            clearImmediate(this.updateinterval);
        }
    }

    render() {
        const { data, loaded } = this.state;

        if (!loaded) {
            return <EmptyTable loading={true} />
        }
        else {
            if (data.length == 0) {
                return <EmptyTable loading={false} message="Cash Position not found" />
            }
            else {
                const headers = data.headers;
                const values = data.values;
                return <table>
                    <thead>
                        <tr>
                            {headers.map((h, indx) => {
                                return <th key={indx}>{h}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {values.map((row, indx) => {
                            return <tr key={indx}>
                                {row.map((v, i) => {
                                    return <td key={i}>{curr(v)}</td>
                                })}
                            </tr>
                        })}
                    </tbody>
                </table>
            }
        }
    }
}

class ClientHoldings extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loaded: false
        }
    }

    componentDidMount() {
        const { client_id } = this.props;

        Axios.get(`${REQUEST_BASE_URL}/clientinfo/holdings?clientid=${client_id}`)
            .then((res) => {
                const data = res.data
                if (!data.error) {
                    this.setState({
                        data: data.holding,
                        loaded: true
                    })
                }
            }).catch((err) => {
                this.setState({
                    loaded: true
                })
            })

    }

    render() {
        const { data, loaded } = this.state;

        if (!loaded) {
            return <EmptyTable loading={true} />
        }
        else {
            if (data.length == 0) {
                return <EmptyTable loading={false} message="Holdings not found!" />
            }
            else {
                return <table>
                    <thead>
                        <tr>
                            <th>SYMBOL</th>
                            <th>EXCHANGE</th>
                            <th>QUANTITY</th>
                            <th>BUY AVG</th>
                            <th>T0,T1,T2</th>

                        </tr>
                    </thead>
                    <tbody>
                        {data.map((d, indx) => {
                            return <tr key={indx}>
                                <td>{d['symbol']}</td>
                                <td>{d['exchange']}</td>
                                <td>{d['quantity']}</td>
                                <td>{curr(d['buy_avg'])}</td>
                                <td>{`(${d['t0_quantity']}, ${d['t1_quantity']}, ${d['t2_quantity']})`}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            }
        }
    }
}

class ClientOrders extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loaded: false
        }
    }

    componentDidMount() {
        const { client_id, type } = this.props;


        Axios.get(`${REQUEST_BASE_URL}/clientinfo/orders?clientid=${client_id}&type=${type}`)
            .then((res) => {
                const data = res.data;
                if (!data.error) {
                    this.setState({
                        data: data.orders,
                        loaded: true
                    })
                }
            }).catch((err) => {
                this.setState({
                    loaded: true
                })
            })

    }

    render() {
        const { data, loaded } = this.state;
        const { setOMSID } = this.props;

        if (!loaded) {
            return <EmptyTable loading={true} />
        }
        else {
            if (data.length == 0) {
                return <EmptyTable loading={false} message="No orders found for today!" />
            }
            else {
                return <table>
                    <thead>
                        <tr>
                            <th>SYMBOL</th>
                            <th>EXCHANGE</th>
                            <th>ORDER SIDE</th>
                            <th>ORDER TYPE</th>
                            <th>QUANTITY</th>
                            <th>ORDER PRICE</th>
                            <th>REM. QUANTITY</th>
                            <th>STATUS</th>
                            <th>HISTORY</th>

                        </tr>
                    </thead>
                    <tbody>
                        {data.map((d, indx) => {
                            return <tr key={indx}>
                                <td>{d['trading_symbol']}</td>
                                <td>{d['exchange']}</td>
                                <td>{d['order_side']}</td>
                                <td>{d['order_type']}</td>
                                <td>{d['quantity']}</td>
                                <td>{curr(d['price'])}</td>
                                <td>{d['remaining_quantity']}</td>
                                <td>{d['order_status']}</td>
                                <td onClick={() => { setOMSID(d['oms_order_id']) }}><img src={OrderHistoryIcon} alt="" /></td>
                            </tr>
                        })}
                    </tbody>
                </table>
            }
        }
    }
}

class ClientTrades extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loaded: false
        }
    }

    componentDidMount() {
        const { client_id } = this.props;

        Axios.get(`${REQUEST_BASE_URL}/clientinfo/trades?clientid=${client_id}`)
            .then((res) => {
                const data = res.data
                if (!data.error) {
                    this.setState({
                        data: data.trades,
                        loaded: true
                    })
                }
            }).catch((err) => {
                this.setState({
                    loaded: true
                })
            })

    }

    render() {
        const { data, loaded } = this.state;

        if (!loaded) {
            return <EmptyTable loading={true} />
        }
        else {
            if (data.length == 0) {
                return <EmptyTable loading={false} message="No trades found for today!" />
            }
            else {
                return <table>
                    <thead>
                        <tr>
                            <th>SYMBOL</th>
                            <th>EXCHANGE</th>
                            <th>ORDER SIDE</th>
                            <th>ORDER TYPE</th>
                            <th>FILLED QUANTITY</th>
                            <th>PRICE</th>
                            <th>TRADE TIME</th>

                        </tr>
                    </thead>
                    <tbody>
                        {data.map((d, indx) => {
                            return <tr key={indx}>
                                <td>{d['trading_symbol']}</td>
                                <td>{d['exchange']}</td>
                                <td>{d['order_side']}</td>
                                <td>{d['order_type']}</td>
                                <td>{d['filled_quantity']}</td>
                                <td>{curr(d['trade_price'])}</td>
                                <td>{moment.unix(d['trade_time']).format("HH:mm:ss")}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            }
        }
    }
}

class ClientPositions extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loaded: false
        }
    }

    componentDidMount() {
        const { client_id, type } = this.props;


        Axios.get(`${REQUEST_BASE_URL}/clientinfo/positions?clientid=${client_id}&type=${type}`)
            .then((res) => {
                const data = res.data;
                if (!data.error) {
                    this.setState({
                        data: data.position,
                        loaded: true
                    })
                }
            }).catch((err) => {
                this.setState({
                    loaded: true
                })
            })

    }

    render() {
        const { data, loaded } = this.state;

        if (!loaded) {
            return <EmptyTable loading={true} />
        }
        else {
            if (data.length == 0) {
                return <EmptyTable loading={false} message="Position book not found!" />
            }
            else {
                return <table>
                    <thead>
                        <tr>
                            <th>SYMBOL</th>
                            <th>EXCHANGE</th>
                            <th>SELL QUANTITY</th>
                            <th>SELL AMOUNT</th>
                            <th>BUY QUANTITY</th>
                            <th>BUY AMOUNT</th>
                            <th>NET AMOUNT</th>
                            <th>PREVIOUS CLOSE</th>

                        </tr>
                    </thead>
                    <tbody>
                        {data.map((d, indx) => {
                            return <tr key={indx}>
                                <td>{d['symbol']}</td>
                                <td>{d['exchange']}</td>
                                <td>{d['sell_quantity']}</td>
                                <td>{curr(d['sell_amount'])}</td>
                                <td>{d['buy_quantity']}</td>
                                <td>{curr(d['buy_amount'])}</td>
                                <td>{curr(d['net_amount'])}</td>
                                <td>{d['previous_close']}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            }
        }
    }
}

class ClientOrderHistory extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loaded: false
        }
    }

    componentDidMount() {
        const { client_id, omsid } = this.props;

        Axios.get(`${REQUEST_BASE_URL}/clientinfo/orderhistory?clientid=${client_id}&omsid=${omsid}`)
            .then((res) => {
                const data = res.data
                if (!data.error) {
                    this.setState({
                        data: data.history.reverse(),
                        loaded: true
                    })
                }
            }).catch((err) => {
                this.setState({
                    loaded: true
                })
            })

    }

    render() {
        const { data, loaded } = this.state;

        const formattime = (time) => {
            if (time === "--" || time === " ") {
                return "--";
            }
            else {
                return moment(time).format("HH:mm:ss");
            }
        }

        if (!loaded) {
            return <EmptyTable loading={true} />
        }
        else {
            if (data.length == 0) {
                return <EmptyTable loading={false} message="Order history not found!" />
            }
            else {
                return <table>
                    <thead>
                        <tr>
                            <th>SYMBOL</th>
                            <th>EXCHANGE</th>
                            <th>ORDER SIDE</th>
                            <th>ORDER TYPE</th>
                            <th>QUANTITY</th>
                            <th>AVG. PRICE</th>
                            <th>STATUS</th>
                            <th>TIMESTAMP</th>

                        </tr>
                    </thead>
                    <tbody>
                        {data.map((d, indx) => {
                            return <tr key={indx}>
                                <td>{d['trading_symbol']}</td>
                                <td>{d['exchange']}</td>
                                <td>{d['order_side']}</td>
                                <td>{d['order_type']}</td>
                                <td>{d['quantity']}</td>
                                <td>{d['avg_price']}</td>
                                <td>{d['status']}</td>
                                <td>{formattime(d['exchange_time'])}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            }
        }
    }
}

class EmptyTable extends React.PureComponent {
    render() {
        const { loading, message } = this.props;
        return <div className='bo__table__empty'>
            {loading ?
                <Pulse /> :
                <div className='bo__empty'>
                    <img src={EmptyOrder} alt="" />
                    <p>{message}</p>
                </div>
            }
        </div>
    }
}

export class BackofficeClient extends React.PureComponent {


    constructor(props) {
        super(props);
        this.state = {
            loggedin: true,
            logincheck: true,
            client_id: false,
            client_loaded: false,
            is_client: false,
            client_data: false,
            client_token: false,
            table_field: 'cash',
            omsid: null
        }

        this.setField = this.setField.bind(this);
        this.setOMSID = this.setOMSID.bind(this);
    }

    componentDidMount() {
        this.checkLogin();
    }

    async GetClientDetails(client_id) {
        return new Promise((resolve, reject) => {
            Axios.get(`${REQUEST_BASE_URL}/clientinfo/clientdetails?clientid=${client_id}`)
                .then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    resolve({ error: true })
                })
        })
    }

    async GetClientToken(client_id) {
        return new Promise((resolve, reject) => {
            Axios.get(`${REQUEST_BASE_URL}/clientinfo/clienttoken?clientid=${client_id}`)
                .then((res) => {
                    resolve(res.data)
                }).catch((err) => {
                    resolve({ error: true })
                })
        })
    }

    async checkLogin() {
        const client_id = new URLSearchParams(this.props.location.search).get('id');
        const data = await this.GetClientDetails(client_id);
        const token_data = await this.GetClientToken(client_id);
        if (!data.error && !token_data.error) {
            this.setState({
                client_id: client_id,
                is_client: true,
                client_loaded: true,
                client_data: data.data,
                client_token: token_data.userrecord.Token
            })
        }
        else {
            this.setState({
                is_client: false,
                client_loaded: true
            })
        }
    }

    setField(table_field) {
        this.setState({
            table_field: table_field
        })
    }

    setOMSID(omsid) {
        this.setState({
            omsid: omsid,
            table_field: "history"
        })
    }

    render() {

        const { loggedin, logincheck, client_loaded, is_client, client_data, client_id, table_field, omsid } = this.state;

        return (
            <>
                {loggedin && client_loaded ?
                    <>
                        {is_client ?
                            <div className='backoffice__client__wrapper'>
                                <div className='backoffice__client'>
                                    <div className='bo__client__details'>
                                        <p>{client_data.primaryname}</p>
                                    </div>
                                    <div className='bo__data'>
                                        <div className='bo__menu'>
                                            {bo_menu.map((m, indx) => {
                                                return <div
                                                    key={indx}
                                                    className={table_field === m.key ? "bo__menu__item active" : 'bo__menu__item'}
                                                    onClick={() => { this.setField(m.key) }}
                                                >
                                                    <span>{m['name']}</span>
                                                </div>
                                            })}

                                        </div>
                                        <div className='bo__table'>
                                            {table_field === "cash" && <CashPosition client_id={client_id} />}
                                            {table_field === "holding" && <ClientHoldings client_id={client_id} />}
                                            {table_field === "obp" && <ClientOrders client_id={client_id} type="pending" setOMSID={this.setOMSID} />}
                                            {table_field === "obc" && <ClientOrders client_id={client_id} type="complete" setOMSID={this.setOMSID} />}
                                            {table_field === "trade" && <ClientTrades client_id={client_id} />}
                                            {table_field === "pbl" && <ClientPositions client_id={client_id} type="live" />}
                                            {table_field === "pbh" && <ClientPositions client_id={client_id} type="historical" />}
                                            {table_field === "history" && <ClientOrderHistory client_id={client_id} omsid={omsid} />}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <Navigate to="NotFound" />

                        }
                    </>
                    :
                    <>
                        {!logincheck ?
                            <Navigate to="NotFound" />
                            :
                            <div className="backoffice__login__check">
                                <Spinner size={50} />
                            </div>
                        }
                    </>
                }
            </>
        )
    }
}

export default BackofficeClient;