import React from 'react';
import PropTypes from "prop-types";
import Calculator from '../../../../assets/icons/calculator.svg';
import Plus from '../../../../assets/icons/plus.svg';
import Minus from '../../../../assets/icons/minus.svg';
import '../../../../css/CustomComponents/PriceBox.css';

class PriceBox extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isCalculatorOpen: false
        }
        this.setComponentRef = this.setComponentRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.toggleCalculator = this.toggleCalculator.bind(this);
        this.closeCalculator = this.closeCalculator.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    toggleCalculator() {
        this.setState((prevState) => ({
            isCalculatorOpen: !prevState.isCalculatorOpen
        }));
    }

    closeCalculator() {
        this.setState({
            isCalculatorOpen: false
        })
    }

    handleClickOutside(event) {

        if (this.ComponentRef && !this.ComponentRef.contains(event.target)) {
            this.closeCalculator();
        }
    }


    handleChange(value) {
        // let value = parseInt(e.target.value.replace(/[^0-9]*/g, ''));
        value = isNaN(value) ? 0 : value;
        this.props.changehandler(value);
    }

    setComponentRef(node) {
        this.ComponentRef = node;
    }

    render() {
        const { enabled, valid, label, helper, step, value, editable, calculator } = this.props;
        const { isCalculatorOpen } = this.state;
        return (
            <div className={enabled ? (valid ? 'price-box' : 'price-box invalid') : 'price-box disabled'} ref={this.setComponentRef}>
                <div className='price-box-title'>
                    <span className='label'>{label}</span>
                    <span className='helper'>{helper}</span>
                </div>
                <div className='price-box-content'>
                    {editable ?
                        <>
                            <div className='price-box-value'>
                                <input type="number" min="0" step={step} value={value} onInput={(e) => { this.handleChange(e.target.value); }} />
                            </div>
                            {calculator ? <div className='price-box-options' onClick={() => { this.toggleCalculator() }}>
                                <img src={Calculator} />
                            </div> : null}
                            {isCalculatorOpen &&
                                <div className='price-box-calculator'>
                                    <div className='calculator-col'>
                                        <span onClick={() => { this.handleChange(value - 1) }}><img src={Minus} alt="-" /></span>
                                        <span onClick={() => { this.handleChange(value - 5) }}>5</span>
                                        <span onClick={() => { this.handleChange(value - 25) }}>25</span>
                                        <span onClick={() => { this.handleChange(value - 100) }}>100</span>
                                    </div>
                                    <div className='calculator-col'>
                                        <span onClick={() => { this.handleChange(parseInt(value) + 1) }}><img src={Plus} alt="+" /></span>
                                        <span onClick={() => { this.handleChange(parseInt(value) + 5) }}>5</span>
                                        <span onClick={() => { this.handleChange(parseInt(value) + 25) }}>25</span>
                                        <span onClick={() => { this.handleChange(parseInt(value) + 100) }}>100</span>
                                    </div>
                                </div>
                            }
                        </>
                        :
                        <div className='price-box-value'>
                            <span>{value}</span>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

PriceBox.propTypes = {
    enabled: PropTypes.bool,
    valid: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.any,
    helper: PropTypes.string,
    step: PropTypes.number,
    editable: PropTypes.bool,
    calculator: PropTypes.bool,
    changehandler: PropTypes.func
}

PriceBox.defaultProps = {
    enabled: true,
    valid: true,
    label: 'label',
    helper: '',
    value: 0.00,
    step: 0.01,
    editable: true,
    calculator: false,
    changehandler: null
}

export default PriceBox;
