import React, { useState } from 'react';
import { ArrowRight2, ArrowDown2, FilterSearch } from '../../CustomComponents/SwiftIcon/Icons';
import TradeItem from './TradeItem';
import { numberFormat, titleCase } from '../../../exports/UtilFunctions';


function TradeSection({ user_role_type,order }) {


    const [filter, setFilter] = useState('');
    const [trade, setTrade] = useState([]);
    const [totalSellValue, setTotalSellValue] = useState(0);
    const [totalBuyValue, setTotalBuyValue] = useState(0);
    const [totalTradeValue, setTotalTradeValue] = useState(0);
    const [open, setOpen] = useState(false);


    function setTradeIndex(index, value, side) {
        const arr = trade;
        arr[index] = side.toUpperCase() === 'SELL' ? -1 * value : value;
        setTrade(arr);

        if (arr.length === 0) return;

        const sum = arr.reduce((a, b) => { return a + b });
        const sell_sum = arr.filter((a) => a < 0).reduce((a, b) => a + b, 0);
        const buy_sum = arr.filter((a) => a >= 0).reduce((a, b) => a + b, 0);
        setTotalSellValue(-1 * sell_sum);
        setTotalBuyValue(buy_sum);
        setTotalTradeValue(sum);
    }

    return (<>

        <div className='order-trades-section'>
            <div className='order-trades-header'>
                <div className='order-trades-config'>
                    <div className='config-box expand-collapse-box arrow' onClick={() => { setOpen(!open); setFilter('') }} >
                        {open ? <ArrowDown2 size={24} color="#192b40" /> : <ArrowRight2 size={24} color="#192b40" />}
                    </div>

                    <div className='config-box name'>
                        <span title={titleCase(order.config.name)}>{titleCase(order.config.name)}</span>
                    </div>
                    <div className='config-box account'>
                        <span>{order.config.user_role === 'MAC' ? 'Managed Account' : 'Broking Account'}</span>
                    </div>

                    <div className='config-box count'>
                        <span>{order.config.count}</span>
                    </div>
                    <div className='config-box sell'>
                        <span>{numberFormat(totalSellValue)}</span>
                    </div>
                    <div className='config-box buy'>
                        <span>{numberFormat(totalBuyValue)}</span>
                    </div>
                    <div className={'config-box trade ' + (totalTradeValue >= 0 ? 'positive' : 'negative')}>
                        <span>{numberFormat(totalTradeValue)}</span>
                    </div>
                </div>

            </div>

            <div className={"order-trades-container-wrapper " + (open ? '' : 'hide')}>
                <div className='order-trades-options'>
                    <p>Generated Trades</p>
                    <div className='order-trades-search'>
                        <input placeholder='Search symbol' value={filter} onChange={(e) => { setFilter(e.target.value.toUpperCase()) }} />
                        <div className='filter-icon'><FilterSearch size={20} color="#192b40" /></div>
                    </div>
                </div>

                <div className="order-trades-container trades">
                    <table>
                        <thead>
                            <tr>
                                <td>Stock Name</td>
                                <td>Exchange</td>
                                <td>Order Type</td>
                                <td>Limit Price</td>
                                <td>Order Side</td>
                                <td>Quantity</td>
                                <td>Current Price</td>
                                <td>Amount</td>
                                <td>Broker</td>
                                <td>Status</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {order['trades'].map((o, index) => {
                                return <TradeItem
                                    key={index}
                                    user_role_type={user_role_type}
                                    setTradeIndex={setTradeIndex}
                                    index={index}
                                    show={o.stockCode.includes(filter)}
                                    order={o}
                                />
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </>)
}

export default TradeSection;