import React, { useState } from "react";
import "../../css/FundsManagement/FundsManagement.css";
import FundsHistory from "./FundsHistory";
import FundsRequest from "./FundsRequest";
import { getDeviceSize } from "../../exports/InteractiveUtils";
import SwiftModal from "../CustomComponents/SwiftModal/SwiftModal";

function FundsManagement() {
  const [openFundsPopup, setOpenFundsPopup] = useState(false);
  const [historyFlag, setHistoryFlag] = useState(0);

  const DEVICE_SIZE = getDeviceSize();

  return (
    <div className="swift-funds-management swift-content">
      <div className="strategy-content">
        <div className="funds-management">
          {DEVICE_SIZE !== "S" && (
            <>
              <FundsRequest setHistoryFlag={setHistoryFlag} />
            </>
          )}
          <FundsHistory
            historyFlag={historyFlag}
            setOpenFundsPopup={setOpenFundsPopup}
          />

          {DEVICE_SIZE === "S" && openFundsPopup && (
            <>
              <div className="funds-request-popup">
                <FundsRequest
                  setHistoryFlag={setHistoryFlag}
                  close={() => {
                    setOpenFundsPopup(false);
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default FundsManagement;
