import React from 'react';
import CustomIconButton from '../Body/CustomChartComponents/CustomIconButton/CustomIconButton';
import { PlayCircle, TickCircle } from '../CustomComponents/SwiftIcon/Icons';
import { Alert } from '../Body/CustomChartComponents/CustomAlert/CustomAlert';
import { priceFormat } from '../../exports/UtilFunctions';

function PortfolioTradeValue({ data, stocksBuyValue, stocksSellValue,brokerageValue, cashPosVirtual, placeOrder, executeOrder, execute }) {

    const post_trade_cash = parseFloat(cashPosVirtual) + parseFloat(stocksSellValue) - parseFloat(stocksBuyValue) - parseFloat(brokerageValue);

    function PlacePortfolioOrder() {
        if (stocksBuyValue !== 0 && ((cashPosVirtual - stocksBuyValue - brokerageValue) < 0)) {
            Alert({
                TitleText: 'Warning',
                Message: `You don't have sufficient cash margin to proceed with the order!`,
                Band: true,
                BandColor: '#470985',
                BoxColor: '#ffffff',
                TextColor: '#000000',
                AutoClose: {
                    Active: true,
                    Line: true,
                    LineColor: '#470985',
                    Time: 5
                }
            });
        }
        else {
            executeOrder();
        }
    }

    if (data.length === 0) { return <></> }

    return (
        <div className='trade__value__section'>
            <div className='trade__value'>
                <p>Total Buy Value:&nbsp;&nbsp;<span style={{ display: 'inline' }} className='positive'>{priceFormat(stocksBuyValue)}</span></p>
                <p>Total Sell Value:&nbsp;&nbsp;<span style={{ display: 'inline' }} className='negative'>{priceFormat(stocksSellValue)}</span></p>
                <p>Approx. Brokerage:&nbsp;&nbsp;<span style={{ display: 'inline' }} >{priceFormat(brokerageValue)}</span></p>
                <p>Cash Position Post Trade:&nbsp;&nbsp;<span style={{ display: 'inline' }} >{priceFormat(post_trade_cash)}</span></p>
            </div>
            <div className='trade__button'>
                {execute ?
                    <>
                        <CustomIconButton
                            disabled={stocksBuyValue === 0 && stocksSellValue === 0}
                            onClick={placeOrder}
                            icon={<TickCircle size={20} />}
                            title="Confirm Routing"
                        />
                    </> :
                    <>
                        <CustomIconButton
                            disabled={stocksBuyValue === 0 && stocksSellValue === 0}
                            onClick={PlacePortfolioOrder}
                            icon={<PlayCircle size={20} />}
                            title="Route Order"
                        />
                    </>
                }
            </div>
        </div>
    )
}

export default PortfolioTradeValue