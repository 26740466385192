import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { GetCookie } from "../../exports/CookieManagement";
import ServerRequest from "../../exports/ServerRequest";

function Auth({ children }) {
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const token = GetCookie("token");
    const advisor = GetCookie("advisor");

    async function CheckToken() {
      const data = await ServerRequest({
        method: "GET",
        url: `/checktoken?token=${token}&advisor=${advisor}`,
      });
      return data;
    }

    if (!token) {
      setLoading(false);
      setRedirect(true);
      return;
    }

    CheckToken()
      .then((data) => {
        if (!data.error) {
          setLoading(false);
        } else {
          throw new Error("Invalid Credentials");
        }
      })
      .catch((error) => {
        setLoading(false);
        setRedirect(true);
      });
  }, []);

  if (loading) {
    return null;
  }
  if (redirect) {
    return <Navigate to="/404" />;
  }
  return children;
}

export default Auth;
