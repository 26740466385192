import { useEffect, useState } from "react";
import Axios from "axios";
import { GetCookie } from "../exports/CookieManagement";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const useCustomerCash = () => {
  const account_token = GetCookie("token");

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);

  useEffect(() => {
    GetCashPosition();
  }, []);

  function GetCashPosition() {
    setLoading(true);

    Axios.get(
      `${REQUEST_BASE_URL}/clientinfo/cashposition?token=${account_token}`
    )
      .then((response) => {
        const data = response.data;
        if (data.error) {
          setData(false);
        } else {
          setData(data);
        }
      })
      .catch((error) => {
        setData(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return { loading, cash_data: data };
};

export default useCustomerCash;
