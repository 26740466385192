import React, { useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { GetCookie } from "../../exports/CookieManagement";
import { GetRoleType } from "../../exports/RoleTypes";
import { ICON_COLORS, More } from "../CustomComponents/SwiftIcon/Icons";
import LOGO from "../../assets/images/logo.png";
import { getDeviceSize } from "../../exports/InteractiveUtils";

const DEVICE_SIZE = getDeviceSize();

function Menu({ menu_array }) {
  const location = useLocation();
  const { pathname = "" } = location;

  const [menuOpen, setMenuOpen] = useState(false);

  const user_role = GetRoleType(GetCookie("role"));

  return (
    <div
      className={"swift-menu " + (menuOpen ? "active" : "")}
      onMouseEnter={() => {
        setMenuOpen(true);
      }}
      onMouseLeave={() => {
        setMenuOpen(false);
      }}
    >
      <div className="swift-menu-icon swift-menu-logo">
        <div className="menu-icon">
          <img src={LOGO} alt="" />
        </div>
        <p className="menu-text">
          <i>swift</i>folios
        </p>
      </div>

      <div className="swift-menu-options">
        {menu_array.map((m) => {
          const { title, options } = m;

          return Array.isArray(options) && options.length > 0 ? (
            <MenuSection
              key={title}
              menuOpen={menuOpen}
              user_role={user_role}
              pathname={pathname}
              title={title}
              options={options}
            />
          ) : (
            <></>
          );
        })}
      </div>

      <MenuMobile
        pathname={pathname}
        user_role={user_role}
        menu_array={menu_array}
      />
    </div>
  );
}

function MenuSection({ menuOpen, user_role, pathname, title, options }) {
  return (
    <div className="swift-menu-section" key={title}>
      <p className="menu-section-title">{title}</p>
      <div className="menu-section-options">
        {options.map((o, index) => {
          return (
            <MenuOption
              key={o.title}
              menuOpen={menuOpen}
              menu={o}
              pathname={pathname}
              user_role={user_role}
            />
          );
        })}
      </div>
      <div className="menu-section-line"></div>
    </div>
  );
}

function MenuOption({ menuOpen, menu, pathname, user_role }) {
  const { title, path, customer_title } = menu;
  return (
    <div
      className={
        "swift-menu-icon " +
        ((pathname.includes(`/admin/${path}`) ||
          pathname.includes(`/account/${path}`)) &&
        menuOpen
          ? "active"
          : "")
      }
    >
      <NavLink className="menu-link" activeclassname="menu-active" to={path}>
        <div className="menu-icon">
          <menu.icon
            size={DEVICE_SIZE === 'S' ? 28 : 22}
            color={
              pathname.includes(`/admin/${path}`) ||
              pathname.includes(`/account/${path}`)
                ? ICON_COLORS["SWIFT_COLOR4"]
                : ICON_COLORS["SWIFT_COLOR5"]
            }
          />
        </div>
        <p className="menu-text">
          {user_role === "CUSTOMER" ? customer_title || title : title}
        </p>
      </NavLink>
    </div>
  );
}

function MenuMobile({ pathname, user_role, menu_array }) {
  const menu_options = useMemo(() => {
    const account_options = menu_array.find((m) => {
      return m["title"] === "ACCOUNT";
    });

    if (account_options) {
      return account_options["options"].filter((o) => {
        return o["is_mobile"] === true;
      });
    }
  }, [menu_array]);

  if (!menu_options) {
    return <></>;
  }

  return (
    <div className="swift-menu-options-mobile">
      {menu_options.map((o) => {
        return (
          <MenuOption
            key={o.title}
            menuOpen={true}
            menu={o}
            pathname={pathname}
            user_role={user_role}
          />
        );
      })}
      <MenuOption
        key={"more"}
        menuOpen={true}
        menu={{
          title: "More",
          path: "more",
          customer_title: "More",
          icon: More,
        }}
        pathname={pathname}
        user_role={user_role}
      />
      {/* <div className="swift-menu-icon">
        <a>
          <div className="menu-icon">
            <More size={20} color={ICON_COLORS["SWIFT_COLOR4"]} />
          </div>
          <p className="menu-text">More</p>
        </a>
      </div> */}
    </div>
  );
}

export default Menu;
