import React, { useEffect, useState } from "react";
import Pulse from "./Loader/Pulse";
import Account from "./Account/Account";
import { GetCookie } from "../exports/CookieManagement";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

let INACTIVITY_TIMER;
let INACTIVITY_THRESHOLD = 3 * 60 * 1000; // 3 minutes

function MainPage() {
  const account_token = GetCookie("token");
  const [accountInfo, setAccountInfo] = useState(undefined);

  useEffect(() => {

    async function GetAccountInfo() {
      const response = await fetch(
        `${REQUEST_BASE_URL}/getuserinfo?token=${account_token}`
      );
      const data = await response.json();
      setAccountInfo(data);
    }

    GetAccountInfo();
  }, [account_token]);

  useEffect(()=>{
    document.addEventListener("visibilitychange", HandleVisibilityChange);
  },[]);

  function StartInactivityTimer() {
    ResetInactivityTimer();
    document.addEventListener("mousemove", ResetInactivityTimer);
    document.addEventListener("keypress", ResetInactivityTimer);
  }

  function ResetInactivityTimer() {
    clearTimeout(INACTIVITY_TIMER);
    INACTIVITY_TIMER = setTimeout(RefreshPage,INACTIVITY_THRESHOLD);
  }

  function RefreshPage() {
    if (document.hidden) {
      window.location.reload();
    }
  }

  function HandleVisibilityChange() {
    if (!document.hidden) {
      clearTimeout(INACTIVITY_TIMER);
    } else {
      StartInactivityTimer();
    }
  }

  if (accountInfo === undefined) {
    return (
      <div className="app__onboard">
        <Pulse />
      </div>
    );
  }

  return (
    <>
      <div className="app__back__blur"></div>
      <Account account_info={accountInfo} />
    </>
  );
}

export default MainPage;
