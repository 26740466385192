import { GetStockConfig } from "./GetStockConfig";

export const GetBulkStockConfig = async (symbol_array) => {

    return new Promise(async (resolve, reject) => {

        if (symbol_array.length === 0) resolve({})

        let config = {};

        const promises = symbol_array.map(async (s) => {
            const symbol_config = await GetStockConfig(s);
            config = { ...config, [s]: symbol_config };
        });

        await Promise.all(promises);

        resolve(config);

    });
}
