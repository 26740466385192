import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import HeroIntro from "./HeroComponents/HeroIntro";
import NotFound from "./AccountComponents/NotFound";
import "../css/HeroPage.css";

const WORKING_ENVIRONMENT = process.env.REACT_APP_ENV || "PRODUCTION";

function HeroPage() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const current_path = location.pathname;

  useEffect(() => {
    // console.log(WORKING_ENVIRONMENT,current_path);
    if (WORKING_ENVIRONMENT === "PRODUCTION" && current_path === "/") {
      window.location.href = "https://www.swiftfolios.com";
    } else {
      setLoading(false);
    }
  }, [current_path]);

  if (loading) {
    return <></>
  }

  return (
    <div className="app__hero__page">
      <div className="app__hero__body">
        <Routes>
          {WORKING_ENVIRONMENT === "DEVELOPMENT" && (
            <>
              <Route path="/" exact element={<HeroIntro />} />
            </>
          )}
          <Route path="/404" element={<NotFound />} />
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
}
export default HeroPage;
