import React, { useState } from "react";
import ReportDashboard from "./ReportDashboard";
import "../../css/ReportsManagement/ReportsManagement.css";
import { ArrowDown2, ArrowUp2 } from "../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../exports/ColorVars";
import { getDeviceSize } from "../../exports/InteractiveUtils";

const MENU_ITEMS = ["Generate Report", "Report Schedule", "Report History"];

function ReportManagement() {
  const [menu, setMenu] = useState(MENU_ITEMS[0]);
  const [menuPopup, setMenuPopup] = useState(false);

  const DEVICE_SIZE = getDeviceSize();

  return (
    <div className="swift-reports-management swift-content">
      {DEVICE_SIZE === "S" && (
        <>
          <div className="swift-submenu-wrapper">
            <ReportsMenu
              menu={menu}
              setMenu={setMenu}
              menuPopup={menuPopup}
              setMenuPopup={setMenuPopup}
            />
          </div>
        </>
      )}
      <div className="strategy-content">
        <ReportDashboard menu={menu} />
      </div>
    </div>
  );
}

function ReportsMenu({ menu, setMenu, menuPopup, setMenuPopup }) {
  function MenuItemClick(menu_item) {
    setMenu(menu_item);
    setMenuPopup(false);
  }
  return (
    <>
      <div
        className="swift-submenu-item"
        onClick={() => {
          setMenuPopup(!menuPopup);
        }}
      >
        <p>{menu}</p>
        {menuPopup ? (
          <>
            <ArrowUp2 size={18} color={COLOR_VARS["SWIFT_COLOR1"]} />
          </>
        ) : (
          <>
            <ArrowDown2 size={18} color={COLOR_VARS["SWIFT_COLOR1"]} />
          </>
        )}
      </div>
      <div className={"swift-submenu " + (menuPopup ? "active" : "")}>
        {MENU_ITEMS.map((m, index) => {
          return (
            <p
              key={index}
              onClick={() => {
                MenuItemClick(m);
              }}
              className={menu === m ? "active" : ""}
            >
              {m}
            </p>
          );
        })}
      </div>
    </>
  );
}

export default ReportManagement;
