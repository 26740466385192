import React from 'react';
import SmallcaseStrategy from './SmallcaseStrategy';
import MakeSmallcase from './MakeSmallcase';
import '../../css/Smallcase/AdvisorSmallcase.css';
import EditPortfolios from './EditPortfolios';

export class Smallcase extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            component: 'home',
            pannumber: 'ALQPD7054E',
            smallcase_id: null,
            smallcase_name: null,
        }

        this.newsmallcase = this.newsmallcase.bind(this);
        this.editPortfolio = this.editPortfolio.bind(this);
        this.editStrategy = this.editStrategy.bind(this);
    }

    newsmallcase() {
        this.setState({
            smallcase_name: null,
            smallcase_id: null,
            component: 'smallcase'
        });
    }

    editPortfolio(smallcase_id, name) {
        this.setState({
            smallcase_name: name,
            smallcase_id: smallcase_id,
            component: 'editportfolio'
        })
    }

    editStrategy(smallcase_id, name) {
        this.setState({
            smallcase_name: name,
            smallcase_id: smallcase_id,
            component: 'smallcase'
        })
    }

    render() {

        const { component, pannumber, smallcase_id, smallcase_name } = this.state;

        return (
            <div className='advisor__smallcase'>
                <div className='advisor__smallcase__header'>
                    <p className='advisor__smallcase__title'>Smallcase Strategy</p>
                    {component == 'home' &&
                        <>
                            <div className='advisor__smallcase__buttons'>
                            <div className='advisor__smallcase__button' onClick={() => { window.history.go(-1); }}>Advisor Dashboard</div>
                                <div className='advisor__smallcase__button' onClick={this.newsmallcase}>New Smallcase Strategy</div>
                            </div>
                        </>
                    }
                </div>
                <div className='advisor__smallcase__container'>

                    {component == 'home' &&
                        <SmallcaseStrategy
                            pannumber={pannumber}
                            newsmallcase={this.newsmallcase}
                            editPortfolio={this.editPortfolio}
                            editStrategy={this.editStrategy}
                        />}
                    {component == 'smallcase' &&
                        <MakeSmallcase
                            smallcase_id={smallcase_id}
                            smallCaseHome={() => this.setState({ component: "home" })}
                            pannumber={pannumber}
                            editPortfolio={this.editPortfolio} />}
                    {component == 'editportfolio' && 
                        <EditPortfolios
                            smallCaseHome={() => this.setState({ component: "home" })}
                            backButton={() => this.setState({ component: "smallcase" })}
                            smallcase_id={smallcase_id}
                        smallcase_name={smallcase_name}
                            pannumber={pannumber} />
                    }

                </div>
                <div className='advisor__smallcase__footer'>

                </div>

            </div>
        )
    }
}

export default Smallcase;
