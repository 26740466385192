import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";
import { SetCookie } from "../../exports/CookieManagement";
import { ArrowRight2 } from "../CustomComponents/SwiftIcon/Icons";
import Pulse from "../Loader/Pulse";
import AccessHeader from "./AccessHeader";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const warning_config = {
  Band: true,
  BandColor: "#470985",
  BoxColor: "#ffffff",
  TextColor: "#000000",
  AutoClose: {
    Active: true,
    Line: true,
    LineColor: "#470985",
    Time: 3,
  },
};

function AccessCustomerToken({ customer_token,setLogin }) {
  const [step, setStep] = useState(1);
  const [otp, setOTP] = useState("");
  const [timer, setTimer] = useState(0);
  const [otpError, setOTPError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const counter = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
    return () => clearInterval(counter);
  }, [timer]);

  async function resendOTP() {
    if (timer > 0) {
      return;
    }

    setOTPError("");
    setOTP("");

    await sendOTP();
    setTimer(30);
  }

  async function sendOTP() {
    return new Promise((resolve, reject) => {
      Axios.post(`${REQUEST_BASE_URL}/access/sendotp/token`, {
        token: customer_token,
      })
        .then((response) => {
          const data = response.data;
          resolve(data);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }

  async function validateAccount() {
    setLoading(true);
    setLoadingMessage("Sending one time password...");

    const data = await sendOTP();

    if (!data || data.error) {
      Alert({
        TitleText: "Warning",
        Message: "Something went wrong!",
        ...warning_config,
      });
      setLoading(false);
      setLoadingMessage("");
      return;
    }

    setTimeout(() => {
      setLoading(false);
      setLoadingMessage("");
      setStep(2);
    }, 2000);
  }

  function resetPassword() {
    navigate("/access/reset");
  }

  function validateOTP() {
    if (otp === "") {
      setOTPError("Please enter the correct otp");
    } else {
      setOTPError("");
      setLoading(true);
      setLoadingMessage("Validating your OTP...");

      Axios.post(`${REQUEST_BASE_URL}/access/validateotp/token`, {
        token: customer_token,
        otp: otp,
      })
        .then((response) => {
          const data = response.data;
          if (!data.error) {
            const token = data.token;
            setTimeout(() => {
              setOTPError("");
              SetCookie("token", token);
              setLogin(true);
              setLoading(false);
              setLoadingMessage("");
            }, 2000);
          } else {
            setOTPError("Entered otp is invalid");
            setLoading(false);
            setLoadingMessage("");
          }
        })
        .catch((error) => {
          setOTPError("Something went wrong!");
          setLoading(false);
          setLoadingMessage("");
        });
    }
  }

  function otpKeyUp(e) {
    if (e.key === "Enter") {
      validateOTP();
    }
  }

  return (
    <div className="user-login">
      <div className="user-login-popup-wrapper">
        {loading ? (
          <>
            <div className="popup-loader">
              <Pulse />
              <p>{loadingMessage}</p>
            </div>
          </>
        ) : (
          <>
            <div className="login-header">
              <AccessHeader header_text="Order Confirmation Login" />
            </div>
            <>
              {step == 1 && (
                <>
                  <div className="login-content">
                    <div className="login-body">
                      <div className="login-notes">
                        <p className="login-note">
                          In order to approve the trades, kindly click on
                          Generate OTP button and login to your account.
                        </p>
                        <p className="login-note">
                          You will be able to verify and approve the orders once
                          you login.
                        </p>
                      </div>
                    </div>
                    <>
                      <div className="login-footer">
                        <div
                          className="swift-button-icon"
                          onClick={validateAccount}
                        >
                          <div className="button-icon">
                            <ArrowRight2 size={20} />
                          </div>
                          <p>Generate OTP</p>
                        </div>
                      </div>
                    </>
                  </div>
                  <div className="footer-line">
                    <p>
                      <Link to="/access/reset">
                        Forgot Customer ID or Password?
                      </Link>
                    </p>
                    <p>
                      Don't have an account?{" "}
                      <Link to="/access/signup">Signup</Link>
                    </p>
                  </div>
                </>
              )}
              {step == 2 && (
                <>
                  <div className="login-content">
                    <div className="login-body">
                      <div className="swift-input-box">
                        <p>Verification OTP</p>

                        <input
                          className="swift-input"
                          value={otp}
                          placeholder="123456"
                          onKeyUp={otpKeyUp}
                          maxLength={6}
                          onChange={(e) => {
                            setOTP(e.target.value.replace(/[^\d]/g, ""));
                          }}
                        />
                        <p
                          className={
                            otpError === "" ? "error-text hide" : "error-text"
                          }
                        >
                          {otpError}
                        </p>
                      </div>
                      {timer === 0 ? (
                        <>
                          <p className="resend-otp-button" onClick={resendOTP}>
                            Resend OTP
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="resend-message">
                            Please wait {timer} seconds to resend
                          </p>
                        </>
                      )}
                    </div>
                    <div className="login-footer">
                      <div className="swift-button-icon" onClick={validateOTP}>
                        <div className="button-icon">
                          <ArrowRight2 size={20} />
                        </div>
                        <p>Submit</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          </>
        )}
      </div>
    </div>
  );
}

export default AccessCustomerToken;
