import React, { useEffect, useState } from "react";
import StockTable from "./StockTable";


const caseCommon = {
  "Cash from Operating Activity": false,
  "Cash from Investing Activity": false,
  "Cash from Financing Activity": false,
  "Net Cash Flow": false,
};

const StockCash = ({ snapShot }) => {
  const [data, setData] = useState([]);
  const [header, setHeader] = useState([]);

  useEffect(() => {
    if (!snapShot) return;
    filterData();
  }, [snapShot]);

  const filterData = () => {
    const display_data = { ...snapShot["cash_flow"] };
    let headers = [];
    let data = [];
    let filteredData = [];

    headers = display_data.fields.slice(2);

    function convertNumber(text){
      const num = parseFloat(text.replace(/,/g, ""));
      if(isNaN(num)){
        return text;
      }
      return num;
    }

    filteredData = display_data.values
      .map((row) => {
        const filteredRow = row.map((value) => value.replace(/\+/g, ""));

        return filteredRow.filter((value, index) => {
          if (index === 0) {
            return false;
          }
          const fieldName = row[1].replace(/\s*\+$/, "");
          let res;
          res = caseCommon.hasOwnProperty(fieldName);
          return res;
        });
      })
      .filter((row) => row.length > 0)
      .map((row) => {
        const fieldName = row[0].trim();
        // console.log(fieldName);

        let isBold;

        isBold = caseCommon[fieldName];

        return { data: row, isBold };
      })
      .map((row) => {
        // Reverse each row from the second item onwards
        const reversedRow = [
          ...row.data.slice(0, 1),
          ...row.data.slice(1),
        ];

        // Replace empty or null values with "--"
        const updatedRow = reversedRow.map((value) =>
          value === "" || value === null ? "--" : convertNumber(value)
        );

        return { data: updatedRow, isBold: row.isBold };
      });

    // console.log(headers, filteredData);
    setData(filteredData);
    setHeader(headers);
  };

  return (
    <div className="swift-stock-management-case" id="caseflow">
      <div className="swift-stock-management-case-header">Cash Flow</div>
      {data.length > 0 && header.length > 0 ? (
        <StockTable data={data} header={header} />
      ) : (
        <>No Data</>
      )}
    </div>
  );
};

export default StockCash;
