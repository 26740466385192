import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import '../../css/KYCManagement/KYCManagement.css';
import KYCCustomerInfo from './KYCCustomerInfo';
import KYCForm from './KYCForm';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function KYCManagement() {

    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get("token")

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`${REQUEST_BASE_URL}/kyc/customer?token=${token}`).then((response) => {
            const data = response.data;
            console.log(data)
            if (!data.error) {
                setData(data.data);
                setLoading(false);
            }
        })
    }, []);

    if (!token) {
        return <Navigate to="/404" />;
    }

    if (loading) {
        return <p>Loading</p>
    }

    if (!data) {
        return <p>Loading</p>
    }

    return (
        <div className='swift-kyc-management'>
            <div className='client-header'>
                <p className='client-title'>Know Your Customer</p>
            </div>
            <div className="client-content">
                <KYCCustomerInfo data={data} />
                <KYCForm data={data} />
            </div>
        </div>
    )
}

export default KYCManagement