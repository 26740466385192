export const SWIFTALGO_TIMEWINDOW = {
  '5 seconds': 5, '10 seconds': 10, '1 Minute': 60, '3 Minutes': 180
};

// export const SWIFTALGO_TIMEWINDOW = {
//   '1 Minute': 60, '3 Minutes': 180
// };

export const SWIFTALGO_TIMEWINDOW_OPTIONS = Object.entries(SWIFTALGO_TIMEWINDOW).map(([key,value])=>{return key});
export const SWIFTALGO_TIMEWINDOW_REVERSE = Object.entries(SWIFTALGO_TIMEWINDOW).reduce((acc, [key, value]) => (acc[value] = key, acc), {});

export const SWIFTALGO_DATAPOINTS = [3,5,10];
