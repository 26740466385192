import React from "react";
import { ArrowRight2 } from "../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../exports/ColorVars";

const StockFeed = ({ newsData, openFeedModal, isVisible }) => {
  return (
    <div className="swift-stock-management-case swift-stock-management-feed" style={{ display: !isVisible ? "none" : "flex" }}>
      <p className="swift-stock-management-case-header">Feed</p>
      <div className="swift-stock-management-detail-row-2-right-detail">
        {newsData &&
          newsData.length > 0 &&
          newsData.slice(0, 4).map((item, index) => (
            <div
              key={index}
              className="swift-stock-management-detail-row-2-right-detail-rows"
            >
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="swift-stock-management-detail-row-2-right-news"
              >
                <p>{item.news}</p>
              </a>
              <p className="swift-stock-management-detail-row-2-right-date">
                {item.source} {"- "} {item.date}
              </p>
            </div>
          ))}
        {(!newsData || newsData.length == 0) && <>No Feed</>}
      </div>
      <div
        className="swift-stock-management-detail-right-btn right"
        style={{
          visibility: !newsData || newsData.length <= 3 ? "hidden" : "visible",
        }}
        onClick={openFeedModal}
      >
        <button>
          More
          <ArrowRight2 size={16} color={COLOR_VARS["SWIFT_COLOR1"]} />
        </button>
      </div>
    </div>
  );
};

export default StockFeed;
