import React, { useEffect, useState } from "react";
import Axios from "axios";
import Pulse from "../../../Loader/Pulse";
import AddBackTestStock from "./AddBackTestStock";
import { GetCookie } from "../../../../exports/CookieManagement";
import CustomButton from "../../CustomChartComponents/CustomButton/CustomButton";
import CustomInput from "../../CustomChartComponents/CustomInput/CustomInput";
import CustomTextArea from "../../CustomChartComponents/CustomTextArea/CustomTextArea";


const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;


function SmallCaseStrategy({ strategy_info, smallCaseHome }) {
  const [active, setActive] = useState("");
  const [checking, setChecking] = useState(false);

  const [strategyDetails, setStrategyDetails] = useState({
    isStrategyExist: false,
    smallcase_id: false,
    createdDate: false,
    strategy: "",
    objective: "",
    methodology: "",
    benchmark: "",
    frequency: "",
    price: "",
    portfolios: [],
    backTestResult: [],
  });

  useEffect(() => {
    if (!strategy_info) return;
    const { smallcase_id, name } = strategy_info;

    GetStrategy(smallcase_id, name);
  }, [strategy_info]);

  async function GetStrategy(smallcase_id, name) {
    setChecking(true);

    const access_token = GetCookie("token");

    const payload = {
      strategy: name,
      smallcase_id: smallcase_id,
    };

    Axios.post(
      `${REQUEST_BASE_URL}/check_strategy?token=${access_token}`,
      payload
    )
      .then((response) => {
        const data = response.data;
        const { portfolios, backTestResult, details } = data;
        if (details) {
          const {
            smallcase_id,
            createdDate,
            strategy,
            objective,
            methodology,
            benchmark,
            frequency,
            price,
          } = details;
          setStrategyDetails({
            isStrategyExist: true,
            smallcase_id: smallcase_id,
            createdDate: createdDate,
            strategy: strategy,
            objective: objective,
            methodology: methodology,
            benchmark: benchmark,
            frequency: frequency,
            price: price,
            portfolios: portfolios,
            backTestResult: backTestResult,
          });
        } else {
          setStrategyDetails({
            isStrategyExist: false,
            createdDate:
              new Date().getFullYear() +
              "-" +
              ("0" + (new Date().getMonth() + 1)).slice(-2) +
              "-" +
              ("0" + new Date().getDate()).slice(-2),
            objective: "",
            smallcase_id: null,
            methodology: "",
            benchmark: "",
            frequency: "",
            price: "",
            portfolios: [],
            backTestResult: [],
          });
        }

        setChecking(false);
      })
      .finally(() => {
        setActive("strategy");
      });
  }



  function StrategyUpdate() {
    setActive("success");
  }

  const {
    smallcase_id,
    strategy,
    objective,
    methodology,
    portfolios,
    createdDate,
  } = strategyDetails;

  return (
    <div className="smallcase__strategy__container">
      <div className="smallcase__strategy__body__wrapper">
        {checking ? (
          <div className="smallcase__strategy__body loader">
            <Pulse />
            <p className="loading__text">Fetching details...</p>
          </div>
        ) : (
          <>
            {active === "success" ? (
              <>
                <div className="smallcase__success__page">
                  <div>
                    <svg
                      className="checkmark"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 52 52"
                    >
                      <circle
                        className="checkmark__circle"
                        cx="26"
                        cy="26"
                        r="25"
                        fill="none"
                      />
                      <path
                        className="checkmark__check"
                        fill="none"
                        d="M14.1 27.2l7.1 7.2 16.7-16.8"
                      />
                    </svg>
                  </div>
                  <span className="success__message">
                    Strategy Updated Successfully
                  </span>
                  <CustomButton title="View Strategy" onClick={smallCaseHome} />
                </div>
              </>
            ) : (
              <>
                <div className="smallcase__strategy__body">
                  {/* <p className="strategy__title">Create/Edit Strategy</p> */}
                  <div className="smallcase__strategy__form">
                    <CustomInput
                      placeholder="Enter name of the strategy"
                      heading="Strategy Name"
                      value={strategy}
                      onChange={(val) => {
                        setStrategyDetails({
                          ...strategyDetails,
                          strategy: val,
                        });
                      }}
                    />
                    <CustomTextArea
                      placeholder="Enter objective of the strategy"
                      heading="Strategy Objective"
                      value={objective}
                      onChange={(val) => {
                        setStrategyDetails({
                          ...strategyDetails,
                          objective: val,
                        });
                      }}
                      maxLength="200"
                    />
                    <CustomTextArea
                      placeholder="Enter methodology of the strategy"
                      heading="Strategy Methodology"
                      value={methodology}
                      onChange={(val) => {
                        setStrategyDetails({
                          ...strategyDetails,
                          methodology: val,
                        });
                      }}
                      maxLength="200"
                    />
                  </div>
                  <div className="smallcase__strategy__right">
                    <AddBackTestStock
                      smallcase_id={smallcase_id}
                      strategyDetails={strategyDetails}
                      state={{ portfolios, createdDate }}
                      backButton={() => {
                        setActive(null);
                      }}
                      updateStrategy={StrategyUpdate}
                      smallCaseHome={smallCaseHome}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default SmallCaseStrategy;
