import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import MoreManagement from "./MoreManagement";

function Wrapper({ is_visible, menu_array, ...props }) {
  const [menu, setMenu] = useState(menu_array);

  useEffect(() => {
    setMenu([]);
    setTimeout(() => {
      setMenu(menu_array);
    }, 500);
  }, [menu_array]);

  return (
    <div className={"swift-content-wrapper " + (is_visible ? "active" : "")}>
      {!Array.isArray(menu) || menu.length === 0 ? (
        <></>
      ) : (
        <>
          <div className="swift-content-data-section">
            <Routes>
              <Route path="*" element={<Navigate to="/account/dashboard" />} />

              {menu_array.map((m, index) => {
                return (
                  <Route
                    key={index}
                    path={`/${m.path}/*`}
                    element={<m.component {...props} />}
                  />
                );
              })}

              <Route
                key={'more'}
                path={`/more/*`}
                element={<MoreManagement {...props} />}
              />
            </Routes>
          </div>
        </>
      )}
    </div>
  );
}

export default Wrapper;
