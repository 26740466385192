
 
// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('backofficetoken') || null;
}

export const getMobile = () => {
  return sessionStorage.getItem('mobile') || null;
}
 
// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('backofficetoken');
  sessionStorage.removeItem('mobile');
}
 
// set the token and user from the session storage
export const setUserSession = (mobile,backofficetoken) => {
  sessionStorage.setItem('backofficetoken', backofficetoken);
  sessionStorage.setItem('mobile',mobile);
}