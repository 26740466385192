import React, { useState } from 'react';
import Axios from 'axios';
import { Cross } from '../CustomComponents/SwiftIcon/Icons';
import CustomInput from '../Body/CustomChartComponents/CustomInput/CustomInput';
import CustomButton from '../Body/CustomChartComponents/CustomButton/CustomButton';
import Pulse from '../Loader/Pulse';
import { Alert } from '../Body/CustomChartComponents/CustomAlert/CustomAlert';
import { GetCookie } from '../../exports/CookieManagement';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const error_config = {
    Band: true,
    BandColor: '#470985',
    BoxColor: '#ffffff',
    TextColor: '#000000',
    AutoClose: {
        Active: true,
        Line: true,
        LineColor: '#470985',
        Time: 3
    }
}

function RenameFamilyPortfolio({ portfolio, close = () => { }, update = () => { } }) {

    const { family_portfolio_id } = portfolio;

    const [loading, setLoading] = useState(false);
    const [portfolio_name, setPortfolioName] = useState('');
    const [error, setError] = useState(false);

    function RenamePortfolio() {

        setLoading(true);
        setTimeout(() => {
            if (portfolio_name === '') {
                setError('Please enter portfolio name');
            }
            else {

                const account_token = GetCookie('token');
                Axios.post(`${REQUEST_BASE_URL}/family/renameportfolio?token=${account_token}`, { portfolio_id: family_portfolio_id, portfolio_name: portfolio_name }).then((response) => {
                    const data = response.data;
                    if (!data.error) {
                        setError('');
                        update();
                        close();
                        Alert({
                            TitleText: 'Family Portfolio Renamed',
                            Message: 'Portfolio renamed successfully',
                            Band: true,
                            BandColor: '#67D098',
                            BoxColor: '#ffffff',
                            TextColor: '#000000',
                            AutoClose: {
                                Active: true,
                                Line: true,
                                LineColor: '#67D098',
                                Time: 3
                            }
                        });
                    }
                    else {
                        setError('');
                        if (data.exists) {
                            Alert({
                                TitleText: 'Warning',
                                Message: 'Portfolio already exists in your account',
                                ...error_config
                            })
                        }
                        else {
                            Alert({
                                TitleText: 'Warning',
                                Message: 'Something went wrong while renaming portfolio',
                                ...error_config
                            })
                        }
                    }
                }).catch((err) => {
                    setError('');
                    Alert({
                        TitleText: 'Warning',
                        Message: 'Something went wrong while renaming portfolio',
                        ...error_config
                    })
                })
            }

            setLoading(false);

        }, 500);
    }

    return (
        <div className='family-rename-popup'>
            {loading && <>
                <div className='family-rename-popup-loader'>
                    <Pulse />
                </div>
            </>}
            <div className='family-rename-header'>
                <p className='family-rename-title'>Rename Family Portfolio</p>
                <div className='family-rename-close' onClick={close}>
                    <Cross color="#192b40" size={26} />
                </div>
            </div>
            <div className='family-rename-body'>
                <CustomInput
                    heading="Family Portfolio Name"
                    value={portfolio_name}
                    placeholder=""
                    error={error}
                    onChange={(val) => { setPortfolioName(val) }}
                />
            </div>
            <div className='family-rename-footer'>
                <CustomButton title="Rename Portfolio" onClick={RenamePortfolio} />
            </div>
        </div>
    )
}

export default RenameFamilyPortfolio;