import React from "react";
import LOGO from "../../assets/images/logo.png";
import { Link } from "react-router-dom";

function AccessHeader({header_text}) {
  return (
    <>
      {/* add home page url */}
      <img src={LOGO} alt="Swiftfolio Logo" />
      <h2>
        <Link to="/">
          <i>swift</i>folios
        </Link>
      </h2>
      <span>{header_text}</span>
    </>
  );
}

export default AccessHeader;
