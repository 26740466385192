import React from "react";
import FamilyStocksTableRow from "./FamilyStocksTableRow";
import { getDeviceSize } from "../../exports/InteractiveUtils";

const DEVICE_SIZE = getDeviceSize();

function FamilyStocksTable({ portfolio, SetIndividualPrice }) {
  return (
    <div className="family-stocks-table">
      {!portfolio || portfolio.length === 0 ? (
        <></>
      ) : (
        <>
          {DEVICE_SIZE === "S" ? (
            <>
              {portfolio.map((p, index) => {
                return (
                  <FamilyStocksTableRow
                    key={index}
                    index={index}
                    stock={p}
                    SetIndividualPrice={SetIndividualPrice}
                  />
                );
              })}
            </>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <td className="name">Company Name</td>
                    <td className="quantity">Quantity</td>
                    <td className="weight">Weight</td>
                    <td>Avg. Buy Price</td>
                    <td>Current Price</td>
                    <td>Investment Cost</td>
                    <td>Current Value</td>
                    <td className="returns">Returns</td>
                    <td className="today">Today</td>
                  </tr>
                </thead>
                <tbody>
                  {portfolio.map((p, index) => {
                    return (
                      <FamilyStocksTableRow
                        key={index}
                        index={index}
                        stock={p}
                        SetIndividualPrice={SetIndividualPrice}
                      />
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default FamilyStocksTable;
