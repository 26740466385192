import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import Pulse from "../Loader/Pulse";
import {
  Cross,
  ArchiveBook,
  ArrowLeft,
} from "../CustomComponents/SwiftIcon/Icons";
import { GetCookie } from "../../exports/CookieManagement";
import { COLOR_VARS } from "../../exports/ColorVars";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function Notifications({ close }) {
  const [notBox, setNotBox] = useState(false);

  async function GetNotifications() {
    const account_token = GetCookie("token");

    const response = await fetch(
      `${REQUEST_BASE_URL}/clientinfo/notifications?token=${account_token}`
    );
    const data = await response.json();

    if (data.error) {
      return false;
    }

    return data.notifications;
  }

  const { data: notifications, isLoading } = useQuery(
    ["notifications"],
    GetNotifications
  );

  return (
    <div className="notifications-popup">
      {isLoading && (
        <>
          <div className="notifications-popup-loader">
            <Pulse />
          </div>
        </>
      )}
      <div className="notifications-header">
        <p className="notifications-title">Notifications</p>
        <div className="notifications-close" onClick={close}>
          <Cross color="#192b40" size={26} />
        </div>
      </div>
      <div className="notifications-body">
        {notBox ? (
          <>
            <NotificationBox notification={notBox} setNotBox={setNotBox} />
          </>
        ) : (
          <>
            {!isLoading && (
              <>
                {notifications.length === 0 ? (
                  <>
                    <div className="notifications-empty">
                      <ArchiveBook color={COLOR_VARS['SWIFT_COLOR4']} size={70} />
                      <p>No notifications to display</p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="notifications-list">
                      {notifications.map((n, index) => {
                        return (
                          <NotificationRow
                            key={index}
                            notification={n}
                            setNotBox={setNotBox}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function NotificationRow({ notification, setNotBox }) {
  return (
    <>
      <div
        className="notification-row"
        onClick={() => {
          setNotBox(notification);
        }}
      >
        <span className="subject">{notification.subject}</span>
        <span className="time">
          {moment(notification.time).format("DD-MMM-YYYY HH:mm A")}
        </span>
      </div>
    </>
  );
}

function NotificationBox({ notification, setNotBox }) {
  const { subject, time, body, attachments } = notification;

  return (
    <>
      <div className="notification-box">
        <div
          className="back"
          onClick={() => {
            setNotBox(false);
          }}
        >
          <ArrowLeft size={16} color="#000000" />
          <span>Go Back</span>
        </div>
        <div>
          <p className="subject">{subject}</p>
          <span className="time">
            {moment(time).format("DD MMM YYYY, HH:mm A")}
          </span>
        </div>
        {attachments.length > 0 && (
          <>
            <div className="attachments">
              <p>Attachments</p>
              <div className="attachments-list">
                {attachments.map((a, index) => {
                  return (
                    <a key={index} href={a.link}>
                      <span>{a.name}</span>
                    </a>
                  );
                })}
              </div>
            </div>
          </>
        )}
        <div className="body" dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    </>
  );
}

export default Notifications;
