import React from "react";

const options = ["Stocks", "Futures", "Call", "Put"];

function SearchTabs({ tab, changeActiveTab }) {
  return (
    <div className="search__tabs">
      {options.map((option, index) => {
        return (
          <span
            key={index}
            className={tab === option.toLowerCase() ? "s__tab active" : "s__tab"}
            onClick={() => {changeActiveTab(option.toLowerCase());}}
          >
            {option}
          </span>
        );
      })}
    </div>
  );
}

export default SearchTabs;
