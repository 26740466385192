import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { InfoCircle } from "../SwiftIcon/Icons";
import { COLOR_VARS } from "../../../exports/ColorVars";

function SwiftInfoMessage({
  size = 14,
  color = COLOR_VARS["SWIFT_COLOR4"],
  message = "",
  position = "bottom",
  align = "right",
  width = 100,
}) {
  const ref = useRef(null);
  const [hover, setHover] = useState(false);
  const [layout, setLayout] = useState(false);
  const [dimension, setDimension] = useState({
    top: false,
    left: false,
  });

  useLayoutEffect(() => {
    if (!ref || !ref.current) return;
    setLayout({
      y: ref.current.getBoundingClientRect().top,
      x: ref.current.getBoundingClientRect().left,
    });
  }, []);

  useEffect(() => {
    if (!layout) {
      setDimension({ top: "0%", left: "0%" });
      return;
    }

    const top = position === 'bottom' ?  `${layout.y+25}` : `${layout.y - 45}`;
    let left;

    if(align === 'right'){
      left = layout.x;
    }
    else if(align === 'center'){
      left = (layout.x-(width/2));
    }
    else{
      left = (layout.x-width);
    }
    setDimension({ top: `${top}px`, left: `${left}px` });
  }, [layout, message, position,align, width]);

  const { top = false, left = false } = dimension;

  return (
    <div
      className="swift-info-message"
      ref={ref}
      onMouseOver={() => {
        setHover(true);
      }}
      onMouseOut={()=>{
        setHover(false);
      }}
    >
      {dimension ? (
        <>
          <InfoCircle size={size} color={color} />
          {hover && (
            <>
              <div
                className="swift-info-message-box"
                style={{
                  top,
                  left,
                  width: `${width}px`,
                }}
              >
                <p>{message}</p>
              </div>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default SwiftInfoMessage;
