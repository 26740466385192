import React, { useEffect } from 'react'
import useMarketStock from '../../hooks/useMarketStock';
import { getDeviceSize } from '../../exports/InteractiveUtils';
import { numberFormat } from '../../exports/UtilFunctions';

const DEVICE_SIZE = getDeviceSize();

function FamilyStocksTableRow({ stock, index, SetIndividualPrice }) {

    const { StockCode, StockName, AverageCost, PortfolioWeight, CostValue, LastClose, CurrentValue, Quantity, TotalReturn, CurrentPrice } = stock;

    const stockData = useMarketStock(StockCode);

    const GetColorClass = (num) => {
        if (parseFloat(num) < 0) {
            return 'negative';
        }
        else {
            return 'positive';
        }
    }

    useEffect(() => {
        let last_traded_price = stockData ? stockData.last_traded_price : CurrentPrice;
        SetIndividualPrice(index, last_traded_price);
    }, [stockData]);

    let prev_close = LastClose;
    let last_traded_price = stockData ? stockData.last_traded_price : CurrentPrice;
    let change_percentage = (last_traded_price / prev_close - 1) * 100;
    const original_value = parseFloat(CurrentValue);
    let current_value = Quantity * last_traded_price;

    const change_value = current_value - original_value;
    let current_returns = (current_value / CostValue - 1) * 100;

    if(DEVICE_SIZE === 'S'){
        return <div className='family-stocks-row'>
            <div className='fs-row'>
                <div><span>{StockCode}</span></div>
                <div><span>{numberFormat(current_value)}</span></div>
            </div>
            <div className='fs-row'>
                <div><span>{numberFormat(Quantity,0)}</span><span>@ {numberFormat(AverageCost)}</span></div>
                <div><span>{numberFormat(CostValue)}</span><span className={GetColorClass(TotalReturn)}>{numberFormat(TotalReturn)}%</span></div>
            </div>
            <div className='fs-row'>
                <div><span>Wt.: {PortfolioWeight}%</span></div>
                <div><span>{numberFormat(change_value)}</span><span className={GetColorClass(change_percentage)}>{numberFormat(change_percentage)}%</span></div>
            </div>
        </div>
    }


    return (
        <>
            <tr>
                <td>
                    <div className='stock-name-code'>
                        <p>{StockCode}</p>
                        <span>{StockName}</span>
                    </div>
                </td>
                <td>{numberFormat(Quantity,0)}</td>
                <td>{PortfolioWeight}%</td>
                <td>{numberFormat(AverageCost)}</td>
                <td>{numberFormat(last_traded_price)}</td>
                <td>{numberFormat(CostValue)}</td>
                <td>{numberFormat(current_value)}</td>
                <td className={GetColorClass(current_returns)}>{numberFormat(current_returns)}%</td>
                <td className={GetColorClass(change_percentage)}>{numberFormat(change_percentage)}%</td>
            </tr>
        </>
    )
}

export default FamilyStocksTableRow