import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import Pulse from "../Loader/Pulse";
import { ArrowLeft2, ArrowRight2, NotePad } from "../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../exports/ColorVars";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const getTimeDiff = (date) => {
  const d1 = moment();
  const d2 = moment(date);

  let diff = d1.diff(d2, "seconds");
  if (diff < 60) {
    return diff + (diff == 1 ? " second ago" : " seconds ago");
  }

  diff = d1.diff(d2, "minutes");
  if (diff < 60) {
    return diff + (diff == 1 ? " minute ago" : " minutes ago");
  }

  diff = d1.diff(d2, "hours");
  if (diff < 24) {
    return diff + (diff == 1 ? " hour ago" : " hours ago");
  }

  diff = d1.diff(d2, "days");
  if (diff < 31) {
    return diff == 1 ? "Yesterday" : `${diff} days ago`;
  }

  diff = d1.diff(d2, "months");
  if (diff < 12) {
    return diff + (diff == 1 ? " month ago" : " months ago");
  }

  diff = d1.diff(d2, "years");
  return diff + (diff == 1 ? " year ago" : " years ago");
};

function GoogleFeeds() {
  async function GetGoogleFeeds() {
    const response = await fetch(`${REQUEST_BASE_URL}/googlefeeds`);
    const data = await response.json();

    if (!data.error) {
      return data.data;
    }

    return false;
  }

  const { data, isLoading } = useQuery(["googlefeeds"], GetGoogleFeeds);

  return (
    <>
      {isLoading ? (
        <>
          <div className="dashboard-news-loading">
            <Pulse />
            <p>Loading news, please wait...</p>
          </div>
        </>
      ) : (
        <>
          {data.length === 0 ? (
            <>
              <div className="dashboard-news-loading">
                <NotePad size={60} color="#192b40" />
                <p>We don't have any news to show</p>
              </div>
            </>
          ) : (
            <>
              {data.map((d, index) => {
                return <GoogleFeedRow key={index} data={d} />;
              })}
            </>
          )}
        </>
      )}
    </>
  );
}

function GoogleFeedRow({ data }) {
  const Get = (t, i = 0) => (Array.isArray(t) ? t[i] : t);
  const head = Get(data.title),
    Source = Get(data.source)._;
  const title = head.slice(0, head.lastIndexOf(" - " + Source));
  const time = getTimeDiff(Get(data.pubDate));

  return (
    <div className="google-feeds-row">
      <p
        title={title}
        onClick={() => {
          window.open(Get(data.link), "_blank");
        }}
      >
        {title}
      </p>
      <p>
        -&nbsp;<span>{Source}</span>&nbsp;&nbsp;
        <span>&middot;&nbsp;{time}</span>
      </p>
    </div>
  );
}

function DashboardNews() {
  const [news, setNews] = useState(false);

  return (
    <>
      <div className="swift-dashboard-news-mobile">
        <p>
          For the <br /> professional <br /> investor
        </p>
        <div className="news-button-row">
          <div className="news-button" onClick={()=>{setNews(true)}}>
            <p>Daybook</p>
            <ArrowRight2 size={20} color={COLOR_VARS["SWIFT_COLOR1"]} />
          </div>
          {/* <div className="news-button">
                <p>Recent Research</p>
                <ArrowRight2 size={20} color={COLOR_VARS['SWIFT_COLOR1']} />
            </div> */}
        </div>
      </div>
      <div className={"swift-dashboard-news " + (news ? "active": "")}>
        <p className="dashboard-box-title">
            <span className="back-icon" onClick={()=>{setNews(false)}}><ArrowLeft2 size={18} color={COLOR_VARS['SWIFT_COLOR1']} /></span>
            <span className="title">Daybook</span>
        </p>
        <div className="dashboard-news-content">
          <div className="news-content">
            <div className="dashboard-news-google">
              <GoogleFeeds />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DashboardNews;
