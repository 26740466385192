import React from 'react';
import { Monitor } from '../CustomComponents/SwiftIcon/Icons';

function HideContent() {
    return (
        <div className="swift-wrapper-hidden">
            <div className='swift-name'>Swift<i>folios</i></div>
            <div className='icon'>
                <Monitor size={80} color="#192b40" />
            </div>
            <p className='message'>
                We're sorry, but it appears that the screen size of your device may be too small to display this content optimally. <br />
                <span>To ensure the best viewing experience, we recommend using a device with a larger screen or increasing the screen resolution if possible.</span>
            </p>
        </div>
    )
}

export default HideContent