import { useEffect, useState } from "react";
import Axios from "axios";
import { GetCookie } from "../exports/CookieManagement";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const useAccount = () => {
  const account_token = GetCookie("token");

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);

  useEffect(() => {
    GetAccountInfo();
  }, []);

  function GetAccountInfo() {
    setLoading(true);

    Axios.get(
      `${REQUEST_BASE_URL}/clientinfo/swiftaccount?token=${account_token}`
    )
      .then((response) => {
        const data = response.data;
        if (data.error) {
          setData(false);
        } else {
          const customer = data.data;

          const { brokers = [] } = customer;

          if (!brokers || !Array.isArray(brokers) || brokers.length === 0) {
            customer["isAPI"] = false;
          } else {
            const broker = brokers[0];
            customer["isAPI"] = broker["trading_method"] === "API";
          }

          setData(customer);
        }
      })
      .catch((error) => {
        setData(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return { loading, account: data };
};

export default useAccount;
