import React from "react";

const StockFooter = () => {
  return (
    <div className="stock-management-footer">
      All rights reserved @ Yottol Fintech Private Limited{" "}
    </div>
  );
};

export default StockFooter;
