import React from 'react';

class ClientHoldings extends React.PureComponent {

    FormatPercentage(value) {
        if (value < 0) {
            return parseFloat(value).toFixed(2);
        }
        else {
            return "+" + parseFloat(value).toFixed(2);
        }
    }


    render() {

        const { holdings, portfolio } = this.props;
        if (holdings.length > 0 && portfolio) {
            return (
                <div className='orderbook__container'>
                    <table>
                        <thead>
                            <tr>
                                <th>Symbol</th>
                                <th>Exchange</th>
                                <th>Average Buy Price</th>
                                <th>Previous Close</th>
                                <th>Last Traded Price</th>
                                <th>Today's Return</th>
                                <th>Portfolio Holdings</th>
                                <th>API Holdings</th>
                            </tr>

                        </thead>
                        <tbody>
                            {holdings.map((holding, indx) => {
                                if (holding.Quantity > 0) {
                                    return <tr key={indx}>
                                        <td>{holding.stockCode}</td>
                                        <td>{holding.exchange}</td>
                                        <td>{holding.buy_avg}</td>
                                        <td>{holding.previous_close}</td>
                                        <td>{holding.ltp}</td>
                                        <td className={holding.change >= 0 ? 'positive' : 'negative'}>{this.FormatPercentage(holding.change)}</td>
                                        <td>{holding.QOrderBook}</td>
                                        <td>{holding.QDP}</td>
                                    </tr>
                                }
                            })}
                        </tbody>
                    </table>


                </div>

            )
        }
        else {
            return 'NO DATA FOUND!';
        }


    }
}

export default ClientHoldings;