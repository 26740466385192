import React from 'react'
import "../../../css/AdminManagement/MFOrdersManagement.css";
import { NavLink, useLocation } from 'react-router-dom';
import BrokerRoute from './BrokerRoute';



const menu_array = [
  {
    name: 'Equity',
    path: '/admin/broker/equity',
  }
];


function BrokerDashbaord() {

  const location = useLocation();

  const { pathname = '' } = location;


  return (
    <div className='swift-content-wrapper swift-account-main'>
      <div className='swift-submenu'>
        {menu_array.map((m, index) => {
          return <NavLink key={index} activeclassname='active' to={m.path}>
            <p className={(pathname === m.path) ? 'active' : ''}>{m.name}</p>
          </NavLink>
        })}
      </div>

      <div className='strategy-content'>
        <BrokerRoute />
      </div>
    </div>
  
  )
}


export default BrokerDashbaord