import React, { useState } from "react";
import axios from "axios";
import { Cross, ReceiptEdit } from "../../CustomComponents/SwiftIcon/Icons";
import SwiftModal from "../../CustomComponents/SwiftModal/SwiftModal";
import {
  SWIFTALGO_TIMEWINDOW_REVERSE,
  SWIFTALGO_TIMEWINDOW,
} from "../../../exports/OrderAlgoConfig";
import ConfirmBox from "../../CustomComponents/ConfirmBox/ConfirmBox";
import OrderTypePopup from "./OrderTypePopup";
import useMarketStock from "../../../hooks/useMarketStock";
import useTradeInfo from "../../../hooks/useTradeInfo";
import { GetCookie } from "../../../exports/CookieManagement";
import { Alert } from "../../Body/CustomChartComponents/CustomAlert/CustomAlert";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const success_config = {
  BandColor: "#470985",
  AutoClose: {
    Active: true,
    Line: true,
    LineColor: "#470985",
    Time: 3,
  },
};

const failure_config = {
  BandColor: "#E51A4B",
  AutoClose: {
    Active: true,
    Line: true,
    LineColor: "#E51A4B",
    Time: 3,
  },
};

function TradeItem({ show, user_role_type, order }) {
  // console.log('TradeItem')

  const { tradeOrderID: trade_order_id } = order;

  const trade_info = useTradeInfo(trade_order_id);

  const {
    stockCode: name,
    stockName: fullname,
    exchange,
    instrument_token: code,
    orderType: ordertype,
    limitPrice: limitprice,
    Side: side,
    Quantity: quantity,
    Status: status,
    brokerCode: broker,
    order_info = false,
    algo_type = false,
  } = trade_info;

  let current_price = "";
  let executed_quantity = 0;
  const stock_data = useMarketStock(name);

  const [typeOpen, setTypeOpen] = useState(false);

  if (order_info) {
    executed_quantity = order_info["executed_quantity"] || 0;
  }

  if (stock_data) {
    current_price = stock_data.last_traded_price;
  }

  const order_value = current_price === "" ? "" : quantity * current_price;

  function ModifyOrder(config) {
    const account_token = GetCookie("token");
    const { type, limit_price, algo_config } = config;

    if (algo_config["time_window"]) {
      algo_config["time_window"] =
        SWIFTALGO_TIMEWINDOW[algo_config["time_window"]];
    }

    axios
      .post(`${REQUEST_BASE_URL}/order/modify?token=${account_token}`, {
        order_id: trade_order_id,
        order_type: type,
        limit_price: limit_price,
        algo_config: algo_config,
      })
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          Alert({
            TitleText: "Order Modify",
            Message: "The order has been modified successfully",
            ...success_config,
          });
        } else {
          Alert({
            TitleText: "Warning",
            Message: "Something went wrong while modifying the order",
            ...failure_config,
          });
        }
      })
      .catch((error) => {
        Alert({
          TitleText: "Warning",
          Message: "Something went wrong while modifying the order",
          ...failure_config,
        });
      });
  }

  function CancelOrder(order_id, callback) {
    if (!order_id) {
      callback();
      return;
    }

    const account_token = GetCookie("token");
    axios
      .post(`${REQUEST_BASE_URL}/order/cancel?token=${account_token}`, {
        order_id: trade_order_id,
      })
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          Alert({
            TitleText: "Order Cancel",
            Message: "The order has been cancelled successfully",
            ...success_config,
          });
          callback();
        } else {
          Alert({
            TitleText: "Warning",
            Message: "Something went wrong while cancelling the order",
            ...failure_config,
          });
          callback();
        }
      })
      .catch((error) => {
        Alert({
          TitleText: "Warning",
          Message: "Something went wrong while cancelling the order",
          ...failure_config,
        });
        callback();
      });
  }

  function OrderItemName(name, fullname) {
    return (
      <>
        <div className="stock-info">
          <span className="stock-name">{name}</span>
          <span className="stock-fullname">{fullname}</span>
        </div>
      </>
    );
  }

  function OrderSide(side) {
    return (
      <div className={side ? side.toLowerCase() : side}>
        {side && side.toUpperCase()}
      </div>
    );
  }

  function ReplaceNoise(value) {
    if (
      value === null ||
      value === undefined ||
      value === "" ||
      parseInt(value) === 0
    ) {
      return "-";
    }
    return value;
  }

  function curr(num) {
    return parseFloat(num).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  const algo_config = order_info
    ? {
        time_window: SWIFTALGO_TIMEWINDOW_REVERSE[order_info["time_window"]],
        data_points: order_info["data_points"],
      }
    : false;

  if (!show || !trade_info) {
    return <></>;
  }

  return (
    <>
      <tr>
        <td className="order-item-stock-name">
          {OrderItemName(name, fullname)}
        </td>
        <td className="order-item-stock-exchange">{exchange}</td>
        <td className="order-item-type">
          {user_role_type === "BROKER" && algo_type != "MARKET" && status == 'Pending' ? (
            <>
              <div
                className="order-item-type-box"
                onClick={() => {
                  setTypeOpen(true);
                }}
              >
                {algo_type}
                <ReceiptEdit size={14} color="rgba(1, 22, 39, 0.90)" />
              </div>
            </>
          ) : (
            <>{algo_type}</>
          )}
        </td>
        <td className="order-item-limit">{ReplaceNoise(limitprice)}</td>
        <td className="order-item-side">{OrderSide(side)}</td>
        <td className="order-item-quantity">
          {executed_quantity}/{quantity}
        </td>
        <td className="order-item-current-price">
          {current_price ? curr(current_price) : "-"}
        </td>
        <td className="order-item-current-value">
          {order_value ? curr(order_value) : "-"}
        </td>
        <td className="order-item-broker">{broker ? broker : "-"}</td>
        <td className="order-item-status">{status}</td>
        <td
          className="order-item-remove"
          onClick={() => {
            ConfirmBox({
              title: "Delete Trade",
              description: (
                <>
                  Are you sure you want to delete the <strong>{name}</strong>?
                </>
              ),
              properties: [
                {
                  id: "2",
                  label: "Delete",
                  color: "#192b40",
                  bgColor: "#ffffff",
                  handleFunction: CancelOrder,
                  functionArgs: [trade_order_id],
                },
              ],
              cancel: true,
            });
          }}
        >
          <Cross size={24} color="rgba(1, 22, 39, 0.90)" />
        </td>
      </tr>
      {typeOpen && (
        <>
          <SwiftModal
            top="2%"
            closeModal={() => {
              setTypeOpen(false);
            }}
          >
            <OrderTypePopup
              order={{
                ordertype: algo_type,
                code,
                exchange,
                quantity,
                side,
                name,
                broker,
                limitprice,
                algo_config,
              }}
              current_price={current_price}
              ModifyOrder={ModifyOrder}
              setTypeOpen={setTypeOpen}
              closePopup={() => {
                setTypeOpen(false);
              }}
            />
          </SwiftModal>
        </>
      )}
    </>
  );
}

export default TradeItem;
