import React, { useEffect, useMemo, useState } from "react";
import TransactionRoutes from "./TransactionRoutes";
import { NavLink, useLocation } from "react-router-dom";
import { GetRoleType } from "../../exports/RoleTypes";
import { GetCookie } from "../../exports/CookieManagement";
import {
  ArrowDown2,
  ArrowUp2,
} from "../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../exports/ColorVars";

const menu_array = [
  {
    name: "Route Book",
    path: "/account/transaction/routebook",
    roles: ["ADMIN", "BROKER"],
  },
  {
    name: "Order Book",
    path: "/account/transaction/orderbook",
    roles: ["ADMIN", "BROKER"],
  },
  {
    name: "Trade Book",
    path: "/account/transaction/tradebook",
    roles: ["ADMIN", "BROKER"],
  },
  {
    name: "Order Confirmation",
    path: "/account/transaction/confirmation",
    roles: ["CUSTOMER"],
  },
  {
    name: "Order History",
    path: "/account/transaction/history",
    roles: ["ADMIN", "BROKER", "CUSTOMER"],
  },
  {
    name: "Order Files",
    path: "/account/transaction/files",
    roles: ["ADMIN", "BROKER"],
  },
  {
    name: "Manual Order Entry",
    path: "/account/transaction/entry",
    roles: ["ADMIN", "BROKER"],
  },
  {
    name: "Mismatched Holdings",
    path: "/account/transaction/holdings",
    roles: ["ADMIN", "BROKER", "CUSTOMER"],
  },
];

function TransactionMenu({ user_role_type }) {
  const location = useLocation();
  const { pathname = "" } = location;

  const [menu, setMenu] = useState("Dashboard");
  const [menuPopup, setMenuPopup] = useState(false);

  function NavLinkCallback(menu_item) {
    setMenu(menu_item);
    setMenuPopup(false);
  }

  useEffect(() => {
    const menu_item = menu_array.find((m) => m.path === pathname);
    if (menu_item) {
      setMenu(menu_item.name);
    } else {
      const filtered_array = menu_array.filter((m) =>
        m.roles.includes(user_role_type)
      );
      if (filtered_array.length > 0) {
        setMenu(filtered_array[0].name);
      }
    }
  }, []);

  const filtered_menu_array = useMemo(() => {
    return menu_array.filter((m) => {
      return m.roles.includes(user_role_type);
    });
  },[]);

  return (
    <>
      {filtered_menu_array.length > 1 && (
        <>
          <div
            className="swift-submenu-item"
            onClick={() => {
              setMenuPopup(!menuPopup);
            }}
          >
            <p>{menu}</p>
            {menuPopup ? (
              <>
                <ArrowUp2 size={18} color={COLOR_VARS["SWIFT_COLOR1"]} />
              </>
            ) : (
              <>
                <ArrowDown2 size={18} color={COLOR_VARS["SWIFT_COLOR1"]} />
              </>
            )}
          </div>
        </>
      )}
      <div className={"swift-submenu " + (menuPopup ? "active" : "")}>
        {filtered_menu_array.map((m, index) => {
          return (
            <NavLink
              key={index}
              activeclassname="active"
              to={m.path}
              onClick={() => {
                NavLinkCallback(m.name);
              }}
            >
              <p className={pathname === m.path ? "active" : ""}>{m.name}</p>
            </NavLink>
          );
        })}
      </div>
    </>
  );
}

function TransactionManagement() {
  const user_role_type = GetRoleType(GetCookie("role"));

  return (
    <>
      <div className="swift-transaction-management swift-content">
        <div className="swift-submenu-wrapper">
          <TransactionMenu user_role_type={user_role_type} />
        </div>

        <div className="strategy-content">
          <TransactionRoutes user_role_type={user_role_type} />
        </div>
      </div>
    </>
  );
}

export default TransactionManagement;
