import React from "react";
import '../../css/ClientManagement/ClientManagement.css';
import KYCClient from "./KYCClient";
import KYCTable from "./KYCTable";
import Customers from "./Customers";

function ClientManagement() {



    return (
        <>
            <div className='swift-client-management swift-content'>
                {/* <div className='client-header'>
                    <p className='client-title'>Know Your Customer</p>
                </div> */}

                <div className='strategy-content'>
                    <div className="client-strategy-content">
                        <KYCClient />
                        {/* <KYCTable /> */}
                        <Customers />
                    </div>

                </div>
            </div>
        </>
    )
}

export default ClientManagement