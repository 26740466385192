import React from 'react';
import Axios from 'axios';
import "../../../../css/Popup/OrderStatus.css";
import { Alert } from '../../CustomChartComponents/CustomAlert/CustomAlert';
import CloseIcon from '../../../../assets/icons/closesmall.svg';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

export class OrderStatus extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            pannumber: this.props.pannumber,
            orderdetails: this.props.orderdetails,
            orderstatus: false
        }
    }

    componentDidMount() {
        const { pannumber, orderdetails } = this.state;
        let oms_order_id = orderdetails['orderinfo']['oms_order_id'];
        if (Array.isArray(oms_order_id)) {
            oms_order_id = oms_order_id[0];
        }
        if (orderdetails.error) {
            this.setState({
                orderstatus: {
                    status: false,
                    rejected_reason: '',
                    custom_reason: ''
                }
            })
        }
        else {
            Axios.get(`${REQUEST_BASE_URL}/order/orderstatus?oms_order_id=${oms_order_id}&pan=${pannumber}`).then((response) => {
                const data = response.data;

                if (!data.error) {
                    let rejected_reason = data.rejected_reason;
                    let custom_reason;
                    if (rejected_reason.includes('RMS:Margin')) {
                        custom_reason = 'Issue with margin. Kindly get in touch with us @ +91 8160080658';
                    }
                    else if (rejected_reason.includes('RMS:Blocked')) {
                        custom_reason = 'Restricted stock. Kindly get in touch with us @ +91 8160080658';
                    }
                    else {
                        custom_reason = '';
                    }
                    this.setState({
                        orderstatus: {
                            status: data.status,
                            rejected_reason: data.rejected_reason,
                            custom_reason: custom_reason

                        }
                    })
                }
                else {
                    this.setState({
                        orderstatus: {
                            status: false,
                            rejected_reason: '',
                            custom_reason: ''
                        }
                    })
                }
            }).catch((err) => {
                console.log(err)
            });

        }
    }

    displayMessage = (msg) => {
        Alert({
            TitleText: 'Exchange Message',
            Message: msg,
            BandColor: '#e51a4b',
            AutoClose: {
                Active: true,
                Line: true,
                LineColor: '#e51a4b',
                Time: 5
            }
        })
    }

    render() {

        const { orderstatus } = this.state;

        if (orderstatus) {
            const { status, rejected_reason, custom_reason } = orderstatus;
            return (
                <div className='order-status'>
                    <div className='order-status-header'>
                        <img src={CloseIcon} alt="X" onClick={() => { this.props.closeOrderStatus() }} />
                    </div>
                    <div className='order-status-body'>
                        {status === 'complete' &&
                            <>
                                <div className='order-icon success'>
                                    <svg className="checkmark__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                        <circle className="checkmark__circle__inner" cx="26" cy="26" r="25" fill="none" />
                                        <path className="checkmark__check__inner" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                    </svg>
                                </div>
                                <p className='order-status-title'>Your order is completed!</p>
                                <div className='order-status-description'>
                                    <span>Your order has been placed successfully. </span>
                                    <span>Please check your Portfolio or Orders section to check the order details</span>
                                </div>
                                <div className='order-status-buttons'>
                                    <div onClick={() => { this.props.closeOrderStatus(true) }}>Back</div>
                                </div>
                            </>
                        }
                        {(status === 'open' || status === 'pending' || status === 'open pending') &&
                            <>
                                <div className='order-icon success'>
                                    <svg className="checkmark__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                        <circle className="checkmark__circle__inner" cx="26" cy="26" r="25" fill="none" />
                                        <path className="checkmark__check__inner" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                    </svg>
                                </div>
                                <p className='order-status-title'>Your order is completed!</p>
                                <div className='order-status-description'>
                                    <span>Your order has been placed successfully. </span>
                                    <span>Your order is in the market with open status. It wiil be confirmed soon.</span>
                                </div>
                                <div className='order-status-buttons'>
                                    <div onClick={() => { this.props.closeOrderStatus(true) }}>Back</div>
                                </div>
                            </>
                        }
                        {status === 'rejected' &&
                            <>
                                <div className='order-icon failure'>
                                    <svg className="checkmark__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                        <circle className="checkmark__circle__inner" cx="26" cy="26" r="25" fill="none" />
                                        <path className="checkmark__check__inner" fill="none" d="M16 16 36 36 M36 16 16 36" />
                                    </svg>
                                </div>
                            <p className='order-status-title'>Your order is Rejected!</p>
                                <div className='order-status-description'>
                                    <span>Rejected Reason</span>
                                <span>{custom_reason}</span>
                            </div>
                            <div className='order-status-exchange'>
                                <span onClick={() => { this.displayMessage(rejected_reason) }}>Exchange Message</span>
                                </div>
                                <div className='order-status-buttons'>
                                    <div>Dashboard</div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className='order-status'>
                    <p>Loading</p>
                </div>
            )
        }
    }
}

export default OrderStatus;
