import React, { useEffect, useState } from "react";
import moment from 'moment';
import Axios from "axios";
import CustomDate from "../Body/CustomChartComponents/CustomDate/CustomDate";
import CustomSelect from "../Body/CustomChartComponents/CustomSelect/CustomSelect";
import CustomInput from "../Body/CustomChartComponents/CustomInput/CustomInput";
import CustomIconButton from "../Body/CustomChartComponents/CustomIconButton/CustomIconButton";
import { GetCookie } from "../../exports/CookieManagement";
import MACSearch from "../CustomComponents/MACSearch/MACSearch";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";
import { ArrowRight2 } from "../CustomComponents/SwiftIcon/Icons";
import Pulse from "../Loader/Pulse";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const side_array = ["Buy", "Sell"];

function OrderEntry() {
  const [loading, setLoading] = useState(false);
  const [placed, setPlaced] = useState(false);
  const [customers, setCustomers] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [brokers, setBrokers] = useState([]);
  const [brokerArray, setBrokerArray] = useState([]);
  const [brokerConfig, setBrokerConfig] = useState(false);
  const [broker, setBroker] = useState(false);
  const [stock, setStock] = useState(false);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [side, setSide] = useState(side_array[0]);
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");

  useEffect(() => {
    GetMACustomers();
  }, []);

  useEffect(() => {
    setBrokers([]);
    setBroker(false);
    setStock(false);
    setSide(side_array[0]);
    setQuantity("");
    setPrice("");
    GetBrokers();
  }, [customer]);

  useEffect(() => {
    if (brokers.length === 0) {
      setBrokerArray([]);
    } else {
      const broker_array = brokers.map((b) => {
        return b.broker;
      });
      setBrokerArray(
        brokers.map((b) => {
          return b.broker;
        })
      );
      setBrokerConfig(brokers[0]);
      setBroker(broker_array[0]);
    }
  }, [brokers]);

  useEffect(() => {
    const config = brokers.find((b) => {
      return b.broker === broker;
    });
    if (!config) return;
    setBrokerConfig(config);
  }, [broker]);

  function GetMACustomers() {
    const account_token = GetCookie("token");
    Axios.get(`${REQUEST_BASE_URL}/portfolio/customers?token=${account_token}`)
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          setCustomers(data.customers);
        } else {
          setCustomer([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function GetBrokers() {
    if (!customer) return;
    const account_code = customer.account_code;
    Axios.get(
      `${REQUEST_BASE_URL}/clientinfo/brokers?account_code=${account_code}`
    )
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          setBrokers(data.brokers);
        } else {
          setBrokers([]);
        }
      })
      .catch((error) => {
        setBrokers([]);
      });
  }

  function ShowError(msg) {
    Alert({
      TitleText: "Warning",
      Message: msg,
      Band: true,
      BandColor: "#E51A4B",
      BoxColor: "#ffffff",
      TextColor: "#000000",
      AutoClose: {
        Active: true,
        Line: true,
        LineColor: "#E51A4B",
        Time: 3,
      },
    });
  }

  function ShowSuccess(msg) {
    Alert({
      TitleText: "Success",
      Message: msg,
      Band: true,
      BandColor: "#028F76",
      BoxColor: "#ffffff",
      TextColor: "#000000",
      AutoClose: {
        Active: true,
        Line: true,
        LineColor: "#028F76",
        Time: 3,
      },
    });
  }

  function SubmitOrder() {
    if (quantity === "" || isNaN(quantity)) {
      ShowError("Please enter valid order quantity");
    } else if (price === "" || isNaN(price)) {
      ShowError("Please enter valid order price");
    } else {
      setLoading(true);
      const account_token = GetCookie("token");
      const account_code = customer.account_code;

      const { nse_code, bse_code, exchange, isin, name, sc_group, code } =
        stock;
      const { trading_code, broker: broker_code } = brokerConfig;

      const stock_exchange = exchange.exchange;
      const symbol = stock_exchange === "NSE" ? nse_code : bse_code;
      let orderside = side;

      const stock_data = {
        Date: date,
        AccountCode: account_code,
        StockCode: symbol,
        Company: name,
        Code: code,
        isin: isin,
        Series: sc_group,
        Exchange: stock_exchange,
        Quantity: quantity,
        Side: orderside,
        OrderType: "MARKET",
        LMPrice: "",
        Price: price,
        TradingCode: trading_code,
        BrokerCode: broker_code,
      };

      Axios.post(`${REQUEST_BASE_URL}/order/entry?token=${account_token}`, {
        account_code: account_code,
        stocks: [stock_data],
      })
        .then((response) => {
          setTimeout(() => {
            const data = response.data;
            if (data.error) {
              ShowError("Something went wrong while adding the order");
            } else {
              ShowSuccess("Order added successfully");
            }

            setCustomer(false);
            setBrokers([]);
            setBrokerArray([]);
            setBrokerConfig(false);
            setBroker(false);
            setSide(side_array[0]);
            setQuantity("");
            setPrice("");
            setStock(false);
            setLoading(false);
            setPlaced(!placed);
          }, 1000);
        })
        .catch((error) => {
          ShowError("Something went wrong while adding the order");
          setBrokers([]);
          setLoading(false);
        });
    }
  }

  return (
    <div className="orders-entry-wrapper">
      {!customers ? (
        <>
          <div className="loading-wrapper">
            <Pulse />
            <p>Loading, please wait...</p>
          </div>
        </>
      ) : (
        <>
          <div className="entry-box">
            {loading && (
              <>
                <div className="entry-loader">
                  <Pulse />
                  <p>Placing your order...</p>
                </div>
              </>
            )}
            <div className="entry-row">
              <MACSearch
                key={placed}
                title="Search Client"
                customers={customers}
                onSelect={(val) => {
                  setCustomer(val);
                }}
              />
            </div>
            {brokers.length > 0 ? (
              <>
                <div className="entry-row">
                  <SearchStock setStock={setStock} />
                </div>
                {stock && (
                  <>
                    <div className="entry-row">
                      <div className="entry-box-half">
                        <CustomDate
                          heading="Order Date"
                          value={date}
                          error={false}
                          onChange={(val) => {
                            setDate(val);
                          }}
                        />
                      </div>
                      <div className="entry-box-half"></div>
                    </div>
                    <div className="entry-row">
                      <CustomSelect
                        heading="Order Type"
                        options={side_array}
                        defaultIndex={0}
                        value={side}
                        onTypeChange={(value) => {
                          setSide(value);
                        }}
                      />
                      <CustomSelect
                        heading="Broker"
                        options={brokerArray}
                        defaultIndex={0}
                        value={side}
                        onTypeChange={(value) => {
                          setBroker(value);
                        }}
                      />
                    </div>
                    <div className="entry-row">
                      <CustomInput
                        placeholder=""
                        heading="Order Quantity"
                        val={quantity}
                        onChange={(val) => {
                          setQuantity(val);
                        }}
                      />

                      <CustomInput
                        placeholder=""
                        heading="Order Price"
                        val={price}
                        onChange={(val) => {
                          setPrice(val);
                        }}
                      />
                    </div>
                    <div className="entry-row submit">
                      <CustomIconButton
                        icon={<ArrowRight2 size={20} />}
                        title="Submit"
                        onClick={SubmitOrder}
                      />
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </div>
  );
}

function SearchStock({ setStock }) {
  const [selected, setSelected] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggessions] = useState([]);

  function HandleSearchChange(e) {
    setSearch(e.target.value);
  }

  function GetStockSuggessions() {
    Axios.get(`${REQUEST_BASE_URL}/stock/${search}`)
      .then((response) => {
        let suggestions = response.data.suggestions;
        setLoading(false);
        setSuggessions(suggestions);
      })
      .catch((error) => {
        setLoading(false);
        setSuggessions([]);
      });
  }

  function SelectStock(stock) {
    setSelected(true);
    setSearch(stock.symbol);
    setSuggessions([]);
    setStock(stock);
  }

  useEffect(() => {
    if (!selected) {
      GetStockSuggessions();
    }
  }, [search]);

  return (
    <div className="entry-stock-search">
      <p className="search-title">Search Symbol</p>
      <div className="search-wrapper">
        <input
          placeholder="Search symbol"
          value={search}
          onFocus={() => {
            setSelected(false);
          }}
          onChange={(e) => {
            HandleSearchChange(e);
          }}
        />

        {suggestions.length > 0 && (
          <>
            <div className="entry-stock-suggessions">
              {suggestions.map((s, index) => {
                return (
                  <StockSuggession
                    key={index}
                    stock={s}
                    SelectStock={SelectStock}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function StockSuggession({ stock, SelectStock }) {
  const { nse_code, bse_code, company, exchange } = stock;

  const symbol = exchange.exchange === "NSE" ? nse_code : bse_code;

  return (
    <div
      className="suggession-row"
      onClick={() => {
        SelectStock(stock);
      }}
    >
      <span>{symbol}</span>
      <span>{company}</span>
      <span>{exchange.exchange}</span>
    </div>
  );
}

export default OrderEntry;
