import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AccessSuperLogin from "./AccessSuperLogin";
import AccessLogin from "./AccessLogin";
import AccessSignup from "./AccessSignup";
import AccessSuccess from "./AccessSuccess";
import AccessPasswordReset from "./AccessPasswordReset";
import AccessLogout from "./AccessLogout";
import "../../css/AccessManagement/AccessManagement.css";


function AccessManagement() {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/access/login" />} />
      <Route path="/superuser" element={<AccessSuperLogin />} />
      <Route path="/login" element={<AccessLogin />} />
      <Route path="/logout" element={<AccessLogout />} />
      <Route path="/reset" element={<AccessPasswordReset />} />
      <Route path="/signup" element={<AccessSignup />} />
      <Route path="/success" element={<AccessSuccess />} />
    </Routes>
  );
}

export default AccessManagement;
