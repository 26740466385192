import React, { useEffect, useState, useRef } from 'react';
import { FilterSearch } from '../../../CustomComponents/SwiftIcon/Icons';
import '../../../../css/CustomComponents/CustomSearchSelect.css';
import { titleCase } from '../../../../exports/UtilFunctions';

function CustomSearchSelect({ title = 'Search Title', placeholder = 'Enter your input', options = [], onSelect = null, showSearch = true, hideOnSelect = false, selectedOption=null }) {

    const [filter, setFilter] = useState([]);
    const [search, setSearch] = useState('');
    const [focused,setFocused] =  useState(false);
    const [selected, setSelected] = useState(false);

    const MACRef = useRef();

    useEffect(()=>{
        if(selectedOption && selectedOption.name){
            setSearch(selectedOption.name);
        }
    },[selectedOption]);

    useEffect(() => {

        if (selected) {
            setFilter([]);
            return;
        };

        if(focused && search === ''){
            setFilter(options);
            return;
        }

        if(search === ''){
            setFilter([]);
            return;
        }
        
        const data = options.filter((c) => {
            return c.name.toLowerCase().includes(search.toLowerCase());
        });

        if(selectedOption && !focused){
            return;
        }

        setFilter(data);


    }, [focused,selected, search,options]);

    useEffect(() => {
        const listener = (event) => {
            if (!MACRef.current || MACRef.current.contains(event.target)) {
                return;
            }
            if (!selected) { 
                // setSearch('');
                setFocused(false);
                setFilter([])
            }
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
            document.removeEventListener("mousedown", listener);
            document.removeEventListener("touchstart", listener);
        };
    }, [selected, MACRef]);

    useEffect(() => {
        if (!showSearch) {
            setSearch('');
            setFocused(false);
        }
    }, [showSearch]);

    function handleSelect(val) {
        if (hideOnSelect) {
            setSearch('');
            setFocused(false);
        }
        else {
            setSearch(val.name);
            setFocused(false);
        }
        onSelect(val);
        setSelected(true);
    }

    return (
        <div className='select-search-wrapper' ref={MACRef}>
            <p className='search-title'>{title}</p>
            <div className='select-search-box'>
                <input placeholder={placeholder} value={titleCase(search)} onChange={(e) => setSearch(e.target.value)} onFocus={() => { setSelected(false); setFocused(true); }} />
                <div className='filter-icon'><FilterSearch size={20} color="#000" /></div>

                {filter?.length > 0 &&
                    <>
                        <div className='select-search-result'>
                            {filter.map((f, index) => {
                                return <div onClick={() => handleSelect(f)} key={index} className='result-row'><p>{f.name}</p></div>
                            })}
                        </div>

                    </>
                }

            </div>
        </div>
    )
}

export default CustomSearchSelect;