import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Menu from "./Menu";
import BodyWrapper from "./BodyWrapper";
import HideContent from "./HideContent";

import {
  Dashboard as DashboardIcon,
  BoxSearch,
  Book,
  UserProfile,
  ArrangeHorizontal,
  BriefCase,
  SecurityUser,
  Wallet,
  ProfileUser,
} from "../CustomComponents/SwiftIcon/Icons";

import Dashboard from "../Dashboard/Dashboard";
import StrategyManagement from "../StrategyManagement.js/StrategyManagement";
import PortfolioManagement from "../PortfolioManagement/PortfolioManagement";
import TransactionManagement from "../TransactionManagement/TransactionManagement";
import FamilyPortfolios from "../FamilyManagement/FamilyPortfolios";
import ReportManagement from "../ReportManagement/ReportManagement";
import ClientManagement from "../ClientManagement/ClientManagement";
import RolesManagement from "../RolesManagement/RolesManagement";
import FundsManagement from "../FundsManagement/FundsManagement";

import "../../css/Account/Account.css";
import { GetCookie } from "../../exports/CookieManagement";
import useAccount from "../../hooks/useAccount";
import ActivationAuth from "../AccountComponents/ActivationAuth";

/* 
    Account Component
    To manage other components after login
*/

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const menu_array = [
  {
    title: "ACCOUNT",
    options: [
      {
        name: "dashboard",
        title: "Overview",
        path: "dashboard",
        is_mobile: true,
        icon: DashboardIcon,
        component: Dashboard,
      },
      {
        name: "strategy",
        title: "Strategy & Equity",
        customer_title: "Equity",
        path: "strategy",
        is_mobile: true,
        icon: BoxSearch,
        component: ActivationAuth("equity", StrategyManagement),
      },
      {
        name: "portfolio",
        title: "Portfolios",
        path: "portfolio",
        is_mobile: false,
        icon: BriefCase,
        component: PortfolioManagement,
      }
    ],
  },
  {
    title: "GENERAL",
    options: [
      {
        name: "transaction",
        title: "Transactions",
        path: "transaction",
        icon: ArrangeHorizontal,
        component: TransactionManagement,
      },
      {
        name: "family",
        title: "Family Portfolios",
        path: "family",
        icon: ProfileUser,
        component: FamilyPortfolios,
      },
      {
        name: "reports",
        title: "Reports",
        path: "reports",
        icon: Book,
        component: ReportManagement,
      },
      {
        name: "funds",
        title: "Funds",
        path: "funds",
        icon: Wallet,
        component: FundsManagement,
      },
      {
        name: "clients",
        title: "Clients",
        path: "clients",
        icon: UserProfile,
        component: ClientManagement,
      },
      {
        name: "roles",
        title: "Roles",
        path: "roles",
        icon: SecurityUser,
        component: RolesManagement,
      },
    ],
  },
];

function Account(props) {
  const navigate = useNavigate();

  const location = useLocation();

  const { account } = useAccount();

  const user_role = GetCookie("role");
  const [accountCode, setAccountCode] = useState(false);
  const [roles, setRoles] = useState([]);
  const [rolesArray, setRolesArray] = useState([]);
  const [menuRolesArray, setMenuRolesArray] = useState([]);

  useEffect(() => {
    Axios.get(`${REQUEST_BASE_URL}/roles`)
      .then((response) => {
        const data = response.data;
        const roles = data.roles;
        setRoles(roles);
      })
      .catch((error) => {
        setRoles([]);
      });
  }, []);

  useEffect(() => {
    if (roles.length === 0 || !account) return;

    const role_info = roles.find((r) => {
      return r.role_code === user_role;
    });

    if (!role_info) return;

    const access = role_info["role_access"];

    const roles_array = menu_array
      .map((m) => {
        return m["options"];
      })
      .flat(1);
    const menu_roles = roles_array.filter((m) => {
      return access.includes(m.name);
    });

    let menu_roles_array = [];
    menu_array.forEach((menu) => {
      const { title, options } = menu;
      if (options.length > 0) {
        let options_array = options.filter((o) => {
          return (
            access.includes(o.name) &&
            (o.name === "portfolio" ? !account.isAPI : true)
          );
        });
        menu_roles_array.push({
          title,
          options: options_array,
        });
      }
    });

    const path = menu_roles[0];
    if (location.pathname === "/account") {
      navigate(`account/${path}`, { replace: true });
    }

    setRolesArray(menu_roles);
    setMenuRolesArray(menu_roles_array);
  }, [accountCode, roles, account]);


  return (
    <>
      <div className="swift-wrapper">
        <Menu menu_array={menuRolesArray} user_role={user_role} />

        <BodyWrapper
          {...props}
          accountCode={accountCode}
          setAccountCode={setAccountCode}
          rolesArray={rolesArray}
          menuRolesArray={menuRolesArray}
        />
      </div>
      <HideContent />
    </>
  );
}

export default Account;
