import React from 'react';

function Date({ heading, value, error, onChange, ...rest }) {

    function changeHandler(e) {
        onChange(e.target.value)
    }

    return (
        <div className='swift-date-wrapper'>
            <p className='swift-date-title'>{heading}</p>
            <input {...rest} type="date" value={value || ''} onChange={(e) => { changeHandler(e) }} />
            <p className='error-text'>{error}</p>
        </div>

    );

}

export default Date;
