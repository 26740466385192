import React, { useEffect } from "react";
import Axios from "axios";
import $ from "jquery";
import {
  Routes,
  Route,
  Navigate,
  NavLink,
  useLocation,
} from "react-router-dom";
import withRouter from "../../hooks/withRouter";
import { GetRoleType } from "../../exports/RoleTypes";
import "../../css/MenuSection/Portfolio.css";
import { ReCalculateWeight } from "../../exports/ReCalculateWeight";
import PerformanceAnalytics from "../Body/MenuSection/PortfolioComponents/PerformanceAnalytics";
import Pulse from "../Loader/Pulse";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";
import { GetCookie } from "../../exports/CookieManagement";
import MACSearch from "../CustomComponents/MACSearch/MACSearch";
import ApplySmallcasePopup from "../Body/MenuSection/PortfolioComponents/ApplySmallcasePopup";
import PortfolioSearchBar from "../Body/MenuSection/PortfolioComponents/SearchBar";
import useLiveOrderValue from "../../hooks/useLiveOrderValue";
import { priceFormat } from "../../exports/UtilFunctions";
import PortfolioTableContainer from "./PortfolioTableContainer";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function PortfolioInvestment({ portfolio_investment }) {
  return (
    <>
      <div className="portfolio__info__value">
        <span className="portfolio__info__head">Investment Value</span>
        <div>
          <span>{priceFormat(portfolio_investment)}</span>
        </div>
      </div>
    </>
  );
}

function PortfolioUnrealisedGains({ customer, portfolio_profitloss, change }) {

  if(!customer) return;

  let gains = parseFloat(portfolio_profitloss) + parseFloat(change);
  return (
    <>
      <div className="portfolio__info__value">
        <span className="portfolio__info__head">{customer.user_role === 'MAC'? "Total Gains": "Unrealised Gains"}</span>
        <div>
          <span
            className={parseFloat(gains) >= 0 ? "positive" : "negative"}
          >
            {priceFormat(gains)}
          </span>
        </div>
      </div>
    </>
  );
}

function PortfolioValue({ portfolio_value, portfolio_change }) {
  const total = parseFloat(portfolio_value) + parseFloat(portfolio_change);

  return (
    <>
      <div className="portfolio__info__value">
        <span className="portfolio__info__head">Portfolio Value</span>
        <div>
          <span>{priceFormat(total)}</span>
        </div>
      </div>
    </>
  );
}

function PortfolioChange({ change, changeper, positive }) {
  let changeamount = !isNaN(change) ? change : 0;

  return (
    <>
      <div className="portfolio__info__value">
        <span className="portfolio__info__head">Portfolio Change Today</span>
        <div className={positive ? "positive" : "negative"}>
          <span className="portfolio__change__value">
            {priceFormat(changeamount)}
          </span>
          &nbsp;
          {changeper ? (
            <span
              className="portfolio__change__per"
              style={{ display: "flex" }}
            >
              ({changeper}%)
            </span>
          ) : (
            <>
              <span className="portfolio__change__per">(0.00%)</span>
            </>
          )}
        </div>
      </div>
    </>
  );
}

function PortfolioCashPosition({
  customer,
  cash,
  cash_virtual,
  setCashPosLive,
}) {
  const account_code = customer.account_code || "";
  const ordervalue = useLiveOrderValue(account_code);

  const live_cash = cash_virtual - ordervalue;

  useEffect(() => {
    setCashPosLive(live_cash);
  }, [live_cash]);

  return (
    <>
      <div className="portfolio__info__value">
        <span className="portfolio__info__head">Current Cash Position</span>
        <div>
          <span>{priceFormat(cash)}</span>
          &nbsp;
          {cash || cash === 0 ? (
            <span
              className="portfolio__change__per"
              style={{ display: "flex", fontSize: "12px", fontWeight: "500" }}
            >
              ({priceFormat(live_cash)})
            </span>
          ) : (
            <>
              <span className="portfolio__change__per">(0.00)</span>
            </>
          )}
        </div>
      </div>
    </>
  );
}

function PortfolioMenu({ user_role_type, activeCustomer }) {
  const location = useLocation();
  const { pathname = "" } = location;

  let customer_token = false;
  if (activeCustomer && activeCustomer.customer_token) {
    customer_token = activeCustomer.customer_token;
  }

  return (
    <>
      <NavLink
        to={
          customer_token
            ? `/account/portfolio/view?customer=${customer_token}`
            : "/account/portfolio/view"
        }
      >
        <p className={pathname === "/account/portfolio/view" ? "active" : ""}>
          View Portfolio
        </p>
      </NavLink>
      <NavLink
        to={
          customer_token
            ? `/account/portfolio/analysis?customer=${customer_token}`
            : "/account/portfolio/analysis"
        }
      >
        <p
          className={pathname === "/account/portfolio/analysis" ? "active" : ""}
        >
          Portfolio Analysis
        </p>
      </NavLink>
      {user_role_type === "BROKER" && (
        <>
          <NavLink to="/account/portfolio/blanket">
            <p
              className={
                pathname === "/account/portfolio/blanket" ? "active" : ""
              }
            >
              Blanket Execution
            </p>
          </NavLink>
        </>
      )}
    </>
  );
}

function PortfolioHeader({
  currentPath,
  user_role_type,
  customers,
  activeCustomer,
  isLoaded,
  emptyportfolios,
  sum,
  totalReturnChange,
  cashPos,
  cashPosVirtual,
  totalReturnToday,
  totalReturnPositive,
  setCustomer,
  setCashPosLive,
  addTableRow,
}) {
  if (!sum) {
    sum = {};
  }

  const {
    costValueSum = false,
    lastValueSum = 0,
    macCash = 0,
  } = sum;

  return (
    <div
      className={`portfolio__options ${
        user_role_type === "BROKER" ? "full" : ""
      }`}
    >
      {user_role_type === "BROKER" && (
        <>
          <div className="portfolio__selection">
            <div className="portfolio__customer">
              <MACSearch
                title="Search Client"
                customers={customers}
                onSelect={(val) => {
                  setCustomer(val);
                }}
                selectedCustomer={activeCustomer}
              />
            </div>
          </div>
        </>
      )}
      {activeCustomer && isLoaded && !emptyportfolios && (
        <>
          <div className="portfolio__header">
            <div className="portfolio__info__box">
              <PortfolioInvestment portfolio_investment={costValueSum} />
            </div>
            <div className="portfolio__info__box">
              <PortfolioValue
                portfolio_value={parseFloat(lastValueSum) + parseFloat(macCash)}
                portfolio_change={totalReturnChange}
              />
            </div>

            <div className="portfolio__info__box">
              <PortfolioUnrealisedGains
                customer={activeCustomer}
                portfolio_profitloss={parseFloat(lastValueSum) - parseFloat(costValueSum) + parseFloat(macCash)}
                change={totalReturnChange}
              />
            </div>
            <div className="portfolio__info__box">
              <PortfolioCashPosition
                customer={activeCustomer}
                cash={cashPos}
                cash_virtual={cashPosVirtual}
                setCashPosLive={setCashPosLive}
              />
            </div>
            <div className="portfolio__info__box">
              <PortfolioChange
                change={totalReturnChange}
                changeper={totalReturnToday}
                positive={totalReturnPositive}
              />
            </div>
          </div>

          {user_role_type === "BROKER" ? (
            <>
              {currentPath === "/account/portfolio/view" ? (
                <>
                  <div className="portfolio__search__bar">
                    <PortfolioSearchBar addTableRow={addTableRow} />
                  </div>
                </>
              ) : (
                <>
                  <div className="portfolio__search__bar"></div>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}

class PortfolioManagement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      account_token: GetCookie("token"),
      user_role: GetCookie("role"),
      user_role_type: GetRoleType(GetCookie("role")),
      isCustomerLoaded: false,
      customers: [],
      activeCustomer: false,
      userportfolios: [],
      emptyportfolios: false,
      currentportfolio: false,
      data: [],
      originalLen: 0,
      portfolioWeightArr: [],
      orderArr: [],
      priceArr: [],
      newWeight: [],
      changeArr: [],
      dynamicFields: [
        "currprice",
        "buyprice",
        "cost",
        "currvalue",
        "return",
        "tradevolume",
        "today",
      ],
      cashPos: 0,
      cashPosVirtual: 0,
      cashPosLive: 0,
      tradeVolume: 0,
      brokerageValue: 0,
      stocksBuyValue: 0,
      stocksSellValue: 0,
      sum: null,
      totalReturnToday: "",
      totalReturnChange: 0,
      totalReturnPositive: false,
      isLoaded: false,
      reportdownload: false,
      activeElement: 0,
      dataLen: 0,
      openSmallcasePopup: false,
      execute: false,
      isPlacing: false,
    };

    this.getMACustomers = this.getMACustomers.bind(this);
    this.loadPortfolio = this.loadPortfolio.bind(this);
    this.addOrderArr = this.addOrderArr.bind(this);
    this.updateOrderArr = this.updateOrderArr.bind(this);
    this.portfolioHome = this.portfolioHome.bind(this);
    this.performanceAnalyse = this.performanceAnalyse.bind(this);
    this.addTableRow = this.addTableRow.bind(this);
    this.setIndividualChange = this.setIndividualChange.bind(this);
    this.setIndividualPrice = this.setIndividualPrice.bind(this);
    this.selectField = this.selectField.bind(this);
    this.deleteStock = this.deleteStock.bind(this);
    this.placeOrder = this.placeOrder.bind(this);
    this.setCustomer = this.setCustomer.bind(this);
    this.loadCustomer = this.loadCustomer.bind(this);
    this.loadCashPosition = this.loadCashPosition.bind(this);
    this.setCashPosLive = this.setCashPosLive.bind(this);
    this.calculateTradeValues = this.calculateTradeValues.bind(this);
  }

  componentDidMount() {
    const { account_token, user_role_type } = this.state;

    if (user_role_type === "CUSTOMER") {
      // get user information
      this.getCustomerDetails(account_token);
    } else if (user_role_type === "BROKER") {
      this.getMACustomers(account_token);
    } else {
      this.setState({ isCustomerLoaded: true, customers: [] });
    }
  }

  getCustomerDetails(account_token) {
    Axios.get(
      `${REQUEST_BASE_URL}/clientinfo/swiftaccount?token=${account_token}`
    )
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          const customer = data.data;
          this.setState({ isCustomerLoaded: true, customers: [customer] });
          this.setState({ isCustomerLoaded: true });
          this.setCustomer(customer);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getMACustomers(account_token) {
    Axios.get(`${REQUEST_BASE_URL}/portfolio/customers?token=${account_token}`)
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          const customers = data.customers;
          this.setState({ isCustomerLoaded: true, customers: customers });
          const { location } = this.props.router;
          const query_params = new URLSearchParams(location.search);
          const encoded_customer_token = query_params.get("customer");

          if (encoded_customer_token) {
            const customer = customers.find((c) => {
              return c.customer_token === encoded_customer_token;
            });

            this.setCustomer(customer);
          }
        } else {
          this.setState({ isCustomerLoaded: true });
        }
      })
      .catch((error) => {
        this.setState({ isCustomerLoaded: true, customers: [] });
      });
  }

  loadCustomer(account_code) {
    this.loadPortfolio(account_code);
    this.setButtonPosition();
  }

  loadCashPosition(account_code) {
    Axios.get(
      `${REQUEST_BASE_URL}/clientinfo/cashposition?account_code=${account_code}`
    )
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          this.setState({
            cashPos: data.cash,
            cashPosVirtual: data.cash_virtual,
          });
        } else {
          this.setState({
            cashPos: 0,
            cashPosVirtual: 0,
          });
        }
      })
      .catch((err) => {
        this.setState({
          cashPos: 0,
          cashPosVirtual: 0,
        });
      });
  }

  loadPortfolio(account_code) {
    this.setState({
      isLoaded: false,
      totalReturnChange: 0,
      totalReturnToday: 0,
    });
    Axios.post(`${REQUEST_BASE_URL}/portfolio?account_code=${account_code}`, {
      portfolioName: "",
    }).then((response) => {
      const { portfolioData, sum } = response.data;

      let arr = new Array(portfolioData.length).fill(0);
      let weightArr = new Array(portfolioData.length).fill(false);

      this.setState(
        {
          data: portfolioData,
          originalLen: portfolioData.length,
          dataLen: portfolioData.length,
          orderArr: arr,
          newWeight: weightArr,
          changeArr: new Array(portfolioData.length).fill(0),
          priceArr: new Array(portfolioData.length).fill(0),
          sum: sum,
          isLoaded: true,
          emptyportfolios: false,
        },
        () => {
          let pwa = [];
          const data = portfolioData;
          data.forEach((d) => {
            pwa.push(parseFloat(d.PortfolioWeight));
          });

          this.setState({
            portfolioWeightArr: pwa,
          });
        }
      );
    });
  }

  setIndividualChange(index, value) {
    if (value && value !== undefined) {
      value = parseFloat(value.split("%")[0]);
      let changeArr = this.state.changeArr;
      changeArr[index] = value;
      this.setState({
        changeArr: changeArr,
      });
    }

    this.calculateReturns();
  }

  setIndividualPrice(index, value) {
    if (value && value !== undefined) {
      value = parseFloat(value);
      let priceArr = this.state.priceArr;
      priceArr[index] = value;
      this.setState({
        priceArr: priceArr,
      });
    }

    this.calculateReturns();
    this.calculateTradeValues();
  }

  calculateReturns() {
    const { activeCustomer, isLoaded } = this.state;

    if (!activeCustomer || !isLoaded) {
      return;
    }

    const { data, priceArr, sum } = this.state;
    const currValue = sum.lastValueSum;

    const portfolio_value = currValue;

    let totalReturns = 0;
    let current_portfolio_value = 0;

    priceArr.forEach((p, indx) => {
      if (p && p !== undefined && data[indx]) {
        current_portfolio_value += p * data[indx]["Quantity"];
      }
    });

    let totalChange = current_portfolio_value - portfolio_value;

    totalReturns =
      (current_portfolio_value / parseFloat(portfolio_value) - 1) * 100;
    let totalReturnPositive = totalReturns >= 0 ? true : false;

    totalReturns = parseFloat(totalReturns).toLocaleString("en-IN", {
      currency: "INR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    this.setState({
      totalReturnToday: totalReturns,
      totalReturnChange: totalChange.toFixed(2),
      totalReturnPositive,
    });
  }

  calculateTradeValues() {
    const { orderArr, priceArr, data, sum } = this.state;

    const { newWeight, tradeVolume, buyValue, sellValue } = ReCalculateWeight(
      data,
      orderArr,
      priceArr,
      sum
    );

    const { brokeragePercentage = 0.1 } = sum;

    const brokerage =
      (Math.abs(buyValue) + Math.abs(sellValue)) *
      parseFloat(brokeragePercentage);

    this.setState({
      newWeight,
      tradeVolume,
      brokerageValue: brokerage,
      stocksBuyValue: buyValue,
      stocksSellValue: sellValue,
    });
  }

  portfolioHome() {
    this.setState({
      activeElement: 0,
    });
  }

  performanceAnalyse() {
    this.setState({
      activeElement: 2,
    });
  }

  addOrderArr(index, type) {
    const { orderArr, priceArr, data, sum, cashPosLive } = this.state;

    const { brokeragePercentage = 0.1 } = sum;

    this.setState({ insufficientCash: false });

    // console.log(index, type, orderArr)

    if (type === "+") {
      orderArr[index] += 1;
    } else {
      orderArr[index] -= 1;
    }

    if (-orderArr[index] > data[index].Quantity) {
      orderArr[index] += 1;
      Alert({
        TitleText: "Warning",
        Message: "Cannot sell more than current holding.",
        Band: true,
        BandColor: "#E51A4B",
        BoxColor: "#ffffff",
        TextColor: "#000000",
        AutoClose: {
          Active: true,
          Line: true,
          LineColor: "#E51A4B",
          Time: 5,
        },
      });
    } else {
      const { newWeight, tradeVolume, buyValue, sellValue } = ReCalculateWeight(
        data,
        orderArr,
        priceArr,
        sum
      );

      const brokerage =
        (Math.abs(buyValue) + Math.abs(sellValue)) *
        parseFloat(brokeragePercentage);

      if (cashPosLive - tradeVolume < 0) {
        orderArr[index] -= 1;
        Alert({
          TitleText: "Warning",
          Message: `You don't have sufficient cash margin to proceed with the order!`,
          Band: true,
          BandColor: "#470985",
          BoxColor: "#ffffff",
          TextColor: "#000000",
          AutoClose: {
            Active: true,
            Line: true,
            LineColor: "#470985",
            Time: 5,
          },
        });
      } else {
        this.setState({
          newWeight,
          tradeVolume,
          orderArr: [...orderArr],
          brokerageValue: brokerage,
          stocksBuyValue: buyValue,
          stocksSellValue: sellValue,
        });
      }
    }
  }

  updateOrderArr(index, value) {
    const { orderArr, priceArr, data, sum, cashPosLive } = this.state;
    const { brokeragePercentage = 0.1 } = sum;

    this.setState({ insufficientCash: false });

    if (value !== 0 && (!value || value === undefined)) {
      value = "";
    }

    if (-value > data[index].Quantity) {
      Alert({
        TitleText: "Warning",
        Message: "Cannot sell more than current holding.",
        Band: true,
        BandColor: "#E51A4B",
        BoxColor: "#ffffff",
        TextColor: "#000000",
        AutoClose: {
          Active: true,
          Line: true,
          LineColor: "#E51A4B",
          Time: 5,
        },
      });

      return;
    }

    const clonedOrderArr = Array.from(orderArr);

    clonedOrderArr[index] = value;

    const { newWeight, tradeVolume, buyValue, sellValue } = ReCalculateWeight(
      data,
      clonedOrderArr,
      priceArr,
      sum
    );

    const brokerage =
      (Math.abs(buyValue) + Math.abs(sellValue)) *
      parseFloat(brokeragePercentage);
    // console.log("UPDATE", brokerage);

    if (buyValue !== 0 && cashPosLive - buyValue - brokerage < 0) {
      Alert({
        TitleText: "Warning",
        Message: `You don't have sufficient cash margin to proceed with the order!`,
        Band: true,
        BandColor: "#470985",
        BoxColor: "#ffffff",
        TextColor: "#000000",
        AutoClose: {
          Active: true,
          Line: true,
          LineColor: "#470985",
          Time: 5,
        },
      });
    } else {
      orderArr[index] = value;

      this.setState({
        newWeight,
        tradeVolume,
        orderArr: [...orderArr],
        brokerageValue: brokerage,
        stocksBuyValue: buyValue,
        stocksSellValue: sellValue,
        execute: false,
      });
    }
  }

  deleteStock(index) {
    let { data, orderArr, newWeight, dataLen, sum, priceArr } = this.state;

    data.splice(index, 1);
    orderArr.splice(index, 1);
    newWeight.splice(index, 1);
    dataLen = data.length;

    let res = ReCalculateWeight(data, orderArr, priceArr, sum);
    this.setState({
      data,
      orderArr,
      newWeight: res.newWeight,
      dataLen,
      tradeVolume: res.tradeVolume,
    });
  }

  async addTableRow(stock) {
    let { data, orderArr, newWeight, dataLen } = this.state;

    let code =
      stock.exchange.exchange.toLowerCase() === "nse"
        ? stock.nse_code
        : stock.code;

    if (!data.map((el) => el.StockCode).includes(code)) {
      const obj = {
        StockCode: code,
        StockName: stock.name,
        isin: stock.isin,
        Quantity: 0,
        AverageCost: 0,
        CostValue: 0,
        CurrentPrice: (
          await Axios.get(
            `${REQUEST_BASE_URL}/stock_price/${stock.exchange.exchange.toLowerCase()}/${code}`
          )
        ).data.CLOSE,
        CurrentValue: 0,
        PortfolioWeight: 0,
        TotalReturn: 0,
      };

      data.push(obj);
      dataLen = data.length;
      orderArr[dataLen - 1] = 0;
      newWeight[dataLen - 1] = false;

      this.setState({
        data,
        newWeight,
        orderArr,
        dataLen,
      });
    }
  }

  selectField(field) {
    const dynamicfields = this.state.dynamicFields;
    dynamicfields.forEach((d, indx) => {
      $(
        `.portfolio__container .portfolio__table table tbody tr td.${d}`
      ).removeClass("active");
    });
    field = field.split(" ").join("").toLowerCase();
    $(
      `.portfolio__container .portfolio__table table tbody tr td.${field}`
    ).addClass("active");
    // console.log(field);
  }

  setButtonPosition() {
    if (window.innerWidth <= 768) {
      setTimeout(() => {
        $(".portfolio__buttons").css("top", window.innerHeight - 110 + "px");
      }, 1000);
    }
  }

  placeOrder() {
    const {
      data,
      clientid,
      orderArr,
      currentportfolio,
      account_token,
      activeCustomer,
    } = this.state;
    const account_code = activeCustomer?.account_code;
    this.setState({ isPlacing: true });
    let stocks = [];
    data.forEach((el, i) => {
      if (orderArr[i] !== 0) {
        stocks.push({
          AccountCode: clientid,
          StockCode: el.StockCode,
          Company: el.StockName,
          isin: el.isin,
          Quantity: orderArr[i],
          Side: orderArr[i] < 0 ? "Sell" : "Buy",
          OrderType: "MARKET",
        });
      }
    });

    stocks.sort((a, b) => a.Quantity - b.Quantity);
    stocks.forEach((el) => (el.Quantity = Math.abs(el.Quantity)));

    if (stocks.length > 0) {
      Axios.post(`${REQUEST_BASE_URL}/order/bulk?token=${account_token}`, {
        account_code: account_code,
        stocks: stocks,
        portfolio: currentportfolio,
      }).then((res) => {
        if (!res.data.error) {
          this.setState(
            {
              activeCustomer: false,
              userportfolios: [],
              emptyportfolios: false,
              currentportfolio: false,
              data: [],
              originalLen: 0,
              portfolioWeightArr: [],
              orderArr: [],
              newWeight: [],
              changeArr: [],
              priceArr: [],
              dynamicFields: [
                "currprice",
                "buyprice",
                "cost",
                "currvalue",
                "return",
                "tradevolume",
                "today",
              ],
              cashPos: 0,
              cashPosVirtual: 0,
              cashPosLive: 0,
              tradeVolume: 0,
              brokerageValue: 0,
              stocksBuyValue: 0,
              stocksSellValue: 0,
              sum: null,
              totalReturnToday: "",
              totalReturnChange: 0,
              totalReturnPositive: false,
              isLoaded: false,
              reportdownload: false,
              activeElement: 0,
              dataLen: 0,
              openSmallcasePopup: false,
              execute: false,
              isPlacing: false,
            },
            () => {
              Alert({
                TitleText: "Order Routed Successfully!",
                Message: "Please visit transaction section to place order",
                Band: true,
                BandColor: "#67D098",
                BoxColor: "#ffffff",
                TextColor: "#000000",
                AutoClose: {
                  Active: true,
                  Line: true,
                  LineColor: "#67D098",
                  Time: 3,
                },
              });
              this.setCustomer(activeCustomer);
            }
          );
        } else {
          console.log("ERROR");
        }
        // console.log(res);
      });
    } else {
      this.setState({ isLoaded: true });

      Alert({
        TitleText: "Warning",
        Message: "Please select atleast one stock.",
        Band: true,
        BandColor: "#470985",
        BoxColor: "#ffffff",
        TextColor: "#000000",
        AutoClose: {
          Active: true,
          Line: true,
          LineColor: "#470985",
          Time: 3,
        },
      });
    }
  }

  setCustomer(customer) {
    this.setState(
      {
        activeCustomer: customer,
        isLoaded: false,
      },
      () => {
        if (this.state.activeCustomer) {
          const { account_code, customer_token } = this.state.activeCustomer;
          const { navigate } = this.props.router;
          // history.push(`?customer=${customer_token}`);
          navigate(`?customer=${customer_token}`);
          this.loadCustomer(account_code);
          this.loadCashPosition(account_code);
        }
      }
    );
  }

  setCashPosLive(live_cash) {
    this.setState({
      cashPosLive: live_cash,
    });
  }

  render() {
    const {
      user_role,
      user_role_type,
      data,
      newWeight,
      orderArr,
      originalLen,
      userportfolios,
      currentportfolio,
      sum,
      emptyportfolios,
      isLoaded,
      cashPos,
      cashPosVirtual,
      cashPosLive,
      totalReturnToday,
      totalReturnPositive,
      totalReturnChange,
      isCustomerLoaded,
      activeCustomer,
      customers,
      execute,
      brokerageValue,
      stocksBuyValue,
      stocksSellValue,
      isPlacing,
    } = this.state;

    const { location } = this.props.router;

    const currentPath = location.pathname;

    return (
      <div className="swift-portfolio-management swift-content">
        {/* <div className="portfolio-header">
          <p className="portfolio-title">Portfolio Management</p>
        </div> */}

        {!isCustomerLoaded ? (
          <>
            <div className="portfolio__container loader">
              <Pulse />
              <p>Loading Portfolio Management...</p>
            </div>
          </>
        ) : (
          <>
            {customers.length === 0 ? (
              <>
                <div className="portfolio__container empty">
                  <p>You don't have any active customers</p>
                </div>
              </>
            ) : (
              <>
                {isPlacing ? (
                  <>
                    <div className="portfolio__container loader">
                      <Pulse />
                      <p>Routing your order...</p>
                    </div>
                  </>
                ) : (
                  <>
                    {/* submenu */}
                    <div className="swift-submenu">
                      <PortfolioMenu
                        user_role_type={user_role_type}
                        activeCustomer={activeCustomer}
                      />
                    </div>
                    <div className="strategy-content">
                      {
                        <>
                          <Routes>
                            <>
                              {["view", "analysis"].map((path) => {
                                return (
                                  <Route
                                    key={path}
                                    path={path}
                                    element={
                                      <>
                                        <PortfolioHeader
                                          currentPath={currentPath}
                                          user_role_type={user_role_type}
                                          customers={customers}
                                          activeCustomer={activeCustomer}
                                          isLoaded={isLoaded}
                                          emptyportfolios={emptyportfolios}
                                          sum={sum}
                                          totalReturnChange={totalReturnChange}
                                          cashPos={cashPos}
                                          cashPosVirtual={cashPosVirtual}
                                          totalReturnToday={totalReturnToday}
                                          totalReturnPositive={
                                            totalReturnPositive
                                          }
                                          setCustomer={this.setCustomer}
                                          setCashPosLive={this.setCashPosLive}
                                          addTableRow={this.addTableRow}
                                        />

                                        {emptyportfolios ? (
                                          <>
                                            <div className="portfolio__container empty">
                                              <p>
                                                You don't have any portfolios
                                              </p>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className="portfolio__container">
                                              {activeCustomer &&
                                                !emptyportfolios && (
                                                  <>
                                                    {isLoaded ? (
                                                      <>
                                                        <PortfolioTableContainer
                                                          hide={
                                                            currentPath ===
                                                            "/account/portfolio/view"
                                                              ? false
                                                              : true
                                                          }
                                                          user_role_type={
                                                            user_role_type
                                                          }
                                                          customer={
                                                            activeCustomer
                                                          }
                                                          data={data}
                                                          originalLen={
                                                            originalLen
                                                          }
                                                          sum={sum}
                                                          totalReturnToday={
                                                            totalReturnToday
                                                          }
                                                          totalReturnChange={
                                                            totalReturnChange
                                                          }
                                                          selectField={
                                                            this.selectField
                                                          }
                                                          newWeight={newWeight}
                                                          orderArr={orderArr}
                                                          addOrderArr={
                                                            this.addOrderArr
                                                          }
                                                          updateOrderArr={
                                                            this.updateOrderArr
                                                          }
                                                          setIndividualChange={
                                                            this
                                                              .setIndividualChange
                                                          }
                                                          setIndividualPrice={
                                                            this
                                                              .setIndividualPrice
                                                          }
                                                          deleteStock={
                                                            this.deleteStock
                                                          }
                                                          execute={execute}
                                                          stocksBuyValue={
                                                            stocksBuyValue
                                                          }
                                                          stocksSellValue={
                                                            stocksSellValue
                                                          }
                                                          brokerageValue={
                                                            brokerageValue
                                                          }
                                                          cashPosVirtual={
                                                            cashPosLive
                                                          }
                                                          placeOrder={
                                                            this.placeOrder
                                                          }
                                                          executeOrder={() => {
                                                            this.setState({
                                                              execute: true,
                                                            });
                                                          }}
                                                        />

                                                        <PerformanceAnalytics
                                                          customer={
                                                            activeCustomer
                                                          }
                                                          portfolio={
                                                            currentportfolio
                                                          }
                                                          hide={
                                                            currentPath ===
                                                            "/account/portfolio/analysis"
                                                              ? false
                                                              : true
                                                          }
                                                        />
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div className="portfolio__table loader">
                                                          <Pulse />
                                                          <p>
                                                            Loading Portfolio...
                                                          </p>
                                                        </div>
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                            </div>
                                          </>
                                        )}
                                      </>
                                    }
                                  />
                                );
                              })}
                            </>

                            <Route
                              path="blanket"
                              element={
                                <ApplySmallcasePopup
                                  user_role={user_role}
                                  user_role_type={user_role_type}
                                  customers={customers}
                                  closePopup={() =>
                                    this.setState({ openSmallcasePopup: false })
                                  }
                                  userportfolios={userportfolios}
                                  currentportfolio={currentportfolio}
                                />
                              }
                            />

                            <Route
                              path="*"
                              element={
                                <Navigate to="/account/portfolio/view" />
                              }
                            />
                          </Routes>
                        </>
                      }
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

export default withRouter(PortfolioManagement);
