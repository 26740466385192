import React, { useState, useEffect } from "react";
import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import ServerRequest from "../../exports/ServerRequest";
import Pulse from "../Loader/Pulse";
import { numberFormat } from "../../exports/UtilFunctions";
import CustomIconButton from "../Body/CustomChartComponents/CustomIconButton/CustomIconButton";
import { COLOR_VARS } from "../../exports/ColorVars";
import { ArrowRight2 } from "../CustomComponents/SwiftIcon/Icons";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";
import ConfirmBox from "../CustomComponents/ConfirmBox/ConfirmBox";

function ManagedHoldings() {
  const [loading, setLoading] = useState(false);

  async function GetHoldings() {
    try {
      const data = await ServerRequest({
        method: "GET",
        url: `/order/holdings/managed`,
      });
      if(data.error){
        return [];
      }
      else{
        return data.data;
      }
    } catch (error) {
      return [];
    }
  }

  const { data: holdings, isLoading, refetch } = useQuery(
    ["managed-holdings"],
    GetHoldings
  );

  // useEffect(() => {
  //   setHoldings(undefined);
  //   async function GetHoldings() {
  //     try {
  //       const data = await ServerRequest({
  //         method: "GET",
  //         url: `/order/holdings/managed`,
  //       });
  //       return data;
  //     } catch (error) {
  //       setHoldings([]);
  //     }
  //   }

  //   GetHoldings()
  //     .then((response) => {
  //       console.log(response);
  //       const { error, data } = response;

  //       if (error) {
  //         setHoldings([]);
  //       } else {
  //         setHoldings(data);
  //       }
  //     })
  //     .catch((error) => {
  //       setHoldings([]);
  //     });
  // }, [trigger]);

  function ShowMessage(title, msg) {
    Alert({
      TitleText: title,
      Message: msg,
      Band: true,
      BandColor: "#028F76",
      BoxColor: "#ffffff",
      TextColor: "#000000",
      AutoClose: {
        Active: true,
        Line: true,
        LineColor: "#028F76",
        Time: 3,
      },
    });
  }

  function SubmitMismatchHoldings(customer, holdings) {
    const { account_code, broker, trading_code } = customer;
    const { stock, file_quantity, db_quantity, price } = holdings;
    const quantity = parseInt(file_quantity) - parseInt(db_quantity);
    const side = quantity > 0 ? "Buy" : "Sell";

    const stock_data = {
      Date: moment().format("YYYY-MM-DD"),
      AccountCode: account_code,
      StockCode: stock.symbol,
      Company: stock.company_name,
      Code: stock.instrument_token,
      isin: stock.isin,
      Series: stock.series,
      Exchange: stock.exchange.exchange,
      Quantity: Math.abs(quantity),
      Side: side,
      OrderType: "MARKET",
      LMPrice: "",
      Price: price,
      TradingCode: trading_code,
      BrokerCode: broker,
    };

    setLoading(true);

    ServerRequest({
      method: "POST",
      url: "/order/entry",
      data: {
        stocks: [stock_data],
      },
    })
      .then((response) => {
        ShowMessage("Success", "Order added successfully");
        refetch();
      })
      .catch((error) => {
        ShowMessage("Warning", "Something went wrong while placing the order");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function SubmitAllMismatchHoldings(callback) {
    callback();
    let stock_data = [];
    holdings.forEach((h) => {
      const { customer, holdings: data } = h;
      const { account_code, broker, trading_code } = customer;
      data.forEach((d) => {
        const { stock, file_quantity, db_quantity, price } = d;
        const quantity = parseInt(file_quantity) - parseInt(db_quantity);
        const side = quantity > 0 ? "Buy" : "Sell";
        stock_data.push({
          Date: moment().format("YYYY-MM-DD"),
          AccountCode: account_code,
          StockCode: stock.symbol,
          Company: stock.company_name,
          Code: stock.instrument_token,
          isin: stock.isin,
          Series: stock.series,
          Exchange: stock.exchange.exchange,
          Quantity: Math.abs(quantity),
          Side: side,
          OrderType: "MARKET",
          LMPrice: "",
          Price: price,
          TradingCode: trading_code,
          BrokerCode: broker,
        });
      });
    });

    console.log(stock_data);

    setLoading(true);

    ServerRequest({
      method: "POST",
      url: "/order/entry",
      data: {
        stocks: stock_data,
      },
    })
      .then((response) => {
        ShowMessage("Success", "Order added successfully");
        refetch();
      })
      .catch((error) => {
        ShowMessage("Warning", "Something went wrong while placing the order");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (isLoading) {
    return (
      <div className="managed-holdings loader">
        <Pulse />
        <p>Loading...</p>
      </div>
    );
  }

  if (Array.isArray(holdings) && holdings.length == 0) {
    return (
      <div className="managed-holdings empty">
        <p>No holdings found</p>
        <span>There are no holdings mismatch</span>
      </div>
    );
  }

  return (
    <div className="managed-holdings">
      {loading && (
        <>
          <div className="managed-holdings-loading">
            <Pulse />
            <p>Placing order, please wait...</p>
          </div>
        </>
      )}
      <div className="managed-holdings-table">
        <table>
          <thead>
            <tr>
              <td className="name">Customer Name</td>
              <td className="symbol">Symbol</td>
              <td className="db_qty">Database Quantity</td>
              <td className="api_qty">API Quantity</td>
              <td className="avg_price">Average Price</td>
              <td className="trade">Trade</td>
              <td className="apply"></td>
            </tr>
          </thead>
          <tbody>
            {holdings.map((h, index) => {
              return (
                <CustomerHoldings
                  key={index}
                  customer={h.customer}
                  holdings={h.holdings}
                  SubmitMismatchHoldings={SubmitMismatchHoldings}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="managed-holdings-button">
        <CustomIconButton
          icon={<ArrowRight2 size={20} color={COLOR_VARS["SWIFT_COLOR1"]} />}
          title="Apply All"
          onClick={() => {
            ConfirmBox({
              title: "Confirm Apply",
              description: (
                <>
                  Are you sure you want apply all orders?
                </>
              ),
              properties: [
                {
                  id: "2",
                  label: "Confirm",
                  color: "#192b40",
                  bgColor: "#ffffff",
                  handleFunction: SubmitAllMismatchHoldings,
                  functionArgs: [],
                },
              ],
              cancel: true,
            });
          }}
        />
      </div>
    </div>
  );
}

function CustomerHoldings({ customer, holdings, SubmitMismatchHoldings }) {
  function GetTradeDescription(db_qty, api_qty, avg_price) {
    const net_qty = api_qty - db_qty;

    if (net_qty == 0) return <span>-</span>;
    else if (net_qty > 0)
      return (
        <span className="positive">
          Buy #{Math.abs(net_qty)} @{numberFormat(avg_price)}
        </span>
      );
    else
      return (
        <span className="negative">
          Sell #{Math.abs(net_qty)} @{numberFormat(avg_price)}
        </span>
      );
  }

  return (
    <>
      {holdings.map((h, index) => {
        return (
          <tr key={index}>
            <td title={customer.name}>{customer.name}</td>
            <td>{h.stock.symbol}</td>
            <td>{h.db_quantity}</td>
            <td>{h.file_quantity}</td>
            <td>{numberFormat(h.price)}</td>
            <td>
              {GetTradeDescription(h.db_quantity, h.file_quantity, h.price)}
            </td>
            <td>
              <button
                className="apply-btn"
                onClick={() => {
                  SubmitMismatchHoldings(customer, h);
                }}
              >
                Apply
              </button>
            </td>
          </tr>
        );
      })}
    </>
  );
}

export default ManagedHoldings;
