import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import IndexItem from "./IndexItem";
import "../../../../css/Popup/IndexSelectPopup.css";
import { Cross, ICON_COLORS } from "../../../CustomComponents/SwiftIcon/Icons";
import { Alert } from "../../CustomChartComponents/CustomAlert/CustomAlert";
import { GetCookie } from "../../../../exports/CookieManagement";
import Pulse from "../../../Loader/Pulse";
import { getDeviceSize } from "../../../../exports/InteractiveUtils";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const DEVICE_SIZE = getDeviceSize();

function IndexSelectPopup({ indexOpen, max_index, indices, closeIndexPopup }) {
  const [loading, setLoading] = useState(true);
  const [currentIndices, setCurrentIndices] = useState(indices);
  const [allIndices, setAllIndices] = useState(undefined);
  const [sortedIndices, setSortedIndices] = useState(undefined);

  const indexRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (indexRef.current && !indexRef.current.contains(event.target)) {
      closeIndexPopup();
    }
  };

  useEffect(() => {
    async function GetAllIndices() {
      const response = await fetch(`${REQUEST_BASE_URL}/Indices`);
      const data = await response.json();
      if (data.error) {
        setAllIndices([]);
        return;
      }
      setAllIndices(data.data);
    }

    GetAllIndices();
  }, []);

  useEffect(() => {
    setCurrentIndices(indices);
  }, [indices]);

  useEffect(() => {
    if (allIndices === undefined || currentIndices === undefined) return;

    const indices_list = currentIndices.map((i) => {
      return i["index"];
    });

    const s_indices = currentIndices.map((c) => {
      const data = allIndices.find((a) => {
        return a["index"] === c["index"];
      });
      if (data) return { ...data, selected: true };
    });

    const u_indices = allIndices
      .filter((i) => {
        return !indices_list.includes(i["index"]);
      })
      .map((s) => {
        return { ...s, selected: false };
      });

    if (s_indices.length > 0) {
      setSortedIndices([...s_indices, ...u_indices]);
    } else {
      setSortedIndices([...s_indices, ...u_indices]);
    }

    setLoading(false);
  }, [currentIndices, allIndices]);

  function SaveIndices() {
    setLoading(true);
    const access_token = GetCookie("token");

    const index_list = sortedIndices
      .map((c, ind) => {
        return {
          index: c.index,
          order: ind + 1,
        };
      })
      .filter((s, index) => {
        return index < max_index;
      });

    Axios.post(`${REQUEST_BASE_URL}/SaveUserIndices?token=${access_token}`, {
      indexlist: index_list,
    })
      .then((response) => {
        console.log(response.data);
        Alert({
          TitleText: "Success",
          Message: "Configuration saved successfully",
          AutoClose: {
            Active: true,
            Line: true,
            LineColor: "#470985",
            Time: 3,
          },
        });
      })
      .catch((error) => {
        Alert({
          TitleText: "Failure",
          Message: "Something went wrong while saving",
          AutoClose: {
            Active: true,
            Line: true,
            LineColor: "#E51A4B",
            Time: 3,
          },
        });
      })
      .finally(() => {
        closeIndexPopup();
        setLoading(false);
      });
  }

  function HandleDragStart(e, index) {
    e.dataTransfer.setData("draggedIndex", index);
  }

  function HandleDragOver(e, index) {
    e.preventDefault();
  }

  function HandleDrop(e, index) {
    e.preventDefault();
    const draggedIndex = e.dataTransfer.getData("draggedIndex");
    const updated_list = [...sortedIndices];
    const [draggedItem] = updated_list.splice(draggedIndex, 1);
    updated_list.splice(index, 0, draggedItem);

    setSortedIndices([...updated_list]);
  }

  function HandleMove(direction = "U", index) {
    const new_index = direction === "U" ? index - 1 : index + 1;
    const updated_list = [...sortedIndices];
    [updated_list[index], updated_list[new_index]] = [
      updated_list[new_index],
      updated_list[index],
    ];
    setSortedIndices([...updated_list]);
  }

  return (
    <>
      <div
        className={
          indexOpen ? "index__select__popup" : "index__select__popup hide"
        }
        ref={indexRef}
      >
        {loading ? (
          <>
            <div className="index__select__loading">
              <Pulse />
            </div>
          </>
        ) : (
          <>
            <div className="index__select__header">
              <p>All indices</p>
              <div
                onClick={() => {
                  closeIndexPopup();
                }}
              >
                <Cross size={30} color={ICON_COLORS["SWIFT_COLOR4"]} />
              </div>
            </div>
            <div className="index__select__container">
              <>
                {Array.isArray(sortedIndices) && sortedIndices.length > 0 && (
                  <>
                    {sortedIndices.map((i, index) => {
                      return (
                        <React.Fragment key={`${i.index}-${index}`}>
                          <IndexItem
                            data={i}
                            list_index={index}
                            handleDragStart={HandleDragStart}
                            handleDragOver={HandleDragOver}
                            handleDrop={HandleDrop}
                            handleMove={HandleMove}
                            count={sortedIndices.length}
                            sticky={index < max_index}
                          />
                          {index + 1 === max_index && (
                            <>
                              <div
                                key="sep"
                                className="separator"
                                style={{
                                  position: "sticky",
                                  top: `${
                                    max_index * (DEVICE_SIZE === "S" ? 70 : 60)
                                  }px`,
                                }}
                              ></div>
                            </>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </>
                )}
              </>
            </div>
            <div className="index__select__footer">
              <button
                className="reset"
                type="button"
                onClick={() => {
                  setCurrentIndices([...indices]);
                }}
              >
                Reset
              </button>
              <button
                type="button"
                onClick={() => {
                  SaveIndices();
                }}
              >
                Save
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default IndexSelectPopup;
