import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Cross, UserProfile } from '../CustomComponents/SwiftIcon/Icons';
import SwiftToolTip from '../CustomComponents/SwiftToolTip/SwiftToolTip';
import FamilyStocksTable from './FamilyStocksTable';
import { numberFormat, titleCase } from '../../exports/UtilFunctions';
import { GetCookie } from '../../exports/CookieManagement';
import ConfirmBox from '../CustomComponents/ConfirmBox/ConfirmBox';
import { Alert } from '../Body/CustomChartComponents/CustomAlert/CustomAlert';
import SectorMarketChart from '../Body/MenuSection/PortfolioComponents/SectorMarketChart';
import Pulse from '../Loader/Pulse';
import { COLOR_VARS } from '../../exports/ColorVars';

const error_config = {
    Band: true,
    BandColor: '#470985',
    BoxColor: '#ffffff',
    TextColor: '#000000',
    AutoClose: {
        Active: true,
        Line: true,
        LineColor: '#470985',
        Time: 3
    }
}

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function FamilyPortfolioDetails({ portfolio, setRenameOpen, setCustomerOpen, update = () => { } }) {

    const { family_portfolio_id, family_portfolio_name, family_portfolio_customers } = portfolio;

    const [loading, setLoading] = useState(true);
    const [sectorData, setSectorData] = useState(false);
    const [portfolioDetails, setPortfolioDetails] = useState(false);
    const [portfolioFlag, setPortfolioFlag] = useState(false);
    const [portfolioData, setPortfolioData] = useState(false);

    useEffect(() => {
        setSectorData(false);
        setPortfolioDetails(false);
        setPortfolioData(false);
        GetPortfolioData();
        GetSectorData();
    }, [family_portfolio_id, portfolioFlag]);

    function GetPortfolioData() {
        setLoading(true);
        const account_token = GetCookie('token');

        Axios.get(`${REQUEST_BASE_URL}/family/portfolio?portfolio_id=${family_portfolio_id}&token=${account_token}`).then((response) => {
            const data = response.data;
            if (!data.error) {

                const { portfolioData, sum } = data.portfolio;
                setPortfolioDetails({...sum,originalValue: sum.lastValueSum});
                setPortfolioData(portfolioData);
                setLoading(false);
            }
            else {
                setPortfolioDetails(false);
                setPortfolioData(false);
                setLoading(false);
            }
        }).catch(() => {
            setPortfolioDetails(false);
            setPortfolioData(false);
            setLoading(false);
        });
    }

    function GetSectorData() {
        const account_token = GetCookie('token');

        Axios.get(`${REQUEST_BASE_URL}/family/sector?portfolio_id=${family_portfolio_id}&token=${account_token}`).then((response) => {
            const data = response.data;
            if (!data.error) {
                setSectorData(data.sector)
            }
            else {
                setSectorData([]);
            }
        }).catch(() => {
            setSectorData([]);
        });
    }

    function DeletFamilyPortfolio(callback) {
        const account_token = GetCookie('token');

        Axios.delete(`${REQUEST_BASE_URL}/family?portfolio_id=${family_portfolio_id}&token=${account_token}`).then((response) => {
            const data = response.data;
            if (!data.error) {
                Alert({
                    TitleText: 'Family Portfolio Deleted',
                    Message: 'Portfolio deleted successfully',
                    Band: true,
                    BandColor: '#67D098',
                    BoxColor: '#ffffff',
                    TextColor: '#000000',
                    AutoClose: {
                        Active: true,
                        Line: true,
                        LineColor: '#67D098',
                        Time: 3
                    }
                });
                update(true);
                callback();
            }
            else {
                Alert({
                    TitleText: 'Warning',
                    Message: 'Something went wrong while deleting the portfolio',
                    ...error_config
                })
                callback();
            }
        }).catch(() => {
            Alert({
                TitleText: 'Warning',
                Message: 'Something went wrong while deleting the portfolio',
                ...error_config
            })
            callback();
        });
    }

    function DeleteFamilyCustomer(portfolio_customer, callback) {

        if (!portfolio_customer) {
            callback();
            return;
        };

        const account_token = GetCookie('token');

        Axios.post(`${REQUEST_BASE_URL}/family/deletecustomer?token=${account_token}`, {
            portfolio_id: family_portfolio_id,
            portfolio_customer: portfolio_customer
        }).then((response) => {
            const data = response.data;
            if (!data.error) {
                Alert({
                    TitleText: 'Customer Removed',
                    Message: 'Customer successfully removed from portfolio',
                    Band: true,
                    BandColor: '#67D098',
                    BoxColor: '#ffffff',
                    TextColor: '#000000',
                    AutoClose: {
                        Active: true,
                        Line: true,
                        LineColor: '#67D098',
                        Time: 3
                    }
                });
                setPortfolioFlag(!portfolioFlag);
                callback();
                update(true);
            }
            else {
                Alert({
                    TitleText: 'Warning',
                    Message: 'Something went wrong while removing the customer',
                    ...error_config
                })
                callback();
            }
        }).catch(() => {
            Alert({
                TitleText: 'Warning',
                Message: 'Something went wrong while removing the customer',
                ...error_config
            })
            callback();
        });
    }

    function SetIndividualPrice(index, value) {
        if (value && value !== undefined && index < portfolioData.length) {
            const data = portfolioData;
            data[index]['CurrentPrice'] = value;
            setPortfolioData([...data]);
        }
    }

    function CalculateReturns() {

        if (!portfolioData || portfolioData.length === 0 || !portfolioDetails) return;

        const { costValueSum,macCash,originalValue } = portfolioDetails;

        let current_value_sum = 0;
        portfolioData.forEach((p) => {
            current_value_sum += (p['Quantity'] * p['CurrentPrice']);
        });

        const final_portfolio_value = current_value_sum + parseFloat(macCash);

        const profit_loss = final_portfolio_value - costValueSum;
        const current_returns = (profit_loss * 100) / costValueSum;

        const today_profit_loss = current_value_sum - parseFloat(originalValue);
        const today_returns = parseFloat((100*today_profit_loss)/current_value_sum);

        setPortfolioDetails({
            ...portfolioDetails,
            portfolioValue: final_portfolio_value,
            profitLoss: profit_loss,
            totalReturnSum: current_returns,
            todayProfitLoss: today_profit_loss,
            todayReturns: today_returns
        });

    }

    useEffect(() => {
        CalculateReturns();
    }, [portfolioData]);

    const { costValueSum = 0, portfolioValue = 0, totalReturnSum = 0, profitLoss = 0,todayProfitLoss=0,todayReturns=0 } = portfolioDetails;

    const color_class = parseFloat(profitLoss) < 0 ? 'negative' : 'positive';
    const today_color_class = parseFloat(todayProfitLoss) < 0 ? 'negative': 'positive';

    return (
        <div className='family-portfolio-details'>
            {loading ? <>
                <div className='family-portfolio-loading'>
                    <Pulse />
                    <p>Loading family portfolio details...</p>
                </div>
            </>
                :
                <>
                    <div className='family-details-section'>
                        <div className='family-name-options'>
                            <div>
                                <p className='family-name'>{family_portfolio_name}</p>
                            </div>
                            <div className='family-options'>
                                <span onClick={() => { setCustomerOpen(true) }}>Add Customer</span>
                                <span onClick={() => { setRenameOpen(true) }}>Rename Portfolio</span>
                                <span onClick={() => {
                                    ConfirmBox({
                                        title: 'Delete Family Portfolio',
                                        description: (<>Are you sure you want to delete the portfolio named <strong>{family_portfolio_name}</strong>?<br /> Keep in mind that by doing so, all customers within the portfolio will be removed.</>),
                                        properties: [
                                            {
                                                id: '2',
                                                label: 'Delete',
                                                color: '#192b40',
                                                bgColor: '#ffffff',
                                                handleFunction: DeletFamilyPortfolio
                                            },
                                        ],
                                        cancel: true
                                    })
                                }}>Delete Portfolio</span>
                            </div>
                        </div>
                        {family_portfolio_customers.length <= 0 ? <>
                            <div className='family-portfolio-customers-empty'>
                                <UserProfile size={60} color={COLOR_VARS['SWIFT_COLOR4']} />
                                <p>You don't have any customers in this portfolio</p>
                                <button onClick={() => { setCustomerOpen(true) }}>Add New Customer</button>
                            </div>
                        </> :
                            <>
                                <div className='family-portfolio-customers'>
                                    <p className='family-details-title'>Added Customers ({family_portfolio_customers.length})</p>
                                    <div className='customers-wrapper'>

                                        {family_portfolio_customers.length === 0 ? <>
                                            <></>
                                        </> : <>
                                            {family_portfolio_customers.map((c, index) => {
                                                return <div key={index} className='customer-box'>
                                                    <p className='customer-name'>{titleCase(c.customer_name)}</p>
                                                    <SwiftToolTip title='Remove Customer' placement='bottom' >
                                                        <div className='customer-remove' onClick={() => {
                                                            ConfirmBox({
                                                                title: 'Remove Portfolio Customer',
                                                                description: (<>Are you sure you want to remove the customer named <strong>{titleCase(c.customer_name)}</strong>? <br />This action cannot be undone and all associated data will be permanently removed.</>),
                                                                properties: [
                                                                    {
                                                                        id: '2',
                                                                        label: 'Remove',
                                                                        color: '#192b40',
                                                                        bgColor: '#ffffff',
                                                                        handleFunction: DeleteFamilyCustomer,
                                                                        functionArgs: [c.customer_account_code]
                                                                    },
                                                                ],
                                                                cancel: true
                                                            })
                                                        }}>
                                                            <Cross size={20} color="#192b40" />
                                                        </div>
                                                    </SwiftToolTip>
                                                </div>
                                            })}
                                        </>}
                                    </div>
                                </div>
                                <div className='family-portfolio-config'>
                                    <div className='family-config-box'>
                                        <p className='family-details-title'>Total Investment Value</p>
                                        <span>{numberFormat(costValueSum)}</span>
                                    </div>
                                    <div className='family-config-box'>
                                        <p className='family-details-title'>Total Portfolio Value</p>
                                        <span>{numberFormat(portfolioValue)}</span>
                                    </div>
                                    <div className='family-config-box'>
                                        <p className='family-details-title'>Total Portfolio Returns</p>
                                        <span className={color_class}>{numberFormat(profitLoss)}&nbsp;
                                            ({numberFormat(totalReturnSum)}%)
                                        </span>
                                    </div>
                                    <div className='family-config-box'>
                                        <p className='family-details-title'>Portfolio Returns Today</p>
                                        <span className={today_color_class}>{numberFormat(todayProfitLoss)}&nbsp;
                                            ({numberFormat(todayReturns)}%)
                                        </span>
                                    </div>
                                    <div className='family-config-box'>
                                        <p className='family-details-title'>Total Cash Position</p>
                                        <span>{numberFormat(portfolioDetails?.cashValue)}&nbsp;({numberFormat(portfolioDetails?.cashValueVirtual)})</span>
                                    </div>
                                </div>
                                <div className='family-portfolio-stocks'>
                                    <p className='family-details-title'>Portfolio Structure</p>
                                    <FamilyStocksTable portfolio={portfolioData} SetIndividualPrice={SetIndividualPrice} />
                                </div>
                            </>
                        }
                    </div>
                    {family_portfolio_customers.length > 0 && sectorData && <>
                        <div className='family-portfolio-charts'>
                            <div className='family-sector'>
                                {sectorData.industryTable ? <>
                                    <p className='family-details-title'>Sector Weights</p>
                                    <SectorMarketChart values={sectorData['industryTable']} unit="Inv. Value Rs." />
                                </> : <>

                                </>}
                            </div>
                            {/* <div className='family-market'>
                                {sectorData.marketCapTable ? <>
                                    <p className='family-details-title'>Market Cap Breakdown</p>
                                    <SectorMarketChart values={sectorData['marketCapTable']} unit="Stocks" />
                                </> : <>

                                </>}
                            </div> */}
                        </div>
                    </>}
                </>

            }
        </div>
    )
}

export default FamilyPortfolioDetails