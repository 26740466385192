import React, { useState } from "react";
import { Link } from "react-router-dom";
import StockDetailsPopup from "../AppPopups/StockDetailsPopup/StockDetailsPopup";
import OptionsList from "../../../assets/icons/options-list.svg";
import Delete from "../../../assets/icons/delete-bin.svg";
import Select from "../../../assets/icons/select-graph.svg";

import useMarketStock from "../../../hooks/useMarketStock";
import SwiftModal from "../../CustomComponents/SwiftModal/SwiftModal";
import useStockInfo from "../../../hooks/useStockInfo";
import { numberFormat } from "../../../exports/UtilFunctions";
import OrderPopup from "../AppPopups/StockOrderPopup/OrderPopup";

function WatchListItem({
  item,
  index,
  handleDragStart,
  handleDragOver,
  handleDrop,
  deleteItem,
}) {
  const [orderOpen, setOrderOpen] = useState(false);

  const [marketDataOpen, setMarketDataOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const { symbol, quantity = 0, avg_buy_value, total_returns } = item;

  const stock_data = useMarketStock(symbol);
  const { loading, stock_info } = useStockInfo(symbol);

  const PriceFormat = (price) => {
    return parseFloat(price).toLocaleString(false, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const { company = symbol } = stock_info;

  let last_traded_price = "0";
  let change_price = "0";
  let change_percentage = "0";

  if (stock_data) {
    last_traded_price = stock_data.last_traded_price;
    change_price = stock_data.change_price;
    change_percentage = stock_data.change_percentage;
  }

  // const { last_traded_price=0, change_price=0, change_percentage=0 } = stock_data;

  const priceClass = change_price.includes("-") ? "negative" : "positive";

  let stocks_returns =
    quantity == 0 || avg_buy_value == 0 || last_traded_price == 0
      ? total_returns
      : (last_traded_price / avg_buy_value - 1) * 100;

  return (
    <>
      {marketDataOpen && (
        <>
          <SwiftModal
            top="5%"
            closeModal={() => {
              setMarketDataOpen(false);
            }}
          >
            <StockDetailsPopup
              stock={item}
              closePopup={() => {
                setMarketDataOpen(false);
              }}
            />
          </SwiftModal>
        </>
      )}

      {orderOpen && (
        <>
          <OrderPopup
            closeModal={() => {
              setOrderOpen(false);
            }}
            config={{
              side: orderOpen,
              symbol: symbol,
              squareoff: false,
            }}
          />
        </>
      )}

      <div
        className="share__profile__wrapper"
        draggable
        onDragStart={(e) => {
          handleDragStart(e, index);
        }}
        onDragOver={(e) => handleDragOver(e, index)}
        onDrop={(e) => handleDrop(e, index)}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <div className="share__profile">
          <div className="sp__info">
            <div className="sp__name">{symbol}</div>
            <div className="sp__fullname">
              {quantity > 0 ? (
                <>
                  {quantity}&nbsp; @&nbsp;{numberFormat(avg_buy_value)}&nbsp;{" "}
                  <span
                    className={stocks_returns >= 0 ? "positive" : "negative"}
                  >
                    ({numberFormat(stocks_returns)}%)
                  </span>
                </>
              ) : (
                <>{company}</>
              )}
            </div>
          </div>
          <div className="sp__price">
            <div className="cp__price">
              <span>{PriceFormat(last_traded_price)}</span>
            </div>
            <div className="sp__change">
              <div className={priceClass + " cp__change"}>
                <span>{change_price}</span>
              </div>
              <div className={priceClass + " cp__change__per"}>
                <span>({change_percentage})</span>
              </div>
            </div>
          </div>
          {hover ? (
            <>
              <div className="sp__hover__options">
                <div
                  className="sp__hover__buttons"
                  onClick={() => setOrderOpen("B")}
                >
                  <span>B</span>
                </div>
                <div
                  className="sp__hover__buttons"
                  onClick={() => setOrderOpen("S")}
                >
                  <span>S</span>
                </div>
                {/* <div
                  className="sp__hover__buttons"
                  onClick={() => setMarketDataOpen(true)}
                >
                  <img src={OptionsList} />
                </div> */}
                <Link to={`?symbol=${symbol}`}>
                  <div className="sp__hover__buttons">
                    <img src={Select} />
                  </div>
                </Link>
                <div
                  className="sp__hover__buttons"
                  onClick={() => deleteItem(index)}
                >
                  <img src={Delete} />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default WatchListItem;
