import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/Header/Header.css";
import SearchTabs from "./SearchTabs";
import Search from "../../assets/icons/search.svg";
import Pulse from "../Loader/Pulse";
import TopStocks from "../Body/TopStocks/TopStocks";
import HeaderOptions from "./HeaderOptions";
import { GetHistory } from "../../exports/HistoryStorage";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

class HighLightText extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const text = this.props.text;
    const query = this.props.query;
    const customClass = this.props.customClass;
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return (
      <span>
        {" "}
        {parts.map((part, i) => (
          <span
            key={i}
            className={
              part.toLowerCase() === query.toLowerCase() ? `${customClass}` : ""
            }
          >
            {part.toUpperCase()}
          </span>
        ))}{" "}
      </span>
    );
    // return <span>{text}</span>
  }
}

function StockSuggestion({
  search,
  loading,
  history,
  suggestions,
  ClearSearchBox,
}) {
  const navigate = useNavigate();

  function OpenSymbol(symbol) {
    navigate(`/account/strategy/stock?symbol=${symbol}`);
    ClearSearchBox();
  }

  if (suggestions === undefined) {
    suggestions = [];
  }

  if (Array.isArray(suggestions) && suggestions.length === 0) {
    suggestions = suggestions.concat(history);
  }

  if (
    loading ||
    (search !== "" && JSON.stringify(suggestions) === JSON.stringify(history)) ||
    (!loading && !Array.isArray(suggestions))
  ) {
    return (
      <div className="search__loader">
        <Pulse />
        <p>Loading Stocks Please Wait ...</p>
      </div>
    );
  }

  if (Array.isArray(suggestions) && suggestions.length > 0) {
    return (
      <>
        {suggestions.map((s, i) => {
          return (
            <p
              key={`${s.symbol}=${s.code}`}
              onClick={() => {
                OpenSymbol(s.symbol);
              }}
            >
              <span>{s.symbol}</span>
              <HighLightText
                text={s.company}
                query={search}
                customClass="search__highlight"
              />
              <span>{s.exchange.exchange}</span>
            </p>
          );
        })}
      </>
    );
  }

  return (
    <div className="search__loader">
      {search.length === 0 ? (
        <>
          <Pulse />
          <p>No recent searches</p>
          <p>Hit Me...</p>
        </>
      ) : (
        <p>
          No result for &nbsp;<strong>'{search.toUpperCase()}'</strong>
        </p>
      )}
    </div>
  );
}

function Header({ is_visible, setAccountCode, account_info }) {
  const ref = useRef(null);

  const [search, setSearch] = useState("");
  const [searchTab, setSearchTab] = useState("stocks");
  const [suggestionOpen, setSuggestionOpen] = useState(false);
  const [indexOpen, setIndexOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestion] = useState(undefined);
  const [history, setHistory] = useState(undefined);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!account_info || !account_info.account_code) return;

    const hist = GetHistory(account_info.account_code);
    setHistory(hist);
  }, [account_info]);

  useEffect(() => {
    setLoading(true);

    async function GetStockSuggestions() {
      setLoading(false);
      if (search === "") return [];

      const type_object = {
        stocks: "stocks",
        futures: "fut",
        call: "ce",
        put: "pe",
      };

      const type = type_object[searchTab];
      const response = await fetch(
        `${REQUEST_BASE_URL}/stock/${search}/${type}`
      );
      const data = await response.json();
      return data.suggestions;
    }

    const triggerAPICall = setTimeout(async () => {
      const data = await GetStockSuggestions();
      setSuggestion(data);
      setLoading(false);
    }, 500);

    return () => {
      clearTimeout(triggerAPICall);
      setSuggestion(undefined);
    };
  }, [search, searchTab]);

  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      setSuggestionOpen(false);
      setSearch("");
      setSuggestion(undefined);
    }
  }

  function ClearSearchBox() {
    setSearch("");
    setSuggestion([]);
    setSuggestionOpen(false);
  }


  return (
    <div className={"swift-header " + (is_visible ? 'active': '')}>
      <div className="stock__search__icons__wrapper" ref={ref}>
        <div className="stock__search">
          <div className="stock__search__icon">
            <img src={Search} alt="" />
          </div>
          <input
            placeholder="Search Stock"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onClick={() => {
              setSuggestionOpen(true);
            }}
          />

          {suggestionOpen && (
            <>
              <div className="stock__suggestions">
                <SearchTabs tab={searchTab} changeActiveTab={setSearchTab} />
                <div className="suggestion__stocks">
                  <StockSuggestion
                    search={search}
                    loading={loading}
                    history={history}
                    suggestions={suggestions}
                    ClearSearchBox={ClearSearchBox}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="swift-header__top">
        <TopStocks is_visible={is_visible} indexOpen={indexOpen} setIndexOpen={setIndexOpen} />
        <HeaderOptions
          setIndexOpen={setIndexOpen}
          setAccountCode={setAccountCode}
        />
      </div>
    </div>
  );
}

export default Header;
