import React, { useState, useEffect } from "react";
import {
  DeleteCookie,
  GetCookie,
} from "../../exports/CookieManagement";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useNavigate } from "react-router-dom";
import SwiftToolTip from "../CustomComponents/SwiftToolTip/SwiftToolTip";
import ConfirmBox from "../CustomComponents/ConfirmBox/ConfirmBox";
import { titleCase } from "../../exports/UtilFunctions";
import GetProfileColor from "../../exports/GetProfileColor";
import ServerRequest from "../../exports/ServerRequest";
import { useLocation } from "react-router-dom";

const AdminHeader = ({menu_array}) => {
  const [accountOpen, setAccountOpen] = useState(false);
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const location = useLocation();

  const token = GetCookie("admin_token");
  function LogoutUser(callback) {
    setTimeout(() => {
      callback();
      DeleteCookie("admin_token");
      navigate("/");
    }, 3000);
  }

  const GetAccountData = async () => {
    setLoading(true);

    const data1 = await ServerRequest({
      url: `/admin/superadmin?token=${token}`,
      method: "get",
    });
    if (data1.server_error) {
      navigate("/404", { state: { errorMessage: data1.message } });
    }
    setAccount(data1.data);
    setName(data1.data.name);
    setLoading(false);
  };
  useEffect(() => {
    GetAccountData();
  }, []);

  useEffect(() => {
    const pathname = location.pathname;

    const menu_array_flat = menu_array.map((m)=>{return m['options']}).flat();

    const menu = menu_array_flat.find((m)=>{
      return pathname.includes(m.path);
    });

    if(!menu){
      setTitle("");
      return;
    }

    setTitle(menu.name);

  }, [location.pathname]);

  return (
    <div className="header-options">
      <p className="title">Swiftfolios Admin {title && <>({title})</>}</p>
      <SwiftToolTip
        title={name ? titleCase(name) : "Account"}
        placement="bottom"
      >
        <div
          className="header-options-menu hide"
          onClick={() => {
            setAccountOpen(!accountOpen);
          }}
        >
          {name ? (
            <>
              <div className="account-logo">
                {name.slice(0, 1).toUpperCase()}
              </div>
            </>
          ) : (
            <>
              <PersonOutlinedIcon
                style={{ color: "#808080" }}
                fontSize="small"
              />
            </>
          )}

          {accountOpen && (
            <>
              <div
                className="accounts-wrapper"
                onMouseLeave={() => {
                  setAccountOpen(false);
                }}
              >
                {loading ? (
                  <>
                    <div className="accounts-list-loader">
                      <p>Loading accounts...</p>
                    </div>
                  </>
                ) : (
                  <>
                    {account === null ? (
                      <></>
                    ) : (
                      <>
                        <div className="accounts-list">
                          <div className="account-row">
                            <div className="account-icon">
                              <div
                                className="account-profile"
                                style={{
                                  background: GetProfileColor(account.name),
                                }}
                              >
                                <span>
                                  {account?.name.slice(0, 1).toUpperCase()}
                                </span>
                              </div>
                            </div>
                            <div className="account-info">
                              <p className="account-name">
                                {titleCase(account.name)}
                              </p>
                              <p className="account-role">Super Admin</p>
                            </div>
                          </div>
                        </div>
                        <div className="menu-line"></div>
                      </>
                    )}
                  </>
                )}

                <div
                  className="logout-button"
                  onClick={() => {
                    ConfirmBox({
                      title: "Logout",
                      description: (
                        <>
                          Are you sure you want to logout? You will be
                          redirected to the home screen.
                        </>
                      ),
                      properties: [
                        {
                          id: "2",
                          label: "Logout",
                          color: "#192b40",
                          bgColor: "#ffffff",
                          handleFunction: LogoutUser,
                          functionArgs: [],
                        },
                      ],
                      cancel: true,
                    });
                  }}
                >
                  <p>Sign out</p>
                </div>
              </div>
            </>
          )}
        </div>
      </SwiftToolTip>
    </div>
  );
};

export default AdminHeader;
