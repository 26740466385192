import React, { Component } from 'react';
import Axios from 'axios';

import '../../../../css/Popup/SmallCaseConfigurePopup.css';
import CustomSelect from '../../CustomChartComponents/CustomSelect/CustomSelect';
import Cross from '../../../../assets/icons/closesmall.svg'
import ArrowDown from '../../../../assets/icons/ArrowDown.svg'


const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

class SmallCaseConfigurePopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pannumber: this.props.pannumber,
            smallcase_id: this.props.smallcase_id,
            clientId: this.props.clientid,
            name: this.props.name,
            newPortfolio: false,
            portfolio: this.props.modify ? this.props.portfolio : "",
            frequency: this.props.modify ? this.props.frequency : "",
            capital: this.props.modify ? this.props.capital : "",
            customCapital: false,
            mincash: 0,
            userportfolios: [],
        }

        this.ApplySmallcase = this.ApplySmallcase.bind(this)
    }

    componentDidMount() {
        const { pannumber, smallcase_id } = this.state;
        Axios.get(`${REQUEST_BASE_URL}/clientinfo/portfolio?pan=${pannumber}`)
            .then((response) => {
                const data = response.data;
                if (!data.error)
                    this.setState({
                        userportfolios: data.portfolio
                    })
            })
            .catch(err => console.log(err))

        Axios.get(`${REQUEST_BASE_URL}/smallcase/min_cash?smallcase_id=${smallcase_id}`)
            .then(response => this.setState({ mincash: response.data.min_cash }))
            .catch(err => console.log(err))

    }

    ApplySmallcase() {
        const { smallcase_id, pannumber, clientId, portfolio, frequency, capital } = this.state;
        const { modify } = this.props;

        Axios.post(`${REQUEST_BASE_URL}/smallcase/apply_config`, { smallcase_id, portfolio, frequency, capital, pannumber, clientId, modify })
            .then(res => {
                if (res.data.success) {
                    this.props.closePopup()
                }
            })
            .catch(err => console.log(err))
    }

    render() {
        const curr = (val) => val.toLocaleString('en-IN', { style: 'currency', currency: 'INR', maximumFractionDigits: 0 })
        const getPortfolioOption = () => ["New Portfolio", ...this.state.userportfolios]
        const getFrequencyOption = () => ["Weekly", "Monthly", "Quaterly"]
        const getCapitalOption = () => {
            const min = this.state.mincash
            let options = []
            for (let i = 0; i < 100; i++) {
                options.push(curr(min * i / 10 + min))
            }
            if (this.props.modify) {
                options.push(curr(this.state.capital));
            }
            return ["Custom", ...options];
        }
        return (
            <div className='smallcase__configure__popup'>
                <div className='sc__popup__overlay'>
                    <div className='sc__popup__content'>
                        <div className='sc__header'>
                            <div className='heading'>
                                <p>SmallCase SIP</p>
                            </div>
                            <div className='close' onClick={this.props.closePopup}>
                                <img src={Cross} width={10} />
                            </div>
                        </div>
                        <div className='sc__body'>
                            <div className='row'>
                                <span>Smallcase Startegy</span>
                                <div>
                                    <p>{this.state.name}</p>
                                </div>
                            </div>
                            <div className='row' style={{ position: 'relative' }}>
                                <span>Portfolio</span>
                                {this.props.modify ?
                                    <div>
                                        <p>{this.state.portfolio}</p>
                                    </div>
                                    :
                                    <>
                                        {this.state.newPortfolio ?
                                            <>
                                                <input
                                                    className='new__portfolio'
                                                    value={this.state.portfolio}
                                                    onChange={(e) => this.setState({ portfolio: e.target.value })}
                                                />
                                                <span style={{ position: 'absolute', right: '10px' }}
                                                    onClick={(e) => this.setState({ portfolio: "", newPortfolio: false })}
                                                ><img src={ArrowDown} width={20} /></span>
                                            </>
                                            :
                                            <CustomSelect
                                                defaultIndex={getPortfolioOption().indexOf(this.state.portfolio)}
                                                options={getPortfolioOption()}
                                                width={240}
                                                height={40}
                                                placeholder="Select Portfolio"
                                                onTypeChange={(value) => {
                                                    if (value === "New Portfolio") {
                                                        this.setState({
                                                            newPortfolio: true,
                                                            portfolio: ""
                                                        }, () => {
                                                            document.getElementsByClassName('new__portfolio')[0].focus()
                                                        })
                                                    } else {
                                                        this.setState({ portfolio: value })
                                                    }
                                                }}
                                            />
                                        }
                                    </>
                                }
                            </div>
                            <div className='row'>
                                <span>Frequency</span>
                                <CustomSelect
                                    defaultIndex={getFrequencyOption().indexOf(this.state.frequency)}
                                    options={getFrequencyOption()}
                                    width={240}
                                    height={40}
                                    placeholder="Select Frequency"
                                    onTypeChange={(value) => this.setState({ frequency: value })}
                                />
                            </div>
                            <div className='row' style={{ position: 'relative' }}>                         
                                <span>Capital</span>
                                {this.state.customCapital ?
                                    <>
                                        <input
                                            className='custom__capital'
                                            value={this.state.capital}
                                            onChange={(e) => this.setState({ capital: isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value) })}
                                        />
                                        <span style={{ position: 'absolute', right: '10px' }}
                                            onClick={(e) => this.setState({ capital: "", customCapital: false })}
                                        ><img src={ArrowDown} width={20} /></span>
                                    </>
                                    :
                                    <CustomSelect
                                        defaultIndex={getCapitalOption().indexOf(curr(this.state.capital))}
                                        options={getCapitalOption()}
                                        width={240}
                                        height={40}
                                        placeholder="Select Capital"
                                        onTypeChange={(value) => {
                                            if (value === "Custom") {
                                                this.setState({
                                                    customCapital: true,
                                                    capital: ""
                                                }, () => {
                                                    document.getElementsByClassName('custom__capital')[0].focus()
                                                })
                                            } else {
                                                this.setState({ capital: parseFloat(value.replace(/[^\d\.]/g, '')) })
                                            }
                                        }}
                                    />
                                }
                            </div>
                        </div>
                        <div className='sc__footer'>
                            <div className='apply__button' onClick={() => this.ApplySmallcase()}>
                                {this.props.modify ? "Update SIP" : "Start SmallCase"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SmallCaseConfigurePopup;