import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { createRoot } from "react-dom/client";
import { COLOR_VARS } from "../../../../exports/ColorVars";
import { Cross } from "../../../CustomComponents/SwiftIcon/Icons";
import "../../../../css/CustomComponents/CustomAlert.css";


const Alert = (config) => {
  const containerDOMNode = document.createElement("div");
  containerDOMNode.classList.add("custom-alert-box");
  document.body.appendChild(containerDOMNode);
  const root = createRoot(containerDOMNode);
  root.render(<AlertBox DOMRef={containerDOMNode} {...config} />);
};

function AlertBox(props) {
  const { DOMRef, TitleText, Message, BoxColor, TextColor, AutoClose } = props;
  const { Active, Line, Time } = AutoClose;

  useEffect(() => {
    setTimeout(() => {
      DOMRef.classList.add("active");
    }, 10);

    AutoClosePopup(DOMRef, AutoClose);
  }, []);

  function AutoClosePopup(DOMRef, AutoClose) {
    const { Active, Time } = AutoClose;

    if (Active) {
      setTimeout(() => {
        ClosePopup(DOMRef);
      }, Time * 1000);
    }
  }

  function ClosePopup(DOMRef) {
    DOMRef.classList.remove("active");
    setTimeout(() => {
      DOMRef &&
        document.body.contains(DOMRef) &&
        document.body.removeChild(DOMRef);
    }, 100);
  }

  return (
    <>
      <div
        className="custom-alert-box-container"
        style={{ backgroundColor: `${BoxColor}`, color: `${TextColor}` }}
      >
        <div className="custom-alert-content">
          <div className="custom-alert-text">
            <h6>{TitleText}</h6>
            <p>{Message}</p>
          </div>
          <div
            className="custom-alert-close"
            onClick={() => {
              ClosePopup(DOMRef);
            }}
          >
            <Cross size={26} color={COLOR_VARS["SWIFT_COLOR4"]} />
          </div>
        </div>
        {Active && Line && (
          <div
            className="custom-alert-bottom-line"
            style={{
              backgroundColor: `transparent`,
              animation: `animateLine ${Time}s linear forwards`,
            }}
          ></div>
        )}
      </div>
    </>
  );
}

AlertBox.propTypes = {
  TitleText: PropTypes.string.isRequired,
  Message: PropTypes.string.isRequired,
  Band: PropTypes.bool,
  BandColor: PropTypes.string,
  BoxColor: PropTypes.string,
  TextColor: PropTypes.string,
  AutoClose: PropTypes.object,
};

AlertBox.defaultProps = {
  TitleText: "Warning",
  Message: "Something Wrong",
  Band: true,
  BandColor: COLOR_VARS["SWIFT_COLOR1"],
  BoxColor: "#ffffff",
  TextColor: "#404040",
  AutoClose: {
    Active: false,
    Line: false,
  },
};

export { Alert };
