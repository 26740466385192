import React, { useState, useRef } from 'react';
import axios from 'axios';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function UploadPAN({ pannumber, saveLocation }) {

    const [file, setFile] = useState(false);
    const fileInputRef = useRef(null);

    const handleFileChange = async () => {
        setFile(fileInputRef.current.files[0])
    }

    const handleFileUpload = async () => {

        const file = fileInputRef.current.files[0];
        const formData = new FormData();
        formData.append('file', file);

        axios.post(`${REQUEST_BASE_URL}/kyc/uploadpan?pan=${pannumber}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                if (!response.data.error && response.data.link) {
                    saveLocation((response.data.link));
                }
            })
            .catch((error) => {
                console.error(error);
            });

    }


    return (
        <>
            <div className='upload-pan-wrapper'>
                <input
                    type="file"
                    id="fileInput"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    accept=".png,.jpg,.jpeg,.pdf"
                />
                <button className='choose-button' onClick={() => fileInputRef.current.click()}>Browse PAN</button>
                {/* <button className='upload-button' onClick={() => fileInputRef.current.click()}>Upload PAN</button> */}
                {file && file?.name ? <>
                    <p title={file?.name} className='file-name'>{file?.name}</p>
                </> : <>
                    <p className='file-name light'>No file selected</p>
                </>}
            </div>
            {file && <>
                <button className='upload-button' onClick={() => { handleFileUpload() }}>Upload</button>
            </>}
        </>
    );
}

export default UploadPAN;