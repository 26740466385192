import React from "react";
import Axios from "axios";
import "../../css/MenuSection/Orders.css";
import { GetCookie } from "../../exports/CookieManagement";
import { GetRoleType } from "../../exports/RoleTypes";
import {
  OrderBook,
  TickCircle,
  PlayCircle,
} from "../CustomComponents/SwiftIcon/Icons";
import CustomIconButton from "../Body/CustomChartComponents/CustomIconButton/CustomIconButton";
import CustomCheckBox from "../Body/CustomChartComponents/CustomCheckBox/CustomCheckBox";
import { SWIFTALGO_TIMEWINDOW } from "../../exports/OrderAlgoConfig";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";
import Pulse from "../Loader/Pulse";
import { COLOR_VARS } from "../../exports/ColorVars";
import RouteSection from "./OrdersComponents/RouteSection";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

class RouteBook extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      account_token: GetCookie("token"),
      user_role: GetCookie("role"),
      user_role_type: GetRoleType(GetCookie("role")),
      ordersloaded: false,
      orderslist: [],
      execute: false,
      place: false,
      isPlacing: false,
      allSelected: false,
    };
    this.FetchUserOrders = this.FetchUserOrders.bind(this);
    this.UpdateOrders = this.UpdateOrders.bind(this);
    this.SetOrderType = this.SetOrderType.bind(this);
    this.SetLimitPrice = this.SetLimitPrice.bind(this);
    this.SetSelectBox = this.SetSelectBox.bind(this);
    this.SetAllSelect = this.SetAllSelect.bind(this);
    this.RemoveBulkOrder = this.RemoveBulkOrder.bind(this);
    this.RemoveSingleOrder = this.RemoveSingleOrder.bind(this);
    this.ExecuteBulkOrder = this.ExecuteBulkOrder.bind(this);
    this.ExecuteSingleOrder = this.ExecuteSingleOrder.bind(this);
    this.ApplyOrder = this.ApplyOrder.bind(this);
  }

  componentDidMount() {
    this.FetchUserOrders();
  }

  FetchUserOrders() {
    this.setState({
      isPlacing: false,
    });
    const { account_token } = this.state;
    this.setState({ ordersloaded: false }, () => {
      Axios.get(`${REQUEST_BASE_URL}/order/route?token=${account_token}`)
        .then((response) => {
          if (!response.data.error) {
            this.setState(
              {
                orderslist: response.data.data,
              },
              () => {
                this.UpdateOrders();
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  UpdateOrders() {
    const { orderslist } = this.state;

    if (!orderslist || orderslist.length == 0) {
      this.setState({
        ordersloaded: true,
      });
      return;
    }

    let updated_orders = {};
    orderslist.map((list) => {
      const { config, routes: orders, error } = list;

      let order_config = {
        config,
        selected: false,
      };
      let orders_list = [];
      orders.map((order) => {
        orders_list.push(order);
      });

      order_config["routes"] = orders_list;
      order_config["error"] = error;
      updated_orders[config.order_id] = order_config;
    });

    this.setState({
      ordersloaded: true,
      orderslist: updated_orders,
    });
  }

  SetOrderType(order_id, isin, type_config) {
    const { type, limit_price = false, algo_config = false } = type_config;

    const { orderslist } = this.state;
    if (Object.keys(orderslist).includes(order_id)) {
      const order = orderslist[order_id];
      if (order) {
        const { config, error, routes: orders, selected } = order;
        const new_orders = [];
        orders.map((o) => {
          if (o.isin === isin) {
            o["orderType"] = type;

            if (type === "MARKET") {
              o["limitPrice"] = false;
              o["algo_config"] = false;
            } else if (type === "LIMIT") {
              o["limitPrice"] = limit_price;
              o["algo_config"] = false;
            } else {
              o["limitPrice"] = limit_price;
              o["algo_config"] = algo_config;
            }
          }
          new_orders.push(o);
        });

        const order_config = {
          config,
          error,
          selected,
          routes: new_orders,
        };

        this.setState({
          orderslist: {
            ...orderslist,
            [order_id]: order_config,
          },
        });
      }
    }
  }

  SetLimitPrice(order_id, isin, price) {
    const { orderslist } = this.state;
    if (Object.keys(orderslist).includes(order_id)) {
      const order = orderslist[order_id];
      if (order) {
        const { config, error, routes: orders, selected } = order;
        const new_orders = [];
        orders.map((o) => {
          if (o.isin === isin) {
            o["limitPrice"] = price;
          }
          new_orders.push(o);
        });

        const order_config = {
          config,
          error,
          selected,
          routes: new_orders,
        };

        this.setState({
          orderslist: {
            ...orderslist,
            [order_id]: order_config,
          },
        });
      }
    }
  }

  SetSelectBox(order_id, value) {
    const { orderslist } = this.state;
    if (Object.keys(orderslist).includes(order_id)) {
      let obj = orderslist[order_id];
      obj = {
        ...obj,
        selected: value,
      };

      const updated_orders = { ...orderslist, [order_id]: obj };

      this.setState(
        {
          orderslist: updated_orders,
          allSelected: false,
        },
        () => {
          const selected = Object.values(updated_orders).filter((order) => {
            return order.selected === true;
          });
          this.setState({
            place: selected.length > 0 ? true : false,
          });
        }
      );
    }
  }

  SetAllSelect(value) {
    const { orderslist } = this.state;

    let updated_orders = {};
    Object.entries(orderslist).map(([order_id, orders]) => {
      const obj = {
        ...orders,
        selected: value,
      };
      updated_orders[order_id] = obj;
    });

    this.setState(
      {
        allSelected: value,
        orderslist: updated_orders,
      },
      () => {
        const selected = Object.values(updated_orders).filter((order) => {
          return order.selected === true;
        });
        this.setState({
          place: selected.length > 0 ? true : false,
        });
      }
    );
  }

  ExecuteBulkOrder() {
    const { place, orderslist } = this.state;

    if (!place) return;

    const filtered_orders = Object.values(orderslist).filter((o) => {
      return o.selected === true;
    });

    this.setState({
      isPlacing: true,
    });

    this.ApplyOrder(filtered_orders);
  }

  ExecuteSingleOrder(order_id) {
    const { orderslist } = this.state;

    const filtered_orders = Object.values(orderslist).filter((o) => {
      return o.config.order_id === order_id;
    });

    this.setState({
      isPlacing: true,
    });

    this.ApplyOrder(filtered_orders);
  }

  ApplyOrder(filtered_orders) {
    const { account_token } = this.state;

    const order_data = filtered_orders.map((order) => {
      const { config, routes: orders } = order;

      const orders_list = orders.map((o) => {
        if (o.algo_config && o.algo_config["time_window"]) {
          o.algo_config["time_window"] =
            SWIFTALGO_TIMEWINDOW[o.algo_config["time_window"]];
        }

        const order_info = {
          order_id: o.tradeOrderID,
          cp_code: o.CPCode,
          isin: o.isin,
          exchange: o.exchange,
          close: o.close,
          quantity: o.Quantity,
          limit: o.limitPrice,
          side: o.Side,
          type: o.orderType,
          algo_config: o.algo_config,
          symbol: o.stockCode,
          scrip_symbol: o.symbol,
          series: o.series,
          company: o.stockName,
        };

        return order_info;
      });

      return {
        config: config,
        orders: orders_list,
      };
    });

    Axios.post(`${REQUEST_BASE_URL}/order/bulkroute?token=${account_token}`, {
      data: order_data,
    })
      .then((response) => {
        // console.log(response)
        if (!response.data.error) {
          this.FetchUserOrders();
          Alert({
            TitleText: "Order Placed Successfully!",
            Message: "Please visit trade book to check the order status",
            Band: true,
            BandColor: "#67D098",
            BoxColor: "#ffffff",
            TextColor: "#000000",
            AutoClose: {
              Active: true,
              Line: true,
              LineColor: "#67D098",
              Time: 3,
            },
          });
        } else {
          Alert({
            TitleText: "Warning",
            Message: "Something went wrong while placing the orders",
            Band: true,
            BandColor: "#E51A4B",
            BoxColor: "#ffffff",
            TextColor: "#000000",
            AutoClose: {
              Active: true,
              Line: true,
              LineColor: "#E51A4B",
              Time: 5,
            },
          });
          this.setState({
            isPlacing: false,
          });
        }
      })
      .catch((err) => {
        Alert({
          TitleText: "Warning",
          Message: "Something went wrong while placing the orders",
          Band: true,
          BandColor: "#E51A4B",
          BoxColor: "#ffffff",
          TextColor: "#000000",
          AutoClose: {
            Active: true,
            Line: true,
            LineColor: "#E51A4B",
            Time: 5,
          },
        });
      }).finally(()=>{
        this.setState({
          execute: false
        })
      });
  }

  RemoveBulkOrder(account_code, order_id) {
    const { account_token } = this.state;

    Axios.get(
      `${REQUEST_BASE_URL}/order/remove?token=${account_token}&account_code=${account_code}&type=bulk&order_id=${order_id}`
    )
      .then((response) => {
        console.log(response);
        if (!response.data.error) {
          this.FetchUserOrders();
          Alert({
            TitleText: "Trades Removed",
            Message: "Trades are removed successfully!",
            Band: true,
            BandColor: "#67D098",
            BoxColor: "#ffffff",
            TextColor: "#000000",
            AutoClose: {
              Active: true,
              Line: true,
              LineColor: "#67D098",
              Time: 3,
            },
          });
        } else {
          Alert({
            TitleText: "Warning",
            Message: "Something went wrong while removing the trades",
            Band: true,
            BandColor: "#E51A4B",
            BoxColor: "#ffffff",
            TextColor: "#000000",
            AutoClose: {
              Active: true,
              Line: true,
              LineColor: "#E51A4B",
              Time: 5,
            },
          });
        }
      })
      .catch((err) => {
        Alert({
          TitleText: "Warning",
          Message: "Something went wrong while removing the trades",
          Band: true,
          BandColor: "#E51A4B",
          BoxColor: "#ffffff",
          TextColor: "#000000",
          AutoClose: {
            Active: true,
            Line: true,
            LineColor: "#E51A4B",
            Time: 5,
          },
        });
      });
  }

  RemoveSingleOrder(account_code, trade_id) {
    const { account_token } = this.state;

    Axios.get(
      `${REQUEST_BASE_URL}/order/remove?token=${account_token}&account_code=${account_code}&type=single&order_id=${trade_id}`
    )
      .then((response) => {
        console.log(response.data);
        if (!response.data.error) {
          this.FetchUserOrders();
          Alert({
            TitleText: "Trade Removed",
            Message: "Trade removed successfully!",
            Band: true,
            BandColor: "#67D098",
            BoxColor: "#ffffff",
            TextColor: "#000000",
            AutoClose: {
              Active: true,
              Line: true,
              LineColor: "#67D098",
              Time: 3,
            },
          });
        } else {
          Alert({
            TitleText: "Warning",
            Message: "Something went wrong while removing the trade",
            Band: true,
            BandColor: "#E51A4B",
            BoxColor: "#ffffff",
            TextColor: "#000000",
            AutoClose: {
              Active: true,
              Line: true,
              LineColor: "#E51A4B",
              Time: 5,
            },
          });
        }
      })
      .catch((err) => {
        Alert({
          TitleText: "Warning",
          Message: "Something went wrong while removing the trade",
          Band: true,
          BandColor: "#E51A4B",
          BoxColor: "#ffffff",
          TextColor: "#000000",
          AutoClose: {
            Active: true,
            Line: true,
            LineColor: "#E51A4B",
            Time: 5,
          },
        });
      });
  }

  render() {
    const {
      user_role_type,
      ordersloaded,
      orderslist,
      execute,
      place,
      allSelected,
      isPlacing,
    } = this.state;

    return (
      <>
        <div className="orders-wrapper">
          <div className="table-container">
            {isPlacing ? (
              <>
                <div className="table-loader">
                  <Pulse />
                  <p>Sending approval email please wait!</p>
                </div>
              </>
            ) : (
              <>
                {orderslist && ordersloaded === true ? (
                  Object.entries(orderslist).length > 0 ? (
                    <>
                      <div className="order-trades-section">
                        <div className="order-trades-header">
                          <div className="order-trades-config">
                            <div className="config-box arrow"></div>
                            {user_role_type === "BROKER" ? (
                              <>
                                <div className="config-box select">
                                  <CustomCheckBox
                                    width={18}
                                    height={18}
                                    isChecked={allSelected}
                                    onChangeValue={(value) => {
                                      this.SetAllSelect(value);
                                    }}
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <div className="config-box name">
                              <span>Customer Name</span>
                            </div>
                            <div className="config-box account">
                              <span>Account Type</span>
                            </div>

                            <div className="config-box count">
                              <span>Total Trades</span>
                            </div>
                            <div className="config-box sell">
                              <span>Sell Value</span>
                            </div>
                            <div className="config-box buy">
                              <span>Buy Value</span>
                            </div>
                            <div className="config-box trade">
                              <span>Trade Value</span>
                            </div>
                            {user_role_type === "BROKER" ? (
                              <>
                                <div className="config-box execute"></div>
                                <div className="config-box remove"></div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>

                      {Object.entries(orderslist).map(([key, order]) => {
                        if (!order.error) {
                          return (
                            <RouteSection
                              key={key}
                              user_role_type={user_role_type}
                              OrderID={key}
                              OpenModifyPopup={this.props.OpenModifyPopup}
                              FetchUserOrders={this.FetchUserOrders}
                              SetOrderType={this.SetOrderType}
                              SetLimitPrice={this.SetLimitPrice}
                              SetSelectBox={this.SetSelectBox}
                              ExecuteSingleOrder={this.ExecuteSingleOrder}
                              RemoveBulkOrder={this.RemoveBulkOrder}
                              RemoveSingleOrder={this.RemoveSingleOrder}
                              order={order}
                            />
                          );
                        }
                      })}
                    </>
                  ) : (
                    <div className="order-container-empty">
                      <OrderBook size={40} color={COLOR_VARS["SWIFT_COLOR4"]} />
                      <div>
                        <p>No orders found</p>
                        <span>Looks like you have't made any order today</span>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="table-loader">
                    <Pulse />
                    <p>Fetching your orders...</p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="orders-execution">
          {execute ? (
            <>
              <CustomIconButton
                onClick={this.ExecuteBulkOrder}
                icon={<TickCircle size={20} />}
                title="Confirm"
                disabled={!place}
              />
            </>
          ) : (
            <>
              <CustomIconButton
                onClick={() => {
                  this.setState({ execute: true });
                }}
                icon={<PlayCircle size={20} />}
                title="Send Approval Email"
                disabled={!place}
              />
            </>
          )}
        </div>
      </>
    );
  }
}

export default RouteBook;
