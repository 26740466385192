import React from "react";
import HourglassEmptyRoundedIcon from "@mui/icons-material/HourglassEmptyRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ConfirmBox from "../CustomComponents/ConfirmBox/ConfirmBox";

function TopTask({ pending = false, data, TaskDelete, TaskUpdate }) {
  const handleTaskDelete = () => {
    showConfirmationBox("Delete", data.id);
  };

  const handleTaskUpdate = () => {
    showConfirmationBox("Complete", data.id);
  };

  const showConfirmationBox = (type, task_id) => {
    ConfirmBox({
      title: `${type} Task`,
      description: (
        <>
          <>Are you sure you want to {type} this Task ?</>
        </>
      ),
      properties: [
        {
          id: "2",
          label: type,
          color: "#192b40",
          bgColor: "#ffffff",
          handleFunction: (callback) => {
            handleConfirmComplete(type, task_id);
            callback();
          },
        },
      ],
      cancel: true,
    });
  };

  const handleConfirmComplete = (type, task_id) => {
    if (type === "Delete") {
      TaskDelete(task_id);
    } else if (type === "Complete") {
      TaskUpdate(task_id);
    }
  };

  return (
    <div className="top-task-row">
      <div className="top-task-data">
        <div className="task-dates">
          <span>
            Created: <i>{data.created}</i>
          </span>
          <span>
            Due Date: <i>{data.due}</i>
          </span>
        </div>
        <p className="task-description">{data.description}</p>
      </div>
      <div className="top-task-action">
        {pending ? (
          <>
            <HourglassEmptyRoundedIcon
              style={{ fontSize: "20px", color: "#CCCCCC" }}
            />
            <ClearRoundedIcon
              style={{ fontSize: "20px", color: "#CCCCCC", cursor: "pointer" }}
              onClick={handleTaskDelete}
            />
          </>
        ) : (
          <>
            <CheckRoundedIcon
              style={{ fontSize: "20px", color: "#CCCCCC", cursor: "pointer" }}
              onClick={handleTaskUpdate}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default TopTask;
