import React from "react";
import CustomSelect from "../Body/CustomChartComponents/CustomSelect/CustomSelect";
import PortfolioTableRow from "../Body/MenuSection/PortfolioComponents/PortfolioTableRow";
import { numberFormat, priceFormat } from "../../exports/UtilFunctions";
import { Bag2 } from "../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../exports/ColorVars";

function SumWeight(weight_arr) {
  let weight = 0;
  if (Array.isArray(weight_arr) && weight_arr.length > 0) {
    weight_arr.forEach((w) => {
      if (!isNaN(w)) {
        weight += parseFloat(w);
      }
    });
    if (weight === 0 || isNaN(weight)) return "";
    return `(${numberFormat(weight)}%)`;
  } else {
    return "";
  }
}

function PortfolioTable({
  user_role_type,
  customer,
  data,
  originalLen,
  sum,
  totalReturnToday,
  totalReturnChange,
  selectField,
  newWeight,
  orderArr,
  addOrderArr,
  updateOrderArr,
  setIndividualChange,
  setIndividualPrice,
  deleteStock,
}) {
  if (data.length === 0) {
    return (
      <div className="portfolio__table">
        <div className="empty__table">
          <Bag2 size={60} color={COLOR_VARS["SWIFT_COLOR4"]} />
          <p>You portfolio is empty</p>
        </div>
      </div>
    );
  }

  const cash_value = parseFloat(sum.macCash);
  const cash_amount = parseFloat(sum.cashValueVirtual);
  let total_portfolio_value =
    parseFloat(sum.lastValueSum) +
    parseFloat(totalReturnChange) +
    parseFloat(cash_value);

  const cash_percentage = (cash_value * 100) / total_portfolio_value;

  const customer_role = customer ? customer.user_role : "";

  // if (customer_role === "MAC") {
  //   total_portfolio_value -= cash_value;
  // }

  const portfolio_returns =
    (total_portfolio_value / parseFloat(sum.costValueSum) - 1) * 100;

  return (
    <div className="portfolio__table">
      <div className="table__content">
        <table>
          <thead>
            <tr>
              {user_role_type === "BROKER" ? (
                <>
                  <td className="name active">Name</td>
                  <td className="quantity active">Quantity</td>
                  <td className="buyprice">Avg. Buy Price</td>
                  <td className="currprice">Current Price</td>
                  <td className="dynamicfield active">
                    <CustomSelect
                      width={80}
                      height={20}
                      defaultIndex={0}
                      options={[
                        "Curr Price",
                        "Buy Price",
                        "Cost",
                        "Curr Value",
                        "Return",
                        "Today",
                        "Trade Volume",
                      ]}
                      onTypeChange={(field) => {
                        selectField(field);
                      }}
                    />
                  </td>
                  <td className="cost">Investment Cost</td>
                  <td className="currvalue">Current Value</td>
                  <td className="return shrink">Return</td>
                  <td className="today shrink">Today</td>
                  <td className="portweight active shrink">Portfolio Weight</td>
                  <td className="tradevol">Amount</td>
                  <td className="order active">Order</td>
                  {/* <td className="addorder active"></td> */}
                  {/* <td className="removeorder active"></td> */}
                  <td className="deleteorder"></td>
                </>
              ) : (
                <>
                  <td className="name active">Name</td>
                  <td className="quantity active">Quantity</td>
                  <td className="buyprice">Avg. Buy Price</td>
                  <td className="currprice">Current Price</td>
                  <td className="dynamicfield active">
                    <CustomSelect
                      width={80}
                      height={20}
                      defaultIndex={0}
                      options={[
                        "Curr Price",
                        "Buy Price",
                        "Cost",
                        "Curr Value",
                        "Return",
                        "Today",
                        "Trade Volume",
                      ]}
                      onTypeChange={(field) => {
                        selectField(field);
                      }}
                    />
                  </td>
                  <td className="cost">Investment Cost</td>
                  <td className="currvalue">Current Value</td>
                  <td className="return">Return</td>
                  <td className="today">Today</td>
                  <td className="portweight active">Port. Wt.</td>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="name active">
                <div>
                  <div className="portfolio__stock__code">
                    <div className="stock__code">CASH</div>
                  </div>
                  <div className="portfolio__stock__name">CASH AMOUNT</div>
                </div>
              </td>
              <td className="quantity active">
                <div>{numberFormat(cash_amount, 0)}</div>
              </td>
              <td className="buyprice">
                <div>{priceFormat(1)}</div>
              </td>
              <td className="currprice active">
                <div>{priceFormat(1)}</div>
              </td>
              <td className="cost">
                <div>{priceFormat(cash_amount)}</div>
              </td>
              <td className="currvalue">
                <div>{priceFormat(cash_amount)}</div>
              </td>
              <td className="return">
                <div>
                  <span>{`-`}</span>
                </div>
              </td>
              <td className="today">
                <div>
                  <span>{`-`}</span>
                </div>
              </td>
              <td className="portweight active">
                <div>{numberFormat(cash_percentage)}%</div>
              </td>
              <td className="tradevol">
                <div>-</div>
              </td>
              <td className="order active">
                <div className="input-box">-</div>
              </td>
              {user_role_type === "BROKER" ? <></> : <></>}
            </tr>

            {data.map((row, i) => {
              return (
                <PortfolioTableRow
                  key={i + 100}
                  index={i}
                  newWeight={newWeight[i]}
                  data={row}
                  total_portfolio_value={total_portfolio_value}
                  cash_value={cash_value}
                  orderArr={orderArr}
                  addOrderArr={addOrderArr}
                  updateOrderArr={updateOrderArr}
                  setIndividualChange={setIndividualChange}
                  setIndividualPrice={setIndividualPrice}
                  originalLen={originalLen}
                  deleteStock={deleteStock}
                  user_role_type={user_role_type}
                />
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td className="name active"></td>
              <td className="quantity active"></td>
              <td className="buyprice active"></td>
              <td className="currprice"></td>
              <td className="cost">
                <div>{priceFormat(sum.costValueSum)}</div>
              </td>
              <td className="currvalue">
                <div >{priceFormat(total_portfolio_value)}</div>
              </td>
              <td className="return">
                <span
                  className={
                    parseFloat(portfolio_returns) > 0 ? "positive" : "negative"
                  }
                >
                  {numberFormat(portfolio_returns)}%
                </span>
              </td>
              <td className="today">
                <span
                  className={
                    parseFloat(totalReturnToday) > 0 ? "positive" : "negative"
                  }
                >
                  {numberFormat(totalReturnToday)}%
                </span>
              </td>
              <td className="portweight active">
                <div>
                  {numberFormat(100)}% {SumWeight(newWeight)}
                </div>
              </td>

              {user_role_type === "BROKER" ? (
                <>
                  <td className="tradevol"></td>
                </>
              ) : (
                <></>
              )}

              {user_role_type === "BROKER" ? (
                <>
                  <td className="order active"></td>
                  {/* <td className="addorder active"></td>
                                <td className="removeorder active"></td> */}
                  <td className="delete"></td>
                </>
              ) : (
                <></>
              )}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default PortfolioTable;
