import React, { useEffect, useState } from "react";
import UpperStock from "./UpperStock";
import IndexSelectPopup from "../AppPopups/IndexSelectPopup/IndexSelectPopup";
import { GetCookie } from "../../../exports/CookieManagement";

const INDEX_COUNT = 2;
const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function TopStocks({ is_visible, indexOpen, setIndexOpen }) {
  const access_token = GetCookie("token");

  const [indexVisible, setIndexVisible] = useState(false);
  const [indices, setIndices] = useState(undefined);

  useEffect(() => {
    async function GetIndicesList() {
      const response = await fetch(
        `${REQUEST_BASE_URL}/clientinfo/indices?token=${access_token}`
      );
      const data = await response.json();
      if (data.error) {
        setIndices([]);
        return;
      }

      let indices = data.data;
      indices = indices.slice(0, INDEX_COUNT);
      setIndices(indices);
    }

    GetIndicesList();
  }, [access_token]);

  useEffect(() => {
    if(indexOpen){
      setIndexVisible(true);
    }
  }, [indexOpen]);

  if (!indices || indices === undefined) return <></>;

  const display_indices = indices.slice(0, INDEX_COUNT);

  return (
    <>
      {indexVisible && (
        <>
          <IndexSelectPopup
            indexOpen={indexOpen}
            max_index={INDEX_COUNT}
            indices={indices}
            closeIndexPopup={() => {
              setIndexOpen(false);
            }}
          />
        </>
      )}

      <div className={"top__stocks " + (is_visible ? "active" : "")}>
        {display_indices.map((s) => {
          return <UpperStock key={s.index} symbol={s.index} />;
        })}
      </div>
    </>
  );
}

export default TopStocks;
