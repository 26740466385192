import React, { useEffect, useState } from "react";
import { DeleteCookie, GetCookie } from "../../exports/CookieManagement";
import Pulse from "../Loader/Pulse";
import AccessCustomerToken from "../AccessManagement/AccessCustomerToken";
import useQueryParams from "../../hooks/useQueryParams";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ConfirmationBook from "../TransactionManagement/ConfirmationBook";
import LOGO from "../../assets/images/logo.png";
import CustomIconButton from "../Body/CustomChartComponents/CustomIconButton/CustomIconButton";
import { LogOut } from "../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../exports/ColorVars";
import ConfirmBox from "../CustomComponents/ConfirmBox/ConfirmBox";
import { getDeviceSize } from "../../exports/InteractiveUtils";

const DEVICE_SIZE = getDeviceSize();

function CustomerTrades() {
  const [token, setToken] = useState("");
  const [login, setLogin] = useState(false);

  const { id = false } = useQueryParams();

  useEffect(() => {
    const auth_token = GetCookie("token");
    setToken(auth_token);
  }, [login]);

  if (!id) {
    return <Navigate to="/access/login" />;
  }

  if (token === "") {
    return (
      <>
        <div className="customer-trades">
          <CustomerTradesHeader />
          <div className="customer-trades-table loader">
            <Pulse />
          </div>
        </div>
      </>
    );
  }

  if (token === undefined) {
    return <AccessCustomerToken customer_token={id} setLogin={setLogin} />;
  }

  return (
    <div className="customer-trades">
      <CustomerTradesHeader />
      <div className="customer-trades-table">
        <ConfirmationBook />
      </div>
    </div>
  );
}

function CustomerTradesHeader() {
  const navigate = useNavigate();

  function LogoutUser(callback) {
    setTimeout(async () => {
      callback();

      DeleteCookie("token");
      navigate("/");
    }, 2000);
  }

  function LogoutConfirmation() {
    ConfirmBox({
      title: "Logout",
      description: (
        <>
          Are you sure you want to logout? You will be redirected to the home
          page.
        </>
      ),
      properties: [
        {
          id: "2",
          label: "Logout",
          color: "#192b40",
          bgColor: "#ffffff",
          handleFunction: LogoutUser,
          functionArgs: [],
        },
      ],
      cancel: true,
    });
  }

  return (
    <>
      <div className="customer-trades-header">
        <div className="trades-header-left">
          <div>
            <Link to="/">
              <img src={LOGO} alt="Swiftfolio Logo" />
            </Link>
          </div>
          <p className="customer-trades-title">
            Swiftfolios Trades Confirmation
          </p>
        </div>
        <div className="trades-header-right">
          {DEVICE_SIZE === "S" ? (
            <>
              <div className="header-logout" onClick={LogoutConfirmation}>
                <LogOut color={COLOR_VARS["SWIFT_COLOR1"]} size={20} />
              </div>
            </>
          ) : (
            <>
              <CustomIconButton
                onClick={LogoutConfirmation}
                title="Logout"
                icon={<LogOut color={COLOR_VARS["SWIFT_COLOR1"]} size={20} />}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CustomerTrades;
