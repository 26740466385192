import React, { useEffect, useState } from "react";
import StockTable from "./StockTable";
import { numberFormat } from "../../exports/UtilFunctions";

const quartely_bank = {
  Revenue: true,
  Interest: false,
  Expenses: false,
  "Financing Profit": true,
  "Financing Margin %": false,
  "Other Income": false,
  Depreciation: false,
  "Profit before tax": false,
  "Tax %": false,
  "Net Profit": false,
  "EPS in Rs": false,
  "Dividend Payout %": false,
};

const quartely_common = {
  Revenue: true,
  Sales: true,
  Expenses: false,
  "Operating Profit": true,
  "OPM %": false,
  "Other Income": false,
  Interest: false,
  Depreciation: false,
  "Profit before tax": false,
  "Tax %": false,
  "Net Profit": true,
  "EPS in Rs": false,
};

const annual_common = {
  ...quartely_common,
  "Dividend Payout %": false,
};

const StockIncome = ({ snapShot }) => {
  const [selectedButton, setSelectedButton] = useState("Annual");
  const [data, setData] = useState([]);
  const [header, setHeader] = useState([]);
  const [growth, setGrowth] = useState({
    sales: false,
    profit: false,
  });

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  function convertNumber(text) {
    const num = parseFloat(text.replace(/,/g, ""));
    if (isNaN(num)) {
      return text;
    }
    return num;
  }

  useEffect(() => {
    if (!snapShot) return;
    filterData();
  }, [snapShot, selectedButton]);

  useEffect(() => {
    if (!data || !Array.isArray(data) || data.length == 0) return;

    function FindGrowth(array) {
      if (!array && !Array.isArray(array) && array.length == 0) return;
      return ((array[array.length - 1] - array[0]) / Math.abs(array[0])) * 100;
    }

    let sales_growth = false,
      profit_growth = false;

    data.forEach((d) => {
      const title = d["data"][0];
      if (title.trim().toLowerCase() === "sales") {
        sales_growth = FindGrowth(d.data.slice(-3));
      }
      if (title.trim().toLowerCase() === "net profit") {
        profit_growth = FindGrowth(d.data.slice(-3));
      }
    });

    setGrowth({
      sales: sales_growth,
      profit: profit_growth,
    });
  }, [data]);

  const filterData = () => {
    const display_data =
      selectedButton === "Annual"
        ? { ...snapShot["profit_loss"] }
        : { ...snapShot["quarters"] };

    let headers = [];
    let data = [];
    let filteredData = [];

    headers = display_data.fields.slice(2);

    filteredData = display_data.values
      .map((row) => {
        const filteredRow = row.map((value) => value.replace(/\+/g, ""));

        return filteredRow.filter((value, index) => {
          if (index === 0) {
            return false;
          }
          const fieldName = row[1].replace(/\s*\+$/, "");
          let res;
          if (snapShot?.type == "banks" && selectedButton == "Quarterly") {
            res = quartely_bank.hasOwnProperty(fieldName);
          } else if (selectedButton == "Quarterly") {
            res = quartely_common.hasOwnProperty(fieldName);
          } else {
            res = annual_common.hasOwnProperty(fieldName);
          }

          return res;
        });
      })
      .filter((row) => row.length > 0)
      .map((row) => {
        const fieldName = row[0].trim();

        let isBold;
        if (snapShot?.type == "banks" && selectedButton == "Quarterly") {
          isBold = quartely_bank[fieldName];
        } else if (selectedButton == "Quarterly") {
          isBold = quartely_common[fieldName];
        } else {
          isBold = annual_common[fieldName];
        }

        return { data: row, isBold };
      })
      .map((row) => {
        // Reverse each row from the second item onwards
        const reversedRow = [...row.data.slice(0, 1), ...row.data.slice(1)];

        // Replace empty or null values with "-"
        const updatedRow = reversedRow.map((value) =>
          value === "" || value === null ? "-" : convertNumber(value)
        );

        return { data: updatedRow, isBold: row.isBold };
      });

    setData(filteredData);
    setHeader(headers);
  };

  return (
    <div className="swift-stock-management-income" id="income">
      <div className="swift-stock-management-income-header">
        <div className="swift-stock-management-income-header-left">
          <p className="swift-stock-management-income-title">
            Income Statement
          </p>
        </div>
        {data.length > 0 && header.length > 0 && (
          <div className="swift-stock-management-income-header-right">
            <div className="swift-stock-management-income-btn">
              <div className="swift-stock-management-income-btn-left">
                <span>
                  3 {selectedButton == "Annual" ? "Yr." : "Qtr."}&nbsp;
                </span>
                <p>Sales Growth</p>
              </div>
              <div className="swift-stock-management-income-btn-right">
                {!growth.sales ? (
                  <span>-</span>
                ) : (
                  <>
                    <span
                      className={growth.sales >= 0 ? "positive" : "negative"}
                    >
                      {numberFormat(growth.sales, 2)}%
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="swift-stock-management-income-btn">
              <div className="swift-stock-management-income-btn-left">
                <span>
                  3 {selectedButton == "Annual" ? "Yr." : "Qtr."}&nbsp;
                </span>
                <p>Profit Growth</p>
              </div>
              <div className="swift-stock-management-income-btn-right">
                {!growth.profit ? (
                  <span>-</span>
                ) : (
                  <span
                    className={growth.profit >= 0 ? "positive" : "negative"}
                  >
                    {numberFormat(growth.profit, 2)}%
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {data.length > 0 && header.length > 0 ? (
        <>
          <div className="swift-stock-management-income-time-btn">
            <button
              className={selectedButton === "Annual" ? "selected" : ""}
              onClick={() => handleButtonClick("Annual")}
            >
              Annual
            </button>
            <button
              className={selectedButton === "Quarterly" ? "selected" : ""}
              onClick={() => handleButtonClick("Quarterly")}
            >
              Quarterly
            </button>
          </div>
          <StockTable data={data} header={header} />
        </>
      ) : (
        <>No Data </>
      )}
    </div>
  );
};

export default StockIncome;
