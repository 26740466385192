import React from "react";
import { titleCase } from "../../../exports/UtilFunctions";
import useMarketStock from "../../../hooks/useMarketStock";

function TradesTableItem({ show, order }) {
  const {
    client_name,
    client_code,
    symbol,
    fullname,
    exchange,
    ordertype,
    limitprice,
    side,
    quantity,
    avgprice,
    status,
    trade_id,
  } = order;

  const stock_data = useMarketStock(symbol);

  const curr = (num) =>
    parseFloat(num).toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  function OrderItemName(name, fullname) {
    return (
      <>
        <div className="stock-info" title={fullname.toUpperCase()}>
          <span className="stock-name">{name}</span>
          <span className="stock-fullname">{fullname}</span>
        </div>
      </>
    );
  }

  function OrderSide(side) {
    return (
      <div className={side.toUpperCase() === "BUY" ? "positive" : "negative"}>
        {side.toUpperCase()}
      </div>
    );
  }

  function OrderStatus(status) {
    return status;
  }

  function ReplaceNoise(value) {
    if (
      value === null ||
      value === undefined ||
      value === "" ||
      parseInt(value) === 0
    ) {
      return "-";
    }
    return value;
  }

  if (!show) {
    return <></>;
  } else {
    let current_price = parseFloat(stock_data?.last_traded_price);

    let total_amount =
      avgprice == "" ? quantity * current_price : quantity * avgprice;

    return (
      <>
        <tr>
          <td className="order-item-stock-name">
            {OrderItemName(symbol,fullname)}
          </td>
          <td
            className="order-item-stock-client"
            title={titleCase(client_name)}
          >
            {titleCase(client_name)}
          </td>
          <td className="order-item-stock-clientcode" title={client_code}>
            {client_code}
          </td>
          <td className="order-item-stock-exchange" title={exchange}>
            {exchange}
          </td>
          <td className="order-item-type" title={ordertype.toUpperCase()}>
            {ordertype.toUpperCase()}
          </td>
          <td className="order-item-limit">{ReplaceNoise(limitprice)}</td>
          <td className="order-item-side">
            {OrderSide(side)}
          </td>
          <td className="order-item-quantity" title={quantity}>
            {quantity}
          </td>
          <td className="order-item-current-price">
            {current_price ? curr(current_price) : "-"}
          </td>
          <td className="order-item-trade-price">
            {avgprice ? curr(avgprice) : "-"}
          </td>
          <td
            className={
              side.toUpperCase() === "BUY"
                ? "order-item-current-value positive"
                : "order-item-current-value negative"
            }
          >
            {total_amount ? curr(total_amount) : "-"}
          </td>
          <td className="order-item-stock-tradeid" title={trade_id}>
            {trade_id}
          </td>
          <td className="order-item-status">
            {OrderStatus(status)}
          </td>
        </tr>
      </>
    );
  }
}



export default TradesTableItem;
