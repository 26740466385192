import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";
import Pulse from "../Loader/Pulse";
import { getCandleDuration } from "../../exports/MessageStructure";
import {
  getFuturePoints,
  getStartPointIndex,
} from "../../exports/FutureEntries";
import { convertToUNIX, dateToUNIX } from "../../exports/TimeConverter";
import { splitAdjustmentArray } from "../../exports/SplitAdjustment";
import useMarketStock from "../../hooks/useMarketStock";
import useStockInfo from "../../hooks/useStockInfo";
import useInterval from "../../hooks/useInterval";
import StockChartNew from "../Body/StockChartNew";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const RANGE_ARRAY = [
  { title: "1H", value: "D" },
  { title: "1D", value: "1D" },
  { title: "5D", value: "5D" },
  { title: "1M", value: "1M" },
  { title: "3M", value: "3M" },
  { title: "6M", value: "6M" },
  { title: "YTD", value: "YTD" },
  { title: "1Y", value: "1Y" },
  { title: "5Y", value: "5Y" },
  { title: "MAX", value: "MAX" },
];

function StockChartContainer({ symbol, newsData, openFeedModal }) {
  const chartRef = useRef();
  const [range, setRange] = useState("1D");

  return (
    <div
      className="swift-stock-management-chart-feed-wrapper"
      id="chart"
      style={{ display: "flex", columnGap: "45px" }}
    >
      <div className="swift-stock-management-chart">
        <div className="swift-stock-management-chart-header">
          <div className="swift-stock-management-chart-left">
            <p className="swift-stock-management-chart-title">Charts</p>
          </div>
        </div>
        <div
          className="swift-stock-management-chart-wrapper"
          id="stock-management-chart"
          ref={chartRef}
        >
          <StockChart chartRef={chartRef} symbol={symbol} range={range} />
        </div>
        <div className="stocks-management-chart-range">
          {RANGE_ARRAY.map((r) => {
            return (
              <RangeBox
                key={r.title}
                range={r}
                active={range === r.value}
                setRange={setRange}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

function StockChart({ chartRef, symbol, range }) {
  const stock_data = useMarketStock(symbol);
  const { stock_info } = useStockInfo(symbol);

  const controllerRef = useRef();
  const [config, setConfig] = useState(false);
  const [chartLoading, setChartLoading] = useState(false);
  const [chartProps, setChartProps] = useState(false);
  const [chartData, setChartData] = useState(false);
  const [chartDimension, setChartDimension] = useState(false);

  useInterval(() => {
    if (!config) return;
    UpdateChartData(range, config);
  }, 10000);

  useEffect(() => {
    SetInitialSize();
  }, [chartRef]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      console.log("RESIZE", window.innerWidth, window.innerHeight);
      SetInitialSize();
    });

    return () => {
      window.removeEventListener("resize", SetInitialSize);
    };
  }, []);

  useEffect(() => {
    // console.log("UE1", symbol);
    setChartLoading(true);
    setChartData(false);
    setChartProps(false);
  }, [symbol]);

  useEffect(() => {
    // console.log("UE2", symbol, range, chartDimension);
    setChartLoading(true);
    const options = getCandleDuration(range);
    setConfig(options);
  }, [chartDimension, range]);

  useEffect(() => {
    // console.log("UE3", symbol, range);
    /* GET THE CHART DATA */
    async function LoadChartData(type, config) {
      async function GetStockArray(type) {
        return new Promise(async (resolve, reject) => {
          if (chartData[type]) {
            if (Array.isArray(chartData[type]) && chartData[type].length > 0) {
              resolve(chartData[type]);
              return;
            }
          }

          let START_UNIX = convertToUNIX(type);
          const { exchange, nse_code, bse_code, code: token } = stock_info;

          let exchange_name = exchange.exchange;
          let code = exchange_name === "NSE" ? nse_code : bse_code;

          const params = {
            code: code,
            ct: ct,
            starttime: START_UNIX,
            dd: dd,
            exchange: exchange_name,
            token: token,
            mixed: mixed,
            type: type,
            index: false,
          };

          const stock_array = await FetchChartDataAPI(params);
          resolve(stock_array);
        });
      }

      // console.log("CONFIG", config);
      const { candle: ct, duration: dd, mixed } = config;
      // console.log(chartData)

      let stock_array = await GetStockArray(type);

      if (stock_array && stock_array.length != 0) {
        FormatStockData(type, stock_array, false);
        setChartData({
          ...chartData,
          [type]: stock_array,
        });
      } else {
        setChartLoading(false);
      }
    }

    // console.log(!stock_info, !config);
    if (!stock_info || !config) return;

    LoadChartData(range, config);

    return () => {
      // console.log("ABORT CONTROLLER", symbol, range);
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [stock_info, config]);

  async function UpdateChartData(type, config) {
    const { candle: ct, duration: dd, mixed } = config;

    const { last_point = false } = chartProps;

    if (!last_point || !last_point.date) return;

    let START_UNIX = dateToUNIX(last_point.date, type);
    const { exchange, nse_code, bse_code, code: token } = stock_info;

    let exchange_name = exchange.exchange;
    let code = exchange_name === "NSE" ? nse_code : bse_code;

    const stock_array = await FetchChartDataAPI({
      ct: ct,
      starttime: START_UNIX,
      dd: dd,
      exchange: exchange_name,
      token: token,
      code: code,
      mixed: mixed,
      type: type,
      index: false,
    });

    // console.log(stock_array);

    if (stock_array && stock_array.length != 0) {
      FormatStockData(type, stock_array, true);
      const old_array = chartData[type] || [];
      const new_array = old_array.concat(stock_array);
      // console.log(old_array.length,new_array.length)
      setChartData({
        ...chartData,
        [type]: new_array,
      });
    }
  }

  async function FetchChartDataAPI(params) {
    return new Promise((resolve, reject) => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      controllerRef.current = new AbortController();
      const signal = controllerRef.current.signal;

      // console.log('PARAMS: ',params);
      fetch(`${REQUEST_BASE_URL}/stockdata?${new URLSearchParams(params)}`, {
        method: "GET",
        signal: signal,
      })
        .then(async (response) => {
          response = await response.json();

          if (response.status !== "success") {
            resolve(false);
            return;
          }
          const data = response.data;
          // console.log(data.length);
          resolve(data);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }

  function FormatStockData(type, stock_array, update = false) {
    // console.log("FORMAT STOCK DATA", type, stock_array.length);

    if (stock_array.length === 0) return;

    const { chart_data = [] } = chartProps;

    let temp_data_array = update ? chart_data : [];

    let converted_data = splitAdjustmentArray(stock_array);
    // console.log(converteddata);

    converted_data.forEach((d) => {
      let dobj = {
        date: new Date(d["date"]),
        open: parseFloat(d["open"]),
        high: parseFloat(d["high"]),
        low: parseFloat(d["low"]),
        close: parseFloat(d["close"]),
        volume: parseInt(d["volume"]),
      };

      temp_data_array.push(dobj);
    });

    // console.log(temp_data_array.length);

    let first_point = temp_data_array[0];
    let last_point = temp_data_array[temp_data_array.length - 1];

    // console.log(first_point, last_point);

    let START_INDEX = getStartPointIndex(
      temp_data_array,
      type,
      last_point,
      first_point
    );

    // console.log(temp_data_array.length,START_INDEX);

    let future_points = getFuturePoints(last_point, type);

    setChartProps({
      chart_data: temp_data_array,
      extra_data: future_points,
      last_point: last_point,
      start_index: START_INDEX,
      extra_points: future_points.length,
      range: type,
    });

    // console.log(temp_data_array,future_points,last_point,START_INDEX,type);

    setChartLoading(false);
  }

  function SetInitialSize() {
    if (!chartRef || !chartRef.current) return;

    let wd = $("#stock-management-chart").width();
    let ht = $("#stock-management-chart").height();

    // console.log('SIZE',window.innerWidth,window.innerHeight,wd,ht,(wd/ht));

    setChartDimension({
      width: wd,
      height: ht,
    });
  }

  if (!stock_info || !stock_data || !chartProps) {
    return (
      <>
        <Pulse />
      </>
    );
  }

  const {
    close_price = 0,
    last_traded_price = 0,
    change_price = 0,
    change_percentage = 0,
  } = stock_data;

  return (
    <>
      <>
        <StockChartNew
          chartDimension={chartDimension}
          chartProps={chartProps}
          closePrice={close_price}
          currentPrice={last_traded_price}
        />
        {chartLoading && (
          <>
            <div className="chart-wrapper-loader">
              <Pulse />
            </div>
          </>
        )}
      </>
    </>
  );
}

function RangeBox({ range, active, setRange }) {
  return (
    <>
      <div
        className={`chart-range-value ${active ? "active" : ""} `}
        onClick={() => {
          setRange(range.value);
        }}
      >
        {range.title}
      </div>
    </>
  );
}

export default StockChartContainer;
