import React from 'react';
import moment from 'moment';
import { titleCase } from '../../exports/UtilFunctions';
import { ArrowDown } from '../CustomComponents/SwiftIcon/Icons';
import SwiftToolTip from '../CustomComponents/SwiftToolTip/SwiftToolTip';

function ReportHistoryRow({ data }) {

    const { name, report_execution_date, report_type, report_url } = data;

    function GetReportDate(date) {
        return moment(date).format('DD/MM/YYYY');
    }

    function GetReportType(type) {

        if (type === 'factsheet') return 'Factsheet Statement';
        if (type === 'gainloss') return 'Gain & Loss Statement';
        return '-';

    }

    return (
        <div className="report-history-row">
            <div className='history-left'>
                <p className='report-customer' title={titleCase(name)}>{titleCase(name)}</p>
                <p className='report-date'>Created {GetReportDate(report_execution_date)}</p>
            </div>

            <div className='history-right'>
                <p className='report-type'>{GetReportType(report_type)}</p>

                <SwiftToolTip title='Download Report' placement='bottom'>
                    <div className='report-download'>
                        <a href={report_url} download>
                            <ArrowDown size={18} color="#192b40" />
                        </a>
                    </div>
                </SwiftToolTip>

            </div>
        </div>
    )
}

export default ReportHistoryRow;