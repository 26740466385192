import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import OrderBook from "./OrderBook";
import TradeBook from "./TradeBook";
import OrdersHistory from "./OrdersHistory";
import OrderEntry from "./OrderEntry";
import OrderFiles from "./OrderFiles";
import RouteBook from "./RouteBook";
import ConfirmationBook from "./ConfirmationBook";
import ManagedHoldings from "./ManagedHoldings";

function TransactionRoutes({ user_role_type }) {
  if (["ADMIN", "BROKER"].includes(user_role_type)) {
    return (
      <>
        <Routes>
          <Route path="routebook" element={<RouteBook />} />
          <Route path="orderbook" element={<OrderBook />} />
          <Route path="tradebook" element={<TradeBook />} />
          <Route path="history" element={<OrdersHistory />} />
          <Route path="files" element={<OrderFiles />} />
          <Route path="entry" element={<OrderEntry />} />
          <Route path="holdings" element={<ManagedHoldings />} />
          <Route
            path="*"
            element={<Navigate to="/account/transaction/routebook" />}
          />
        </Routes>
      </>
    );
  } else {
    return (
      <>
        <Routes>

          <Route path="confirmation" element={<ConfirmationBook />} />
          <Route path="history" element={<OrdersHistory />} />
          <Route path="holdings" element={<ManagedHoldings />} />

          <Route
            path="*"
            element={<Navigate to="/account/transaction/confirmation" />}
          />
        </Routes>
      </>
    );
  }
}

export default TransactionRoutes;
