import React from "react";
import { useQuery } from "@tanstack/react-query";
import DashboardBottom from "./DashboardBottom";
import DashboardNews from "./DashboardNews";
import DashboardTop from "./DashboardTop";
import Pulse from "../Loader/Pulse";
import "../../css/Dashboard/Dashboard.css";
import { GetCookie } from "../../exports/CookieManagement";
import { titleCase } from "../../exports/UtilFunctions";
import { GetRoleType } from "../../exports/RoleTypes";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function Dashboard() {
  const account_token = GetCookie("token");
  const role_type = GetRoleType(GetCookie("role"));

  async function GetClientInfo() {
    const role = role_type === "CUSTOMER" ? "customer" : "superuser";

    const response = await fetch(
      `${REQUEST_BASE_URL}/clientinfo/${role}?token=${account_token}`
    );
    const data = await response.json();

    if (data.error) {
      return false;
    }

    return data;
  }

  const { data, isLoading } = useQuery(
    [`client-${account_token}`],
    GetClientInfo
  );

  return (
    <div className="swift-dashboard">
      {isLoading ? (
        <>
          <div className="dashboard-loading">
            <Pulse />
            <p>Loading dashboard, please wait...</p>
          </div>
        </>
      ) : (
        <>
          <div className="dashboard-client">
            <p className="welcome-message">
              Welcome back {titleCase(data?.account_data?.name)}!
            </p>
          </div>
          {data ? (
            <>
              <div className="dashboard-content">
                {role_type === "CUSTOMER" ? (
                  <>
                    <div className="dashboard-left">
                      <DashboardTop user_role_type={role_type} data={data} />
                    </div>
                    <DashboardNews />
                  </>
                ) : (
                  <>
                    <div className="dashboard-left">
                      <DashboardNews />
                      <DashboardTop user_role_type={role_type} data={data} />
                    </div>
                    <DashboardBottom />
                  </>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}

export default Dashboard;
