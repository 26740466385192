import React, { useState, useEffect } from "react";
import CustomSelect from "../Body/CustomChartComponents/CustomSelect/CustomSelect";
import CustomInput from "../Body/CustomChartComponents/CustomInput/CustomInput";
import CustomDate from '../Body/CustomChartComponents/CustomDate/CustomDate';
import { Alert } from '../Body/CustomChartComponents/CustomAlert/CustomAlert';
import axios from "axios";
import NomineeCard from "./NomineeCard";
import UploadPAN from "./UploadPAN";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;
const Gender_Options = ['Male', 'Female'];
const Maritial_Options = ['Married', 'Unmarried'];

function KYCForm({ data }) {

    const { pan, kyc_form_id: token, customer_type, config } = data;
    const [timer, setTimer] = useState(0);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [postObject, setPostObject] = useState({
        email_verified: config.email_verified || false,
        email_id: config.email_id || '',
        mobile_number: config.mobile_number || '',
        marital_status: config.marital_status || 'Married',
        gender: config.gender || 'Male',
        number_of_customers: config.number_of_customers || 1,
        type_of_customer: config.customer_type || customer_type,
        first_name: config.first_name || '',
        middle_name: config.middle_name || '',
        last_name: config.last_name || '',
        user_dob: config.user_dob || '',
        place_of_birth: config.place_of_birth || '',
        pan_location: config.pan_location || false,
        nominee_count: config.nominee_count || 1,
        nominees: config['nominees'] || [{ active: true }]
    });
    const [errors, setErrors] = useState({
        email_id: '',
        mobile_number: '',
        first_name: '',
        last_name: '',
        place_of_birth: "",
        pan_number: "",
        nominee_name_1: "",
        relation_with_applicant_1: "",
        nominee_address_1: "",
        nominee_guardian_name_1: "",
        nominee_guardian_address_1: "",
        nominee_name_2: "",
        relation_with_applicant_2: "",
        nominee_address_2: "",
        nominee_guardian_name_2: "",
        nominee_guardian_address_2: "",
        nominee_name_3: "",
        relation_with_applicant_3: "",
        nominee_address_3: "",
        nominee_guardian_name_3: "",
        nominee_guardian_address_3: "",
        otp: '',
        pan_location: ''
    });
    const errorMsgs = {
        email_id: "Please enter your email id",
        mobile_number: "Please enter your mobile number",
        first_name: "Please enter your first name",
        middle_name: "Please enter your middle name",
        last_name: "Please enter your last name",
        place_of_birth: "Please enter place of birth",
        pan_number: "Please enter PAN card number!",
        nominee_name_1: "Please enter name of nominee",
        relation_with_applicant_1: "Please enter relation with applicant",
        nominee_guardian_name_1: "Please enter name of Guardian",
        nominee_guardian_address_1: "Please enter address of Guadian",
        nominee_address_1: "Please enter address of nominee",
        nominee_name_2: "Please enter name of nominee",
        relation_with_applicant_2: "Please enter relation with applicant",
        nominee_address_2: "Please enter address of nominee",
        nominee_guardian_name_2: "Please enter name of Guardian",
        nominee_guardian_address_2: "Please enter address of Guadian",
        nominee_name_3: "Please enter name of nominee",
        relation_with_applicant_3: "Please enter relation with applicant",
        nominee_address_3: "Please enter address of nominee",
        nominee_guardian_name_3: "Please enter name of Guardian",
        nominee_guardian_address_3: "Please enter address of Guadian",
        otp: "Enter Valid OTP!",
        pan_location: "please submit your pan card"
    }

    let json_keys = ["type_of_customer", 'number_of_customers', 'email_id', 'mobile_number', 'first_name', "last_name", 'middle_name', 'user_dob', 'place_of_birth', 'marital_status', 'pan_number', "nominee_name_1", "nominee_dob_1", 'relation_with_applicant_1', "nominee_address_1", "email_verified", "pan_location"];


    useEffect(() => {
        const counter = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
        return () => clearInterval(counter);
    }, [timer]);

    function isUnder18(dateOfBirth) {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age < 18;
    }

    // regex for email
    function isValidEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    // regex for mobile number
    function isValidMobileNumber(mobileNumber) {
        const regex = /^\d{10}$/;
        return regex.test(mobileNumber);
    }

    function savePostObjectSelect(key, value) {
        postObject[key] = value;
        console.log(postObject);
    }

    function savePostObjectInput(key, value) {

        // setting errors if value is empty
        if (key == "email_id") {
            setErrors((prevState) => {
                const newState = { ...prevState };
                newState[key] = "";
                return newState;
            })
            setPostObject({
                ...postObject,
                [key]: value
            })
        }
        else if (key == 'otp') {
            setErrors((prevState) => {
                const newState = { ...prevState };
                newState[key] = "";
                return newState;
            })
            setPostObject({
                ...postObject,
                [key]: value
            })

            if (value.length === 6) {
                validateOTP(value);
            }

        }
        else if (key == "mobile_number" && value) {
            // for checking mobile number
            if (!isValidMobileNumber(value)) {
                setErrors((prevState) => {
                    const newState = { ...prevState };
                    newState[key] = "Please enter valid mobile number";
                    return newState;
                })
                setPostObject({
                    ...postObject,
                    [key]: value
                })
            } else {
                setErrors((prevState) => {
                    const newState = { ...prevState };
                    newState[key] = "";
                    return newState;
                })
                setPostObject({
                    ...postObject,
                    [key]: value
                })
            }
        } else if (!value) {
            setErrors((prevState) => {
                const newState = { ...prevState };
                newState[key] = errorMsgs[key];
                return newState;
            })
            setPostObject({
                ...postObject,
                [key]: value
            })
        }
        else {
            // remvoing the errors if value is filled
            setErrors((prevState) => {
                const newState = { ...prevState };
                newState[key] = '';
                return newState;
            })
            setPostObject({
                ...postObject,
                [key]: value
            })
        }

    }

    function savePANLink(link) {
        savePostObjectInput('pan_location', link);
        Alert({
            TitleText: 'File Upload',
            Message: 'PAN file uploaded successfully',
            AutoClose: {
                Active: true,
                Line: true,
                LineColor: '#470985',
                Time: 3
            }
        })
    }

    function saveNominee(index, key, value) {
        let nominees = postObject['nominees'];
        nominees[index][key] = value;
        setPostObject({
            ...postObject, nominees
        });
    }

    function nomineeDOBChange(index, key, value) {
        let nominees = postObject['nominees'];
        if (isUnder18(value)) {
            nominees[index][key] = value;
            nominees[index]['minor'] = true;
        }
        else {
            nominees[index][key] = value;
            nominees[index]['minor'] = false;
            nominees[index] = {
                ...nominees[index],
                'nominee_guardian_name': '',
                'nominee_guardian_address': '',
                'nominee_guardian_dob': ''
            }
        }
        setPostObject({
            ...postObject, nominees: [...nominees]
        });


    }

    function nomineePercentageChange(index, key, value) {
        const percentage_array = postObject['nominees'].map((n) => { return n.percentage || 0 });
        percentage_array[index] = value;
        const percentageSum = percentage_array.reduce((a, b) => { return a + b })
        if (percentageSum <= 100) {
            saveNominee(index, key, value)
        }
        else {
            Alert({
                TitleText: 'Warning',
                Message: 'Total nominee percentage must be 100',
                Band: true,
                BandColor: '#E51A4B',
                BoxColor: '#ffffff',
                TextColor: '#000000',
                AutoClose: {
                    Active: true,
                    Line: true,
                    LineColor: '#E51A4B',
                    Time: 3
                }
            })
        }
    }

    function addNominee(current) {
        let nominees = postObject['nominees'];
        nominees[current]['active'] = true;
        postObject['nominee_count'] = postObject['nominee_count'] + 1;
        setPostObject({
            ...postObject, nominees
        });
    }

    function removeNominee(current) {
        current -= 1;
        let nominees = postObject['nominees'];
        nominees[current] = {
            'active': false,
        };
        const nominee = nominees[current];
        nominees = nominees.filter((n, index) => { return index !== current });
        nominees.push(nominee);
        postObject['nominee_count'] = postObject['nominee_count'] - 1;
        setPostObject({
            ...postObject, nominees
        });
    }

    function sendOTP() {
        // if(postObject['email_id'])
        const email = postObject['email_id'] || '';
        if (email === '' || !isValidEmail(email)) {
            setErrors((prevState) => {
                const newState = { ...prevState };
                newState['email_id'] = "Please enter valid email id";
                return newState;
            })
        }
        else {
            axios.post(`${REQUEST_BASE_URL}/access/sendotp?email=${email}&verify=false`).then((response) => {
                const data = response.data;
                if (!data.error) {

                    Alert({
                        TitleText: 'One Time Password',
                        Message: 'OTP sent successfully',
                        AutoClose: {
                            Active: true,
                            Line: true,
                            LineColor: '#470985',
                            Time: 3
                        }
                    })
                    setTimer(30);

                }
                else {
                    setErrors((prevState) => {
                        const newState = { ...prevState };
                        newState['email_id'] = "Something went wrong";
                        return newState;
                    })
                }
            }).catch((error) => {

            });
        }
    }

    function validateOTP(otp) {
        const email = postObject['email_id'] || '';

        if (!email || email == '' || !isValidEmail(email)) {
            setErrors((prevState) => {
                const newState = { ...prevState };
                newState['email_id'] = "Please enter valid email id";
                return newState;
            })
        }
        else if (!otp || otp == '') {
            setErrors(prevState => {
                return {
                    ...prevState,
                    otp: "Please enter valid OTP!"
                }
            })
        }
        else {
            axios.post(`${REQUEST_BASE_URL}/access/validateotp?email=${email}&otp=${otp}&type=OTHER`).then(response => {
                console.log(response.data)
                if (!response.data.error) {
                    Alert({
                        TitleText: 'Email Verification',
                        Message: 'Email Address Verified Successfully',
                        AutoClose: {
                            Active: true,
                            Line: true,
                            LineColor: '#470985',
                            Time: 3
                        }
                    })
                    savePostObjectInput('email_verified', true);
                } else {
                    setErrors(prevState => {
                        return {
                            ...prevState,
                            otp: "Please enter valid OTP!"
                        }
                    })
                    console.log(errors);
                }
            })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    // submit form logic
    function handleSubmitForm() {

        axios.post(`${REQUEST_BASE_URL}/kyc/save?pan=${pan}&token=${token}`, { data: postObject })
            .then((response) => {
                console.log(response.data);
                Alert({
                    TitleText: 'KYC Data Update',
                    Message: 'Your Changes Are Saved Successfully',
                    AutoClose: {
                        Active: true,
                        Line: true,
                        LineColor: '#470985',
                        Time: 3
                    }
                })
            })
            .catch(error => {
                console.error('Error sending data:', error);
            });
    }

    return (
        <>
            <div className="client-content-right">
                <div className='client-sub-form'>
                    <div className="sub-form-header">
                        <p>KYC Individual</p>
                    </div>
                    <div className='account-holder-select'>
                        <CustomSelect
                            heading="No of Account Holders"
                            defaultIndex={0}
                            value={postObject['number_of_customers']}
                            options={[1, 2, 3]}
                            onTypeChange={(value) => {
                                savePostObjectSelect("number_of_customers", value)
                            }}
                        />
                    </div>

                    <div className='sub-primary-form'>
                        <p className="title">Primary Information</p>

                        {postObject['email_verified'] === true ? <>
                            <div className='primary-form-input-select'>
                                <div className='swift-input-box disabled'>
                                    <div className='input-header'>
                                        <p>Email Address</p>
                                    </div>
                                    <input className='swift-input' value={postObject['email_id']} readOnly />
                                    <p className='error-text hide'></p>
                                </div>
                            </div>

                        </> : <>
                            <div className="flex-row">
                                <div className="flex-item">
                                    <div className='swift-input-box'>
                                        <div className='input-header'>
                                            <p>Email Address</p>
                                            {timer > 0 ? <span className='secondary-text'>Resend in {timer} seconds</span> : <span className='primary-text' onClick={sendOTP}>Send OTP</span>}
                                        </div>
                                        <input className='swift-input' value={postObject['email_id']} placeholder="Enter email id" onChange={(e) => { savePostObjectInput("email_id", e.target.value) }} />
                                        <p className={errors.email_id === '' ? 'error-text hide' : 'error-text'}>{errors.email_id}</p>
                                    </div>

                                </div>
                                <div className="flex-item">
                                    <CustomInput heading="One Time Password (OTP)" value={postObject['otp']} onChange={(value) => { savePostObjectInput('otp', value.replace(/[^\d]/g, '')) }} maxLength={6} placeholder="Enter your otp" error={errors.otp} />
                                </div>
                            </div>
                        </>}



                        <div className='primary-form-input-select'>
                            <CustomInput
                                error={errors.mobile_number}
                                onChange={(value) => {
                                    if (!(value.length > 10)) {
                                        let newValue = value;
                                        const regex = /^[0-9]*$/;
                                        if (!regex.test(newValue)) {
                                            newValue = newValue.replace(/[^0-9]/g, '');
                                        }
                                        savePostObjectInput("mobile_number", newValue)
                                    }
                                }}
                                value={postObject['mobile_number']}
                                heading="Mobile Number"
                                placeholder="Enter your mobile number"
                            />
                        </div>

                        <div className='primary-form-input-select'>
                            <CustomInput
                                error={errors.first_name}
                                value={postObject['first_name'] || ''}
                                onChange={(value) => { savePostObjectInput("first_name", value) }}
                                heading="First Name"
                                placeholder="Enter your first name"
                            />
                        </div>

                        <div className='primary-form-input-select'>
                            <CustomInput
                                error={errors.middle_name}
                                value={postObject['middle_name'] || ''}
                                onChange={(value) => { savePostObjectInput("middle_name", value) }}
                                heading="Middle Name"
                                placeholder="Enter your middle name"
                            />
                        </div>

                        <div className='primary-form-input-select'>
                            <CustomInput
                                error={errors.last_name}
                                value={postObject['last_name'] || ''}
                                onChange={(value) => { savePostObjectInput("last_name", value) }}
                                heading="Last Name"
                                placeholder="Enter your last name"
                            />
                        </div>

                        <div className="flex-row">
                            <div className='primary-form-input-select flex-item'>
                                <CustomSelect formSubmit={formSubmitted}
                                    heading="Gender"
                                    defaultIndex={Gender_Options.findIndex((g) => { return g === postObject['gender'] }) || 0}
                                    value={postObject['gender']}
                                    options={Gender_Options}
                                    onTypeChange={(value) => { savePostObjectSelect("gender", value) }}
                                />

                            </div>

                            <div className="primary-form-input-select flex-item">
                                <CustomSelect formSubmit={formSubmitted}
                                    heading="Marital Status"
                                    defaultIndex={Maritial_Options.findIndex((m) => { return m === postObject['marital_status'] }) || 0}
                                    value={postObject['marital_status']}
                                    options={Maritial_Options}
                                    onTypeChange={(value) => { savePostObjectSelect("marital_status", value) }}
                                />

                            </div>
                        </div>

                        <div className="flex-row">
                            <div className="primary-form-input-select flex-item">
                                <CustomDate value={postObject['user_dob']} heading="Date of Birth" onChange={(value) => {
                                    setPostObject({
                                        ...postObject, user_dob: value
                                    })
                                }} />
                            </div>
                            <div className='primary-form-input-select flex-item'>
                                <CustomInput
                                    error={errors.place_of_birth}
                                    value={postObject['place_of_birth']}
                                    onChange={(value) => { savePostObjectInput("place_of_birth", value) }}
                                    heading="Place of Birth"
                                    placeholder="Enter your Place of Birth"
                                />

                            </div>

                        </div>

                        <div className="flex-row">
                            <div className='primary-form-input-select flex-item'>
                                <div className='swift-input-box disabled'>
                                    <div className='input-header'>
                                        <p>PAN Number</p>
                                    </div>
                                    <input className='swift-input' value={pan} readOnly />
                                    <p className='error-text hide'></p>
                                </div>
                            </div>
                        </div>
                        {postObject['pan_location'] ? <>
                            <button className="view-pan-button"><a href={postObject['pan_location']} target="_blank">View Uploaded Document</a></button>
                        </> : <>
                            <div className="pan-upload-btn">
                                <UploadPAN pannumber={pan} saveLocation={(value) => { savePANLink(value) }} />
                            </div>
                        </>}
                    </div>

                    <div className="sub-nomination-form">
                        <p className='title'>Nominee Details</p>

                        {postObject.nominees.map((n, index) => {
                            if (n.active) {
                                return <NomineeCard
                                    key={index}
                                    current={index + 1}
                                    total={postObject['nominee_count']}
                                    minor={n.minor}
                                    name={{
                                        value: n['nominee_name'] || '',
                                        error: '',
                                        onChange: (value) => { saveNominee(index, "nominee_name", value) }
                                    }}
                                    relation={{
                                        value: n['relation_with_applicant'] || '',
                                        error: '',
                                        onChange: (value) => { saveNominee(index, "relation_with_applicant", value) }
                                    }}
                                    address={{
                                        value: n['nominee_address'] || '',
                                        error: '',
                                        onChange: (value) => { saveNominee(index, "nominee_address", value) }
                                    }}
                                    dob={{
                                        value: n['nominee_dob'] || '',
                                        onChange: (value) => { nomineeDOBChange(index, 'nominee_dob', value) }
                                    }}
                                    percentage={{
                                        value: n['percentage'] || 0,
                                        onChange: (value) => {
                                            const regex = /[^0-9]/g;
                                            let newValue = value.toString().replace(regex, '');
                                            newValue = Number(newValue);
                                            nomineePercentageChange(index, 'percentage', newValue)
                                        }
                                    }}
                                    guardianName={{
                                        value: n['nominee_guardian_name'],
                                        error: '',
                                        onChange: (value) => { saveNominee(index, "nominee_guardian_name", value) }
                                    }}
                                    guardianAddress={{
                                        value: n['nominee_guardian_address'],
                                        error: '',
                                        onChange: (value) => {
                                            saveNominee(0, "nominee_guardian_address", value)
                                        }
                                    }}
                                    guardianDob={{
                                        value: n['nominee_guardian_dob'] || '',
                                        onChange: (value) => { saveNominee(index, 'nominee_guardian_dob', value) }
                                    }}
                                    addNominee={addNominee}
                                    removeNominee={removeNominee}
                                />
                            }
                        })}

                    </div>


                </div>
                <div className="sub-submit">
                    <button onClick={() => { handleSubmitForm() }}>Save Changes</button>
                </div>
            </div>
        </>
    )
}

export default KYCForm;