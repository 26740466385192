const axios = require('axios');

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

export const GetPendingOrders = async (account_code) => {

    return new Promise((resolve, reject) => {

        axios.get(`${REQUEST_BASE_URL}/order/routed?account_code=${account_code}`)
            .then((response) => {

                const data = response.data;

                if (!data.error) {
                    let stocks = data.orders;
                    stocks = (stocks || []).map((s) => {
                        return {
                            symbol: s.stockCode,
                            quantity: parseInt(s.Quantity),
                            side: s.Side,
                        }
                    })
                    resolve(stocks)
                }
                else {
                    resolve([]);
                }
            })
            .catch((error) => {
                resolve([]);
            })
    })
}
