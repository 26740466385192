import React, { useState, useEffect, useMemo } from "react";
import ServerRequest from "../../../exports/ServerRequest";
import Pulse from "../../Loader/Pulse";
import { Alert } from "../../Body/CustomChartComponents/CustomAlert/CustomAlert";
import {
  OrderBook,
  UserProfile,
  Edit,
  Cross,
  PlayCircle,
  TickCircle,
} from "../../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../../exports/ColorVars";
import MACSearch from "../../CustomComponents/MACSearch/MACSearch";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  numberFormat,
  priceFormat,
  titleCase,
} from "../../../exports/UtilFunctions";
import PortfolioSearchBar from "../../Body/MenuSection/PortfolioComponents/SearchBar";
import useMarketStock from "../../../hooks/useMarketStock";
import SwiftModal from "../../CustomComponents/SwiftModal/SwiftModal";
import OrderTypePopup from "../../TransactionManagement/OrdersComponents/OrderTypePopup";
import useStockInfo from "../../../hooks/useStockInfo";
import useMovingAverage from "../../../hooks/useMovingAverage";
import CustomIconButton from "../../Body/CustomChartComponents/CustomIconButton/CustomIconButton";
import CustomValueSelect from "../../Body/CustomChartComponents/CustomValueSelect/CustomValueSelect";

function SuccessMessage(title, message) {
  Alert({
    TitleText: title,
    Message: message,
    Band: true,
    BandColor: "#E51A4B",
    BoxColor: "#ffffff",
    TextColor: "#000000",
    AutoClose: {
      Active: true,
      Line: true,
      LineColor: "#E51A4B",
      Time: 5,
    },
  });
}

function AlertMessage(message) {
  Alert({
    TitleText: "Warning",
    Message: message,
    Band: true,
    BandColor: "#E51A4B",
    BoxColor: "#ffffff",
    TextColor: "#000000",
    AutoClose: {
      Active: true,
      Line: true,
      LineColor: "#E51A4B",
      Time: 5,
    },
  });
}

function GenerateOrders() {
  const [customers, setCustomers] = useState(undefined);

  useEffect(() => {
    GetCustomers();
  }, []);


  function ResetScreen(){
    GetCustomers();
  }

  async function GetCustomers() {
    setCustomers(undefined);
    try {
      const data = await ServerRequest({
        method: "GET",
        url: `/backoffice/dealer/customers`,
      });
      const { error, customers = [] } = data;
      if (error) {
        setCustomers([]);
      } else {
        setCustomers(customers);
      }
    } catch (error) {
      setCustomers([]);
    }
  }

  if (customers === undefined || !Array.isArray(customers)) {
    return (
      <div className="dealer-orders loading">
        <Pulse />
        <p>Loading Please wait</p>
      </div>
    );
  }

  if (Array.isArray(customers) && customers.length == 0) {
    return (
      <div className="dealer-orders loading">
        <UserProfile size={40} color={COLOR_VARS["SWIFT_COLOR4"]} />
        <p>You don't have any active customers</p>
      </div>
    );
  }

  return (
    <div className="dealer-orders">
      <OrderScreen customers={customers} ResetScreen={ResetScreen} />
    </div>
  );
}

function OrderScreen({ customers, ResetScreen }) {
  const [customer, setCustomer] = useState(undefined);
  const [portfolio, setPortfolio] = useState(false);
  const [brokers, setBrokers] = useState(false);
  const [orders, setOrders] = useState([]);
  const [config, setConfig] = useState({
    buy_value: 0,
    sell_value: 0,
  });

  const [isPlacing,setIsPlacing] = useState(false);

  useEffect(() => {
    if (!customer) return;
    setOrders([]);
    setConfig({
      buy_value: 0,
      sell_value: 0,
    });
    setPortfolio(undefined);
    setBrokers(undefined);
    GetCustomerPortfolio(customer.account_code);
    GetCustomerBrokers(customer.account_code);
  }, [customers, customer]);

  async function AddSymbol(stock) {
    const data = [...orders];

    let code =
      stock.exchange.exchange.toLowerCase() === "nse"
        ? stock.nse_code
        : stock.code;

    if (!data.map((el) => el.symbol).includes(code)) {
      const { portfolioData = [] } = portfolio;

      let holdings = 0;
      if (Array.isArray(portfolioData) && portfolioData.length > 0) {
        const portfolio_index = portfolioData.findIndex((p) => {
          return p["StockCode"] === code;
        });
        if (portfolio_index != -1) {
          holdings = portfolioData[portfolio_index]["Quantity"];
        }
      }

      const obj = {
        symbol: code,
        name: stock.company,
        isin: stock.isin,
        holdings: holdings,
        quantity: 0,
        current_price: 0,
        current_value: 0,
        broker: brokers[0].broker,
        algo_config: {
          order_type: "MARKET",
          limit_price: 0,
          config: false,
        },
      };
      data.push(obj);
      setOrders(data);
    } else {
      AlertMessage(`${code} already added`);
    }
  }

  async function RemoveSymbol(index) {
    if (index >= orders.length) return;
    const orders_object_copy = [...orders];
    orders_object_copy.splice(index, 1);
    setOrders([...orders_object_copy]);
  }

  async function UpdateProperty(index, key, value) {
    if (index >= orders.length) return;

    const { sum } = portfolio;
    let error_message = false;

    const order = orders[index];
    const { holdings } = order;

    if (key === "quantity") {
      if (!value && value != 0) return;
      if (value < -1 * holdings) {
        error_message = "Cannot sell more than current holding";
      }
    }

    const orders_object_copy = JSON.parse(JSON.stringify(orders));
    orders_object_copy[index][key] = value;

    const { buy_value, sell_value } = CalculateBuySellValue(orders_object_copy);

    if (buy_value !== 0 && parseFloat(sum.cashValue) - buy_value < 0) {
      error_message =
        "You don't have sufficient cash margin to proceed with the order";
    }

    if (error_message === false) {
      setOrders(JSON.parse(JSON.stringify(orders_object_copy)));
      setConfig({
        ...config,
        buy_value: buy_value,
        sell_value: sell_value,
      });
    } else {
      AlertMessage(error_message);
    }
  }

  function CalculateBuySellValue(orders) {
    let buy_value = 0,
      sell_value = 0;

    if (!orders || !Array.isArray(orders) || orders.length === 0)
      return { buy_value, sell_value };

    orders.forEach((order) => {
      const { quantity, current_price, algo_config } = order;
      const { order_type, limit_price } = algo_config;
      const trade_price = order_type === "MARKET" ? current_price : limit_price;
      if (!quantity && quantity != 0) quantity = 0;

      if (quantity < 0) {
        sell_value += Math.abs(quantity) * trade_price;
      } else {
        buy_value += Math.abs(quantity) * trade_price;
      }
    });

    return { buy_value, sell_value };
  }

  async function GetCustomerPortfolio(account_code) {
    try {
      const data = await ServerRequest({
        method: "POST",
        url: `/portfolio?account_code=${account_code}`,
      });

      if (data) {
        setPortfolio(data);
      } else {
        setPortfolio(false);
      }
    } catch (error) {
      setPortfolio(false);
    }
  }

  async function GetCustomerBrokers(account_code) {
    try {
      const data = await ServerRequest({
        method: "GET",
        url: `/clientinfo/brokers?account_code=${account_code}`,
      });

      if (!data.error) {
        setBrokers(data.brokers);
      } else {
        setBrokers(false);
      }
    } catch (error) {
      setBrokers(false);
    }
  }

  async function PlacePortfolioOrder() {
    if (!customer || !orders || !Array.isArray(orders)) return;

    setIsPlacing(true);

    // ResetScreen();
    // setIsPlacing(false);

    // return;

    const account_code = customer?.account_code;

    let stocks = [];
    orders.forEach((order, i) => {
      const { isin, symbol, name, quantity, broker, algo_config } = order;

      if (quantity !== 0) {
        stocks.push({
          isin: isin,
          StockCode: symbol,
          Company: name,
          Quantity: Math.abs(quantity),
          Side: quantity < 0 ? "Sell" : "Buy",
          OrderType: algo_config.order_type,
          LMPrice: algo_config.limit_price,
          Broker: broker
        });
      }
    });

    try {
      const data = await ServerRequest({
        method: "POST",
        url: "/backoffice/dealer/placeorder",
        data: {
          account_code: account_code,
          stocks: stocks,
        },
      });

      if (data.error) {
        AlertMessage(`Something went wrong while placing the order!`);
      } else {
        SuccessMessage(
          `Order Placed Successfully!`,
          "Please visit order files to download the orders file"
        );
        ResetScreen();
      }
    } catch (error) {}
    finally{
      setIsPlacing(false);
    }
  }

  useEffect(() => {
    if (!portfolio) return;
    const { sum } = portfolio;
    setConfig({
      ...config,
      cash_position: sum.cashValue || 0,
    });
  }, [portfolio]);

  return (
    <>
      <CustomersHeader
        customers={customers}
        customer={customer}
        setCustomer={setCustomer}
        portfolio={portfolio}
        AddSymbol={AddSymbol}
      />
      <CustomerOrders
        orders={orders}
        portfolio={portfolio}
        brokers={brokers}
        config={config}
        UpdateProperty={UpdateProperty}
        RemoveSymbol={RemoveSymbol}
        PlacePortfolioOrder={PlacePortfolioOrder}
        isPlacing={isPlacing}
      />
    </>
  );
}

function CustomersHeader({
  customers,
  customer,
  setCustomer,
  portfolio,
  AddSymbol,
}) {
  return (
    <div className="dealer-orders-header">
      <div className="header-customers">
        <MACSearch
          title="Search Client"
          customers={customers}
          onSelect={(val) => {
            setCustomer(val);
          }}
          selectedCustomer={customer}
        />
      </div>
      <div className="header-config">
        {portfolio && (
          <>
            <div className="portfolio__search__bar">
              <PortfolioSearchBar addTableRow={AddSymbol} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function CustomerOrders({
  orders,
  portfolio,
  brokers,
  config,
  UpdateProperty,
  RemoveSymbol,
  PlacePortfolioOrder,
  isPlacing
}) {
  const [execute, setExecute] = useState(false);

  const { cash_position, buy_value, sell_value } = config;

  function PlaceOrder() {
    if (buy_value !== 0 && cash_position - buy_value < 0) {
      AlertMessage(
        "You don't have sufficient cash margin to proceed with the order"
      );
    } else {
      setExecute(true);
    }
  }

  if (portfolio === false || brokers === false) return;

  return (
    <>
      {portfolio === undefined || isPlacing ? (
        <>
          <div className="dealer-orders-table-wrapper loading">
            <Pulse />
          </div>
        </>
      ) : (
        <>
          <div className="dealer-orders-table-wrapper">
            <div className="dealer-orders-table">
              {orders.length === 0 ? (
                <>
                  <div className="orders-table-empty">
                    <OrderBook size={40} color={COLOR_VARS["SWIFT_COLOR4"]} />
                    <p>Add symbols to generate trades</p>
                  </div>
                </>
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <td className="name">Name</td>
                        <td className="holdings">Holdings</td>
                        <td className="quantity">Quantity</td>
                        <td className="type">Order Type</td>
                        <td className="price">Limit Price</td>
                        <td className="ltp">Current Price</td>
                        <td className="bid">Bid Price</td>
                        <td className="ask">Ask Price</td>
                        <td className="trade">Trade Value</td>
                        <td className="broker">Broker</td>
                        <td className="remove"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map((o, index) => {
                        return (
                          <CustomerOrderRow
                            key={o.symbol}
                            index={index}
                            order={o}
                            brokers={brokers}
                            UpdateProperty={UpdateProperty}
                            RemoveSymbol={RemoveSymbol}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </>
              )}
            </div>
            <div className="dealer-orders-footer">
              <div className="trade-value">
                <p>
                  Current Cash: &nbsp;
                  <span>{priceFormat(cash_position)}</span>
                </p>
                <p>
                  Total Buy Value: &nbsp;
                  <span className="positive">{priceFormat(buy_value)}</span>
                </p>
                <p>
                  Total Sell Value: &nbsp;
                  <span className="negative">{priceFormat(sell_value)}</span>
                </p>
                <p>
                  Cash Position Post Trade: &nbsp;
                  <span>
                    {priceFormat(cash_position - buy_value + sell_value)}
                  </span>
                </p>
              </div>
              <div className="trade__button">
                {execute ? (
                  <>
                    <CustomIconButton
                      disabled={buy_value === 0 && sell_value === 0}
                      onClick={PlacePortfolioOrder}
                      icon={<PlayCircle size={20} />}
                      title="Route Order"
                    />
                  </>
                ) : (
                  <>
                    <CustomIconButton
                      disabled={buy_value === 0 && sell_value === 0}
                      onClick={PlaceOrder}
                      icon={<TickCircle size={20} />}
                      title="Confirm Routing"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

function CustomerOrderRow({
  index,
  order,
  brokers,
  UpdateProperty,
  RemoveSymbol,
}) {
  const { symbol, holdings, quantity, broker, algo_config } = order;

  const { order_type, limit_price, config } = algo_config;

  const [typeOpen, setTypeOpen] = useState(false);
  const [movingPrice, setMovingPrice] = useState(0);

  const stock_data = useMarketStock(symbol);
  const { stock_info } = useStockInfo(symbol);

  useEffect(() => {
    if (!stock_data) return;
    UpdateProperty(index, "current_price", stock_data.last_traded_price);
  }, [stock_data]);

  useEffect(() => {
    if (order_type === "MARKET" || order_type === "LIMIT") {
      return;
    }
    UpdateProperty(index, "algo_config", algo_config);
  }, [movingPrice]);

  const broker_list = useMemo(() => {
    return brokers.map((b) => {
      return b["broker"];
    });
  }, [brokers]);

  function onChangeOrder(event) {
    const value = event.target.value;

    if (/^-?\d*$/.test(value) || value === "") {
      UpdateProperty(index, "quantity", value);
    }
  }

  function onBlurOrder(event) {
    const value = event.target.value;
    if (value === "") {
      UpdateProperty(index, "quantity", 0);
    }
  }

  function onKeyDownOrder(event) {
    let value = event.target.value;

    if (value == "") {
      value = "0";
    }

    value = parseInt(value);

    const keyCode = event.keyCode;

    if (keyCode === 38) {
      event.preventDefault();
      UpdateProperty(index, "quantity", value + 1);
    } else if (keyCode === 40) {
      event.preventDefault();
      UpdateProperty(index, "quantity", value - 1);
    }
  }

  function ModifyOrder(order_config) {
    let { type, limit_price, algo_config: config } = order_config;

    if (type === "MARKET") {
      limit_price = 0;
      config = false;
    } else if (type === "LIMIT") {
      limit_price = limit_price;
      config = false;
    } else {
      type = "LIMIT";
    }

    const algo_config = {
      order_type: type,
      limit_price: limit_price,
      config: config,
    };

    UpdateProperty(index, "algo_config", algo_config);
  }

  if (!stock_data || !stock_info) return;

  const { last_traded_price, best_ask_price, best_bid_price } = stock_data;
  const { code, exchange } = stock_info;

  const trade_price = order_type === "LIMIT" ? limit_price : last_traded_price;
  const trade_value = quantity * trade_price;

  return (
    <>
      <tr>
        <td>{symbol}</td>
        <td>{holdings}</td>
        <td>
          <div className="quantity-box">
            <span
              onClick={() => {
                if (-1 * holdings === quantity) {
                  UpdateProperty(index, "quantity", 0);
                } else {
                  UpdateProperty(index, "quantity", -1 * holdings);
                }
              }}
            >
              FS
            </span>
            <span
              onClick={() => {
                UpdateProperty(index, "quantity", parseInt(quantity) - 1);
              }}
            >
              <RemoveIcon fontSize="14" color={COLOR_VARS["SWIFT_COLOR4"]} />
            </span>
            <input
              type="text"
              value={quantity}
              onChange={(e) => {
                onChangeOrder(e);
              }}
              onBlur={(e) => {
                onBlurOrder(e);
              }}
              onKeyDown={(e) => {
                onKeyDownOrder(e);
              }}
            />
            <span
              onClick={() => {
                UpdateProperty(index, "quantity", parseInt(quantity) + 1);
              }}
            >
              <AddIcon fontSize="14" color={COLOR_VARS["SWIFT_COLOR4"]} />
            </span>
          </div>
        </td>
        <td>
          {quantity != 0 ? (
            <div
              className="ordertype-box"
              onClick={() => {
                setTypeOpen(true);
              }}
            >
              <Edit size={14} color={COLOR_VARS["SWIFT_COLOR4"]} />
              <p>{titleCase(order_type)}</p>
            </div>
          ) : (
            <div className="ordertype-box">-</div>
          )}
        </td>
        <td>
          {config ? (
            <>
              <MovingAverage
                symbol={symbol}
                current_price={last_traded_price}
                side={order < 0 ? "SELL" : "BUY"}
                time_window={config.time_window}
                data_points={config.data_points}
                setMovingPrice={setMovingPrice}
              />
            </>
          ) : order_type === "LIMIT" ? (
            numberFormat(limit_price)
          ) : (
            "-"
          )}
        </td>
        <td>{numberFormat(last_traded_price)}</td>
        <td>{numberFormat(best_bid_price)}</td>
        <td>{numberFormat(best_ask_price)}</td>
        <td className={trade_value < 0 ? "negative" : "positive"}>
          {numberFormat(trade_value)}
        </td>
        <td>
          <div className="config-box broker">
            <CustomValueSelect
              value={broker}
              options={broker_list}
              onChange={(value) => {
                UpdateProperty(index, "broker", value);
              }}
            />
          </div>
        </td>
        <td>
          <div
            className="remove-symbol"
            onClick={() => {
              RemoveSymbol(index);
            }}
          >
            <Cross size={20} color={COLOR_VARS["SWIFT_COLOR4"]} />
          </div>
        </td>
      </tr>
      {typeOpen && (
        <>
          <SwiftModal
            top="2%"
            closeModal={() => {
              setTypeOpen(false);
            }}
          >
            <OrderTypePopup
              order={{
                ordertype: order_type,
                code: code,
                exchange: exchange.exchange,
                quantity: quantity,
                side: quantity >= 0 ? "BUY" : "SELL",
                name: symbol,
                broker: "-",
                limitprice: limit_price,
                algo_config: config,
              }}
              current_price={last_traded_price}
              ModifyOrder={ModifyOrder}
              setTypeOpen={setTypeOpen}
              closePopup={() => {
                setTypeOpen(false);
              }}
            />
          </SwiftModal>
        </>
      )}
    </>
  );
}

function MovingAverage({
  symbol,
  current_price,
  side,
  time_window,
  data_points,
  setMovingPrice,
}) {
  let movingPrice = useMovingAverage(symbol, side, time_window, data_points);
  movingPrice = movingPrice == 0 ? current_price : movingPrice;
  useEffect(() => {
    setMovingPrice(movingPrice);
  }, [movingPrice]);

  return <>{numberFormat(movingPrice)}</>;
}

function PortfolioConfig({ portfolio }) {
  const { sum } = portfolio;
  return (
    <>
      <div className="portfolio-config-box">
        <span>Portfolio Value</span>
        <p>{priceFormat(sum.currentValueSum)}</p>
      </div>
      <div className="portfolio-config-box">
        <span>Cash Position</span>
        <p>{priceFormat(sum.cashValue)}</p>
      </div>
    </>
  );
}

export default GenerateOrders;
