import React from "react";
import Cross from "../../../../assets/icons/closesmall.svg";
import "../../../../css/Popup/StockDetailsPopup.css";
import useFullSnapQuote from "../../../../hooks/useFullSnapQuote";
import useStockInfo from "../../../../hooks/useStockInfo";
import Pulse from "../../../Loader/Pulse";

function NumberFormat(num) {
  if (!num && num != 0) return "-";
  return parseFloat(num).toLocaleString("en-IN", {
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

function CurrencyFormat(num) {
  if (!num && num != 0) return "-";
  return parseFloat(num).toLocaleString("en-IN", {
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}


function GenerateTableData(price_array,quantity_array,customers_array){


    function isset(val){
        if(val || val == 0) return val;
        return '-';
    }

    const data = [];
    if(Array.isArray(price_array) && price_array.length != 0){
        price_array.forEach((p,index)=>{
            data.push({
                price: isset(price_array[index]),
                quantity: isset(quantity_array[index]),
                customers: isset(customers_array[index])
            })
        });
    }

    return data;
}

function StockDetailsPopup({ stock,closePopup }) {

  const { symbol = false} = stock;
  const snapquote = useFullSnapQuote(symbol);
  const { loading, stock_info } = useStockInfo(symbol);

  if(loading || !snapquote){
    return <div className="stock__details__popup loading">
        <Pulse />
        <p>Loading data....</p>
    </div>
  }

  const {top_5_buyers=[],top_5_sellers=[],top_5_bid_prices=[],top_5_ask_prices=[],top_5_bid_quantities=[],top_5_ask_quantities=[]} = snapquote;
  const {name,exchange} = stock_info;
  
  let exchange_name = exchange.exchange;

  const buy_data = GenerateTableData(top_5_bid_prices,top_5_bid_quantities,top_5_buyers);
  const sell_data = GenerateTableData(top_5_ask_prices,top_5_ask_quantities,top_5_sellers);

  return (
    <div className="stock__details__popup">
      <div className="sd__header">
        <div className="sd__heading">
          <p>
            <span>{symbol}</span>&nbsp;:&nbsp;
            <span>{exchange_name}</span>
          </p>
          <span>{name}</span>
        </div>
        <div className="sd__close" onClick={() => {closePopup()}}>
          <img src={Cross} />
        </div>
      </div>
      <div className='sd__body'>
        <div className='sd__tables'>
        <div className='buyers__table'>
            <table>
                <thead>
                    <tr>
                        <th>Bid Price</th>
                        <th>Quantity</th>
                        <th>Buyers</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(buy_data) && buy_data.length > 0 ? <>
                        {buy_data.map((b,index)=>{
                            return <tr key={index}>
                                <td>{CurrencyFormat(b['price'])}</td>
                                <td className='buy_quantity'>{NumberFormat(b['quantity'])}</td>
                                <td>{b['customers']}</td>
                            </tr>
                        })}
                    </> : <>
                    </>}
                </tbody>
            </table>
        </div>
        <div className='sellers__table'>
            <table>
                <thead>
                    <tr>
                        <th>Ask Price</th>
                        <th>Quantity</th>
                        <th>Sellers</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(sell_data) && sell_data.length > 0 ? <>
                        {sell_data.map((s,index)=>{
                            return <tr key={index}>
                                <td>{CurrencyFormat(s['price'])}</td>
                                <td className='sell_quantity'>{NumberFormat(s['quantity'])}</td>
                                <td>{s['customers']}</td>
                            </tr>
                        })}
                    </> : <>
                    </>}
                </tbody>
            </table>
        </div>
        </div>
        <div className='sd__prices__wrapper'>
            <div className='sd__prices'>
                <PriceRow data={[
                    { title: "Open Price", value: CurrencyFormat(snapquote["open_price"]) },
                    { title: "Close Price", value: CurrencyFormat(snapquote["close_price"]) },
                    { title: "High Price", value: CurrencyFormat(snapquote["high_price"]) },
                    { title: "Low Price", value: CurrencyFormat(snapquote["low_price"]) },
                ]} />
            </div>
            <div className='sd__prices'>
                <PriceRow data={[
                    { title: "Total Buy Quantity", value: NumberFormat(snapquote["total_buy_quantity"]), class_name: 'buy_quantity' },
                    { title: "Total Sell Quantity", value: NumberFormat(snapquote["total_sell_quantity"]), class_name: 'sell_quantity' },
                    { title: "Average Trade Price", value: CurrencyFormat(snapquote["average_traded_price"]) },
                    { title: "Volume", value: NumberFormat(snapquote["volume"]) },
                ]} />
            </div>
        </div>
      </div>
    </div>
  );
}


function PriceRow({data=[]}) {
  return (
    data.map(({ title, value, class_name = "" }, i) =>
        <div key={i} className='sd__row'>
            <div className='sd__row__title'>{title}</div>
            <div className={`sd__row__value ${class_name}`}>{value ? value : 0}</div>
        </div>
    )
  )
}


export default StockDetailsPopup;
