import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const SwiftTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#ffffff',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f5',
        fontSize: 12,
        padding: '5px 15px',
        letterSpacing: '-0.5px',
        fontWeight: 500,
        borderRadius: 30,
        fontFamily: 'Open Sans',
        color: 'rgba(1, 22, 39, 0.90)',
        borderColor: '#e8e8e8'
    },
}));

function SwiftToolTip({ children = <></>, placement = 'bottom-end', title = 'Title' }) {
    return (
        <SwiftTooltip title={title} placement={placement} className='swift-tooltip'>
            {children}
        </SwiftTooltip>
    )
}

export default SwiftToolTip;