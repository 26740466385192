import React, { useEffect, useState, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import $ from "jquery";
import Pulse from "../Loader/Pulse";
import { getCandleDuration } from "../../exports/MessageStructure";
import {
  getFuturePoints,
  getStartPointIndex,
} from "../../exports/FutureEntries";
import { convertToUNIX, dateToUNIX } from "../../exports/TimeConverter";
import { splitAdjustmentArray } from "../../exports/SplitAdjustment";
import {
  AddSquare,
  ArrowLeft,
  MaxiMize,
  MinusSquare,
} from "../CustomComponents/SwiftIcon/Icons";
import { numberFormat, priceFormat } from "../../exports/UtilFunctions";
import { GetRoleType } from "../../exports/RoleTypes";
import { GetCookie } from "../../exports/CookieManagement";
import useMarketStock from "../../hooks/useMarketStock";
import useStockInfo from "../../hooks/useStockInfo";
import useInterval from "../../hooks/useInterval";
import StockChartNew from "./StockChartNew";
import { COLOR_VARS } from "../../exports/ColorVars";
import { getDeviceSize } from "../../exports/InteractiveUtils";
import moment from "moment";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const RANGE_ARRAY = [
  { title: "1H", value: "D" },
  { title: "1D", value: "1D" },
  { title: "5D", value: "5D" },
  { title: "1M", value: "1M" },
  { title: "3M", value: "3M" },
  { title: "6M", value: "6M" },
  { title: "YTD", value: "YTD" },
];

function ChartContainer({ symbol, OpenOrderPopup }) {
  const user_role = GetCookie("role");
  const user_role_type = GetRoleType(GetCookie("role"));
  const stock_data = useMarketStock(symbol);
  const { stock_info } = useStockInfo(symbol);

  const controllerRef = useRef();
  const [config, setConfig] = useState(false);
  const [chartLoading, setChartLoading] = useState(false);
  const [range, setRange] = useState("1D");
  const [chartProps, setChartProps] = useState(false);
  const [chartData, setChartData] = useState(false);
  const [chartDimension, setChartDimension] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  useInterval(() => {
    if (!config) return;
    UpdateChartData(range, config);
  }, 10000);

  useEffect(() => {
    // console.log("UE1", symbol);
    setChartLoading(true);
    setChartData(false);
    setChartProps(false);
  }, [symbol]);

  useEffect(() => {
    // console.log("UE2", symbol, range);
    setChartLoading(true);
    const options = getCandleDuration(range);
    setConfig(options);
  }, [range]);

  useEffect(() => {
    // console.log("UE3", symbol, range);
    /* GET THE CHART DATA */
    async function LoadChartData(type, config) {
      async function GetStockArray(type) {
        return new Promise(async (resolve, reject) => {
          if (chartData[type]) {
            if (Array.isArray(chartData[type]) && chartData[type].length > 0) {
              resolve(chartData[type]);
              return;
            }
          }

          let START_UNIX = convertToUNIX(type);
          const { exchange, nse_code, bse_code, code: token } = stock_info;

          let exchange_name = exchange.exchange;
          let code = exchange_name === "NSE" ? nse_code : bse_code;

          const params = {
            code: code,
            ct: ct,
            starttime: START_UNIX,
            dd: dd,
            exchange: exchange_name,
            token: token,
            mixed: mixed,
            type: type,
            index: false,
          };

          const stock_array = await FetchChartDataAPI(params);
          resolve(stock_array);
        });
      }

      console.log("CONFIG", config);
      const { candle: ct, duration: dd, mixed } = config;
      // console.log(chartData)

      let stock_array = await GetStockArray(type);

      if (stock_array && stock_array.length != 0) {
        FormatStockData(type, stock_array, false);
        setChartData({
          ...chartData,
          [type]: stock_array,
        });
      } else {
        setChartLoading(false);
      }
    }

    // console.log(!stock_info, !config);
    if (!stock_info || !config) return;

    SetInitialSize();
    LoadChartData(range, config);

    return () => {
      console.log("ABORT CONTROLLER", symbol, range);
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [stock_info, config]);

  async function UpdateChartData(type, config) {
    const { candle: ct, duration: dd, mixed } = config;

    const { last_point = false } = chartProps;

    if (!last_point || !last_point.date) return;

    let START_UNIX = dateToUNIX(last_point.date, type);
    // console.log(last_point)
    const { exchange, nse_code, bse_code, code: token } = stock_info;

    let exchange_name = exchange.exchange;
    let code = exchange_name === "NSE" ? nse_code : bse_code;

    const stock_array = await FetchChartDataAPI({
      ct: ct,
      starttime: START_UNIX,
      dd: dd,
      exchange: exchange_name,
      token: token,
      code: code,
      mixed: mixed,
      type: type,
      index: false,
    });

    if (stock_array && stock_array.length != 0) {
      if (moment(stock_array[0][0]).unix() === moment(last_point.date).unix()) {
        stock_array.splice(0, 1);
      }

      if (stock_array.length === 0) return;

      FormatStockData(type, stock_array, true);
      const old_array = chartData[type] || [];
      const new_array = old_array.concat(stock_array);
      // console.log(old_array.length,new_array.length)
      setChartData({
        ...chartData,
        [type]: new_array,
      });
    }
  }

  async function FetchChartDataAPI(params) {
    return new Promise((resolve, reject) => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }

      controllerRef.current = new AbortController();
      const signal = controllerRef.current.signal;

      fetch(`${REQUEST_BASE_URL}/stockdata?${new URLSearchParams(params)}`, {
        method: "GET",
        signal: signal,
      })
        .then(async (response) => {
          response = await response.json();

          if (response.status !== "success") {
            resolve(false);
            return;
          }
          const data = response.data;
          // console.log(data.length);
          resolve(data);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }

  function FormatStockData(type, stock_array, update = false) {
    // console.log("FORMAT STOCK DATA", type, stock_array.length);

    if (stock_array.length === 0) return;

    const { chart_data = [] } = chartProps;

    let temp_data_array = update ? chart_data : [];

    let converted_data = splitAdjustmentArray(stock_array);
    // console.log(converteddata);

    converted_data.forEach((d) => {
      let dobj = {
        date: new Date(d["date"]),
        open: parseFloat(d["open"]),
        high: parseFloat(d["high"]),
        low: parseFloat(d["low"]),
        close: parseFloat(d["close"]),
        volume: parseInt(d["volume"]),
      };

      temp_data_array.push(dobj);
    });

    // console.log(temp_data_array.length);

    let first_point = temp_data_array[0];
    let last_point = temp_data_array[temp_data_array.length - 1];

    // console.log(first_point, last_point);

    let START_INDEX = getStartPointIndex(
      temp_data_array,
      type,
      last_point,
      first_point
    );

    // console.log(last_point,first_point,START_INDEX);

    let future_points = getFuturePoints(last_point, type);

    setChartProps({
      chart_data: temp_data_array,
      extra_data: future_points,
      last_point: last_point,
      start_index: START_INDEX,
      extra_points: future_points.length,
      range: type,
    });

    // console.log(temp_data_array,future_points,last_point,START_INDEX,type);

    setChartLoading(false);
  }

  function SetInitialSize() {
    let wd = $(".stock__chart").width();
    let ht = $(".stock__chart").height();

    setChartDimension({
      width: wd,
      height: ht,
    });
  }

  function SetAction(action) {
    if (user_role_type === "BROKER" || user_role === "BRC") {
      OpenOrderPopup(action);
    }
  }

  if (!stock_info || !stock_data) {
    return (
      <>
        <div className="chart__container">
          <div className="stock__info__chart">
            <div className="stock__chart stock__chart__blur ">
              <div className="stock__chart__pulse">
                <Pulse />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  const DEVICE_SIZE = getDeviceSize();

  const {
    close_price = 0,
    last_traded_price = 0,
    change_price = 0,
    change_percentage = 0,
  } = stock_data;
  let price_class = parseFloat(change_price) >= 0 ? "positive" : "negative";

  return (
    <>
      {DEVICE_SIZE === "S" && (
        <>
          <div className="chart__mobile__header">
            <div
              className="chart__close"
              onClick={() => {
                searchParams.delete("symbol");
                setSearchParams(searchParams);
              }}
            >
              <ArrowLeft size={24} color={COLOR_VARS["SWIFT_COLOR4"]} />
            </div>
          </div>
        </>
      )}
      <div className="chart__container">
        <div className="chart__container__stock__info">
          <div className="stock__info">
            <div className="stock__details">
              <p className="stock__name__code">
                <span id="stock__code">{stock_info.symbol}</span>
              </p>
              <div id="stock__full__name">
                <span>{stock_info.company}</span>
              </div>
            </div>
            <div className="stock__price__purchase">
              <div className="stock__price__details">
                <div className="price__decimals">
                  {numberFormat(last_traded_price, 2)}
                </div>
              </div>
              <div className="stock__price__change">
                <div className={price_class + " stock__performance__amount"}>
                  {numberFormat(change_price, 2)}
                </div>
                <div
                  className={price_class + " stock__performance__percentage"}
                  style={{ display: "flex" }}
                >
                  ({change_percentage})
                </div>
              </div>
            </div>
          </div>
          <div className="stock__purchase">
            <div className="buy__sell__box">
              {user_role_type === "BROKER" || user_role === "BRC" ? (
                <>
                  <div
                    className="buy__stock"
                    onClick={() => {
                      SetAction("B");
                    }}
                  >
                    <AddSquare size={22} color={COLOR_VARS["SWIFT_COLOR3"]} />
                  </div>
                  <div
                    className="sell__stock"
                    onClick={() => {
                      SetAction("S");
                    }}
                  >
                    <MinusSquare size={22} color={COLOR_VARS["SWIFT_COLOR2"]} />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            {/* <p className="currency">INR</p> */}
          </div>
        </div>
        <div className="stock__info__chart">
          {chartLoading ? (
            <>
              <div className="stock__chart stock__chart__blur ">
                <div className="stock__chart__pulse">
                  <Pulse />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="stock__chart">
                {chartProps && (
                  <>
                    <StockChartNew
                      chartDimension={chartDimension}
                      chartProps={chartProps}
                      closePrice={close_price}
                      currentPrice={last_traded_price}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <div className="chart__range">
          <div className="chart__range__left">
            {RANGE_ARRAY.map((r) => {
              return (
                <RangeBox
                  key={r.title}
                  range={r}
                  active={range === r.value}
                  setRange={setRange}
                />
              );
            })}
          </div>
          <div className="chart__range__right">
            <span>
              <Link to={`/stocks?symbol=${symbol}`} target="_blank">
                {DEVICE_SIZE !== 'S' ? `Detailed View`: <MaxiMize size={16} color={COLOR_VARS['SWIFT_COLOR4']} />}
              </Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

function RangeBox({ range, active, setRange }) {
  return (
    <>
      <div
        className={`chart__range__value ${active ? "active__range" : ""} `}
        onClick={() => {
          setRange(range.value);
        }}
      >
        {range.title}
      </div>
    </>
  );
}

export default ChartContainer;
