import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Pulse from '../Loader/Pulse';
import TradeSection from './OrdersComponents/TradeSection';
import { GetRoleType } from '../../exports/RoleTypes';
import { GetCookie } from '../../exports/CookieManagement';
import { Book1 } from '../CustomComponents/SwiftIcon/Icons';
import { COLOR_VARS } from '../../exports/ColorVars';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function TradeBook() {


    const [loading, setLoading] = useState(true);
    const [trades, setTrades] = useState(false);
    const [userRole,setUserRole] = useState(false);


    function FetchUserTrades() {
        setLoading(true);
        const account_token = GetCookie('token');
        const user_role_type = GetRoleType(GetCookie('role'));
        setUserRole(user_role_type);

        Axios.get(`${REQUEST_BASE_URL}/order/usertrade?token=${account_token}`).then((response) => {
            if (!response.data.error) {
                setTrades(response.data.data);
                setLoading(false);
            }
            else {
                setLoading(false);
                setTrades([]);
            }
        })
        .catch((err) => {
            setLoading(false);
            setTrades([]);
        });
    }

    useEffect(() => {
        FetchUserTrades();
    }, []);

    return (
        <div className="orders-wrapper full">
            <div className='table-container'>
                {loading === true ? <>
                    <div className="table-loader">
                        <Pulse />
                        <p>Fetching your trades...</p>
                    </div>
                </> : <>
                    {Object.entries(trades).length > 0 ?
                        <>

                            <div className='order-trades-section'>
                                <div className='order-trades-header'>
                                    <div className='order-trades-config'>
                                        <div className='config-box arrow'>
                                        </div>
                                        <div className='config-box name'>
                                            <span>Customer Name</span>
                                        </div>
                                        <div className='config-box account'>
                                            <span>Account Type</span>
                                        </div>

                                        <div className='config-box count'>
                                            <span>Total Trades</span>
                                        </div>
                                        <div className='config-box sell'>
                                            <span>Sell Value</span>
                                        </div>
                                        <div className='config-box buy'>
                                            <span>Buy Value</span>
                                        </div>
                                        <div className='config-box trade'>
                                            <span>Trade Value</span>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {Object.entries(trades).map(([key, order]) => {
                                if (!order.error) {
                                    return <TradeSection
                                        key={key}
                                        user_role_type={userRole}
                                        order={order}
                                    />
                                }
                            })}

                        </>
                        :
                        <div className='order-container-empty'>
                            <Book1 size={40} color={COLOR_VARS['SWIFT_COLOR4']}  />
                            <div>
                                <p>No trades found</p>
                                <span>Looks like you have't made any trades today</span>
                            </div>
                        </div>}
                </>}
            </div>
        </div>
    )
}

export default TradeBook;