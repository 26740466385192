import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import MACSearch from '../CustomComponents/MACSearch/MACSearch';
import CustomSelect from '../Body/CustomChartComponents/CustomSelect/CustomSelect';
import CustomIconButton from '../Body/CustomChartComponents/CustomIconButton/CustomIconButton';
import { ArrowRight2, Cross } from '../CustomComponents/SwiftIcon/Icons';
import { GetCookie } from '../../exports/CookieManagement';
import { Alert } from '../Body/CustomChartComponents/CustomAlert/CustomAlert';
import { COLOR_VARS } from '../../exports/ColorVars';
import { getDeviceSize } from '../../exports/InteractiveUtils';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;
const DEVICE_SIZE = getDeviceSize();

const report_types = ['Factsheet Statement', 'Gain & Loss Statement'];
const report_frequencies = ['Daily', 'Weekly', 'Monthly'];
const report_targets = ['Individual Customer', 'Family Portfolio'];

function ReportScheduler({ user_role_type, customers, portfolios, setScheduler, setScheduled }) {

    const [accountCode, setAccountCode] = useState(false);
    const [portfolioID, setPortfolioID] = useState(false);
    const [reportTarget, setReportTarget] = useState(report_targets[0]);
    const [reportType, setReportType] = useState(report_types[0]);
    const [reportFrequency, setReportFrequency] = useState(report_frequencies[0]);

    useEffect(() => {
        if (user_role_type === 'CUSTOMER' && reportTarget === report_targets[0]) {
            if (customers.length === 0) return;
            setCustomer(customers[0]);
        }

    }, [accountCode, customers, reportTarget]);

    function setCustomer(customer) {
        setAccountCode(customer.account_code);
    }

    function setPortfolio(portfolio) {
        const portfolio_id = portfolio.family_portfolio_id;
        setPortfolioID(portfolio_id);
    }

    function getReportType(report_type) {
        if (report_type === 'Factsheet Statement') {
            return 'factsheet';
        }
        else {
            return 'gainloss';
        }
    }

    function getReportFrequency(report_frequency) {
        if (report_frequency === 'Daily')
        {
            return 'daily';
        }
        else if (report_frequency === 'Weekly') {
            return 'weekly';
        }
        else {
            return 'monthly';
        }
    }

    function getReportTarget(report_target) {
        if (report_target === 'Individual Customer') {
            return 'customer';
        }
        else {
            return 'family';
        }
    }

    function scheduleReport() {

        const account_token = GetCookie('token');
        let account_code = accountCode;
        let portfolio_id = portfolioID;
        let report_target = getReportTarget(reportTarget);
        let report_type = getReportType(reportType);
        let report_frequency = getReportFrequency(reportFrequency);

        Axios.post(`${REQUEST_BASE_URL}/reports/schedule?token=${account_token}`, {
            report_target,
            portfolio_id,
            account_code,
            report_type,
            report_frequency
        }).then((response) => {
            const data = response.data;
            if (data.error) {
                if (data.exists) {
                    Alert({
                        TitleText: 'Report Schedule',
                        Message: 'The report already exists in the account',
                        BandColor: '#E51A4B',
                        AutoClose: {
                            Active: true,
                            Line: true,
                            LineColor: '#E51A4B',
                            Time: 3
                        }
                    });
                }
                else {
                    Alert({
                        TitleText: 'Report Schedule',
                        Message: 'Something went wrong while scheduling the report',
                        BandColor: '#E51A4B',
                        AutoClose: {
                            Active: true,
                            Line: true,
                            LineColor: '#E51A4B',
                            Time: 3
                        }
                    });
                }
            }
            else {
                Alert({
                    TitleText: 'Report Schedule',
                    Message: 'The report has been scheduled successfully',
                    BandColor: '#470985',
                    AutoClose: {
                        Active: true,
                        Line: true,
                        LineColor: '#470985',
                        Time: 3
                    }
                });

                setScheduled(Math.floor(Math.random() * 1000));
            }

            closeScheduler();

        }).catch((err) => {
            console.log(err);
        });
    }

    function closeScheduler() {
        setAccountCode(false);
        setReportType(report_types[0]);
        setReportFrequency(report_frequencies[0]);
        setScheduler(false);
    }

    return (
        <>
            <div className='report-scheduler-header'>
                <p className='report-scheduler-title'>Report Scheduler</p>
                <div className='report-scheduler-close' onClick={closeScheduler}>
                    <Cross size={DEVICE_SIZE === 'S' ? 26 : 24} color={COLOR_VARS['SWIFT_COLOR4']} />
                </div>
            </div>
            <div className='report-scheduler-content'>
                <div className='report-scheduler-inputs'>

                    <div className='report-section-target'>
                        <CustomSelect
                            heading="Report Type"
                            options={report_targets}
                            defaultIndex={0}
                            value={reportTarget}
                            onTypeChange={(value) => { setReportTarget(value) }}
                        />
                    </div>

                    {reportTarget == 'Individual Customer' ? <>

                        {user_role_type === 'BROKER' ? <>
                            <div className='report-section-customer'>
                                <MACSearch title='Search Customer' showSearch={accountCode} customers={customers} onSelect={(customer) => { setCustomer(customer) }} />
                            </div>
                        </> :
                            <></>
                        }
                    </>
                        : <>
                            <MACSearch title='Search Family Portfolio' placeholder='Enter Portfolio Name' showSearch={portfolioID} customers={portfolios} onSelect={(portfolio) => { setPortfolio(portfolio) }} />
                        </>
                    }

                    <div className='report-section-type'>
                        <CustomSelect
                            heading="Type of Statement"
                            defaultIndex={0}
                            value={reportType}
                            onTypeChange={(value) => { setReportType(value) }}
                            options={report_types}
                        />
                    </div>

                    <div className='report-section-frequency'>
                        <CustomSelect
                            heading="Frequency of Report"
                            defaultIndex={0}
                            value={reportFrequency}
                            onTypeChange={(value) => { setReportFrequency(value) }}
                            options={report_frequencies}
                        />
                    </div>
                </div>

                <div className='report-section-button'>
                    <CustomIconButton
                        onClick={scheduleReport}
                        icon={<ArrowRight2 size={20} />}
                        title="Submit"
                        disabled={(!accountCode && !portfolioID)}
                    />
                </div>
            </div>
        </>
    )
}

export default ReportScheduler;