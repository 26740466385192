import React from 'react';
import CustomInput from '../Body/CustomChartComponents/CustomInput/CustomInput';
import CustomDate from '../Body/CustomChartComponents/CustomDate/CustomDate';
import { Add, Minus } from '../CustomComponents/SwiftIcon/Icons';

function NomineeCard({ data, current, total, minor, name, relation, address, dob, percentage, guardianName, guardianAddress, guardianDob, addNominee, removeNominee }) {

    return (
        <div className='sub-nominee-form'>

            <div className='nominee-form-header'>
                <p className="nominee-number">Nominee {current}/{total}</p>
                {current === total && current < 3 ? <>
                    <div className='nominee-add' onClick={() => { addNominee(current) }}>
                        <span>Add Nominee</span>
                        <Add size={20} color="#ffffff" />
                    </div>
                </> : <>
                    <div className='nominee-remove' onClick={() => { removeNominee(current) }}>
                        <span>Remove Nominee</span>
                        <Minus size={20} color="#ffffff" />
                    </div>
                </>}
            </div>
            <div className="flex-row">
                <div className="flex-item">
                    <CustomInput
                        value={name.value}
                        error={name.error}
                        onChange={(value) => { name.onChange(value) }}
                        heading="Nominee Name"
                        placeholder="Enter name of nominee"
                    />
                </div>

                <div className="flex-item">
                    <CustomInput
                        value={relation.value}
                        error={relation.error}
                        onChange={(value) => { relation.onChange(value) }}
                        heading="Relation with Applicant"
                        placeholder="Enter Relation with Applicant"
                    />
                </div>
            </div>

            <CustomInput
                value={address.value}
                error={address.error}
                onChange={(value) => { address.onChange(value) }}
                heading="Nominee Address"
                placeholder="Enter Address of Nominee"
            />

            <div className="flex-row">
                <div className="flex-item">
                    <CustomDate heading="Nominee's date of birth" value={dob.value} onChange={(value) => { dob.onChange(value) }} />
                </div>
                <div className="flex-item">
                    <CustomInput
                        value={percentage.value}
                        heading="Nominee Percentage"
                        onChange={(value) => {
                            percentage.onChange(value)
                        }}
                    />
                </div>
            </div>

            {minor ?
                <>
                    <div className="flex-row">
                        <div className="flex-item">
                            <CustomInput
                                value={guardianName.value}
                                error={guardianName.error}
                                onChange={(value) => { guardianName.onChange(value) }}
                                heading="Name of Guardian"
                                placeholder="Enter Name of Nominee's Guardian"
                            />
                        </div>
                        <div className="flex-item">
                            <CustomDate heading="Nominee's Guardian's date of birth" value={guardianDob.value} onChange={(value) => { guardianDob.onChange(value) }} />
                        </div>
                    </div>
                    <CustomInput
                        value={guardianAddress.value}
                        error={guardianAddress.error}
                        onChange={(value) => { guardianAddress.onChange(value) }}
                        heading="Address of Guardian"
                        placeholder="Enter Address of Nominee's Guardian"
                    />

                </> :
                <></>
            }

        </div>
    )
}

export default NomineeCard;