import React from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import BrokerContact from './BrokerContact'

function BrokerRoute () {
    const location = useLocation();
    console.log(location.pathname);
  return (
    <>
      <Routes>
        <Route path="equity" element={<BrokerContact />} />
        <Route path="*" element={<Navigate to="/admin/broker/equity" />} />
        </Routes>
    </>
  )
}

export default BrokerRoute
