import React, { useState } from 'react';
import Axios from 'axios';
import { Cross } from '../CustomComponents/SwiftIcon/Icons';
import CustomInput from '../Body/CustomChartComponents/CustomInput/CustomInput';
import CustomButton from '../Body/CustomChartComponents/CustomButton/CustomButton';
import Pulse from '../Loader/Pulse';
import { Alert } from '../Body/CustomChartComponents/CustomAlert/CustomAlert';
import { GetCookie } from '../../exports/CookieManagement';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const success_config = {
    Band: true,
    BandColor: '#67D098',
    BoxColor: '#ffffff',
    TextColor: '#000000',
    AutoClose: {
        Active: true,
        Line: true,
        LineColor: '#67D098',
        Time: 3
    }
}

const error_config = {
    Band: true,
    BandColor: '#470985',
    BoxColor: '#ffffff',
    TextColor: '#000000',
    AutoClose: {
        Active: true,
        Line: true,
        LineColor: '#470985',
        Time: 3
    }
}

function NewFamilyPortfolio({ close = () => { }, update = () => { } }) {

    const [loading, setLoading] = useState(false);
    const [portfolio, setPortfolio] = useState('');
    const [error, setError] = useState(false);

    function AddPortfolio() {

        setLoading(true);
        setTimeout(() => {
            if (portfolio === '') {
                setError('Please enter portfolio name');
            }
            else {

                const account_token = GetCookie('token');
                Axios.post(`${REQUEST_BASE_URL}/family?token=${account_token}`, { portfolio_name: portfolio }).then((response) => {
                    const data = response.data;
                    if (!data.error) {
                        setError('');
                        update();
                        close();
                        Alert({
                            TitleText: 'Family Portfolio Added',
                            Message: 'Portfolio added successfully',
                            ...success_config
                        });
                    }
                    else {
                        setError('');
                        if (data.portfolio_exists) {
                            Alert({
                                TitleText: 'Warning',
                                Message: 'Portfolio already exists in your account',
                                ...error_config
                            })
                        }
                        else {
                            Alert({
                                TitleText: 'Warning',
                                Message: 'Something went wrong while adding portfolio',
                                ...error_config
                            })
                        }
                    }
                }).catch((err) => {
                    setError('');
                    Alert({
                        TitleText: 'Warning',
                        Message: 'Something went wrong while adding portfolio',
                        ...error_config
                    })
                })
            }

            setLoading(false);

        }, 500);
    }

    return (
        <div className='family-new-popup'>
            {loading && <>
                <div className='family-new-popup-loader'>
                    <Pulse />
                </div>
            </>}
            <div className='family-new-header'>
                <p className='family-new-title'>Create Family Portfolio</p>
                <div className='family-new-close' onClick={close}>
                    <Cross color="#192b40" size={26} />
                </div>
            </div>
            <div className='family-new-body'>
                <CustomInput
                    heading="Family Portfolio Name"
                    value={portfolio}
                    placeholder=""
                    error={error}
                    onChange={(val) => { setPortfolio(val) }}
                />
            </div>
            <div className='family-new-footer'>
                <CustomButton title="Create Portfolio" onClick={AddPortfolio} />
            </div>
        </div>
    )
}

export default NewFamilyPortfolio;