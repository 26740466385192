import React, { useState, useEffect } from "react";
import { Navigate, Link } from "react-router-dom";
import { BarCode, ArrowRight2 } from "../CustomComponents/SwiftIcon/Icons";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";
import Pulse from "../Loader/Pulse";
import {
  checkLength,
  isEmpty,
  isValidEmail,
  isValidPan,
  isValidPhoneNumber,
} from "../../exports/UtilFunctions";
import ServerRequest from "../../exports/ServerRequest";
import AccessHeader from "./AccessHeader";

const warning_config = {
  Band: true,
  BandColor: "#470985",
  BoxColor: "#ffffff",
  TextColor: "#000000",
  AutoClose: {
    Active: true,
    Line: true,
    LineColor: "#470985",
    Time: 3,
  },
};

function AccessSignup() {
  const [step, setStep] = useState(1);

  const [email, setEmail] = useState("");
  const [otp, setOTP] = useState("");
  const [number, setNumber] = useState("");
  const [pan, setPan] = useState("");
  const [name, setName] = useState("");
  const [aadhaar, serAadhaar] = useState("");

  const [panFile, setPanFile] = useState(null);
  const [aadhaarFile, serAadhaarFile] = useState(null);
  const [bankFile, setBankFile] = useState(null);

  const [timer, setTimer] = useState(0);

  const [Error1, setError1] = useState("");
  const [Error2, setError2] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);

  const [otpSent, setOTPSent] = useState(false);
  // const [login, setLogin] = useState(false);
  // const [accounts, setAccounts] = useState([]);

  const handleNext = () => {
    setStep(step + 1);
  };

  useEffect(() => {
    const counter = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
    return () => clearInterval(counter);
  }, [timer]);

  async function sendOTP() {
    if (timer > 0) {
      return;
    }

    let isValid = false;
    let errorMessage = "";

    let data = false;

    if (step === 1) {
      if (isEmpty(email) || !isValidEmail(email)) {
        errorMessage = "Please enter a valid email address";
        isValid = false;
      } else {
        setLoading(true);
        setLoadingMessage("Sending one-time password...");

        data = await ServerRequest({
          method: "POST",
          url: `/access/signup/sendotp`,
          data: {
            type: "email",
            value: email,
          },
        });
      }
    } else if (step === 2) {
      if (isEmpty(number) || !isValidPhoneNumber(number)) {
        errorMessage = "Please enter a valid phone number";
        isValid = false;
      } else {
        setLoading(true);
        setLoadingMessage("Sending one-time password...");
        data = await ServerRequest({
          method: "POST",
          url: `/access/signup/sendotp`,
          data: {
            type: "mobile",
            value: number,
          },
        });
      }
    } else if (step === 3) {
      if (isEmpty(name)) {
        setError1("Please enter valid name");
        setError2("");
        return;
      }
      if (isEmpty(pan) || !isValidPan(pan)) {
        setError1("");
        setError2("Please enter valid PAN number");
        return;
      }
      if (!panFile) {
        setError1("");
        setError2("");
        Alert({
          TitleText: "Warning",
          Message: "Please upload PAN image",
          ...warning_config,
        });
        return;
      }

      setLoading(true);
      setLoadingMessage("");

      data = await ServerRequest({
        method: "POST",
        url: `/access/signup/sendotp`,
        data: {
          type: "pan",
          value: pan,
        },
      });
    }

    if (data.error) {
      errorMessage = data.message;
      isValid = false;
    } else {
      isValid = true;
    }

    if (!isValid) {
      Alert({
        TitleText: "Warning",
        Message: errorMessage,
        ...warning_config,
      });
      setLoading(false);
      setLoadingMessage("");
      setError1("");
      setError2("");
    } else {
      setTimeout(() => {
        setError1("");
        setError2("");
        setLoading(false);
        setLoadingMessage("");

        if (step === 3) {
          setStep(4);
        } else {
          setOTPSent(true);
          setTimer(30);
        }
      }, 2000);
    }
  }

  async function validateOtp() {
    if (isEmpty(otp) || !checkLength(otp, 6)) {
      setError1("");
      setError2("Please enter correct otp");
    } else {
      setLoading(true);
      setLoadingMessage("Validating one time password...");

      const data = await ServerRequest({
        url: `/access/signup/validateotp`,
        data: {
          type: step === 1 ? "email" : "number",
          value: step === 1 ? email : number,
          otp: otp,
        },
        method: "post",
      });

      if (!data.error) {
        setTimeout(() => {
          setTimer(0);
          setError1("");
          setError2("");
          setLoading(false);
          setLoadingMessage(false);
          setOTPSent(false);
          setOTP("");
          handleNext();
        }, 2000);
      } else {
        setTimeout(() => {
          setError1("");
          setError2(data.message);
          setLoading(false);
          setLoadingMessage("");
        }, 1000);
      }
    }
  }

  async function handleFileChange(e, setFile) {
    const file = e.target.files[0];

    if (file) {
      setFile(file);
    }

    setError1("");
    setError2("");

  }

  function KeyUp1(e) {
    if (e.key === "Enter") {
      sendOTP();
    }
  }

  function KeyUp2(e) {
    if (e.key === "Enter") {
      validateOtp();
    }
  }

  async function handleSubmit() {
    if (!checkLength(aadhaar, 12)) {
      setError1("Please enter valid Aadhaar Number");
      setError2("");
      return;
    }

    if (!aadhaarFile) {
      setError1("");
      setError2("");
      Alert({
        TitleText: "Warning",
        Message: "Please upload aadhaar image",
        ...warning_config,
      });
      return;
    }

    if (!bankFile) {
      setError1("");
      setError2("");
      Alert({
        TitleText: "Warning",
        Message: "Please upload cheque image",
        ...warning_config,
      });
      return;
    }

    const myForm = new FormData();
    myForm.append("pan", pan);
    myForm.append("name", name);
    myForm.append("email", email);
    myForm.append("mobile", number);
    myForm.append('aadhaar', aadhaar);
    myForm.append("pan_image", panFile);
    myForm.append("aadhaar_image", aadhaarFile);
    myForm.append("bank_image", bankFile);

    // console.log(myForm)
    setLoading(true);
    setLoadingMessage("Submitting please wait...");

    const data = await ServerRequest({
      method: "POST",
      url: "/access/signup/account",
      data: myForm,
      headers: {
        "Content-type": "multipart/form-data",
      },
    });

    if (data.error) {
      setError1("");
      setError2(data.message);
      setLoading(false);
      setLoadingMessage("");
      return;
    }

    setLoading(false);
    setLoadingMessage("");
    handleNext();
  }

  if (step === 5) {
    return <Navigate to="/access/success" />;
  }

  return (
    <div className="user-login">
      <div className="user-login-popup-wrapper">
        {loading ? (
          <>
            <div className="popup-loader">
              <Pulse />
              <p>{loadingMessage}</p>
            </div>
          </>
        ) : (
          <>
            <div className="login-header">
              <AccessHeader header_text="SignUp" />
            </div>

            {step === 1 && (
              <>
                <div className="login-content">
                  <div className="login-body">
                    <div className="swift-input-box">
                      <p>Email</p>
                      <input
                        className="swift-input"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        onKeyUp={KeyUp1}
                        placeholder="Your email address"
                      />
                      <p
                        className={
                          Error1 === "" ? "error-text hide" : "error-text"
                        }
                      >
                        {Error1}
                      </p>
                    </div>
                    <div className="swift-button-icon" onClick={sendOTP}>
                      <div className="button-icon">
                        <BarCode size={20} />
                      </div>
                      <p>Generate OTP</p>
                    </div>
                    {otpSent && (
                      <>
                        <p
                          className={
                            timer === 0
                              ? "resend-message hide"
                              : "resend-message"
                          }
                        >
                          Please wait {timer} seconds to resend
                        </p>
                        <div className="swift-input-box">
                          <p>OTP</p>
                          <input
                            className="swift-input"
                            value={otp}
                            placeholder="123456"
                            onKeyUp={KeyUp2}
                            maxLength={6}
                            onChange={(e) => {
                              setOTP(e.target.value.replace(/[^\d]/g, ""));
                            }}
                          />
                          <p
                            className={
                              Error2 === "" ? "error-text hide" : "error-text"
                            }
                          >
                            {Error2}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  {otpSent && (
                    <>
                      <div className="login-footer">
                        <div
                          className="swift-button-icon"
                          onClick={validateOtp}
                        >
                          <div className="button-icon">
                            <ArrowRight2 size={20} />
                          </div>
                          <p>Next</p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="footer-line">
                    <p>
                      Already have an account?{" "}
                      <Link to="/access/login">Login</Link>
                    </p>
                  </div>
              </>
            )}

            {step === 2 && (
              <>
                <div className="login-content">
                  <div className="login-body">
                    <div className="swift-input-box">
                      <p>Mobile Number</p>
                      <input
                        className="swift-input"
                        maxLength={10}
                        type="text"
                        value={number}
                        onChange={(e) => {
                          setNumber(e.target.value.replace(/[^\d]/g, ""));
                        }}
                        onKeyUp={KeyUp1}
                        placeholder="Your mobile number"
                      />
                      <p
                        className={
                          Error1 === "" ? "error-text hide" : "error-text"
                        }
                      >
                        {Error1}
                      </p>
                    </div>
                    <div className="swift-button-icon" onClick={sendOTP}>
                      <div className="button-icon">
                        <BarCode size={20} />
                      </div>
                      <p>Generate OTP</p>
                    </div>
                    {otpSent && (
                      <>
                        <p
                          className={
                            timer === 0
                              ? "resend-message hide"
                              : "resend-message"
                          }
                        >
                          Please wait {timer} seconds to resend
                        </p>
                        <div className="swift-input-box">
                          <p>OTP</p>
                          <input
                            className="swift-input"
                            value={otp}
                            placeholder="123456"
                            onKeyUp={KeyUp2}
                            maxLength={6}
                            onChange={(e) => {
                              setOTP(e.target.value.replace(/[^\d]/g, ""));
                            }}
                          />
                          <p
                            className={
                              Error2 === "" ? "error-text hide" : "error-text"
                            }
                          >
                            {Error2}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  {otpSent && (
                    <>
                      <div className="login-footer">
                        <div
                          className="swift-button-icon"
                          onClick={validateOtp}
                        >
                          <div className="button-icon">
                            <ArrowRight2 size={20} />
                          </div>
                          <p>Next</p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            {step === 3 && (
              <>
                <div className="login-content">
                  <div className="login-body">
                    <div className="swift-input-box">
                      <p>Name</p>
                      <input
                        className="swift-input"
                        type="text"
                        maxLength={100}
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        placeholder="Your Name"
                      />
                      <p
                        className={
                          Error1 === "" ? "error-text hide" : "error-text"
                        }
                      >
                        {Error1}
                      </p>
                    </div>
                    <div className="swift-input-box">
                      <p>PAN Number</p>
                      <input
                        className="swift-input"
                        type="text"
                        maxLength={10}
                        value={pan}
                        onChange={(e) => {
                          setPan(e.target.value.toUpperCase());
                        }}
                        onKeyUp={KeyUp1}
                        placeholder="Your pan number"
                      />
                      <p
                        className={
                          Error2 === "" ? "error-text hide" : "error-text"
                        }
                      >
                        {Error2}
                      </p>
                    </div>
                    <div
                      className="swift-button-icon"
                      onClick={() => {
                        document.getElementById("fileInput").click();
                      }}
                    >
                      <div className="button-icon">
                        <label htmlFor="fileInput">
                          <BarCode size={20} />
                          <input
                            type="file"
                            id="fileInput"
                            accept="image/*,application/pdf"
                            onChange={(e) => handleFileChange(e, setPanFile)}
                            style={{ display: "none" }}
                          />
                        </label>
                      </div>
                      <p>Upload Pan</p>
                    </div>
                    {panFile && (
                      <>
                        <p
                          className={
                            !panFile ? "resend-message hide" : "resend-message"
                          }
                        >
                          {panFile.name}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="login-footer">
                    <div className="swift-button-icon" onClick={sendOTP}>
                      <div className="button-icon">
                        <ArrowRight2 size={20} />
                      </div>
                      <p>Next</p>
                    </div>
                  </div>
                </div>
              </>
            )}

            {step === 4 && (
              <>
                <div className="login-content">
                  <div className="login-body">
                    <div className="swift-input-box">
                      <p>Aadhaar Number</p>
                      <input
                        className="swift-input"
                        value={aadhaar}
                        placeholder="Your Aadhaar Number"
                        maxLength={12}
                        onChange={(e) => {
                          serAadhaar(e.target.value.replace(/[^\d]/g, ""));
                        }}
                      />
                      <p
                        className={
                          Error1 === "" ? "error-text hide" : "error-text"
                        }
                      >
                        {Error1}
                      </p>
                    </div>

                    <div
                      className="swift-button-icon"
                      onClick={() => {
                        document.getElementById("fileInput").click();
                      }}
                    >
                      <div className="button-icon">
                        <BarCode size={20} />
                        <input
                          type="file"
                          id="fileInput"
                          accept="image/*,application/pdf"
                          onChange={(e) => handleFileChange(e, serAadhaarFile)}
                          style={{ display: "none" }}
                        />
                      </div>
                      <p>Upload Aadhaar Image</p>
                    </div>
                    {aadhaarFile && (
                      <>
                        <p
                          className={
                            !aadhaarFile
                              ? "resend-message hide"
                              : "resend-message"
                          }
                        >
                          {aadhaarFile.name}
                        </p>
                      </>
                    )}

                    <div
                      className="swift-button-icon"
                      onClick={() => {
                        document.getElementById("bankInput").click();
                      }}
                    >
                      <div className="button-icon">
                        <BarCode size={20} />
                        <input
                          type="file"
                          id="bankInput"
                          accept="image/*,application/pdf"
                          onChange={(e) => handleFileChange(e, setBankFile)}
                          style={{ display: "none" }}
                        />
                      </div>
                      <p>Upload Self Attested Cheque</p>
                    </div>
                    {bankFile && (
                      <>
                        <p
                          className={
                            !bankFile ? "resend-message hide" : "resend-message"
                          }
                        >
                          {bankFile.name}
                        </p>
                      </>
                    )}
                  </div>
                  <div className="login-footer">
                    <div className="swift-button-icon" onClick={handleSubmit}>
                      <div className="button-icon">
                        <ArrowRight2 size={20} />
                      </div>
                      <p>Submit</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        
      </div>
    </div>
  );
}

export default AccessSignup;
