import React, { useEffect, useState } from "react";
import SwiftModal from "../../CustomComponents/SwiftModal/SwiftModal";
import {
  Cross,
  ArrowRight2,
  UserSearch,
  Edit,
} from "../../CustomComponents/SwiftIcon/Icons";
import ServerRequest from "../../../exports/ServerRequest";
import { useNavigate } from "react-router-dom";
import Pulse from "../../Loader/Pulse";
import "../../../css/CustomComponents/CustomSearchSelect.css";

import CustomInputFile from "../../CustomComponents/CustomInputFile/CustomInputFile";
import {
  isEmpty,
  isValidEmail,
  isValidPhoneNumber,
  titleCase,
} from "../../../exports/UtilFunctions";
import { Alert } from "../../Body/CustomChartComponents/CustomAlert/CustomAlert";
import { GetCookie } from "../../../exports/CookieManagement";
import { COLOR_VARS } from "../../../exports/ColorVars";

const DealerManagement = () => {
  const [form, setForm] = useState(null);
  const [formData, setFormData] = useState({});
  const [change, setChange] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState("");
  const token = GetCookie("admin_token");
  const [emails, setEmails] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data1 = await ServerRequest({
        url: `/admin/dealer/get?token=${token}`,
        method: "get",
      });
      if (data1.server_error) {
        navigate("/404", { state: { errorMessage: data1.message } });
      }
      setData(data1.data);
      setLoading(false);
    };
    fetchData();
  }, [change]);

  const [error, setError] = useState({
    email: "",
    name: "",
    mobile: "",
  });

  const DisplayAlert = (msg) => {
    Alert({
      TitleText: "Error",
      Message: msg,
      BandColor: "#e51a4b",

      AutoClose: {
        Active: true,
        Line: true,
        LineColor: "#e51a4b",
        Time: 3,
      },
    });
  };

  const handleInputChange = (name, value) => {
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const openForm = (item) => {
    setFormData(item);
    setForm(true);
    const Emails = data
      .filter((i) => i.email != item.email)
      .map((i) => i.email);
    setEmails(Emails);
  };
  const closeForm = () => {
    setFormData({});
    setForm(false);
    setError("");
  };

  const validateAll = () => {
    setError({
      email: "",
      name: "",
      mobile: "",
    });
    if (isEmpty(formData["name"])) {
      setError((prevError) => ({
        ...prevError,
        ["name"]: `Name Cannot Be Empty`,
      }));
      DisplayAlert("Name Cannot Be Empty");
      return false;
    }
    if (!isValidEmail(formData["email"])) {
      setError((prevError) => ({
        ...prevError,
        ["email"]: `Enter Valid Email`,
      }));
      DisplayAlert("Enter Valid Email");
      return false;
    }
    if (emails.includes(formData["email"])) {
      setError((prevError) => ({
        ...prevError,
        ["email"]: `Email already exists.`,
      }));
      DisplayAlert("Email already exists.");
      return false;
    }
    if (!isValidPhoneNumber(formData["mobile"])) {
      setError((prevError) => ({
        ...prevError,
        ["mobile"]: `Enter Valid Mobile Number`,
      }));
      DisplayAlert("Enter Valid Mobile Number");
      return false;
    }
    return true;
  };
  const handleSubmit = async () => {
    const isValid = validateAll();

    if (isValid) {
      setLoading(true);

      const data2 = await ServerRequest({
        url: `/admin/dealer?token=${token}`,
        data: formData,
        method: "post",
      });

      if (data2.server_error) {
        navigate("/404", { state: { errorMessage: data2.message } });
      }
      setTimeout(() => {
        setLoading(false);
        closeForm();
        setChange(Math.floor(Math.random() * 900000000 + 100000000));
      }, 2000);
    }
  };

  const renderFormContent = () => {
    return (
      <>
        <div className="swift-account-form-body-content">
          <CustomInputFile
            label="Name"
            name="name"
            type="text"
            value={formData["name"]}
            onchange={handleInputChange}
            error={error.name}
          />
          <CustomInputFile
            label="Email"
            name="email"
            type="email"
            value={formData["email"]}
            onchange={handleInputChange}
            error={error.email}
          />

          <CustomInputFile
            label="Mobile"
            name="mobile"
            type="number"
            value={formData["mobile"]}
            onchange={handleInputChange}
            error={error.mobile}
          />
        </div>
        {/* </div> */}
        <div className="login-footer">
          <div className="swift-button-icon" onClick={handleSubmit}>
            <div className="button-icon">
              <ArrowRight2 size={20} />
            </div>
            <p>Submit</p>
          </div>
        </div>
      </>
    );
  };

  return !loading ? (
    <div className="swift-account-main open">
      <div className="swift-account-heading">
        <div className="swift-account-heading-right">
          <div className="select-search-wrapper">
            <div className="select-search-box">
              <input
                placeholder="Search Dealer"
                value={filterData}
                onChange={(e) => setFilterData(e.target.value)}
              />
              <div className="filter-icon">
                <UserSearch size={20} color="#000" />
              </div>
            </div>
          </div>
          <button onClick={() => openForm({})}>Add New Dealer</button>
        </div>
      </div>

      {data.filter((item) =>
        item.name.toLowerCase().includes(filterData.toLowerCase())
      ).length > 0 ? (
        <div className="admin-wrapper full">
          <div className="admin-history-container admin-dealer-container">
            <table>
              <thead>
                <tr>
                  <td className="name">Name</td>
                  <td className="email">Email</td>
                  <td className="mobile">Mobile</td>
                  <td className="account-code">Account Code</td>
                  <td className="edit"></td>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  if (
                    item.name.toLowerCase().includes(filterData.toLowerCase())
                  ) {
                    return (
                      <tr key={index}>
                        <td>{item["name"] ? titleCase(item["name"]) : "-"}</td>
                        <td>{item.email ? item.email : "-"}</td>
                        <td>{item.mobile ? item.mobile : "-"}</td>
                        <td>{item.account_code ? item.account_code : "-"}</td>

                        <td>
                          <div
                            className="swift-account-svg"
                            onClick={() => openForm(item)}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            <Edit
                              size={18}
                              color={COLOR_VARS["SWIFT_COLOR4"]}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="empty-data-wrapper">
          <p>No Dealers Found</p>
        </div>
      )}

      {form && (
        <SwiftModal closeModal={closeForm} top="2%">
          <div className="swift-account-form">
            {loading ? (
              <>
                <div className="loader">
                  <Pulse />
                  <p>Creating Account</p>
                </div>
              </>
            ) : (
              <>
                <div className="swift-account-form-header">
                  <div className="swift-account-form-title">
                    Dealer Management
                  </div>
                  <div className="swift-account-svg" onClick={closeForm}>
                    <Cross size={30} color={COLOR_VARS["SWIFT_COLOR4"]} />
                  </div>
                </div>
                <div className="swift-account-form-body">
                  {renderFormContent()}
                </div>
              </>
            )}
          </div>
        </SwiftModal>
      )}
    </div>
  ) : (
    <>
      <div className="admin-body-loader">
        <Pulse />
        <p>Loading data</p>
      </div>
    </>
  );
};

export default DealerManagement;
