import React, { useState } from 'react';
import Axios from 'axios';
import { Cross } from '../CustomComponents/SwiftIcon/Icons';
import CustomInput from '../Body/CustomChartComponents/CustomInput/CustomInput';
import CustomButton from '../Body/CustomChartComponents/CustomButton/CustomButton';
import { Alert } from '../Body/CustomChartComponents/CustomAlert/CustomAlert';
import Pulse from '../Loader/Pulse';
import { GetCookie } from '../../exports/CookieManagement';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const success_config = {
    Band: true,
    BandColor: '#67D098',
    BoxColor: '#ffffff',
    TextColor: '#000000',
    AutoClose: {
        Active: true,
        Line: true,
        LineColor: '#67D098',
        Time: 3
    }
}

const error_config = {
    Band: true,
    BandColor: '#470985',
    BoxColor: '#ffffff',
    TextColor: '#000000',
    AutoClose: {
        Active: true,
        Line: true,
        LineColor: '#470985',
        Time: 3
    }
}

function FamilyCustomerAdd({ portfolio, close = () => { }, update = () => { } }) {

    const { family_portfolio_id } = portfolio;

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [pan, setPan] = useState('');
    const [otp, setOTP] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [panError, setPanError] = useState(false);
    const [otpError, setOtpError] = useState(false);
    const [accountOTP, setAccountOTP] = useState(false);
    const [customer, setCustomer] = useState(false);

    function SendOTP() {

        setLoading(true);
        setTimeout(() => {
            if (email.trim() === '') {
                setEmailError('Please enter customer email address');
                setPanError('');
                setLoading(false);
                return;
            }
            else if (pan.trim() === '') {
                setPanError('Please enter customer PAN number');
                setEmailError('');
                setLoading(false);
                return;
            }
            else {

                const account_token = GetCookie('token');
                Axios.post(`${REQUEST_BASE_URL}/family/customerotp?token=${account_token}`, {
                    portfolio_id: family_portfolio_id,
                    customer_email: email,
                    customer_pan: pan
                }).then((response) => {
                    const data = response.data;
                    if (data.error) {
                        if (!data.exists) {
                            Alert({
                                TitleText: 'Warning',
                                Message: 'Customer with the given credentials does not exists',
                                ...error_config
                            })
                        }
                        else if (data.portfolio_exists) {
                            Alert({
                                TitleText: 'Warning',
                                Message: 'Customer already exists in this portfolio',
                                ...error_config
                            })
                        }
                        else {
                            Alert({
                                TitleText: 'Warning',
                                Message: 'Something went wrong while sending the OTP. Please try again later',
                                ...error_config
                            })
                        }

                        setLoading(false);
                    }
                    else {
                        setAccountOTP(data.otp);
                        setCustomer(data.customer_details);
                        Alert({
                            TitleText: 'One Time Password Sent',
                            Message: 'OTP successfully sent to the customer',
                            ...success_config
                        });
                        setLoading(false);
                    }
                }).catch((error) => {
                    Alert({
                        TitleText: 'Warning',
                        Message: 'Something went wrong while sending the OTP. Please try again later',
                        ...error_config
                    });
                    setLoading(false);
                });
            }
        }, 1000);
    }

    function AddAccount() {
        setLoading(true);
        setTimeout(() => {
            if (otp.trim() === '') {
                setOtpError('Please enter one time password (OTP)');
                setLoading(false);
                return;
            }
            else if (otp != accountOTP) {
                setOtpError('Please enter correct one time password (OTP)');
                setLoading(false);
                return;
            }
            else {
                const account_token = GetCookie('token');
                const customer_code = customer.account_code;
                Axios.post(`${REQUEST_BASE_URL}/family/addcustomer?token=${account_token}`, {
                    portfolio_id: family_portfolio_id,
                    portfolio_customer: customer_code
                }).then((response) => {
                    const data = response.data;
                    if (!data.error) {
                        Alert({
                            TitleText: 'Customer Added',
                            Message: 'Customer successfully added to portfolio',
                            ...success_config
                        });
                        setLoading(false);
                        update(true);
                        close();
                    }
                    else {
                        Alert({
                            TitleText: 'Warning',
                            Message: 'Something went wrong while adding the customer. Please try again later',
                            ...error_config
                        });
                        setLoading(false);
                    }
                }).catch((error) => {
                    Alert({
                        TitleText: 'Warning',
                        Message: 'Something went wrong while adding the customer. Please try again later',
                        ...error_config
                    });
                    setLoading(false);
                })
            }
        }, 1000);
    }

    return (
        <div className='family-customer-popup'>
            {loading && <>
                <div className='family-customer-loader'>
                    <Pulse />
                </div>
            </>}
            <div className='family-customer-header'>
                <p className='family-customer-title'>Add New Customer</p>
                <div className='family-customer-close' onClick={close}>
                    <Cross color="#192b40" size={26} />
                </div>
            </div>
            <div className='family-customer-body'>
                {accountOTP ? <>
                    <CustomInput
                        heading="One Time Password (OTP)"
                        value={otp}
                        placeholder=""
                        error={otpError}
                        onChange={((val) => { setOTP(val) })}
                    />
                </>
                    :
                    <>
                        <div>
                            <CustomInput
                                heading="Customer Email Address"
                                value={email}
                                placeholder=""
                                error={emailError}
                                onChange={(val) => { setEmail(val) }}
                            />
                        </div>
                        <div>
                            <CustomInput
                                heading="Customer PAN Number"
                                value={pan}
                                placeholder=""
                                error={panError}
                                maxLength={10}
                                onChange={(val) => { setPan(val.toUpperCase()) }}
                            />
                        </div>
                    </>
                }

            </div>
            <div className='family-customer-footer'>
                {accountOTP ? <>
                    <CustomButton title="Add Customer" onClick={AddAccount} />
                </>
                    :
                    <>
                        <CustomButton title="Send OTP" onClick={SendOTP} />
                    </>
                }
            </div>
        </div>
    )
}

export default FamilyCustomerAdd;