import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";
import { GetRoleType } from "../../exports/RoleTypes";
import { GetCookie } from "../../exports/CookieManagement";
import Pulse from "../Loader/Pulse";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function ActivationAuth(vertical, BaseComponent) {
  return function Activation(props) {
    const [trigger, setTrigger] = useState(false);
    const [status, setStatus] = useState(false);

    async function GetActivation() {
      const account_token = GetCookie("token");
      const response = await Axios(
        `${REQUEST_BASE_URL}/clientinfo/activation?token=${account_token}&vertical=${vertical}`
      );
      return response.data;
    }

    function ToggleTrigger() {
      setTrigger(!trigger);
    }

    useEffect(() => {
      setStatus(false);
      setTimeout(() => {
        const user_role_type = GetRoleType(GetCookie("role"));
        if (user_role_type === "BROKER") {
          setStatus("active");
          return;
        } else {
          GetActivation()
            .then((data) => {
              const { error, status = "" } = data;
              if (error) {
                setStatus("pre-activation");
              } else {
                setStatus(status);
              }
            })
            .catch((error) => {
              setStatus("pre-activation");
            });
        }
      }, 1000);
    }, [trigger]);

    if (!status)
      return (
        <>
          <div className="activation-box">
            <Pulse />
          </div>
        </>
      );

    if (status === "active") {
      return <BaseComponent {...props} />;
    }

    return (
      <ActivationBox
        status={status}
        vertical={vertical}
        ToggleTrigger={ToggleTrigger}
      />
    );
  };
}

function ActivationBox({ status, vertical, ToggleTrigger }) {
  const [loading, setLoading] = useState(false);

  function GetActiovationStatus(status) {
    if (status === "pre-activation") {
      return "Pending Activation";
    } else if (status === "pending") {
      return "Activation in process";
    } else if (status === "deactive") {
      return "Deactivated";
    } else {
      return "";
    }
  }

  function GetActiovationInfo(status) {
    if (status === "pre-activation") {
      return (
        <>
          <p>
            Please process an activation request based on your interest. We will
            work in the background to activate this vertical for you.
          </p>
          <p>
            It might take 1-2 working days for us to complete the process. If we
            need anything, we will reach out to you.
          </p>
        </>
      );
    } else if (status === "pending") {
      return (
        <>
          <p>
            We are working on activating this vertical for you. It might take
            1-2 working days for us to complete the process.
          </p>
          <p>
            If we need anything, we will reach out to you. If there is anything,
            please feel free to email hello@swiftfolios.com quoting your PAN
            number in the subject line.
          </p>
        </>
      );
    }
    else if (status === "deactive") {
      return (
        <>
          <p>
            This vertical has been deactivated for you.
          </p>
          <p>
            If there is anything, please feel free to email hello@swiftfolios.com quoting your PAN
            number in the subject line.
          </p>
        </>
      );
    }
    else {
      return "";
    }
  }

  function SendActivationRequest() {
    setLoading(true);
    setTimeout(() => {
      const account_token = GetCookie("token");
      Axios.post(
        `${REQUEST_BASE_URL}/clientinfo/activation?token=${account_token}`,
        { vertical: vertical }
      )
        .then((response) => {
          const data = response.data;
          const { error } = data;
          if (error) {
            Alert({
              TitleText: "Failure",
              Message: "Something went wrong while sending activation request",
              AutoClose: {
                Active: true,
                Line: true,
                Time: 3,
              },
            });
          } else {
            Alert({
              TitleText: "Success",
              Message: "Vertical activation request sent successfully",
              AutoClose: {
                Active: true,
                Line: true,
                Time: 3,
              },
            });
            ToggleTrigger();
          }
        })
        .catch((error) => {
          console.log(error);
          Alert({
            TitleText: "Failure",
            Message: "Something went wrong while sending activation request",
            AutoClose: {
              Active: true,
              Line: true,
              Time: 3,
            },
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }, 2000);
  }

  return (
    <>
      <div className="activation-box">
        <div className="activation-box-sec1">
          <span>Account Status</span>
          <p>{GetActiovationStatus(status)}</p>
        </div>
        <div className="activation-box-sec2">{GetActiovationInfo(status)}</div>
        <div className="activation-box-sec3">
          {status === "pre-activation" ? (
            <>
              <button
                onClick={() => {
                  if (!loading) {
                    SendActivationRequest();
                  }
                }}
              >
                {loading ? (
                  <>
                    <Pulse />
                  </>
                ) : (
                  <>Activate</>
                )}
              </button>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default ActivationAuth;
