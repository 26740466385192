import { useEffect, useState } from "react";
import { SWIFTALGO_TIMEWINDOW } from "../exports/OrderAlgoConfig";
import useFullSnapQuote from "./useFullSnapQuote";
import moment from "moment";
import useStockInfo from "./useStockInfo";

const useMovingAverage = (symbol, side, time_window_text, data_points) => {
  // console.log('useMovingAverage',symbol,side,time_window_text,data_points)

  const time_window = SWIFTALGO_TIMEWINDOW[time_window_text];

  const stockData = useFullSnapQuote(symbol);
  const { stock_info } = useStockInfo(symbol);


  const [movingPrice, setMovingPrice] = useState(0);
  const [pointsArray, setPointsArray] = useState([]);

  useEffect(() => {
    // console.log("BID ARRAY ========> ", stockData?.top_5_bid_prices);
    // console.log("ASK ARRAY ========> ", stockData?.top_5_ask_prices);

    let last_entry = false;

    if (pointsArray.length > 0) {
      last_entry = pointsArray[pointsArray.length - 1]["timestamp"];
    }

    const duration = GetDifferenceInSeconds(last_entry);

    if (!last_entry || duration >= time_window) {
      UpdateMovingPrice();
    }
  }, [stockData]);

  function UpdateMovingPrice() {
    // console.log("UPDATE", moment().format());
    if (!stockData || !stock_info) return;

    const { top_5_bid_prices = [], top_5_ask_prices = [] } = stockData;

    const { tick_size = 0.05 } = stock_info;

    let price_array = side.toUpperCase() == "BUY" ? top_5_bid_prices : top_5_ask_prices;

    // console.log("PARRAY ======> ",price_array);

    if (price_array.length == 0) return;

    // const best_price = price_array[price_array.length - 1];
    const best_price = price_array[0];
    if (pointsArray.length >= data_points) {
      pointsArray.shift();
    }

    pointsArray.push({ timestamp: moment().unix(), price: best_price });

    // console.log("PRICE ARRAY =======> ", pointsArray);

    setPointsArray([...pointsArray]);

    const array = pointsArray.map((p) => {
      return p["price"];
    });

    const moving_average = GetAverage(array);
    const moving_average_rounded = RoundTickSize(moving_average, tick_size);

    // console.log(moving_average, moving_average_rounded);

    setMovingPrice(moving_average_rounded);
  }

  function RoundTickSize(price, tick_size) {
    const quotient = price / tick_size;
    const truncated_quotient = Math.trunc(quotient);
    const rounded_down_number = truncated_quotient * tick_size;
    return parseFloat(parseFloat(rounded_down_number).toFixed(2));
  }

  function GetAverage(num_array) {
    const sum = num_array.reduce((a, b) => {
      return a + b;
    }, 0);
    return parseFloat(parseFloat(sum / num_array.length).toFixed(2));
  }

  function GetDifferenceInSeconds(timestamp) {
    if (!timestamp) return 0;
    return moment().diff(moment.unix(timestamp), "seconds");
  }

  return movingPrice;
};

export default useMovingAverage;
