import React from 'react';

function AccessLogout() {
  return (
    <div className='logout-wrapper'>
      <p>Logged Out</p>
      <span>Relaunch App to Login Again</span>
    </div>
  )
}

export default AccessLogout;