import React, { useState } from "react";
import {
  useNavigate,
  Navigate,
  NavLink,
  useLocation,
  Routes,
  Route,
} from "react-router-dom";
import LOGO from "../../../assets/images/logo.png";
import CustomIconButton from "../../Body/CustomChartComponents/CustomIconButton/CustomIconButton";
import ConfirmBox from "../../CustomComponents/ConfirmBox/ConfirmBox";
import { LogOut } from "../../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../../exports/ColorVars";
import { DeleteCookie } from "../../../exports/CookieManagement";
import GenerateOrders from "./GenerateOrders";
import OrderConfirmation from "./OrderConfirmation";
import OrderFiles from "../../TransactionManagement/OrderFiles";

const menu_array = [
  {
    name: "Generate Orders",
    path: "/backoffice/dealer/orders",
  },
  {
    name: "Order Files",
    path: "/backoffice/dealer/files",
  },
  {
    name: "Order Confirmation",
    path: "/backoffice/dealer/confirmation",
  },
];

const MENU_ITEMS = ["Generate Orders", "Order Files", "Order Confirmation"];

function DealerManagement() {
  const [menu, setMenu] = useState(MENU_ITEMS[0]);

  function NavLinkCallback(menu_item) {
    setMenu(menu_item);
  }

  return (
    <div className="dealer-wrapper">
      <DealerHeader />
      <div className="dealer-content">
        <div className="swift-content-wrapper full">
          <DealerMenu NavLinkCallback={NavLinkCallback} />
          <DealerRoutes />
        </div>
      </div>
    </div>
  );
}

function DealerHeader() {
  const navigate = useNavigate();

  function LogoutUser(callback) {
    setTimeout(async () => {
      callback();
      DeleteCookie("back_token");
      navigate("/");
    }, 2000);
  }

  return (
    <div className="dealer-header">
      <div className="dealer-header-brand">
        <img src={LOGO} alt="" />
        <p>Swiftfolios Dealer Console</p>
      </div>
      <div
        onClick={() => {
          ConfirmBox({
            title: "Logout",
            description: (
              <>
                Are you sure you want to logout? You will be redirected to the
                login screen.
              </>
            ),
            properties: [
              {
                id: "2",
                label: "Logout",
                color: "#192b40",
                bgColor: "#ffffff",
                handleFunction: LogoutUser,
                functionArgs: [],
              },
            ],
            cancel: true,
          });
        }}
      >
        <CustomIconButton
          title="Logout"
          icon={<LogOut size={20} color={COLOR_VARS["SWIFT_COLOR4"]} />}
        />
      </div>
    </div>
  );
}

function DealerMenu({ NavLinkCallback }) {
  const location = useLocation();
  const { pathname = "" } = location;

  return (
    <div className="dealer-menu">
      <div className="swift-submenu">
        {menu_array.map((m, index) => {
          return (
            <NavLink
              key={index}
              activeclassname="active"
              to={m.path}
              onClick={() => {
                NavLinkCallback(m.name);
              }}
            >
              <p className={pathname === m.path ? "active" : ""}>{m.name}</p>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
}

function DealerRoutes() {
  return (
    <div className="dealer-content-wrapper">
      <Routes>
        <Route path="orders" element={<GenerateOrders />} />
        <Route path="files" element={<OrderFiles origin="BACKOFFICE" />} />
        <Route path="confirmation" element={<OrderConfirmation />} />
        <Route path="*" element={<Navigate to="/backoffice/dealer/orders" />} />
      </Routes>
    </div>
  );
}

export default DealerManagement;
