import React from "react";
import { useParams } from "react-router-dom";

const ImageShow = () => {
  const { url } = useParams();
  const fileExtension = url.split(".").pop().toLowerCase();

  const isImage =
    fileExtension === "jpg" ||
    fileExtension === "jpeg" ||
    fileExtension === "png" ||
    fileExtension === "gif";
  const isPdf = fileExtension === "pdf";

  return (
    <div>
      {isImage && (
        <img
          style={{ width: "90vw", height: "90vh", objectFit: "contain" }}
          src={url}
          alt="Image"
        />
      )}
      {isPdf && (
        <iframe
          src={url}
          style={{ width: "100vw", height: "100vh", border: "none" }}
          title="PDF Viewer"
        ></iframe>
      )}
    </div>
  );
};

export default ImageShow;
