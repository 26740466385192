import React from "react";
import Axios from "axios";
import "../../css/MenuSection/Orders.css";
import { GetCookie } from "../../exports/CookieManagement";
import { OrderBook as OrderBookIcon } from "../CustomComponents/SwiftIcon/Icons";
import ConfirmationSection from "./OrdersComponents/ConfirmationSection";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";
import Pulse from "../Loader/Pulse";
import { COLOR_VARS } from "../../exports/ColorVars";
import { getDeviceSize } from "../../exports/InteractiveUtils";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;
const DEVICE_SIZE = getDeviceSize();

function AlertMessage(title, message) {
  Alert({
    TitleText: title,
    Message: message,
    Band: true,
    BandColor: "#E51A4B",
    BoxColor: "#ffffff",
    TextColor: "#000000",
    AutoClose: {
      Active: true,
      Line: true,
      LineColor: "#E51A4B",
      Time: 5,
    },
  });
}

class ConfirmationBook extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      account_token: GetCookie("token"),
      ordersloaded: false,
      orderslist: [],
      isPlacing: false,
    };
    this.FetchUserOrders = this.FetchUserOrders.bind(this);
    this.ExecuteSingleOrder = this.ExecuteSingleOrder.bind(this);
  }

  componentDidMount() {
    this.FetchUserOrders();
  }

  FetchUserOrders() {
    this.setState({
      isPlacing: false,
    });
    const { account_token } = this.state;
    this.setState({ ordersloaded: false }, () => {
      Axios.get(`${REQUEST_BASE_URL}/order/confirmation?token=${account_token}`)
        .then((response) => {
          if (!response.data.error) {
            this.setState({
              orderslist: response.data.data,
              ordersloaded: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  ExecuteSingleOrder(order_id) {
    const { account_token } = this.state;

    this.setState({
      isPlacing: true,
    });

    Axios.post(`${REQUEST_BASE_URL}/order/confirm?token=${account_token}`, {
      order_id: order_id,
    })
      .then((response) => {
        if (!response.data.error) {
          this.FetchUserOrders();
          AlertMessage(
            "Trades Approved Successfully!",
            "Please visit order history to check the order status"
          );
        } else {
          AlertMessage(
            "Warning",
            "Something went wrong while approving the tardes"
          );
          this.setState({
            isPlacing: false,
          });
        }
      })
      .catch((err) => {
        AlertMessage(
          "Warning",
          "Something went wrong while approving the tardes"
        );
      });
  }

  render() {
    const { ordersloaded, orderslist, isPlacing } = this.state;

    return (
      <>
        <div className="orders-wrapper full">
          <div className="table-container">
            {isPlacing ? (
              <>
                <div className="table-loader">
                  <Pulse />
                  <p>Approving your trades, please wait!</p>
                </div>
              </>
            ) : (
              <>
                {orderslist && ordersloaded === true ? (
                  Object.entries(orderslist).length > 0 ? (
                    <>
                      {DEVICE_SIZE === "S" ? (
                        <></>
                      ) : (
                        <>
                          <div className="order-trades-section">
                            <div className="order-trades-header">
                              <div className="order-trades-config">
                                <div className="config-box arrow"></div>
                                <div className="config-box account">
                                  <span>Account Type</span>
                                </div>
                                <div className="config-box count">
                                  <span>Total Trades</span>
                                </div>
                                <div className="config-box sell">
                                  <span>Sell Value</span>
                                </div>
                                <div className="config-box buy">
                                  <span>Buy Value</span>
                                </div>
                                <div className="config-box trade">
                                  <span>Trade Value</span>
                                </div>
                                <div className="config-box execute"></div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {Object.entries(orderslist).map(([key, order]) => {
                        if (!order.error) {
                          return (
                            <ConfirmationSection
                              key={key}
                              ExecuteSingleOrder={this.ExecuteSingleOrder}
                              order={order}
                            />
                          );
                        }
                      })}
                    </>
                  ) : (
                    <div className="order-container-empty">
                      <OrderBookIcon
                        size={40}
                        color={COLOR_VARS["SWIFT_COLOR4"]}
                      />
                      <div>
                        <p>No orders found</p>
                        <span>
                          Looks like you there are not any orders to approve
                        </span>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="table-loader">
                    <Pulse />
                    <p>Fetching your orders...</p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default ConfirmationBook;
