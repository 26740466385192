import React from "react";
import { numberFormat } from "../../../exports/UtilFunctions";

import useMarketStock from "../../../hooks/useMarketStock";
import useStockInfo from "../../../hooks/useStockInfo";

function UpperStock({ symbol }) {
  const stock_data = useMarketStock(symbol, true);
  const { stock_info } = useStockInfo(symbol);

  const { display_name = "" } = stock_info;

  let last_traded_price = "0";
  let change_price = "0";
  let change_percentage = "0";

  if (stock_data) {
    last_traded_price = stock_data.last_traded_price;
    change_price = stock_data.change_price;
    change_percentage = stock_data.change_percentage;
  }

  const priceClass = change_price.includes("-") ? "negative" : "positive";

  if (!stock_data) return <></>;

  return (
    <div className="upper__stock">
      <div className="upper__stock__info">
        <p className="upper__stock__symbol">{display_name.toUpperCase()}</p>
      </div>
      <div className="upper__stock__price">
        <p className="upper__stock__value">{numberFormat(last_traded_price)}</p>
        <div className="upper__stock__change__wrapper">
          {change_price && (
            <>
              <p className={priceClass + " upper__stock__change__value"}>
                {numberFormat(change_price)}
              </p>
            </>
          )}
          {change_percentage && (
            <>
              <p className={priceClass + " upper__stock__change"}>
                {priceClass === "positive"
                  ? "+ " + change_percentage
                  : change_percentage}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default UpperStock;
