import React, { useEffect, useState } from "react";
import { BarCode } from "../SwiftIcon/Icons";

const CustomInputFile = ({
  label,
  name,
  type = "text",
  maxlength = "100",
  onchange,
  error,
  value,
  disabled,
  placeholder,
  isUpper,
  style,
  FullDivstyle,
  className,
  DivClassName,
}) => {
  const [value1, setValue1] = useState(value);

  useEffect(() => {
    setValue1(value);
  }, [value]);

  const handleChange = (e) => {
    if (type == "file") {
      const file = e.target.files[0];

      if (file) {
        onchange && onchange(name, file);
        setValue1(file);
      }
    } else {
      let inputValue = e.target.value;
      if (isUpper) inputValue = inputValue.toUpperCase();

      if (type === "number" && maxlength) {
        inputValue = inputValue.replace(/\D/g, "");
        inputValue = inputValue.slice(0, maxlength);
      }
      if (type === "decimal" && maxlength) {
        inputValue = inputValue.replace(/[^\d.]/g, "");

        const parts = inputValue.split(".");
        let integerPart = parts[0];
        let decimalPart = parts[1] || "";

        inputValue =
          integerPart +
          (parts.length === 2 ? "." + decimalPart.slice(0, 2) : "");
        inputValue = inputValue.slice(0, maxlength);
      }
      onchange && onchange(name, inputValue);
      setValue1(inputValue);
    }
  };

  if (type == "file") {
    return (
      <>
        <div
          className="swift-button-icon"
          onClick={() => {
            document.getElementById(label).click();
          }}
        >
          <div className="button-icon">
            <label htmlFor="fileInput">
              <BarCode size={20} />
              <input
                type="file"
                id={label}
                accept="image/*"
                onChange={handleChange}
                style={{ display: "none" }}
              />
            </label>
          </div>
          <p>{label}</p>
        </div>
        {value1 && (
          <p
            className={
              !value1 ? "resend-message hide" : "resend-message swift-error"
            }
          >
            {!value1 ? "None" : value1.name}
          </p>
        )}
        {error && !value1 && (
          <p
            className={
              error === "" ? "error-text hide" : "error-text swift-error"
            }
          >
            {error}
          </p>
        )}
        {!value1 && !error && (
          <p
            className={
              error === "" ? "error-text hide" : "error-text swift-error"
            }
          >
            {error}hi
          </p>
        )}
      </>
    );
  }
  return (
    <div
      className={`swift-input-box ` + DivClassName}
      style={{ ...FullDivstyle }}
    >
      <p>{label}</p>
      <input
        className={`swift-input ` + className}
        maxLength={maxlength}
        type="text"
        value={value1}
        onChange={handleChange}
        // placeholder={`Please Enter ${label}`}
        placeholder={placeholder ? placeholder : `Please Enter ${label}`}
        style={{ ...style, opacity: disabled ? 0.4 : 1 }}
        disabled={disabled}
      />
      <p
        className={
          error === "" || error === "null" ? "error-text hide" : "error-text"
        }
      >
        {error}
      </p>
    </div>
  );
};

export default CustomInputFile;
