import React from 'react';
import { Routes, Route, Navigate  } from "react-router-dom";
import "../../css/BrokerManagement/BrokerManagement.css";
import TradesManagement from './TradesManagement/TradesManagement';
import FundsRequestView from './FundsRequestView';


function BrokerManagement() {
  return (
    <div className='broker-management'>
      <Routes>
        <Route path="*" element={<Navigate to="/404" />} />
        <Route path="/trades" element={<TradesManagement />} />
        <Route path="/funds" element={<FundsRequestView />} />
      </Routes>
    </div>
  )
}

export default BrokerManagement;
