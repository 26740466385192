import React, { useState } from "react";
import moment from "moment";
import ConfirmationItem from "./ConfirmationItem";
import {
  ArrowRight2,
  ArrowDown2,
  FilterSearch,
  ArrowLeft2,
} from "../../CustomComponents/SwiftIcon/Icons";
import { numberFormat } from "../../../exports/UtilFunctions";
import ConfirmBox from "../../CustomComponents/ConfirmBox/ConfirmBox";
import { getDeviceSize } from "../../../exports/InteractiveUtils";
import { COLOR_VARS } from "../../../exports/ColorVars";

const DEVICE_SIZE = getDeviceSize();

function ConfirmationSection({ order, ExecuteSingleOrder }) {
  const [filter, setFilter] = useState("");
  const [trade, setTrade] = useState([]);
  const [totalSellValue, setTotalSellValue] = useState(0);
  const [totalBuyValue, setTotalBuyValue] = useState(0);
  const [totalTradeValue, setTotalTradeValue] = useState(0);
  const [open, setOpen] = useState(false);

  function setTradeIndex(index, value, side) {
    const arr = trade;
    arr[index] = side.toUpperCase() === "SELL" ? -1 * value : value;
    setTrade(arr);

    if (arr.length === 0) return;

    const sum = arr.reduce((a, b) => {
      return a + b;
    });
    const sell_sum = arr.filter((a) => a < 0).reduce((a, b) => a + b, 0);
    const buy_sum = arr.filter((a) => a >= 0).reduce((a, b) => a + b, 0);
    setTotalSellValue(-1 * sell_sum);
    setTotalBuyValue(buy_sum);
    setTotalTradeValue(sum);
  }

  function ApproveTrades(order_id, callback) {
    ExecuteSingleOrder(order_id);
    callback();
  }

  function ConfirmTrade(order_id) {
    ConfirmBox({
      title: "Approve Trades",
      description: <>Are you sure you want to approve the trades?</>,
      properties: [
        {
          id: "2",
          label: "Approve",
          color: "#192b40",
          bgColor: "#ffffff",
          handleFunction: ApproveTrades,
          functionArgs: [order_id],
        },
      ],
      cancel: true,
    });
  }

  return (
    <>
      <div className="order-trades-section">
        <div className="order-trades-header">
          <div className="order-trades-config">
            {DEVICE_SIZE === "S" ? (
              <>
                <div className="trades-config-row">
                  <div>
                    <p>Total Trades: </p>
                    <span>{order.config.count}</span>
                  </div>
                  <div>
                    <span>
                      {moment(order.config.request_time).format(
                        "DD-MM-YY HH:mm:ss"
                      )}
                    </span>
                  </div>
                </div>
                <div className="trades-config-row">
                  <div>
                    <p>Buy Value: </p>
                    <span className="positive">
                      {numberFormat(totalBuyValue)}
                    </span>
                  </div>
                  <div>
                    <p>Sell Value: </p>
                    <span className="negative">
                      {numberFormat(totalSellValue)}
                    </span>
                  </div>
                </div>
                <div className="trades-config-row">
                  <div>
                    <p>Total Value: </p>
                    <span
                      className={totalTradeValue > 0 ? "positive" : "negative"}
                    >
                      {numberFormat(totalTradeValue)}
                    </span>
                  </div>
                </div>
                <div className="trades-config-row">
                  <div
                    onClick={() => {
                      ConfirmTrade(order.config.order_id);
                    }}
                    className="config-box mobile execute"
                  >
                    <button>Approve Trades</button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="config-box expand-collapse-box arrow"
                  onClick={() => {
                    setOpen(!open);
                    setFilter("");
                  }}
                >
                  {open ? (
                    <ArrowDown2 size={24} color="#192b40" />
                  ) : (
                    <ArrowRight2 size={24} color="#192b40" />
                  )}
                </div>
                <div className="config-box account">
                  <span>
                    {order.config.user_role === "MAC"
                      ? "Managed Account"
                      : "Broking Account"}
                  </span>
                </div>
                <div className="config-box count">
                  <span>{order.config.count}</span>
                </div>
                <div className="config-box sell">
                  <span>{numberFormat(totalSellValue)}</span>
                </div>
                <div className="config-box buy">
                  <span>{numberFormat(totalBuyValue)}</span>
                </div>
                <div
                  className={
                    "config-box trade " +
                    (totalTradeValue >= 0 ? "positive" : "negative")
                  }
                >
                  <span>{numberFormat(totalTradeValue)}</span>
                </div>
                <div className="config-box execute">
                  <button
                    onClick={() => {
                      ConfirmTrade(order.config.order_id);
                    }}
                  >
                    Approve Trades
                  </button>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className={"order-trades-container-wrapper " + (open ? "" : "hide")}
        >
          {DEVICE_SIZE !== "S" && (
            <>
              <div className="order-trades-options">
                <p>Generated Trades</p>
                <div className="order-trades-search">
                  <input
                    placeholder="Search symbol"
                    value={filter}
                    onChange={(e) => {
                      setFilter(e.target.value.toUpperCase());
                    }}
                  />
                  <div className="filter-icon">
                    <FilterSearch size={20} color="#192b40" />
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="order-trades-container orders">
            {DEVICE_SIZE === "S" ? (
              <>
                <div className="order-trades-container-header">
                  <p>Trades</p>
                </div>
                {order["confirmation"].map((o, index) => {
                  return (
                    <div className="orders-row-container">
                      <ConfirmationItem
                        key={index}
                        setTradeIndex={setTradeIndex}
                        index={index}
                        show={o.stockCode.includes(filter)}
                        order={o}
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <td>Stock Name</td>
                      <td>Order Type</td>
                      <td>Limit Price</td>
                      <td>Order Side</td>
                      <td>Quantity</td>
                      <td>Current Price</td>
                      <td>Amount</td>
                      <td>Broker</td>
                      <td>Status</td>
                    </tr>
                  </thead>
                  <tbody>
                    {order["confirmation"].map((o, index) => {
                      return (
                        <ConfirmationItem
                          key={index}
                          setTradeIndex={setTradeIndex}
                          index={index}
                          show={o.stockCode.includes(filter)}
                          order={o}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ConfirmationSection;
