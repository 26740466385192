import React from "react";
import Axios from "axios";
import "../../css/MenuSection/Orders.css";
import { GetCookie } from "../../exports/CookieManagement";
import { GetRoleType } from "../../exports/RoleTypes";
import {
  OrderBook as OrderBookIcon,
  TickCircle,
  PlayCircle,
} from "../CustomComponents/SwiftIcon/Icons";
import OrderSection from "./OrdersComponents/OrderSection";
import CustomIconButton from "../Body/CustomChartComponents/CustomIconButton/CustomIconButton";
import CustomCheckBox from "../Body/CustomChartComponents/CustomCheckBox/CustomCheckBox";
import { SWIFTALGO_TIMEWINDOW } from "../../exports/OrderAlgoConfig";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";
import Pulse from "../Loader/Pulse";
import { COLOR_VARS } from "../../exports/ColorVars";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function AlertMessage(title, message) {
  Alert({
    TitleText: title,
    Message: message,
    Band: true,
    BandColor: "#E51A4B",
    BoxColor: "#ffffff",
    TextColor: "#000000",
    AutoClose: {
      Active: true,
      Line: true,
      LineColor: "#E51A4B",
      Time: 5,
    },
  });
}

class OrderBook extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      account_token: GetCookie("token"),
      user_role: GetCookie("role"),
      user_role_type: GetRoleType(GetCookie("role")),
      ordersloaded: false,
      orderslist: [],
      execute: false,
      place: false,
      isPlacing: false,
      allSelected: false,
    };
    this.FetchUserOrders = this.FetchUserOrders.bind(this);
    this.UpdateOrders = this.UpdateOrders.bind(this);
    this.SetSelectBox = this.SetSelectBox.bind(this);
    this.SetBrokerGroup = this.SetBrokerGroup.bind(this);
    this.SetBroker = this.SetBroker.bind(this);
    this.SetAllSelect = this.SetAllSelect.bind(this);
    this.RemoveBulkOrder = this.RemoveBulkOrder.bind(this);
    this.RemoveSingleOrder = this.RemoveSingleOrder.bind(this);
    this.ExecuteBulkOrder = this.ExecuteBulkOrder.bind(this);
    this.ExecuteSingleOrder = this.ExecuteSingleOrder.bind(this);
    this.ApplyOrder = this.ApplyOrder.bind(this);
  }

  componentDidMount() {
    this.FetchUserOrders();
  }

  FetchUserOrders() {
    this.setState({
      isPlacing: false,
    });
    const { account_token } = this.state;
    this.setState({ ordersloaded: false }, () => {
      Axios.get(`${REQUEST_BASE_URL}/order/userorder?token=${account_token}`)
        .then((response) => {
          if (!response.data.error) {
            this.setState(
              {
                orderslist: response.data.data,
              },
              () => {
                this.UpdateOrders();
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  UpdateOrders() {
    const { orderslist } = this.state;

    if (!orderslist || orderslist.length == 0) {
      this.setState({
        ordersloaded: true,
      });
      return;
    }

    let updated_orders = {};
    orderslist.map((list) => {
      const { config, orders, error } = list;

      let order_config = {
        config,
        selected: false,
      };
      let orders_list = [];
      orders.map((order) => {
        if (config && config["brokers"] && config["brokers"].length > 0) {
          order["broker"] = config["brokers"][0]["broker"];
          order["trading_code"] = config["brokers"][0]["trading_code"];
        }
        orders_list.push(order);
      });

      order_config["orders"] = orders_list;
      order_config["error"] = error;
      updated_orders[config.order_id] = order_config;
    });

    this.setState({
      ordersloaded: true,
      orderslist: updated_orders,
    });
  }

  SetSelectBox(order_id, value) {
    const { orderslist } = this.state;
    if (Object.keys(orderslist).includes(order_id)) {
      let obj = orderslist[order_id];
      obj = {
        ...obj,
        selected: value,
      };

      const updated_orders = { ...orderslist, [order_id]: obj };

      this.setState(
        {
          orderslist: updated_orders,
          allSelected: false,
        },
        () => {
          const selected = Object.values(updated_orders).filter((order) => {
            return order.selected === true;
          });
          this.setState({
            place: selected.length > 0 ? true : false,
          });
        }
      );
    }
  }

  SetBrokerGroup(order_id, broker) {
    const { orderslist } = this.state;
    if (Object.keys(orderslist).includes(order_id)) {
      const order = orderslist[order_id];
      if (order) {
        const { config, error, orders, selected } = order;
        const new_orders = [];
        const broker_info = this.GetBrokerInfo(config.brokers, broker);
        orders.map((o) => {
          o["broker"] = broker_info.broker;
          o["trading_code"] = broker_info.trading_code;
          new_orders.push(o);
        });

        const order_config = {
          config,
          error,
          selected,
          orders: new_orders,
        };

        this.setState({
          orderslist: {
            ...orderslist,
            [order_id]: order_config,
          },
        });
      }
    }
  }

  SetBroker(order_id, isin, broker) {
    const { orderslist } = this.state;
    if (Object.keys(orderslist).includes(order_id)) {
      const order = orderslist[order_id];
      if (order) {
        const { config, error, orders, selected } = order;
        const new_orders = [];
        const broker_info = this.GetBrokerInfo(config.brokers, broker);
        orders.map((o) => {
          if (o.isin === isin) {
            o["broker"] = broker_info.broker;
            o["trading_code"] = broker_info.trading_code;
          }
          new_orders.push(o);
        });

        const order_config = {
          config,
          error,
          selected,
          orders: new_orders,
        };

        this.setState({
          orderslist: {
            ...orderslist,
            [order_id]: order_config,
          },
        });
      }
    }
  }

  GetBrokerInfo(brokers, broker) {
    const info = brokers.find((b) => {
      return b.broker === broker;
    });
    return info;
  }

  SetAllSelect(value) {
    const { orderslist } = this.state;

    let updated_orders = {};
    Object.entries(orderslist).map(([order_id, orders]) => {
      const obj = {
        ...orders,
        selected: value,
      };
      updated_orders[order_id] = obj;
    });

    this.setState(
      {
        allSelected: value,
        orderslist: updated_orders,
      },
      () => {
        const selected = Object.values(updated_orders).filter((order) => {
          return order.selected === true;
        });
        this.setState({
          place: selected.length > 0 ? true : false,
        });
      }
    );
  }

  ExecuteBulkOrder() {
    const { place, orderslist } = this.state;

    if (!place) return;

    const filtered_orders = Object.values(orderslist).filter((o) => {
      return o.selected === true;
    });

    this.setState({
      isPlacing: true,
    });

    this.ApplyOrder(filtered_orders);
  }

  ExecuteSingleOrder(order_id) {
    const { orderslist } = this.state;

    const filtered_orders = Object.values(orderslist).filter((o) => {
      return o.config.order_id === order_id;
    });

    this.setState({
      isPlacing: true,
    });

    this.ApplyOrder(filtered_orders);
  }

  ApplyOrder(filtered_orders) {
    const { account_token } = this.state;

    let order_data = [];

    filtered_orders.map((order) => {
      const { config, orders } = order;
      orders.map((o) => {
        if (o.algo_config && o.algo_config["time_window"]) {
          o.algo_config["time_window"] =
            SWIFTALGO_TIMEWINDOW[o.algo_config["time_window"]];
        }

        const order_info = {
          order_id: o.tradeOrderID,
          account_code: config.account_code,
          user_role: config.user_role,
          portfolio: config.portfolio_name,
          name: config.name,
          cp_code: o.CPCode,
          isin: o.isin,
          exchange: o.exchange,
          close: o.close,
          quantity: o.Quantity,
          limit: o.limitPrice,
          side: o.Side,
          type: o.orderType,
          algo_config: o.algo_config,
          symbol: o.stockCode,
          scrip_symbol: o.symbol,
          series: o.series,
          company: o.stockName,
          broker: o.broker,
          trading_code: o.trading_code,
        };

        order_data.push(order_info);
      });
    });

    console.log(order_data);
    // this.setState({
    //     isPlacing: false
    // });
    Axios.post(`${REQUEST_BASE_URL}/order/bulkexecute?token=${account_token}`, {
      data: order_data,
    })
      .then((response) => {
        // console.log(response)
        if (!response.data.error) {
          this.FetchUserOrders();
          AlertMessage(
            "Order Placed Successfully!",
            "Please visit trade book to check the order status"
          );
        } else {
          AlertMessage(
            "Warning",
            "Something went wrong while placing the orders"
          );
          this.setState({
            isPlacing: false,
          });
        }
      })
      .catch((err) => {
        AlertMessage(
          "Warning",
          "Something went wrong while placing the orders"
        );
      });
  }

  RemoveBulkOrder(account_code, order_id) {
    const { account_token } = this.state;

    Axios.get(
      `${REQUEST_BASE_URL}/order/remove?token=${account_token}&account_code=${account_code}&type=bulk&order_id=${order_id}`
    )
      .then((response) => {
        console.log(response);
        if (!response.data.error) {
          this.FetchUserOrders();
          AlertMessage("Trades Removed", "Trades removed successfully!");
        } else {
          AlertMessage(
            "Warning",
            "Something went wrong while removing the trades"
          );
        }
      })
      .catch((err) => {
        AlertMessage(
          "Warning",
          "Something went wrong while removing the trades"
        );
      });
  }

  RemoveSingleOrder(account_code, trade_id) {
    const { account_token } = this.state;

    Axios.get(
      `${REQUEST_BASE_URL}/order/remove?token=${account_token}&account_code=${account_code}&type=single&order_id=${trade_id}`
    )
      .then((response) => {
        console.log(response.data);
        if (!response.data.error) {
          this.FetchUserOrders();
          AlertMessage("Trade Removed", "Trade removed successfully!");
        } else {
          AlertMessage(
            "Warning",
            "Something went wrong while removing the trade"
          );
        }
      })
      .catch((err) => {
        AlertMessage(
          "Warning",
          "Something went wrong while removing the trade"
        );
      });
  }

  render() {
    const {
      user_role_type,
      ordersloaded,
      orderslist,
      execute,
      place,
      allSelected,
      isPlacing,
    } = this.state;

    return (
      <>
        <div className="orders-wrapper">
          <div className="table-container">
            {isPlacing ? (
              <>
                <div className="table-loader">
                  <Pulse />
                  <p>Placing your orders please wait!</p>
                </div>
              </>
            ) : (
              <>
                {orderslist && ordersloaded === true ? (
                  Object.entries(orderslist).length > 0 ? (
                    <>
                      <div className="order-trades-section">
                        <div className="order-trades-header">
                          <div className="order-trades-config">
                            <div className="config-box arrow"></div>
                            {user_role_type === "BROKER" ? (
                              <>
                                <div className="config-box select">
                                  <CustomCheckBox
                                    width={18}
                                    height={18}
                                    isChecked={allSelected}
                                    onChangeValue={(value) => {
                                      this.SetAllSelect(value);
                                    }}
                                  />
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                            <div className="config-box name">
                              <span>Customer Name</span>
                            </div>
                            <div className="config-box account">
                              <span>Account Type</span>
                            </div>

                            <div className="config-box count">
                              <span>Total Trades</span>
                            </div>
                            <div className="config-box sell">
                              <span>Sell Value</span>
                            </div>
                            <div className="config-box buy">
                              <span>Buy Value</span>
                            </div>
                            <div className="config-box trade">
                              <span>Trade Value</span>
                            </div>
                            {user_role_type === "BROKER" ? (
                              <>
                                <div className="config-box broker">
                                  <span>Broker</span>
                                </div>
                                <div className="config-box execute"></div>
                                <div className="config-box remove"></div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>

                      {Object.entries(orderslist).map(([key, order]) => {
                        if (!order.error) {
                          return (
                            <OrderSection
                              key={key}
                              user_role_type={user_role_type}
                              OrderID={key}
                              SetSelectBox={this.SetSelectBox}
                              SetBroker={this.SetBroker}
                              SetBrokerGroup={this.SetBrokerGroup}
                              ExecuteSingleOrder={this.ExecuteSingleOrder}
                              RemoveBulkOrder={this.RemoveBulkOrder}
                              RemoveSingleOrder={this.RemoveSingleOrder}
                              order={order}
                            />
                          );
                        }
                      })}
                    </>
                  ) : (
                    <div className="order-container-empty">
                      <OrderBookIcon
                        size={40}
                        color={COLOR_VARS["SWIFT_COLOR4"]}
                      />
                      <div>
                        <p>No orders found</p>
                        <span>Looks like you have't made any order today</span>
                      </div>
                    </div>
                  )
                ) : (
                  <div className="table-loader">
                    <Pulse />
                    <p>Fetching your orders...</p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="orders-execution">
          {execute ? (
            <>
              <CustomIconButton
                onClick={this.ExecuteBulkOrder}
                icon={<TickCircle size={20} />}
                title="Confirm Execution"
                disabled={!place}
              />
            </>
          ) : (
            <>
              <CustomIconButton
                onClick={() => {
                  this.setState({ execute: true });
                }}
                icon={<PlayCircle size={20} />}
                title="Execute Order"
                disabled={!place}
              />
            </>
          )}
        </div>
      </>
    );
  }
}

export default OrderBook;
