import React from "react";
import { PieChart, Pie, Sector, ResponsiveContainer, Legend } from "recharts";
import { COLOR_VARS } from "../../../../exports/ColorVars";
import { CHART_COLORS } from "../../../../exports/ChartColors";
import { numberFormat } from "../../../../exports/UtilFunctions";

const CustomActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    cornerRadius,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    unit,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 15) * cos;
  const sy = cy + (outerRadius + 15) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  const width = innerRadius * 2;
  const height = innerRadius * 2;

  return (
    <g>
      <foreignObject
        x={cx - innerRadius}
        y={cy - innerRadius}
        dy={8}
        width={width}
        height={height}
      >
        <div
          style={{
            width: width,
            height: height,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 13,
            fontWeight: 600,
            textAlign: "center",
            padding: "0 10px",
          }}
        >
          {payload.name}
        </div>
      </foreignObject>
      <Sector
        cx={cx}
        cy={cy}
        cornerRadius={cornerRadius}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const TextEllipsis = (text, length) => {

  if(!text) return '';

  if (text.length > length - 3) {
    return text.substring(0, length - 3) + "...";
  }
  return text;
};

const RenderLegend = (props) => {
    const { payload } = props;
  
    return (
      <ul
        style={{
          width: "240px",
          display: "flex",
          flexDirection: "column",
          rowGap: "2px",
          listStyle: 'circle'
        }}
      >
        {payload.map((entry, index) => {
          const { payload: payload_value } = entry;
          const { percent } = payload_value;
          return (
            <li
              key={`item-${index}`}
              style={{
                fontSize: "12px",
                fontWeight: "600",
                color: entry.color,
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                color: COLOR_VARS['SWIFT_COLOR4']
              }}
            >
              <p style={{ letterSpacing: "0px", display: 'flex', alignItems: 'center', columnGap: '5px'  }}>
                <span style={{display: 'block', width: '8px', height: '8px', background: entry['color'], borderRadius: '50%'}}></span>
                <span title={entry.value}>{TextEllipsis(entry.value, 30)}</span>
              </p>{" "}
              <p>{numberFormat(parseFloat(percent * 100).toFixed(2))}%</p>
            </li>
          );
        })}
      </ul>
    );
  };
  

class SectorMarketChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: false,
      activeIndex: 0,
    };

    this.GetSectorMarketDataArray = this.GetSectorMarketDataArray.bind(this);
  }

  componentDidMount() {
    this.GetSectorMarketDataArray();
  }

  componentDidUpdate(prevProp) {
    if (prevProp.values !== this.props.values) {
      this.GetSectorMarketDataArray();
    }
  }

  GetSectorMarketDataArray() {
    const { values } = this.props;
    let data = [];

    values.map((s) => {
      let {name,value} = s;
      // if (data.length > 5) {
      //   value = "ETF";
      // }
      const index = data.findIndex((d) => d["name"] === name);
      if (index === -1) {
        data.push({ name: name, value: parseFloat(value) });
      } else {
        data[index]["value"] = data[index]["value"] + parseFloat(value);
      }
    });


    data = data.sort((a,b)=>{return b['value'] - a['value']});

    data = data.map((d,index)=>{
        return {
            ...d,
            fill: CHART_COLORS[index%CHART_COLORS.length]
        }
    })

    this.setState({ data, loading: false });
  }

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const { data } = this.state;

    const { width = 300, height = 150, unit = "Value" } = this.props;

    return (
      <div className="strategy__sector__chart">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={width} height={height}>
            <Pie
              activeIndex={this.state.activeIndex}
              activeShape={<CustomActiveShape unit={unit} />}
              data={data}
              cx="40%"
              cy="40%"
              innerRadius={85}
              outerRadius={120}
              cornerRadius={0}
              opacity={0.9}
              paddingAngle={0}
              dataKey="value"
              onMouseEnter={this.onPieEnter}
            />
            <Legend
              content={RenderLegend}
              iconType="circle"
              iconSize={6}
              layout="vertical"
              verticalAlign="top"
              align="right"
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default SectorMarketChart;
