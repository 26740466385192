import React from 'react';
import { readFullSnapQuoteData } from '../../../exports/FormatData';

const LIVEFEED_BASE_URL = process.env.REACT_APP_LIVEFEED_BASE_URL;
let HeartBeatInterval;

class OrderLiquidity extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            stockCode: null,
            exchange: null,
            ws: null,
            FeedConnection: false,
            stockData: null,
            buy_liquidity: 0,
            sell_liquidity: 0
        }

        this.feedLiveData = this.feedLiveData.bind(this)
        this.makeSocketConnection = this.makeSocketConnection.bind(this)
        this.checkConnection = this.checkConnection.bind(this);
        this.CalculateLiquidty = this.CalculateLiquidty.bind(this);
    }


    componentDidMount() {

        const { el } = this.props
        this.setState({
            stockCode: parseInt(el.Code),
            exchange: parseInt(el.Exchange)
        }, () => {
            // console.log(this.state.stockCode, this.state.exchange)
            this.makeSocketConnection()
                .then(() => {
                    this.checkConnection();
                })
        });
    }

    componentWillUnmount() {
        if (this.state.ws)
            this.state.ws.send(JSON.stringify({
                "a": "unsubscribe",
                "v": [[this.state.exchange, this.state.stockCode]],
                "m": "marketdata"
            }
            ));

        if (HeartBeatInterval) {
            clearInterval(HeartBeatInterval);
        }
    }

    /*<--- Live Data Feed Methods --->*/
    async makeSocketConnection() {
        return new Promise((resolve, reject) => {
            // console.log('make socket connection')
            let ws = new WebSocket(LIVEFEED_BASE_URL);
            ws.onopen = () => {
                // console.log('connection done');
                this.setState({
                    ws: ws,
                    FeedConnection: true
                });
                resolve(ws)
            }
            ws.onerror = () => {
                // console.log('Connection Error');
                this.setState({
                    ws: null,
                    FeedConnection: false
                })
            }
        })



    }

    checkConnection() {
        if (this.state.FeedConnection) {
            this.feedLiveData(this.state.ws);
        }

    }

    feedLiveData(ws) {

        ws.send(JSON.stringify({
            "a": "subscribe",
            "v": [[this.state.exchange, this.state.stockCode]],
            "m": "full_snapquote"
        }
        ));

        ws.onmessage = (response) => {
            var reader = new FileReader();

            reader.readAsArrayBuffer(response.data);
            let convertedData;
            reader.onloadend = (event) => {
                let data = new Uint8Array(reader.result);
                if (response.data.size >= 166) {
                    convertedData = readFullSnapQuoteData(data);
                    let livedata = convertedData.livedata;
                    this.CalculateLiquidty(livedata);
                    this.setState({
                        stockData: livedata
                    });
                }


            }
        }

        HeartBeatInterval = setInterval(() => {
            ws.send(JSON.stringify({
                "a": "h",
                "v": [[this.state.exchange, this.state.stockCode]],
                "m": ""
            }
            ));
        }, 10 * 1000);

    }

    CalculateLiquidty(data) {
        const { top_5_ask_quantities, top_5_bid_quantities } = data;
        let buy_liquidity = 0;
        let sell_liquidity = 0;
        if (top_5_ask_quantities.length >= 3) {
            for (let i = 0; i < 3; i++) {
                buy_liquidity += top_5_ask_quantities[i];
            }
        }
        if (top_5_bid_quantities.length >= 3) {
            for (let i = 0; i < 3; i++) {
                sell_liquidity += top_5_bid_quantities[i];
            }
        }

        this.setState({
            buy_liquidity,
            sell_liquidity
        })
    }

    render() {

        const { el } = this.props;
        const { buy_liquidity, sell_liquidity } = this.state;

        return (
            <span style={{ color: el.Side === "Buy" ? '#28947f' : '#e51a4b', fontWeight: '700' }}>
                {el.Side === 'Buy' ? buy_liquidity : sell_liquidity}
            </span>
        )
    }
}

export default OrderLiquidity;