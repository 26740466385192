import React, { useState, useEffect, useMemo } from "react";
import Axios from "axios";
import { DeleteCookie, GetCookie } from "../../exports/CookieManagement";
import { useNavigate } from "react-router-dom";
import SwiftToolTip from "../CustomComponents/SwiftToolTip/SwiftToolTip";
import ConfirmBox from "../CustomComponents/ConfirmBox/ConfirmBox";
import SwiftModal from "../CustomComponents/SwiftModal/SwiftModal";
import Notifications from "./Notifications";
import { GetRoleType } from "../../exports/RoleTypes";
import GetProfileColor from "../../exports/GetProfileColor";
import {
  LogOut,
  Notification,
  Settings,
} from "../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../exports/ColorVars";
import { getDeviceSize } from "../../exports/InteractiveUtils";
import LOGO from "../../assets/images/logo.png";
import ServerRequest from "../../exports/ServerRequest";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function HeaderOptions({ setIndexOpen, setAccountCode }) {
  const navigate = useNavigate();

  const DEVICE_SIZE = useMemo(() => {
    return getDeviceSize();
  }, []);

  const [notificationOpen, setNotificationOpen] = useState(false);

  const [accountData, setAccountData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState(false);
  const [accountOpen, setAccountOpen] = useState(false);
  const [localAccountCode, setLocalAccountCode] = useState(false);

  function LogoutUser(callback) {
    setTimeout(async () => {
      callback();

      const app = GetCookie("app");

      try {
        if (app) {
          const data = await ServerRequest({
            method: "POST",
            url: `/access/logout`,
            data: {},
          });

          if (!data.error) {
            navigate("/access/logout");
          }
        } else {
          navigate("/");
        }
      } catch (error) {
      } finally {
        DeleteCookie("app");
        DeleteCookie("auth_token");
        DeleteCookie("token");
        DeleteCookie("advisor");
        DeleteCookie("role");
      }
    }, 2000);
  }

  function GetOtherAccounts() {
    const auth_token = GetCookie("auth_token");
    if (auth_token) {
      Axios.get(`${REQUEST_BASE_URL}/access/accounts?auth_token=${auth_token}`)
        .then((response) => {
          const data = response.data;
          if (!data.error) {
            setLoading(false);
            setAccounts(data.data);
          } else {
            setLoading(false);
            setAccounts([]);
          }
        })
        .catch((err) => {});
    }
  }

  function GetAccountData() {
    const token = GetCookie("token");
    const role =
      GetRoleType(GetCookie("role")) === "CUSTOMER" ? "customer" : "superuser";

    Axios.get(`${REQUEST_BASE_URL}/clientinfo/${role}?token=${token}`)
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          const account_data = data.account_data;
          const profile_color = GetProfileColor(account_data.name);
          setAccountData({
            ...data.account_data,
            profile_color: profile_color,
          });
        } else {
          setAccountData(false);
        }
      })
      .catch((err) => {
        setAccountData(false);
      });
  }

  useEffect(() => {
    GetOtherAccounts();
  }, []);

  useEffect(() => {
    setLoading(false);
    setAccounts([accountData]);
  }, [accountData]);

  useEffect(() => {
    GetAccountData();
  }, [localAccountCode]);

  return (
    <>
      <div className="header-options">
        <p className="brand-logo">
          <img src={LOGO} alt="" />
        </p>
        <div className="header-options-list">
          <SwiftToolTip title="Notifications" placement="bottom">
            <div
              className="header-options-menu"
              onClick={() => {
                setNotificationOpen(true);
              }}
            >
              <Notification
                size={DEVICE_SIZE === "S" ? 22 : 14}
                color={COLOR_VARS["SWIFT_COLOR4"]}
              />
            </div>
          </SwiftToolTip>
          <SwiftToolTip title="All Indices" placement="bottom">
            <div
              className="header-options-menu"
              onClick={() => {
                setIndexOpen(true);
              }}
            >
              <Settings
                size={DEVICE_SIZE === "S" ? 22 : 14}
                color={COLOR_VARS["SWIFT_COLOR4"]}
              />
            </div>
          </SwiftToolTip>
          <SwiftToolTip title="Sign Out" placement="bottom">
            <div
              className="header-options-menu"
              onClick={() => {
                ConfirmBox({
                  title: "Logout",
                  description: (
                    <>
                      Are you sure you want to logout? You will be redirected to
                      the login screen.
                    </>
                  ),
                  properties: [
                    {
                      id: "2",
                      label: "Logout",
                      color: "#192b40",
                      bgColor: "#ffffff",
                      handleFunction: LogoutUser,
                      functionArgs: [],
                    },
                  ],
                  cancel: true,
                });
              }}
            >
              <LogOut
                size={DEVICE_SIZE === "S" ? 22 : 14}
                color={COLOR_VARS["SWIFT_COLOR4"]}
              />
            </div>
          </SwiftToolTip>
        </div>

        {/* <SwiftToolTip
          title={name ? titleCase(name) : "Account"}
          placement="bottom"
        >
          <div
            className="header-options-menu hide"
            onClick={() => {
              setAccountOpen(!accountOpen);
            }}
          >
            {name ? (
              <>
                <div
                  className="account-logo"
                >
                  {name.slice(0, 1).toUpperCase()}
                </div>
              </>
            ) : (
              <>
                <PersonOutlinedIcon
                  style={{ color: "#808080" }}
                  fontSize="small"
                />
              </>
            )}

            {accountOpen && (
              <>
                <div
                  className="accounts-wrapper"
                  onMouseLeave={() => {
                    setAccountOpen(false);
                  }}
                >
                  {loading ? (
                    <>
                      <div className="accounts-list-loader">
                        <p>Loading accounts...</p>
                      </div>
                    </>
                  ) : (
                    <>
                      {accounts.length === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div className="accounts-list">
                            {accounts.map((account, index) => {
                              return (
                                <div className="account-row" key={index}>
                                  <div className="account-icon">
                                    <div
                                      className="account-profile"
                                      style={{
                                        background: GetProfileColor(
                                          account.name
                                        ),
                                      }}
                                    >
                                      <span>
                                        {account?.name
                                          .slice(0, 1)
                                          .toUpperCase()}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="account-info">
                                    <p className="account-name">
                                      {titleCase(account.name)}
                                    </p>
                                    <p className="account-role">
                                      {account.role_name}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="menu-line"></div>
                        </>
                      )}
                    </>
                  )}
                  
                  <div
                    className="logout-button"
                    onClick={() => {
                      ConfirmBox({
                        title: "Logout",
                        description: (
                          <>
                            Are you sure you want to logout? You will be
                            redirected to the login screen.
                          </>
                        ),
                        properties: [
                          {
                            id: "2",
                            label: "Logout",
                            color: "#192b40",
                            bgColor: "#ffffff",
                            handleFunction: LogoutUser,
                            functionArgs: [],
                          },
                        ],
                        cancel: true,
                      });
                    }}
                  >
                    <p>Sign out</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </SwiftToolTip> */}
      </div>

      {notificationOpen && (
        <>
          <SwiftModal
            top="2%"
            closeModal={() => {
              setNotificationOpen(false);
            }}
          >
            <Notifications
              close={() => {
                setNotificationOpen(false);
              }}
            ></Notifications>
          </SwiftModal>
        </>
      )}
    </>
  );
}

export default HeaderOptions;
