import React, { useEffect, useState } from "react";
import Axios from "axios";
import moment from "moment";
import { OrderBook } from "../CustomComponents/SwiftIcon/Icons";
import { GetCookie } from "../../exports/CookieManagement";
import Pulse from "../Loader/Pulse";
import {
  replaceNull,
  numberFormat,
  titleCase,
} from "../../exports/UtilFunctions";
import { COLOR_VARS } from "../../exports/ColorVars";
import { getDeviceSize } from "../../exports/InteractiveUtils";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;
const DEVICE_SIZE = getDeviceSize();

function OrdersHistory() {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    GetOrderHistory();
  }, []);

  function GetOrderHistory() {
    const account_token = GetCookie("token");

    Axios.get(`${REQUEST_BASE_URL}/order/userhistory?token=${account_token}`)
      .then((response) => {
        const data = response.data;
        if (data.error) {
          setLoading(false);
          setHistory([]);
        } else {
          setLoading(false);
          setHistory(data.history);
        }
      })
      .catch((err) => {
        setLoading(false);
        setHistory([]);
      });
  }

  return (
    <div className="orders-wrapper full">
      <div className="orders-history-container">
        {loading ? (
          <>
            <div className="orders-history-loading">
              <Pulse />
              <p>Loading orders history...</p>
            </div>
          </>
        ) : (
          <>
            {history.length === 0 ? (
              <>
                <div className="orders-history-empty">
                  <OrderBook size={40} color={COLOR_VARS["SWIFT_COLOR4"]} />
                  <div>
                    <p>No orders history found</p>
                    <span>Looks like you have't made any orders yet</span>
                  </div>
                </div>
              </>
            ) : (
              <>
                {DEVICE_SIZE === "S" ? (
                  <>
                    {history.map((h, index) => {
                      return (
                        <OrderHistoryRow
                          key={index}
                          DEVICE_SIZE={DEVICE_SIZE}
                          h={h}
                        />
                      );
                    })}
                  </>
                ) : (
                  <>
                    <table>
                      <thead>
                        <tr>
                          <td>Customer Name</td>
                          <td>Transaction Date</td>
                          <td>Stock Name</td>
                          <td>Order Type</td>
                          <td>Limit Price</td>
                          <td>Order Side</td>
                          <td>Quantity</td>
                          <td>Transaction Price</td>
                          <td>Transaction Amount</td>
                          <td>Broker</td>
                          <td>Status</td>
                        </tr>
                      </thead>
                      <tbody>
                        {history.map((h, index) => {
                          return (
                            <OrderHistoryRow
                              key={index}
                              DEVICE_SIZE={DEVICE_SIZE}
                              h={h}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function OrderHistoryRow({ DEVICE_SIZE, h }) {
  if (DEVICE_SIZE === "S") {
    return (
      <div className="orders-history-row">
        <div className="history-row">
          <div>
            <span>{h["Side"].toUpperCase()}</span>
          </div>
          <div>
            <span>Broker : {replaceNull(h["brokerCode"])}</span>
          </div>
        </div>
        <div className="history-row">
          <div>
            <span>{h["stockCode"]}</span>
          </div>
          <div>
            <span>{numberFormat(h["Quantity"], 0)}</span>
            <span>@ {numberFormat(replaceNull(h["trxnPrice"]))}</span>
          </div>
        </div>
        <div className="history-row">
          <div>
            <span>{h["orderType"]}</span>
          </div>
          <div>
            <span>{moment(h["Date"]).format("DD-MM-YY HH:mm:ss")}</span>
            <span>{replaceNull(h["Status"])}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <tr>
      <td title={titleCase(h["customer_name"])}>
        {titleCase(h["customer_name"])}
      </td>
      <td>{moment(h["Date"]).format("DD-MM-YY HH:mm:ss")}</td>
      <td>{h["stockCode"]}</td>
      <td>{h["orderType"]}</td>
      <td>{numberFormat(replaceNull(h["limitPrice"]))}</td>
      <td>{h["Side"]}</td>
      <td>{numberFormat(h["Quantity"], 0)}</td>
      <td>{numberFormat(replaceNull(h["trxnPrice"]))}</td>
      <td>{numberFormat(replaceNull(h["grossValue"]))}</td>
      <td>{replaceNull(h["brokerCode"])}</td>
      <td>{replaceNull(h["Status"])}</td>
    </tr>
  );
}

export default OrdersHistory;
