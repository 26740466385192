import React, { useState } from "react";
import SnapDataBox from "./SnapDataBox";
import TopClient from "./TopClient";
import RevenueChart from "./RevenueChart";
import BrokerHistory from "./BrokerHistory";
import SwiftModal from "../CustomComponents/SwiftModal/SwiftModal";
import { numberFormat, priceFormat } from "../../exports/UtilFunctions";
import { Link } from "react-router-dom";
import { ArrowRight2 } from "../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../exports/ColorVars";

function DashboardTop({ user_role_type, data }) {
  const [historyOpen, setHistoryOpen] = useState(false);
  const { customers = [], snapshot } = data;

  return (
    <div className="swift-dashboard-top">
      {user_role_type === "BROKER" || user_role_type === "ADMIN" ? (
        <>
          <BrokerOverview snapshot={snapshot} setHistoryOpen={setHistoryOpen} />
        </>
      ) : (
        <>
          <CustomerOverview snapshot={snapshot} />
        </>
      )}
      {user_role_type === "BROKER" && (
        <>
          <div className="top-clients">
            <p className="dash-box-title">Top Clients</p>
            <div className="clients-wrapper">
              {customers.length > 0 ? (
                <>
                  <div className="clients-wrapper-header client-row">
                    <div>Client</div>
                    <div>Investment</div>
                    <div>Current Value</div>
                    <div>Cash Position</div>
                  </div>

                  {customers.map((c, index) => {
                    return <TopClient key={index} data={c} />;
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </>
      )}
      {/* <div className='dashboard-data-charts'>
                
                <div className='revenue-trend'>
                    <div><p className='dash-box-title'>Revenue Trend</p>
                        <p className='dash-box-subtitle'>This includes broking commissions as well as asset management fees.</p></div>
                    <div className='revenue-chart-wrapper'>
                        <RevenueChart />
                    </div>
                </div>
            </div> */}
      {historyOpen && (
        <>
          <SwiftModal
            top="2%"
            closeModal={() => {
              setHistoryOpen(false);
            }}
          >
            <BrokerHistory
              close={() => {
                setHistoryOpen(false);
              }}
            />
          </SwiftModal>
        </>
      )}
    </div>
  );
}

function BrokerOverview({ snapshot, setHistoryOpen }) {
  return (
    <>
      <div className="dashboard-data-snapshot">
        <p className="dashboard-box-title">Snapshot</p>
        <>
          <span
            id="broking-history"
            onClick={() => {
              setHistoryOpen(true);
            }}
          >
            View History
          </span>
        </>
        <div className="dashboard-snapshot">
          <div className="data-snapshot-row">
            <SnapDataBox
              title="Assets"
              value={priceFormat(snapshot["assets"])}
              class_name="hero"
            />
            <SnapDataBox
              title="Total Active Clients"
              value={snapshot["clients"]}
            />
            <SnapDataBox
              title="Total Transactions"
              value={snapshot["transactions"]}
            />
          </div>
          <div className="data-snapshot-row">
            <SnapDataBox
              title="Net Cash Flow"
              value={priceFormat(snapshot["netflow"])}
            />
            <SnapDataBox
              title="Turnover"
              value={priceFormat(snapshot["turnover"])}
            />
            <SnapDataBox
              title="Brokerage this month"
              value={priceFormat(snapshot["brokerage"])}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function CustomerOverview({ snapshot }) {
  const { assets, investment_cost, profit_loss } = snapshot;

  const profit_loss_change = parseFloat(
    (parseFloat(assets) / parseFloat(investment_cost) - 1) * 100
  );

  return (
    <>
      <div className="customer-overview">
        <SnapDataBox
          title={
            <>
              <Link to="/account/strategy">
                <span>Stocks</span>
                <ArrowRight2 size={13} color={COLOR_VARS["SWIFT_COLOR4"]} />
              </Link>
            </>
          }
          value={numberFormat(assets)}
          subtext={
            <p
              className={`change ${profit_loss >= 0 ? "positive" : "negative"}`}
            >
              <span>{numberFormat(profit_loss)}</span>
              <span>{numberFormat(profit_loss_change)}%</span>
            </p>
          }
          class_name="hero-huge"
        />
      </div>
    </>
  );
}

export default DashboardTop;
