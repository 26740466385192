import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";
import { ArrowRight2 } from "../CustomComponents/SwiftIcon/Icons";
import Pulse from "../Loader/Pulse";
import ServerRequest from "../../exports/ServerRequest";
import AccessHeader from "./AccessHeader";

const warning_config = {
  Band: true,
  BandColor: "#470985",
  BoxColor: "#ffffff",
  TextColor: "#000000",
  AutoClose: {
    Active: true,
    Line: true,
    LineColor: "#470985",
    Time: 3,
  },
};

function AccessPasswordReset() {
  const [step, setStep] = useState(1);

  const [timer, setTimer] = useState(0);

  const [customerID, setCustomerID] = useState("");
  const [customerIDError, setCustomerIDError] = useState("");

  const [PAN, setPAN] = useState("");
  const [PANError, setPANError] = useState("");

  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const counter = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
    return () => clearInterval(counter);
  }, [timer]);

  async function SendOTP() {
    const data = await ServerRequest({
      method: "POST",
      url: `/access/reset/validate`,
      data: {
        customer_id: customerID,
        pan: PAN,
      },
    });
    return data;
  }

  async function ResendOTP() {
    if (timer > 0) {
      return;
    }

    await SendOTP();
    setTimer(30);
  }

  async function SubmitCustomer() {
    if (customerID === "") {
      setCustomerIDError("Please enter valid customer ID");
      setPANError("");
      return;
    }

    if (PAN === "") {
      setPANError("Please enter valid PAN");
      setCustomerIDError("");
      return;
    }

    setCustomerIDError("");
    setPANError("");

    setLoading(true);
    setLoadingMessage("Validating details...");

    const data = await SendOTP();

    const { error, message = "" } = data;

    if (error) {
      Alert({
        TitleText: "Warning",
        Message: message,
        ...warning_config,
      });
    } else {
      setStep(2);
    }

    setLoading(false);
    setLoadingMessage("");
  }

  async function SubmitOTP() {
    if (otp === "") {
      setOtpError("Please enter valid OTP");
      return;
    }

    setLoading(true);
    setLoadingMessage("Validating OTP...");

    const data = await ServerRequest({
      method: "POST",
      url: `/access/reset/otp`,
      data: {
        customer_id: customerID,
        otp: otp,
      },
    });

    const { error } = data;

    if (error) {
      Alert({
        TitleText: "Warning",
        Message: "Please enter valid OTP",
        ...warning_config,
      });
    } else {
      setStep(3);
    }

    setLoading(false);
    setLoadingMessage("");
  }

  async function ResetPassword() {

    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    if (password === "") {
      setPasswordError("Please enter password");
      setConfirmPasswordError("");
      return;
    }

    if (!regex.test(password)){
      setPasswordError("Your password does not match the requirements");
      setConfirmPasswordError("");
      return;
    }

    if (confirmPassword === "") {
      setPasswordError("");
      setConfirmPasswordError("Please enter confirm password");
      return;
    }

    if (password != confirmPassword) {
      setPasswordError("");
      setConfirmPasswordError("Password and Confirm Password does not match");
      return;
    }

    setPasswordError("");
    setConfirmPasswordError("");

    setLoading(true);
    setLoadingMessage("Resetting your password");

    const data = await ServerRequest({
      method: "POST",
      url: `/access/reset/password`,
      data: {
        customer_id: customerID,
        password: password,
      },
    });

    const { error, message="" } = data;

    if (error) {
      setConfirmPasswordError(message != "" ? message : "Something went wrong while resetting the password");
    } else {
      setStep(4);
    }

    setLoading(false);
    setLoadingMessage("");
  }

  function RedirectToLogin() {
    navigate("/access/login");
  }

  return (
    <div className="user-login">
      <div className="user-login-popup-wrapper">
        {loading ? (
          <>
            <div className="popup-loader">
              <Pulse />
              <p>{loadingMessage}</p>
            </div>
          </>
        ) : (
          <>
            <div className="login-header">
              <AccessHeader header_text="Customer Password Reset" />
            </div>
            <>
              {step === 1 && (
                <>
                  <div className="login-content">
                    <div className="login-body">
                      <div className="swift-input-box">
                        <p>Customer ID</p>
                        <input
                          className="swift-input"
                          value={customerID}
                          onChange={(e) => {
                            setCustomerID(e.target.value.toUpperCase());
                          }}
                          placeholder=""
                        />
                        <p
                          className={
                            customerIDError === ""
                              ? "error-text hide"
                              : "error-text"
                          }
                        >
                          {customerIDError}
                        </p>
                      </div>
                      <div className="swift-input-box">
                        <p>PAN</p>
                        <input
                          className="swift-input"
                          value={PAN}
                          maxLength={10}
                          onChange={(e) => {
                            setPAN(e.target.value.toUpperCase());
                          }}
                          placeholder=""
                        />
                        <p
                          className={
                            PANError === "" ? "error-text hide" : "error-text"
                          }
                        >
                          {PANError}
                        </p>
                      </div>
                    </div>
                    <div className="login-footer">
                      <div
                        className="swift-button-icon"
                        onClick={SubmitCustomer}
                      >
                        <div className="button-icon">
                          <ArrowRight2 size={20} />
                        </div>
                        <p>Continue</p>
                      </div>
                    </div>
                  </div>
                  <div className="footer-line">
                    <p>
                      Already have an account?{" "}
                      <Link to="/access/login">Login</Link>
                    </p>
                    <p>
                      Don't have an account?{" "}
                      <Link to="/access/signup">Signup</Link>
                    </p>
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <div className="login-content">
                    <div className="login-body">
                      <div className="swift-input-box">
                        <p>Verification OTP</p>
                        <input
                          className="swift-input"
                          value={otp}
                          onChange={(e) => {
                            setOtp(e.target.value.replace(/[^\d]/g, ""));
                          }}
                          placeholder=""
                        />
                        <p
                          className={
                            otpError === "" ? "error-text hide" : "error-text"
                          }
                        >
                          {otpError}
                        </p>
                      </div>
                      {timer === 0 ? (
                        <>
                          <p className="resend-otp-button" onClick={ResendOTP}>
                            Resend OTP
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="resend-message">
                            Please wait {timer} seconds to resend
                          </p>
                        </>
                      )}
                    </div>
                    <div className="login-footer">
                      <div className="swift-button-icon" onClick={SubmitOTP}>
                        <div className="button-icon">
                          <ArrowRight2 size={20} />
                        </div>
                        <p>Continue</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {step === 3 && (
                <>
                  <div className="login-content">
                    <div className="login-body">
                      <div className="swift-input-box">
                        <p>New Password</p>
                        
                        <input
                          className="swift-input"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          placeholder=""
                        />
                        <p
                          className={
                            passwordError === ""
                              ? "error-text hide"
                              : "error-text"
                          }
                        >
                          {passwordError}
                        </p>
                      </div>
                      <div className="swift-input-box">
                        <p>Confirm New Password</p>
                        <input
                          className="swift-input"
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                          placeholder=""
                        />
                        <p
                          className={
                            confirmPasswordError === ""
                              ? "error-text hide"
                              : "error-text"
                          }
                        >
                          {confirmPasswordError}
                        </p>
                      </div>
                      <p id="password-note">
                          * Password must be 8+ characters, 1 digit and 1 special
                          symbol
                      </p>
                    </div>
                    <div className="login-footer">
                      <div
                        className="swift-button-icon"
                        onClick={ResetPassword}
                      >
                        <div className="button-icon">
                          <ArrowRight2 size={20} />
                        </div>
                        <p>Reset Password</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {step === 4 && (
                <>
                  <div className="login-content">
                    <div className="login-body">
                      <div className="login-message">
                        <p>Password has been reset successfully</p>
                        <span>
                          You can now use your new password to login to your
                          account
                        </span>
                      </div>
                    </div>
                    <div className="login-footer">
                      <div
                        className="swift-button-icon"
                        onClick={RedirectToLogin}
                      >
                        <div className="button-icon">
                          <ArrowRight2 size={20} />
                        </div>
                        <p>Login</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          </>
        )}
      </div>
    </div>
  );
}

export default AccessPasswordReset;
