const moment = require('moment');

export const replaceNull = (string, replace = "-") => {
  if (!string || string == "" || string == null) return replace;
  return string;
};

export const titleCase = (string) => {
  if (!string) return "";
  return string.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
};

export const priceFormat = (number, frac = 2) => {
  if (number !== 0 && (!number || isNaN(number))) return "0.00";
  return parseFloat(number).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: frac,
    maximumFractionDigits: frac,
  });
};

export const numberFormat = (number, frac = 2) => {

  if (!number) {
    number = 0;
  }

  if (typeof number === "string") {
    number = number.replace(/,/g, "");
  }

  if (isNaN(number)) {
    number = 0;
  }

  return parseFloat(number).toLocaleString("en-IN", {
    minimumFractionDigits: frac,
    maximumFractionDigits: frac,
  });
};

export function isEmpty(value) {
  return value === undefined || value === null || value === "" || !value;
}

export function checkLength(value, length) {
  return value && value.length == length;
}

export const isValidEmail = (email) => {
  const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return emailPattern.test(email);
};

export const isValidPhoneNumber = (phoneNumber) => {
  const phoneNumberPattern = /^\d{10,}$/;
  return phoneNumberPattern.test(phoneNumber);
};

export const isValidPan = (pan) => {
  if (!pan) return false;
  const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
  return panPattern.test(pan.toUpperCase());
};

export const isValidFloat = (number) => {
  const floatRegex = /^[+-]?\d+(\.\d+)?$/;
  if (!floatRegex.test(number)) {
    return false;
  }
  const floatNumber = parseFloat(number);
  return floatNumber >= 0 && floatNumber <= 100;
};

export const isValidCustomerId = (customerId, accountCode) => {
  if (customerId && accountCode && customerId.length == 7) {
    const firstChar = customerId.charAt(0);
    const randomNumber = customerId.slice(1);
    if (firstChar === accountCode.charAt(0) && /^\d{6}$/.test(randomNumber)) {
      return true;
    }
  }
  return false;
};

export function convertNumberToWords(amount) {
  const [integerPart, fractionalPart] = amount.toString().split(".");

  const convertLogic = (price) => {
    var sglDigit = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ],
      dblDigit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ],
      tensPlace = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ],
      handle_tens = function (dgt, prevDgt) {
        return 0 == dgt
          ? ""
          : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt]);
      },
      handle_utlc = function (dgt, nxtDgt, denom) {
        return (
          (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") +
          (0 != nxtDgt || dgt > 0 ? " " + denom : "")
        );
      };

    var str = "",
      digitIdx = 0,
      digit = 0,
      nxtDigit = 0,
      words = [];
    if (((price += ""), isNaN(parseInt(price)))) str = "";
    else if (parseInt(price) > 0 && price.length <= 10) {
      for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--)
        switch (
        ((digit = price[digitIdx] - 0),
          (nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0),
          price.length - digitIdx - 1)
        ) {
          case 0:
            words.push(handle_utlc(digit, nxtDigit, ""));
            break;
          case 1:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 2:
            words.push(
              0 != digit
                ? " " +
                sglDigit[digit] +
                " Hundred" +
                (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2]
                  ? " "
                  : "")
                : ""
            );
            break;
          case 3:
            words.push(handle_utlc(digit, nxtDigit, "Thousand"));
            break;
          case 4:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 5:
            words.push(handle_utlc(digit, nxtDigit, "Lakh"));
            break;
          case 6:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 7:
            words.push(handle_utlc(digit, nxtDigit, "Crore"));
            break;
          case 8:
            words.push(handle_tens(digit, price[digitIdx + 1]));
            break;
          case 9:
            words.push(
              0 != digit
                ? " " +
                sglDigit[digit] +
                " Hundred" +
                (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2]
                  ? " "
                  : " Crore")
                : ""
            );
        }
      str = words.reverse().join("");
    } else str = "";
    return str;
  };

  if (isEmpty(fractionalPart) || parseFloat(fractionalPart) == 0)
    return convertLogic(integerPart);
  else
    return (
      convertLogic(integerPart) +
      " And " +
      convertLogic(fractionalPart) +
      "Paisa"
    );
}

export const formateDate = (dateString, time = false) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  if (time) {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  }
  return `${day}-${month}-${year}`
}

export function momentDate(date,format){
  return moment(date).format(format);
}