import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { GetCookie } from '../../exports/CookieManagement';
import { FilterSearch, UserSearch } from '../CustomComponents/SwiftIcon/Icons';
import Pulse from '../Loader/Pulse';
import ClientRow from './ClientRow';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function ExternalClients() {

    const [filter, setFilter] = useState('');
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = GetCookie('token');
        axios.get(`${REQUEST_BASE_URL}/roles/external?token=${token}`).then((response) => {
            const data = response.data;
            if (data.error) {
                setLoading(false);
            }
            else {
                setLoading(false);
                setCustomers(data.customers);
            }
        }).catch((error) => {
            // console.log(error)
            setLoading(false);
        })
    }, []);

    if (loading) {
        return <div className='roles-customer-loading-content'>
            <Pulse />
            <p className='message'>Loading customer details, please wait...</p>
        </div>
    }

    if (customers.length === 0) {
        return <div className='roles-customer-loading-content'>
            <UserSearch size={80} color="#192b40" />
            <p className='message'>You don't have any active customers</p>
            <button className='roles-customers-add'><Link to="/account/clients">Add New Customer</Link></button>
        </div>
    }

    return (
        <>
            <div className='roles-customers-header'>
                <div className='roles-customers-search'>
                    <input placeholder='Search customer' value={filter} onChange={(e) => { setFilter(e.target.value) }} />
                    <div className='filter-icon'><FilterSearch size={20} color="#192b40" /></div>
                </div>
            </div>
            <div className='roles-customers-content'>
                <div className='customer-content-header'>
                    <div className='cust-config arrow' ></div>
                    <div className='cust-config name'>Customer Name</div>
                    <div className='cust-config pan'>PAN Number</div>
                    <div className='cust-config mobile'>Mobile Number</div>
                    <div className='cust-config email'>Email Address</div>
                    <div className='cust-config type'>Customer Type</div>
                    <div className='cust-config kyc'>KYC Form</div>
                    {/* <div className='cust-config broker'></div> */}
                </div>
                <div className='customer-content-body'>
                    {customers.map((c, index) => {
                        return <ClientRow show={c ? c.name.toLowerCase().includes(filter.toLowerCase()) : true} key={index} customer={c} />
                    })}
                </div>
            </div>
        </>
    )
}

export default ExternalClients;