import React, { useState } from 'react';
import axios from 'axios';
import { GetCookie } from '../../exports/CookieManagement';
import { Alert } from '../Body/CustomChartComponents/CustomAlert/CustomAlert';
import CustomSelect from "../Body/CustomChartComponents/CustomSelect/CustomSelect";
import CustomInput from "../Body/CustomChartComponents/CustomInput/CustomInput";
import CustomIconButton from "../Body/CustomChartComponents/CustomIconButton/CustomIconButton";
import { ArrowRight2, } from '../CustomComponents/SwiftIcon/Icons';
import Pulse from '../Loader/Pulse';

let roles = ["Managed Customer", "Broking Customer"];
const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;


function KYCClient() {

    const [pan, setPan] = useState('');
    const [role, setRole] = useState(roles[0]);
    const [panError, setPanError] = useState('');
    const [loading, setLoading] = useState(false);

    function ValidatePAN(pan) {
        const regex = /[A-Z]{3}[PCHFATBLJG]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/;
        return regex.test(pan);
    }

    function AddClient() {
        if (!ValidatePAN(pan)) {
            setPanError("Please enter correct pan number");
            return;
        }

        setLoading(true);
        setPanError('');

        const account_token = GetCookie('token')

        axios.post(`${REQUEST_BASE_URL}/client/addkyc?token=${account_token}`, { customer_type: role, pannumber: pan })
            .then((response) => {

                const data = response.data;
                console.log(response.data);
                setLoading(false);

                if (data.error) {
                    if (data.exists) {
                        Alert({
                            TitleText: 'Warning',
                            Message: 'A client with given PAN already exists',
                            BandColor: '#470985',
                            AutoClose: {
                                Active: true,
                                Line: true,
                                LineColor: '#470985',
                                Time: 3
                            }
                        });
                    }
                    else {
                        Alert({
                            TitleText: 'Failure',
                            Message: 'New customer has not been added',
                            BandColor: '#D74467',
                            AutoClose: {
                                Active: true,
                                Line: true,
                                LineColor: '#D74467',
                                Time: 3
                            }
                        });
                    }
                }
                else {
                    Alert({
                        TitleText: 'Success',
                        Message: 'New KYC customer has been added successfully',
                        BandColor: '#67D098',
                        AutoClose: {
                            Active: true,
                            Line: true,
                            LineColor: '#67D098',
                            Time: 3
                        }
                    });
                    setPan('');
                }
            })

    }

    return (
        <div className='client-add'>
            <p className='title'>Add Customer KYC</p>
            {loading && <>
                <div className='client-add-loader'>
                    <Pulse />
                </div>
            </>}

            <CustomSelect
                heading="Type of Customer"
                defaultIndex={0}
                value={role}
                options={roles}
                onTypeChange={(value) => { setRole(value) }}
            />
            <div className="client-pan">
                <CustomInput
                    heading="Permanent Account Number (PAN)"
                    placeholder="Enter PAN number of client"
                    value={pan}
                    onChange={(value) => { setPan(value.toUpperCase()) }}
                    maxLength={10}
                    error={panError}
                />
            </div>

            <div className="step-button">
                <CustomIconButton title="Add Client" onClick={() => { AddClient() }} icon={<ArrowRight2 size={20} />} />
            </div>
        </div>
    )
}

export default KYCClient