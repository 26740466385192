import { useEffect, useState } from "react";
import Axios from "axios";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;


const cache = new Map();

const useStockInfo = (symbol) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(false);

  useEffect(() => {
    if (!symbol || symbol === "") return;
    GetStockInfo();
  }, [symbol]);

  function GetStockInfo() {
    setLoading(true);

    if (cache.has(symbol)) {
      const cached_data = cache.get(symbol);
      setData(cached_data);
      setLoading(false);
    } else {
      Axios.get(`${REQUEST_BASE_URL}/StockDetails/${symbol}`)
        .then((response) => {
          const data = response.data;
          if (data.error) {
            setData(false);
          } else {
            setData(data.stock);
            cache.set(symbol, data.stock);
          }
        })
        .catch((error) => {
          setData(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
  return { loading, stock_info: data };
};

export default useStockInfo;
