import React from 'react';

class HeroReach extends React.PureComponent {
    render() {
        return (
            <div className="hero__body__reach">
                <h1>Reach Us</h1>
            </div>
        )
    }
}

export default HeroReach;
