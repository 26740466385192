import React, { useEffect, useState } from "react";
import ServerRequest from "../../exports/ServerRequest";
import { useNavigate, Navigate, Link } from "react-router-dom";
import LOGO from "../../assets/images/logo.png";
import Pulse from "../Loader/Pulse";
import CustomIconButton from "../Body/CustomChartComponents/CustomIconButton/CustomIconButton";
import { ArrowRight2 } from "../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../exports/ColorVars";
import { numberFormat } from "../../exports/UtilFunctions";
import ConfirmBox from "../CustomComponents/ConfirmBox/ConfirmBox";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";
import moment from "moment";

function FundsRequestView() {
  const queryParameters = new URLSearchParams(window.location.search);
  const request_id = queryParameters.get("request_id");

  const navigate = useNavigate();

  const [data, setData] = useState(undefined);

  useEffect(() => {
    async function GetRequestDetails() {
      const data = await ServerRequest({
        method: "GET",
        url: `/funds/request?request_id=${request_id}`,
      });
      return data;
    }

    GetRequestDetails().then((data) => {
      if (!data.error) {
        setData(data.data);
      } else {
        throw new Error("Invalid Credentials");
      }
    }).catch((error)=>{
      navigate("/404");
    });
  }, [request_id]);

  function ConfirmFundsRequest(callback) {
    async function ConfirmRequest() {
      const data = await ServerRequest({
        method: "PUT",
        url: `/funds/request`,
        data: {
          request_id: request_id,
        },
      });
      return data;
    }

    ConfirmRequest().then((data) => {
      if (!data.error) {
        Alert({
          TitleText: 'Funds Request Confirmation',
          Message: 'Funds request have been confirmed successfully',
          Band: true,
          BandColor: '#67D098',
          BoxColor: '#ffffff',
          TextColor: '#000000',
          AutoClose: {
              Active: true,
              Line: true,
              LineColor: '#67D098',
              Time: 3
          }
        });

        callback();

        setTimeout(()=>{
          navigate("/");
        },3000);

      } else {
        throw new Error("Invalid Credentials");
      }
    }).catch(()=>{
      Alert({
        TitleText: "Funds Request Confirmation",
        Message: "Something went wrong while confirming the request",
        Band: true,
        BandColor: "#67D098",
        BoxColor: "#ffffff",
        TextColor: "#000000",
        AutoClose: {
          Active: true,
          Line: true,
          LineColor: "#67D098",
          Time: 3,
        },
      });

      callback();

      
    }); 
  }

  if (!data) {
    return (
      <div className="funds-request-wrapper">
        <div className="request-box loader">
          <Pulse />
        </div>
      </div>
    );
  }

  const {
    request_type,
    broker_account_id,
    amount,
    utr_number,
    status,
    input_timestamp,
  } = data;

  if(status != 'pending'){
    return <Navigate to="/404" />
  }

  return (
    <div className="funds-request-wrapper">
      <div className="request-box">
        <div className="request-box-header">
          <img src={LOGO} alt="" />
          <h2>
            <Link to="/"><i>swift</i>folios</Link>
          </h2>
          <span>Fund Request Confirmation</span>
        </div>
        <div className="request-box-body">
          <p>
            Account Code<span>{broker_account_id}</span>
          </p>
          <p>
            UTR Number<span>{utr_number}</span>
          </p>
          <p>Request Time<span>{moment(input_timestamp).format('DD-MM-YYYY HH:mm:ss')}</span></p>
          <h4>
            Fund {request_type} of Rs. <span>{numberFormat(amount, 2)}</span>{" "}
            has been registered for the client with above credentials. Kindly
            click on the <span>Confirm</span> to confirm the funds{" "}
            {request_type} request.
          </h4>
        </div>
        <div className="request-box-footer">
          <CustomIconButton
            icon={<ArrowRight2 size={20} color={COLOR_VARS["SWIFT_COLOR1"]} />}
            title="Confirm"
            onClick={() => {
              ConfirmBox({
                title: `Confirm ${request_type} request`,
                description: (
                  <>
                    Are you sure you want to confirm the funds {request_type}{" "}
                    request for the given details?
                    <br />
                    Keep in mind that you won't be able to undo the action.
                  </>
                ),
                properties: [
                  {
                    id: "2",
                    label: "Confirm",
                    color: COLOR_VARS["SWIFT_COLOR4"],
                    bgColor: "#ffffff",
                    handleFunction: ConfirmFundsRequest,
                  },
                ],
                cancel: true,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default FundsRequestView;
