import React from 'react';
import Axios from 'axios';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { Alert } from '../Body/CustomChartComponents/CustomAlert/CustomAlert';

import DeleteOrderIcon from '../../assets/icons/deleteorder.svg';
import EditOrderIcon from '../../assets/icons/editorder.svg';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

export class OrderItem extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            modifyPopup: false,
            filled_qty: 0,
        }

        this.ModifyOrder = this.ModifyOrder.bind(this)
        this.CancelOrder = this.CancelOrder.bind(this)
        this.confirmBox = this.confirmBox.bind(this)
        this.displayAlert = this.displayAlert.bind(this)
    }

    componentDidMount() {

        const { omsOrderId, pan } = this.props.order;

        Axios.get(`${REQUEST_BASE_URL}/order/orderhistory?oms_order_id=${omsOrderId}&pan=${pan}`).then((response) => {
            const history = response.data.history;
            this.setState({
                filled_qty: history.fill_quantity
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    OrderBody({ td = [] }) {
        return (
            <tr>
                {
                    td.map((v, i) => {
                        return <td key={i}>{v}</td>
                    })
                }
            </tr>
        )
    }

    OrderItemName({ name, fullname }) {
        return (
            <>
                <div className="stock-info">
                    <span className="stock-name">{name}</span>
                    <span className="stock-fullname">{fullname}</span>
                </div>
            </>)
    }

    OrderSide({ side }) {
        return <div className={side.toLowerCase()}>
            {side.toUpperCase()}
        </div>
    }

    OrderStatus({ status }) {
        return <div className={status.toLowerCase()}>
            {status}
        </div>
    }

    OrderTime({ time }) {
        return moment(time).format('H:mm:ss')
    }

    ModifyOrder({ order, status }) {
        return (
            <>
                <div className="modify-order"
                    style={{
                        display: status === "Pending" ? 'flex' : 'none'
                    }}
                    onClick={() =>
                        this.props.OpenModifyPopup({
                            symbol: order.name,
                            portfolio: order.portfolio,
                            side: order.side.toLowerCase() === 'buy' ? 'B' : 'S',
                            quantity: parseInt(order.quantity),
                            type: this.getOrderType(order.ordertype),
                            orderprice: parseFloat(order.limitprice),
                            omsOrderId: order.omsOrderId,
                            onclose: this.props.FetchUserOrders
                        })
                    }>
                    <img src={EditOrderIcon} alt="" />
                </div>
            </>
        )
    }

    CancelOrder({ text, status }) {
        return (
            <>
                <div className="cancel-order"
                    style={{
                        display: status === "Pending" ? 'flex' : 'none'
                    }}
                    onClick={() =>
                        status === "Pending" && this.confirmBox("Are you sure! You want to cancel this order. Select YES to proceed.")
                    }>
                    <img src={DeleteOrderIcon} alt="" />
                </div>
            </>
        )
    }

    ReplaceNoise(value) {
        if (value === null || value === undefined || value === '' || parseInt(value) === 0) {
            return '-'
        }
        return value;
    }

    confirmBox(msg) {
        confirmAlert({
            title: 'Confirmation',
            message: msg,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        console.log(this.props.order)
                        Axios.post(`${REQUEST_BASE_URL}/order/cancel`, { detail: this.props.order })
                            .then(res => {
                                if (res.data.success === "false") {
                                    this.displayAlert("Something went wrong in cancelling the buy/sell order. Please try again later.");
                                } else {
                                    this.displayAlert("Your order has been cancelled successfully.")
                                    this.props.FetchUserOrders()
                                }
                            })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { return }
                }
            ]
        })
    }

    displayAlert = (msg) => {
        Alert({
            TitleText: 'Warning',
            Message: msg,
            AutoClose: {
                Active: true,
                Line: true,
                LineColor: '#470985',
                Time: 3
            }
        })
    }

    getOrderType(type) {
        if (type === 'MARKET') {
            return 'M'
        }
        else if (type === 'LIMIT') {
            return 'L';
        }
        else {
            return type;
        }

    }

    render() {

        const { name, fullname, ordertype, limitprice, side, quantity, filledquantity, avgprice, grossvalue, timestamp, status } = this.props.order;
        const { filled_qty } = this.state;

        return (
            <>

                <this.OrderBody td={[
                    <this.OrderItemName name={name} fullname={fullname} />,
                    ordertype.toUpperCase(),
                    this.ReplaceNoise(limitprice),
                    <this.OrderSide side={side} />,
                    filled_qty + '/' + (quantity - filled_qty),
                    this.ReplaceNoise(avgprice),
                    this.ReplaceNoise(grossvalue),
                    <this.OrderTime time={timestamp} />,
                    <this.OrderStatus status={status} />,
                    <this.ModifyOrder order={this.props.order} status={status} />,
                    <this.CancelOrder text="Cancel" status={status} />
                ]} />
            </>
        )
    }
}

export default OrderItem;