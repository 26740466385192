import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { GetCookie } from '../../exports/CookieManagement';
import Pulse from '../Loader/Pulse';
import { Alert } from '../Body/CustomChartComponents/CustomAlert/CustomAlert';
import { ICON_COLORS, ArrowRight2, BarCode } from '../CustomComponents/SwiftIcon/Icons';
import CustomSelect from '../Body/CustomChartComponents/CustomSelect/CustomSelect';
import CustomInput from '../Body/CustomChartComponents/CustomInput/CustomInput';
import CustomIconButton from '../Body/CustomChartComponents/CustomIconButton/CustomIconButton';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function RoleAddition() {

    const [allRoles,setAllRoles] = useState([]);
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState('');
    const [pan, setPan] = useState('');
    const [name, setName] = useState('');
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [otp, setOTP] = useState('');
    const [timer, setTimer] = useState(0);
    const [otpSent, setOtpSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const [errors, setErrors] = useState({
        pan: '',
        name: '',
        mobile: '',
        email: '',
        otp: '',
    });

    useEffect(()=>{
        axios.get(`${REQUEST_BASE_URL}/roles`).then((response)=>{
            const data = response.data;
            const roles = data.roles;
            setAllRoles(roles);
        }).catch((error)=>{
            setAllRoles([]);
        });
    },[]);

    useEffect(() => {
        const counter = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
        return () => {
            if(!counter) return;
            clearInterval(counter);
        };
    }, [timer]);

    useEffect(() => {
        if(allRoles.length === 0) return;

        const broker_roles = allRoles.filter((r)=>{return r.role_type === 'BROKER' && r.role_code != 'SU'});
        const roles = broker_roles.map((r)=>{
            return {
                role: r.role_code,
                title: r.role_name
            }
        })

        setRoles(roles.map((r) => { return r.title }));
    }, [allRoles]);

    function UpdateRole(role) {
        setRole(role);
    }

    function ValidatePAN(pan) {
        const regex = /[A-Z]{3}[PCHFATBLJG]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/;
        return regex.test(pan);
    }

    function sendOTP() {

        setSubmitError('');
        setOTP('');

        if (timer > 0) {
            return;
        }

        if (!ValidatePAN(pan)) {
            SetEmptyErros("Please enter correct pan number");
            return;
        }

        if (name === '') {
            SetEmptyErros("", "Please enter user's name");
            return;
        }

        if (mobile === '') {
            SetEmptyErros("", "", "Please enter correct mobile number");
            return;
        }

        if (email === '') {
            SetEmptyErros("", "", "", "Please enter valid email address");
            return;
        }
        else {
            setLoading(true);

            axios.post(`${REQUEST_BASE_URL}/access/sendotp?email=${email}&verify=false`).then((response) => {
                const data = response.data;
                if (!data.error) {

                    Alert({
                        TitleText: 'One Time Password',
                        Message: 'OTP sent successfully',
                        AutoClose: {
                            Active: true,
                            Line: true,
                            LineColor: '#470985',
                            Time: 3
                        }
                    })
                    SetEmptyErros();
                    setLoading(false);
                    setTimer(30);
                    setOtpSent(true);

                }
                else {
                    if (data.status === 'accounterror') {
                        SetEmptyErros("", "", "", "Account exists with this email!")
                    }
                    else {
                        SetEmptyErros("", "", "", "Something went wrong!")
                    }
                    setLoading(false);
                }
            }).catch((error) => {
                SetEmptyErros("", "", "", "Something went wrong!")
                setLoading(false);
            });

        }
    }

    function SetEmptyErros(pan = "", name = "", mobile = "", email = "", otp = "") {
        setErrors({
            pan: pan,
            name: name,
            mobile: mobile,
            email: email
        })
    }

    function AddSubUser() {


        if (otp === '') {
            SetEmptyErros("", "", "", "", "Please enter otp");
            return;
        }

        const account_token = GetCookie('token');
        const role_value = allRoles.find((r) => { return r.role_name == role });

        if (role_value === -1) {
            return;
        }


        axios.post(`${REQUEST_BASE_URL}/roles/internal?token=${account_token}`, {
            user: { role: role_value.role_code, pan, name, mobile, email, otp }
        }).then((response) => {
            const data = response.data;
            if (data.error) {
                if (data.message === 'invalidotp') {
                    setSubmitError('Entered OTP is invalid!');
                }
                else if (data.message === 'accounterror') {
                    setSubmitError('Account already exists! Please use different email address.');
                }
                else {
                    setSubmitError('Something went wrong! Please try again later.');
                }
            }
            else {
                Alert({
                    TitleText: 'Success',
                    Message: 'New Internal User Has Been Added!',
                    AutoClose: {
                        Active: true,
                        Line: true,
                        LineColor: '#470985',
                        Time: 3
                    }
                });
                setSubmitError('');
                setRole('TRD');
                setPan('');
                setName('');
                setMobile('');
                setEmail('');
                setOTP('');
                setOtpSent(false);
            }
        }).catch((error) => {
            console.log(error)
        })
    }


    return (
        <div className='role-addition'>
            <p className='title'>Add Internal User</p>
            {loading && roles.length > 0 && <>
                <div className='addition-loader'>
                    <Pulse />
                </div>
            </>}
            <div className='role-addition-content'>
                <div className='role-addition-row'>
                    <div className='swift-select-box'>

                        <CustomSelect
                            heading="User Role"
                            defaultIndex={0}
                            value={role}
                            options={roles}
                            onTypeChange={(role) => { UpdateRole(role) }}
                        />

                    </div>
                    <CustomInput
                        placeholder=""
                        heading="Pan Number"
                        value={pan}
                        error={errors.pan}
                        maxLength={10}
                        onChange={(val) => { setPan(val.toUpperCase()) }}
                    />
                </div>
                <div className='role-addition-row'>
                    <CustomInput
                        placeholder=""
                        heading="Full Name"
                        value={name}
                        error={errors.name}
                        onChange={(val) => { setName(val.toUpperCase()) }}
                    />
                    <CustomInput
                        placeholder=""
                        heading="Mobile Number"
                        value={mobile}
                        error={errors.mobile}
                        maxLength={10}
                        onChange={(val) => { setMobile(val.replace(/[^\d]/g, '')) }}
                    />

                </div>
                <div className='role-addition-row'>
                    <div className='swift-input-box'>
                        <div className='input-header'>
                            <p>Email Address</p>
                            {timer > 0 ? <span className='secondary-text'>Resend in {timer} seconds</span> : <span className='primary-text' onClick={sendOTP}>Send OTP</span>}
                        </div>
                        <input className='swift-input' value={email} placeholder="" onChange={(e) => { setEmail(e.target.value) }} />
                        <p className={errors.email === '' ? 'error-text hide' : 'error-text'}>{errors.email}</p>
                    </div>
                    <CustomInput
                        placeholder=""
                        heading="One Time Password (OTP)"
                        value={otp}
                        error={errors.otp}
                        maxLength={6}
                        onChange={(val) => { setOTP(val.replace(/[^\d]/g, '')) }}
                    />
                </div>
            </div>
            <div className='role-addition-footer'>
                <p className='addition-error'>{submitError}</p>
                <CustomIconButton
                    title="Add Super User"
                    disabled={!otpSent}
                    onClick={otpSent ? AddSubUser : null}
                    icon={otpSent ? <ArrowRight2 size={20} color={ICON_COLORS['SWIFT_COLOR1']}  /> : <ArrowRight2 size={20} color={ICON_COLORS['SWIFT_COLOR5']} />}
                />
            </div>
        </div>
    )
}

export default RoleAddition;