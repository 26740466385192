import React, { useState } from "react";
import TradesTableBody from "./TradesTableBody";
import { FilterSearch } from "../../CustomComponents/SwiftIcon/Icons";

function TradesTable({ trades }) {
  const [filter, setFilter] = useState("");
  const [status, setStatus] = useState("pending");
  const [statusArray, setStatusArray] = useState(["Pending"]);

  const pending_trades = trades.filter((t) => {
    return t.Status === "Pending";
  }).length;
  const complete_trades = trades.filter((t) => {
    return t.Status === "Complete";
  }).length;
  const total_trades = pending_trades + complete_trades;

  return (
    <div className="client-trades-table">
      <div className="trades-table-menu">
        <div className="trades-status">
          <p
            className={"status-menu " + (status === "pending" ? "active" : "")}
            onClick={() => {
              setStatus("pending");
              setStatusArray(["Pending"]);
              setFilter("");
            }}
          >
            Pending Trades ({pending_trades})
          </p>
          <p
            className={"status-menu " + (status === "complete" ? "active" : "")}
            onClick={() => {
              setStatus("complete");
              setStatusArray(["Complete"]);
              setFilter("");
            }}
          >
            Completed Trades ({complete_trades})
          </p>
          <p
            className={"status-menu " + (status === "all" ? "active" : "")}
            onClick={() => {
              setStatus("all");
              setStatusArray(["Pending", "Complete"]);
              setFilter("");
            }}
          >
            All Trades ({total_trades})
          </p>
        </div>
        <div className="trades-search">
          <input
            placeholder="Search symbol"
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value.toUpperCase());
            }}
          />
          <div className="filter-icon">
            <FilterSearch size={20} color="#192b40" />
          </div>
        </div>
      </div>
      <div className="trades-table-wrapper">
        <TradesTableBody filter_text={filter} trades={trades} status={statusArray} />
      </div>
    </div>
  );
}

export default TradesTable;
