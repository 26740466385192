import React, { useEffect } from "react";
import successSound from "../../../../assets/sounds/success.mp3";
import Pulse from "../../../Loader/Pulse";
import CircularProgress from "@material-ui/core/CircularProgress";
import Spinner from "../../../Loader/Spinner";
import { COLOR_VARS } from "../../../../exports/ColorVars";

const CustomIconActionButton = ({
  disabled,
  onClick,
  icon,
  title,
  loading_title = "",
  success_title,
  failure_title,
  action = "default",
}) => {
  let content;

  useEffect(() => {
    if (action === "success") {
      const delay = 200;
      setTimeout(() => {
        const audio = new Audio(successSound);
        audio.play();
      }, delay);
    }
  }, [action]);

  switch (action) {
    case "success":
      content = (
        <>
          <div className="button-icon">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 18.3334C14.5834 18.3334 18.3334 14.5834 18.3334 10.0001C18.3334 5.41675 14.5834 1.66675 10 1.66675C5.41669 1.66675 1.66669 5.41675 1.66669 10.0001C1.66669 14.5834 5.41669 18.3334 10 18.3334Z"
                stroke={COLOR_VARS["SWIFT_COLOR3"]}
                fill={COLOR_VARS["SWIFT_COLOR3"]}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="circle"
              />
              <path
                d="M6.45831 9.99993L8.81665 12.3583L13.5416 7.6416"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="tick"
              />
            </svg>
          </div>
          <p>{success_title}</p>
        </>
      );
      break;
    case "failure":
      content = (
        <>
          <div className="button-icon">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.99999 18.3334C14.5833 18.3334 18.3333 14.5834 18.3333 10.0001C18.3333 5.41675 14.5833 1.66675 9.99999 1.66675C5.41666 1.66675 1.66666 5.41675 1.66666 10.0001C1.66666 14.5834 5.41666 18.3334 9.99999 18.3334Z"
                stroke={COLOR_VARS["SWIFT_COLOR2"]}
                fill={COLOR_VARS["SWIFT_COLOR2"]}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="circle"
              />
              <path
                d="M7.64166 12.3583L12.3583 7.6416"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="cross"
              />
              <path
                d="M12.3583 12.3583L7.64166 7.6416"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="cross"
              />
            </svg>
          </div>
          <p>{failure_title}</p>
        </>
      );
      break;
    case "loading":
      content = (
        <>
          <div className="button-icon">
            <CircularProgress
              
              style={{ color: COLOR_VARS["SWIFT_COLOR5"] }}
              size={18}
            />
          </div>
          <p>{loading_title}</p>
        </>
      );
      break;
    default:
      content = (
        <>
          <div className="button-icon">{icon}</div>
          <p>{title}</p>
        </>
      );
      break;
  }

  return (
    <>
      <div
        className={
          "swift-button-icon " + action + (disabled ? " disabled" : "")
        }
        onClick={!disabled ? onClick : () => {}}
      >
        {content}
      </div>
    </>
  );
};

export default CustomIconActionButton;
