import React from 'react';
import { Cross } from '../../../CustomComponents/SwiftIcon/Icons';
import { numberFormat } from '../../../../exports/UtilFunctions';

function ApplySmallcaseRow({ data, index, account_code, removeStock, show }) {

    function Name({ name, fullName }) {
        return (
            <>
                <div className="portfolio__stock__code">
                    <div className="stock__code">{name}</div>
                </div>
                <div className="portfolio__stock__name">{fullName}</div>
            </>)
    }

    if (!show) {
        return <></>
    }

    return (
        <tr>
            <td><Name name={data.stockCode} fullName={data.StockName} /></td>
            <td>{numberFormat(data.totalQuantity,0)}</td>
            <td>{numberFormat(data.portfolioWeight)}</td>
            <td>{numberFormat(data.targetWeight)}</td>
            <td>{numberFormat(data.proposedWeight)}</td>
            <td>{numberFormat(data.activeWeight)}</td>
            <td>{numberFormat(data.targetStock,0)}</td>
            <td className={data.trade >= 0 ? 'positive' : 'negative'}>{numberFormat(data.trade,0)}</td>
            <td>{numberFormat(data.currentPrice)}</td>
            <td className={data.trade >= 0 ? 'positive' : 'negative'}>{numberFormat(parseFloat(data.tradeValue))}</td>
            <td>{numberFormat(data.brokerage)}</td>
            <td>
                {data.stockCode !== 'CASH' && <>
                    <div className='remove__stock' onClick={() => { removeStock(account_code, data.stockCode) }}>
                        <Cross size={22} color="#192b40" />
                    </div>
                </>}
            </td>

        </tr>
    )
}

export default ApplySmallcaseRow;