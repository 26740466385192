import React from "react";
import useMarketStock from "../../../../hooks/useMarketStock";
import {
  ArrowDown2,
  ArrowUp2,
  ICON_COLORS,
  ReOrder,
} from "../../../CustomComponents/SwiftIcon/Icons";
import { numberFormat } from "../../../../exports/UtilFunctions";
import { getDeviceSize } from "../../../../exports/InteractiveUtils";

const DEVICE_SIZE = getDeviceSize();

function IndexItem({
  data,
  list_index,
  handleDragStart,
  handleDragOver,
  handleDrop,
  handleMove,
  count,
  sticky,
}) {
  const { name, index } = data;
  const stock_data = useMarketStock(index, true);

  let last_traded_price = "0";
  let change_price = "0";
  let change_percentage = "0.00%";

  if (stock_data) {
    last_traded_price = stock_data.last_traded_price;
    change_price = stock_data.change_price;
    change_percentage = stock_data.change_percentage;
  }

  const priceClass = change_price.includes("-") ? "negative" : "positive";

  return (
    <div
      className={`index_row ${sticky ? "sticky" : ""}`}
      style={
        sticky
          ? {
              top: `${list_index * (DEVICE_SIZE === "S" ? 70 : 60)}px`,
            }
          : {}
      }
      draggable
      onDragStart={(e) => {
        handleDragStart(e, list_index);
      }}
      onDragOver={(e) => handleDragOver(e, list_index)}
      onDrop={(e) => handleDrop(e, list_index)}
    >
      <div className="index_row_left">
        {DEVICE_SIZE === "S" ? (
          <>
            <div className="reorder mobile">
              <div
                onClick={
                  list_index === 0
                    ? {}
                    : () => {
                        handleMove("U", list_index);
                      }
                }
              >
                <ArrowUp2 size={18} color={list_index === 0 ? ICON_COLORS["SWIFT_COLOR5"] : ICON_COLORS["SWIFT_COLOR4"]} />
              </div>
              <div
                onClick={
                  list_index === count-1
                    ? {}
                    : () => {
                        handleMove("D", list_index);
                      }
                }
              >
                <ArrowDown2 size={18} color={list_index === count-1 ? ICON_COLORS["SWIFT_COLOR5"] : ICON_COLORS["SWIFT_COLOR4"]} />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="reorder">
              <ReOrder size={14} color={ICON_COLORS["SWIFT_COLOR4"]} />
            </div>
          </>
        )}
        <p className="name">{name}</p>
      </div>
      <div className="index_row_right">
        <div className="price">{numberFormat(last_traded_price)}</div>
        <div className={priceClass + " change"}>
          {numberFormat(change_price)}&nbsp;&nbsp;({change_percentage})
        </div>
      </div>
    </div>
  );
}

export default IndexItem;
