import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../../css/Popup/StockOrderPopup.css";
import MarketDepth from "../../Body/AppPopups/StockOrderPopup/MarketDepth";
import { Cross } from "../../CustomComponents/SwiftIcon/Icons";
import CustomButton from "../../Body/CustomChartComponents/CustomButton/CustomButton";
import CustomSelect from "../../Body/CustomChartComponents/CustomSelect/CustomSelect";
import { COLOR_VARS } from "../../../exports/ColorVars";
import CustomInput from "../../Body/CustomChartComponents/CustomInput/CustomInput";
import {
  SWIFTALGO_TIMEWINDOW_OPTIONS,
  SWIFTALGO_DATAPOINTS,
} from "../../../exports/OrderAlgoConfig";
import Pulse from "../../Loader/Pulse";
import { numberFormat, titleCase } from "../../../exports/UtilFunctions";
import PriceBox from "../../Body/CustomChartComponents/PriceBox/PriceBox";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const order_types = ["MARKET", "LIMIT", "SWIFTALGO"];

function OrderTypePopup({
  order,
  current_price,
  ModifyOrder,
  setTypeOpen,
  closePopup,
}) {
  const {
    ordertype,
    code,
    exchange,
    quantity,
    side,
    name,
    broker = "",
    limitprice,
    algo_config = false,
  } = order;

  const current_limit_price = limitprice != 0 ? limitprice : current_price;

  const local_algo_config = algo_config
    ? algo_config
    : {
        time_window: SWIFTALGO_TIMEWINDOW_OPTIONS[0],
        data_points: SWIFTALGO_DATAPOINTS[0],
      };

  const [type, setType] = useState(ordertype);
  const [algoConfig, setAlgoConfig] = useState(local_algo_config);
  const [limitPrice, setLimitPrice] = useState(current_limit_price);
  const [tickInfo, setTickInfo] = useState(false);
  const [error, setError] = useState(false);

  const { tick_size = false } = tickInfo;

  useEffect(() => {
    GetTickInfo();
  }, []);

  useEffect(() => {
    setError(false);
    setLimitPrice(current_limit_price);
  }, [type]);

  function ChangeOrderType() {
    const error = GetError();

    if (error) {
      setError(error);
      return;
    }

    setError(false);

    let limit_price = 0;
    if (type === "LIMIT") {
      limit_price = limitPrice;
    } else {
      limit_price = limitPrice;
    }

    ModifyOrder({
      type,
      limit_price: limit_price,
      algo_config: algoConfig,
    });
    setTypeOpen(false);
  }

  function GetTickInfo() {
    if (!code || !exchange) return;

    axios
      .get(`${REQUEST_BASE_URL}/GetScripInfo/${exchange}/${code}`)
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          const { tick_size, higher_circuit_limit, lower_circuit_limit } =
            data.stock;
          setTickInfo({
            tick_size: tick_size,
            higher_limit: higher_circuit_limit,
            lower_limit: lower_circuit_limit,
          });
        } else {
          setTickInfo(false);
        }
      })
      .catch((e) => {
        setTickInfo(false);
      });
  }

  function GetError() {
    const {
      tick_size = false,
      higher_limit = false,
      lower_limit = false,
    } = tickInfo;

    if (isNaN(limitPrice)) {
      return "Please enter valid limit price";
    }

    if (!IsMultiple(limitPrice, tick_size)) {
      return "Limit Price must be multiple of tick size";
    }

    if (limitPrice < lower_limit || limitPrice > higher_limit) {
      return `Price must be within the valid range (${numberFormat(
        lower_limit
      )} - ${numberFormat(higher_limit)})`;
    }

    return false;
  }

  function IsMultiple(num1, num2) {
    num1 = parseFloat(parseFloat(num1).toFixed(2) * 100).toFixed(2);
    num2 = parseFloat(parseFloat(num2).toFixed(2) * 100).toFixed(2);

    if (num1 % num2 == 0) {
      return true;
    } else {
      return false;
    }
  }

  const { higher_limit = false, lower_limit = false } = tickInfo;

  return (
    <div className="order-type-popup">
      <div className="order-type-header">
        <div className="order-stock-info">
          <div
            className={
              "order-buysell " + (side.toUpperCase() === "BUY" ? "buy" : "sell")
            }
          >
            <span>{side.toUpperCase()}</span>
          </div>
          <p>
            {name}
            {exchange && (
              <>
                &nbsp;:&nbsp;<span>{exchange}</span>
              </>
            )}
          </p>
        </div>
        <div
          className="order-stock-close"
          style={{ cursor: "pointer" }}
          onClick={closePopup}
        >
          <Cross size="26" color={COLOR_VARS.SWIFT_COLOR4} />
        </div>
      </div>

      {tickInfo ? (
        <>
          <div className="order-type-body">
            <div className="order-type-section">
              <div className="order-customer-info">
                <div className="customer-info-box">
                  <span>Broker</span>
                  <p>{broker}</p>
                </div>
                <div className="customer-info-box">
                  <span>Quantity</span>
                  <p>{Math.abs(quantity)}</p>
                </div>
                <div className="customer-info-box">
                  <span>Current Price</span>
                  <p>{numberFormat(current_price)}</p>
                </div>
              </div>
              <div className="order-type-select-wrapper">
                <p>Order Type</p>
                <div className="order-type-select">
                  {order_types.map((o) => {
                    return (
                      <span
                        className={type === o ? "active" : ""}
                        key={o}
                        onClick={() => {
                          setType(o);
                        }}
                      >
                        {titleCase(o)}
                      </span>
                    );
                  })}
                </div>
              </div>
              {type === "LIMIT" && (
                <>
                  <div className="order-type-info-wrapper">
                    <p>
                      Price Range:{" "}
                      <span>
                        {numberFormat(lower_limit)} -{" "}
                        {numberFormat(higher_limit)}
                      </span>
                    </p>
                    <p>
                      Tick Size: <span>{numberFormat(tick_size)}</span>
                    </p>
                  </div>
                </>
              )}
              <div className="order-type-input-wrapper">
                {type === "MARKET" && (
                  <>
                    <div className="order-type-input">
                      <CustomInput
                        heading="Price"
                        value={current_price}
                        disabled
                      />
                    </div>
                  </>
                )}
                {type === "LIMIT" && (
                  <>
                    {/* <div className="order-type-input">
                    <CustomInput
                      heading={
                        tick_size
                          ? `Limit Price (Tick Size: ${tick_size})`
                          : `Limit Price`
                      }
                      value={limitPrice}
                      onChange={(value) => {
                        setLimitPrice(value);
                      }}
                    />
                  </div> */}
                    <div className="order-type-input">
                      <PriceBox
                        enabled={true}
                        valid={!error}
                        label="Limit Price"
                        step={tick_size}
                        value={limitPrice}
                        changehandler={(value) => {
                          setLimitPrice(value);
                        }}
                      />
                    </div>
                  </>
                )}
                {type === "SWIFTALGO" && (
                  <>
                    <div className="order-type-input">
                      <CustomSelect
                        heading="Time Window"
                        value={algoConfig["time_window"]}
                        defaultIndex={SWIFTALGO_TIMEWINDOW_OPTIONS.findIndex(
                          (f) => {
                            return f === algoConfig["time_window"];
                          }
                        )}
                        options={SWIFTALGO_TIMEWINDOW_OPTIONS}
                        onTypeChange={(val) => {
                          setAlgoConfig({
                            ...algoConfig,
                            time_window: val,
                          });
                        }}
                      />
                    </div>
                    <div className="order-type-input">
                      <CustomSelect
                        heading="Data Points"
                        value={algoConfig["data_points"]}
                        defaultIndex={SWIFTALGO_DATAPOINTS.findIndex((f) => {
                          return f === algoConfig["data_points"];
                        })}
                        options={SWIFTALGO_DATAPOINTS}
                        onTypeChange={(val) => {
                          setAlgoConfig({
                            ...algoConfig,
                            data_points: val,
                          });
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
              {/* {type === "SWIFTALGO" && (
                <>
                  <p className="note">
                    Each time limit price is modified, you may receive an sms
                  </p>
                </>
              )} */}
              <p
                className="error-text"
                style={{
                  opacity: error ? "1" : "0",
                }}
              >
                {error}
              </p>
            </div>
            <div className="order-section-separator">
              <div></div>
            </div>
            <div className="order-depth-section">
              <MarketDepth symbol={name} />
            </div>
          </div>
          <div className="order-type-footer">
          <div className="submit-button">
            <CustomButton
              title="Modify Order"
              onClick={() => {
                ChangeOrderType();
              }}
            />
          </div>
          </div>
        </>
      ) : (
        <>
          <div className="order-type-loader">
            <Pulse />
          </div>
        </>
      )}
    </div>
  );
}

export default OrderTypePopup;
