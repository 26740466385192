import React from "react";
import { Cross, ArrowLeft } from "../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../exports/ColorVars";

function FundsProcessPopup({ closePopup }) {
  return (
    <div className="funds-process">
      <div className="funds-process-header">
        <p>Funds Payin/Payout Process</p>
        <div className="funds-process-close" onClick={closePopup}>
          <Cross size={24} color={COLOR_VARS["SWIFT_COLOR4"]} />
        </div>
      </div>
      <div className="funds-process-header-mobile">
        <div className="funds-process-close" onClick={closePopup}>
          <ArrowLeft size={24} color={COLOR_VARS["SWIFT_COLOR4"]} />
        </div>
      </div>
      <div className="funds-process-body">
        <div className="funds-process-section">
          <p className="funds-process-title">Funds Payin</p>
          <div className="funds-process-info">
            <p>
              To initiate your transfer, kindly submit payment using the
              provided bank credentials. Subsequently, provide the exact
              transfer amount and the corresponding transaction reference number
              in the payin form. Your ledger balance will reflect the deposited
              funds within 2-3 business hours.
            </p>
            <div>
              <p>
                Account name: <span>Choice Equity Broking Private Limited</span>
              </p>
              <p>
                Bank Name: <span>HDFC Bank</span>
              </p>
              <p>
                Account Number: <span>15770340003528</span>
              </p>
              <p>
                IFSC Code: <span>HDFC0000060</span>
              </p>
              <p>
                MICR Code: <span>400240015</span>
              </p>
              <p>
                Branch Name: <span>Fort, Mumbai</span>
              </p>
            </div>
          </div>
        </div>
        <div className="funds-process-section">
          <p className="funds-process-title">Funds Payout</p>
          <div className="funds-process-info">
            <p>
              To initiate a funds withdrawal, please complete the withdrawal
              form by specifying the desired amount. Your funds will be
              reflected in your account within 2-3 business hours.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FundsProcessPopup;
