// const CHART_COLORS = [
//   "#FEAD26",
//   "#7E7FD7",
//   "#8ED8B7",
//   "#69B8F4",
//   "#FDE35A",
//   "#C899F4",
//   "#B6BEC7",
//   "#BECFF6",
//   "#D88EAD",
//   "#DCEDC2"
// ];

const CHART_COLORS = [
  "#519DE9",
  "#7CC674",
  "#73C5C5",
  "#8481DD",
  "#F6D173",
  "#EF9234",
  "#0066CC",
  "#4CB140",
  "#009596",
  "#5752D1",
  "#F4C145",
  "#EC7A08",
];

module.exports = { CHART_COLORS };
