import React, { useEffect, useState } from "react";
import RouteItem from "./RouteItem";
import CustomValueSelect from "../../Body/CustomChartComponents/CustomValueSelect/CustomValueSelect";
import {
  ArrowRight2,
  ArrowDown2,
  FilterSearch,
  Cross,
} from "../../CustomComponents/SwiftIcon/Icons";
import CustomCheckBox from "../../Body/CustomChartComponents/CustomCheckBox/CustomCheckBox";
import SwiftToolTip from "../../CustomComponents/SwiftToolTip/SwiftToolTip";
import { numberFormat, titleCase } from "../../../exports/UtilFunctions";

import { COLOR_VARS } from "../../../exports/ColorVars";

function RouteSection({
  user_role_type,
  OrderID,
  order,
  SetOrderType,
  SetLimitPrice,
  SetSelectBox,
  ExecuteSingleOrder,
  RemoveBulkOrder,
  RemoveSingleOrder,
}) {
  // console.log('RouteSection')
  const [filter, setFilter] = useState("");
  const [trade, setTrade] = useState([]);
  const [totalSellValue, setTotalSellValue] = useState(0);
  const [totalBuyValue, setTotalBuyValue] = useState(0);
  const [totalTradeValue, setTotalTradeValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [tradeBroker, setTradeBroker] = useState("");

  useEffect(() => {
    if (order && order["orders"]) {
      if (
        order &&
        order["config"]["brokers"] &&
        order["config"]["brokers"].length > 0
      ) {
        setTradeBroker(order["config"]["brokers"][0]["broker"]);
      }
    }
  }, [order]);

  useEffect(() => {
    const routes = order.routes;

    const brokers_list = routes.map((o) => {
      return o.broker;
    });
    const broker_set = new Set(brokers_list);
    if (broker_set.size === 1) {
      setTradeBroker(brokers_list[0]);
    } else {
      setTradeBroker("MIXED");
    }
  }, [order.routes]);

  function setTradeIndex(index, value, side) {
    const arr = trade;
    arr[index] = side.toUpperCase() === "SELL" ? -1 * value : value;
    setTrade(arr);

    if (arr.length === 0) return;

    const sell_sum = arr.filter((a) => a < 0).reduce((a, b) => a + b, 0);
    const buy_sum = arr.filter((a) => a >= 0).reduce((a, b) => a + b, 0);
    setTotalSellValue(-1 * sell_sum);
    setTotalBuyValue(buy_sum);
    setTotalTradeValue(Math.abs(sell_sum) + buy_sum);
  }

  const brokers = order.config.brokers.map((b) => {
    return b.broker;
  });

  return (
    <>
      <div className="order-trades-section">
        <div className="order-trades-header">
          <div className="order-trades-config">
            <div
              className="config-box expand-collapse-box arrow"
              onClick={() => {
                setOpen(!open);
                setFilter("");
              }}
            >
              {open ? (
                <ArrowDown2 size={24} color={COLOR_VARS['SWIFT_COLOR4']} />
              ) : (
                <ArrowRight2 size={24} color={COLOR_VARS['SWIFT_COLOR4']} />
              )}
            </div>
            {user_role_type === "BROKER" ? (
              <>
                <div className="config-box select">
                  <CustomCheckBox
                    width={18}
                    height={18}
                    isChecked={order.selected}
                    onChangeValue={(value) => {
                      SetSelectBox(order.config.order_id, value);
                    }}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="config-box name">
              <span title={titleCase(order.config.name)}>
                {titleCase(order.config.name)}
              </span>
            </div>
            <div className="config-box account">
              <span>
                {order.config.user_role === "MAC"
                  ? "Managed Account"
                  : "Broking Account"}
              </span>
            </div>

            <div className="config-box count">
              <span>{order.config.count}</span>
            </div>
            <div className="config-box sell negative">
              <span>{numberFormat(totalSellValue)}</span>
            </div>
            <div className="config-box buy positive">
              <span>{numberFormat(totalBuyValue)}</span>
            </div>
            <div className="config-box trade">
              <span>{numberFormat(totalTradeValue)}</span>
            </div>
            {user_role_type === "BROKER" ? (
              <>
                <div className="config-box execute">
                  <button
                    onClick={() => {
                      ExecuteSingleOrder(OrderID);
                    }}
                  >
                    {order.config.request_time
                      ? "Resend Approval Email"
                      : "Send Approval Email"}
                  </button>
                </div>
                <div className="config-box remove">
                  <SwiftToolTip title="Remove All Trades" placement="top">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        RemoveBulkOrder(
                          order.config.account_code,
                          order.config.order_id
                        );
                      }}
                    >
                      <Cross size={24} color={COLOR_VARS["SWIFT_COLOR4"]} />
                    </div>
                  </SwiftToolTip>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div
          className={"order-trades-container-wrapper " + (open ? "" : "hide")}
        >
          <div className="order-trades-options">
            <p>Generated Trades</p>
            <div className="order-trades-search">
              <input
                placeholder="Search symbol"
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value.toUpperCase());
                }}
              />
              <div className="filter-icon">
                <FilterSearch size={20} color={COLOR_VARS['SWIFT_COLOR4']} />
              </div>
            </div>
          </div>

          <div className="order-trades-container orders">
            <table>
              <thead>
                <tr>
                  {user_role_type === "BROKER" ? (
                    <>
                      <td>Stock Name</td>
                      <td>Order Type</td>
                      <td>Limit Price</td>
                      <td>Order Side</td>
                      <td>Quantity</td>
                      <td>Current Price</td>
                      <td>Amount</td>
                      {/* <td>Broker</td> */}
                      <td>Status</td>
                      <td className="order-item-remove"></td>
                    </>
                  ) : (
                    <>
                      <td>Stock Name</td>
                      <td>Order Type</td>
                      <td>Limit Price</td>
                      <td>Order Side</td>
                      <td>Quantity</td>
                      <td>Current Price</td>
                      <td>Amount</td>
                      <td>Status</td>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {order["routes"].map((o, index) => {
                  return (
                    <RouteItem
                      key={index}
                      user_role_type={user_role_type}
                      modify_order={
                        order.config.user_role === "MAC" ? true : true
                      }
                      setTradeIndex={setTradeIndex}
                      SetOrderType={SetOrderType}
                      SetLimitPrice={SetLimitPrice}
                      RemoveSingleOrder={RemoveSingleOrder}
                      index={index}
                      show={o.stockCode.includes(filter)}
                      order={o}
                      account_code={order.config.account_code}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default RouteSection;
