import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";
import { SetCookie } from "../../exports/CookieManagement";
import { ArrowRight2 } from "../CustomComponents/SwiftIcon/Icons";
import Pulse from "../Loader/Pulse";
import AccessHeader from "./AccessHeader";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const warning_config = {
  Band: true,
  BandColor: "#470985",
  BoxColor: "#ffffff",
  TextColor: "#000000",
  AutoClose: {
    Active: true,
    Line: true,
    LineColor: "#470985",
    Time: 3,
  },
};

function AccessLogin() {
  const [step, setStep] = useState(1);
  const [customerID, setCustomerID] = useState("");
  const [customerPassword, setCustomerPassword] = useState("");
  const [otp, setOTP] = useState("");
  const [timer, setTimer] = useState(0);
  const [customerIDError, setCustomerIDError] = useState("");
  const [customerPasswordError, setCustomerPasswordError] = useState("");
  const [otpError, setOTPError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [isAPI, setIsAPI] = useState(true);
  const [login, setLogin] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const counter = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
    return () => clearInterval(counter);
  }, [timer]);

  async function resendOTP() {
    if (timer > 0) {
      return;
    }

    setOTPError("");
    setOTP("");

    await sendOTP();
    setTimer(30);
  }

  async function sendOTP() {
    return new Promise((resolve, reject) => {
      Axios.post(`${REQUEST_BASE_URL}/access/login`, {
        customer_id: customerID,
        customer_password: customerPassword,
      })
        .then((response) => {
          const data = response.data;
          resolve(data);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }

  async function validateAccount() {
    if (customerID === "") {
      setCustomerIDError("Please enter customer ID");
      setCustomerPasswordError("");
    } else if (customerPassword === "") {
      setCustomerIDError("");
      setCustomerPasswordError("Please enter password");
    } else {
      setCustomerIDError("");
      setCustomerPasswordError("");

      setLoading(true);
      setLoadingMessage("Sending one time password...");

      const data = await sendOTP();

      if (!data) {
        Alert({
          TitleText: "Warning",
          Message: "Something went wrong!",
          ...warning_config,
        });
        setCustomerPasswordError("");
        setLoading(false);
        setLoadingMessage("");
        return;
      }

      if (!data.error) {
        setTimeout(() => {
          setCustomerIDError("");
          setCustomerPasswordError("");
          setLoading(false);
          setLoadingMessage("");
          setIsAPI(data.type === "API");
          setStep(2);
        }, 2000);
      } else if (data.first_login) {
        setLoading(false);
        setLoadingMessage("");
        setStep(3);
      } else {
        Alert({
          TitleText: "Warning",
          Message: data.message,
          ...warning_config,
        });
        setCustomerIDError("");
        setCustomerPasswordError("");
        setLoading(false);
        setLoadingMessage("");
      }
    }
  }

  function resetPassword() {
    navigate("/access/reset");
  }

  function validateOTP() {
    if (otp === "") {
      setOTPError("Please enter the correct otp");
    } else {
      setOTPError("");
      setLoading(true);
      setLoadingMessage("Validating your OTP...");

      Axios.post(
        `${REQUEST_BASE_URL}/access/validatecotp`,
        {
          customer_id: customerID,
          otp: otp,
        },
        { withCredentials: true }
      )
        .then((response) => {
          const data = response.data;
          if (!data.error) {
            const token = data.token;
            setTimeout(() => {
              setOTPError("");
              SetCookie("token", token);
              setLogin(true);
              setLoading(false);
              setLoadingMessage("");
            }, 2000);
          } else {
            setOTPError("Entered otp is invalid");
            setLoading(false);
            setLoadingMessage("");
          }
        })
        .catch((error) => {
          setOTPError("Something went wrong!");
          setLoading(false);
          setLoadingMessage("");
        });
    }
  }

  function mobileKeyUp(e) {
    if (e.key === "Enter") {
      validateAccount();
    }
  }

  function otpKeyUp(e) {
    if (e.key === "Enter") {
      validateOTP();
    }
  }

  if (login) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="user-login">
      <div className="user-login-popup-wrapper">
        {loading ? (
          <>
            <div className="popup-loader">
              <Pulse />
              <p>{loadingMessage}</p>
            </div>
          </>
        ) : (
          <>
            <div className="login-header">
              <AccessHeader header_text="Customer Login" />
            </div>
            <>
              {step == 1 && (
                <>
                  <div className="login-content">
                    <div className="login-body">
                      <div className="swift-input-box">
                        <p>Customer ID</p>
                        <input
                          className="swift-input"
                          value={customerID}
                          onChange={(e) => {
                            setCustomerID(e.target.value.toUpperCase());
                          }}
                          placeholder=""
                        />
                        <p
                          className={
                            customerIDError === ""
                              ? "error-text hide"
                              : "error-text"
                          }
                        >
                          {customerIDError}
                        </p>
                      </div>
                      <div className="swift-input-box">
                        <p>Customer Password</p>
                        <input
                          className="swift-input"
                          type="password"
                          value={customerPassword}
                          onChange={(e) => {
                            setCustomerPassword(e.target.value);
                          }}
                          onKeyUp={mobileKeyUp}
                          placeholder=""
                        />
                        <p
                          className={
                            customerPasswordError === ""
                              ? "error-text hide"
                              : "error-text"
                          }
                        >
                          {customerPasswordError}
                        </p>
                      </div>
                      <p>{}</p>
                    </div>
                    <>
                      <div className="login-footer">
                        <div
                          className="swift-button-icon"
                          onClick={validateAccount}
                        >
                          <div className="button-icon">
                            <ArrowRight2 size={20} />
                          </div>
                          <p>Login</p>
                        </div>
                      </div>
                    </>
                  </div>
                  <div className="footer-line">
                    <p>
                      <Link to="/access/reset">
                        Forgot Customer ID or Password?
                      </Link>
                    </p>
                    <p>
                      Don't have an account?{" "}
                      <Link to="/access/signup">Signup</Link>
                    </p>
                  </div>
                </>
              )}
              {step == 2 && (
                <>
                  <div className="login-content">
                    <div className="login-body">
                      {isAPI && (
                        <>
                          <div className="login-notes">
                            <p className="login-note">
                              We have identified your account to be using Choice
                              Broking servers. OTP thus generated will therefore
                              come from Choice Broking.
                            </p>
                            <p className="login-note">
                              To keep the API authentication alive, we may
                              automatically reactivate your access to Choice
                              Servers for which you may receive an OTP on email
                              or mobile. You may ignore this email or sms.
                            </p>
                          </div>
                        </>
                      )}
                      <div className="swift-input-box">
                        <p>Verification OTP</p>

                        <input
                          className="swift-input"
                          value={otp}
                          placeholder="123456"
                          onKeyUp={otpKeyUp}
                          maxLength={6}
                          onChange={(e) => {
                            setOTP(e.target.value.replace(/[^\d]/g, ""));
                          }}
                        />
                        <p
                          className={
                            otpError === "" ? "error-text hide" : "error-text"
                          }
                        >
                          {otpError}
                        </p>
                      </div>
                      {timer === 0 ? (
                        <>
                          <p className="resend-otp-button" onClick={resendOTP}>
                            Resend OTP
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="resend-message">
                            Please wait {timer} seconds to resend
                          </p>
                        </>
                      )}
                    </div>
                    <div className="login-footer">
                      <div className="swift-button-icon" onClick={validateOTP}>
                        <div className="button-icon">
                          <ArrowRight2 size={20} />
                        </div>
                        <p>Submit</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {step == 3 && (
                <>
                  <div className="login-content">
                    <div className="login-body">
                      <div className="login-notes">
                        <p className="login-note">
                          Before continuing, we need you to reset your password
                          in order to maintain the highest level of protection
                          for your account. This initial password reset is a
                          standard security measure for all new users on our
                          platform.
                        </p>
                      </div>
                    </div>
                    <div className="login-footer">
                      <div
                        className="swift-button-icon"
                        onClick={resetPassword}
                      >
                        <div className="button-icon">
                          <ArrowRight2 size={20} />
                        </div>
                        <p>Continue</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          </>
        )}
      </div>
    </div>
  );
}

export default AccessLogin;
