import {
  ResponsiveContainer, Legend, Tooltip,
  LineChart, Line, ReferenceLine, XAxis, YAxis, AreaChart, Area
} from "recharts";



export const BaseLine = ({ height = 250, width = 360, centerLine = 1, data = data, benchmark = benchmark }) => {

  return <ResponsiveContainer width="100%" height="100%">
    <LineChart
      data={data}
      margin={{
        top: 10,
        left: -30
            }}
    >
      <YAxis axisLine={false} tickLine={false} fontSize={10} fontWeight={500} stroke="#404040" strokeWidth={1} allowDecimals={true} />
      <XAxis dataKey="date" fontSize={10} fontWeight="400" stroke="#e5e5e5" tickLine={false} strokeWidth={1} tick={{ fill: '#404040' }} />
      <Legend verticalAlign="top" iconType="circle" iconSize={8} />
      <Tooltip content={<CustomTooltip benchmark={benchmark} />} />
      <ReferenceLine y={centerLine} stroke="#cccccc" strokeDasharray="3 3" />
      <Line type="monotone" dataKey="Portfolio" stroke="#B937E7" dot={false} strokeWidth={1.5} />
      <Line type="monotone" dataKey={benchmark} stroke="#192B40" dot={false} strokeWidth={1.5} />
    </LineChart>

  </ResponsiveContainer>
}


const CustomTooltip = ({ active, payload, benchmark }) => {

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ background: '#ffff', padding: '6px 16px 6px 16px', borderRadius: 3, boxShadow: '1px 3px 5px #e8e8e8' }}>
        <p className="label" style={{ color: '#192b40', fontSize: 12, fontWeight: 600 }}>{`Portfolio : ${parseFloat(payload[0].payload.Portfolio).toFixed(2)}`}</p>
        <p className="label" style={{ color: '#192b40', fontSize: 12, fontWeight: 600 }}>{`${benchmark} : ${parseFloat(payload[0].payload[benchmark]).toFixed(2)}`}</p>
        <p className="label" style={{ display: 'flex', justifyContent: 'center', fontSize: 10, color: '#192b40', marginTop: '3px' }}>{`${payload[0].payload.date}`}</p>
      </div>
    );
  }

  return null;
};

const CustomTooltipSingle = ({ active, payload }) => {

  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{ background: '#ffff', padding: '6px 16px 6px 16px', borderRadius: 3, boxShadow: '1px 3px 5px #e8e8e8' }}>
        <p className="label" style={{ color: '#192b40', fontSize: 12 }}>{`Portfolio : ${parseFloat(payload[0].payload.indexValue).toFixed(2)}`}</p>
        <p className="label" style={{ display: 'flex', justifyContent: 'center', fontSize: 12, color: '#404040', marginTop: '3px' }}>{`${payload[0].payload.date}`}</p>
      </div>
    );
  }

  return null;
};

const RenderLegend = ({payload}) => {

return (
  <ul style={{ display: 'flex', justifyContent: 'center'}}>
    {
      payload.map((entry, index) => (
        <li key={`item-${index}`}>{entry.value}</li>
      ))
    }
  </ul>
);
}

export const SingleLine = ({ centerLine = 1, data = data }) => {


  return <ResponsiveContainer width="100%" height="100%">
    <AreaChart
      data={data}
      margin={{
        left: -30
      }}
    >
      <defs>
        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#B937E7" stopOpacity={0.15} />
          <stop offset="95%" stopColor="#B937E7" stopOpacity={0} />
        </linearGradient>
      </defs>
      <YAxis axisLine={false} tickLine={false} fontWeight={400} fontSize={10} />
      <XAxis dataKey="date" fontSize={10} fontWeight={400} stroke="#e8e8e8" strokeWidth={0.5} tick={{ fill: '#192b40' }} />
      <Tooltip content={<CustomTooltipSingle />} />
      <ReferenceLine y={centerLine} stroke="#cccccc" strokeDasharray="10 10" />
      <Area type="monotone" dataKey="indexValue" stroke="#B937E7" strokeWidth={2} fillOpacity={2} fill="url(#colorPv)" />
    </AreaChart>

  </ResponsiveContainer>
}