import {
  numberFormat, priceFormat
} from "../../exports/UtilFunctions";

export function PortfolioInvestment({ portfolio_investment }) {
  return (
    <>
      <div className="portfolio__info__value">
        <span className="portfolio__info__head">Investment Value</span>
        <div>
          <span>{priceFormat(portfolio_investment)}</span>
        </div>
      </div>
    </>
  );
}

export function PortfolioProfitLoss({ type="BRC", portfolio_profitloss }) {
  return (
    <>
      <div className="portfolio__info__value">
        <span className="portfolio__info__head">{type === 'BRC'? "Unrealised Gains" : "Total Gains"}</span>
        <div>
          <span
            className={
              parseFloat(portfolio_profitloss) >= 0 ? "positive" : "negative"
            }
          >
            {priceFormat(portfolio_profitloss)}
          </span>
        </div>
      </div>
    </>
  );
}

export function PortfolioValue({ portfolio_value }) {
  return (
    <>
      <div className="portfolio__info__value">
        <span className="portfolio__info__head">Portfolio Value</span>
        <div>
          <span>{priceFormat(portfolio_value)}</span>
        </div>
      </div>
    </>
  );
}

export function PortfolioChange({ change_amount, change_percentage }) {
  const positive = change_amount >= 0 ? true : false;

  return (
    <>
      <div className="portfolio__info__value">
        <span className="portfolio__info__head">Portfolio Change Today</span>
        <div className={positive ? "positive" : "negative"}>
          <span className="portfolio__change__value">
            {priceFormat(change_amount)}
          </span>
          &nbsp;
          {change_percentage ? (
            <span
              className="portfolio__change__per"
              style={{ display: "flex" }}
            >
              ({numberFormat(change_percentage)}%)
            </span>
          ) : (
            <>
              <span className="portfolio__change__per">(0.00%)</span>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export function PortfolioCashPosition({ cash }) {
  return (
    <>
      <div className="portfolio__info__value">
        <span className="portfolio__info__head">Current Cash Position</span>
        <div>
          <span>{priceFormat(cash)}</span>
        </div>
      </div>
    </>
  );
}