function GetCookie(cookie_name) {
    return document.cookie.split('; ').filter(row => row.startsWith(`${cookie_name}=`)).map(c => c.split('=')[1])[0];
}

function SetCookie(cookie_name, value) {
    document.cookie = `${cookie_name}=${value}; path=/;`;
}

function DeleteCookie(cookie_name) {
    document.cookie = `${cookie_name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`;
}

module.exports = { GetCookie, SetCookie, DeleteCookie };