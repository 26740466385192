import React, { useState } from 'react';
import { replaceNull, titleCase } from '../../exports/UtilFunctions';
import { Link } from 'react-router-dom';
import { ArrowDown2, ArrowRight2 } from '../CustomComponents/SwiftIcon/Icons';
import { COLOR_VARS } from '../../exports/ColorVars';

function ClientRow({ show, customer }) {

    const { broker_config } = customer;
    const [open, setOpen] = useState(false);

    if (!show) {
        return <></>
    }

    return (
        <div className='customer-row'>
            <div className='customer-info'>
                <div className='cust-config arrow' onClick={() => { setOpen(!open); }}>
                    {open ? <ArrowDown2 size={22} color={COLOR_VARS['SWIFT_COLOR4']} /> : <ArrowRight2 size={22} color={COLOR_VARS['SWIFT_COLOR4']} />}
                </div>
                <div className='cust-config name'>{titleCase(customer.name)}</div>
                <div className='cust-config pan'>{customer.pan}</div>
                <div className='cust-config mobile'>{customer.mobile}</div>
                <div className='cust-config email'>{customer.email}</div>
                <div className='cust-config type'>{customer.user_role === 'BRC' ? 'Broking Customer' : 'Managed Customer'}</div>
                <div className='cust-config kyc'><Link target="_blank" to={`/customer/kyc?token=${customer.kyc_form_id}`}>Open KYC Form</Link></div>
                {/* <div className='cust-config broker'>{customer.user_role === 'MAC' ? <button>Add Broker</button> : <>-</>}</div> */}
            </div>
            <div className={'customer-broker-info ' + (open ? '' : 'hide')}>
                {broker_config.length === 0 ? <>
                    <div className='broker-info-empty'>
                        <p>No brokers found</p>
                    </div>
                </> :
                    <>
                        <div className='customer-broker-table'>
                            <table>
                                <thead>
                                    <tr>
                                        <td>Broker Name</td>
                                        <td>CP Code</td>
                                        <td>Trading Code</td>
                                        <td>Brokerage</td>
                                        <td>STT</td>
                                        <td>Stamp Duty</td>
                                        <td>Performance Fees</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {broker_config.map((b, index) => {
                                        return <tr key={index}>
                                            <td>{b.broker}</td>
                                            <td>{replaceNull(b.cpcode,'-')}</td>
                                            <td>{replaceNull(b.trading_code,'-')}</td>
                                            <td>{replaceNull(b.brokerage,'-')}</td>
                                            <td>{replaceNull(b.stt,'-')}</td>
                                            <td>{replaceNull(b.stamp_duty,'-')}</td>
                                            <td>{replaceNull(b.perf_fee,'-')}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default ClientRow;