import React, { useState, useEffect } from "react";
import Axios from "axios";
import { GetCookie } from "../../exports/CookieManagement";
import { OrderBook } from "../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../exports/ColorVars";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  PlayCircle,
  TickCircle,
  Edit,
} from "../CustomComponents/SwiftIcon/Icons";
import useAccount from "../../hooks/useAccount";
import useStockInfo from "../../hooks/useStockInfo";
import useMovingAverage from "../../hooks/useMovingAverage";
import useMarketStock from "../../hooks/useMarketStock";
import SwiftModal from "../CustomComponents/SwiftModal/SwiftModal";
import OrderTypePopup from "../TransactionManagement/OrdersComponents/OrderTypePopup";
import CustomIconButton from "../Body/CustomChartComponents/CustomIconButton/CustomIconButton";
import Pulse from "../Loader/Pulse";
import {
  numberFormat,
  priceFormat,
  titleCase,
} from "../../exports/UtilFunctions";
import { SWIFTALGO_TIMEWINDOW } from "../../exports/OrderAlgoConfig";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";
import { getDeviceSize } from "../../exports/InteractiveUtils";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function AlertMessage(message) {
  Alert({
    TitleText: "Warning",
    Message: message,
    Band: true,
    BandColor: "#E51A4B",
    BoxColor: "#ffffff",
    TextColor: "#000000",
    AutoClose: {
      Active: true,
      Line: true,
      LineColor: "#E51A4B",
      Time: 5,
    },
  });
}

function CustomerPosition() {
  const account_token = GetCookie("token");

  const [isPlacing, setIsPlacing] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [execute, setExecute] = useState(false);
  const [position, setPosition] = useState(undefined);
  const [config, setConfig] = useState(undefined);
  const [stockConfig, setStockConfig] = useState(undefined);
  const [broker, setBroker] = useState(false);

  async function GetPosition() {
    const response = await fetch(
      `${REQUEST_BASE_URL}/broking/position?token=${account_token}`
    );
    const data = await response.json();
    return data;
  }

  async function GetCashPosition(account_code) {
    const response = await fetch(
      `${REQUEST_BASE_URL}/clientinfo/cashposition?account_code=${account_code}`
    );
    const data = await response.json();
    const { cash = 0 } = data;
    setConfig((config) => {
      return {
        ...config,
        cash_position: cash,
      };
    });
  }

  const { account } = useAccount();

  useEffect(() => {
    setPosition(undefined);
    setStockConfig(undefined);
    setConfig(undefined);
    setExecute(false);
    GetPosition()
      .then((data) => {
        const { error, position } = data;
        if (!error) {
          setPosition(position);
        } else {
          setPosition([]);
        }
      })
      .catch(() => {
        setPosition([]);
      });
  }, [trigger]);

  useEffect(() => {
    if (!account) return;

    GetCashPosition(account.account_code);
    const { brokers = [] } = account;

    if (!brokers || !Array.isArray(brokers) || brokers.length === 0) return;
    setBroker(brokers[0]);
  }, [account]);

  useEffect(() => {
    if (!position || !Array.isArray(position)) return;

    if (position.length === 0) {
      setStockConfig({});
      return;
    }

    let stock_config_object = { ...stockConfig };

    position.forEach((p) => {
      if (!stock_config_object.hasOwnProperty(p["ISIN"])) {
        stock_config_object[p["ISIN"]] = {
          symbol: p["StockCode"],
          current_price: 0,
          order: 0,
          order_config: {
            type: "MARKET",
            algo_order_type: "MARKET",
            limit: 0,
            algo_config: false,
          },
        };
      }
    });

    setStockConfig({ ...stock_config_object });
  }, [position]);

  useEffect(() => {
    if (!position || !Array.isArray(position) || !stockConfig) return;

    if (position.length === 0) {
      setConfig({});
      return;
    }

    let total_buy_position = 0;
    let total_sell_position = 0;
    let position_pnl = 0;
    let order_value = 0;

    position.forEach((p) => {
      const { ISIN, Qty, NetPrice } = p;

      if (stockConfig[ISIN]) {
        let { order, current_price, order_config } = stockConfig[ISIN];

        const { type, limit } = order_config;

        current_price = type === "MARKET" ? current_price : limit;
        order = parseInt(order);
        if (isNaN(order)) {
          order = 0;
        }

        if (Qty > 0) {
          total_buy_position += Qty * NetPrice;
        } else {
          total_sell_position += Qty * NetPrice;
        }

        position_pnl += Qty * (current_price - NetPrice);

        order_value += parseInt(order) * parseFloat(current_price);
      }
    });

    const stock_config_object_copy = JSON.parse(JSON.stringify(stockConfig));

    const { buy_value, sell_value } = CalculateBuySellValue(
      stock_config_object_copy
    );

    let { brokerage = 10 } = broker;

    brokerage = brokerage / 100 / 100;
    const total_trade_value = Math.abs(buy_value) + Math.abs(sell_value);
    const brokerage_value = total_trade_value * brokerage;

    setConfig((config) => {
      return {
        ...config,
        total_buy_position,
        total_sell_position,
        position_pnl,
        buy_value,
        sell_value,
        brokerage_value,
      };
    });
  }, [position, stockConfig]);

  function PlaceOrder() {
    const { cash_position, buy_value, brokerage_value } = config;

    if (buy_value !== 0 && cash_position - (buy_value + brokerage_value) < 0) {
      AlertMessage(
        "You don't have sufficient cash margin to proceed with the order"
      );
    } else {
      setExecute(true);
    }
  }

  function PlacePortfolioOrder() {
    if (!account) return;

    setIsPlacing(true);
    const { account_code } = account;

    let stocks = [];
    position.forEach((p) => {
      const { ISIN } = p;
      const { order: quantity, symbol, order_config } = stockConfig[ISIN];
      const { type, limit, algo_order_type, algo_config } = order_config;

      let limit_price = 0;
      let algo_config_data = false;

      if (algo_order_type === "MARKET") {
        limit_price = false;
        algo_config_data = false;
      } else if (algo_order_type === "LIMIT") {
        limit_price = limit;
        algo_config_data = false;
      } else {
        limit_price = limit;
        algo_config_data = {
          order_type: algo_order_type,
          algo_config: {
            time_window: SWIFTALGO_TIMEWINDOW[algo_config["time_window"]],
            data_points: algo_config["data_points"],
          },
        };
      }

      if (quantity != 0) {
        stocks.push({
          StockCode: symbol,
          Company: symbol,
          isin: ISIN,
          Quantity: quantity,
          Side: quantity < 0 ? "Sell" : "Buy",
          OrderType: type,
          LMPrice: limit_price,
          AlgoConfig: algo_config_data,
        });
      }
    });

    stocks.sort((a, b) => a.Quantity - b.Quantity);
    stocks.forEach((el) => (el.Quantity = Math.abs(el.Quantity)));

    Axios.post(`${REQUEST_BASE_URL}/broking/order?token=${account_token}`, {
      account_code: account_code,
      stocks: stocks,
    })
      .then((response) => {
        const data = response.data;
        console.log(data);
        Alert({
          TitleText: "Order Routed Successfully!",
          Message: "Please visit transaction section to check your order",
          Band: true,
          BandColor: "#67D098",
          BoxColor: "#ffffff",
          TextColor: "#000000",
          AutoClose: {
            Active: true,
            Line: true,
            LineColor: "#67D098",
            Time: 3,
          },
        });
      })
      .catch((error) => {
        Alert({
          TitleText: "Order Routing Failed",
          Message: "Something went wrong while placing the order!",
          Band: true,
          BandColor: "#470985",
          BoxColor: "#ffffff",
          TextColor: "#000000",
          AutoClose: {
            Active: true,
            Line: true,
            LineColor: "#470985",
            Time: 3,
          },
        });
        console.log(error);
      })
      .finally(() => {
        setIsPlacing(false);
        setTrigger(!trigger);
      });
  }

  function CalculateBuySellValue(stock_config) {
    let buy_value = 0,
      sell_value = 0;

    if (!stock_config) return { buy_value, sell_value };

    for (let isin in stock_config) {
      let { order, current_price, order_config } = stock_config[isin];
      const { type, limit } = order_config;

      current_price = type === "MARKET" ? current_price : limit;
      if (!order && order != 0) order = 0;

      if (order < 0) {
        sell_value += Math.abs(order) * current_price;
      } else {
        buy_value += Math.abs(order) * current_price;
      }
    }

    return { buy_value, sell_value };
  }

  function GetStockQuantity(isin) {
    const stock = position.find((h) => {
      return h["ISIN"] == isin;
    });
    if (stock) return stock["Qty"];
    return false;
  }

  function SetStockConfig(isin, property, value) {
    let error_message = false;
    let stock_config_object = { ...stockConfig };

    let buy_value, sell_value;

    if (property == "order") {
      const quantity = GetStockQuantity(isin);

      if (!quantity && quantity != 0) return;

      if (quantity < 0 && value <= quantity) {
        error_message = "Cannot sell more than current holding";
      }

      if (quantity >= 0 && -value > quantity) {
        error_message = "Cannot sell more than current holding";
      }

      let stock_config_object_copy = JSON.parse(JSON.stringify(stockConfig));
      stock_config_object_copy[isin][property] = value;

      ({ buy_value, sell_value } = CalculateBuySellValue(
        stock_config_object_copy
      ));

      if (
        buy_value !== 0 &&
        cash_position - (buy_value + brokerage_value) < 0
      ) {
        error_message =
          "You don't have sufficient cash margin to proceed with the order";
      }
    }

    if (error_message === false) {
      stock_config_object[isin][property] = value;
      setStockConfig({ ...stock_config_object });
    } else {
      AlertMessage(error_message);
    }
  }

  const DEVICE_SIZE = getDeviceSize();

  if (!config || !position || !Array.isArray(position)) {
    return (
      <>
        <div className="orders-wrapper full">
          <div className="orders-transactions-empty">
            <Pulse />
          </div>
        </div>
      </>
    );
  }

  if (position.length === 0) {
    return (
      <>
        <div className="orders-wrapper full">
          <div className="orders-transactions-empty">
            <OrderBook size={40} color={COLOR_VARS["SWIFT_COLOR4"]} />
            <div>
              <p>No position found</p>
              <span>Looks like you have't any position</span>
            </div>
          </div>
        </div>
      </>
    );
  }

  const {
    total_buy_position,
    total_sell_position,
    position_pnl,
    buy_value,
    sell_value,
    brokerage_value,
    cash_position,
  } = config;

  if (isPlacing) {
    return (
      <>
        <div className="portfolio__container loader">
          <Pulse />
          <p>Routing your order...</p>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="portfolio__options strategy">
        <div className="portfolio__header">
          <div className="portfolio__info__box">
            <div className="portfolio__info__value">
              <span className="portfolio__info__head">
                Current Cash Position
              </span>
              <div>
                <span>{numberFormat(cash_position)}</span>
              </div>
            </div>
          </div>
          <div className="portfolio__info__box">
            <div className="portfolio__info__value">
              <span className="portfolio__info__head">Total Buy Position</span>
              <div>
                <span className="positive">
                  {numberFormat(total_buy_position)}
                </span>
              </div>
            </div>
          </div>
          <div className="portfolio__info__box">
            <div className="portfolio__info__value">
              <span className="portfolio__info__head">Total Sell Position</span>
              <div>
                <span className="negative">
                  {numberFormat(total_sell_position)}
                </span>
              </div>
            </div>
          </div>
          <div className="portfolio__info__box">
            <div className="portfolio__info__value">
              <span className="portfolio__info__head">
                Position Profit/Loss
              </span>
              <div>
                <span className={position_pnl >= 0 ? "positive" : "negative"}>
                  {numberFormat(position_pnl)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="portfolio__container">
        <div className="portfolio__table">
          <div className="table__content">
            {DEVICE_SIZE === "S" ? (
              <>
                {position.map((p, index) => {
                  return (
                    <CustomerPositionRow
                      key={index}
                      DEVICE_SIZE={DEVICE_SIZE}
                      account={account}
                      config={stockConfig[p["ISIN"]]}
                      data={p}
                      SetStockConfig={SetStockConfig}
                    />
                  );
                })}
              </>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <td>Stock Name</td>
                      <td>Quantity</td>
                      <td>Avg. Price</td>
                      <td>Current Price</td>
                      <td>Profit & Loss</td>
                      <td>Return</td>
                      <td>Order</td>
                      <td>Order Type</td>
                      <td>Amount</td>
                    </tr>
                  </thead>
                  <tbody>
                    {position.map((p, index) => {
                      return (
                        <CustomerPositionRow
                          key={index}
                          DEVICE_SIZE={DEVICE_SIZE}
                          account={account}
                          config={stockConfig[p["ISIN"]]}
                          data={p}
                          SetStockConfig={SetStockConfig}
                        />
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
        <div className="trade__value__section">
          <div className="trade__value">
            <p>
              Total Buy Value: &nbsp;
              <span className="positive">{priceFormat(buy_value)}</span>
            </p>
            <p>
              Total Sell Value: &nbsp;
              <span className="negative">{priceFormat(sell_value)}</span>
            </p>
            <p>
              Approx Brokerage: &nbsp;
              <span>{priceFormat(brokerage_value)}</span>
            </p>
            <p>
              Cash Position Post Trade: &nbsp;
              <span>{priceFormat(cash_position - buy_value + sell_value)}</span>
            </p>
          </div>
          <div className="trade__button">
            {execute ? (
              <>
                <CustomIconButton
                  disabled={buy_value === 0 && sell_value === 0}
                  onClick={PlacePortfolioOrder}
                  icon={<PlayCircle size={20} />}
                  title="Route Order"
                />
              </>
            ) : (
              <>
                <CustomIconButton
                  disabled={buy_value === 0 && sell_value === 0}
                  onClick={PlaceOrder}
                  icon={<TickCircle size={20} />}
                  title="Confirm Routing"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

function CustomerPositionRow({
  DEVICE_SIZE,
  account,
  config,
  data,
  SetStockConfig,
}) {
  const { StockCode: Symbol, Token, LTP, Qty, NetPrice, ISIN } = data;

  const { order, order_config } = config;
  const { brokers } = account;

  const { type, algo_order_type, limit, algo_config } = order_config;

  function FilterZero(number, replace = "-") {
    if (number == 0) {
      return replace;
    }
    return number;
  }

  function onChangeOrder(event) {
    const value = event.target.value;

    if (/^-?\d*$/.test(value) || value === "") {
      SetStockConfig(ISIN, "order", value);
    }
  }

  function onBlurOrder(event) {
    const value = event.target.value;
    if (value === "") {
      SetStockConfig(ISIN, "order", 0);
    }
  }

  function onKeyDownOrder(event) {
    let value = event.target.value;

    if (value == "") {
      value = "0";
    }

    value = parseInt(value);

    const keyCode = event.keyCode;

    if (keyCode === 38) {
      event.preventDefault();
      SetStockConfig(ISIN, "order", value + 1);
    } else if (keyCode === 40) {
      event.preventDefault();
      SetStockConfig(ISIN, "order", value - 1);
    }
  }

  function ModifyOrder(config) {
    let { type, limit_price, algo_config } = config;

    let algo_order_type = type;

    if (algo_order_type === "MARKET") {
      limit_price = 0;
      algo_config = false;
    } else if (algo_order_type === "LIMIT") {
      algo_config = false;
    } else {
      type = "LIMIT";
    }

    SetStockConfig(ISIN, "order_config", {
      type,
      algo_order_type,
      limit: limit_price,
      algo_config: algo_config,
    });
  }

  const [movingPrice, setMovingPrice] = useState(0);
  const [typeOpen, setTypeOpen] = useState(false);

  const stock_data = useMarketStock(Symbol);
  const { stock_info } = useStockInfo(Symbol);

  useEffect(() => {
    if (!stock_data) return;
    SetStockConfig(ISIN, "current_price", stock_data.last_traded_price);
  }, [stock_data]);

  useEffect(() => {
    if (!stock_info) return;
    const { symbol } = stock_info;
    SetStockConfig(ISIN, "symbol", symbol);
  }, [stock_info]);

  useEffect(() => {
    // console.log(type, algo_order_type, limit, algo_config);
    if (algo_order_type === "MARKET" || algo_order_type === "LIMIT") {
      return;
    }
    SetStockConfig(ISIN, "order_config", {
      type,
      algo_order_type,
      limit: movingPrice,
      algo_config: algo_config,
    });
  }, [movingPrice]);

  if (!stock_info) return;

  const { symbol, name, exchange } = stock_info;

  const current_price = stock_data ? stock_data["last_traded_price"] : LTP;
  const pnl = Qty * (current_price - NetPrice);
  const return_percentage =
    Qty >= 0
      ? (current_price / NetPrice - 1) * 100
      : (NetPrice / current_price - 1) * 100;
  const order_amount =
    type === "MARKET" ? order * current_price : order * limit;

  if (DEVICE_SIZE === "S") {
    return (
      <div className="table__content__row">
        <div className="content__row">
          <div className="cont__row">
            <div>
              <span>{symbol}</span>
            </div>
            <div>
              <span>{FilterZero(numberFormat(NetPrice * Math.abs(Qty)))}</span>
            </div>
          </div>
          <div className="cont__row">
            <div>
              <span>{FilterZero(Qty)}</span>
              <span>@ {FilterZero(numberFormat(NetPrice))}</span>
            </div>
            <div>
              <span className={pnl >= 0 ? "positive" : "negative"}>
                {numberFormat(pnl)}
              </span>
              <span className={pnl >= 0 ? "positive" : "negative"}>
                {numberFormat(return_percentage)}%
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <tr>
        <td>
          <div>
            <div className="portfolio__stock__code">
              <div className="stock__code">{symbol}</div>
            </div>
            <div className="portfolio__stock__name">{name}</div>
          </div>
        </td>
        <td>
          <div>{FilterZero(Qty)}</div>
        </td>
        <td>
          <div>{FilterZero(numberFormat(NetPrice))}</div>
        </td>
        <td>
          <div>{numberFormat(current_price)}</div>
        </td>
        <td>
          <div className={pnl >= 0 ? "positive" : "negative"}>
            {numberFormat(pnl)}
          </div>
        </td>

        <td>
          <div className={return_percentage >= 0 ? "positive" : "negative"}>
            {`${numberFormat(return_percentage)}%`}
          </div>
        </td>
        <td className="order">
          <div className="input-box">
            <span
              onClick={() => {
                if (Qty > 0) {
                  if (-1 * Qty === order) {
                    SetStockConfig(ISIN, "order", 0);
                  } else {
                    SetStockConfig(ISIN, "order", -1 * Qty);
                  }
                }
              }}
            >
              FS
            </span>
            <span
              onClick={() => {
                SetStockConfig(ISIN, "order", order - 1);
              }}
            >
              <RemoveIcon fontSize="14" color={COLOR_VARS["SWIFT_COLOR4"]} />
            </span>
            <input
              type="text"
              value={order}
              onChange={(e) => {
                onChangeOrder(e);
              }}
              onBlur={(e) => {
                onBlurOrder(e);
              }}
              onKeyDown={(e) => {
                onKeyDownOrder(e);
              }}
            />
            <span
              onClick={() => {
                SetStockConfig(ISIN, "order", order + 1);
              }}
            >
              <AddIcon fontSize="14" color={COLOR_VARS["SWIFT_COLOR4"]} />
            </span>
          </div>
        </td>
        <td className="ordertype">
          {order != 0 ? (
            <div
              className="ordertype-box"
              onClick={() => {
                setTypeOpen(true);
              }}
            >
              <Edit size={14} color={COLOR_VARS["SWIFT_COLOR4"]} />
              <p>
                {titleCase(algo_order_type)}&nbsp; @&nbsp;
                {order == 0 || algo_order_type === "MARKET" ? (
                  numberFormat(current_price)
                ) : algo_config ? (
                  <>
                    <MovingAverage
                      symbol={symbol}
                      current_price={current_price}
                      side={order < 0 ? "SELL" : "BUY"}
                      time_window={algo_config.time_window}
                      data_points={algo_config.data_points}
                      setMovingPrice={setMovingPrice}
                    />
                  </>
                ) : (
                  numberFormat(limit)
                )}
              </p>
            </div>
          ) : (
            <div className="ordertype-box">-</div>
          )}
        </td>
        <td className="tradevol">
          <div
            className={parseFloat(order_amount) >= 0 ? "positive" : "negative"}
          >
            {numberFormat(order_amount)}
          </div>
        </td>
      </tr>
      {typeOpen && (
        <>
          <SwiftModal
            top="2%"
            closeModal={() => {
              setTypeOpen(false);
            }}
          >
            <OrderTypePopup
              order={{
                ordertype: algo_order_type,
                code: Token,
                exchange: exchange.exchange,
                quantity: order,
                side: order >= 0 ? "BUY" : "SELL",
                name: symbol,
                broker:
                  Array.isArray(brokers) && brokers.length > 0
                    ? brokers[0].broker
                    : "-",
                limitprice: limit,
                algo_config: algo_config,
              }}
              current_price={current_price}
              ModifyOrder={ModifyOrder}
              setTypeOpen={setTypeOpen}
              closePopup={() => {
                setTypeOpen(false);
              }}
            />
          </SwiftModal>
        </>
      )}
    </>
  );
}

function MovingAverage({
  symbol,
  current_price,
  side,
  time_window,
  data_points,
  setMovingPrice,
}) {
  let movingPrice = useMovingAverage(symbol, side, time_window, data_points);
  movingPrice = movingPrice == 0 ? current_price : movingPrice;
  useEffect(() => {
    setMovingPrice(movingPrice);
  }, [movingPrice]);

  return <>{numberFormat(movingPrice)}</>;
}

export default CustomerPosition;
