import React, { useEffect, useState } from "react";
import { ArrowRight2, Cross } from "../../CustomComponents/SwiftIcon/Icons";
import CustomSelectError from "../../Body/CustomChartComponents/CustomSelectError/CustomSelectError";
import ServerRequest from "../../../exports/ServerRequest";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../css/CustomComponents/CustomSearchSelect.css";
import "../../../css/AdminManagement/DisplayForm.css";
import { useParams } from "react-router-dom";

import {
  checkLength,
  isEmpty,
  isValidCustomerId,
  isValidEmail,
  isValidFloat,
} from "../../../exports/UtilFunctions";
import CustomInputFile from "../../CustomComponents/CustomInputFile/CustomInputFile";
import Pulse from "../../Loader/Pulse";
import { Alert } from "../../Body/CustomChartComponents/CustomAlert/CustomAlert";
import ConfirmBox from "../../CustomComponents/ConfirmBox/ConfirmBox";
import SwiftModal from "../../CustomComponents/SwiftModal/SwiftModal";
import { GetCookie } from "../../../exports/CookieManagement";
import { COLOR_VARS } from "../../../exports/ColorVars";

const customerRoles = ["Managed Account Customer", "Broking Customer"];
const Roles = ["BROKER", "CUSTOMER"];
const HWMOption = ["Yes", "No"];
const BrokerageData = Array.from({ length: 30 }, (_, index) => index + 1);

const roleCodeMap = {
  "Super Admin": "SA",
  "Super User": "SU",
  Trader: "TRD",
  Operations: "OPR",
  Marketing: "MRK",
  "Trader & Operations": "TOU",
  "Trader & Marketing": "TMU",
  "Operations & Marketing": "OMU",
  "Trading & Operations & Marketing": "TMO",
  "Managed Account Customer": "MAC",
  "Broking Customer": "BRC",
};

const DisplayAlert = (msg) => {
  Alert({
    TitleText: "Error",
    Message: msg,
    BandColor: "#e51a4b",

    AutoClose: {
      Active: true,
      Line: true,
      LineColor: "#e51a4b",
      Time: 3,
    },
  });
};

const DisplayForm = () => {
  // const {
  //   state: { formdata, superuserCustomerdata, BrokerOption },
  // } = useLocation();
  const { pan } = useParams();
  const token = GetCookie("admin_token");

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    user_role: "",
    super_user_code: "",
    ClientID: "",
    password: "",
    bank_name: "",
    bank_acc_no: "",
    bank_ifsc_code: "",
    signature_image: "",
    role_type: "",
    role_name: "",
    custody_user_code: ["", "", ""],
    client_password: ["", "", ""],
    client_id: ["", "", ""],
    cpcode: ["", "", ""],
    broker: ["", "", ""],
    trading_code: ["", "", ""],
    dp_id: ["", "", ""],
    brokerage: ["", "", ""],
    stt: ["", "", ""],
    stamp_duty: ["", "", ""],
    sebi_charges: ["", "", ""],
    amc: ["", "", ""],
    hurdle: ["", "", ""],
    hwm: ["", "", ""],
    gst: ["", "", ""],
    perf_fee: ["", "", ""],
    customer_id: "",
    customer_password: "",
    new_scheme: "",
    scheme: "",
  });
  const [formData, setFormData] = useState({});
  const [selectedBrokerIndex, setSelectedBrokerIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  // const [deleteok, setDeleteOk] = useState();
  const [superuserCustomerdata, setSuperUserCustomerData] = useState([]);
  const [BrokerOption, setBrokerOption] = useState([]);
  const [BrokerMap, setBrokerMap] = useState({});
  const [schema, setSchema] = useState([]);
  const [currentSchema, setCurrentSchema] = useState([]);
  const [schemePopup, setSchemePopup] = useState(false);

  const [email, setEmail] = useState("");
  const [loader, setloader] = useState("");
  const [popupImage, setPopupImage] = useState(null);
  const [isCustomerId, setIsCustomerId] = useState(null);
  console.log(BrokerMap);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // setloader("Getting Account Detail");
      const data1 = await ServerRequest({
        url: `/admin/account/user?pan=${pan}&token=${token}`,
        method: "get",
      });
      if (data1.server_error) {
        navigate("/404", { state: { errorMessage: data1.message } });
      }
      if (data1.error) {
        navigate("/404", { state: { errorMessage: data1.message } });
      }

      const sanitizedData = Object.fromEntries(
        Object.entries(data1.data).map(([key, value]) => [
          key,
          value === "" || value === "null" ? null : value,
        ])
      );

      setFormData(sanitizedData);
      setEmail(sanitizedData.email);

      const data2 = await ServerRequest({
        url: `/admin/account/superuser?token=${token}`,
        method: "get",
      });
      if (data2.server_error) {
        navigate("/404", { state: { errorMessage: data2.message } });
      }
      const newData = [
        { name: "No Super User", user_role: null, account_code: null },
        ...data2.data,
      ];

      const data3 = await ServerRequest({
        url: `/admin/account/broker?token=${token}`,
        method: "get",
      });
      if (data3.server_error) {
        navigate("/404", { state: { errorMessage: data2.message } });
      }
      const brokerNames = data3.data.map((entry) => entry.broker);
      setBrokerOption(brokerNames);

      const map = {};
      data3.data.forEach((entry) => {
        map[entry.broker] = entry.trading_method;
      });
      setBrokerMap(map);

      setSuperUserCustomerData(newData);

      const data4 = await ServerRequest({
        url: `/admin/account/schema?token=${token}`,
        method: "get",
      });
      if (data4.server_error) {
        navigate("/404", { state: { errorMessage: data2.message } });
      }
      setSchema(data4.data);
      setLoading(false);
      setloader("");
    };

    fetchData();
  }, [pan]);

  useEffect(() => {
    if (formData !== null && formData !== undefined) {
      const filteredSchema = schema.filter((entry) => {
        return (
          entry.scheme_owner === formData.super_user_code &&
          entry.scheme_status === "active"
        );
      });
      setCurrentSchema(filteredSchema);
    }
  }, [formData.super_user_code, schema]);

  // console.log(schema, currentSchema, formData.super_user_code);
  const navigate = useNavigate();

  const openLinkInNewTab = async (url) => {
    if (url) {
      // const newUrl = await getIng(url);
      // window.open(url, "_blank");
      // setPopupImage(url);
      const encodedUrl = encodeURIComponent(url);

      window.open(`/admin/image/${encodedUrl}`, "_blank");
    }
  };
  const closePopup = () => {
    setPopupImage(null);
  };
  const ValidateAll = () => {
    const newErrors = {
      name: "",
      email: "",
      user_role: "",
      super_user_code: "",
      ClientID: "",
      password: "",
      bank_name: "",
      bank_acc_no: "",
      bank_ifsc_code: "",
      signature_image: "",
      role_type: "",
      role_name: "",
      custody_user_code: ["", "", ""],
      client_password: ["", "", ""],
      client_id: ["", "", ""],
      cpcode: ["", "", ""],
      broker: ["", "", ""],
      trading_code: ["", "", ""],
      dp_id: ["", "", ""],
      brokerage: ["", "", ""],
      stt: ["", "", ""],
      stamp_duty: ["", "", ""],
      sebi_charges: ["", "", ""],
      amc: ["", "", ""],
      hurdle: ["", "", ""],
      hwm: ["", "", ""],
      gst: ["", "", ""],
      perf_fee: ["", "", ""],
      customer_id: "",
      customer_password: "",
      new_scheme: "",
      scheme: "",
    };
    console.log(formData);
    let hasError = false;
    if (isEmpty(formData.name)) {
      newErrors["name"] = `Name cannot be empty`;
      hasError = true;
    }

    if (isEmpty(formData.role_type)) {
      newErrors["role_type"] = `Role cannot be empty`;
      hasError = true;
    }
    if (isEmpty(formData.user_role) && !hasError) {
      newErrors["user_role"] = "Role Can not be empty";
      hasError = true;
    }
    if (isEmpty(formData.scheme) && !hasError) {
      newErrors["scheme"] = "Scheme can not be empty";
      hasError = true;
    }
    if (!checkLength(formData.aadhaar_no, 12) && !hasError) {
      newErrors["aadhaar_no"] = "aadhaar_no length should be 12";
      hasError = true;
    }

    if (!isValidEmail(formData.email) && !hasError) {
      newErrors["email"] = "Plaese Enter Valid Email";
      hasError = true;
    }
    // if (isEmpty(formData.signature_image) && !hasError) {
    //   newErrors["signature_image"] = "Plaese Select Image";
    //   hasError = true;
    // }
    formData.brokerArray.map((item, index) => {
      if (isEmpty(formData.brokerArray[index].broker) && !hasError) {
        newErrors["broker"][index] = "Plaese Select Broker";
        hasError = true;
      }
      if (
        isEmpty(formData.brokerArray[index].trading_code) &&
        !hasError &&
        BrokerMap[formData.brokerArray[index].broker] === "FILE" &&
        formData.user_role == "BRC"
      ) {
        newErrors["trading_code"][index] = "trading_code should not be empty";
        hasError = true;
      }
      if (
        isEmpty(formData.brokerArray[index].client_id) &&
        (BrokerMap[formData.brokerArray[index].broker] === "API" ||
          (BrokerMap[formData.brokerArray[index].broker] === "FILE" &&
            formData.user_role == "BRC")) &&
        !hasError
      ) {
        newErrors["client_id"][index] = "ClientId Should not be Empty";
        hasError = true;
      }
      if (
        (!formData.brokerArray[index].client_password ||
          formData.brokerArray[index].client_password.length < 8) &&
        BrokerMap[formData.brokerArray[index].broker] === "API" &&
        !hasError
      ) {
        newErrors["client_password"][index] = "Password length should be 8";
        hasError = true;
      }
      console.log(isEmpty(formData.brokerArray[index].custody_user_code));
      if (
        isEmpty(formData.brokerArray[index].custody_user_code) &&
        !hasError &&
        BrokerMap[formData.brokerArray[index].broker] === "FILE" &&
        formData.user_role == "MAC"
      ) {
        newErrors["custody_user_code"][index] =
          "custody_user_code should not be empty";
        hasError = true;
      }
      if (
        isEmpty(formData.brokerArray[index].cpcode) &&
        !hasError &&
        BrokerMap[formData.brokerArray[index].broker] === "FILE" &&
        formData.user_role == "MAC"
      ) {
        newErrors["cpcode"][index] = "cpcode should not be empty";
        hasError = true;
      }
      if (
        isEmpty(formData.brokerArray[index].dp_id) &&
        !hasError &&
        ((formData.user_role == "BRC" &&
          BrokerMap[formData.brokerArray[index].broker] === "FILE") ||
          BrokerMap[formData.brokerArray[index].broker] === "API")
      ) {
        newErrors["dp_id"][index] = "dp_id should not be empty";
        hasError = true;
      }

      if (!isValidFloat(formData.brokerArray[index].brokerage) && !hasError) {
        newErrors["brokerage"][index] = "Plaese Enter Valid Brokerage";
        hasError = true;
      }

      if (!isValidFloat(formData.brokerArray[index].stamp_duty) && !hasError) {
        newErrors["stamp_duty"][index] = "Plaese Enter Valid StampDuty";
        hasError = true;
      }
      if (!isValidFloat(formData.brokerArray[index].stt) && !hasError) {
        newErrors["stt"][index] = "Plaese Enter Valid SecuritiesTransactionTax";
        hasError = true;
      }
      if (
        !isValidFloat(formData.brokerArray[index].sebi_charges) &&
        !hasError
      ) {
        newErrors["sebi_charges"][index] = "Plaese Enter Valid SEBICharges";
        hasError = true;
      }
      if (!isValidFloat(formData.brokerArray[index].amc) && !hasError) {
        newErrors["amc"][index] = "Plaese Enter Valid amc";
        hasError = true;
      }
      if (!isValidFloat(formData.brokerArray[index].hurdle) && !hasError) {
        newErrors["hurdle"][index] = "Plaese Enter Valid hurdle";
        hasError = true;
      }

      if (!isValidFloat(formData.brokerArray[index].gst) && !hasError) {
        newErrors["gst"][index] = "Plaese Enter Valid gst";
        hasError = true;
      }
      if (!isValidFloat(formData.brokerArray[index].perf_fee) && !hasError) {
        newErrors["perf_fee"][index] = "Plaese Enter Valid perf_fee";
        hasError = true;
      }
    });

    setErrors(newErrors);

    if (hasError) {
      for (const [key, value] of Object.entries(newErrors)) {
        if (Array.isArray(value)) {
          value.forEach((error, index) => {
            if (error) {
              let errorMessage = error + ` In Broker ${index + 1}`;

              // if (key.includes("broker")) {
              //   errorMessage += ` (Broker ${index + 1})`;
              // }
              DisplayAlert(errorMessage);
            }
          });
        } else if (value) {
          let errorMessage = value;
          if (key.includes("broker")) {
            errorMessage += ` (Broker 1)`;
          }
          DisplayAlert(errorMessage);
        }
      }
    }

    return hasError;
  };
  const handleInputChange = (name, value) => {
    setFormData((prevValues) => ({ ...prevValues, [name]: value }));
  };
  const handleInputChange1 = (name, value) => {
    const index = parseInt(name.match(/\d+/)[0]);
    const array = [...formData.brokerArray];
    const fieldName = name.replace(/\d+/g, "");
    array[index][fieldName] = value;
    setFormData((prevValues) => ({ ...prevValues, brokerArray: array }));
  };
  const addBrokerHandler = () => {
    setFormData((prevState) => ({
      ...prevState,
      brokerArray: [
        ...prevState.brokerArray,
        {
          custody_user_code: null,
          cpcode: null,
          broker: null,
          trading_code: null,
          dp_id: null,
          brokerage: null,
          stt: null,
          stamp_duty: null,
          sebi_charges: null,
          amc: null,
          hurdle: null,
          hwm: null,
          gst: null,
          perf_fee: null,
        },
      ],
    }));
    setSelectedBrokerIndex(formData.brokerArray.length);
  };
  const DeleteBrokerHandler = (indexToDelete) => {
    const updatedBrokerArray = [...formData.brokerArray];
    updatedBrokerArray.splice(indexToDelete, 1);
    setFormData((prevFormData) => ({
      ...prevFormData,
      brokerArray: updatedBrokerArray,
    }));
    setSelectedBrokerIndex(4);
    // setDeleteOk();

    setTimeout(() => {
      setSelectedBrokerIndex(0);
    }, 100);
  };
  const openCustomerId = async () => {
    const data3 = await ServerRequest({
      url: `/admin/customer-id?account_code=${formData.user_role}&token=${token}`,
      method: "get",
    });

    if (data3.server_error) {
      navigate("/404", { state: { errorMessage: data3.message } });
    }
    setIsCustomerId(true);
    handleInputChange("customer_id", data3.data.CustomerId);
    handleInputChange("customer_ids", data3.data.CustomerIds);

    const firstName = formData.name.split(" ")[0];
    const panDigits = formData.pan.match(/\d+/)[0];
    const panFirstFourDigits = panDigits.slice(0, 4);
    const newPassword = `${firstName}@${panFirstFourDigits}`;

    handleInputChange("customer_password", newPassword);
  };
  const closeCustomerId = () => {
    setIsCustomerId(null);
    navigate("/admin/customer-management");
  };

  const AddSchema = () => {
    if (isEmpty(formData.new_scheme)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        new_scheme: "Scheme name can not be empty",
      }));
      return;
    }
    const lowercasedNewScheme = formData.new_scheme.toLowerCase();
    if (
      schema.some(
        (entry) => entry.scheme_name.toLowerCase() === lowercasedNewScheme
      )
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        new_scheme: "Scheme name already exists",
      }));
      return;
    }

    const newScheme = {
      scheme_id: Math.floor(Date.now() / 1000),
      scheme_owner: formData.super_user_code,
      scheme_name: formData.new_scheme,
      scheme_status: "active",
    };

    setSchema((prev) => [...prev, newScheme]);
    setErrors((prevErrors) => ({
      ...prevErrors,
      new_scheme: "",
    }));
    formData.new_scheme = "";

    setSchemePopup(false);
  };
  const handleSubmit = async () => {
    if (ValidateAll()) return;
    if (
      formData.status !== "Pending" &&
      (!formData.customer_id ||
        formData.customer_id.charAt(0) !== formData.user_role.charAt(0))
    ) {
      formData.customer_id = "";
      await openCustomerId();
      return;
    }

    const myForm = new FormData();
    setLoading(true);
    setloader("Creating Account");

    Object.entries(formData).forEach(([key, value]) => {
      myForm.append(key, value);
    });
    formData.startemail = email;
    formData.schemeArray = schema;

    myForm.append("form", JSON.stringify(formData));

    const data = await ServerRequest({
      url: `/admin/account/validate?email=${formData.email}&token=${token}`,
      // data: myForm,
      method: "get",
      // headers: {
      //   "Content-type": "multipart/form-data",
      // },
    });

    if (data.error && email != formData.email) {
      setErrors({ ...errors, ["email"]: "Email already exist" });
      setLoading(false);
      DisplayAlert("Email already exist");
      return;
    }
    if (data.server_error) {
      navigate("/404", { state: { errorMessage: data.message } });
    }

    const data2 = await ServerRequest({
      url: `/admin/account/create?token=${token}`,
      data: myForm,
      method: "post",
      headers: {
        "Content-type": "multipart/form-data",
      },
    });

    if (data2.server_error) {
      navigate("/404", { state: { errorMessage: data2.message } });
    }

    setLoading(false);
    setloader("");
    closeCustomerId();
  };
  const handleSubmitActivate = async () => {
    const regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (formData.customer_ids.includes(formData.customer_id)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        customer_id: "Customer Id already Exist",
      }));

      return;
    }
    if (!isValidCustomerId(formData.customer_id, formData.user_role)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        customer_id: "Enter Valid Customer Id",
      }));

      return;
    }
    if (!regex.test(formData.customer_password)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        customer_password: "Enter Valid Password",
      }));

      return;
    }
    formData.isChange = true;
    formData.status = "Activated";
    handleSubmit();
  };
  const showConfirmBox = (deleteok) => {
    console.log(deleteok);
    ConfirmBox({
      title: "Delete Broker",
      description: (
        <>
          <>Are you sure you want to Delete the Broker ?</>
        </>
      ),
      properties: [
        {
          id: "2",
          label: "Delete",
          color: "#192b40",
          bgColor: "#ffffff",
          handleFunction: (callback) => {
            DeleteBrokerHandler(deleteok);
            callback();
          },
        },
      ],
      cancel: true,
    });
  };
  console.log(formData);
  return loading ? (
    <div className="loader admin-display-form">
      <Pulse />
      <p>{loader}</p>
    </div>
  ) : (
    <div className="main">
      <div className="swift-account-main swift-account-display-form">
        <div className="swift-account-form-body-content-extra">
          <div className="swift-account-form-body-content">
            <div
              className="swift-account-form-section"
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "5px",
              }}
            >
              <span>User Details</span>
              <p
                style={{
                  paddingBottom: "5px",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              >
                Account Status : {formData.status}
              </p>
            </div>

            <CustomInputFile
              label="Name"
              name="name"
              type="text"
              value={formData["name"].toUpperCase()}
              onchange={handleInputChange}
              error={errors.name}
              placeholder="Enter your name"
              isUpper={true}
            />
            <div className="swift-account-display-form-row">
              {formData.status == "Activated" ? (
                <>
                  <CustomInputFile
                    label="Customer Role"
                    name="role_name"
                    type="text"
                    value={formData["role_name"]}
                    onchange={handleInputChange}
                    error={errors.role_name}
                    disabled={true}
                  />
                  <CustomInputFile
                    label="Super User Code"
                    name="super_user_name"
                    type="text"
                    value={
                      superuserCustomerdata.find(
                        (user) => user.account_code === formData.super_user_code
                      ).name
                    }
                    onchange={handleInputChange}
                    error={errors.super_user_code}
                    disabled={true}
                  />
                  <CustomInputFile
                    label="Portfolio Scheme"
                    name="scheme"
                    type="text"
                    value={formData.scheme}
                    onchange={handleInputChange}
                    error={errors.scheme}
                    disabled={true}
                  />
                </>
              ) : (
                <>
                  <CustomSelectError
                    heading="Customer Role"
                    placeholder="Please Select Customer Role"
                    defaultIndex={customerRoles.indexOf(formData["role_name"])}
                    options={customerRoles}
                    onTypeChange={(role) => {
                      handleInputChange("role_name", role);
                      handleInputChange("user_role", roleCodeMap[role]);
                    }}
                    error={errors.user_role}
                  />
                  <CustomSelectError
                    heading="Super User Code"
                    placeholder="Please Select Super User Code"
                    defaultIndex={superuserCustomerdata
                      .map((item) => item.account_code)
                      .indexOf(formData["super_user_code"])}
                    options={superuserCustomerdata.map((item) => item.name)}
                    onTypeChange={(role) => {
                      const selectedSuperUser = superuserCustomerdata.find(
                        (user) => user.name === role
                      );
                      if (selectedSuperUser) {
                        handleInputChange("super_user_name", role);
                        handleInputChange(
                          "super_user_code",
                          selectedSuperUser.account_code
                        );
                      }
                    }}
                  />
                  {formData.super_user_code && (
                    <>
                      <CustomSelectError
                        heading="Portfolio Scheme"
                        placeholder="Select Scheme"
                        defaultIndex={currentSchema
                          .map((item) => item.scheme_name)
                          .indexOf(formData.scheme)}
                        options={currentSchema.map(
                          (entry) => entry.scheme_name
                        )}
                        onTypeChange={(role) => {
                          handleInputChange("scheme", role);
                          const matchedScheme = schema.find(
                            (scheme) => scheme.scheme_name === role
                          );

                          handleInputChange(
                            "scheme_id",
                            matchedScheme.scheme_id
                          );
                        }}
                        error={errors.user_role}
                      />
                      <button
                        className="swift-account-btn extra-btn"
                        onClick={() => setSchemePopup(true)}
                      >
                        Add Scheme
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
            {/* <div className="swift-account-display-form-row">
              <CustomInputFile
                label="Client ID"
                name="ClientID"
                type="text"
                value={formData["ClientID"]}
                onchange={handleInputChange}
                error={errors.ClientID}
              />

              <CustomInputFile
                label="Password"
                name="password"
                type="password"
                value={formData.password}
                onchange={handleInputChange}
                error={errors.password}
              />
            </div> */}
            <div className="swift-account-display-form-row">
              <CustomInputFile
                label="Aadhar Number"
                name="aadhaar_no"
                type="number"
                maxlength="12"
                value={formData.aadhaar_no}
                onchange={handleInputChange}
                error={errors.aadhaar_no}
                disabled={true}
              />

              <button
                className="swift-account-btn extra-btn"
                onClick={() => openLinkInNewTab(formData.aadhaar_image)}
              >
                View Aadhar Image
              </button>
            </div>
            <CustomInputFile
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onchange={handleInputChange}
              error={errors.email}
              placeholder="E.g. xyz@gmail.com"
            />
            <div className="swift-account-display-form-row">
              <CustomInputFile
                label="Bank Name"
                name="bank_name"
                type="text"
                value={formData.bank_name}
                onchange={handleInputChange}
                error={errors.bank_name}
                placeholder="Enter bank name"
              />
              <button
                className="swift-account-btn extra-btn"
                onClick={() => openLinkInNewTab(formData.bank_cheque_image)}
              >
                View Bank cheque Image
              </button>
            </div>
            <div className="swift-account-display-form-row">
              <CustomInputFile
                label="Bank Account Number"
                name="bank_acc_no"
                type="number"
                maxlength="18"
                value={formData.bank_acc_no}
                onchange={handleInputChange}
                error={errors.bank_acc_no}
                placeholder="E.g. 111111"
              />

              <CustomInputFile
                label="IFSC Code"
                name="bank_ifsc_code"
                type="text"
                maxlength="11"
                value={formData.bank_ifsc_code}
                onchange={handleInputChange}
                error={errors.bank_ifsc_code}
                placeholder="E.g. ABCD0123456"
              />
            </div>

            {/* <CustomInputFile
              label="Signature Image"
              name="signature_image"
              type="file"
              onchange={handleInputChange}
              error={errors.signature_image}
            /> */}
            <div className="swift-account-form-section">
              <span>Broking Details</span>
              {formData.brokerArray.length < 3 && (
                <button onClick={addBrokerHandler}>Add Broker</button>
              )}
            </div>
            <div className="broker-tabs">
              {formData.brokerArray.map((item, index) => (
                <div
                  key={index}
                  className={`broker-tab ${
                    index === selectedBrokerIndex ? "active" : ""
                  }`}
                  onClick={() => setSelectedBrokerIndex(index)}
                >
                  Broker {index + 1}
                </div>
              ))}
            </div>
            {formData.brokerArray.map((item, index) => {
              if (index === selectedBrokerIndex) {
                return (
                  <div key={index}>
                    <div className="swift-account-display-form-row">
                      <CustomSelectError
                        heading="Broker"
                        placeholder="Select Broker"
                        defaultIndex={BrokerOption.indexOf(
                          formData.brokerArray[selectedBrokerIndex].broker
                        )}
                        options={BrokerOption}
                        onTypeChange={(role) =>
                          handleInputChange1(
                            `broker${selectedBrokerIndex}`,
                            role
                          )
                        }
                        error={errors.broker[selectedBrokerIndex]}
                      />
                      {/* <CustomInputFile
                        label="Trading Code"
                        name={`trading_code${selectedBrokerIndex}`}
                        type="text"
                        value={
                          formData.brokerArray[selectedBrokerIndex].trading_code
                        }
                        onchange={handleInputChange1}
                        error={errors.trading_code[selectedBrokerIndex]}
                      /> */}
                    </div>
                    {BrokerMap[
                      formData.brokerArray[selectedBrokerIndex].broker
                    ] === "API" && (
                      <div className="swift-account-display-form-row">
                        <CustomInputFile
                          label="Client ID"
                          name={`client_id${selectedBrokerIndex}`}
                          type="text"
                          value={
                            formData.brokerArray[selectedBrokerIndex].client_id
                          }
                          onchange={handleInputChange1}
                          error={errors.client_id[selectedBrokerIndex]}
                          placeholder="E.g. 12345678"
                        />
                        <CustomInputFile
                          label="Broker Password"
                          name={`client_password${selectedBrokerIndex}`}
                          type="text"
                          value={
                            formData.brokerArray[selectedBrokerIndex]
                              .client_password
                          }
                          onchange={handleInputChange1}
                          error={errors.client_password[selectedBrokerIndex]}
                          placeholder="E.g. mybrokerpassword@123"
                        />
                        <CustomInputFile
                          label="Dp ID"
                          name={`dp_id${selectedBrokerIndex}`}
                          type="text"
                          maxlength="100"
                          value={
                            formData.brokerArray[selectedBrokerIndex].dp_id
                          }
                          onchange={handleInputChange1}
                          error={errors.dp_id[selectedBrokerIndex]}
                          placeholder="E.g. DP1234"
                        />
                      </div>
                    )}
                    {BrokerMap[
                      formData.brokerArray[selectedBrokerIndex].broker
                    ] === "FILE" &&
                      formData.user_role == "MAC" && (
                        <div className="swift-account-display-form-row">
                          <CustomInputFile
                            label="Custody User Code"
                            name={`custody_user_code${selectedBrokerIndex}`}
                            type="number"
                            maxlength="100"
                            value={
                              formData.brokerArray[selectedBrokerIndex]
                                .custody_user_code
                            }
                            onchange={handleInputChange1}
                            error={
                              errors.custody_user_code[selectedBrokerIndex]
                            }
                            placeholder="E.g. 12345678"
                          />
                          <CustomInputFile
                            label="CP Code"
                            name={`cpcode${selectedBrokerIndex}`}
                            type="text"
                            maxlength="100"
                            value={
                              formData.brokerArray[selectedBrokerIndex].cpcode
                            }
                            onchange={handleInputChange1}
                            error={errors.cpcode[selectedBrokerIndex]} // Make sure to update error key dynamically
                            placeholder="E.g. CP1234"
                          />
                        </div>
                      )}
                    {BrokerMap[
                      formData.brokerArray[selectedBrokerIndex].broker
                    ] === "FILE" && (
                      <>
                        <div className="swift-account-display-form-row">
                          <CustomInputFile
                            label="Trading Code"
                            name={`trading_code${selectedBrokerIndex}`}
                            type="text"
                            maxlength="100"
                            value={
                              formData.brokerArray[selectedBrokerIndex]
                                .trading_code
                            }
                            onchange={handleInputChange1}
                            error={errors.trading_code[selectedBrokerIndex]}
                            placeholder="E.g. ABCD1234"
                          />
                          {formData.user_role == "BRC" && (
                            <>
                              <CustomInputFile
                                label="Client ID"
                                name={`client_id${selectedBrokerIndex}`}
                                type="text"
                                value={
                                  formData.brokerArray[selectedBrokerIndex]
                                    .client_id
                                }
                                onchange={handleInputChange1}
                                error={errors.client_id[selectedBrokerIndex]}
                                placeholder="E.g. 12345678"
                              />
                              <CustomInputFile
                                label="Dp ID"
                                name={`dp_id${selectedBrokerIndex}`}
                                type="text"
                                maxlength="100"
                                value={
                                  formData.brokerArray[selectedBrokerIndex]
                                    .dp_id
                                }
                                onchange={handleInputChange1}
                                error={errors.dp_id[selectedBrokerIndex]}
                                placeholder="E.g. DP1234"
                              />
                            </>
                          )}
                        </div>
                      </>
                    )}

                    <div className="swift-account-display-form-row">
                      <CustomSelectError
                        heading="Brokerage"
                        placeholder="Please Select Brokerage"
                        defaultIndex={BrokerageData.indexOf(
                          parseInt(
                            formData.brokerArray[selectedBrokerIndex].brokerage
                          )
                        )}
                        options={BrokerageData}
                        onTypeChange={(role) =>
                          handleInputChange1(
                            `brokerage${selectedBrokerIndex}`,
                            role
                          )
                        }
                        error={errors.brokerage[selectedBrokerIndex]}
                      />
                      {/* <CustomInputFile
                        label="Brokerage"
                        name={`brokerage${selectedBrokerIndex}`}
                        type="number"
                        maxlength="10"
                        value={
                          formData.brokerArray[selectedBrokerIndex].brokerage
                        }
                        onchange={handleInputChange1}
                        error={errors.brokerage[selectedBrokerIndex]}
                      /> */}

                      <CustomInputFile
                        label="Stamp Duty"
                        name={`stamp_duty${selectedBrokerIndex}`}
                        type="number"
                        maxlength="10"
                        value={
                          formData.brokerArray[selectedBrokerIndex].stamp_duty
                        }
                        onchange={handleInputChange1}
                        error={errors.stamp_duty[selectedBrokerIndex]}
                        placeholder="E.g. 1"
                      />

                      <CustomInputFile
                        label="Securities Transaction Tax"
                        name={`stt${selectedBrokerIndex}`}
                        type="number"
                        maxlength="10"
                        value={formData.brokerArray[selectedBrokerIndex].stt}
                        onchange={handleInputChange1}
                        error={errors.stt[selectedBrokerIndex]}
                        placeholder="E.g. 1"
                      />
                    </div>
                    <div className="swift-account-display-form-row">
                      <CustomInputFile
                        label="SEBI Charges"
                        name={`sebi_charges${selectedBrokerIndex}`}
                        type="number"
                        maxlength="10"
                        value={
                          formData.brokerArray[selectedBrokerIndex].sebi_charges
                        }
                        onchange={handleInputChange1}
                        error={errors.sebi_charges[selectedBrokerIndex]}
                        placeholder="E.g. 1"
                      />

                      <CustomInputFile
                        label="AMC"
                        name={`amc${selectedBrokerIndex}`}
                        type="number"
                        maxlength="10"
                        value={formData.brokerArray[selectedBrokerIndex].amc}
                        onchange={handleInputChange1}
                        error={errors.amc[selectedBrokerIndex]}
                        placeholder="E.g. 1"
                      />
                      <CustomInputFile
                        label="Hurdle"
                        name={`hurdle${selectedBrokerIndex}`}
                        type="number"
                        maxlength="10"
                        value={formData.brokerArray[selectedBrokerIndex].hurdle}
                        onchange={handleInputChange1}
                        error={errors.hurdle[selectedBrokerIndex]}
                        placeholder="E.g. 1"
                      />
                    </div>
                    <div className="swift-account-display-form-row">
                      <CustomSelectError
                        heading="HWM"
                        placeholder="Please Select HWM"
                        defaultIndex={HWMOption.indexOf(
                          formData.brokerArray[selectedBrokerIndex]["hwm"] ==
                            "Y"
                            ? "Yes"
                            : formData.brokerArray[selectedBrokerIndex][
                                "hwm"
                              ] == "N"
                            ? "No"
                            : "abc"
                        )}
                        options={HWMOption}
                        onTypeChange={(role) => {
                          let x = "";
                          if (role == "Yes") x = "Y";
                          else x = "N";
                          handleInputChange1(`hwm${selectedBrokerIndex}`, x);
                        }}
                        // error={errors.user_role}
                      />
                      <CustomInputFile
                        label="Gst"
                        name={`gst${selectedBrokerIndex}`}
                        type="number"
                        maxlength="10"
                        value={formData.brokerArray[selectedBrokerIndex].gst}
                        onchange={handleInputChange1}
                        error={errors.gst[selectedBrokerIndex]}
                        placeholder="E.g. 10"
                      />
                      <CustomInputFile
                        label="Perf-Fee"
                        name={`perf_fee${selectedBrokerIndex}`}
                        type="number"
                        maxlength="10"
                        value={
                          formData.brokerArray[selectedBrokerIndex].perf_fee
                        }
                        onchange={handleInputChange1}
                        error={errors.perf_fee[selectedBrokerIndex]}
                        placeholder="E.g. 1"
                      />
                    </div>
                    {formData.brokerArray.length > 1 && (
                      <button
                        className="broker-tab delete"
                        onClick={() => showConfirmBox(index)}
                      >
                        Delete Broker
                      </button>
                    )}
                  </div>
                );
              } else {
                return null; // Skip rendering for other indexes
              }
            })}
          </div>
        </div>
        <div className="login-footer extra">
          {formData.status === "Activated" ? (
            <></>
          ) : (
            <div
              className="swift-button-icon"
              onClick={() => {
                if (ValidateAll()) return;
                openCustomerId();
              }}
              disabled={formData.status === "Activated"}
            >
              <div className="button-icon">
                <ArrowRight2 size={20} />
              </div>
              <p>Submit And Activate</p>
            </div>
          )}

          <div className="swift-button-icon" onClick={handleSubmit}>
            <div className="button-icon">
              <ArrowRight2 size={20} />
            </div>
            <p>Save</p>
          </div>
        </div>

        {/* {deleteok !== undefined &&
          deleteok !== null &&
          ConfirmBox({
            title: "Delete Broker",
            description: (
              <>
                <>Are you sure you want to Delete the Broker ?</>
              </>
            ),
            properties: [
              {
                id: "2",
                label: "Delete",
                color: "#192b40",
                bgColor: "#ffffff",
                handleFunction: (callback) => {
                  DeleteBrokerHandler(deleteok);
                  callback();
                },
              },
            ],
            cancel: true,
          })} */}
        {popupImage && (
          <SwiftModal closeModal={closePopup} top="2%">
            <div className="popup-content">
              <img src={popupImage} alt="PAN Image" />
            </div>
          </SwiftModal>
        )}
      </div>

      {isCustomerId && (
        <SwiftModal closeModal={closeCustomerId} top="2%">
          <div className="swift-account-form swift-customer-form">
            {loading ? (
              <>
                <div className="loader">
                  <Pulse />
                  <p>Creating Account</p>
                </div>
              </>
            ) : (
              <>
                <div className="swift-account-form-header">
                  <div className="swift-account-form-title">Customer Id</div>
                  <div className="swift-account-svg" onClick={closeCustomerId}>
                    <Cross size={30} color={COLOR_VARS["SWIFT_COLOR4"]} />
                  </div>
                </div>
                <div className="swift-account-form-body">
                  <div className="swift-account-form-body-content-extra popup-customer_id-form">
                    <div className="swift-account-form-body-content">
                      <CustomInputFile
                        label="Customer ID"
                        name="customer_id"
                        type="text"
                        value={formData["customer_id"]}
                        onchange={handleInputChange}
                        error={errors.customer_id}
                      />
                      <CustomInputFile
                        label="Customer Password"
                        name="customer_password"
                        type="text"
                        value={formData["customer_password"]}
                        onchange={handleInputChange}
                        error={errors.customer_password}
                      />
                    </div>
                  </div>
                  <div className="login-footer">
                    <div
                      className="swift-button-icon"
                      onClick={handleSubmitActivate}
                    >
                      <div className="button-icon">
                        <ArrowRight2 size={20} />
                      </div>
                      <p>Submit</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </SwiftModal>
      )}

      {schemePopup && (
        <SwiftModal closeModal={() => setSchemePopup(false)} top="2%">
          <div className="swift-account-form swift-customer-form">
            {loading ? (
              <>
                <div className="loader">
                  <Pulse />
                  <p>Creating Account</p>
                </div>
              </>
            ) : (
              <>
                <div className="swift-account-form-header">
                  <div className="swift-account-form-title">
                    Portfolio Scheme
                  </div>
                  <div
                    className="swift-account-svg"
                    onClick={() => setSchemePopup(false)}
                  >
                    <Cross size={30} color={COLOR_VARS["SWIFT_COLOR4"]} />
                  </div>
                </div>
                <div className="swift-account-form-body">
                  <div className="swift-account-form-body-content-extra popup-customer_id-form">
                    <div className="swift-account-form-body-content">
                      <CustomInputFile
                        label="Portfolio Scheme"
                        name="new_scheme"
                        type="text"
                        value={formData["new_scheme"]}
                        onchange={handleInputChange}
                        error={errors.new_scheme}
                      />
                    </div>
                  </div>
                  <div className="login-footer">
                    <div className="swift-button-icon" onClick={AddSchema}>
                      <div className="button-icon">
                        <ArrowRight2 size={20} />
                      </div>
                      <p>Submit</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </SwiftModal>
      )}
    </div>
  );
};

export default DisplayForm;
