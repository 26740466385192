import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { GetCookie } from "../../exports/CookieManagement";
import Pulse from "../Loader/Pulse";
import {
  ArrowRight2,
  Cross,
  UserSearch,
} from "../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../exports/ColorVars";
import CustomIconButton from "../Body/CustomChartComponents/CustomIconButton/CustomIconButton";
import SwiftModal from "../CustomComponents/SwiftModal/SwiftModal";
import { formateDate } from "../../exports/UtilFunctions";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function Customers() {
  const [loading, setLoading] = useState(true);
  const [customers, setCustomers] = useState("");
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const account_token = GetCookie("token");
    axios
      .get(`${REQUEST_BASE_URL}/client/get-customers?token=${account_token}`)
      .then((response) => {
        const data = response.data;
        if (data.error) {
          setLoading(false);
        } else {
          setLoading(false);
          setCustomers(data.data);
        }
      });
  }, []);

  const openLinkInNewTab = async (url) => {
    if (url) {
      const encodedUrl = encodeURIComponent(url);

      window.open(`/admin/image/${encodedUrl}`, "_blank");
    }
  };

  return (
    <div className="client-customers-table">
      <p className="title">Customers</p>
      {loading ? (
        <>
          <div className="customers-table loading">
            <Pulse />
            <p>Loading customers...</p>
          </div>
        </>
      ) : (
        <>
          {customers.length === 0 ? (
            <>
              <div className="customers-table empty">
                <UserSearch size={40} color={COLOR_VARS["SWIFT_COLOR4"]} />
                <p>You don't have any customers</p>
              </div>
            </>
          ) : (
            <>
              <div className="customers-table">
                <table>
                  <thead>
                    <tr>
                      <td>PAN Number</td>
                      <td>Name</td>
                      <td>Email Address</td>
                      <td>Customer Type</td>
                      <td>Activation Date</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {customers.map((c, index) => {
                      return (
                        <tr key={index}>
                          <td>{c.pan}</td>
                          <td>{c.name}</td>
                          <td>{c.email}</td>
                          <td>{c.role_name}</td>
                          <td>{formateDate(c.input_timestamp)}</td>
                          <td>
                            <button
                              className="customers-table-view-btn"
                              onClick={() => {
                                setData(c);
                                setShowModal(true);
                              }}
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      )}

      {showModal && (
        <SwiftModal
          closeModal={() => {
            setShowModal(false);
            setData({});
          }}
          top="2%"
        >
          <div className="swift-superuser-customer-popup">
            <div className="swift-superuser-customer-header">
              <div
                className="swift-account-svg"
                onClick={() => {
                  setShowModal(false);
                  setData({});
                }}
              >
                <Cross size={30} color={COLOR_VARS["SWIFT_COLOR4"]} />
              </div>
            </div>

            <div className="swift-superuser-customer-popup-body">
              <div className="swift-superuser-customer-popup-body-row">
                <span>Status : </span>
                <p>{data.status}</p>
              </div>
              <div className="swift-superuser-customer-popup-body-row">
                <span>Pan : </span>
                <p>{data.pan}</p>
              </div>
              <div className="swift-superuser-customer-popup-body-row">
                <span>Name : </span>
                <p>{data.name}</p>
              </div>
              <div className="swift-superuser-customer-popup-body-row">
                <span>Mobile : </span>
                <p>{data.mobile}</p>
              </div>
              <div className="swift-superuser-customer-popup-body-row">
                <span>Email : </span>
                <p>{data.email}</p>
              </div>
              <div className="swift-superuser-customer-popup-body-row">
                <span>User Role : </span>
                <p>{data.role_name}</p>
              </div>
              <div className="swift-superuser-customer-popup-body-row">
                <span>Aadhar No : </span>
                <p>{data.aadhaar_no}</p>
              </div>
              <button
                className="swift-superuser-customer-popup-btn"
                onClick={() => openLinkInNewTab(data.aadhaar_image)}
              >
                View Aadhar Image
              </button>
              <button
                className="swift-superuser-customer-popup-btn"
                onClick={() => openLinkInNewTab(data.pan_image)}
              >
                View Pan Image
              </button>
              {data.brokerArray[0].broker ? (
                <div className="brokers-table ">
                  <table>
                    <thead>
                      <tr>
                        <td>Broker Name</td>
                        <td>CP Code</td>
                        <td>Trading Code</td>
                        <td>Brokerage</td>
                        <td>STT</td>
                        <td>Stamp Duty</td>
                        <td>Performance Fees</td>
                      </tr>
                    </thead>
                    <tbody>
                      {data.brokerArray.map((c, index) => {
                        return (
                          <tr key={index}>
                            <td>{c.broker}</td>
                            <td>{c.cpcode || "--"}</td>
                            <td>{c.trading_code || "--"}</td>
                            <td>{c.brokerage}</td>
                            <td>{c.stt}</td>
                            <td>{c.stamp_duty}</td>
                            <td>{c.perf_fee}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="brokers-table empty">
                  <p>You don't have any brokers</p>
                </div>
              )}
            </div>
          </div>
        </SwiftModal>
      )}
    </div>
  );
}

export default Customers;
