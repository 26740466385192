import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useSearchParams, Link } from "react-router-dom";
import "../../css/MenuSection/FamilyPortfolios.css";
import { Add, Cards } from "../CustomComponents/SwiftIcon/Icons";
import SwiftModal from "../CustomComponents/SwiftModal/SwiftModal";
import SwiftToolTip from "../CustomComponents/SwiftToolTip/SwiftToolTip";
import FamilyCustomerAdd from "./FamilyCustomerAdd";
import NewFamilyPortfolio from "./NewFamilyPortfolio";
import RenameFamilyPortfolio from "./RenameFamilyPortfolio";
import { GetCookie } from "../../exports/CookieManagement";
import FamilyPortfolioDetails from "./FamilyPortfolioDetails";
import Pulse from "../Loader/Pulse";
import { COLOR_VARS } from "../../exports/ColorVars";
import useQueryParams from "../../hooks/useQueryParams";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function FamilyPortfolios() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { portfolio_id = false } = useQueryParams();

  const [renameOpen, setRenameOpen] = useState(false);
  const [newOpen, setNewOpen] = useState(false);
  const [customerOpen, setCustomerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [portfolios, setPortfolios] = useState([]);
  const [portfolio, setPortfolio] = useState(false);
  const [updateData, setUpdateData] = useState(false);

  useEffect(() => {
    if (!portfolio_id || !Array.isArray(portfolios) || portfolios.length === 0)
      return;
    const selected_portfolio = portfolios.find((f) => {
      return f["family_portfolio_id"] == portfolio_id;
    });

    if (!selected_portfolio) return;
    setPortfolio(selected_portfolio);
  }, [portfolio_id, portfolios]);

  
  useEffect(() => {
    if (!portfolio_id && Array.isArray(portfolios) && portfolios.length > 0) {
      SetPortfolioID(portfolios[0].family_portfolio_id);
    }
  }, [portfolios]);

  useEffect(() => {
    GetFamilyPortfolios();
  }, [updateData]);

  function SetPortfolioID(id) {
    searchParams.set("portfolio_id", id);
    setSearchParams(searchParams);
  }

  function update(refreshPortfolio = true) {
    setUpdateData(!updateData);
    if (refreshPortfolio) setPortfolio(false);
  }

  function GetFamilyPortfolios() {
    const account_token = GetCookie("token");

    Axios.get(`${REQUEST_BASE_URL}/family?token=${account_token}`)
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          setPortfolios(data.family_portfolios);
          setLoading(false);
        } else {
          setPortfolios([]);
          setLoading(false);
        }
      })
      .catch(() => {
        setPortfolios([]);
        setLoading(false);
      });
  }

  return (
    <>
      <div className="family-portfolios">
        {loading ? (
          <>
            <div className="family-portfolios-loading">
              <Pulse />
              <p>Loading family portfolios...</p>
            </div>
          </>
        ) : (
          <>
            <div className="family-portfolios-left">
              <div className="family-portfolios-header">
                <p>Family Portfolios</p>
                <SwiftToolTip title="Add new portfolio" placement="bottom">
                  <div
                    className="family-portfolios-add"
                    onClick={() => {
                      setNewOpen(true);
                    }}
                  >
                    <Add size={20} />
                  </div>
                </SwiftToolTip>
              </div>
              {portfolios.length > 0 ? (
                <div className="family-portfolios-wrapper">
                  <>
                    {portfolios.map((p, index) => {
                      return (
                        <div
                          key={index}
                          className="family-portfolio-card"
                          onClick={() => {
                            SetPortfolioID(p.family_portfolio_id);
                          }}
                        >
                          <div className="family-card-header">
                            <p>{p.family_portfolio_name}</p>
                          </div>
                          <div className="family-card-details">
                            <p>
                              Total Customers:{" "}
                              {p.family_portfolio_customers.length}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </>
                </div>
              ) : (
                <>
                  <div className="family-portfolios-empty">
                    <Cards size={60} color={COLOR_VARS["SWIFT_COLOR4"]} />
                    <p>You don't have any family portfolio</p>
                    <button
                      onClick={() => {
                        setNewOpen(true);
                      }}
                    >
                      New Family Portfolio
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className="family-portfolios-right">
              {portfolio ? (
                <>
                  <FamilyPortfolioDetails
                    portfolio={portfolio}
                    setRenameOpen={setRenameOpen}
                    setCustomerOpen={setCustomerOpen}
                    update={update}
                  />
                </>
              ) : (
                <>
                  <div className="family-portfolio-details-default">
                    <h2>Explore collective performance</h2>
                    <p>Construct and analyze integrated portfolios</p>
                    <button
                      onClick={() => {
                        setNewOpen(true);
                      }}
                    >
                      New Family Portfolio
                    </button>
                  </div>
                </>
              )}
            </div>

            {renameOpen && (
              <>
                <SwiftModal
                  closeModal={() => {
                    setRenameOpen(false);
                  }}
                >
                  <RenameFamilyPortfolio
                    portfolio={portfolio}
                    close={() => {
                      setRenameOpen(false);
                    }}
                    update={update}
                  />
                </SwiftModal>
              </>
            )}

            {newOpen && (
              <>
                <SwiftModal
                  closeModal={() => {
                    setNewOpen(false);
                  }}
                >
                  <NewFamilyPortfolio
                    close={() => {
                      setNewOpen(false);
                    }}
                    update={update}
                  />
                </SwiftModal>
              </>
            )}

            {customerOpen && (
              <>
                <SwiftModal
                  closeModal={() => {
                    setCustomerOpen(false);
                  }}
                >
                  <FamilyCustomerAdd
                    portfolio={portfolio}
                    close={() => {
                      setCustomerOpen(false);
                    }}
                    update={update}
                  />
                </SwiftModal>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default FamilyPortfolios;
