import { useEffect, useRef, useState } from "react";
import { readFullSnapQuoteData } from '../exports/FormatData';
import { GetStockConfig } from "../exports/GetStockConfig";

const LIVEFEED_BASE_URL = process.env.REACT_APP_LIVEFEED_BASE_URL;
let WEBSOCKET_INTERVAL = false;

const useFullSnapQuoteNew = (symbol) => {
    const wsRef = useRef(null);
    const configRef = useRef(null);

    const [connection, setConnection] = useState(false);
    const [stockData, setStockData] = useState(null);
    const [configLoaded, setConfigLoaded] = useState(true);

    useEffect(() => {
        GetWebSocketConnection();
        document.addEventListener("visibilitychange", handleVisibilityChange);
        return () => {
            CloseWebSocketConnection();
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        const fetchdata = async () => {
            setConfigLoaded((prev) => !prev)

            if (!symbol || symbol == "") return;
            configRef.current = null;

            try {
                const config = await GetStockConfig(symbol);
                configRef.current = config;
            } catch {
                configRef.current = false;
            } finally {
                setConfigLoaded((prev) => !prev)
            }

        }
        fetchdata();

        return () => {
            CloseWebSocketData();
        }

    }, [symbol]);

    useEffect(() => {
        if (!configRef.current) return;

        GetWebSocketData();
    }, [connection, configLoaded]);

    const handleVisibilityChange = () => {
        const ws = wsRef.current;
        if (ws.readyState === 3) {
            CloseWebSocketData();
            CloseWebSocketConnection();
            wsRef.current = false;
            setConnection(false);
            GetWebSocketConnection();
            // GetWebSocketData();
        }
    };

    function GetWebSocketConnection() {
        // console.log("===== GET WEBSOCKET CONNECTION =====");
        // console.log("CONNECTION STATUS is", connection, wsRef);

        if (connection || wsRef?.current) return;

        // console.log("CREATE NEW WEBSOCKET CONNECTION");
        let socket = new WebSocket(LIVEFEED_BASE_URL);
        wsRef.current = socket;

        socket.onopen = () => {
            // console.log("OPEN ON WEBSOCKET");
            // console.log('===== CONNECTION ESTABLISHED =====')
            setConnection(true);
        };

        socket.onclose = () => {
            // console.log("CLOSE ON WEBSOCKET");
            wsRef.current = false;
            setConnection(false);
        };

        socket.onerror = () => {
            // console.log("ERROR ON WEBSOCKET");
            wsRef.current = false;
            setConnection(false);
        };
    }

    function GetWebSocketData() {
        // console.log('GET SOCKET DATA FOR', symbol)
        // console.log(connection, wsRef);
        if (!connection || !wsRef.current || wsRef.current.readyState !== 1 || !configRef.current) return;


        const ws = wsRef.current;
        const config = configRef.current;

        ws.onmessage = (response) => {
            var reader = new FileReader();
            reader.readAsArrayBuffer(response.data);

            let convertedData;

            reader.onloadend = async (event) => {
                let data = new Uint8Array(reader.result);

                if (response.data.size >= 166) {
                    convertedData = readFullSnapQuoteData(data)

                    let livedata = convertedData.livedata;
                    setStockData(livedata);
                }
            };
        };

        ws.send(
            JSON.stringify({
                a: "subscribe",
                v: [[config.exchange, config.code]],
                m: "full_snapquote",
            })
        );

        ws.onclose = () => {
            // console.log('CLOSE EVENT FIRE IN WEBSCOKET FOR', symbol)
            clearInterval(WEBSOCKET_INTERVAL);
        };

        WEBSOCKET_INTERVAL = setInterval(() => {
            if ((!ws && WEBSOCKET_INTERVAL) || ws.readyState === 3) {
                clearInterval(WEBSOCKET_INTERVAL);
                return;
            }
            ws.send(
                JSON.stringify({
                    a: "h",
                    v: [[config.exchange, config.code]],
                    m: "",
                })
            );
        }, 10 * 10 * 1000 * 1000);
    }

    function CloseWebSocketData() {

        console.log('CLOSE SOCKET DATA FOR', symbol);
        if (!wsRef.current || wsRef.current.readyState !== 1 || !configRef.current) return;

        const ws = wsRef.current;
        const config = configRef.current;

        ws.send(
            JSON.stringify({
                a: "unsubscribe",
                v: [[config.exchange, config.code]],
                m: "full_snapquote",
            })
        );

        if (WEBSOCKET_INTERVAL) {
            clearInterval(WEBSOCKET_INTERVAL);
        }

    }

    function CloseWebSocketConnection() {
        // console.log("===== CLOSE WEBSOCKET CONNECTION =====");

        const ws = wsRef.current;

        // console.log("WEB SOCKET =>", ws);

        if (!ws) return;
        // console.log("CLOSE SOCKET");

        if (ws.readyState === 1) {
            ws.close();
            setConnection(false);
        }
    }

    return stockData;
};

export default useFullSnapQuoteNew;
