import React, { Component } from "react";
import Axios from "axios";
import CustomButton from "../../CustomChartComponents/CustomButton/CustomButton";
import PortfolioSearchBar from "../PortfolioComponents/SearchBar";
import Cross from "../../../../assets/icons/cross.svg";
import { Alert } from "../../CustomChartComponents/CustomAlert/CustomAlert";
import download from "../../../../stockImport.csv";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { GetCookie } from "../../../../exports/CookieManagement";
import useStockInfo from "../../../../hooks/useStockInfo";
import ConfirmBox from "../../../CustomComponents/ConfirmBox/ConfirmBox";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const MINIMUM_CASH_BUFFER = 0;

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

class AddBackTestStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      account_token: GetCookie("token"),
      smallcase_id: this.props.smallcase_id,
      data: [],
      isBacktesting: false,
      isIndexLoaded: false,
      table1: [],
      table2: [],
      table3: [],
      index: [],
      opacityMsg: true,
    };

    this.CsvRow = this.CsvRow.bind(this);
    this.addTableRow = this.addTableRow.bind(this);
    this.addPortfolioWeight = this.addPortfolioWeight.bind(this);
    this.importFile = this.importFile.bind(this);
    this.deleteStock = this.deleteStock.bind(this);
    this.CreateSmallCase = this.CreateSmallCase.bind(this);
    this.inputFocusOut = this.inputFocusOut.bind(this);
  }

  componentDidMount() {
    let { portfolios, createdDate } = this.props.state;

    if (portfolios.length > 0) {
      portfolios.forEach((element) => {
        element.PortfolioWeight = parseFloat(element.PortfolioWeight);
      });
    } else {
      portfolios = [
        {
          StockCode: "CASH",
          StockName: "-",
          StockExchange: "-",
          PortfolioWeight: "100",
          isin: "CASH",
          dateCreated: new Date(createdDate),
        },
      ];
    }

    this.setState({ data: portfolios });
  }

  CsvRow({ importFile }) {
    return (
      <div className="strategy__stock__buttons">
        <div onClick={importFile}>
          Import csv
          {/* <img src={UpArrow} alt="UpArrow" style={{width: 10, marginLeft: 2}} /> */}
        </div>
        <div>
          <a href={download} download="format.csv">
            Download Format
          </a>
          {/* <img src={DownArrow} alt="DownArrow" style={{width: 10, marginLeft: 2}} /> */}
        </div>
      </div>
    );
  }

  deleteStock(index) {
    let { data } = this.state;

    let deleteStockWeight = parseFloat(data[index].PortfolioWeight);

    console.log(deleteStockWeight);

    const new_cash_weight = parseInt(data[0].PortfolioWeight) + deleteStockWeight;

    data[0].PortfolioWeight = new_cash_weight;

    // let weightSum = 0;
    // for (let i = 0; i < data.length; i++)
    //   weightSum += parseFloat(data[i].PortfolioWeight);

    //   console.log(weightSum)

    // if (weightSum - deleteStockWeight <= 100)
    //   data[0].PortfolioWeight += 100 - (weightSum - deleteStockWeight);

    data.splice(index, 1);

    this.setState({ data });
  }

  addTableRow(stock) {
    let { data } = this.state;

    const exchange = stock.exchange.exchange || "NSE";

    let code = exchange == "NSE" ? stock.nse_code : stock.code;

    if (!data.map((el) => el.StockCode).includes(code)) {
      const obj = {
        StockCode: code,
        StockName: stock.name,
        StockExchange: exchange.toUpperCase(),
        PortfolioWeight: "0",
        isin: stock.isin,
        dateCreated: data[0].dateCreated,
      };

      data.push(obj);
      this.setState({ data });
    }
  }

  addPortfolioWeight(value, index) {
    let { data } = this.state;

    if (index === 0) return;

    if(value < 0) return;

    function WeightSum(data) {
      let total_weight = 0;
      for (let i = 1; i < data.length; i++) {
        let weight = parseInt(data[i].PortfolioWeight) || 0;
        total_weight += weight;
      }
      return total_weight;
    }

    const current_cash_weight = parseInt(data[0].PortfolioWeight);
    let old_weight = parseInt(data[index].PortfolioWeight);
    let new_weight = value;

    if (isNaN(old_weight)) {
      old_weight = 0;
    }

    if (new_weight === "") {
      data[index]["PortfolioWeight"] = new_weight;
      let total_weight = WeightSum(data);
      data[0]["PortfolioWeight"] = 100 - total_weight;
      this.setState({ data });
      return;
    }

    new_weight = parseInt(new_weight);

    if (isNaN(new_weight) && new_weight != "") {
      return;
    }

    const diff = new_weight - old_weight;
    const remaining_cash_weight = current_cash_weight - diff;


    if (remaining_cash_weight >= MINIMUM_CASH_BUFFER) {
      data[index]["PortfolioWeight"] = new_weight;
      data[0]["PortfolioWeight"] = remaining_cash_weight;
      this.setState({ data });
    } else {
      this.displayAlert(
        `Weight of cash cannot be less than ${MINIMUM_CASH_BUFFER}%`
      );
    }
  }

  inputFocusOut() {
    let { data } = this.state;
    for (let i = 0; i < data.length; i++) {
      if (data[i].PortfolioWeight == "") {
        data[i].PortfolioWeight = 0;
      }
    }

    this.setState({ data });
  }

  importFile(e) {
    e.preventDefault();
    const fileSelector = document.createElement("input");
    fileSelector.setAttribute("type", "file");
    fileSelector.setAttribute("accept", ".csv, .xls, .xlsx");
    fileSelector.click();

    fileSelector.onchange = (evt) => {
      const file = evt.target.files[0];
      const reader = new FileReader();

      reader.readAsBinaryString(file);
      reader.onload = (event) => {
        const doc = event.target.result;
        this.validateCsv(doc);
      };
    };
  }

  async validateCsv(doc) {
    let row = doc.split("\r\n");
    if (row[row.length - 1] == "") row.pop();

    let newData = [];

    let col = row[0].split(",");

    if (col[0] != "STOCKCODE" || col[1] != "WEIGHT%") {
      this.displayAlert("File import not in correct format.");
      return;
    } else {
      col = row[1].split(",");
      if (col[0] != "CASH") {
        this.displayAlert("CASH needs to be in first row.");
        return;
      } else if (parseFloat(col[1]) < MINIMUM_CASH_BUFFER) {
        this.displayAlert(`Minimum CASH percentage cannot be less than ${MINIMUM_CASH_BUFFER}%.`);
        return;
      } else {
        for (let i = 1; i < row.length; i++) {
          col = row[i].split(",");
          if (isNaN(parseFloat(col[1]))) continue;

          newData.push({
            StockCode: col[0],
            PortfolioWeight: parseFloat(col[1]),
          });
        }
      }
    }

    let weightSum = 0;
    for (let i = 0; i < newData.length; i++)
      weightSum += parseFloat(newData[i].PortfolioWeight);

    if (weightSum != 100) this.displayAlert("Total weight must be 100%.");
    else {
      newData = (
        await Axios.post(`${REQUEST_BASE_URL}/validate_import`, newData)
      ).data.portfolio;
      if (!newData)
        this.displayAlert("Please check the STOCKCODE and try again.");
      else this.setState({ data: newData });
    }
  }

  async CreateSmallCase() {
    const account_token = GetCookie("token");
    const { data: portfolio } = this.state;
    const { strategyDetails, smallCaseHome, updateStrategy } = this.props;
    const {
      smallcase_id,
      isStrategyExist,
      createdDate,
      strategy,
      methodology,
      objective,
      frequency,
      benchmark,
      price,
    } = strategyDetails;

    if (strategy == "") {
      this.displayAlert("Please enter name of the strategy");
      return;
    } else if (objective.length < 20) {
      this.displayAlert("Objective must have atleast 20 letters");
      return;
    } else if (methodology.length < 20) {
      this.displayAlert("Methodology must have atleast 20 letters");
      return;
    }

    let isZero = false;
    portfolio.forEach((p) => {
      console.log(p["PortfolioWeight"])
      if (p["PortfolioWeight"] === 0) {
        isZero = true;
      }
    });

    if (isZero) {
      this.displayAlert("Cash weight can not be 0");
      return;
    }

    if (portfolio.length < 4) {
      this.displayAlert("There should be atleast 3 stocks in the strategy");
      return;
    }

    ConfirmBox({
      title: "Create Strategy",
      description: <>Are you sure you want to proceed?</>,
      properties: [
        {
          id: "2",
          label: "Proceed",
          color: "#192b40",
          bgColor: "#ffffff",
          handleFunction: (callback) => {
            const withBacktest = false;
            const index = null;

            const payload = {
              smallcase_id,
              isStrategyExist,
              createdDate,
              strategy,
              methodology,
              objective,
              frequency,
              benchmark,
              price,
              portfolio,
              withBacktest,
              index,
            };

            Axios.post(
              `${REQUEST_BASE_URL}/update_smallCase_details?token=${account_token}`,
              payload
            )
              .then((response) => {
                const data = response.data;
                if (!data.error) {
                  updateStrategy();
                } else {
                  if(data.exists){
                    this.displayAlert("The strategy name you selected already exists.");
                  }
                  else{
                    this.displayAlert("Something went wrong while creating strategy!");
                  }
                }
              })
              .catch((err) => {
                this.displayAlert(
                  "Something went wrong while creating strategy!"
                );
              })
              .finally(() => {
                callback();
              });
          },
        },
      ],
      cancel: true,
    });

  }

  displayAlert = (msg) => {
    Alert({
      TitleText: "Warning",
      Message: msg,
      AutoClose: {
        Active: true,
        Line: true,
        LineColor: "#470985",
        Time: 3,
      },
    });
  };

  render() {
    const { smallCaseHome } = this.props;
    const { data, smallcase_id } = this.state;

    return (
      <>
        {!this.state.isIndexLoaded && !this.state.isBacktesting && (
          <>
            <div className="smallcase__strategy__table">
              <div className="strategy__portfolio__stock__wrapper">
                <PortfolioSearchBar addTableRow={this.addTableRow} />
                <div className="strategy__portfolio__stock__options">
                  <span onClick={this.importFile}>Import File</span>
                  <span>
                    <a href={download} download="format.csv">
                      Download Format
                    </a>
                  </span>
                </div>
              </div>
              <div className="strategy__portfolio__table__wrapper">
                <div className="strategy__portfolio__table">
                  <table>
                    <thead>
                      <tr>
                        <td className="symbol">Symbol</td>
                        <td className="name">Stock Name</td>
                        <td className="exchange">Stock Exchange</td>
                        <td className="weight">Portfolio Weight&nbsp;(%)</td>
                        <td className="remove"></td>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((d, index) => {
                        return (
                          <StockRow
                            key={d.StockCode}
                            data={d}
                            index={index}
                            addPortfolioWeight={this.addPortfolioWeight}
                            inputFocusOut={this.inputFocusOut}
                            deleteStock={this.deleteStock}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="smallcase__strategy__footer">
              <CustomButton title="Cancel" onClick={smallCaseHome} />
              <CustomButton
                title={smallcase_id ? "Update Strategy" : "Create Strategy"}
                onClick={this.CreateSmallCase}
              />
            </div>
          </>
        )}
      </>
    );
  }
}

function StockRow({
  index,
  data,
  addPortfolioWeight,
  inputFocusOut,
  deleteStock,
}) {
  const { StockCode, PortfolioWeight } = data;
  const { loading, stock_info } = useStockInfo(StockCode);

  const inputChange = (e, index) => {
    const value = e.target.value;
    addPortfolioWeight(value, index);
  };

  const input_comp = () => {
    return (
      <>
        {StockCode === "CASH" ? (
          <>
            <div className="input-box">
              <input disabled value={PortfolioWeight} />
            </div>
          </>
        ) : (
          <>
            <div className="input-box">
              <span onClick={() => {addPortfolioWeight(PortfolioWeight - 1, index);}}>
                  <RemoveIcon fontSize="12" color="#192b40" />
              </span>
              <input 
                onChange={(e) => {inputChange(e, index);}}
                onBlur={() => {inputFocusOut();}}
                value={PortfolioWeight}
              />
              <span onClick={() => {addPortfolioWeight(PortfolioWeight + 1, index);}}>
                  <AddIcon fontSize="12" color="#192b40" />
              </span>
            </div>
          </>
        )}
      </>
    );
  };

  const remove_comp = () => {
    return (
      <>
        {StockCode === "CASH" ? (
          <span>(min {MINIMUM_CASH_BUFFER}%)</span>
        ) : (
          <span className="remove" onClick={() => deleteStock(index)}>
            <img src={Cross} alt="cross" />
          </span>
        )}
      </>
    );
  };

  if (loading) {
    return (
      <tr>
        <td>{StockCode}</td>
        <td></td>
        <td></td>
        <td>{input_comp()}</td>
        <td>{remove_comp()}</td>
      </tr>
    );
  }

  if (stock_info) {
    const { symbol, name, exchange } = stock_info;
    let exchange_name = exchange.exchange;

    return (
      <>
        <tr>
          <td>{symbol}</td>
          <td>{name}</td>
          <td>{exchange_name}</td>
          <td>{input_comp()}</td>
          <td>{remove_comp()}</td>
        </tr>
      </>
    );
  }

  return (
    <tr>
      <td>{StockCode}</td>
      <td>-</td>
      <td>-</td>
      <td>{input_comp()}</td>
      <td>{remove_comp()}</td>
    </tr>
  );
}

export default AddBackTestStock;
