import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./css/BusinessNews/index.css";
import "./css/App.css";
import "./css/global.css";
import "./css/CustomComponents/SwiftComponents.css";
import Auth from "./components/AccountComponents/Auth";
import HeroPage from "./components/HeroPage";
import MainPage from "./components/MainPage";
import Login from "./components/AccountComponents/Login";
import Smallcase from "./components/Smallcase/Smallcase";
import HeroAbout from "./components/HeroComponents/HeroAbout";
import HeroTrading from "./components/HeroComponents/HeroTrading";
import HeroReach from "./components/HeroComponents/HeroReach";
import HeroHelp from "./components/HeroComponents/HeroHelp";
import HeroFAQ from "./components/HeroComponents/HeroFAQ";
import AccessManagement from "./components/AccessManagement/AccessManagement";
import AdminManagement from "./components/AdminManagement/AdminManagement";
import StocksManagement from "./components/StocksManagement/StocksManagement";
import NotFound from "./components/AccountComponents/NotFound";
import BrokerManagement from "./components/BrokerManagement/BrokerManagement";
import CustomerManagement from "./components/CustomerManagement/CustomerManagement";
import BackofficeManagement from "./components/Backoffice/BackofficeManagement";

function App() {
  return (
    <>
      <div className="app">
        <Router>
          <Routes>
            <Route path="/" element={<HeroPage />}></Route>
            <Route path="/404" element={<NotFound />}></Route>
            <Route
              path="/account/*"
              element={
                <Auth>
                  <MainPage />
                </Auth>
              }
            ></Route>
            <Route path="/customer/*" element={<CustomerManagement />} />
            <Route path="broker/*" element={<BrokerManagement />} />
            <Route path="/access/*" element={<AccessManagement />} />
            <Route path="/login" exact element={<Login />}></Route>
            <Route
              path="/backoffice/*"
              exact
              element={<BackofficeManagement />}
            />
            <Route path="/admin/*" element={<AdminManagement />}></Route>
            <Route path="/smallcase" exact element={<Auth></Auth>}></Route>
            <Route path="/about" element={<HeroAbout />}></Route>
            <Route path="/trading" element={<HeroTrading />}></Route>
            <Route path="/reachus" element={<HeroReach />}></Route>
            <Route path="/help" element={<HeroHelp />}></Route>
            <Route path="/FAQ" element={<HeroFAQ />}></Route>
            <Route path="/stocks" element={<StocksManagement />}></Route>
            <Route path="/*" element={<NotFound />}></Route>
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
