import React, { useState, useRef } from "react";
import axios from "axios";
import { ArrowRight2, Cross } from "../../CustomComponents/SwiftIcon/Icons";
import { Alert } from "../../Body/CustomChartComponents/CustomAlert/CustomAlert";
import SwiftModal from "../../CustomComponents/SwiftModal/SwiftModal";
import CustomIconButton from "../../Body/CustomChartComponents/CustomIconButton/CustomIconButton";
import { COLOR_VARS } from "../../../exports/ColorVars";
import Pulse from "../../Loader/Pulse";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function DisplayMessage(title, message) {
  Alert({
    TitleText: title,
    Message: message,
    Band: true,
    BandColor: "#67D098",
    BoxColor: "#ffffff",
    TextColor: "#000000",
    AutoClose: {
      Active: true,
      Line: true,
      LineColor: "#67D098",
      Time: 3,
    },
  });
}

function UploadTrades({ closeUpload, updateCount }) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const inputFile = useRef(null);

  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    const formData = new FormData();
    formData.append("trade_file", file);

    setLoading(true);

    axios
      .post(`${REQUEST_BASE_URL}/trades/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          DisplayMessage(
            "Order Executed Successfully!",
            "Please check trades status for confirmation"
          );
        } else {
          let message =
            data.message || "Something went wrong while executing the orders";
          DisplayMessage("Order Execution Failure", message);
        }

        closePopup();
        updateCount();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function closePopup() {
    setFile(null);
    closeUpload();
  }

  return (
    <SwiftModal closeModal={closePopup} top="5%">
      <div className="swift-trades-uploads-wrapper">
        {loading && (
          <>
            <div className="swift-trades-loader">
              <Pulse />
            </div>
          </>
        )}
        <div className="swift-trades-upload">
          <div className="swift-upload-header">
            <p>Upload Executed Trades</p>
            <div className="upload-header-icon" onClick={closePopup}>
              <Cross size={24} color="#192b40" />
            </div>
          </div>
          <div className="swift-upload-content">
            <div className="browse-file">
              <form encType="multipart/form-data">
                <input
                  hidden
                  type="file"
                  name="trade-file"
                  id="trade-file"
                  accept=".xlsx"
                  onChange={handleChange}
                  ref={inputFile}
                />
              </form>
              <p className="message">
                Upload trade file (must be in .xlsx format)
              </p>
              <button
                className="file-browse-btn"
                onClick={() => inputFile.current.click()}
              >
                Choose File
              </button>
              <p className="uploaded-file">{file?.name}</p>
            </div>
            {file?.name && (
              <>
                <div className="upload-file">
                  <CustomIconButton
                    icon={
                      <ArrowRight2
                        size={20}
                        color={COLOR_VARS["SWIFT_COLOR4"]}
                      />
                    }
                    title="Upload Trades"
                    onClick={handleFileUpload}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </SwiftModal>
  );
}

export default UploadTrades;
