import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ServerRequest from "../../../exports/ServerRequest";
import SwiftModal from "../../CustomComponents/SwiftModal/SwiftModal";
import ConfirmBox from "../../CustomComponents/ConfirmBox/ConfirmBox";
import Pulse from "../../Loader/Pulse";
import { Edit, UserSearch } from "../../CustomComponents/SwiftIcon/Icons";
import { GetCookie } from "../../../exports/CookieManagement";
import { titleCase } from "../../../exports/UtilFunctions";
import { COLOR_VARS } from "../../../exports/ColorVars";

moment.updateLocale("language_code", { invalidDate: "-" });

function GetCustomerRoleTitle(role) {
  if (role == "BRC") return "Broking Customer";
  if (role == "MAC") return "Managed Customer";
  return "-";
}

const CustomerManagement = () => {
  const [popupImage, setPopupImage] = useState(null);
  const [data, setData] = useState([]);
  const [change, setChange] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState("");

  const token = GetCookie("admin_token");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data1 = await ServerRequest({
        url: `/admin/account/users?token=${token}`,
        method: "get",
      });
      if (data1.server_error) {
        navigate("/404", { state: { errorMessage: data1.message } });
      }

      const formattedData = data1.data.map((item) => {
        const sanitizedItem = Object.fromEntries(
          Object.entries(item).map(([key, value]) => [
            key,
            value === "" || value === "null" ? null : value,
          ])
        );

        const date = new Date(sanitizedItem.input_timestamp);
        return {
          ...sanitizedItem,
        };
      });

      setData(formattedData);

      setLoading(false);
    };
    fetchData();
    return () => {
      console.log("Cleanup");
    };
  }, [change]);

  const openPopup = async (imagePath) => {
    if (imagePath) {
      const encodedUrl = encodeURIComponent(imagePath);

      window.open(`/admin/image/${encodedUrl}`, "_blank");
    }
  };

  const closePopup = () => {
    setPopupImage(null);
  };
  const handleSubmit = async (item) => {
    const data = item;
    const myForm = new FormData();
    setLoading(true);

    Object.entries(data).forEach(([key, value]) => {
      myForm.append(key, value);
    });
    data.startemail = data.email;
    myForm.append("form", JSON.stringify(data));

    const data2 = await ServerRequest({
      url: `/admin/account/create?token=${token}`,
      data: myForm,
      method: "post",
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
    if (data2.server_error) {
      navigate("/404", { state: { errorMessage: data2.message } });
    }
    setLoading(false);
    setChange(Math.random());
  };

  const openForm = (item) => {
    if (item.status == "Deactivated" || item.role_type == "ADMIN") return;
    navigate(`/admin/form/${item.pan}`);
  };

  const showConfirmBox = (type, item) => {
    ConfirmBox({
      title: "Deactivate Customer Account",
      description: (
        <>
          <>Are you sure you want to {type.toLowerCase()} the account ?</>
        </>
      ),
      properties: [
        {
          id: "2",
          label: type,
          color: "#192b40",
          bgColor: "#ffffff",
          handleFunction: (callback) => {
            const data = item;
            data.status =
              data.status == "Deactivated" ? "Activated" : "Deactivated";
            handleSubmit(data);
            callback();
          },
        },
      ],
      cancel: true,
    });
  };

  const openconfirmPopup = (item) => {
    if (item.status == "Pending") return;
    showConfirmBox(
      item.status == "Deactivated" ? "Activate" : "Deactivate",
      item
    );
  };

  return !loading ? (
    <div className="swift-account-main open">
      <div className="swift-account-heading">
        <div className="select-search-wrapper">
          <div className="select-search-box">
            <input
              placeholder="Search Customer"
              value={filterData}
              onChange={(e) => setFilterData(e.target.value)}
            />
            <div className="filter-icon">
              <UserSearch size={20} color="#000" />
            </div>
          </div>
        </div>
      </div>

      {data.filter((item) =>
        item.name.toLowerCase().includes(filterData.toLowerCase())
      ).length > 0 ? (
        <div className="admin-wrapper full">
          <div className="admin-history-container admin-customer-container">
            <table>
              <thead>
                <tr>
                  <td className="pan">Customer PAN</td>
                  <td className="mobile">Mobile Number</td>
                  <td className="email">Email Address</td>
                  <td className="name">Customer Name</td>
                  <td className="pan-image">PAN Image</td>
                  <td className="registration-time">Registration Time</td>
                  <td className="status">Status</td>
                  <td className="role">Role</td>
                  <td className="edit"></td>
                  <td className="deactivate"></td>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) =>
                  item.name.toLowerCase().includes(filterData.toLowerCase()) ? (
                    <tr key={index}>
                      <td>{item.pan}</td>
                      <td>{item.mobile}</td>
                      <td>{item.primaryemail || item.email}</td>
                      <td>{titleCase(item.name)}</td>
                      <td>
                        <button
                          className="swift-account-btn"
                          onClick={() => openPopup(item.pan_image)}
                        >
                          View
                        </button>
                      </td>
                      <td>
                        {moment(item.input_timestamp).format(
                          "MMM DD, YYYY HH:mm:ss"
                        )}
                      </td>
                      <td>{item.status}</td>
                      <td>
                        {!item.user_role
                          ? "-"
                          : GetCustomerRoleTitle(item.user_role)}
                      </td>
                      <td>
                        <div
                          className="swift-account-svg"
                          onClick={
                            item.status === "Deactivated" ||
                            item.role_type === "ADMIN"
                              ? () => {}
                              : () => {
                                  openForm(item);
                                }
                          }
                        >
                          <Edit
                            size={18}
                            color={
                              item.status === "Deactivated" ||
                              item.role_type === "ADMIN"
                                ? COLOR_VARS["SWIFT_COLOR5"]
                                : COLOR_VARS["SWIFT_COLOR4"]
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <button
                          className={
                            "swift-account-btn " +
                            (item.status === "Pending" ? "disabled" : "")
                          }
                          onClick={() => {
                            openconfirmPopup(item);
                          }}
                        >
                          {item.status === "Deactivated"
                            ? "Activate"
                            : "Deactivate"}
                        </button>
                      </td>
                    </tr>
                  ) : null
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div
          className="swift-account-title"
          style={{
            alignSelf: "center",
            textAlign: "center",
            marginTop: "150px",
          }}
        >
          No Data Found
        </div>
      )}

      {popupImage && (
        <SwiftModal closeModal={closePopup} top="2%">
          <div className="popup-content">
            <img src={popupImage} alt="PAN Image" />
          </div>
        </SwiftModal>
      )}
    </div>
  ) : (
    <>
      <div className="admin-body-loader">
        <Pulse />
        <p>Loading data</p>
      </div>
    </>
  );
};

export default CustomerManagement;
