import React from 'react';

class HeroHelp extends React.PureComponent {
    render() {
        return (
            <div className="hero__body__help">
                <h1>Help</h1>
            </div>
        )
    }
}

export default HeroHelp;
