import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/HeroPages/NotFound.css';

class NotFound extends React.PureComponent {
    render() {
        return (
            <div className="app__notfound">
                <div className="notfound__content">
                    <Link to="/"><p className='brand'><i>swift</i>folios</p></Link>
                    <h2 className='notfound__heading'>404</h2>
                    <div className="notfound__title">
                        <p>Oops, nothing here...</p>
                    </div>
                    <p>We can't seem to find the page you are looking for.</p>
                    <p>Please visit the Homepage or Contact us for more information.</p>
                    <div className="notfound__buttons">
                        <button><Link to="/">Go to Home</Link></button>
                    </div>
                </div>
            </div>
        )
    }
}

export default NotFound;
