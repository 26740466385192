import { useEffect, useState, useRef } from 'react';
import { readMarketData } from '../exports/FormatData';
import { GetPendingOrders } from '../exports/GetPendingOrders';
import { GetBulkStockConfig } from '../exports/GetBulkStockConfig';

const LIVEFEED_BASE_URL = process.env.REACT_APP_LIVEFEED_BASE_URL;
let WEBSOCKET_INTERVAL = false;

const useLiveOrderValue = (account_code) => {

    const wsRef = useRef(null);
    const [connection, setConnection] = useState(false);
    const [config, setConfig] = useState(false);
    const [stockData, setStockData] = useState(false);
    const [orders, setOrders] = useState(false);
    const [totalOrderValue, setTotalOrderValue] = useState(0);

    useEffect(() => {

        GetPendingOrders(account_code).then((orders) => {
            const stock_data = (orders || []).map((o) => {
                return { ...o, last_traded_price: 0, trade_value: 0 }
            }).filter((o) => { return o.side === 'Buy' });
            setTotalOrderValue(0);
            setStockData(stock_data);
            setOrders(orders);
        });

        return () => {
            // console.log('CLOSING WEBSOCKET FOR', symbol);
            CloseWebSocket();
        }

    }, [account_code]);

    useEffect(() => {

        if (!orders) return;

        if (orders.length === 0) {
            setConfig([]);
            return;
        }

        GetConfig().then((config) => {

            if (stockData.length === 0) return;
            const stock_data = Array.isArray(stockData) && stockData.map((s) => {
                return { ...s, ...config[s.symbol] }
            });
            setStockData(stock_data);
            setConfig(config);
        });
    }, [orders]);

    useEffect(() => {

        if (!config) return;

        GetWebSocketConnection();
    }, [config]);

    useEffect(() => {
        GetWebSocketData();
    }, [connection]);


    function GetConfig() {
        return new Promise(async (resolve, reject) => {

            if (orders.length === 0) resolve(false);
            const symbol_array = (orders || []).map((o) => { return o.symbol });
            const config = await GetBulkStockConfig(symbol_array);
            resolve(config);
        });
    }

    function GetWebSocketConnection() {

        if (!config) return;

        let socket = new WebSocket(LIVEFEED_BASE_URL);

        socket.onopen = () => {
            wsRef.current = socket;
            setConnection(true);
        }

        socket.onerror = () => {
            wsRef.current = false;
            setConnection(false);
        }
    }

    function GetWebSocketData() {

        if (!connection || !wsRef.current || wsRef.current.readyState !== 1) return;

        if (Object.keys(config).length === 0) return;

        const ws = wsRef.current;

        const config_array = Object.keys(config).map((k) => { return [config[k].exchange, config[k].code] });

        ws.send(JSON.stringify(
            {
                "a": "subscribe",
                "v": config_array,
                "m": "marketdata"
            }
        )
        );

        ws.onmessage = (response) => {

            var reader = new FileReader();
            reader.readAsArrayBuffer(response.data);

            reader.onloadend = async (event) => {
                let data = new Uint8Array(reader.result);

                if (response.data.size >= 86) {
                    const convertedData = readMarketData(data, -1);

                    let livedata = convertedData.livedata;

                    if (!stockData || !Array.isArray(stockData) || stockData.length === 0) return;

                    for (let stock_index = 0; stock_index < stockData.length; stock_index++) {

                        const { code, side, quantity } = stockData[stock_index];
                        if (code === livedata.instrument_token) {
                            const last_traded_price = livedata.last_traded_price;
                            stockData[stock_index]['last_traded_price'] = livedata.last_traded_price;
                            stockData[stock_index]['trade_value'] = quantity * last_traded_price;
                        }
                    }

                    if (stockData.length === 0) return;

                    const trade_value_array = Array.isArray(stockData) && stockData.map((s) => { return s['trade_value'] })

                    if (trade_value_array.length === 0) return;


                    const order_value = (trade_value_array || []).reduce((a, b) => { return a + b }, 0);

                    setStockData(livedata);
                    setTotalOrderValue(order_value);
                }

            }
        }

        WEBSOCKET_INTERVAL = setInterval(() => {

            if (!ws && WEBSOCKET_INTERVAL) {
                clearInterval(WEBSOCKET_INTERVAL);
            }

            ws.send(JSON.stringify({
                "a": "h",
                "v": [[config.exchange, config.code]],
                "m": ""
            }
            ));
        }, 10 * 1000);

    }

    function CloseWebSocket() {

        const ws = wsRef.current;

        if (!ws) return;

        ws.close();
        setConnection(false);

        if (WEBSOCKET_INTERVAL) {
            clearInterval(WEBSOCKET_INTERVAL);
        };

    }

    return totalOrderValue;

}

export default useLiveOrderValue;