import React, { useState, useEffect } from "react";
import Axios from "axios";
import {
  BoxTick,
  Cross,
  Book,
  PlayCircle,
  TickCircle,
  ArrowRight2, ArrowDown2,
} from "../../../CustomComponents/SwiftIcon/Icons";
import CustomIconButton from "../../CustomChartComponents/CustomIconButton/CustomIconButton";
import CustomerTableRow from "./CustomerTableRow";
import { Alert } from "../../CustomChartComponents/CustomAlert/CustomAlert";
import Pulse from "../../../Loader/Pulse";
import { titleCase, priceFormat, numberFormat } from "../../../../exports/UtilFunctions";
import { GetCookie } from "../../../../exports/CookieManagement";
import useLiveOrderValue from "../../../../hooks/useLiveOrderValue";
import CustomValueSelect from "../../CustomChartComponents/CustomValueSelect/CustomValueSelect";
import { COLOR_VARS } from "../../../../exports/ColorVars";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function CustomerEntryRow({
  scheme_id,
  customer_config,
  smallcases,
  SetCustomerStrategy,
  RemoveCustomer,
  SetLiveCashPosition
}) {
  const { name,portfolio_value, cash_position, account_code,strategy } = customer_config;


  const order_value = useLiveOrderValue(account_code);

  const live_cash_position = cash_position - order_value;

  useEffect(() => {
    SetLiveCashPosition(scheme_id,account_code, live_cash_position);
  }, [order_value]);

  return (
    <>
      <tr>
        <td className="customer__config customer__name" title={titleCase(name)}>
          {titleCase(name)}
        </td>
        <td className="customer__config customer__value">
          {numberFormat(portfolio_value)}
        </td>
        <td className="customer__config customer__cash">
          {numberFormat(live_cash_position)}
        </td>
        <td className="customer__config customer__strategy">
          <CustomValueSelect value={strategy} options={smallcases} onChange={(value)=>{SetCustomerStrategy(scheme_id,account_code,value)}} />
        </td>
        <td
          className="customer__config customer__remove"
          onClick={() => {
            RemoveCustomer(scheme_id,account_code);
          }}
        >
          <Cross size={22} color="#192b40" />
        </td>
      </tr>
    </>
  );
}

function SmallcaseCustomerTable({
  schemes,
  smallcases,
  customers,
  removeCustomer,
  smallcaseName,
  smallcaseId,
  minimumCash,
  resetExecute,
  RemoveScheme,
  SetTradeWindow
}) {
  const [loading, setLoading] = useState(false);
  const [schemeConfig, setSchemeConfig] = useState([]);
  const [customerConfig, setCustomerConfig] = useState([]);
  const [tradesLoaded, setTradesLoaded] = useState(false);
  const [trades, setTrades] = useState([]);
  const [execute, setExecute] = useState(false);

  useEffect(() => {

    tradeWindow();

    if(smallcases.length === 0) return;

    let configArray = schemeConfig;

    if(configArray.length > schemes.length){
      setSchemeConfig([]);
      return;
    }

    for(let i=schemeConfig.length; i<schemes.length; i++){
      const scheme = schemes[i];
      const {scheme_portfolio_customers} = scheme;
      const customer_config = scheme_portfolio_customers.map((customer)=>{
        const {account_code,pan,name,portfolios_config,cash_virtual} = customer;

        let currentValueSum = 0;
        if(portfolios_config && Array.isArray(portfolios_config) && portfolios_config.length > 0){
          currentValueSum = parseFloat(portfolios_config[0]['currentValueSum']);
        }

        return {
          account_code: account_code,
          pan: pan,
          name: name,
          portfolio: "",
          portfolio_value: currentValueSum,
          cash_position: cash_virtual,
          live_cash_position: cash_virtual,
          strategy: smallcases[0].pName,
          investment: cash_virtual
        }
      });

      configArray.push({
        ...scheme,
        scheme_portfolio_customers: customer_config
      });
    }
    setSchemeConfig([...configArray]);
    const scheme_id_array = configArray.map((c)=>{
      return c.scheme_portfolio_id
    });

    // for (let i = customerConfig.length; i < customers.length; i++) {
    //   configArray.push({
    //     account_code: customers[i].account_code,
    //     pan: customers[i].pan,
    //     name: customers[i].name,
    //     portfolio: "",
    //     portfolio_value: 0,
    //     cash_position: customers[i].cash_virtual,
    //     live_cash_position: customers[i].cash_virtual,
    //     strategy: "",
    //     investment: customers[i].cash_virtual,
    //   });

    //   setPortfolio(i, customers[i]["portfolios"][0]);
    // }

    // const account_codes_array = customers.map((c) => {
    //   return c.account_code;
    // });

    // configArray = configArray.filter((c) => {
    //   return account_codes_array.includes(c.account_code);
    // });

    // setCustomerConfig([...configArray]);
  }, [schemes]);

  function GetSchemeInfo(scheme_id){
    const scheme = schemeConfig.find((s)=>{return s['scheme_portfolio_id'] === scheme_id});
    if(scheme === undefined) return false;
    return scheme;
  }

  function UpdateSchemeConfig(scheme_id,scheme_portfolio_customers){

    let total_cash = 0;
    let total_portfolio_value = 0;

    scheme_portfolio_customers.forEach((customer)=>{
        const {cash_position,portfolio_value} = customer;
        total_cash += parseFloat(cash_position);
        total_portfolio_value += parseFloat(portfolio_value);
    })

    const schemes_array = schemeConfig.map((s)=>{
      if(s.scheme_portfolio_id === scheme_id){
        return {
          ...s,
          scheme_total_customers: scheme_portfolio_customers.length,
          scheme_total_portfolio_value: total_portfolio_value,
          scheme_total_cash: total_cash,
          scheme_portfolio_customers
        }
      }
      else{
        return {
          ...s
        }
      }
    });

  setSchemeConfig(schemes_array); 
  }

  function SetCustomerStrategy(scheme_id,account_code,strategy_name){
    const scheme = GetSchemeInfo(scheme_id);
    if(!scheme) return;

    const {scheme_portfolio_customers} = scheme;
    
    const customers_array = scheme_portfolio_customers.map((s)=>{
      if(s.account_code === account_code){
        return {
          ...s,
          strategy: strategy_name
        }
      }
      else{
        return {
          ...s
        }
      }
    });

    UpdateSchemeConfig(scheme_id,customers_array);

  }

  function SetStrategyGroup(scheme_id,strategy_name){
    const scheme = GetSchemeInfo(scheme_id);
    if(!scheme) return;
    const {scheme_portfolio_customers} = scheme;
    
    const customers_array = scheme_portfolio_customers.map((s)=>{
      return {
        ...s,
        strategy: strategy_name
      }
    });

    UpdateSchemeConfig(scheme_id,customers_array);
  }

  function RemoveCustomer(scheme_id,account_code){
    const scheme = GetSchemeInfo(scheme_id);
    if(!scheme) return;
    const {scheme_portfolio_customers} = scheme;
    const customers_array = scheme_portfolio_customers.filter((s)=>{
      return s.account_code !== account_code;
    });
    UpdateSchemeConfig(scheme_id,customers_array);
  }

  function SetLiveCashPosition(scheme_id,account_code, value) {
    const scheme = GetSchemeInfo(scheme_id);
    if(!scheme) return;

    const {scheme_portfolio_customers} = scheme;
    const customers_array = scheme_portfolio_customers.map((s)=>{
      if(s.account_code === account_code){
        return {
          ...s,
          live_cash_position: parseFloat(value)
        }
      }
      else{
        return {
          ...s
        }
      }
    });

    UpdateSchemeConfig(scheme_id,customers_array);
  }


  function displayAlert(msg) {
    Alert({
      TitleText: "Warning",
      Message: msg,
      Band: true,
      BandColor: "#470985",
      BoxColor: "#ffffff",
      TextColor: "#000000",
      AutoClose: {
        Active: true,
        Line: true,
        LineColor: "#470985",
        Time: 3,
      },
    });
  }

  function GenerateTrades() {
    // if (
    //   !smallcaseName ||
    //   !smallcaseId ||
    //   smallcaseName === "" ||
    //   smallcaseId === ""
    // ) {
    //   displayAlert("Please select strategy to apply");
    //   return;
    // }

    // for (let i = 0; i < customerConfig.length; i++) {
    //   const config = customerConfig[i];
    //   if (config["investment"] + config["portfolio_value"] < minimumCash) {
    //     displayAlert("Investment must be more than minimum value");
    //     return;
    //   }

      // if (config['investment'] > (config['cash_position'] + config['portfolio_value'])) {
      //     displayAlert('Investment must be less than the available cash');
      //     return;
      // }
    // }

    loadSmallCase();
  }

  function loadSmallCase() {
    setLoading(true);

    function GetSmallCaseID(strategy){
      const smallcase = smallcases.find((s)=>{return s.pName === strategy});
      if(!smallcase){
        return false
      };
      return smallcase['smallcase_id'];
    }

    let payload = [];
    schemeConfig.forEach((scheme)=>{
      const {scheme_portfolio_name, scheme_portfolio_customers} = scheme;
      scheme_portfolio_customers.forEach((customer)=>{

        const smallcase_id = GetSmallCaseID(customer.strategy);
        if(smallcase_id){
            payload.push({
              account_code: customer.account_code,
              pan: customer.pan,
              name: customer.name,
              portfolio: customer.portfolio,
              capital: customer.investment,
              cash_position: customer.live_cash_position,
              scheme_name: scheme_portfolio_name,
              strategy: customer.strategy,
              smallcase_id: smallcase_id
            });
        }
        
      });
    });



    const account_token = GetCookie("token");

    Axios.post(`${REQUEST_BASE_URL}/smallcase/bulkportfolio?token=${account_token}`,{ data: payload })
      .then((response) => {
        const data = response.data;
        if (!data.error) {
          setLoading(false);
          setTrades(data.data);
          setTradesLoaded(true);
          SetTradeWindow(true);
        }
      })
  }

  function removeCustomerStockBulk(account_code) {
    let data = trades.filter((s) => {
      return s.config.account_code !== account_code;
    });
    setTrades([...data]);
  }

  function removeCustomerStock(account_code, stock) {
    const index = trades.findIndex((s) => {
      return s.config.account_code === account_code;
    });
    if (index === -1) return;

    const { config, stocks } = trades[index];

    const filter_trades = stocks.filter((s) => {
      return s.stockCode !== stock;
    });

    const { stocks_count, stocks_buy_value, stocks_sell_value, stocks_buy_brokerage, stocks_sell_brokerage } =
      calculateConfig(filter_trades);
    const post_trade_cash =
      config["cash_position"] + (stocks_sell_value-stocks_sell_brokerage) - (stocks_buy_value+stocks_buy_brokerage);

    let temp_trades = trades;

    let temp_config = {
      ...config,
      count: stocks_count,
      stocks_buy_value,
      stocks_sell_value,
      stocks_buy_brokerage,
      stocks_sell_brokerage,
      post_trade_cash,
    };
    temp_trades[index]["stocks"] = filter_trades;
    temp_trades[index]["config"] = temp_config;

    setTrades([...temp_trades]);
  }

  function calculateConfig(stocks) {
    let stocks_buy_value = 0;
    let stocks_sell_value = 0;
    let stocks_buy_brokerage = 0;
    let stocks_sell_brokerage = 0;
    let stocks_count = stocks.length;
    stocks.forEach((el) => {
      if (el.stockCode != "CASH" && el.stockCode != "Cash" && el.trade != 0) {
        if (el.trade >= 0) {
          stocks_buy_value =
            stocks_buy_value + Math.abs(el.tradeValue);
            stocks_buy_brokerage += el.brokerage;
        } else {
          stocks_sell_value =
            stocks_sell_value + Math.abs(el.tradeValue);
            stocks_sell_brokerage += el.brokerage;
        }
      }
    });

    stocks_buy_value = parseFloat(parseFloat(stocks_buy_value).toFixed(2));
    stocks_sell_value = parseFloat(parseFloat(stocks_sell_value).toFixed(2));

    return { stocks_count, stocks_buy_value, stocks_sell_value, stocks_buy_brokerage, stocks_sell_brokerage };
  }

  function executeSmallCase() {
    let is_valid = true;
    let invalid_message = "";
    const account_token = GetCookie("token");

    let data = trades.map((t) => {
      const { config, stocks } = t;

      if (config.cash_position - config.stocks_buy_value + config.stocks_sell_value < 0) {
        is_valid = false;
        invalid_message = `Margin not available for ${titleCase(config.name)}!`;
      }

      let stocks_config = stocks
        .filter((s) => {
          return s.StockCode.toUpperCase() !== "CASH" && s.trade !== 0;
        })
        .map((s) => {
          return {
            AccountCode: config.account_code,
            StockCode: s.StockCode,
            Company: s.StockName,
            isin: s.isin,
            Quantity: Math.abs(s.trade),
            Side: s.trade < 0 ? "Sell" : "Buy",
            OrderType: "MARKET",
          };
        });

      return {
        account_code: config.account_code,
        portfolio: config.portfolio,
        stocks: stocks_config,
      };
    });

    if (!is_valid) {
      displayAlert(invalid_message);
      return;
    }

    if (is_valid && data.length > 0) {
      setLoading(true);
      Axios.post(`${REQUEST_BASE_URL}/order/blanket?token=${account_token}`, {
        data: data,
      })
        .then((response) => {
          const d = response.data;
          setLoading(false);
          if (!d.error) {
            Alert({
              TitleText: "Orders routed successfully",
              Message: "Please visit transaction section to view orders",
              Band: true,
              BandColor: "#67D098",
              BoxColor: "#ffffff",
              TextColor: "#000000",
              AutoClose: {
                Active: true,
                Line: true,
                LineColor: "#67D098",
                Time: 3,
              },
            });

            setCustomerConfig([]);
            setTradesLoaded(false);
            setExecute(false);
            setTrades([]);

            resetExecute();
          } else {
            displayAlert("Something went wrong while routing the order");
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      displayAlert("Please add atleast one trade to continue");
    }
  }

  function tradeWindow() {
    setTrades([]);
    setTradesLoaded(false);
    setExecute(false);
    SetTradeWindow(false);
  }

  return (
    <div className={tradesLoaded ? "smallcase__customer__table full" : "smallcase__customer__table"}>
      {loading && (
        <>
          <div className="smallcase__customer__loading">
            <Pulse />
            <p>Loading... Please wait</p>
          </div>
        </>
      )}
      {tradesLoaded ? (
        <>
          <div className="customer__stocks__table">
            <div className="customer__stocks__row">
              <div className="stocks__row__config">
                <div className="config__box arrow"></div>
                <div className="config__box name">
                  <span>Customer Name</span>
                </div>
                <div className="config__box strategy">
                  <span>Strategy Name</span>
                </div>
                <div className="config__box cash">
                  <span>Cash Position</span>
                </div>
                <div className="config__box trades">
                  <span>Total Trades</span>
                </div>
                <div className="config__box buy">
                  <span>Total Buy Value</span>
                </div>
                <div className="config__box sell">
                  <span>Total Sell Value</span>
                </div>
                <div className="config__box brokerage">
                  <span>Approx Brokerage</span>
                </div>
                <div className="config__box cash">
                  <span>Cash Post Trade</span>
                </div>
                <div className="config__box remove"></div>
              </div>
            </div>
            {trades.map((s, index) => {
              return (
                <CustomerTableRow
                  key={index}
                  index={index}
                  data={s}
                  removeCustomerStockBulk={removeCustomerStockBulk}
                  removeCustomerStock={removeCustomerStock}
                />
              );
            })}
          </div>
          <div className="customer__smallcase__buttons">
            <CustomIconButton
              onClick={tradeWindow}
              icon={<Book size={18}  />}
              title="Trade Window"
            />
            {execute ? (
              <>
                <CustomIconButton
                  onClick={executeSmallCase}
                  icon={<TickCircle size={18}  />}
                  title="Confirm Routing"
                />
              </>
            ) : (
              <>
                <CustomIconButton
                  onClick={() => {
                    setExecute(true);
                  }}
                  icon={<PlayCircle size={18} />}
                  title="Route Trades"
                />
              </>
            )}
          </div>
        </>
      ) : (
        <>
          {schemeConfig.length === 0 ? (
            <>
              <div className="customer__table__empty">
                <BoxTick size={40} color={COLOR_VARS['SWIFT_COLOR4']} />
                <p>Please add schemes to continue</p>
              </div>
            </>
          ) : (
            <>
              <>
                <div className="scheme-data-wrapper">
                  <div className="scheme-row">
                    <div className="scheme-row-box arrow"></div>
                    <div className="scheme-row-box"><span>Scheme Name</span></div>
                    <div className="scheme-row-box"><span>Total Customers</span></div>
                    <div className="scheme-row-box"><span>Total Portfolio Value</span></div>
                    <div className="scheme-row-box"><span>Total Cash Position</span></div>
                    <div className="scheme-row-box strategy"><span>Strategy Name</span></div>
                    <div className="scheme-row-box remove"></div>
                  </div>
                  {schemeConfig.map((scheme,index)=>{
                    return <SchemeRow key={index} scheme_details={scheme} smallcases={smallcases} SetStrategyGroup={SetStrategyGroup} SetCustomerStrategy={SetCustomerStrategy} RemoveCustomer={RemoveCustomer} RemoveScheme={RemoveScheme} SetLiveCashPosition={SetLiveCashPosition} />
                  })}
                </div>
                
                <div className="customer__smallcase__buttons">
                  <CustomIconButton
                    onClick={GenerateTrades}
                    icon={<Book size={18} />}
                    title="Generate Trades"
                  />
                </div>
              </>
            </>
          )}
        </>
      )}
    </div>
  );
}

function SchemeRow({scheme_details,smallcases,SetStrategyGroup,SetCustomerStrategy,RemoveCustomer,RemoveScheme,SetLiveCashPosition}){

  const [open,setOpen] = useState(false);
  const [strategy,setStrategy] = useState('');

  const {scheme_portfolio_id,scheme_portfolio_name,scheme_total_customers,scheme_total_portfolio_value,scheme_total_cash,scheme_portfolio_customers} = scheme_details;

  const smallcase_array = smallcases.map((s)=>{
    return s.pName
  });

  useEffect(()=>{
    const strategy_list = scheme_portfolio_customers.map((s)=>{return s.strategy});
    const strategy_set = new Set(strategy_list);

    if(strategy_set.size === 1){
      setStrategy(strategy_list[0]);
    }
    else{
      setStrategy('Mixed');
    }

  },[scheme_portfolio_customers]);

 

  return <>
    <div className="scheme-row">
      <div className="scheme-row-box arrow" onClick={() => { setOpen(!open);}}>
        {open ? <ArrowDown2 size={20} color="#192b40" /> : <ArrowRight2 size={20} color="#192b40" />}
      </div>
      <div className="scheme-row-box"><span>{scheme_portfolio_name}</span></div>
      <div className="scheme-row-box"><span>{scheme_total_customers}</span></div>
      <div className="scheme-row-box"><span>{priceFormat(scheme_total_portfolio_value)}</span></div>
      <div className="scheme-row-box"><span>{priceFormat(scheme_total_cash)}</span></div>
      <div className="scheme-row-box strategy">
        <CustomValueSelect value={strategy} options={smallcase_array} onChange={(value)=>{SetStrategyGroup(scheme_portfolio_id,value)}} />
      </div>
      <div className="scheme-row-box remove" onClick={()=>{RemoveScheme(scheme_portfolio_id)}}>
        <Cross size={22} color="#192b40" />
      </div>
    </div>
    <div className={`customer__table__wrapper ${open ? '' : 'hide'}`}>
        <table>
          <thead>
            <tr>
              <td className="customer__config customer__name">
                Customer Name
              </td>
              <td className="customer__config customer__value">
                Portfolio Value
              </td>
              <td className="customer__config customer__cash">
                Cash Position
              </td>
              <td className="customer__config customer__strategy">
                Strategy Name
              </td>
              <td className="customer__config customer__remove">
              </td>
            </tr>
          </thead>
          <tbody>
            {scheme_portfolio_customers.map((c, index) => {
              return (
                  <CustomerEntryRow
                    key={index}
                    scheme_id={scheme_portfolio_id}
                    customer_config={c}
                    smallcases={smallcase_array}
                    SetCustomerStrategy={SetCustomerStrategy}
                    RemoveCustomer={RemoveCustomer}
                    SetLiveCashPosition={SetLiveCashPosition}
                  />
                );
            })}
          </tbody>
        </table>
    </div>
  </>
}

export default SmallcaseCustomerTable;
