import React, { useEffect, useState, useRef, useMemo } from "react";
import moment from "moment";
import { useSearchParams, Link } from "react-router-dom";
import { OrderBook } from "../CustomComponents/SwiftIcon/Icons";
import { numberFormat, titleCase } from "../../exports/UtilFunctions";
import Pulse from "../Loader/Pulse";
import useQueryParams from "../../hooks/useQueryParams";
import { COLOR_VARS } from "../../exports/ColorVars";
import ServerRequest from "../../exports/ServerRequest";
import ConfirmBox from "../CustomComponents/ConfirmBox/ConfirmBox";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";


function OrderFiles({ origin = "USER" }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { id = false } = useQueryParams();

  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState(false);
  const [file, setFile] = useState(false);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (!id || !Array.isArray(files) || files.length === 0) return;
    const selected_file = files.find((f) => {
      return f["file_id"] == id;
    });

    if (!selected_file) return;
    setFile(selected_file);
  }, [id, files]);

  useEffect(() => {
    if (!id && Array.isArray(files) && files.length > 0) {
      SetFileID(files[0].file_id);
    }
  }, [files]);

  async function GetOrderFiles() {
    const route =
      origin === "USER" ? "/order/files" : "/backoffice/dealer/files";

    try {
      const data = await ServerRequest({
        method: "GET",
        url: route,
      });

      const { error, files = [] } = data;
      if (error || !Array.isArray(files)) {
        setLoading(false);
        setFiles([]);
      } else {
        setLoading(false);
        setFiles(files.reverse());
      }
    } catch (error) {
      setLoading(false);
      setFiles([]);
    }
  }

  useEffect(() => {
    GetOrderFiles();

    return () => {
      setLoading(true);
      setFiles([]);
    };
  }, [origin, trigger]);

  function SetFileID(id) {
    searchParams.set("id", id);
    setSearchParams(searchParams);
  }

  return (
    <div className="orders-wrapper full">
      {loading ? (
        <div className="orders-files-loading">
          <Pulse />
          <p>Loading orders files...</p>
        </div>
      ) : (
        <>
          {files && Array.isArray(files) && files.length > 0 ? (
            <>
              <div className="orders-files-container">
                <div className="orders-files-data">
                  <p>Order Files</p>
                  <div className="orders-file-info">
                    {files.map((f) => {
                      return (
                        <OrderFileRow
                          key={f.file_id}
                          file={f}
                          setFile={SetFileID}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className={`orders-files-preview ${file ? "active" : ""}`}>
                  {file && (
                    <>
                      <FilePreview file={file} origin={origin} setTrigger={setTrigger} />
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="orders-files-empty">
                <OrderBook size={40} color={COLOR_VARS["SWIFT_COLOR4"]} />
                <div>
                  <p>No orders files found</p>
                  <span>Looks like you have't made any orders yet</span>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

function OrderFileRow({ file, setFile }) {
  const { file_id, file_timestamp, orders } = file;
  return (
    <div
      className="orders-file-row"
      onClick={() => {
        setFile(file_id);
      }}
    >
      <p>{moment(file_timestamp).format("DD MMM yyyy, hh:mm A")}</p>
      <span>Total Trades: {orders.length}</span>
    </div>
  );
}

function FilePreview({ file, origin, setTrigger }) {
  const {
    file_id,
    file_timestamp,
    file_url,
    broker_token = false,
    orders,
  } = file;

  const fileRef = useRef(null);

  const [loading,setLoading] = useState(false);

  function DownloadOrdersCSV() {
    const data = orders;

    const csvRows = [
      [
        "Customer",
        "Client Code",
        "ISIN",
        "Symbol",
        "Company",
        "CP Code",
        "Broker",
        "Side",
        "Order Type",
        "Limit Price",
        "Quantity",
        "Internal Ref. No.",
      ],
      ...data.map((item) => [
        item.customer_name,
        item.trading_code,
        item.isin,
        item.symbol,
        item.name,
        item.cpcode,
        item.broker,
        item.side,
        item.type,
        item.type == "MARKET" ? "-" : numberFormat(item.limit),
        item.quantity,
        item.order_id,
      ]),
    ];

    const csvContent = csvRows.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    fileRef.current.href = url;
    fileRef.current.download = `order-${file_id}.csv`;
  }

  async function DeleteFileTrades(callback){

    callback();
    setLoading(true);
    const route = `/order/file?file_id=${file_id}`;
    try{
      await ServerRequest({
        method: "DELETE",
        url: route,
      });
      ShowMessage("Success", "Trades deleted successfully");
      setTrigger(Math.random());
    }
    catch (error) {
      ShowMessage("Warning", "Something went wrong");
    }
    finally{
      setLoading(false);
    }
  }

  function ShowMessage(title, msg) {
    Alert({
      TitleText: title,
      Message: msg,
      AutoClose: {
        Active: true,
        Line: true,
        LineColor: "#E51A4B",
        Time: 3,
      },
    });
  }

  const isCancel = useMemo(()=>{
    return orders?.some((o) => o?.status == 'Pending');
  },[orders]);

  return (
    <>
      {loading && <div className="files-loading">
        <Pulse />
        <p>Deleting Trades...</p>
      </div>}
      <div className="files-preview-header">
        <p>
          Generated On: {moment(file_timestamp).format("DD MMM yyyy, hh:mm A")}
        </p>
        <div>
          <a ref={fileRef} onClick={DownloadOrdersCSV}>
            Download CSV
          </a>
          <a href={file_url} download>
            Download Trades File
          </a>
          {origin == "USER" && broker_token && (
            <>
              <Link to={`/broker/trades?token=${broker_token}`} target="_blank">
                Upload Trades File
              </Link>
            </>
          )}
          {isCancel && <span onClick={()=>{
            ConfirmBox({
              title: "Confirm Delete",
              description: (
                <>
                  Are you sure you want delete pending trades?
                </>
              ),
              properties: [
                {
                  id: "2",
                  label: "Confirm",
                  color: "#192b40",
                  bgColor: "#ffffff",
                  handleFunction: DeleteFileTrades,
                  functionArgs: [],
                },
              ],
              cancel: true,
            });
          }}>Delete Trades</span>}
        </div>
      </div>
      <div className="files-preview-table">
        <table>
          <thead>
            <tr>
              <td className="name">Company Name</td>
              <td className="customer">Customer Name</td>
              <td className="customer">CP Code</td>
              <td className="broker">Broker</td>
              <td className="side">Side</td>
              <td className="type">Order Type</td>
              <td className="price">Limit Price</td>
              <td className="quantity">Quantity</td>
              <td className="status">Status</td>
            </tr>
          </thead>
          <tbody>
            {orders.map((o, index) => {
              return (
                <tr key={index}>
                  <td>
                    <div className="stock-name-code">
                      <p>{o.symbol}</p>
                      <span>{o.name}</span>
                    </div>
                  </td>
                  <td title={titleCase(o.customer_name)}>
                    {titleCase(o.customer_name)}
                  </td>
                  <td>{o.cpcode ? o.cpcode : "-"}</td>
                  <td>{o.broker}</td>
                  <td>{o.side}</td>
                  <td>{titleCase(o.type)}</td>
                  <td>{o.type === "MARKET" ? "-" : numberFormat(o.limit)}</td>
                  <td>{o.quantity}</td>
                  <td>{o.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default OrderFiles;
