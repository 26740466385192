import React from 'react';
import '../../../../css/CustomComponents/CustomCheckBox.css';
import { Tick } from '../../../CustomComponents/SwiftIcon/Icons';


export class CustomCheckBox extends React.PureComponent {

    constructor(props)
    {
        super(props);
        this.state = {
            isChecked : this.props.isChecked
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.isChecked !== prevProps.isChecked) {
            this.setState({
                isChecked: this.props.isChecked
            })
        }
    }

    toggleCheckBox()
    {
        this.setState((prevState)=>({
            isChecked : !prevState.isChecked
        }),()=>{
            this.props.onChangeValue(this.state.isChecked);
        });
        
    }

    render() {

        const {width,height,disabled} = this.props;
        const {isChecked} = this.state;

        return (
            <div 
                className={isChecked ? "custom-checkbox checked" : disabled ? "custom-checkbox disabled" : "custom-checkbox"} 
                style={{width : width , height : height }}
                onClick={disabled ? ()=> {} : ()=>{this.toggleCheckBox()}}            >
                {isChecked ? 
                    
                    <Tick size={20} color="#192b40" />
                    :
                    <></>
                }
            </div>
        )
    }
}

export default CustomCheckBox;
