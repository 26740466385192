import React, { useEffect, useState } from "react";
import SwiftModal from "../../CustomComponents/SwiftModal/SwiftModal";
import {
  Cross,
  ArrowRight2,
  UserSearch,
  Edit,
  ArrowDown2,
} from "../../CustomComponents/SwiftIcon/Icons";
import CustomSelectError from "../../Body/CustomChartComponents/CustomSelectError/CustomSelectError";
import ServerRequest from "../../../exports/ServerRequest";
import { useNavigate } from "react-router-dom";
import Pulse from "../../Loader/Pulse";
import "../../../css/CustomComponents/CustomSearchSelect.css";

import CustomInputFile from "../../CustomComponents/CustomInputFile/CustomInputFile";
import {
  isEmpty,
  isValidEmail,
  isValidPan,
  isValidPhoneNumber,
  titleCase,
} from "../../../exports/UtilFunctions";
import { Alert } from "../../Body/CustomChartComponents/CustomAlert/CustomAlert";
import ConfirmBox from "../../CustomComponents/ConfirmBox/ConfirmBox";
import { GetCookie } from "../../../exports/CookieManagement";
import { COLOR_VARS } from "../../../exports/ColorVars";

const SuperUser = () => {
  const [form, setForm] = useState(null);
  const [formData, setFormData] = useState({});
  const [addForm, setAddForm] = useState(null);
  const [addFormData, setAddFormData] = useState({});
  const [change, setChange] = useState(1);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState("");
  const [confirmPopup, setConfirmPopup] = useState();
  const [clickOn, setclickOn] = useState("");
  const [expand, setExpand] = useState();
  const [editIndex, setEditIndex] = useState();
  const [brokerroles, setBrokerRoles] = useState([]);
  const [roleCodeMap, setroleCodeMap] = useState({});
  const [email, setEmail] = useState("");
  const token = GetCookie("admin_token");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data1 = await ServerRequest({
        url: `/admin/superuser/get?token=${token}`,
        method: "get",
      });

      if (data1.server_error) {
        navigate("/404", { state: { errorMessage: data1.message } });
      }
      setData(data1.data);

      const data = await ServerRequest({
        url: `/admin/superuser/brokerroles?token=${token}`,
        method: "get",
      });

      if (data.server_error) {
        navigate("/404", { state: { errorMessage: data1.message } });
      }
      setBrokerRoles(data.data.roleNames);
      setroleCodeMap(data.data.roleMap);

      setLoading(false);
    };
    fetchData();
  }, [change]);
  const IniError = {
    email: "",
    role: "",
    name: "",
    mobile: "",
  };
  const [error, setError] = useState(IniError);

  const [addError, setAddError] = useState({
    email: "",
    pan: "",
    mobile: "",
    name: "",
  });

  const handleAddInputChange = (name, value) => {
    setAddFormData((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleInputChange = (name, value) => {
    const updatedSuperUser = { ...formData.superUser };
    const updatedBrokers = [...formData.brokers];
    updatedSuperUser[name] = value;
    setFormData({
      brokers: updatedBrokers,
      superUser: updatedSuperUser,
    });
  };

  const handleInputChange1 = (name, value) => {
    const index = parseInt(name.match(/\d+/)[0]);

    const updatedSuperUser = { ...formData.superUser };
    const updatedBrokers = [...formData.brokers];

    const fieldName = name.replace(/\d+/g, "");

    updatedBrokers[index][fieldName] = value;
    setFormData({
      brokers: updatedBrokers,
      superUser: updatedSuperUser,
    });
  };

  const openForm = (rowdata, item) => {
    if (rowdata.status == "Deactivated") return;
    const displayDataFormCopy = JSON.parse(JSON.stringify(rowdata));
    const formDataCopy = JSON.parse(JSON.stringify(item));
    setFormData(formDataCopy);
    setForm(true);
    setEmail(displayDataFormCopy.email);
  };

  const closeForm = () => {
    setFormData({});
    setForm(false);
    setError("");
    setEmail();
  };
  const DisplayAlert = (msg) => {
    Alert({
      TitleText: "Error",
      Message: msg,
      BandColor: "#e51a4b",

      AutoClose: {
        Active: true,
        Line: true,
        LineColor: "#e51a4b",
        Time: 3,
      },
    });
  };
  const ValidateAll = (data) => {
    const formData = data;
    const newErrors = { ...IniError };
    let hasError = false;

    if (clickOn == "broker") {
      if (!isValidEmail(formData.brokers[editIndex].email) && !hasError) {
        newErrors["email"] = `Enter Valid Email`;
        hasError = true;
        DisplayAlert("Enter Valid Email");
      }

      if (isEmpty(formData.brokers[editIndex].name) && !hasError) {
        newErrors["name"] = `Enter Valid Name`;
        hasError = true;
        DisplayAlert("Enter Valid Name");
      }

      if (
        !isValidPhoneNumber(formData.brokers[editIndex].mobile) &&
        !hasError
      ) {
        newErrors["mobile"] = `Enter Valid Mobile Number`;
        hasError = true;
        DisplayAlert("Enter Valid Mobile Number");
      }
    } else {
      if (!isValidEmail(formData.superUser.email) && !hasError) {
        newErrors["email"] = `Enter Valid Email`;
        hasError = true;
        DisplayAlert("Enter Valid Email");
      }
      if (isEmpty(formData.superUser.name) && !hasError) {
        newErrors["name"] = `Enter Valid Name`;
        hasError = true;
        DisplayAlert("Enter Valid Name");
      }
      if (!isValidPhoneNumber(formData.superUser.mobile) && !hasError) {
        newErrors["mobile"] = `Enter Valid Mobile Number`;
        hasError = true;
        DisplayAlert("Enter Valid Mobile Number");
      }
    }

    setError(newErrors);

    return hasError;
  };
  const handleSubmit = async () => {
    if (ValidateAll(formData)) return;

    setLoading(true);

    const data = await ServerRequest({
      url: `/admin/account/validate?email=${
        clickOn == "broker"
          ? formData.brokers[editIndex].email
          : formData.superUser.email
      }&token=${token}`,
      method: "get",
    });

    if (
      data.error &&
      email !=
        (clickOn == "broker"
          ? formData.brokers[editIndex].email
          : formData.superUser.email)
    ) {
      setError({ ...error, ["email"]: "Email already exist" });
      setLoading(false);
      DisplayAlert("Email already exist");
      return;
    }
    if (data.server_error) {
      navigate("/404", { state: { errorMessage: data.message } });
    }

    const data2 = await ServerRequest({
      url: `/admin/superuser/update?token=${token}`,
      data: formData,
      method: "post",
    });

    if (data2.server_error) {
      navigate("/404", { state: { errorMessage: data2.message } });
    }
    setLoading(false);
    closeForm();
    // setConfirmPopup();
    setChange(Math.floor(Math.random() * 900000000 + 100000000));
  };
  const handleStatusChange = async (data1, clickOn, editIndex, email) => {
    if (ValidateAll(data1)) return;

    setLoading(true);
    // formData.startemail = email;

    const data = await ServerRequest({
      url: `/admin/account/validate?email=${
        clickOn == "broker"
          ? data1.brokers[editIndex].email
          : data1.superUser.email
      }&token=${token}`,
      method: "get",
    });

    if (
      data.error &&
      email !=
        (clickOn == "broker"
          ? data1.brokers[editIndex].email
          : data1.superUser.email)
    ) {
      setError({ ...error, ["email"]: "Email already exist" });
      setLoading(false);
      DisplayAlert("Email already exist");
      return;
    }
    if (data.server_error) {
      navigate("/404", { state: { errorMessage: data.message } });
    }

    const data2 = await ServerRequest({
      url: `/admin/superuser/update?token=${token}`,
      data: data1,
      method: "post",
    });

    if (data2.server_error) {
      navigate("/404", { state: { errorMessage: data2.message } });
    }
    setLoading(false);
    closeForm();
    // setConfirmPopup();
    setChange(Math.floor(Math.random() * 900000000 + 100000000));
  };
  const ValidateAddData = () => {
    const newErrors = { email: "", pan: "", mobile: "", name: "" };
    let hasError = false;

    if (isEmpty(addFormData.name) && !hasError) {
      newErrors["name"] = `Enter Valid Name`;
      hasError = true;
      DisplayAlert("Enter Valid Name");
    }

    if (!isValidEmail(addFormData.email) && !hasError) {
      newErrors["email"] = `Enter Valid Email`;
      hasError = true;
      DisplayAlert("Enter Valid Email");
    }

    if (!isValidPan(addFormData.pan) && !hasError) {
      newErrors["pan"] = `Enter Valid Pan`;
      hasError = true;
      DisplayAlert("Enter Valid Pan");
    }
    if (!isValidPhoneNumber(addFormData.mobile) && !hasError) {
      newErrors["mobile"] = `Enter Valid Mobile Number`;
      hasError = true;
      DisplayAlert("Enter Valid Mobile Number");
    }

    setAddError(newErrors);

    return hasError;
  };
  const handleAddSubmit = async () => {
    if (ValidateAddData()) return;

    // setLoading(true);
    // formData.startemail = email;

    const data = await ServerRequest({
      url: `/admin/superuser/validate?token=${token}`,
      data: addFormData,
      method: "post",
    });

    if (data.server_error) {
      navigate("/404", { state: { errorMessage: data.message } });
    }

    if (data.error) {
      const capitalizedType =
        data.type.charAt(0).toUpperCase() + data.type.slice(1);

      setAddError({
        ...error,
        [data.type]: `${capitalizedType} already exist`,
      });
      // setLoading(false);
      DisplayAlert(`${capitalizedType} already exist`);
      return;
    }
    setLoading(true);
    const data2 = await ServerRequest({
      url: `/admin/superuser/insert?token=${token}`,
      data: addFormData,
      method: "post",
    });

    if (data2.server_error) {
      navigate("/404", { state: { errorMessage: data2.message } });
    }
    setLoading(false);
    setAddFormData({});
    setAddError({});
    setAddForm(null);
    // setConfirmPopup();
    setChange(Math.floor(Math.random() * 900000000 + 100000000));
  };

  const renderFormContent = () => {
    return (
      <>
        <div className="swift-account-form-body-content">
          {clickOn == "superuser" && (
            <>
              <CustomInputFile
                label="Name"
                name="name"
                type="text"
                value={formData.superUser.name}
                onchange={handleInputChange}
                error={error.name}
              />
              <CustomInputFile
                label="Mobile"
                name="mobile"
                type="number"
                maxlength="10"
                value={formData.superUser.mobile}
                onchange={handleInputChange}
                error={error.mobile}
              />

              <CustomInputFile
                label="Email"
                name="email"
                type="text"
                value={formData.superUser.email}
                onchange={handleInputChange}
                error={error.email}
              />
            </>
          )}
          {clickOn == "broker" && (
            <>
              <CustomSelectError
                heading="Broker Role"
                placeholder="Please Select Broker Role"
                defaultIndex={brokerroles.indexOf(
                  formData.brokers[editIndex]["role_name"]
                )}
                options={brokerroles}
                onTypeChange={(role) => {
                  handleInputChange1(`role_name${editIndex}`, role);
                  handleInputChange1(
                    `user_role${editIndex}`,
                    roleCodeMap[role]
                  );
                }}
                error={error.role}
              />
              <CustomInputFile
                label="Name"
                name={`name${editIndex}`}
                type="text"
                value={formData.brokers[editIndex].name}
                onchange={handleInputChange1}
                error={error.name}
                placeholder="John Doe"
              />
              <CustomInputFile
                label="Mobile"
                name={`mobile${editIndex}`}
                type="number"
                maxlength="10"
                value={formData.brokers[editIndex].mobile}
                onchange={handleInputChange1}
                error={error.mobile}
                placeholder="9998887776"
              />

              <CustomInputFile
                label="Email"
                name={`email${editIndex}`}
                type="text"
                value={formData.brokers[editIndex].email}
                onchange={handleInputChange1}
                error={error.email}
                placeholder="xyz@gmail.com"
              />
            </>
          )}
        </div>
        <div className="login-footer">
          <div className="swift-button-icon" onClick={handleSubmit}>
            <div className="button-icon">
              <ArrowRight2 size={20} />
            </div>
            <p>Submit</p>
          </div>
        </div>
      </>
    );
  };
  const renderAddFormData = () => {
    return (
      <>
        <div className="swift-account-form-body-content">
          <CustomInputFile
            label="Name"
            placeholder="John Doe "
            name="name"
            type="text"
            value={addFormData.name}
            onchange={handleAddInputChange}
            error={addError.name}
          />

          <CustomInputFile
            label="Email"
            name="email"
            placeholder="xyz@gmail.com"
            type="text"
            value={addFormData.email}
            onchange={handleAddInputChange}
            error={addError.email}
          />

          <CustomInputFile
            label="PAN Number"
            name="pan"
            placeholder="ABCTY1234D"
            type="text"
            value={addFormData.pan}
            onchange={handleAddInputChange}
            error={addError.pan}
          />

          <CustomInputFile
            label="Mobile"
            name="mobile"
            placeholder="9998887776"
            type="number"
            maxlength="10"
            value={addFormData.mobile}
            onchange={handleAddInputChange}
            error={addError.mobile}
          />
        </div>
        <div className="login-footer">
          <div className="swift-button-icon" onClick={handleAddSubmit}>
            <div className="button-icon">
              <ArrowRight2 size={20} />
            </div>
            <p>Submit</p>
          </div>
        </div>
      </>
    );
  };

  const openconfirmPopup = (rowdata, item, clickOn, editIndex) => {
    if (rowdata.status == "Pending") return;
    const formDataCopy = JSON.parse(JSON.stringify(item));
    setFormData(formDataCopy);
    setEmail(rowdata.email);

    const type = rowdata.status == "Deactivated" ? "Activate" : "Deactivate";
    // setConfirmPopup(
    //   rowdata.status == "Deactivated" ? "Activate" : "Deactivate"
    // );

    ConfirmBox({
      title: "Account Status",
      description: (
        <>
          <>Are you sure you want to {type} the account ?</>
        </>
      ),
      properties: [
        {
          id: "2",
          label: type,
          color: "#192b40",
          bgColor: "#ffffff",
          handleFunction: (callback) => {
            if (clickOn == "broker") {
              formDataCopy.brokers[editIndex].status =
                formDataCopy.brokers[editIndex].status == "Deactivated"
                  ? "Activated"
                  : "Deactivated";
            } else {
              formDataCopy.superUser.status =
                formDataCopy.superUser.status == "Deactivated"
                  ? "Activated"
                  : "Deactivated";
            }
            handleStatusChange(formDataCopy, clickOn, editIndex, rowdata.email);
            callback();
          },
        },
      ],
      cancel: true,
    });
  };

  const displayRow = (item, index, firstcol) => {
    const rowdata = firstcol ? item.superUser : item.brokers[index];
    return (
      <>
        <tr key={index}>
          {firstcol ? (
            <td>
              <button
                className="svgicon"
                onClick={() => {
                  setExpand((prevIndex) =>
                    prevIndex === index ? null : index
                  );
                }}
              >
                {expand === index ? (
                  <ArrowDown2 size={16} color={COLOR_VARS["SWIFT_COLOR4"]} />
                ) : (
                  <ArrowRight2 size={16} color={COLOR_VARS["SWIFT_COLOR4"]} />
                )}
              </button>
            </td>
          ) : (
            <td></td>
          )}

          <td>{rowdata["pan"] ? rowdata["pan"] : "-"}</td>
          <td>{rowdata.name ? titleCase(rowdata.name) : "-"}</td>
          <td>{rowdata.mobile ? rowdata.mobile : "-"}</td>
          <td>{rowdata.email ? rowdata.email : "-"}</td>
          <td>{rowdata["account_code"] ? rowdata["account_code"] : "-"}</td>
          <td>{rowdata["user_role"] ? rowdata["user_role"] : "-"}</td>

          <td>
            <div
              className="swift-account-svg"
              onClick={() => {
                setclickOn(firstcol ? "superuser" : "broker");
                if (!firstcol) setEditIndex(index);
                openForm(rowdata, item);
              }}
            >
              <Edit
                size={18}
                color={
                  rowdata.status === "Deactivated" ||
                  rowdata.role_type === "ADMIN"
                    ? COLOR_VARS["SWIFT_COLOR5"]
                    : COLOR_VARS["SWIFT_COLOR4"]
                }
              />
            </div>
          </td>
          <td>
            <button
              className={
                "swift-account-btn " +
                (rowdata.status === "Pending" ? "disabled" : "")
              }
              onClick={() => {
                setEditIndex(index);
                setclickOn(firstcol ? "superuser" : "broker");
                openconfirmPopup(
                  rowdata,
                  item,
                  firstcol ? "superuser" : "broker",
                  index
                );
              }}
            >
              {rowdata.status === "Deactivated" ? "Activate" : "Deactivate"}
            </button>
          </td>
        </tr>
      </>
    );
  };
  return !loading ? (
    <div className="swift-account-main open">
      <div className="swift-account-heading">
        <div className="swift-account-heading-right">
          <div className="select-search-wrapper">
            <div className="select-search-box">
              <input
                placeholder="Search Super User"
                value={filterData}
                onChange={(e) => setFilterData(e.target.value)}
              />
              <div className="filter-icon">
                <UserSearch size={20} color="#000" />
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              setAddForm(true);
            }}
          >
            Add Super User
          </button>
        </div>
      </div>

      {data.filter((item) =>
        item.superUser.name.toLowerCase().includes(filterData.toLowerCase())
      ).length > 0 ? (
        <div className="admin-wrapper full">
          <div className="admin-history-container admin-superuser-container">
            <table>
              <thead>
                <tr>
                  <td className="arrow"></td>
                  <td className="pan">PAN</td>
                  <td className="name">Name</td>
                  <td className="mobile">Mobile Number</td>
                  <td className="email">Email Address</td>
                  <td className="account">Account Code</td>
                  <td className="role">Role</td>
                  <td className="edit"></td>
                  <td className="deactivate"></td>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  if (
                    item.superUser.name
                      .toLowerCase()
                      .includes(filterData.toLowerCase())
                  ) {
                    return (
                      <React.Fragment key={index}>
                        {displayRow(item, index, true)}
                        {expand == index && (
                          <>
                            {item.brokers.map((broker, brokerIndex) =>
                              displayRow(item, brokerIndex, false)
                            )}
                          </>
                        )}
                      </React.Fragment>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="empty-data-wrapper">
          <p>No super user found</p>
        </div>
      )}

      {form && (
        <SwiftModal closeModal={closeForm} top="2%">
          <div className="swift-account-form">
            {loading ? (
              <>
                <div className="loader">
                  <Pulse />
                  <p>Creating Account</p>
                </div>
              </>
            ) : (
              <>
                <div className="swift-account-form-header">
                  <div className="swift-account-form-title">
                    Super User Management
                  </div>
                  <div className="swift-account-svg" onClick={closeForm}>
                    <Cross size={30} color={COLOR_VARS["SWIFT_COLOR4"]} />
                  </div>
                </div>
                <div className="swift-account-form-body">
                  {renderFormContent()}
                </div>
              </>
            )}
          </div>
        </SwiftModal>
      )}
      {addForm && (
        <SwiftModal
          closeModal={() => {
            setAddFormData({});
            setAddError({});
            setAddForm(null);
          }}
          top="2%"
        >
          <div className="swift-account-form">
            {loading ? (
              <>
                <div className="loader">
                  <Pulse />
                  <p>Creating Account</p>
                </div>
              </>
            ) : (
              <>
                <div className="swift-account-form-header">
                  <div className="swift-account-form-title">
                    Super User Management
                  </div>
                  <div
                    className="swift-account-svg"
                    onClick={() => {
                      setAddFormData({});
                      setAddError({});
                      setAddForm(null);
                    }}
                  >
                    <Cross size={30} color={COLOR_VARS["SWIFT_COLOR4"]} />
                  </div>
                </div>
                <div className="swift-account-form-body">
                  {renderAddFormData()}
                </div>
              </>
            )}
          </div>
        </SwiftModal>
      )}
    </div>
  ) : (
    <>
      <div className="admin-body-loader">
        <Pulse />
        <p>Loading data</p>
      </div>
    </>
  );
};

export default SuperUser;
