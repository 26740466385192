import { useEffect, useRef, useState } from "react";
import { readFullSnapQuoteData } from '../exports/FormatData';
import { GetStockConfig } from "../exports/GetStockConfig";

const LIVEFEED_BASE_URL = process.env.REACT_APP_LIVEFEED_BASE_URL;
let WEBSOCKET_INTERVAL = false;

const useFullSnapQuote = (symbol) => {
  const wsRef = useRef(null);
  const [connection, setConnection] = useState(false);
  const [config, setConfig] = useState(null);
  const [stockData, setStockData] = useState(null);

  useEffect(() => {

    if(!symbol) return;
    // console.log('GETTING CONFIG DATA FOR', symbol);
    GetConfig();

    return () => {
      // console.log('CLOSING WEBSOCKET FOR', symbol);
      CloseWebSocket();
    };
  }, [symbol]);

  useEffect(() => {
    if (!config) return;

    GetWebSocketConnection();
  }, [config]);

  useEffect(() => {
    GetWebSocketData();
  }, [connection]);

  function GetConfig() {
    return new Promise(async (resolve, reject) => {
      const config = await GetStockConfig(symbol);
      setConfig(config);
      resolve(config);
    });
  }

  function GetWebSocketConnection() {
    // console.log('GET WEBSOCKET CONNECTION FOR', symbol)
    // console.log('CONNECTION STATUS FOR', symbol, " is", connection)
    let socket = new WebSocket(LIVEFEED_BASE_URL);

    socket.onopen = () => {
      // console.log('OPEN ON WEBSOCKET FOR', symbol)
      wsRef.current = socket;
      setConnection(true);
    };

    socket.onerror = () => {
      // console.log('ERROR ON WEBSOCKET FOR', symbol)
      wsRef.current = false;
      setConnection(false);
    };
  }

  function GetWebSocketData() {
    // console.log('GET SOCKET DATA FOR', symbol)
    // console.log(connection, wsRef);
    if (!connection || !wsRef.current || wsRef.current.readyState !== 1) return;

    const ws = wsRef.current;

    ws.onmessage = (response) => {
      var reader = new FileReader();
      reader.readAsArrayBuffer(response.data);

      let convertedData;

      reader.onloadend = async (event) => {
        let data = new Uint8Array(reader.result);

        if (response.data.size >= 166) {
            convertedData = readFullSnapQuoteData(data)
          
            let livedata = convertedData.livedata;
            setStockData(livedata);
        }
      };
    };

    ws.send(
      JSON.stringify({
        a: "subscribe",
        v: [[config.exchange, config.code]],
        m: "full_snapquote",
      })
    );

    ws.onclose = () => {
      // console.log('CLOSE EVENT FIRE IN WEBSCOKET FOR', symbol)
      clearInterval(WEBSOCKET_INTERVAL);
    };

    WEBSOCKET_INTERVAL = setInterval(() => {
      if ((!ws && WEBSOCKET_INTERVAL) || ws.readyState === 3) {
        clearInterval(WEBSOCKET_INTERVAL);
        return;
      }
      ws.send(
        JSON.stringify({
          a: "h",
          v: [[config.exchange, config.code]],
          m: "",
        })
      );
    }, 10 * 1000);
  }

  function CloseWebSocket() {
    // console.log('INSIDE CLOSE SOCKET FUNCTION FOR', symbol);

    const ws = wsRef.current;

    // console.log('WEB SOCKET =>', ws);

    if (!ws) return;
    // console.log('CLOSE SOCKET')

    ws.close();
    setConnection(false);

    if (WEBSOCKET_INTERVAL) {
      clearInterval(WEBSOCKET_INTERVAL);
    }
  }

  return stockData;
};

export default useFullSnapQuote;
