import React from "react";
import PortfolioTable from "./PortfolioTable";
import PortfolioTradeValue from "./PortfolioTradeValue";

function PortfolioTableContainer({
  hide,
  user_role_type,
  customer,
  data,
  originalLen,
  sum,
  totalReturnToday,
  totalReturnChange,
  selectField,
  newWeight,
  orderArr,
  addOrderArr,
  updateOrderArr,
  setIndividualChange,
  setIndividualPrice,
  deleteStock,
  execute,
  stocksBuyValue,
  stocksSellValue,
  brokerageValue,
  cashPosVirtual,
  placeOrder,
  executeOrder,
}) {
  const hideClass = hide ? "hide" : "";
  return (
    <>
      <div className={"portfolio__table__container " + hideClass}>
        <PortfolioTable
          user_role_type={user_role_type}
          customer={customer}
          data={data}
          originalLen={originalLen}
          sum={sum}
          totalReturnToday={totalReturnToday}
          totalReturnChange={totalReturnChange}
          selectField={selectField}
          newWeight={newWeight}
          orderArr={orderArr}
          addOrderArr={addOrderArr}
          updateOrderArr={updateOrderArr}
          setIndividualChange={setIndividualChange}
          setIndividualPrice={setIndividualPrice}
          deleteStock={deleteStock}
        />
        {user_role_type === "BROKER" && (
          <>
            <PortfolioTradeValue
              data={data}
              execute={execute}
              stocksBuyValue={stocksBuyValue}
              stocksSellValue={stocksSellValue}
              brokerageValue={brokerageValue}
              cashPosVirtual={cashPosVirtual}
              placeOrder={placeOrder}
              executeOrder={executeOrder}
            />
          </>
        )}
      </div>
    </>
  );
}

export default PortfolioTableContainer;
