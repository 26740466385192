import React, { useState,useEffect } from "react";
import { Navigate } from "react-router-dom";
import { GetCookie } from "../../exports/CookieManagement";
import ServerRequest from "../../exports/ServerRequest";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

// export default function AdminAuth(ComponentToProtect) {
//     return class extends Component {

//         constructor() {
//             super();
//             this.state = {
//                 loading: true,
//                 redirect: false,
//             };
//         }
//         componentDidMount() {

//             const admin_token = GetCookie('admin_token');
//             console.log(admin_token)

//             if (!admin_token) {
//                 this.setState({ loading: false, redirect: true });
//                 return;
//             }

//             axios.get(`${REQUEST_BASE_URL}/access/checkadmin?token=${admin_token}`)
//                 .then(res => {
//                     if (res.status === 200 && !res.data.error) {
//                         this.setState({ loading: false });
//                     } else {
//                         const error = new Error(res.data.error);
//                         throw error;
//                     }
//                 })
//                 .catch(err => {
//                     console.error(err);
//                     this.setState({ loading: false, redirect: true });
//                 });
//         }
//         render() {
//             const { loading, redirect } = this.state;
//             if (loading) {
//                 return null;
//             }
//             if (redirect) {
//                 return <Navigate to="/404" />;
//             }
//             return <ComponentToProtect {...this.props} />;
//         }
//     }
// }

function AdminAuth({ children }) {
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    try {
      const token = GetCookie("admin_token");

      async function CheckToken() {
        const data = await ServerRequest({
          method: "GET",
          url: `/access/checkadmin?token=${token}`,
        });
        return data;
      }

      if (!token) {
        setLoading(false);
        setRedirect(true);
        return;
      }

      CheckToken().then((data) => {
        if (!data.error) {
          setLoading(false);
        } else {
          throw new Error("Invalid Credentials");
        }
      });
    } catch (e) {
      setLoading(false);
      setRedirect(true);
    }
  }, []);

  if (loading) {
    return null;
  }
  if (redirect) {
    return <Navigate to="/404" />;
  }
  return children;
}

export default AdminAuth;
