export function ReCalculateWeight(data, order, price_array, sum) {
  const findsum = (array) => array.reduce((a, b) => a + b);

  const { cashValueVirtual = 0 } = sum;

  // const orderSum = findsum(order);
  const isZero =
    order.filter((o) => {
      return o == 0;
    }).length === order.length
      ? true
      : false;

  var newWeight = [];
  var tradeVal = [];
  var tradeVolume = 0,
    buyValue = 0,
    sellValue = 0;
  if (!isZero) {
    var currentValSum = 0;

    order.forEach((elem, i) => {
      const current_price = price_array[i];

      if (elem < 0) {
        sellValue += Math.abs(elem) * current_price;
      } else {
        buyValue += Math.abs(elem) * current_price;
      }

      tradeVal.push(elem * current_price);

      data[i].CurrentValue =
        parseInt(data[i].Quantity) * parseFloat(current_price);

      currentValSum += parseFloat(data[i].CurrentValue);
    });

    tradeVolume = findsum(tradeVal);

    // console.log(currentValSum, tradeVolume, cashValueVirtual);

    const total_portfolio_value =
      parseFloat(currentValSum) +
      parseFloat(cashValueVirtual);

      // console.log("===========================================");
      // console.log("===========================================");

    for (let i = 0; i < data.length; i++) {
      const total_stock_value = parseFloat(data[i].CurrentValue) + tradeVal[i];

      // console.log(total_stock_value, total_portfolio_value);

      newWeight.push(
        parseFloat((total_stock_value / total_portfolio_value) * 100).toFixed(2)
      );
    }
  } else
    for (let i = 0; i < order.length; i++) {
      newWeight.push(false) && tradeVal.push(0);
    }

  return { newWeight, tradeVolume, buyValue, sellValue };
}
