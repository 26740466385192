import React, { useState, useEffect } from "react";
import { Book } from "../../CustomComponents/SwiftIcon/Icons";
import TradesTableItem from "./TradesTableItem";
import { COLOR_VARS } from "../../../exports/ColorVars";

function TradesTableBody({ filter_text, trades, status }) {
  const [filteredTrades, setFilteredTrades] = useState([]);

  useEffect(() => {
    const filter = trades.filter((t) => {
      return status.includes(t.Status) && t["symbol"].includes(filter_text);
    });
    setFilteredTrades(filter);
  }, [trades, status, filter_text]);

  return (
    <>
      {filteredTrades.length === 0 ? (
        <>
          <div className="empty-table-data">
            <Book size={60} color={COLOR_VARS["SWIFT_COLOR4"]} />
            <p>There are no trades to display</p>
          </div>
        </>
      ) : (
        <>
          {
            <table>
              <thead>
                <tr>
                  <td>Stock Name</td>
                  <td>Client Name</td>
                  <td>Client Code</td>
                  <td>Exchange</td>
                  <td>Order Type</td>
                  <td>Limit Price</td>
                  <td>Order Side</td>
                  <td>Quantity</td>
                  <td>Current Price</td>
                  <td>Trade Price</td>
                  <td>Trade Amount</td>
                  <td>Trade ID</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
                {filteredTrades.map((t, index) => {
                  return (
                    <TradesTableItem
                      key={index}
                      show={status.includes(t.Status)}
                      order={{
                        order_id: t["userOrderID"],
                        client_name: t["name"],
                        client_code: t["ClientID"],
                        isin: t["isin"],
                        symbol: t["symbol"],
                        name: t["stockCode"],
                        exchange: t["exchange"],
                        fullname: t["stockName"],
                        ordertype: t["orderType"],
                        limitprice: t["limitPrice"],
                        side: t["Side"],
                        quantity: t["Quantity"],
                        avgprice: t["trxnPrice"],
                        grossvalue: t["grossValue"],
                        status: t["Status"],
                        trade_id: t["tradeOrderID"],
                        broker: t["brokerCode"],
                      }}
                    />
                  );
                })}
              </tbody>
            </table>
          }
        </>
      )}
    </>
  );
}

export default TradesTableBody;
