import React from "react";
import CashPosition from "../Body/CashPosition/CashPosition";
import ChartContainer from "../Body/ChartContainer";
import KeyStatistics from "../Body/KeyStatistics/KeyStatistics";
import useQueryParams from "../../hooks/useQueryParams";

function StrategyDashboard({ symbol, OpenOrderPopup }) {
  const query = useQueryParams();
  const { symbol: query_symbol = "" } = query;

  return (
    <div className="app__body">
      <div className="app__body__bottom">
        <div className={"app__body__left " + (query_symbol === "" ? "" : "active")}>
          <ChartContainer symbol={symbol} OpenOrderPopup={OpenOrderPopup} />
          <KeyStatistics symbol={symbol} />
        </div>
        <div className="app__body__right">
          <CashPosition />
        </div>
      </div>
    </div>
  );
}

export default StrategyDashboard;
