import React from 'react';

class CustomInput extends React.PureComponent {

    constructor(props) {
        super(props);
        this.setValue = this.setValue.bind(this);
    }

    setValue(value) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const { value, placeholder, heading, error, onBlur, onFocus, ...rest } = this.props;

        return (
            <>
                <div className='swift-input-box'>
                    <p>{heading}</p>
                    <input
                        {...rest}
                        placeholder={placeholder}
                        type="text"
                        className='swift-input'
                        value={value}
                        onChange={(e) => { this.setValue(e.target.value) }}
                        onBlur={onBlur}
                        onFocus={onFocus}
                    />
                    <p className={error === '' ? 'error-text hide' : 'error-text'}>{error}</p>
                </div>
            </>
        )

    }

}

export default CustomInput;