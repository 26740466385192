import React from 'react';

class HeroTrading extends React.PureComponent {
    render() {
        return (
            <div className="hero__body__trading">
                <h1>Trading Basics</h1>
            </div>
        )
    }
}

export default HeroTrading;
