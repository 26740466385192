import React, { useEffect, useState } from "react";
import Axios from "axios";
import SectorMarketChart from "./SectorMarketChart.js";
import Pulse from "../../../Loader/Pulse";
import { Bag2 } from '../../../CustomComponents/SwiftIcon/Icons';
import { COLOR_VARS } from '../../../../exports/ColorVars';
import "../../../../css/Fade.css";
import withRouter from "../../../../hooks/withRouter.js";

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;


function PerformanceAnalytics({ customer, portfolio, hide }) {
  const [loading, setLoading] = useState(true);
  const [industry, setIndustry] = useState(undefined);
  const [marketCap, setMarketCap] = useState(undefined);

  function GetSectorMarketData() {
    const { account_code } = customer;
    Axios.post(`${REQUEST_BASE_URL}/sector?account_code=${account_code}`, {
      portfolioName: portfolio,
    }).then((response) => {
      const data = response.data;
      setLoading(false);
      setIndustry(data.industryTable);
      setMarketCap(data.marketCapTable);
    });
  }

  useEffect(() => {
    GetSectorMarketData();
  }, [customer]);

  const hideClass = hide ? "hide" : "";

  if (loading) {
    return (
      <div className={"portfolio__analysis__wrapper loader " + hideClass}>
        <Pulse />
        <p>Loading portfolio analysis...</p>
      </div>
    );
  }

  if(!Array.isArray(industry) || industry.length === 0){
    return (
      <div className={"portfolio__analysis__wrapper loader " + hideClass}>
        <Bag2 size={60} color={COLOR_VARS['SWIFT_COLOR4']} />
        <p>Your portfolio is empty</p>
      </div>
    );
  }

  if(hide) return <></>

  return (
    <div className={"portfolio__analysis__wrapper " + hideClass}>
      <div className="portfolio__analysis__container">
        <div className="portfolio__charts">
          <div className="portfolio__charts__wrapper">
            <div className="charts">
              <p>Sector Allocation</p>
              <div className="portfolio__charts__pie">
                <SectorMarketChart
                  values={industry}
                  unit="Inv. Value Rs."
                />
              </div>
            </div>
            {/* <div className="charts">
              <p>Market Cap Allocation</p>
              <div className="portfolio__charts__pie">
                <SectorMarketChart
                  values={marketCap}
                  unit="Stocks"
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(PerformanceAnalytics);
