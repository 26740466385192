import React, {useMemo} from "react";
import { useLocation } from "react-router-dom";

import Wrapper from "./Wrapper";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { getDeviceSize } from "../../exports/InteractiveUtils";

function BodyWrapper({ ...props }) {

  const { setAccountCode, rolesArray} = props;

  const location = useLocation();
  const { pathname = "" } = location;

  const DEVICE_SIZE = useMemo(() => {
    return getDeviceSize();
  }, []);

  const is_visible =
  DEVICE_SIZE === "L" ||
  DEVICE_SIZE === "M" ||
  (DEVICE_SIZE === "S" && pathname.includes(`/account/dashboard`))
    ? true
    : false;

  if (rolesArray.length == 0) {
    return <></>;
  }

  return (
    <div className="swift-body">
      <Header is_visible={is_visible} {...props} setAccountCode={setAccountCode} />
      <Wrapper is_visible={is_visible} menu_array={rolesArray} {...props} />
      <Footer />
    </div>
  );
}

export default BodyWrapper;
