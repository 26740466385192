import React from 'react';
import { titleCase } from '../../exports/UtilFunctions';
import useLiveOrderValue from '../../hooks/useLiveOrderValue';

function TopClient({ data }) {

    const { account_code, name, user_role, investment_cost, current_value, cash_position } = data;

    const order_value = useLiveOrderValue(account_code);

    const live_cash_position = parseFloat(cash_position) - parseFloat(order_value);

    function GetCurrency(value) {
        return parseFloat(value).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    return (
        <div className='top-client-row client-row'>
            <div className='client-info'>
                <p className='client-name' title={titleCase(name)}>{titleCase(name)}</p>
                <p className='client-role'>{user_role === 'BRC' ? 'Broking Customer' : 'Managed Customer'}</p>
            </div>
            <div className='client-investment'>
                <p>{GetCurrency(investment_cost)}</p>
            </div>
            <div className='client-value'>
                <p>{GetCurrency(current_value)}</p>
            </div>
            <div className='client-cash'>
                <p>{GetCurrency(live_cash_position)}</p>
            </div>

        </div>
    )
}

export default TopClient;