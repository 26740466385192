import React, { useEffect, useState } from "react";
import StockTechnical from "./StockTechnical";
import StockFeed from "./StockFeed";
import { priceFormat } from "../../exports/UtilFunctions";
import { ArrowRight2 } from "../CustomComponents/SwiftIcon/Icons";
import { COLOR_VARS } from "../../exports/ColorVars";
import { getDeviceSize } from "../../exports/InteractiveUtils";

const DEVICE_SIZE = getDeviceSize();

const RenderRow = ({ title, value }) => {
  return (
    <div className="swift-stock-management-detail-row-2-left-row">
      <p className="swift-stock-management-detail-row-2-left-row-title">
        {title}
      </p>
      <p className="swift-stock-management-detail-row-2-left-row-value">
        {value}
      </p>
    </div>
  );
};

const KeyFinanace = ({ data, isVisible, stock_info, stock_data }) => {
  return (
    <div
      className="swift-stock-management-detail-row-2-left"
      style={{ display: !isVisible ? "none" : "flex" }}
    >
      <div className="swift-stock-management-detail-left">
        <div className="swift-stock-management-deatil-left-1">
          <p className="swift-stock-management-detail-symbol">
            {stock_info.symbol}
          </p>
          <p className="swift-stock-management-detail-title">
            {stock_info.company}
          </p>
        </div>
        <div className="swift-stock-management-detail-value">
          <p className="swift-stock-management-detail-current-value">
            {priceFormat(stock_data.last_traded_price)}
          </p>
          <p
            className={`swift-stock-management-detail-change ${
              parseFloat(stock_data.change_percentage) >= 0
                ? "positive"
                : "negative"
            }`}
          >
            {stock_data.change_price} (
            {parseFloat(stock_data.change_percentage) >= 0 ? "+" : ""}
            {stock_data.change_percentage})
          </p>
        </div>
      </div>

      <p className="swift-stock-management-detail-row-2-left-title">
        Key Financials
      </p>
      <div className="swift-stock-management-detail-row-2-left-detail">
        {Object.entries(data).map(([title, value]) => (
          <RenderRow key={title} title={title} value={value} />
        ))}
      </div>
    </div>
  );
};

const About = ({ openAboutModal, isVisible }) => {
  return (
    <div
      className="swift-stock-management-detail-row-2-mid"
      style={{ display: !isVisible ? "none" : "flex" }}
    >
      <p className="swift-stock-management-detail-row-2-left-title">
        About the buisness
      </p>
      <div className="swift-stock-management-detail-row-2-mid-detail">
        <div>
          <p className="swift-stock-management-detail-row-2-mid-detail-title">
            Sales
          </p>
          <p className="swift-stock-management-detail-row-2-mid-detail-value">
            Polycab is India’s leading manufacturers of cables and wires and
            allied products such as uPVC conduits and lugs and glands.
          </p>
        </div>
        <div>
          <p className="swift-stock-management-detail-row-2-mid-detail-title">
            Regional Breakup
          </p>
          <p className="swift-stock-management-detail-row-2-mid-detail-value">
            India- 90%
            <br></br>
            USA- 10%.
          </p>
        </div>
        <div>
          <p className="swift-stock-management-detail-row-2-mid-detail-title">
            Sales
          </p>
          <p className="swift-stock-management-detail-row-2-mid-detail-value">
            Polycab is India’s leading manufacturers of cables and wires and
            allied products such as uPVC conduits and lugs and glands.
          </p>
        </div>
      </div>
      <div
        className="swift-stock-management-detail-right-btn mid"
        onClick={openAboutModal}
      >
        <button>
          More
          <ArrowRight2 size={20} color={COLOR_VARS["SWIFT_COLOR1"]} />
        </button>
      </div>
    </div>
  );
};

const StockDetail = ({
  data,
  newsData,
  stock_data,
  stock_info,
  openFeedModal,
  technicals,
}) => {
  const [keyFinanceVisible, setKeyFinanceVisible] = useState(true);
  const [aboutVisible, setAboutVisible] = useState(false);
  const [techVisible, setTechVisible] = useState(false);
  const [feedVisible, setFeedVisible] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      if (DEVICE_SIZE != 'S') {
        setKeyFinanceVisible(true);
        setAboutVisible(true);
        setTechVisible(true);
        setFeedVisible(true);
      } else {
        setKeyFinanceVisible(true);
        setAboutVisible(false);
        setTechVisible(false);
        setFeedVisible(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="swift-stock-management-detail" id="overview">
      <div className="swift-stock-management-detail-row-2">
        <div className="swift-stock-management-detail-row-2-menu">
          <div
            onClick={() => {
              setKeyFinanceVisible(true);
              setAboutVisible(false);
              setTechVisible(false);
              setFeedVisible(false);
            }}
            className={
              "swift-stock-management-detail-menu-btn " +
              (keyFinanceVisible ? "active" : "")
            }
          >
            Key Financials
          </div>
          <div
            onClick={() => {
              setKeyFinanceVisible(false);
              setAboutVisible(false);
              setTechVisible(true);
              setFeedVisible(false);
            }}
            className={
              "swift-stock-management-detail-menu-btn " +
              (techVisible ? "active" : "")
            }
          >
            Technicals
          </div>
          <div
            onClick={() => {
              setKeyFinanceVisible(false);
              setAboutVisible(false);
              setTechVisible(false);
              setFeedVisible(true);
            }}
            className={
              "swift-stock-management-detail-menu-btn " +
              (feedVisible ? "active" : "")
            }
          >
            Feed
          </div>
        </div>
        <KeyFinanace
          data={data}
          isVisible={keyFinanceVisible}
          stock_data={stock_data}
          stock_info={stock_info}
        />
        <StockTechnical technicals={technicals} isVisible={techVisible} />
        <StockFeed newsData={newsData} openFeedModal={openFeedModal} isVisible={feedVisible} />
      </div>
    </div>
  );
};

export default StockDetail;
