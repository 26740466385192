import React, { useState } from 'react';
import { ArrowRight2, ArrowDown2, Cross, FilterSearch } from '../../../CustomComponents/SwiftIcon/Icons';
import ApplySmallcaseRow from './ApplySmallcaseRow';
import { titleCase, priceFormat } from '../../../../exports/UtilFunctions';

function CustomerTableRow({ index, data, removeCustomerStockBulk, removeCustomerStock }) {

    const { config, stocks } = data;

    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState('');

    return (
        <>
            <div className='customer__stocks__row'>
                <div className='stocks__row__config'>
                    <div className='config__box arrow' onClick={() => { setOpen(!open); setFilter('') }}>
                        {open ? <ArrowDown2 size={22} color="#192b40" /> : <ArrowRight2 size={22} color="#192b40" />}
                    </div>
                    <div className='config__box name' title={titleCase(config.name)}><span>{titleCase(config.name)}</span></div>
                    <div className='config__box strategy' title={titleCase(config.strategy)}><span>{config.strategy}</span></div>
                    <div className='config__box cash'><span>{priceFormat(config.cash_position)}</span></div>
                    <div className='config__box trades'><span>{config.count}</span></div>
                    <div className='config__box buy'><span>{priceFormat(config.stocks_buy_value)}</span></div>
                    <div className='config__box sell'><span>{priceFormat(config.stocks_sell_value)}</span></div>
                    <div className='config__box brpkerage'><span>{priceFormat(parseFloat(config.stocks_buy_brokerage)+ parseFloat(config.stocks_sell_brokerage))}</span></div>
                    <div className='config__box sell'><span>{priceFormat(config.post_trade_cash)}</span></div>
                    <div className='config__box remove' onClick={() => {
                        removeCustomerStockBulk(config.account_code);
                    }}>
                        <Cross size={22} color="#192b40" />
                    </div>
                </div>
            </div>
            <div className={'stocks__container__wrapper ' + (open ? '' : 'hide')}>
                <div className='stocks__options'>
                    <p>Generated Trades</p>
                    <div className='customer__stock__search'>
                        <input placeholder='Search symbol' value={filter} onChange={(e) => { setFilter(e.target.value.toUpperCase()) }} />
                        <div className='filter-icon'><FilterSearch size={20} color="#192b40" /></div>
                    </div>
                </div>
                <div className='smallcase__portfolio__stocks'>
                    <div className='smallcase__portfolio__table'>
                        <table>
                            <thead>
                                <tr>
                                    <td>Name</td>
                                    <td>Quantity</td>
                                    <td>Port Wt</td>
                                    <td>Target Wt</td>
                                    <td>Proposed Wt</td>
                                    <td>Active Wt</td>
                                    <td>Target Qty</td>
                                    <td>Trade</td>
                                    <td>Current Price</td>
                                    <td>Trade Value</td>
                                    <td>Brokerage</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>

                                {stocks.map((s, index) => {
                                    return <ApplySmallcaseRow key={index} show={s.stockCode.includes(filter)} account_code={config.account_code} data={s} index={index} removeStock={removeCustomerStock} />
                                })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomerTableRow;