import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import Pulse from '../../Loader/Pulse';
import TradesTable from './TradesTable';
import UploadTrades from './UploadTrades';
import { Book,DocumentText } from '../../CustomComponents/SwiftIcon/Icons';
import CustomIconButton from '../../Body/CustomChartComponents/CustomIconButton/CustomIconButton';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function TradesManagement() {

    const queryParameters = new URLSearchParams(window.location.search);
    const token = queryParameters.get("token");

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [uploadOpen, setUploadOpen] = useState(false);
    const [executionCount, setExecutionCount] = useState(0);


    useEffect(() => {
        axios.get(`${REQUEST_BASE_URL}/trades/broker?token=${token}`).then((response) => {
            const data = response.data;
            if (!data.error) {
                setData(data.data);
                setLoading(false);
            }
        })
    }, [executionCount]);

    if (!token) {
        return <Navigate to="/404" />;
    }

    if (loading || !data) {
        return <div className='swift-broker-trades-management loader'>
            <Pulse />
            <p>Loading trades, please wait...</p>
        </div>
    }

    return (
        <div className='swift-broker-trades-management'>

            {uploadOpen && <><UploadTrades closeUpload={() => { setUploadOpen(false) }} updateCount={() => { setExecutionCount(executionCount + 1) }} /></>}
            <div className='client-trade-header'>
                <div className='client-header-text'>
                    <p className='client-title'>Trade Confirmation Dashboard <span>({data.broker_config?.broker})</span></p>
                </div>
                <div className='client-header-button'>
                    <CustomIconButton icon={<DocumentText size={20} color="#192b40" />} title="Upload Trades" onClick={() => { setUploadOpen(true) }} />
                </div>
            </div>
            <TradesTable trades={data['trades']} />
        </div>
    )
}

export default TradesManagement;