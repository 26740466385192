import React from 'react';
import Axios from 'axios';
import { Link, Navigate } from "react-router-dom";
import Pulse from '../Loader/Pulse';
import '../../css/LoginSignup/Login.css';
import { GetCookie, SetCookie } from '../../exports/CookieManagement';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;


class Login extends React.PureComponent {

    constructor(props)
    {
        super(props);
        this.state = {
            isLoading : true,
            isLoggedIn: false,
            loadingMsg: 'We are trying to login...',
            opacityMsg: true,
        }
        this.changeLoadingMsg = this.changeLoadingMsg.bind(this);
    }

    componentDidMount()
    {
        this.changeLoadingMsg();
        setTimeout(() => {

            const account_token = GetCookie('token');

            Axios.get(`${REQUEST_BASE_URL}/checktoken?token=${account_token}`).then((res) => {
                if (res.status === 200 && !res.data.error) {
                    SetCookie('role', res.data.user_role);
                    setTimeout(() => {
                        this.setState({
                            isLoggedIn: true,
                            isLoading: false
                        });
                        clearInterval(this.loadingInterval);
                    }, 5000)
                }
                else {
                    setTimeout(() => {
                        this.setState({
                            isLoading: false
                        });
                    }, 2000)
                }
            }).catch(() => {
                setTimeout(() => {
                    this.setState({
                        isLoading: false
                    });
                }, 2000)
            });
        }, 1000);
    }

    changeLoadingMsg() {

        let displayMsg = setInterval(() => {
            if (this.state.isLoggedIn) {
                clearInterval(displayMsg)
            }
            else {
                this.setState({ loadingMsg: (this.state.loadingMsg == 'We are trying to login...') ? 'Please wait for few seconds!' : 'We are trying to login...' })
            }
        }, 3000);

        let displayOpacity = setInterval(() => {
            if (this.state.isLoggedIn) {
                clearInterval(displayOpacity)
            }
            else {
                this.setState({ opacityMsg: (this.state.opacityMsg == false) ? true : false })
            }
        }, 1500);

    }

    render()
    {

        const { isLoading, isLoggedIn, loadingMsg, opacityMsg } = this.state;
        if(isLoading)
        {
            return <div className="app__login__screen">
                <div className="app__login__process">
                    <Pulse />
                    <p className={opacityMsg ? 'fadeIn' : 'fadeOut'}>{loadingMsg}</p>
                </div>
            </div>
        }
        else
        {
            if(isLoggedIn)
            {
                return <Navigate to="/account" />
            }
            else
            {
                return <Navigate to="/404" />
            }
        }
    }
}

export default Login;
