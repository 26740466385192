import React from 'react';
import Axios from 'axios';
import moment from 'moment';
import OrderSelect from '../OrderSelect';
import ShareSplitPopup from './ShareSplitPopup';
import ManualTrade from './ManualTrade';
import TWTrade from './TWTrade';
import VWTrade from './VWTrade';
import DripFeedTrade from './DripFeedTrade';
import TRADEVALUE from '../../../assets/backoffice/tradevalue.svg';
import ACCOUNT from '../../../assets/backoffice/account.svg';
import SEARCHADD from '../../../assets/backoffice/search-add.svg';


import SearchBar from '../../Body/MenuSection/PortfolioComponents/SearchBar';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;
const MARKET_END_HOUR = 16;
const MARKET_END_MINUTE = 15;


class CPTrade extends React.PureComponent {

    constructor(props) {

        super(props);
        this.state = {
            trade_codes: ['7GGY23358', '7GGY23356', '7GGY23288', '7GGY23326', '7GGY23562'],
            cp_codes: ['ORBIS0003228', 'ORBIS0002365', 'ORBIS0005236', 'ORBIS0001235', 'ORBIS0002564'],
            selectedAccount: null,
            activeWindow: 1,
            data: [],
            totalstocks: 0,
            Existing: [],
            TotalTrade: 0,
            ShareSplitConfig: {
                isActive: false,
                timeInterval: 0,
                sharesArray: [],
            },
            ordererror: false
        };
        this.AccountSelectSection = this.AccountSelectSection.bind(this);
        this.AccountDetailsSection = this.AccountDetailsSection.bind(this);
        this.TradeTabsSection = this.TradeTabsSection.bind(this);
        this.addTableRow = this.addTableRow.bind(this);
        this.deleteStock = this.deleteStock.bind(this);
        this.setData = this.setData.bind(this);
        this.calculateCash = this.calculateCash.bind(this);
        this.openShareSplit = this.openShareSplit.bind(this);
        this.closeShareSplit = this.closeShareSplit.bind(this);
        this.ExecuteOrder = this.ExecuteOrder.bind(this);
    }

    AccountSelectSection() {
        const { trade_codes, selectedAccount } = this.state;
        return <div className='account__selection'>
            <div className="getOrder__header__comp">
                <span>
                    <OrderSelect
                        defaultIndex={trade_codes.indexOf(selectedAccount)}
                        placeholder="Select Account"
                        options={trade_codes}
                        width={240}
                        height={40}
                        onTypeChange={(value) => {
                            this.setState({
                                selectedAccount: value
                            })
                        }}
                    />
                </span>
            </div>
        </div>
    }

    AccountDetailsSection() {

        const { TotalTrade } = this.state;

        const curr = (val) => parseFloat(val).toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
        const { trade_codes, cp_codes, selectedAccount } = this.state;
        const trade_index = trade_codes.indexOf(selectedAccount);
        const cp_code = cp_codes[trade_index];

        return <div className="getOrder__header__wrapper no-space">
            <div className="getOrder__details">
                <img src={ACCOUNT} alt="X" />
                <div>
                    <span className="getOrder__details__heading">Account CP Code</span>
                    <span className="getOrder__details__val">{cp_code}</span>
                </div>
            </div>
            <div className="getOrder__details">
                <img src={TRADEVALUE} alt="X" />
                <div>
                    <span className="getOrder__details__heading">Total Trade Value</span>
                    <span className="getOrder__details__val">{curr(TotalTrade)}</span>
                </div>
            </div>
        </div>
    }

    TradeTabsSection() {

        const { activeWindow } = this.state;

        return <div className="getOrder__add__import">

            <div className='getOrder__sections'>
                <div className={activeWindow == 1 ? "order__section active" : "order__section"} onClick={() => {
                    this.setState({
                        activeWindow: 1
                    })
                }}><span>Manual Trading</span></div>
                <div className={activeWindow == 2 ? "order__section active" : "order__section"} onClick={() => {
                    this.setState({
                        activeWindow: 2
                    })
                }}><span>Time Weighted (TW)</span></div>
                <div className={activeWindow == 3 ? "order__section active" : "order__section"} onClick={() => {
                    this.setState({
                        activeWindow: 3
                    })
                }}><span>Volume Weighted (VW)</span></div>
                <div className={activeWindow == 4 ? "order__section active" : "order__section"} onClick={() => {
                    this.setState({
                        activeWindow: 4
                    })
                }}><span>Drip Feed</span></div>
            </div>
            <div className="order__search">
                <div className="getOrder__add">
                    <SearchBar addTableRow={this.addTableRow} />
                </div>
            </div>
        </div>
    }

    async addTableRow(stock) {

        let { data, Existing, selectedAccount } = this.state;
        let code = (stock.exchange.exchange.toLowerCase() == 'nse') ? stock.nse_code : stock.code;
        if (!(data.map(el => el.isin).includes(stock.isin))) {
            const obj = {
                Code: stock['code'],
                Exchange: stock.exchange.code,
                AccountCode: selectedAccount,
                StockCode: code,
                Company: stock['company'],
                Quantity: 0,
                Side: "Buy",
                isin: stock.isin,
                Bid: 0,
                Ask: 0,
                LTP: 0,
                TradeVolume: 0,
                AvgTradeVolume: 0,
                Bounds: [],
                OrderType: "Market",
                LimitPrice: "Current LTP",
                LMPrice: 0,
                TradeValue: 0,
                Liquidity: 0,
                ShareSplit: [],
                OrderInterval: false,
                DisErr: false,
                ErrMsg: null,
            }

            const bounds = await this.fetchStockData(stock);
            // console.log(bounds)
            obj.Bounds = bounds;

            Axios.get(`${REQUEST_BASE_URL}/fetch_tick_size/${stock.exchange.exchange}/${obj.Code}`).then((response) => {
                const res = response.data;
                obj.TickSize = res.tick_size
                obj.HigherLimit = res.higher_circuit_limit
                obj.LowerLimit = res.lower_circuit_limit

                let a = Existing.filter(el => el.isin == obj.isin);
                obj.QOrderBook = a.length == 1 ? a[0].QOrderBook : a.length > 1 ? a.reduce((a, b) => a + b.QOrderBook, 0) : 0;
                obj.QDP = a.length == 1 ? a[0].QDP : a.length > 1 ? a.reduce((a, b) => a + b.QDP, 0) : 0;
                obj.Status = '-';

                data.push(obj);
                // this.setState({ fileloaded: true })
                this.calculateCash(data)
            })
        } else {
            this.displayAlert("You already include one stock with the same isin. Please check and either buy in nse or bse. Or buy nse in one order and bse in another order.", 10)
        }

    }

    async fetchStockData(stock) {
        return new Promise((resolve, reject) => {
            Axios.get(`${REQUEST_BASE_URL}/stockdata`, {
                params: {
                    'ct': 1,
                    'starttime': '1644403239',
                    'dd': 1,
                    'exchange': stock.exchange.exchange,
                    'token': stock.code,
                    'code': stock.symbol,
                    'mixed': "false",
                    'type': '',
                    'index': "false"
                }
            }).then((res) => {
                let data = res.data;
                if (data.status === "success") {
                    let bounds = this.calculateBounds(data.data);
                    console.log(bounds)
                    resolve(bounds)
                }
                else {
                    resolve([0, 0])
                }
            })
        })
    }

    calculateCash(data) {

        let totalTrade = 0, tradeVal = 0
        data.forEach((v, i) => {
            totalTrade += Math.abs(parseFloat(v.TradeValue))
            tradeVal += parseFloat(v.TradeValue)
        });


        this.setState({
            data: data,
            TotalTrade: totalTrade,
            totalstocks: data.length
        })

        return true
    }

    setData(stockData, StockCode, type) {
        const { data } = this.state;
        let newData = JSON.parse(JSON.stringify(data))
        let row = null;
        for (let el of newData) {
            if (el.StockCode == StockCode) {
                row = el;
                break;
            }
        }

        if (!row) return;

        let price;
        if (type === "Bid") {
            price = stockData ? stockData.best_bid_price : 0;
        }
        else if (type === "Ask") {
            price = stockData ? stockData.best_ask_price : 0;
        }
        else {
            price = stockData ? stockData.last_traded_price : 0;
        }
        // row[type] = type === "Bid" ? stockData.best_bid_price : type === "Ask" ? stockData.best_ask_price : stockData.last_traded_price;
        row[type] = price;
        row['TradeVolume'] = stockData ? stockData.trade_volume : 0;

        if (row.OrderType === "Market") {
            row.LMPrice = row.LTP;
            row.TradeValue = row.Side === "Buy" ? row.LMPrice * row.Quantity : -row.LMPrice * row.Quantity;
        }
        this.calculateCash(newData)
    }

    deleteStock(index) {
        let data = JSON.parse(JSON.stringify(this.state.data));
        data.splice(index, 1);
        this.setState({
            data: data,
            totalstocks: data.length
        }, () => {
            this.calculateCash(data);
        })
    }

    openShareSplit(timeInterval, sharesArray) {
        this.setState({
            ShareSplitConfig: {
                isActive: true,
                timeInterval: timeInterval,
                sharesArray: sharesArray
            }
        })
    }

    closeShareSplit() {
        this.setState({
            ShareSplitConfig: {
                isActive: false,
                timeInterval: 0,
                sharesArray: []
            }
        })
    }

    calculateBounds(data) {
        let closearray = [];
        if (data.length > 0) {
            data = data.slice(Math.max(data.length - 10, 0))
            data.forEach(d => {
                closearray.push(d[4]);
            });

            let mean = this.calculateMean(closearray);
            let SD = this.calculateSD(mean, closearray);
            return [parseFloat(mean + SD).toFixed(2), parseFloat(mean - SD).toFixed(2)];
        }
        else {
            return [0, 0];
        }
    }

    calculateMean(array) {
        const n = array.length
        return array.reduce((a, b) => a + b) / n
    }

    calculateSD(mean, array) {
        const n = array.length
        return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
    }

    isMarketClosed() {
        return moment().diff(moment().set('hour', MARKET_END_HOUR).set('minutes', MARKET_END_MINUTE), 'minutes') > 0;
    }
    ExecuteOrder() {
        if (this.isMarketClosed()) {
            this.setState({
                ordererror: true
            })
        }

        setTimeout(() => {
            this.setState({
                ordererror: false
            })
        }, 3000)
    }

    render() {
        const { selectedAccount, data, totalstocks, activeWindow, ShareSplitConfig, ordererror } = this.state;
        return (
            <>

                {ShareSplitConfig.isActive &&
                    <ShareSplitPopup
                        interval={ShareSplitConfig.timeInterval}
                        shares={ShareSplitConfig.sharesArray}
                        closePopup={this.closeShareSplit}
                    />
                }

                <this.AccountSelectSection />
                {selectedAccount ?
                    <>
                        <this.AccountDetailsSection />
                        <this.TradeTabsSection />
                        {data.length > 0 ?
                            <>
                                {activeWindow === 1 &&
                                    <ManualTrade
                                        data={data}
                                        totalstocks={totalstocks}
                                        setData={this.setData}
                                        calculateCash={this.calculateCash}
                                        deleteStock={this.deleteStock}
                                    />

                                }
                                {activeWindow === 2 &&
                                    <TWTrade
                                        data={data}
                                        totalstocks={totalstocks}
                                        setData={this.setData}
                                        calculateCash={this.calculateCash}
                                        deleteStock={this.deleteStock}
                                        openShareSplit={this.openShareSplit}
                                    />
                                }
                                {activeWindow === 3 &&
                                    <VWTrade
                                        data={data}
                                        totalstocks={totalstocks}
                                        setData={this.setData}
                                        calculateCash={this.calculateCash}
                                        deleteStock={this.deleteStock}
                                        openShareSplit={this.openShareSplit}
                                    />
                                }
                                {activeWindow === 4 &&
                                    <DripFeedTrade
                                        data={data}
                                        totalstocks={totalstocks}
                                        setData={this.setData}
                                        calculateCash={this.calculateCash}
                                        deleteStock={this.deleteStock}
                                        openShareSplit={this.openShareSplit}
                                    />
                                }
                                <div className='getOrder__footer'>
                                    <div className='order__button'>
                                        <button onClick={() => { this.ExecuteOrder() }}>Place Order</button>
                                    </div>
                                    <div className='getOrder__message'>
                                        {ordererror ?
                                            <p id="error">Market is currently closed!</p>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="getOrder__table__empty">
                                    <>
                                        <img src={SEARCHADD} alt="" />
                                        <p>Search and add symbol to place the order</p>
                                    </>
                                </div>
                                :
                            </>
                        }
                    </>
                    :
                    <>
                        <div className="container__empty">
                            <p>Please select an account to get started</p>
                        </div>
                    </>
                }
            </>
        )
    }
}

export default CPTrade;