import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryObject = {};

  for (const [paramName, paramValue] of queryParams.entries()) {
    queryObject[paramName] = paramValue;
  }

  return queryObject;
};

export default useQueryParams;