import React from "react";
import WatchList from "./WatchList";
import "../../../css/CashPosition.css";


function CashPosition() {


  return (
    <div className="cash__position__wrapper">
        <div className="cash__position">
            <div className="cp__data">
                <WatchList />
            </div>
        </div>
    </div>
  );
}

export default CashPosition;
