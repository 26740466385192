import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import MACSearch from '../CustomComponents/MACSearch/MACSearch';
import CustomSelect from '../Body/CustomChartComponents/CustomSelect/CustomSelect';
import CustomIconButton from '../Body/CustomChartComponents/CustomIconButton/CustomIconButton';
import { ArrowDown } from '../CustomComponents/SwiftIcon/Icons';
import { Alert } from '../Body/CustomChartComponents/CustomAlert/CustomAlert';
import Pulse from '../Loader/Pulse';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const report_types = ['Factsheet Statement', 'Gain & Loss Statement'];
const report_targets = ['Individual Customer', 'Family Portfolio'];

function ReportGenerate({ user_role_type, customers, portfolios }) {

    const [loading, setLoading] = useState(false);
    const [accountCode, setAccountCode] = useState(false);
    const [portfolioID, setPortfolioID] = useState(false);
    const [reportTarget, setReportTarget] = useState(report_targets[0]);
    const [reportType, setReportType] = useState(report_types[0]);
    const [reportYear, setReportYear] = useState(false);
    const [reportYears, setReportYears] = useState([]);

    useEffect(() => {
        if (user_role_type === 'CUSTOMER' && reportTarget === report_targets[0]) {
            if (customers.length === 0) return;
            setCustomer(customers[0]);
        }

    }, [accountCode, customers, reportTarget]);

    function setCustomer(customer) {
        const account_code = customer.account_code
        setAccountCode(account_code);
        getFYears('customer', false, account_code);
    }

    function setPortfolio(portfolio) {
        const portfolio_id = portfolio.family_portfolio_id;
        setPortfolioID(portfolio_id);
        getFYears('family', portfolio_id, false);
    }

    function getFYears(report_target, portfolio_id = false, account_code = false) {
        Axios.get(`${REQUEST_BASE_URL}/clientinfo/fyears?client_type=${report_target}&portfolio_id=${portfolio_id}&account_code=${account_code}`).then((response) => {
            const data = response.data;
            console.log(data)
            if (!data.error) {
                setReportYears(data.years);
                setReportYear(data.years[data.years.length - 1]);
            }
            else {
                setReportYears([]);
                setReportYear(false);
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    function generateReport() {

        const report_target = reportTarget == 'Individual Customer' ? 'customer' : 'family';
        setLoading(true);
        if (reportType === 'Factsheet Statement') {
            Axios.get(`${REQUEST_BASE_URL}/reports/factsheet?report_type=${report_target}&portfolio_id=${portfolioID}&account_code=${accountCode}`).then((response) => {
                const data = response.data;
                if (!data.error) {
                    setLoading(false);
                    const report_url = data.report_url;
                    DownloadReport(report_url);
                }
                else {
                    setLoading(false);
                    Alert({
                        TitleText: 'Report Download',
                        Message: 'Something went wrong while downloading the report ',
                        BandColor: '#E51A4B',
                        AutoClose: {
                            Active: true,
                            Line: true,
                            LineColor: '#E51A4B',
                            Time: 3
                        }
                    })
                }
            }).catch((error) => {
                console.log(error)
            });
        }
        else {
            Axios.get(`${REQUEST_BASE_URL}/reports/gainloss?report_type=${report_target}&portfolio_id=${portfolioID}&account_code=${accountCode}&fy=${reportYear}`).then((response) => {
                const data = response.data;
                if (!data.error) {
                    setLoading(false);
                    const report_url = data.report_url;
                    DownloadReport(report_url);
                }
                else {
                    setLoading(false);
                    Alert({
                        TitleText: 'Report Download',
                        Message: 'Something went wrong while downloading the report ',
                        BandColor: '#E51A4B',
                        AutoClose: {
                            Active: true,
                            Line: true,
                            LineColor: '#E51A4B',
                            Time: 3
                        }
                    })
                }
            }).catch((error) => {
                console.log(error)
            });
        }
    }

    function DownloadReport(report_url) {

        const element = document.createElement("a");
        element.target = "_blank";
        element.href = report_url;
        element.download = "true";
        document.body.appendChild(element);
        element.click();

        setAccountCode(false);
        setReportYears([]);
        setPortfolioID(false);
        setReportType(report_types[0]);
        setReportYear(false);
        setLoading(false);
        setReportTarget(report_targets[0]);
    }

    function setTarget(report_target) {
        setAccountCode(false);
        setPortfolioID(false);
        setReportType(report_types[0]);
        setReportYear(false);
        setReportYears([]);
        setReportTarget(report_target);
    }

    return (
        <>
            <div className='report-generate-header'>
                <p className='report-generate-title'>Generate Report</p>
            </div>
            <div className='report-generate-content'>
                {loading && <>
                    <div className='report-generate-loading'>
                        <Pulse />
                        <p>Generating report, please wait...</p>
                    </div>
                </>}

                <div className='report-section-target'>
                    <CustomSelect
                        heading="Report Type"
                        options={report_targets}
                        defaultIndex={0}
                        value={reportTarget}
                        onTypeChange={(value) => { setTarget(value) }}
                    />
                </div>

                {reportTarget === 'Individual Customer' ? <>
                    {user_role_type === 'BROKER' ? <>
                        <div className='report-section-customer'>
                            <MACSearch title='Search Customer' showSearch={accountCode} customers={customers} onSelect={(customer) => { setCustomer(customer) }} />
                        </div>
                    </> :
                        <></>
                    }
                </> : <>
                        <MACSearch title='Search Family Portfolio' placeholder='Enter Portfolio Name' showSearch={portfolioID} customers={portfolios} onSelect={(portfolio) => { setPortfolio(portfolio) }} />
                </>}
                {(accountCode || portfolioID) && <>
                    <div className='report-generate-options'>
                        <div className='report-section-type'>
                            <CustomSelect
                                heading="Type of Statement"
                                options={report_types}
                                defaultIndex={0}
                                value={reportType}
                                onTypeChange={(value) => { setReportType(value) }}
                            />
                        </div>
                        {reportType === 'Gain & Loss Statement' && <>
                            <div className='report-generate-year'>
                                <CustomSelect
                                    heading="Financial Year"
                                    options={reportYears}
                                    defaultIndex={reportYears.length - 1}
                                    value={reportYear}
                                    onTypeChange={(value) => { setReportYear(value) }}
                                />
                            </div>
                        </>}
                    </div>
                </>}

                <div className='report-section-button'>
                    <CustomIconButton
                        disabled={(!accountCode && !portfolioID)}
                        icon={<ArrowDown size={20} />}
                        onClick={generateReport}
                        title="Download" />
                </div>
            </div>
        </>
    )
}

export default ReportGenerate