import React from "react";
import { Link, useNavigate  } from "react-router-dom";
import { ArrowRight2 } from "../CustomComponents/SwiftIcon/Icons";
import AccessHeader from "./AccessHeader";

function AccessSuccess() {

  const navigate = useNavigate();

  function NavigateHome() {
    navigate("/");
  }

  return (
    <>
      <div className="user-login">
        <div className="user-login-popup-wrapper">
          <div className="login-header">
            <AccessHeader header_text="SignUp" />
          </div>
          <div className="login-content">
            <div className="login-body">
              <div className="login-notes">
                <p className="login-note">
                  We are trying to get you onboarded as soon as possible.
                </p>
                <p className="login-note">
                  You will have received an email with e-KYC link from
                  technology@swiftfolios.com. Please kindly complete the KYC
                  process. Trading/ Demat account creation will take atleast 1-2
                  working days.
                </p>
                <p className="login-note">
                  For anything else, kindly reach out to us on{" "}
                  <span style={{ fontWeight: 700 }}>
                    <a href="mailto:accounts@swiftfolios.com">accounts@swiftfolios.com</a>
                  </span>
                </p>
              </div>
            </div>
            <div className="login-footer">
              <div className="swift-button-icon" onClick={NavigateHome}>
                <div className="button-icon">
                  <ArrowRight2 size={20} />
                </div>
                <p>Go Home</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AccessSuccess;
