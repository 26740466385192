import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { NotificationStatus } from '../CustomComponents/SwiftIcon/Icons';
import { GetCookie } from '../../exports/CookieManagement';
import ReportHistoryRow from './ReportHistoryRow';
import Pulse from '../Loader/Pulse';
import { COLOR_VARS } from '../../exports/ColorVars';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

function ReportHistory() {

    const [loading, setLoading] = useState(true);
    const [history, setHistory] = useState([]);

    useEffect(() => {
        GetHistory();
    }, []);

    function GetHistory() {
        const account_token = GetCookie('token');
        Axios.get(`${REQUEST_BASE_URL}/reports/history?token=${account_token}`).then((response) => {
            const data = response.data;
            if (!data.error) {
                setHistory(data.data);
            }
            setLoading(false);
        });
    }

    return (
        <>
            {loading ? <>
                <div className='report-history-loading'>
                    <Pulse />
                    <p>Loading history... please wait</p>
                </div>
            </> : <>
                <div className='report-history-header'>
                    <p className='report-history-title'>Report History</p>
                </div>
                <div className='report-history-content'>
                    {history.length === 0 ? <>
                        <div className='report-history-empty'>
                            <NotificationStatus size={50} color={COLOR_VARS['SWIFT_COLOR4']} />
                            <p>You don't have any report history</p>
                        </div>
                    </> : <>

                        {history.map((h, index) => {
                            return <ReportHistoryRow key={index} data={h} />
                        })}

                    </>}

                </div>
            </>}
        </>
    )
}

export default ReportHistory;