import React from "react";
import SwiftModal from "../../../CustomComponents/SwiftModal/SwiftModal";
import StockOrderPopup from "./StockOrderPopup";

function OrderPopup({closeModal,config,top="1%"}) {

  const {side="B",symbol=""} = config;

  return (
    <>
      <SwiftModal
        top={top}
        closeModal={closeModal}
      >
        <StockOrderPopup
          side={side}
          symbol={symbol}
          {...config}
          closepopup={closeModal}
        />
      </SwiftModal>
    </>
  );
}

export default OrderPopup;
