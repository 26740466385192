import { useEffect, useState } from 'react';
import Axios from 'axios';
import { GetCookie } from '../exports/CookieManagement';

const REQUEST_BASE_URL = process.env.REACT_APP_REQUEST_BASE_URL;

const useTradeInfo = (order_id)=>{
  const [tradeInfo,setTradeInfo] = useState(false);

  useEffect(()=>{
    GetOrderInfo(order_id);

    const update_interval = setInterval(()=>{
      GetOrderInfo(order_id);
    },5000);

    return ()=>{
      if(update_interval){
        clearInterval(update_interval);
      }
    }

  },[order_id]);

  function GetOrderInfo(order_id){
    const account_token = GetCookie('token');
    Axios.get(`${REQUEST_BASE_URL}/order/trade?token=${account_token}&order_id=${order_id}`).then((response) => {
      const data = response.data;
      if (!data.error) {
        const order = data.data;
        setTradeInfo(order);
        
      }
      else {
        setTradeInfo({});
      }
  })
  .catch((err) => {
    setTradeInfo({});
  });
  }

  return tradeInfo;

}

export default useTradeInfo;