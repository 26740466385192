import React, { useEffect, useState } from "react";
import ServerRequest from "../../exports/ServerRequest";
import { Alert } from "../Body/CustomChartComponents/CustomAlert/CustomAlert";
import SwiftModal from "../CustomComponents/SwiftModal/SwiftModal";
import CustomSelect from "../Body/CustomChartComponents/CustomSelect/CustomSelect";
import CustomInput from "../Body/CustomChartComponents/CustomInput/CustomInput";
import {
  ArrowRight2,
  Cross,
  InfoCircle,
} from "../CustomComponents/SwiftIcon/Icons";
import { GetCookie } from "../../exports/CookieManagement";
import CustomIconActionButton from "../Body/CustomChartComponents/CustomIconActionButton/CustomIconActionButton";
import { GetRoleType } from "../../exports/RoleTypes";
import Pulse from "../Loader/Pulse";
import MACSearch from "../CustomComponents/MACSearch/MACSearch";
import { numberFormat } from "../../exports/UtilFunctions";
import FundsProcessPopup from "./FundsProcessPopup";
import { COLOR_VARS } from "../../exports/ColorVars";
import { getDeviceSize } from "../../exports/InteractiveUtils";

const request_types = ["Add Funds", "Withdraw Funds"];
const warning_config = {
  Band: true,
  BandColor: "#470985",
  BoxColor: "#ffffff",
  TextColor: "#000000",
  AutoClose: {
    Active: true,
    Line: true,
    LineColor: "#470985",
    Time: 3,
  },
};

function FundsRequest({ setHistoryFlag, close = null }) {
  const user_role_type = GetRoleType(GetCookie("role"));
  const account_token = GetCookie("token");

  const [popup, setPopup] = useState(false);
  const [customer, setCustomer] = useState(undefined);
  const [customers, setCustomers] = useState(undefined);
  const [brokers, setBrokers] = useState(undefined);
  const [action, setAction] = useState("default");

  const [cash, setCash] = useState(undefined);
  const [requestType, setRequestType] = useState(request_types[0]);
  const [broker, setBroker] = useState(undefined);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState("");
  const [utr, setUtr] = useState("");
  const [utrError, setUtrError] = useState("");

  useEffect(() => {
    async function GetCustomerDetails(token) {
      const data = await ServerRequest({
        method: "GET",
        url: `/clientinfo/swiftaccount?token=${token}`,
      });
      return data;
    }

    async function GetMACustomers(token) {
      const data = await ServerRequest({
        method: "GET",
        url: `/portfolio/customers?token=${token}`,
      });
      return data;
    }

    if (user_role_type === "CUSTOMER") {
      GetCustomerDetails(account_token).then((response) => {
        const { error, data } = response;
        if (error || !data) {
          setCustomers([]);
        } else {
          setCustomers([data]);
          setCustomer(data);
        }
      });
    } else if (user_role_type === "BROKER") {
      GetMACustomers(account_token).then((response) => {
        const { error, customers } = response;
        if (error) {
          setCustomers([]);
        } else {
          setCustomers(customers);
        }
      });
    } else {
    }
  }, []);

  useEffect(() => {
    async function GetCashPosition(account_code) {
      const data = await ServerRequest({
        method: "GET",
        url: `/clientinfo/cashposition?account_code=${account_code}`,
      });
      return data;
    }

    async function GetCustomerBrokers(account_code) {
      const data = await ServerRequest({
        method: "GET",
        url: `/clientinfo/brokers?account_code=${account_code}`,
      });
      return data;
    }

    if (!customer) return;

    setCash(undefined);
    ResetForm(false);
    const { account_code } = customer;

    GetCashPosition(account_code).then((response) => {
      const { error, cash } = response;

      if (error) {
        setCash(0);
      } else {
        setCash(cash);
      }
    });

    GetCustomerBrokers(account_code).then((response) => {
      const { error, brokers } = response;

      if (error) {
        setBrokers([]);
      } else {
        if (!brokers || !Array.isArray(brokers) || brokers.length == 0) {
          setBrokers([]);
        } else {
          const brokers_list = brokers.map((b) => {
            return b["broker"];
          });

          setBrokers(brokers_list);
          setBroker(brokers_list[0]);
        }
      }
    });
  }, [customer]);

  useEffect(() => {
    setAmount("");
    setUtr("");
    setAmountError("");
    setUtrError("");
  }, [requestType]);

  const DEVICE_SIZE = getDeviceSize();

  function ResetForm(refresh = false) {
    setAmount("");
    setUtr("");
    setAmountError("");
    setUtrError("");
    setAction("default");
    setRequestType(request_types[0]);
    if (refresh) {
      setHistoryFlag(Math.random());
    }
  }

  async function SubmitFundsRequest() {
    setAmountError("");
    setUtrError("");

    if (amount === "") {
      setAmountError("Please enter valid amount");
      return;
    }

    if (requestType === "Add Funds" && utr === "") {
      setUtrError("Please enter valid transaction number");
      return;
    }

    if (requestType === "Withdraw Funds" && parseFloat(amount) > cash) {
      setAmountError("You don't have enough amount to withdraw");
      return;
    }

    const token = GetCookie("token");

    const { account_code } = customer;

    setAction("loading");
    const data = await ServerRequest({
      method: "POST",
      url: `/funds/request?token=${token}`,
      data: {
        account_code: account_code,
        type: requestType === "Add Funds" ? "payin" : "payout",
        broker: broker,
        amount: parseFloat(amount).toFixed(2),
        utr_number: utr,
      },
    });

    if (data) {
      ResetForm(true);
      Alert({
        TitleText: "Success",
        Message: "Request submitted successfully!",
        ...warning_config,
      });
    } else {
      Alert({
        TitleText: "Warning",
        Message: "Something went wrong!",
        ...warning_config,
      });
    }

    setAction("default");
  }

  return (
    <>
      <div className="funds-request">
        <div className="funds-header">
          <p className="funds-title">Funds Management</p>
          {DEVICE_SIZE === "S" && (
            <>
              <div
                onClick={() => {
                  close && close(false);
                }}
              >
                <Cross size={30} color={COLOR_VARS["SWIFT_COLOR4"]} />
              </div>
            </>
          )}
        </div>

        <div className="funds-request-wrapper">
          {customers ? (
            <>
              {user_role_type === "BROKER" && (
                <>
                  <MACSearch
                    title="Search Customer"
                    customers={customers}
                    onSelect={(customer) => {
                      setCustomer(customer);
                    }}
                  />
                </>
              )}
              {customer ? (
                <>
                  {cash != undefined ? (
                    <>
                      <div className="funds-request-box select-box">
                        <CustomSelect
                          heading="Request Type"
                          options={request_types}
                          defaultIndex={0}
                          value={requestType}
                          onTypeChange={(value) => {
                            setRequestType(value);
                          }}
                        />
                      </div>
                      {customer.user_role === "BRC" && (
                        <>
                          <div className="funds-request-box select-box">
                            <CustomSelect
                              heading="Broker"
                              options={brokers}
                              defaultIndex={0}
                              value={broker}
                              onTypeChange={(value) => {
                                setBroker(value);
                              }}
                            />
                          </div>
                        </>
                      )}
                      {broker === "CHOICE" && (
                        <>
                          <span
                            className="funds-process-button"
                            onClick={() => {
                              setPopup(true);
                            }}
                          >
                            Funds Payin/Payout Process
                            <InfoCircle
                              size={16}
                              color={COLOR_VARS["SWIFT_COLOR4"]}
                            />
                          </span>
                        </>
                      )}
                      <div className="funds-request-box">
                        <CustomInput
                          heading={`Amount (Available Cash: ${numberFormat(
                            cash
                          )})`}
                          value={amount}
                          placeholder=""
                          error={amountError}
                          onChange={(val) => {
                            if (!isNaN(val)) {
                              setAmount(val);
                            }
                          }}
                        />
                      </div>

                      {requestType === "Add Funds" && (
                        <>
                          <div className="funds-request-box">
                            <CustomInput
                              heading="UTR Number (Transaction reference number)"
                              value={utr}
                              placeholder=""
                              error={utrError}
                              onChange={(val) => {
                                setUtr(val);
                              }}
                            />
                          </div>
                        </>
                      )}
                      <div className="funds-request-button">
                        <CustomIconActionButton
                          action={action}
                          icon={<ArrowRight2 />}
                          title="Submit"
                          loading_title="Submitting..."
                          onClick={SubmitFundsRequest}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {/* <div className="funds-request-box loader">
                        <Pulse />
                      </div> */}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <div className="funds-wrapper-loader">
                <Pulse />
              </div>
            </>
          )}
        </div>
      </div>
      {popup && (
        <>
          <SwiftModal
            top="2%"
            closeModal={() => {
              setPopup(false);
            }}
          >
            <FundsProcessPopup
              closePopup={() => {
                setPopup(false);
              }}
            />
          </SwiftModal>
        </>
      )}
    </>
  );
}

export default FundsRequest;
